import React from 'react';
import { FaUserTie } from 'react-icons/fa';


function AccountIcon(props: any) {
    return (
        <>
            <div className='d-flex align-items-center'>
                <span className='icon-bg-rounded icon-user'>
                    <FaUserTie size="16px" className='text-white' />
                </span>
                <span style={{ lineHeight: '17px', marginLeft: '7px' }}>{!!props.userName ? props.userName : null}</span>
            </div>
        </>
    );
}

export default AccountIcon;
