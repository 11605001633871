import { FC } from "react";
import { Badge, Table } from "react-bootstrap";

interface IProps {
    Name?: string,
    heading?: string,
    Data: any,
}

export const LatestNewsList: FC<IProps> = ({ Name, Data, heading }) => {
    return (
        <>
            <Table className={`${Name?.replace(/\s/g, '') + "table"}`}>
                <tbody>
                    {Data.map((item: any, index: number) => {
                        return (
                            <>
                                <tr className="" key={index}>
                                    <td className="p-1">
                                        <span className="fw-bold">{item.title}</span>
                                    </td>
                                    <td className="p-1">
                                        <Badge bg="secondary">{item.countBadge}</Badge>
                                    </td>
                                    <td className="p-1 datetimecolumn">
                                        <span className="">{item.dateTime}</span>
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </Table>
        </>
    )
}