import { useState, useRef, forwardRef, Ref, useImperativeHandle, Dispatch, SetStateAction } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

type propType = {
    triggerEle: JSX.Element,
    content: JSX.Element,
    handlePopoverShow?: () => void,
}

type funcType = {
    setShow: (v: boolean) => void,
}

const CustomPopover = forwardRef<funcType, propType>((props, ref) => {
    const [show, setShow] = useState<boolean>(false);
    const [target, setTarget] = useState<any>(null);
    const innerRef = useRef<HTMLDivElement>(null);

    const handleClick = (event: React.MouseEvent | React.TouchEvent) => {
        setShow(!show);
        setTarget(event.target);
    };
    useImperativeHandle(ref, () => ({
        setShow,
    }));

    const popoverStyle = {
        maxWidth: '700px',     // Set the maximum width for the popover content
        width: '350px'        // Ensure the popover content takes up the available width
    };

    return (
        <div ref={innerRef}>
            <div onClick={(event) => handleClick(event)}>
                {props.triggerEle}
            </div>
            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={innerRef}
                containerPadding={20}
                onHide={() => setShow(!show)}
            >
                <Popover id="popover-contained" style={popoverStyle}>
                    <Popover.Body>
                        {props.content}
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    );
})

export default CustomPopover;