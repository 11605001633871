import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, } from 'react-bootstrap';
import { HeadingWithDivider, MultiColorBox } from '../../StyledComponents';
import Form from 'react-bootstrap/Form';
import FormCheck from '../../FormCheck';
import FormSelect from '../../FormSelect';
import FormControl from '../../FormControl';
import BadgeWidget from '../../BadgeWidget';


const AllIdeaDropdown = [
    {
        title: "",
        color: "",
        badgeTextBorder: "",
        noBadgeTextBorder: "Alle",
        isCheck: false,
    },
    {
        title: "Allgemeine Termine",
        color: "#78e4eb",
        badgeTextBorder: "Praxis Gallenbach & Zähne",
        noBadgeTextBorder: "",
        isCheck: false,
    },
    {
        title: "calendar",
        color: "#0d3f5f",
        badgeTextBorder: "Praxis Gallenbach & Zähne",
        noBadgeTextBorder: "",
        isCheck: false,
    },
    {
        title: "calendar",
        color: "#0d3f5f",
        badgeTextBorder: "Praxis Gallenbach & Zähne",
        noBadgeTextBorder: "",
        isCheck: false,
    },
    {
        title: "Geburtstage / Jubiläen",
        color: "#0d3f5f",
        badgeTextBorder: "Praxis Gallenbach & Zähne",
        noBadgeTextBorder: "",
        isCheck: false,
    },
    {
        title: "Persönlicher Kalender",
        color: "#0d3f5f",
        badgeTextBorder: "Praxis Gallenbach & Zähne",
        noBadgeTextBorder: "",
        isCheck: false,
    },
    {
        title: "Urlaube",
        color: "#69b0ba",
        badgeTextBorder: "Praxis Gallenbach & Zähne",
        noBadgeTextBorder: "",
        isCheck: false,
    },
]

export const EventModuleOptionModalContent = () => {
    const [diversToogle, setDiversToogle] = useState<boolean>(false);
    const [hideStatic, setHideStatic] = useState<string>('');
    const [badgeList, setBadgeList] = useState<string[]>([]);

    const handleFormCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        debugger
        setHideStatic(event.currentTarget.value);
        console.log(event.currentTarget.value);
        if (event.currentTarget.value === 'Divers') {
            setDiversToogle(true);
        } else {
            setDiversToogle(false);
        }
    };


    useEffect(() => {
        console.log(hideStatic);
    }, [hideStatic]);

    return (
        <>
            <Row className='pb-2'>
                <Col md={12}>
                    <FormControl isFormGroup={true} Type='text' Size='sm' Placeholder='Titel' Name='title' />
                </Col>
                <Col md={12}>
                    <Form.Group controlId='Anrede' className="mb-2">
                        <Form.Label> Zeige Termine von: </Form.Label>
                        {AllIdeaDropdown.map((item, index) => {
                            return (
                                <>
                                    <div key={index}>
                                        <FormCheck
                                            handleChange={handleFormCheck}
                                            type='checkbox'
                                            name='phone'
                                            Id='phone'
                                            value={item.title}
                                            label={
                                                <>
                                                    <div className="d-flex align-items-center">
                                                        {item.badgeTextBorder ?
                                                            <BadgeWidget outiline={true} title={item.badgeTextBorder} color='#0d3f5f' mr='8px' />
                                                            :
                                                            <span className='mb-0'>{item.noBadgeTextBorder}</span>
                                                        }
                                                        <MultiColorBox bgcolor={item.color} mr='10px' ml='10px'></MultiColorBox>
                                                    </div>
                                                </>
                                            }
                                            isFormGroup={false}
                                            isInline={false}
                                            disabled={false}
                                            checked={false}
                                        />
                                        <Form.Label>{item.title}</Form.Label>
                                    </div>
                                </>
                            )
                        })}
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
}

