import { FC } from "react";
import { LatestIdeasList } from "../ListOfEntries/LatestIdeasList";
import { BodyText, DisplayBetween, DisplayStart } from "../StyledComponents"
import { Card, Dropdown, DropdownButton } from "react-bootstrap"
import { RiDragMove2Line } from "react-icons/ri"
import FormCheck from "../FormCheck"


type LatestIdeasProps = {
    title: string,
    dateTime: string,
    color: string,
}

const LatestIdeas: LatestIdeasProps[] = [
    {
        title: 'testing idea of creating',
        dateTime: 'vor 10 Tagen',
        color: 'blue',
    },
    {
        title: 'idea of marketing',
        dateTime: '22.11.2022 10:46',
        color: 'yellow',
    },
    {
        title: 'Titel Idee',
        dateTime: '12.11.2022 18:04',
        color: 'yellow',
    },
]





interface IProps {
    isDragMode?: boolean,
    dragHandleProps?: any,
    addedInDashboard?: boolean,
}

export const LatestIdeasModule: FC<IProps> = ({ isDragMode, dragHandleProps, addedInDashboard }) => {
    return (
        <>

            <Card className='border-0 rounded-0 b-shadow'>
                <Card.Header className='bg-white'>
                    <DisplayBetween>
                        <BodyText fontSize='18px' textColor='#666' fontWeight='600'> Aktuelle Termine </BodyText>
                        <DisplayStart>
                            {isDragMode ?
                                <div {...dragHandleProps}>
                                    <RiDragMove2Line size="18px" color="#666" />
                                </div>
                                :
                                null
                            }
                            <DropdownButton size="sm" className="template-withouticon f-12px ms-2" id="new-item" title={<></>}>
                                <Dropdown.Item>
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Bearbeiten </BodyText>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <FormCheck
                                        type='checkbox'
                                        name='phone'
                                        Id='phone'
                                        value='Automatische Höhe'
                                        label='Automatische Höhe'
                                        isFormGroup={false}
                                        isInline={false}
                                        disabled={false}
                                    />
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Löschen </BodyText>
                                </Dropdown.Item>
                            </DropdownButton>
                        </DisplayStart>
                    </DisplayBetween>
                </Card.Header>
                <Card.Body className='h-190px'>
                    <LatestIdeasList Data={LatestIdeas} Name={"Neueste Ideen"} />
                </Card.Body>
            </Card>
        </>
    )
}