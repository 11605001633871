import { FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BodyText, Box, DisplayEnd } from "../../../Components/StyledComponents";
import ButtonWidget from "../../../Components/ButtonWidget";
import AddEditIdea from "../../Idea/AddEditIdea";
import AddEditAppointment from "../../Appointment/AddEditAppointment";

interface IProps { }


const TermConfig: FC<IProps> = ({ }) => {

    const [AppointmentModal, setAppointmentModal] = useState<boolean>(false);

    return (
        <>
            <Row>
                <Col md={12}>
                    <h2> Termine </h2>
                    <BodyText fontSize='15px' fontWeight='400' textColor='#666' mt="12px" mb="15px"> Vorlage ändern, die für alle neu erstellten Termine verwendet wird, für die keine Vorlage eingestellt wurde. </BodyText>
                    <ButtonWidget varient="primary" text="Standard Termin bearbeiten" class='text-nowrap' handleClick={() => setAppointmentModal(true)} />
                </Col>
                <Col md={12}>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
            </Row>

            <AddEditAppointment
                showAppointment={AppointmentModal}
                hideAppointment={() => setAppointmentModal(false)}
            />
        </>
    );
}

export default TermConfig;