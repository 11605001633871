import { FC } from "react";
import { DisplayCenter, DisplayEnd } from "../../../Components/StyledComponents";
import ButtonWidget from "../../../Components/ButtonWidget";
import { FaCrop } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";


interface IProps { }

export const WelcomeMessage: FC<IProps> = ({ }) => {
    return (
        <>
            <Row>
                <Col md={12}>
                    <h2>Willkommensnachricht</h2>
                    <p>Nachricht ändern, die allen neu aktivierten Nutzern geschickt wird</p>
                </Col>
                <Col md={4}>
                    <div className="mt-2">
                        <ButtonWidget
                            varient="primary"
                            text="Bild zuschneiden"
                            class="py-2 px-3"
                            handleClick={() => { }}
                        />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
            </Row>



        </>
    )
}