import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CommonModal from '../../../../Components/Modals/CommonModal';
import SaveCurrentFilterContent from '../../../../Components/Modals/ModelContents/CalendarModalsContent/SaveCurrentFilterContent';
import ManageSavedFilterContent from '../../../../Components/Modals/ModelContents/CalendarModalsContent/ManageSavedFilterContent';

export const WrapperCurrent = styled.div`
padding:15px 0;
${(props: {notop?: boolean}) => props?.notop !== true && "border-top:1px solid #ccc"};
border-bottom:1px solid #ccc;
`
export const AnchorLink = styled.a`
display:block;
text-align:right;
padding-right:15px;
font-size:${(props: {fs?: number}) => props?.fs ? props?.fs + 'px' : '10px'};
color:#666;
`
export type filtesListType = {
    title:string,
    isChecked:boolean
}

function SaveCurrentFilter() {
    const [showSaveCurrentFilterModal, SetshowSaveCurrentFilterModal] = useState<boolean>(false);
    const [showManageSavedFilters, setShowManageSavedFilters] = useState<boolean>(false);
    const [filtersList, setFiltersList] = useState<filtesListType[]>([]);
    return ( 
        <>
        <WrapperCurrent>
            <div className='ms-2 mb-2'>
                {filtersList.map((x,i) => <AnchorLink fs={14} className='text-start' href="javascript:;">{x.title} {i ===0 && "*"}</AnchorLink>)}
            </div>
            <AnchorLink href="javascript:;" className='mb-2' onClick={() => SetshowSaveCurrentFilterModal(true)}>Save Current Filter {'>>'}</AnchorLink>
            <AnchorLink href="javascript:;" onClick={() => setShowManageSavedFilters(true)}>Manage Saved Filters {'>>'}</AnchorLink>
            <CommonModal
                showModal={showSaveCurrentFilterModal}
                hideModal={() => SetshowSaveCurrentFilterModal(false)}
                modalTitle='Save current filters'
                modalContent={<SaveCurrentFilterContent closeModal={SetshowSaveCurrentFilterModal} setFiltersList={setFiltersList} data={filtersList}/>}
                modalSize='md'
                modalFooterContent={<button className='btn btn-default' onClick={() => SetshowSaveCurrentFilterModal(false)}>Close</button>}
            />
            <CommonModal
                showModal={showManageSavedFilters}
                hideModal={() => setShowManageSavedFilters(false)}
                modalTitle='Manage saved filters'
                modalContent={<ManageSavedFilterContent closeModal={setShowManageSavedFilters} setFiltersList={setFiltersList} data={filtersList}/>}
                modalSize='lg'
                modalFooterContent={<button className='btn btn-default' onClick={() => setShowManageSavedFilters(false)}>Close</button>}
            />
        </WrapperCurrent>
        </>
     );
}

export default SaveCurrentFilter;