import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

function DoughnutChart(props) {
    ChartJS.register(ArcElement, Tooltip, Legend);
    var data = {
        labels: props.chartLabel,
        datasets: [{
            data: props.chartData,
            backgroundColor: [
                "rgb(153, 153, 187,0.9)",
                "rgb(92, 184, 92,0.9)",
            ],
            hoverBackgroundColor: [
                "rgb(153, 153, 187)",
                "rgb(92, 184, 92)",
            ]
        }]
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        },
        cutout: props.chartRadius
    };
    const plugins = [{
        beforeDraw: function (chart, a, b) {
            let ctx = chart.ctx;
            ctx.restore();
            const fontSize = (chart.height / 114).toFixed(2);
            ctx.font = fontSize + 'em sans-serif';
            ctx.textBaseline = 'middle';
            const dataArrValues = chart.config._config.data.datasets[0].data;
            let text =
                chart.tooltip._active.length > 0
                    ? `${Math.round(
                        (dataArrValues[chart.tooltip._active[0].datasetIndex] /
                            dataArrValues.reduce((a, b) => a + b)) *
                        100
                    )}%`
                    : `${Math.round(
                        (dataArrValues[0] / dataArrValues.reduce((a, b) => a + b)) * 100
                    )}%`;
            let textX = Math.round((chart.width - ctx.measureText(text).width) / 2);
            let textY = chart.height / 2 + chart.legend.height / 2;
            ctx.fillText(text, textX, textY);
            ctx.fillStyle = '#000';
            ctx.save();



            // var width = chart.width,
            //     height = chart.height,
            //     ctx = chart.ctx;

            // ctx.restore();
            // var fontSize = (height / 240).toFixed(2);
            // ctx.font = fontSize + "em sans-serif";
            // ctx.textBaseline = "middle";

            // var text = "75%",
            //     textX = Math.round((width - ctx.measureText(text).width) / 2),
            //     textY = height / 2;

            // ctx.fillText(text, textX, textY);
            // ctx.save();
        }
    }]

    return (
        <>
            <Doughnut
                data={data}
                options={options}
                plugins={plugins}
            />
        </>
    );
}


export default DoughnutChart;