import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import FormControl from '../../../FormControl';
import { Box, DisplayStart, Divider } from '../../../StyledComponents';
import { Col, Row } from 'react-bootstrap';

export const RequestMoreInformationModalContent = () => {
    return (
        <>
            <Row>
                <Col md={12} sm={12}>
                    <Box mb="12px">
                        <FormControl isFormGroup={true} Type='text' Size='sm' Placeholder='Your email address' Name='emailAddress' />
                    </Box>
                    <Box mb="12px">
                        <FormControl isFormGroup={true} Type='number' Size='sm' Placeholder='Your phone number' Name='phoneNo' />
                    </Box>
                    <Divider mt='8px' mb='12px' />
                    <Box className='mb-2'>
                        <Form.Control as="textarea" placeholder="Notes and Questions" rows={4} />
                    </Box>
                </Col>
            </Row>
        </>
    )
}