import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { FaHome, FaUser } from 'react-icons/fa';
import { NavLink } from "react-router-dom";

export const Sidebar = () => {
    return (
        <>
            <div className='sidebar-users'>
                <Card className='boxShadow border-0 rounded-0'>
                    <Card.Header className='bg-white d-flex justify-content-center align-items-center'>
                        <Card.Title className='mb-0'>
                            <FaUser className='me-2 gray-icon-color' size="25px" />
                        </Card.Title>
                        <Card.Title className='text-uppercase mb-0 gray-color'>Employees</Card.Title>
                    </Card.Header>
                    <Card.Body className='p-0'>
                        <div className='pt-2'>
                            <ul className='list-unstyled ps-4 info-list'>
                                <li className='pt-1 pb-1'>
                                    <NavLink to="" className='text-uppercase'> Alle </NavLink>
                                </li>
                            </ul>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}