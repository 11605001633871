import React, { useState } from 'react';

export const AccountFooter = () => {
    return (
        <>
            <div className='footer-main shadow mt-5 py-3'>
                <div className='text-center'>
                    <p className='m-0'>
                        <b>Medikit Copyright© 2023</b>
                    </p>
                </div>
            </div>
        </>
    )
}