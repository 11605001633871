import React, { useState, useEffect, FC, useRef } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { BodyText, BorderBox, Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider, HeadingWithDivider, } from '../../../Components/StyledComponents';
import FormCheck from '../../../Components/FormCheck';
import { FaCheckCircle, FaChevronDown, FaChevronRight, FaChevronUp, FaPlusCircle } from 'react-icons/fa';
import ButtonWidget from '../../../Components/ButtonWidget';
import BadgeWidget from '../../../Components/BadgeWidget';
import CustomTooltip from '../../../Components/Tooltip';
import Recipient from '../../../Components/Modals/Recipient';
import { Link } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UserProfileDto } from '../../../Model/ViewModels/User/UserProfileDto';
import { useForm } from 'react-hook-form';
import { InputTextControl } from '../../../Components/Widgets/InputControls/InputTextControl';
import { SelectControl } from '../../../Components/Widgets/InputControls/SelectControl';
import { CheckControl } from '../../../Components/Widgets/InputControls/CheckControl';
import { AlertWidget } from '../../../Components/AlertWidget';
import Table from 'react-bootstrap/Table';



const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
    })
    .required();


type permissionType = {
    headingCollapse: string,
    permission:
    {
        title: string,
        value: string | null,
    }[],
}



const groupJsonData: permissionType[] = [
    {
        headingCollapse: "Nachrichten",
        permission: [
            {
                title: "Einträge erstellen",
                value: null,
            },
            {
                title: "Einträge zu späterem Zeitpunkt veröffentlichen",
                value: null,
            },
            {
                title: "Nicht-öffentliche Einträge erstellen",
                value: null,
            },
            {
                title: "Kommentieren",
                value: null,
            },
            {
                title: "Drucken oder PDFs generieren",
                value: null,
            },
            {
                title: "Kann Benachrichtigungen deaktivieren",
                value: null,
            },
            {
                title: "Kommentare schließen",
                value: null,
            },
            {
                title: "Fremde Einträge bearbeiten",
                value: null,
            },
            {
                title: "Fremde Einträge löschen",
                value: null,
            },
            {
                title: "Neue Vorlagen erstellen",
                value: null,
            },
            {
                title: "Vorlagenkategorien erstellen und bearbeiten",
                value: null,
            },
        ],
    },
    {
        headingCollapse: "News",
        permission: [
            {
                title: "Einträge erstellen",
                value: null,
            },
            {
                title: "Einträge zu späterem Zeitpunkt veröffentlichen",
                value: null,
            },
            {
                title: "Nicht-öffentliche Einträge erstellen",
                value: null,
            },
            {
                title: "Kommentieren",
                value: null,
            },
            {
                title: "Drucken oder PDFs generieren",
                value: null,
            },
            {
                title: "Kann Benachrichtigungen deaktivieren",
                value: null,
            },
            {
                title: "Kommentare schließen",
                value: null,
            },
            {
                title: "Fremde Einträge bearbeiten",
                value: null,
            },
            {
                title: "Fremde Einträge löschen",
                value: null,
            },
            {
                title: "Neue Vorlagen erstellen",
                value: null,
            },
            {
                title: "Vorlagenkategorien erstellen und bearbeiten",
                value: null,
            },
        ],
    },
]

const SortingDropdown = [
    {
        title: "Älle andern",
    },
    {
        title: "Alle deaktivieren",
    },
    {
        title: "Alle unverändert belassen",
    },
    {
        title: "Alle aktivieren",
    },
]


export const EditGroupAdminModal = () => {

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });


    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    const [arrowAngle, setArrowAngle] = useState<boolean>(false);
    const [addAll, setAddAll] = useState<boolean>(false);

    const handleArrowAngle = () => {
        setArrowAngle(!arrowAngle);
    }

    const handleAddAll = () => {
        setAddAll(!addAll);
    }

    return (
        <>
            <Form>
                <Row>
                    <Col md={12}>
                        <DisplayBetween>
                            <Box className='w-100' mr="8px">
                                {addAll ?
                                    <>
                                        <DisplayStart>
                                            <CustomTooltip
                                                ToolTipTrigger={
                                                    <BadgeWidget
                                                        solid={true}
                                                        title="Abrechnung"
                                                        color="#fff"
                                                        bgColor="#678ea4"
                                                        mr="5px"
                                                        closeIcon={true}
                                                    />
                                                }
                                                toolTipContent="APZAPZI"
                                                toolTipPlacement="top"
                                            />
                                            <CustomTooltip
                                                ToolTipTrigger={
                                                    <BadgeWidget
                                                        solid={true}
                                                        title="Abrechnung"
                                                        color="#fff"
                                                        bgColor="#678ea4"
                                                        mr="5px"
                                                        closeIcon={true}
                                                    />
                                                }
                                                toolTipContent="Alicia Gojeni, Anne Benm, Cera Bianco, Hoa Trinh, Irene Gantert"
                                                toolTipPlacement="top"
                                            />
                                            <CustomTooltip
                                                ToolTipTrigger={
                                                    <BadgeWidget
                                                        solid={true}
                                                        title="medikit Champions"
                                                        color="#fff"
                                                        bgColor="#678ea4"
                                                        mr="5px"
                                                        closeIcon={true}
                                                    />
                                                }
                                                toolTipContent="Alicia Gojeni, Anne Benm, Cera Bianco, Hoa Trinh, Irene Gantert"
                                                toolTipPlacement="top"
                                            />
                                        </DisplayStart>
                                    </>
                                    :
                                    <>
                                        <Box mb="10px">
                                            <InputTextControl
                                                control={control}
                                                fieldName={"firstName"}
                                                error={errors.firstName}
                                                placeholder="Betroffene Gruppen"
                                                fieldType="text"
                                                size={"sm"}
                                            />
                                        </Box>
                                    </>}
                            </Box>
                            <Box>
                                <DisplayStart>
                                    <Box mb="4px">
                                        <ButtonWidget
                                            varient="light"
                                            text={
                                                <>
                                                    <DisplayEnd>
                                                        <FaPlusCircle className="default-btn-icon mt-2" />
                                                        <span className="ms-1 text-nowrap"> Alle anzejgen </span>
                                                    </DisplayEnd>
                                                </>
                                            }
                                            handleClick={(event) => setRecipientModal(true)}
                                        />
                                    </Box>
                                    <Box mb="4px" ml="5px">
                                        <ButtonWidget
                                            varient="light"
                                            text={
                                                <>
                                                    <DisplayEnd>
                                                        <FaCheckCircle className="default-btn-icon mt-2" />
                                                        <span className="ms-1 text-nowrap"> Alle hinzufügen </span>
                                                    </DisplayEnd>
                                                </>
                                            }
                                            handleClick={handleAddAll}
                                        />
                                    </Box>
                                </DisplayStart>
                            </Box>
                        </DisplayBetween>
                        {addAll ?
                            <>
                                <Box mt="10px" mb="10px">
                                    <AlertWidget variant='warning' text='Bitte gib einen Optionen Text ein!' />
                                </Box>
                            </>
                            : null
                        }
                    </Col>
                </Row>
                <Divider mt="6px" mb="14px" />
                <Box>
                    <DisplayBetween>
                        <BodyText fontSize='14px' fontWeight='600' textColor='#666'>
                            Berechtigungen:
                        </BodyText>
                        <ButtonWidget
                            varient="light"
                            text={'Alle offnen'}
                            handleClick={handleArrowAngle}
                        />
                    </DisplayBetween>
                </Box>
                <Divider mt="6px" mb="14px" />
                {groupJsonData.map((item, index) => {
                    return (
                        <>
                            <Box key={index}>
                                <DisplayBetween className='cursor-pointer'>
                                    <Box onClick={handleArrowAngle} className='w-75'>
                                        <Link to="" className='text-decoration-none'>
                                            <span className='fw-600 w-176px text-truncate text-link-color f-15px'> {item.headingCollapse} </span>
                                        </Link>
                                    </Box>
                                    <Box className='w-25'>
                                        {arrowAngle ?
                                            <>
                                                <DisplayEnd>
                                                    <Box mr="6px">
                                                        <DropdownButton size="sm" className="template-sort f-12px" id="new-item" title="Sortierung: letzte Aktion">
                                                            {SortingDropdown.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <Dropdown.Item key={index}>
                                                                            <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                                                        </Dropdown.Item>
                                                                    </>
                                                                )
                                                            })}
                                                        </DropdownButton>
                                                    </Box>
                                                    <span className='cursor-pointer' onClick={handleArrowAngle}>
                                                        <FaChevronDown size={14} />
                                                    </span>
                                                </DisplayEnd>
                                            </>
                                            :
                                            <>
                                                <DisplayEnd className='cursor-pointer' onClick={handleArrowAngle}>
                                                    <FaChevronRight size={14} />
                                                </DisplayEnd>
                                            </>}
                                    </Box>
                                </DisplayBetween>
                                <Box className={`toggle-text-group ${arrowAngle ? 'show-text-group' : 'hide-text-group'}`}>
                                    {arrowAngle ?
                                        <>
                                            <Row className='mt-3'>
                                                <Col md={12}>
                                                    <div className='table-group-action'>
                                                        <Table striped responsive borderless className='mb-0'>
                                                            <tbody>

                                                                {item.permission.map((innerItem, innerIndex) => {
                                                                    return (
                                                                        <>
                                                                            <tr className='' key={index}>
                                                                                <td className='w-50'>
                                                                                    <Box className='msg-title'>
                                                                                        <BodyText fontSize='15px' fontWeight='400' textColor='#666'>
                                                                                            {innerItem.title}
                                                                                        </BodyText>
                                                                                    </Box>
                                                                                </td>
                                                                                <td className='w-50'>
                                                                                    <DisplayCenter>
                                                                                        <Box>
                                                                                            <FormCheck
                                                                                                type='radio'
                                                                                                name='phone'
                                                                                                Id='phone'
                                                                                                value={"Deaktivieren"}
                                                                                                label={"Deaktivieren"}
                                                                                                isFormGroup={false}
                                                                                                isInline={true}
                                                                                                // handleChange={() => singleCheckboxSet(index, i)}
                                                                                                checked={undefined}
                                                                                            />
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <FormCheck
                                                                                                type='radio'
                                                                                                name='phone'
                                                                                                Id='phone'
                                                                                                value={"Unverändert"}
                                                                                                label={"Unverändert"}
                                                                                                isFormGroup={false}
                                                                                                isInline={true}
                                                                                                // handleChange={() => singleCheckboxSet(index, i)}
                                                                                                checked={undefined}
                                                                                            />
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <FormCheck
                                                                                                type='radio'
                                                                                                name='phone'
                                                                                                Id='phone'
                                                                                                value={"Aktivieren"}
                                                                                                label={"Aktivieren"}
                                                                                                isFormGroup={false}
                                                                                                isInline={true}
                                                                                                // handleChange={() => singleCheckboxSet(index, i)}
                                                                                                checked={undefined}
                                                                                            />
                                                                                        </Box>
                                                                                    </DisplayCenter>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}



                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                        : null}
                                </Box>
                                <Divider mt="8px" mb="8px" />
                            </Box>
                        </>
                    )
                })}

            </Form>

            <Recipient
                displayRecipientModal={RecipientModal}
                hideRecipientModal={() => setRecipientModal(false)}
            />

        </>
    )
}
