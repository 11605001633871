import { FC, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { CheckControl } from "../../../Components/Widgets/InputControls/CheckControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { BodyText, Box, DisplayEnd, DisplayStart } from "../../../Components/StyledComponents";
import { InputTextControl } from "../../../Components/Widgets/InputControls/InputTextControl";
import ButtonWidget from "../../../Components/ButtonWidget";
import { SelectControl } from "../../../Components/Widgets/InputControls/SelectControl";
import { AllRepairNumberDropdown } from "../../../CommonJSON";


interface IProps { }


const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
        lastName: yup
            .string()
            .typeError("Last name is required")
            .required("Last name is required"),
        position: yup
            .string()
            .typeError("Position is required")
            .required("Position is required"),
    })
    .required();


const RepairsConfig: FC<IProps> = ({ }) => {

    const [statusChange, setStatusChange] = useState<boolean>(false);
    const [simpleStatus, setSimpleStatus] = useState<boolean>(false);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });
    const fn = watch("firstName");
    const { gender } = watch();
    console.log(fn);

    const handleStatusChange = () => {
        setStatusChange(!statusChange);
    }

    const handleSimpleStatus = () => {
        setSimpleStatus(!simpleStatus);
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <h2> Reparaturen </h2>
                    <Box mb="10px">
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"gender"}
                            error={errors.gender}
                            value={"Ein vordefinierter Ort muss ausgewählt werden"}
                            label={"Ein vordefinierter Ort muss ausgewählt werden"}
                            isInline={false}
                            disabled={false}
                            checked={undefined}
                            defaultChecked={false}
                        />
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"gender"}
                            error={errors.gender}
                            value={"Ein vordefinierter Typ muss ausgewählt werden"}
                            label={"Ein vordefinierter Typ muss ausgewählt werden"}
                            isInline={false}
                            disabled={false}
                            checked={undefined}
                            defaultChecked={false}
                        />
                    </Box>
                </Col>
                <Row>
                    <Col md={6} className="mt-1">
                        <Box>
                            <BodyText fontSize='15px' fontWeight='600' textColor='#333'> Alle Reparaturen erhalten eine fortlaufende Nummerierung </BodyText>
                        </Box>
                        <Box mt="8px" mb="8px">
                            <SelectControl
                                fieldId={"salutationId"}
                                fieldName={"salutationId"}
                                control={control}
                                error={errors.salutationId}
                                selectOptions={AllRepairNumberDropdown}
                            />
                        </Box>
                    </Col>
                    <Col md={6} className="mt-1">
                        <Box>
                            <BodyText fontSize='15px' fontWeight='600' textColor='#333'> Präfix zur Nummerierung hinzufügen </BodyText>
                        </Box>
                        <Box mt="8px" mb="8px">
                            <InputTextControl
                                control={control}
                                fieldName={"firstName"}
                                error={errors.firstName}
                                placeholder=""
                                fieldType="number"
                                size={"md"}
                            />
                        </Box>
                    </Col>
                    <Col md={12}>
                        <div className="table-data backups">
                            <Box mt="20px">
                                <BodyText fontSize='18px' fontWeight='600' textColor='#333'> Reparatur Park-Status </BodyText>
                            </Box>
                            <Table responsive className="mt-2">
                                <thead>
                                    <tr className="table-primary">
                                        <th> Parken Name </th>
                                        <th> In Statistik inkludieren </th>
                                        <th> Begründung erforderlich </th>
                                        <th>Aktiviere / Deaktiviere Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Box>
                                                <InputTextControl
                                                    control={control}
                                                    fieldName={"firstName"}
                                                    error={errors.firstName}
                                                    placeholder="Neuer Park-Status..."
                                                    fieldType="text"
                                                    size={"md"}
                                                />
                                            </Box>
                                        </td>
                                        <td className="text-center">
                                            <Box mt="6px">
                                                <CheckControl
                                                    fieldType={"checkbox"}
                                                    control={control}
                                                    fieldName={"gender"}
                                                    error={errors.gender}
                                                    value={"Backup-Erinnerungen deaktivieren"}
                                                    label={""}
                                                    isInline={false}
                                                    disabled={false}
                                                    checked={undefined}
                                                    defaultChecked={false}
                                                />
                                            </Box>
                                        </td>
                                        <td className="text-center">
                                            <Box mt="6px">
                                                <CheckControl
                                                    fieldType={"checkbox"}
                                                    control={control}
                                                    fieldName={"gender"}
                                                    error={errors.gender}
                                                    value={"Backup-Erinnerungen deaktivieren"}
                                                    label={""}
                                                    isInline={false}
                                                    disabled={false}
                                                    checked={undefined}
                                                    defaultChecked={false}
                                                />
                                            </Box>
                                        </td>
                                        <td>
                                            {simpleStatus ?
                                                <>
                                                    {statusChange ?
                                                        <>
                                                            <ButtonWidget varient="light" text="Deaktivieren" class='text-nowrap' handleClick={handleStatusChange} />
                                                        </>
                                                        :
                                                        <>
                                                            <ButtonWidget varient="light" text="Aktivieren" class='text-nowrap' handleClick={handleStatusChange} />
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <ButtonWidget varient="primary" text="Status erstellen" class='text-nowrap' handleClick={handleSimpleStatus} />
                                                </>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Box>
                                                <InputTextControl
                                                    control={control}
                                                    fieldName={"firstName"}
                                                    error={errors.firstName}
                                                    placeholder="Neuer Park-Status..."
                                                    fieldType="text"
                                                    size={"md"}
                                                />
                                            </Box>
                                        </td>
                                        <td className="text-center">
                                            <Box mt="6px">
                                                <CheckControl
                                                    fieldType={"checkbox"}
                                                    control={control}
                                                    fieldName={"gender"}
                                                    error={errors.gender}
                                                    value={"Backup-Erinnerungen deaktivieren"}
                                                    label={""}
                                                    isInline={false}
                                                    disabled={false}
                                                    checked={undefined}
                                                    defaultChecked={false}
                                                />
                                            </Box>
                                        </td>
                                        <td className="text-center">
                                            <Box mt="6px">
                                                <CheckControl
                                                    fieldType={"checkbox"}
                                                    control={control}
                                                    fieldName={"gender"}
                                                    error={errors.gender}
                                                    value={"Backup-Erinnerungen deaktivieren"}
                                                    label={""}
                                                    isInline={false}
                                                    disabled={false}
                                                    checked={undefined}
                                                    defaultChecked={false}
                                                />
                                            </Box>
                                        </td>
                                        <td>
                                            {simpleStatus ?
                                                <>
                                                    {statusChange ?
                                                        <>
                                                            <ButtonWidget varient="light" text="Deaktivieren" class='text-nowrap' handleClick={handleStatusChange} />
                                                        </>
                                                        :
                                                        <>
                                                            <ButtonWidget varient="light" text="Aktivieren" class='text-nowrap' handleClick={handleStatusChange} />
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <ButtonWidget varient="primary" text="Status erstellen" class='text-nowrap' handleClick={handleSimpleStatus} />
                                                </>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
                <Col md={12}>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
            </Row>

        </>
    );
}

export default RepairsConfig;