import * as React from 'react';
import { BodyText, Box } from '../../StyledComponents';


export const DeleteMessageDetailModalContent = () => {
    return (
        <>
            <Box className='p-1'>
                <BodyText fontSize='13px' fontWeight='400' textColor='#666'>
                    Really delete this message for EVERYONE?
                </BodyText>
            </Box>
        </>
    )
}