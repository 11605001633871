import React, { useState } from 'react';
import { BodyText, Box, DisplayEnd, Divider } from '../../../StyledComponents';
import { Link } from 'react-router-dom';
import BadgeWidget from '../../../BadgeWidget';
import ButtonWidget from '../../../ButtonWidget';
import CommonModal from '../../CommonModal';
import { RequestMoreInformationModalContent } from './RequestMoreInformationModalContent';

export const MomentModalContent = () => {

    const [requestInfo, setRequestInfo] = useState<boolean>(false);

    return (
        <>
            <div className='modal-moment'>
                <Box>
                    <DisplayEnd>
                        <ButtonWidget
                            text="Request More Information"
                            varient="custom"
                            bgColor="#aad655"
                            textColor="#fff"
                            textColorHover="#0D3F5F"
                            bgHoverColor="#b4e664"
                            handleClick={() => setRequestInfo(true)}
                        />
                    </DisplayEnd>
                </Box>
                <Box mt="20px" mb="20px" className='text-center'>
                    <img src="/images/assets/moment-icon.png" className='integration-preview-img' />
                </Box>
                <Box mb="15px">
                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' mt="10px" mb="10px">
                        Moments
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="10px" mb="10px">
                        medikit is all about communication as well as efficient collaboration and handling of daily processes. With medikit Moments even more fun now follows functionality. There are always, even in difficult times, funny or wonderful moments. The smiles, jokes, excitement. Make sure you capture, share and treasure these memories with medikit Moments.
                    </BodyText>
                </Box>
                <Box mb="15px">
                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' mt="10px" mb="10px">
                        Functions
                    </BodyText>
                    <Box mt="10px" mb="10px">
                        <ul>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'>Share posts with photos and videos</BodyText></li>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'>Like and comment on posts</BodyText></li>
                        </ul>
                    </Box>
                </Box>
                <Box mb="15px">
                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' mt="10px" mb="10px">
                        Advantages
                    </BodyText>
                    <Box mt="10px" mb="10px">
                        <ul>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'>Easy switch between workstream and social stream (Moments)</BodyText></li>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'>Social interaction with the entire team</BodyText></li>
                        </ul>
                    </Box>
                </Box>
                <Box mb="15px">
                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' mt="10px" mb="10px">
                        Activation
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="10px" mb="10px">
                        Are you interested and would like to know more about medikit Moments? Then simply get in touch with your medikit contact person (via the button on the top right).
                    </BodyText>
                </Box>
                <Box mb="15px">
                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' mt="10px" mb="10px">
                        Costs
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="10px" mb="10px">
                        This extension is free of charge for medikit customers.
                    </BodyText>
                </Box>
                <Box mb="15px">
                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' mt="10px" mb="10px">
                        Contact details
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                        medikit
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                        +43 662 238080
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                        <Link to="" className='text-decoration-none'>
                            <span className='w-176px text-truncate text-link-color f-14px text-primary'> info@medikitnet </span>
                        </Link>
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                        <Link to="" className='text-decoration-none'>
                            <span className='w-176px text-truncate text-link-color f-14px text-primary'> www.medikit_net/de/ </span>
                        </Link>
                    </BodyText>
                </Box>
                <Divider mt="5px" mb="6px" />
                <Box className='text-center' mt="5px">
                    <BadgeWidget solid={true} title="Tools" color="#fff" bgColor="#337ab7" />
                </Box>
            </div>

            <CommonModal
                showModal={requestInfo}
                hideModal={() => setRequestInfo(false)}
                modalTitle='Request more information'
                modalContent={<RequestMoreInformationModalContent />}
                modalSize='md'
            />

        </>
    )
}
