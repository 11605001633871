import { ChangeEvent, FC, useState } from "react";
import { Button, Card, Dropdown, DropdownButton, Nav } from "react-bootstrap";
import { BodyText, Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider, HighlightBox } from "../../../Components/StyledComponents";
import { Col, Row } from 'react-bootstrap';
import ButtonWidget from "../../../Components/ButtonWidget";
import { InputTextControl } from "../../../Components/Widgets/InputControls/InputTextControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { setThemeColor } from "../../../Redux/Slice/appSlice";
import { useDispatch } from "react-redux";
import { FaCogs, FaPlusCircle, FaRegCalendarAlt } from "react-icons/fa";
import Recipient from "../../../Components/Modals/Recipient";
import Swal from 'sweetalert2';
import { FaClock } from "react-icons/fa";
import { HiChatBubbleBottomCenterText } from "react-icons/hi2";
import { FaAddressBook } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaBookmark } from "react-icons/fa";
import { HiStar } from "react-icons/hi2";
import { FaUserTie, FaSearch } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { HiLightBulb } from "react-icons/hi";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IpBlockingDropdown } from "../../../CommonJSON";


interface IProps { }

const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
        lastName: yup
            .string()
            .typeError("Last name is required")
            .required("Last name is required"),
        position: yup
            .string()
            .typeError("Position is required")
            .required("Position is required"),
    })
    .required();

const IPManager: FC<IProps> = ({ }) => {

    let dispatch = useDispatch();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });
    const fn = watch("firstName");
    const { gender } = watch();
    console.log(fn);

    const [selectedColor, setSelectedColor] = useState<string>('');
    const [addIpAddress, setAddIpAddress] = useState<boolean>(false);
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    const [addSettingIp, setAddSettingIp] = useState<boolean>(false);
    const [clickCount, setClickCount] = useState<number>(0);

    const handleIpAddress = () => {
        setAddIpAddress(!addIpAddress);
    }

    const handleAddSetting = () => {
        setClickCount(clickCount + 1);
        setAddSettingIp(true)
    }


    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedColor(event.target.value);
    };

    const handleDeleteIpBlocking = () => {
        Swal.fire({
            title: 'Möchtest du diese IP-Sperrregel wirklich löschen?',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonText: `Abbrechen`,
            confirmButtonText: 'Löschen',
            customClass: {
                container: 'discardTask-alert',
                confirmButton: 'f-14px btn-danger fw-400',
                cancelButton: 'f-14px default-btn fw-400',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Verworfen!', '', 'success');
            }
        })
    }

    const [activeTab, setActiveTab] = useState<any>('admin');

    const handleTabSelect = (eventKey: any) => {
        setActiveTab(eventKey);
    };

    return (
        <>
            <div className="ip-manager-wrapper">
                <Card className='boxShadow'>
                    <Card.Body>
                        <Box mb="15px">
                            <HighlightBox
                                className="text-center"
                                bgColor="#d9edf7"
                                borderColor="#bce8f1"
                                textColor="#31708f"
                            >
                                IP-Sperre ist aktuell inaktiv. Zugriff von jeder IP erlaubt.
                            </HighlightBox>
                        </Box>
                        <Row>
                            <Col md={8} xs={12}>
                                <Box>
                                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333'> Allgemeine Einstellungen für die IP-Sperre definieren </BodyText>
                                </Box>
                                <Box width="250px" mt="12px">
                                    <DropdownButton size="sm" className="template-sort f-12px" id="new-item" title="Zugriff von allen IPs aus erlauben">
                                        {IpBlockingDropdown.map((item, index) => {
                                            return (
                                                <>
                                                    <Dropdown.Item key={index}>
                                                        <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                                    </Dropdown.Item>
                                                </>
                                            )
                                        })}
                                    </DropdownButton>
                                </Box>
                            </Col>
                            <Col md={4} xs={12}>
                                <Box mb="15px">
                                    <HighlightBox
                                        className="text-center"
                                        bgColor="#d9edf7"
                                        borderColor="#bce8f1"
                                        textColor="#31708f"
                                    >
                                        Deine aktuelle IP lautet: 182.176.147.55
                                    </HighlightBox>
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7} xs={12}>
                                <Box mt="20px">
                                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333'> Erlaubte IPs definieren </BodyText>
                                </Box>
                                {addIpAddress ?
                                    <>
                                        <Box mt="8px" mb="8px">
                                            <DisplayStart>
                                                <InputTextControl
                                                    control={control}
                                                    fieldName={"firstName"}
                                                    error={errors.firstName}
                                                    placeholder="Erlaubte IP hinzufügen"
                                                    fieldType="text"
                                                    size={"sm"}
                                                />
                                                <Box ml="10px">
                                                    <ButtonWidget varient="light" text="Einzeladresse hinzufügen" class='text-nowrap' />
                                                </Box>
                                            </DisplayStart>
                                        </Box>
                                        <Box mt="8px" mb="8px">
                                            <DisplayStart>
                                                <Box>
                                                    <InputTextControl
                                                        control={control}
                                                        fieldName={"firstName"}
                                                        error={errors.firstName}
                                                        placeholder="Anfang IP-Adressbereich"
                                                        fieldType="text"
                                                        size={"sm"}
                                                    />
                                                </Box>
                                                <Box ml="10px">
                                                    <InputTextControl
                                                        control={control}
                                                        fieldName={"firstName"}
                                                        error={errors.firstName}
                                                        placeholder="Ende IP-Adressbereich"
                                                        fieldType="text"
                                                        size={"sm"}
                                                    />
                                                </Box>
                                                <Box ml="10px">
                                                    <ButtonWidget varient="light" text="Adressbereich hinzufügen" class='text-nowrap' />
                                                </Box>
                                            </DisplayStart>
                                        </Box>
                                    </>
                                    :
                                    <>
                                        <Box mt="12px">
                                            <ButtonWidget varient="light" text="IP-Adressen hinzufügen" class='' handleClick={handleIpAddress} />
                                        </Box>
                                    </>

                                }
                            </Col>
                            <Col md={12}>
                                <Box>
                                    <Divider mt="12px" mb="12px" />
                                    <DisplayEnd>
                                        <ButtonWidget
                                            text="Speichern"
                                            varient="primary"
                                            handleClick={() => dispatch(setThemeColor(selectedColor))}
                                            class="py-2 px-3"
                                        />
                                    </DisplayEnd>
                                </Box>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <div className="specific-ip">
                    <Card className='boxShadow my-3'>
                        <Card.Body>
                            <Row>
                                <Col md={12}>
                                    <Box>
                                        <BodyText fontSize='1.5em' fontWeight='600' textColor='#333'> Gruppenspezifische IP-Sperre </BodyText>
                                    </Box>
                                    <Box mt="10px" mb="10px">
                                        <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Im Folgenden kannst du spezielle IP-Sperren für bestimmte Gruppen erstellen. Diese Einstellung überschreibt die oben angegebene allgemeine Einstellung für bestimmte Gruppen. Wenn ein Nutzer mehrere Gruppen hat, wird der Zugriff gewährt, wenn mindestens eine der Gruppen Zugriff hat. </BodyText>
                                    </Box>
                                    <Box mt="10px" mb="10px">
                                        <DisplayBetween>
                                            <ButtonWidget varient="primary" text="Einstellung hinzufügen" class='' handleClick={handleAddSetting} />
                                            <ButtonWidget varient="primary" text="Speichern" class='' />
                                        </DisplayBetween>
                                    </Box>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>

                {addSettingIp &&
                    <>
                        {[...Array(clickCount)].map((_, index) => (
                            <>
                                <div className="add-setting" key={index}>
                                    <Card className='boxShadow my-3'>
                                        <Card.Body>
                                            <Row>
                                                <Col md={5}>
                                                    <Box>
                                                        <InputTextControl
                                                            control={control}
                                                            fieldName={"firstName"}
                                                            error={errors.firstName}
                                                            placeholder="Ende IP-Adressbereich"
                                                            fieldType="text"
                                                            size={"sm"}
                                                        />
                                                    </Box>
                                                </Col>
                                                <Col md={3}>
                                                    <Box>
                                                        <Button className='default-btn f-12px' onClick={() => setRecipientModal(true)}><FaPlusCircle className='default-btn-icon' />Alle anzeigen</Button>
                                                    </Box>
                                                </Col>
                                                <Col md={4}>
                                                    <Box width="250px">
                                                        <DropdownButton size="sm" className="template-sort f-12px me-3" id="new-item" title="Zugriff von allen IPs aus erlauben">
                                                            {IpBlockingDropdown.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <Dropdown.Item key={index}>
                                                                            <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                                                        </Dropdown.Item>
                                                                    </>
                                                                )
                                                            })}
                                                        </DropdownButton>
                                                    </Box>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <Box>
                                                        {activeTab === 'admin' && (
                                                            <Box pb="10px" pt="10px">
                                                                <HighlightBox
                                                                    className="text-center"
                                                                    bgColor="#d9edf7"
                                                                    borderColor="#bce8f1"
                                                                    textColor="#31708f"
                                                                >
                                                                    Die selektive IP-Sperre ist derzeit aktiv. Nur die im oberen Bereich aufgelisteten IP-Adressen können auf folgende Bereiche zugreifen: Admin
                                                                </HighlightBox>
                                                            </Box>
                                                        )}
                                                        {activeTab === 'message' && (
                                                            <Box pb="10px" pt="10px">
                                                                <HighlightBox
                                                                    className="text-center"
                                                                    bgColor="#d9edf7"
                                                                    borderColor="#bce8f1"
                                                                    textColor="#31708f"
                                                                >
                                                                    Die selektive IP-Sperre ist derzeit aktiv. Nur die im oberen Bereich aufgelisteten IP-Adressen können auf folgende Bereiche zugreifen: Nachrichten
                                                                </HighlightBox>
                                                            </Box>
                                                        )}
                                                        {activeTab === 'news' && (
                                                            <Box pb="10px" pt="10px">
                                                                <HighlightBox
                                                                    className="text-center"
                                                                    bgColor="#d9edf7"
                                                                    borderColor="#bce8f1"
                                                                    textColor="#31708f"
                                                                >
                                                                    Die selektive IP-Sperre ist derzeit aktiv. Nur die im oberen Bereich aufgelisteten IP-Adressen können auf folgende Bereiche zugreifen: News
                                                                </HighlightBox>
                                                            </Box>
                                                        )}
                                                        {activeTab === 'ideas' && (
                                                            <Box pb="10px" pt="10px">
                                                                <HighlightBox
                                                                    className="text-center"
                                                                    bgColor="#d9edf7"
                                                                    borderColor="#bce8f1"
                                                                    textColor="#31708f"
                                                                >
                                                                    Die selektive IP-Sperre ist derzeit aktiv. Nur die im oberen Bereich aufgelisteten IP-Adressen können auf folgende Bereiche zugreifen: Ideen
                                                                </HighlightBox>
                                                            </Box>
                                                        )}
                                                        {activeTab === 'handbook' && (
                                                            <Box pb="10px" pt="10px">
                                                                <HighlightBox
                                                                    className="text-center"
                                                                    bgColor="#d9edf7"
                                                                    borderColor="#bce8f1"
                                                                    textColor="#31708f"
                                                                >
                                                                    Die selektive IP-Sperre ist derzeit aktiv. Nur die im oberen Bereich aufgelisteten IP-Adressen können auf folgende Bereiche zugreifen: Handbuch
                                                                </HighlightBox>
                                                            </Box>
                                                        )}
                                                        {activeTab === 'aufgaben' && (
                                                            <Box pb="10px" pt="10px">
                                                                <HighlightBox
                                                                    className="text-center"
                                                                    bgColor="#d9edf7"
                                                                    borderColor="#bce8f1"
                                                                    textColor="#31708f"
                                                                >
                                                                    Die selektive IP-Sperre ist derzeit aktiv. Nur die im oberen Bereich aufgelisteten IP-Adressen können auf folgende Bereiche zugreifen: Aufgaben
                                                                </HighlightBox>
                                                            </Box>
                                                        )}
                                                        {activeTab === 'calender' && (
                                                            <Box pb="10px" pt="10px">
                                                                <HighlightBox
                                                                    className="text-center"
                                                                    bgColor="#d9edf7"
                                                                    borderColor="#bce8f1"
                                                                    textColor="#31708f"
                                                                >
                                                                    Die selektive IP-Sperre ist derzeit aktiv. Nur die im oberen Bereich aufgelisteten IP-Adressen können auf folgende Bereiche zugreifen: Kalender
                                                                </HighlightBox>
                                                            </Box>
                                                        )}
                                                        {activeTab === 'repetation' && (
                                                            <Box pb="10px" pt="10px">
                                                                <HighlightBox
                                                                    className="text-center"
                                                                    bgColor="#d9edf7"
                                                                    borderColor="#bce8f1"
                                                                    textColor="#31708f"
                                                                >
                                                                    Die selektive IP-Sperre ist derzeit aktiv. Nur die im oberen Bereich aufgelisteten IP-Adressen können auf folgende Bereiche zugreifen: Reparaturen
                                                                </HighlightBox>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                    <Box pb="10px" pt="10px">
                                                        <HighlightBox
                                                            className="text-center"
                                                            bgColor="#fcf8e3"
                                                            borderColor="#faebcc"
                                                            textColor="#8a6d3b"
                                                        >
                                                            Die Teilsperre wird Listen und Detailansichten der ausgewählten Bereiche sperren. Wird der Admin-Bereich gesperrt, bleibt er ebenfalls für alle Änderungen geschlossen, die nicht von erlaubten Adressen aus statt finden.
                                                        </HighlightBox>
                                                    </Box>
                                                    <Box>
                                                        <Tabs
                                                            id="my-tabs"
                                                            activeKey={activeTab}
                                                            onSelect={handleTabSelect}
                                                        >
                                                            <Tab eventKey="admin" title={
                                                                <>
                                                                    <Box className="px-2">
                                                                        <DisplayCenter className="flex-column">
                                                                            <div>
                                                                                <FaCogs size="20px" className="gray-icon-color" />
                                                                            </div>
                                                                            <BodyText fontSize='14px' fontWeight='500' textColor='#333'> Admin </BodyText>
                                                                        </DisplayCenter>
                                                                    </Box>
                                                                </>
                                                            }>
                                                            </Tab>
                                                            <Tab eventKey="message" title={
                                                                <>
                                                                    <Box className="px-2">
                                                                        <DisplayCenter className="flex-column">
                                                                            <div>
                                                                                <FaClock size="20px" className="gray-icon-color" />
                                                                            </div>
                                                                            <BodyText fontSize='14px' fontWeight='500' textColor='#333'> Nachrichten </BodyText>
                                                                        </DisplayCenter>
                                                                    </Box>
                                                                </>
                                                            }>
                                                            </Tab>
                                                            <Tab eventKey="news" title={
                                                                <>
                                                                    <Box className="px-2">
                                                                        <DisplayCenter className="flex-column">
                                                                            <div>
                                                                                <HiChatBubbleBottomCenterText size="20px" className="gray-icon-color" />
                                                                            </div>
                                                                            <BodyText fontSize='14px' fontWeight='500' textColor='#333'> News </BodyText>
                                                                        </DisplayCenter>
                                                                    </Box>
                                                                </>
                                                            }>
                                                            </Tab>
                                                            <Tab eventKey="ideas" title={
                                                                <>
                                                                    <Box className="px-2">
                                                                        <DisplayCenter className="flex-column">
                                                                            <div>
                                                                                <HiChatBubbleBottomCenterText size="20px" className="gray-icon-color" />
                                                                            </div>
                                                                            <BodyText fontSize='14px' fontWeight='500' textColor='#333'> Ideen </BodyText>
                                                                        </DisplayCenter>
                                                                    </Box>
                                                                </>
                                                            }>
                                                            </Tab>
                                                            <Tab eventKey="handbook" title={
                                                                <>
                                                                    <Box className="px-2">
                                                                        <DisplayCenter className="flex-column">
                                                                            <div>
                                                                                <img width="20" src="/images/diaryGray.png" />
                                                                            </div>
                                                                            <BodyText fontSize='14px' fontWeight='500' textColor='#333'> Handbuch </BodyText>
                                                                        </DisplayCenter>
                                                                    </Box>
                                                                </>
                                                            }>
                                                            </Tab>
                                                            <Tab eventKey="aufgaben" title={
                                                                <>
                                                                    <Box className="px-2">
                                                                        <DisplayCenter className="flex-column">
                                                                            <div>
                                                                                <img width="20" src="/images/shopping-bag.png" />
                                                                            </div>
                                                                            <BodyText fontSize='14px' fontWeight='500' textColor='#333'> Aufgaben </BodyText>
                                                                        </DisplayCenter>
                                                                    </Box>
                                                                </>
                                                            }>
                                                            </Tab>
                                                            <Tab eventKey="calender" title={
                                                                <>
                                                                    <Box className="px-2">
                                                                        <DisplayCenter className="flex-column">
                                                                            <div>
                                                                                <FaRegCalendarAlt size="20px" className="gray-icon-color" />
                                                                            </div>
                                                                            <BodyText fontSize='14px' fontWeight='500' textColor='#333'> Kalender </BodyText>
                                                                        </DisplayCenter>
                                                                    </Box>
                                                                </>
                                                            }>
                                                            </Tab>
                                                            <Tab eventKey="repetation" title={
                                                                <>
                                                                    <Box className="px-2">
                                                                        <DisplayCenter className="flex-column">
                                                                            <div>
                                                                                <img width="20" src="/images/working.png" />
                                                                            </div>
                                                                            <BodyText fontSize='14px' fontWeight='500' textColor='#333'> Reparaturen </BodyText>
                                                                        </DisplayCenter>
                                                                    </Box>
                                                                </>
                                                            }>
                                                            </Tab>
                                                        </Tabs>
                                                    </Box>
                                                    <Box mt="15px" mb="10px">
                                                        <DisplayBetween>
                                                            <ButtonWidget varient="danger" text="Löschen" class='' handleClick={() => handleDeleteIpBlocking()} />
                                                            <ButtonWidget varient="primary" text="Speichern" class='' />
                                                        </DisplayBetween>
                                                    </Box>
                                                    {/* <Box>
                                        <div className="d-flex align-items-center flex-wrap me-2">
                                            <Box className="border rounded-3 cursor-pointer me-2 mb-2">
                                                <div className="icon-box px-4">
                                                    <FaCogs size="20px" />
                                                    <span className="icon-title"> Admin </span>
                                                </div>
                                            </Box>
                                            <Box className="border rounded-3 cursor-pointer me-2 mb-2">
                                                <div className="icon-box px-4">
                                                    <FaClock size="20px" />
                                                    <Box className="icon-title">Nachrichten</Box>
                                                </div>
                                            </Box>
                                            <Box className="border rounded-3 cursor-pointer me-2 mb-2">
                                                <div className="icon-box px-4">
                                                    <HiChatBubbleBottomCenterText size="20px" />
                                                    <span className="icon-title">News</span>
                                                </div>
                                            </Box>
                                            <Box className="border rounded-3 cursor-pointer me-2 mb-2">
                                                <div className="icon-box px-4">
                                                    <HiChatBubbleBottomCenterText size="20px" />
                                                    <span className="icon-title">News</span>
                                                </div>
                                            </Box>
                                            <Box className="border rounded-3 cursor-pointer me-2 mb-2">
                                                <div className="icon-box px-4">
                                                    <HiLightBulb size="20px" className="gray-color" />
                                                    <span className="icon-title">Ideen</span>
                                                </div>
                                            </Box>
                                            <Box className="border rounded-3 cursor-pointer me-2 mb-2">
                                                <div className="icon-box px-4">
                                                    <img width="20" src="/images/diaryGray.png" />
                                                    <span className="icon-title">Handbuch</span>
                                                </div>
                                            </Box>
                                            <Box className="border rounded-3 cursor-pointer me-2 mb-2">
                                                <div className="icon-box px-4">
                                                    <img width="20" src="/images/shopping-bag.png" />
                                                    <span className="icon-title">Aufgaben</span>
                                                </div>
                                            </Box>
                                            <Box className="border rounded-3 cursor-pointer me-2 mb-2">
                                                <div className="icon-box px-4">
                                                    <FaRegCalendarAlt size="20px" />
                                                    <span className="icon-title">Kalender</span>
                                                </div>
                                            </Box>
                                            <Box className="border rounded-3 cursor-pointer me-2 mb-2">
                                                <div className="icon-box px-4">
                                                    <img width="20" src="/images/working.png" />
                                                    <span className="icon-title">Reparaturen</span>
                                                </div>
                                            </Box>
                                        </div>
                                    </Box> */}
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </>
                        ))}
                    </>
                }

                <Recipient displayRecipientModal={RecipientModal} hideRecipientModal={() => setRecipientModal(false)} />
            </div>
        </>
    );
}

export default IPManager;