import * as React from 'react';
import Button from 'react-bootstrap/Button';
import { CustomButton } from './StyledComponents';

interface type {
    varient: string,
    text: string | JSX.Element,
    handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    class?: string,
    Id?: string,
    bgColor?: string,
    borderColor?: string,
    bgHoverColor?: string,
    textColor?: string,
    textColorHover?: string,
    disabled?: boolean,
}

function ButtonWidget(props: type) {
    return (
        <>
            {props.varient === 'light' ?
                <>
                    {!!props.handleClick ?
                        <Button className={`default-btn f-14px ${props.class}`} onClick={props.handleClick} id={props.Id}>
                            {props.text}
                        </Button>
                        :
                        <Button className={`default-btn f-14px ${props.class}`} disabled={props.disabled} id={props.Id}>
                            {props.text}
                        </Button>
                    }
                </>
                :
                null
            }
            {props.varient === 'primary' ?
                <>
                    {!!props.handleClick ?
                        <Button className={`primary-bg f-14px ${props.class}`} onClick={props.handleClick} disabled={props.disabled} id={props.Id}>
                            {props.text}
                        </Button>
                        :
                        <Button className={`primary-bg f-14px ${props.class}`} disabled={props.disabled} id={props.Id}>
                            {props.text}
                        </Button>
                    }
                </>
                :
                null
            }
            {props.varient === 'danger' ?
                <>
                    {!!props.handleClick ?
                        <Button variant="danger" className={`f-14px ${props.class}`} onClick={props.handleClick} disabled={props.disabled} id={props.Id}>
                            {props.text}
                        </Button>
                        :
                        <Button variant="danger" className={`f-14px ${props.class}`} disabled={props.disabled} id={props.Id}>
                            {props.text}
                        </Button>
                    }
                </>
                :
                null
            }
            {props.varient === 'custom' ?
                <>
                    {!!props.handleClick ?
                        <CustomButton
                            id={props.Id}
                            className={`f-14px ${props.class}`}
                            onClick={props.handleClick}
                            textColor={props.textColor}
                            textColorHover={props.textColorHover}
                            bgColor={props.bgColor}
                            borderColor={props.borderColor}
                            bgHoverColor={props.bgHoverColor}
                            disabled={props.disabled}
                        >
                            {props.text}
                        </CustomButton>
                        :
                        <CustomButton
                            id={props.Id}
                            className={`f-14px ${props.class}`}
                            textColor={props.textColor}
                            textColorHover={props.textColorHover}
                            bgColor={props.bgColor}
                            borderColor={props.borderColor}
                            bgHoverColor={props.bgHoverColor}
                            disabled={props.disabled}>
                            {props.text}
                        </CustomButton>
                    }
                </>
                :
                null
            }
        </>
    );
}

export default ButtonWidget;