import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function ResetModal(props: any) {

    return (
        <>
            <Modal size="lg" show={props.showReset} onHide={props.hideReset} backdrop="static" keyboard={false} className='shadow-modal' aria-labelledby="ResetModal" centered>
                <Modal.Header closeButton className=''>
                    <Modal.Title id="ResetModal">
                        Auf Werkseinstellungen zurücksetzen
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='m-0'>
                        Möchtest du wirklich die Ansicht auf die Standardeinstellungen zurücksetzen? Alle getroffenen Einstellungen gehen dabei verloren.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='button-light f-14px me-1' onClick={props.hideReset}>Abbrechen</Button>
                    <Button className='button-red f-14px' >Meine Ansicht zurücksetzen</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ResetModal;