import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import FormControl from '../../FormControl';
import { BodyText, Box, DisplayBetween, DisplayEnd, DisplayStart } from '../../StyledComponents';
import { FaRegFileImage } from 'react-icons/fa';
import ButtonWidget from '../../ButtonWidget';


export const AttachmentOptionModalContent = () => {

    const [attachmentBtn, setAttachmentBtn] = useState<boolean>(false);

    const handleAttchmentBtn = () => {
        setAttachmentBtn(!attachmentBtn)
    }

    return (
        <>
            <Row>
                <Col md='12' className='p-2'>
                    <DisplayBetween>
                        <Box>
                            <DisplayStart>
                                <FaRegFileImage className='me-2' size={16} />
                                {attachmentBtn ?
                                    <BodyText fontWeight='400' textColor='#666' fontSize='14px'> 6.png </BodyText>
                                    :
                                    <FormControl Type='text' Size='sm' Placeholder='Attachment Link' Name='attachmentLink' Class='dd' />
                                }
                            </DisplayStart>
                        </Box>
                        <Box onClick={handleAttchmentBtn}>
                            {attachmentBtn ?
                                <ButtonWidget
                                    varient='light'
                                    text={'Umbenennen'}
                                    class='remove-checkList-btn'
                                />
                                :
                                <DisplayStart>
                                    <ButtonWidget
                                        varient='light'
                                        text={'Abbrechen'}
                                        class='me-2'
                                    />
                                    <ButtonWidget
                                        varient='primary'
                                        text={'Speichern'}
                                    />
                                </DisplayStart>
                            }

                        </Box>
                    </DisplayBetween>
                </Col>
            </Row>
        </>
    )
}