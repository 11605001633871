export class UserProfileDto {
    userId: number = 0;
    salutationId: number = 0;
    firstName: string = "";
    lastName: string = "";
    position: string = "";
    department: string = "";
    phoneNumber: string = "";
    gender: string = "";
    dob: Date = new Date();
    entryDate: Date = new Date();
    email: string = "";
    showEmail: boolean = false;
    userImage: string = "";
    practiceIds?: number[] = [];
    groupIds?: number[] = [];
}