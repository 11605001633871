import { useEffect, useState, FC, Dispatch, SetStateAction, MouseEvent, } from "react";
import { TopbarSelectFilterDropdown } from "../../Components/TopbarSelectFilterDropdown"
import { Card, Table } from "react-bootstrap";
import FormCheck from "../../Components/FormCheck";
import CustomTooltip from "../../Components/Tooltip";
import { FaBookmark, FaLock, FaLongArrowAltRight, FaStar } from "react-icons/fa";
import BadgeWidget from "../../Components/BadgeWidget";
import { HiOutlineLockClosed, HiOutlinePaperClip } from "react-icons/hi";
import { BodyText, CustomTr, DisplayEnd } from "../../Components/StyledComponents";
import CommonModal from "../../Components/Modals/CommonModal";
import { SendReminderModalContent } from "../../Components/Modals/ModelContents/SendReminderModalContent";
import ButtonWidget from "../../Components/ButtonWidget";
import { INewsData } from "./NewsIndex";
import { useNavigate } from "react-router-dom";
import { TdisplayMode } from "../Message/Index";


interface IProps {
    listData: INewsData[],
    setListData: Dispatch<SetStateAction<INewsData[]>>,
    displayMode: TdisplayMode,
    showChecks: boolean,
    setCheckCount: Dispatch<SetStateAction<number>>
}

export const NewsList: FC<IProps> = ({ listData, setListData, displayMode, showChecks, setCheckCount }) => {
    const navigate = useNavigate();
    const [sendReminder, setSendReminder] = useState<boolean>(false);


    const HandleCheckbox = (index: number, event: MouseEvent<HTMLInputElement>) => {
        debugger
        let temp = [...listData];
        var count: number = 0;
        temp[index].isChecked = !temp[index].isChecked;

        for (var i = 0; i < listData.length; i++) {
            if (listData[i].isChecked === true) {
                count++;
            }
        }
        setCheckCount(count);
        setListData(temp);
    }
    const handleRowClick = (event: MouseEvent<HTMLElement>) => {
        navigate('/news/news-detial');
    }
    const handleClickGraph = (event: React.MouseEvent<HTMLTableCellElement>) => {
        event.stopPropagation();
        setSendReminder(true);
    }

    return (
        <>
            <div className="news-sec">
                <div className="table-news">
                    <Card className='boxShadow'>
                        <Card.Body>
                            <Table responsive hover className='mb-0'>
                                <tbody>
                                    {listData.map((item, index) => {
                                        return (
                                            <>
                                                <CustomTr key={index} BgColor={item.recordBg} onClick={(event: MouseEvent<HTMLElement>) => handleRowClick(event)} className="cursor-pointer">
                                                    {showChecks ?
                                                        <td width='20'>
                                                            <FormCheck
                                                                type='checkbox'
                                                                name='phone'
                                                                Id='phone'
                                                                value={item.title}
                                                                label=''
                                                                isFormGroup={false}
                                                                isInline={true}
                                                                checked={item.isChecked}
                                                                handleClick={(event: MouseEvent<HTMLInputElement>) => {
                                                                    HandleCheckbox(index, event);
                                                                    event.stopPropagation();
                                                                }}
                                                            />
                                                        </td>
                                                        : null}
                                                    <td>
                                                        <div>
                                                            <BodyText fontSize="1.1em" fontWeight="700" textColor="#000">{item.title}</BodyText>
                                                        </div>

                                                        {displayMode.displayNormal ?
                                                            <>
                                                                <div className='d-flex align-items-center'>
                                                                    <BadgeWidget outiline={true} title={item.badgeOutlineText} color={item.badgeOutlineColor} bgColor={item.badgeOutlineBg} />
                                                                    <div>
                                                                        <CustomTooltip
                                                                            ToolTipTrigger={<BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'> {item.userName1} </BodyText>}
                                                                            toolTipContent={item.userNameTooltipContent1}
                                                                            toolTipPlacement='top'
                                                                        />
                                                                    </div>
                                                                    <FaLongArrowAltRight className='mx-2' />
                                                                    <div className='d-flex align-items-center'>
                                                                        <BodyText mb='0px' fontSize="12px" fontWeight="400" pl='5px' textColor='#666'> {item.userName2} </BodyText>
                                                                        {!!item.badgeSolidTitle1 ?
                                                                            <>
                                                                                <CustomTooltip
                                                                                    ToolTipTrigger={<BadgeWidget solid={true} title={item.badgeSolidTitle1} color={item.badgeSolidColor1} bgColor={item.badgeSolidBg1} />}
                                                                                    toolTipContent={item.badgeSolidTooltipContent1}
                                                                                    toolTipPlacement='top'
                                                                                    contentAlign='center'
                                                                                />
                                                                            </>
                                                                            : null}
                                                                        {!!item.badgeSolidTitle2 ?
                                                                            <>
                                                                                <span className="mx-1">,</span>
                                                                                <CustomTooltip
                                                                                    ToolTipTrigger={<BadgeWidget solid={true} title={item.badgeSolidTitle2} color={item.badgeSolidColor2} bgColor={item.badgeSolidBg2} />}
                                                                                    toolTipContent={item.badgeSolidTooltipContent2}
                                                                                    toolTipPlacement='top'
                                                                                    contentAlign='center'
                                                                                />
                                                                            </>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null}

                                                    </td>
                                                    <td width='50'>
                                                        <div className='d-flex align-items-center justify-content-start mt-1'>
                                                            <div className='mx-2'>
                                                                {item.limitedVisibilty ?
                                                                    <>
                                                                        <CustomTooltip
                                                                            ToolTipTrigger={<HiOutlineLockClosed size={16} />}
                                                                            toolTipContent={item.limitedVisibiltyTooltipContent}
                                                                            toolTipPlacement='top'
                                                                        />
                                                                    </>
                                                                    : null}
                                                            </div>
                                                            <div className=''>
                                                                {item.noticed ?
                                                                    <CustomTooltip
                                                                        ToolTipTrigger={<FaBookmark size={16} />}
                                                                        toolTipContent={item.noticedTooltipContent}
                                                                        toolTipPlacement='top'
                                                                    />
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td width='150'>
                                                        <BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'>{item.dateTime}</BodyText>
                                                    </td>
                                                    <td width='50'>
                                                        <div className='d-flex align-items-center justify-content-center mt-1' onClick={(event: React.MouseEvent<HTMLTableCellElement>) => handleClickGraph(event)}>
                                                            <CustomTooltip
                                                                ToolTipTrigger={<img src={item.image} />}
                                                                toolTipContent={item.imageTooltipContent}
                                                                toolTipPlacement='top'
                                                            />
                                                        </div>
                                                    </td>
                                                </CustomTr>
                                            </>
                                        )
                                    })}
                                </tbody>

                            </Table>
                        </Card.Body>
                    </Card>

                </div>

            </div>
            <CommonModal
                showModal={sendReminder}
                hideModal={() => setSendReminder(false)}
                modalTitle='Erinnerung senden'
                modalContent={<SendReminderModalContent />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Schließen'}
                                class='me-2 ms-3'
                                handleClick={() => setSendReminder(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Ausgewählte erinnem'}
                                disabled={true}
                            />
                        </DisplayEnd>
                    </>
                }
                footerClass={"footer-sticky"}
            />
        </>
    )
}