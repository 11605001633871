import React, { useState } from 'react';
import NewTreeView from '../../../TreeView';
import CommonList from '../../../CommonList';
import WidgetTreeView from '../../../WidgetTreeView';

type listType = {
   title: string,
   isActive: boolean,
}
const listData: listType[] = [
   { title: 'Assistenz', isActive: false },
   { title: 'Hygiene', isActive: false },
   { title: 'Prophylaxe', isActive: false },
   { title: 'Qualitätsmanagement', isActive: false },
   { title: 'Sonstiges', isActive: false },
   { title: 'Verwaltung', isActive: false },
   { title: 'Zahnärzte', isActive: false },
]



const jsonData = [
   {
      id: 1,
      label: 'Assistenz',
      isActive: false
   },
   {
      id: 2,
      label: 'Hygiene',
      isActive: false
   },
   {
      id: 3,
      label: 'Prophylaxe',
      isActive: false
   },
   {
      id: 4,
      label: 'Qualitätsmanagement',
      isActive: false
   },
   {
      id: 5,
      label: 'Sonstiges',
      isActive: false
   },
   {
      id: 6,
      label: 'Verwaltung',
      isActive: false
   },
   {
      id: 7,
      label: 'Zahnärzte',
      isActive: false
   },
];

function ShowAllListModalContent() {
   return (
      <>
         {/* <CommonList listData={listData} /> */}
         <WidgetTreeView treeData={jsonData} />
      </>
   );
}

export default ShowAllListModalContent;