import { React, useState } from 'react';
import { FaCaretRight } from "react-icons/fa";


function TreeView(props) {

    return (
        <>
            <ul className='ps-0'>
                {props.treeData.map((node) => (
                    <TreeNode node={node} key={node.key} isLast={node.isLast} />
                ))}
            </ul>
        </>
    );
}

function TreeNode({ node }) {
    const { children, label } = node;
    const [showChildren, setShowChildren] = useState(false);

    const handleClick = () => {
        debugger
        if (node.isLast === false) {
            setShowChildren(!showChildren);
        }
        console.log(node);
        console.log(showChildren);
    };

    return (
        <>
            <div onClick={handleClick} style={{ marginBottom: "10px" }}>
                {node.isLast === false ? <FaCaretRight /> : ""}
                <span>{label}</span>
            </div>
            <ul style={{ paddingLeft: "10px", borderLeft: "1px solid black" }}>
                {showChildren && <Tree treeData={children} />}
            </ul>
        </>
    );
}

function Tree({ treeData }) {
    return (
        <ul>
            {treeData.map((node) => (
                <TreeNode node={node} key={node.key} />
            ))}
        </ul>
    );
}


export default TreeView;