import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { SideBar } from './SideBar';
import { Outlet } from 'react-router-dom';

const Index = () => {
    return (
        <>
            <Row>
                <Col md={3}>
                    <SideBar />
                </Col>
                <Col md={9}>
                    <Outlet />
                </Col>
            </Row>
        </>
    )
}
export default Index;