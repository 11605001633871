import React, { FC } from 'react';
import { AlertBox } from './StyledComponents';

interface IAlertWidgetProps {
    text: string,
    variant: string
}

export const AlertWidget: FC<IAlertWidgetProps> = ({ text, variant }) => {
    return (
        <>
            {variant === 'danger' ?
                <AlertBox bgcolor='#f2dede' textColor='#a94442' borderColor='#ebccd1'>
                    <span>{text}</span>
                </AlertBox>
                :
                null
            }
            {variant === 'warning' ?
                <AlertBox bgcolor='#fcf8e3' textColor='#8a6d3b' borderColor='#faebcc'>
                    <span>{text}</span>
                </AlertBox>
                :
                null
            }
            {variant === 'success' ?
                <AlertBox bgcolor='#dff0d8' textColor='#3c763d' borderColor='#d6e9c6'>
                    <span>{text}</span>
                </AlertBox>
                :
                null
            }
        </>
    );
}
