import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Row, Col, InputGroup, } from 'react-bootstrap';
import Recipient from '../../../Components/Modals/Recipient';
import DateTimePicker from '../../../Components/DateTimePicker';
import ViewRecipient from '../../../Components/ViewRecipient';
import { Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider, ImageBannerWrap } from '../../../Components/StyledComponents';
import FormSelect from '../../FormSelect';
import { HiOutlineSwitchVertical, HiX } from "react-icons/hi";
import ButtonWidget from '../../ButtonWidget';
import { FaPlusCircle, FaQuestionCircle } from 'react-icons/fa';
import CustomTooltip from '../../Tooltip';
import BadgeWidget from '../../BadgeWidget';
import HomeBanner from '../../../Pages/Home/HomeBanner';
import { CategoryDateJson, CategoryImages, categoryOptions, showDateOption } from '../../../CommonJSON';



export const ShowHomeScreenModalContent = () => {


    // State for modals Begin
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    // State for modals End

    // State for Hide Show Begin
    const [showFor, setShowFor] = useState<boolean>(false);
    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const [chooseImage, setChooseImage] = useState<boolean>(false);
    const [categoryImageData, setCategoryImageData] = useState<CatagoryType[]>(CategoryImages);
    const [showCategoryDate, setShowCategoryDate] = useState<CatagoryDateType[]>(CategoryDateJson);
    const [isActive, setIsActive] = useState<boolean>(false);




    const [categoryDate, setCategoryDate] = useState<string>('');
    const [Category, setCategory] = useState<string>('');

    const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        debugger;
        const value: string = event.target.value;
        setCategory(value);
        var tempDate = [...categoryImageData];

        for (var i = 0; i < tempDate.length; i++) {
            tempDate[i].isSelect = false;
        }

        const foundObject = tempDate.find((obj) => obj.category === value);
        if (!!foundObject) {
            foundObject.isSelect = true;
        }

        const newArray: any = tempDate.map((obj) => {
            if (obj.category === value) {
                return foundObject
            } else {
                return obj;
            }
        });
        setCategoryImageData(newArray);
    };

    const handleCategoryDate = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value: string = event.target.value;
        setCategoryDate(value);
        var temp = [...showCategoryDate];

        for (var i = 0; i < temp.length; i++) {
            temp[i].isSelect = false;
        }
        const foundObject = temp.find((obj) => obj.date === value);
        if (!!foundObject) {
            foundObject.isSelect = true;
        }

        const newArray: any = temp.map((obj) => {
            if (obj.date === value) {
                return foundObject
            } else {
                return obj;
            }
        });
        setShowCategoryDate(newArray);
    }

    const handleActiveColor = () => {
        setIsActive(!isActive);
        // setIsActive((prevIsActive) => !prevIsActive);
    }

    return (
        <>
            <Box>
                <DisplayBetween width='500px'>
                    <Form.Label className='mb-0 me-1'> Hintergrundbild: </Form.Label>
                    <Form.Group controlId="">
                        <Form.Check
                            inline
                            label="Vordefiniertes wählen"
                            name="group1"
                            type="radio"
                            id="public"
                            onChange={() => { setChooseImage(true); setUploadImage(false) }}
                        />
                        <Form.Check
                            inline
                            label="Bild hochladen"
                            name="group1"
                            type="radio"
                            id="toalluser"
                            onChange={() => { setChooseImage(false); setUploadImage(true) }}
                        />
                    </Form.Group>
                </DisplayBetween>
            </Box>
            {chooseImage ?
                <>
                    <DisplayBetween width='370px'>
                        <Form.Label className='mb-0 me-1'> Kategorie: </Form.Label>
                        <div className="mt-2">
                            <FormSelect
                                isFormGroup={true}
                                selectOptions={categoryOptions}
                                Size='sm'
                                Name='voices'
                                Id='voices'
                                handleChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleCategoryChange(event)}
                            />
                        </div>
                    </DisplayBetween>

                    {Category ?
                        <Row style={{ overflowY: 'scroll', height: '275px', overflowX: 'hidden', }}>
                            {categoryImageData.map((item, index) => {
                                return (
                                    <>
                                        {item.isSelect === true ?
                                            <>
                                                {item.images.map((items, i) => {
                                                    return (
                                                        <>
                                                            <Col md="6" key={index}>
                                                                <Box>
                                                                    <ImageBannerWrap key={i} className={isActive ? 'active' : ''} onClick={handleActiveColor}>
                                                                        <img src={items.imageSrc} width={'360px'} height={'125px'} alt="home Screen banner" />
                                                                    </ImageBannerWrap>
                                                                </Box>
                                                            </Col>
                                                        </>
                                                    )
                                                })}
                                            </>
                                            : null}


                                    </>
                                )
                            })}
                        </Row>
                        : null}

                </>
                : null}
            {uploadImage ?
                <>
                    <DisplayCenter>
                        <div className="me-4 mt-2">
                            <ButtonWidget
                                varient='light'
                                text={
                                    <span> Hochladen </span>
                                }
                            />
                        </div>
                    </DisplayCenter>
                </>
                : null}
            <Divider mt="10px" mb="10px" />
            <Row className='mt-3'>
                <Col md={12}>
                    <Form.Group className="mb-3" controlId="">
                        <div className="mb-3">
                            <Form.Control size="sm" type="text" placeholder="Titelzeile (leer lassen um Titel des Eintrags zu verwenden)" />
                        </div>
                        <div className="mb-3">
                            <Form.Control as="textarea" placeholder="Untertitel (leer lassen um Textauszug aus Eintrag zu verwenden)" rows={5} />
                        </div>
                    </Form.Group>
                </Col>
                <Divider mt="8px" mb="8px" />
            </Row>
            <Box>
                <Divider mt="10px" mb="10px" />
                <Row>
                    <Col md={2}>
                        <Form.Label className='mb-0 me-1'> Kategorie: </Form.Label>
                    </Col>
                    <Col md={3}>
                        <FormSelect
                            isFormGroup={true}
                            selectOptions={showDateOption}
                            Size='sm'
                            Name='voices'
                            Id='voices'
                            handleChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleCategoryDate(event)}
                        />
                    </Col>
                    <Col md={3}>
                        {categoryDate ?
                            <DateTimePicker />
                            : null}
                    </Col>
                </Row>
                <Divider mt="10px" mb="10px" />
                <Row>
                    <Col md={2}>
                        <Form.Label className='mb-0 me-1'> Zeige für: </Form.Label>
                    </Col>
                    <Col md={3}>
                        <Form.Check
                            inline
                            label="Empfänger"
                            name="group2"
                            type="radio"
                            onChange={() => { setShowFor(true) }}
                        />
                        {showFor ?
                            <>
                                <DisplayStart mt="8px">
                                    <div className=''>
                                        <CustomTooltip
                                            ToolTipTrigger={<BadgeWidget solid={true} title={'Verwaltung'} color='#1e6c7b' bgColor={'#d4f2f7'} />}
                                            toolTipContent={'Alicia Gojani, Nadine Häring'}
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </div>
                                    <div className='mx-1'>
                                        <span>,</span>
                                    </div>
                                    <div className=''>
                                        <CustomTooltip
                                            ToolTipTrigger={<BadgeWidget solid={true} title={'Zahnärzte'} color='#1e6c7b' bgColor={'#d4f2f7'} />}
                                            toolTipContent={'Dr. Sven Markus Gallenbach, ZA Robert Stühn'}
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </div>
                                </DisplayStart>
                            </>
                            : null}
                    </Col>
                    <Col md={4}>
                        <Form.Check
                            inline
                            label="Alle mit Leseberechtigung"
                            name="group2"
                            type="radio"
                            onChange={() => { setShowFor(false) }}
                        />
                    </Col>
                </Row>
                <Divider mt="10px" mb="10px" />
            </Box>
            <Row>
                <Col md={12} xs={12}>
                    <DisplayStart>
                        <Form.Label className='mb-0 me-1'> Vorschau: </Form.Label>
                        <div className=''>
                            <CustomTooltip
                                ToolTipTrigger={<FaQuestionCircle />}
                                toolTipContent={'Diese Vorschau ist eventuell nicht für alle Nutzer zutreffend, da die Breite der Box von der jeweiligen Bildschirmbreite abhängig ist, die von Gerät zu Gerät variiert.'}
                                toolTipPlacement='top'
                                contentAlign='center'
                            />
                        </div>
                    </DisplayStart>
                    <HomeBanner />
                </Col>
            </Row>

            <Recipient
                displayRecipientModal={RecipientModal}
                hideRecipientModal={() => setRecipientModal(false)}
            />
        </>
    )
}