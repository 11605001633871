import React, { useState, useEffect, ChangeEvent } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Row, Col, InputGroup, } from 'react-bootstrap';
import Recipient from '../../../Components/Modals/Recipient';
import DateTimePicker from '../../../Components/DateTimePicker';
import ViewRecipient from '../../../Components/ViewRecipient';
import { BodyText, Box, DisplayBetween, DisplayEnd, DisplayStart, Divider } from '../../../Components/StyledComponents';
import FormSelect from '../../FormSelect';
import { HiOutlineSwitchVertical, HiX } from "react-icons/hi";
import ButtonWidget from '../../ButtonWidget';
import { FaPlusCircle } from 'react-icons/fa';


interface questionType {
    emailAddress: string,
}


export const SendEmailModalContent = (props: any) => {

    //State for Checklist Question Begin
    const [ChecklistQuestions, setChecklistQuestions] = useState<questionType[]>([]);
    //State for Checklist Question End




    useEffect(() => {
        let newQuestion = {
            emailAddress: '',
        }
        setChecklistQuestions([...ChecklistQuestions, newQuestion]);
    }, []);

    const addOneQuestion = () => {
        let newQuestion = {
            emailAddress: '',
        }
        setChecklistQuestions([...ChecklistQuestions, newQuestion]);
    }

    const removeOneQuestion = (index: number) => {
        if (index != -1) {
            ChecklistQuestions.splice(index, 1);
        }
        setChecklistQuestions([...ChecklistQuestions])
    }
    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        let temp = [...ChecklistQuestions];
        temp[index].emailAddress = event.target.value;
        setChecklistQuestions(temp);
    }

    return (
        <>
            <Row>
                <Col md={12} className='mt-3'>

                    {ChecklistQuestions.map((element, index) => (
                        <>
                            <Box>
                                <InputGroup size="sm" className="mb-1" id={`${"button-sort-" + index}`}>
                                    <Form.Control
                                        aria-label={`${"Check list Queastion no" + index}`}
                                        aria-describedby="basic-addon1"
                                        placeholder='E-Mail-Adresse'
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleEmailChange(event, index)}
                                    />
                                    {index === ChecklistQuestions.length - 1 ?
                                        <ButtonWidget
                                            varient='light'
                                            text={
                                                <span><FaPlusCircle className='default-btn-icon' /> Weitere E-Mail-Adresse hinzufügen </span>
                                            }
                                            handleClick={() => addOneQuestion()}
                                        />
                                        : null}
                                    {ChecklistQuestions.length > 1 ?
                                        <>
                                            < Button variant="secondary" id="button-close-" onClick={() => removeOneQuestion(index)}>
                                                <HiX size="20px" />
                                            </Button>
                                        </>
                                        : null
                                    }
                                </InputGroup>
                            </Box >
                        </>
                    )
                    )}
                </Col>
                <Col md={12}>
                    <Form.Group className="my-2" controlId="">
                        <Form.Control size="sm" type="text" placeholder="Betreff" />
                    </Form.Group>
                </Col>
            </Row >
            <Divider mt="12px" mb="12px" />
            <Row className='mt-3'>
                <Col md={12}>
                    <Box>
                        <Form.Control as="textarea" placeholder="" rows={5} />
                    </Box>
                    <Divider mt="12px" mb="12px" />
                    <BodyText mb='0px' fontSize='13px' fontWeight='400' textColor='#000'> Mit anhängen: </BodyText>
                </Col>
            </Row>
        </>
    )
}