import React from 'react';
import Card from 'react-bootstrap/Card';
import { FaHome } from 'react-icons/fa';
import { NavLink } from "react-router-dom";

export const SideBar = () => {
    return (
        <>
            <Card className='boxShadow border-0 rounded-0'>
                <Card.Header className='bg-white d-flex justify-content-center align-items-center'>
                    <Card.Title className='mb-0'>
                        <FaHome className='me-2 gray-icon-color' size="25px" />
                    </Card.Title>
                    <Card.Title className='text-uppercase mb-0 gray-color'>Infos</Card.Title>
                </Card.Header>
                <Card.Body className='p-0'>
                    <div className='pt-2'>
                        <ul className='list-unstyled ps-4 info-list'>
                            <li className='pt-1 pb-1 border-left-item'>
                                <NavLink to="tips" className='text-uppercase'>Tipps</NavLink>
                            </li>
                            <li className='pt-1 pb-1 border-left-item'>
                                <NavLink to="updates" className='text-uppercase'>Updates</NavLink>
                            </li>
                            <li className='pt-1 pb-1 border-left-item'>
                                <NavLink to="tutorialVideos" className='text-uppercase'>Tutorial Videos</NavLink>
                            </li>
                            <li className='pt-1 pb-1 border-left-item'>
                                <NavLink to="tutorialVideos" className='text-uppercase'>Trainings</NavLink>
                            </li>
                            {/* <li class="sub">
                                <input type="checkbox" />
                                <a class="" href="javascript:;" title="Unser Produktsortiment">Meine</a>
                                <ul class="submenu">
                                    <li><a href="javascript:;" title="Von mir">Von mir</a></li>
                                    <li><a href="javascript:;" title="An mich">An mich</a></li>
                                    <li><a href="javascript:;" title="Mit mir">Mit mir</a></li>
                                    <li><a href="javascript:;" title="Kommentiert">Kommentiert</a></li>
                                    <li><a href="javascript:;" title="Unveröffentlich">Unveröffentlicht</a></li>
                                </ul>
                            </li> */}
                        </ul>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}