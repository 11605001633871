import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateBox, DateIcon } from './StyledComponents';
import { GoCalendar } from "react-icons/go";
function SelectDate() {
    const [startDate, setStartDate] = useState<any>(new Date());
    return (
        <DateBox>
            <DatePicker
                className='form-control form-control-sm'
                dateFormat="d-MM-yyyy"
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
            />
            <DateIcon>
                <GoCalendar size={15}/>
            </DateIcon>
        </DateBox>
    );
}

export default SelectDate;
