import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { BodyText, Box } from '../../../Components/StyledComponents';
import Moments from '../../Home/Moments';

export const MainContentDetail = () => {

    const [key, setKey] = useState('home');

    return (
        <>
            <div className='InformationTabs'>
                <Tabs
                    id="InformationTabs"
                    activeKey={key}
                    onSelect={(k: any) => setKey(k)}
                    className="mb-3"

                >
                    <Tab eventKey="home" title="Aktivitäten">
                        <Card className='boxShadow border-0 rounded-0'>
                            <Card.Body>
                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab eventKey="contact" title="Moments">
                        <Card className='boxShadow border-0 rounded-0'>
                            <Card.Body>
                                <div className='my-4'>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <div className='profile-img mx-auto text-center'>
                                                <img src='/images/assets/MomentsPosts_desktop.png' height="300" width="300" alt="user-image" className='' />
                                            </div>
                                        </Col>
                                        <Col md={6} sm={12} className='align-self-center'>
                                            <Box className='text-center'>
                                                <BodyText fontSize="16px" fontWeight="400" textColor="#333" mb="8px">Welcome to hotelkit Moments - the social stream within hotelkit. </BodyText>
                                                <BodyText fontSize="16px" fontWeight="400" textColor="#333" mb="20px">Stay connected with your colleagues and share your happy moments here. </BodyText>
                                                <BodyText fontSize="16px" fontWeight="700" textColor="#000" mb="20px"> Create your very first moments post in the field above. </BodyText>
                                            </Box>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='mt-5 mb-4'>
                                    <Moments />
                                </div>
                            </Card.Body>
                        </Card>
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}