import * as React from 'react';
import { BodyText, Box } from '../../StyledComponents';
import FormSelect from '../../FormSelect';
import { notificationOptions } from '../../../CommonJSON';
import { Col, Row } from 'react-bootstrap';


export const ReportContentModalContent = () => {
    return (
        <>
            <Row className='p-1'>
                <Col md={12}>
                    <BodyText fontSize='15px' fontWeight='700' textColor='#666' mb="10px">
                        Grund für Meldung
                    </BodyText>
                    <div>
                        <FormSelect
                            isFormGroup={true}
                            selectOptions={notificationOptions}
                            Size='sm'
                            Name='salute'
                            Id='salute'
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}