import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Sidebar from './SideBar';
import { Outlet } from "react-router-dom";
import { TopbarSelectFilterDropdown } from '../../Components/TopbarSelectFilterDropdown';


export interface IMessageData {
    title: string,
    badgeOutline: string,
    userName1: string,
    userNameTooltipContent1: string,
    userName2: string,
    userNameTooltipContent2: string
    userName3: string,
    userNameTooltipContent3: string,
    userName4: string,
    userNameTooltipContent4: string,
    userName5: string,
    userNameTooltipContent5: string,
    badgeSolidColor: string,
    badgeSolidTitle: string,
    badgeSolidTooltipContent: string,
    comment: string,
    commentTooltip: string,
    limitedVisibilty: boolean,
    attachment: boolean,
    messageTime: string,
    messageTimeTooltip: string,
    image: string,
    imageToolTip: string,
    isChecked: boolean,
}


const MessageData = [
    {
        title: "21.11.2022",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "Ellen Klotz",
        userNameTooltipContent2: "ZFA - Praxis Gallenbach & Zähne",
        userName3: "Nadine Häring",
        userNameTooltipContent3: "ZFA - Praxis Gallenbach & Zähne",
        userName4: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent4: "Zahnarzt - Praxis Gallenbach & Zähne",
        userName5: "ZA Robert Stühn",
        userNameTooltipContent5: "Zahnarzt - Praxis Gallenbach & Zähne",
        badgeSolidColor: "",
        badgeSolidTitle: "",
        badgeSolidTooltipContent: "",
        comment: "3",
        commentTooltip: "3 Kommentare",
        limitedVisibilty: true,
        attachment: false,
        messageTime: "vor 15 Stunden",
        messageTimeTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        image: "/images/graph-img.png",
        imageToolTip: "Gelesen: 3",
        isChecked: false,
    },
    {
        title: "Ende der offiziellen Testphase",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "",
        userNameTooltipContent2: "",
        userName3: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent3: "Zahnarzt - Praxis Gallenbach & Zähne",
        userName4: "",
        userNameTooltipContent4: "",
        userName5: "",
        userNameTooltipContent5: "",
        badgeSolidColor: "#2b9cb2",
        badgeSolidTitle: "Verwaltung",
        badgeSolidTooltipContent: "ZFA - Praxis Gallenbach & Zähne",
        comment: "3",
        commentTooltip: "3 Kommentare",
        limitedVisibilty: true,
        attachment: true,
        messageTime: "vor 15 Stunden",
        messageTimeTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        image: "/images/graph-img.png",
        imageToolTip: "Gelesen: 1",
        isChecked: false,
    },
    {
        title: "Ende der offiziellen Testphase",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "",
        userNameTooltipContent2: "",
        userName3: "Nadine Häring",
        userNameTooltipContent3: "ZFA - Praxis Gallenbach & Zähne",
        userName4: "",
        userNameTooltipContent4: "",
        userName5: "",
        userNameTooltipContent5: "",
        badgeSolidColor: "#0D3F5F",
        badgeSolidTitle: "Verwaltung",
        badgeSolidTooltipContent: "ZFA - Praxis Gallenbach & Zähne",
        comment: "3",
        commentTooltip: "3 Kommentare",
        limitedVisibilty: true,
        attachment: false,
        messageTime: "vor 15 Stunden",
        messageTimeTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        image: "/images/graph-img.png",
        imageToolTip: "Gelesen: 2",
        isChecked: false,
    },
    {
        title: "28.12.2022",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "Ellen Klotz",
        userNameTooltipContent2: "ZFA - Praxis Gallenbach & Zähne",
        userName3: "Nadine Häring",
        userNameTooltipContent3: "ZFA - Praxis Gallenbach & Zähne",
        userName4: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent4: "Zahnarzt - Praxis Gallenbach & Zähne",
        userName5: "ZA Robert Stühn",
        userNameTooltipContent5: "Zahnarzt - Praxis Gallenbach & Zähne",
        badgeSolidColor: "",
        badgeSolidTitle: "",
        badgeSolidTooltipContent: "",
        comment: "3",
        commentTooltip: "3 Kommentare",
        limitedVisibilty: true,
        attachment: false,
        messageTime: "vor 15 Stunden",
        messageTimeTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        image: "/images/graph-img.png",
        imageToolTip: "Nicht gelesen: 1",
        isChecked: false,
    },
    {
        title: "Ende der offiziellen Testphase",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "",
        userNameTooltipContent2: "",
        userName3: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent3: "Zahnarzt - Praxis Gallenbach & Zähne",
        userName4: "",
        userNameTooltipContent4: "",
        userName5: "",
        userNameTooltipContent5: "",
        badgeSolidColor: "#2b9cb2",
        badgeSolidTitle: "Verwaltung",
        badgeSolidTooltipContent: "ZFA - Praxis Gallenbach & Zähne",
        comment: "3",
        commentTooltip: "3 Kommentare",
        limitedVisibilty: true,
        attachment: true,
        messageTime: "vor 15 Stunden",
        messageTimeTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        image: "/images/graph-img.png",
        imageToolTip: "Gelesen: 2",
        isChecked: false,
    },
    {
        title: "Ende der offiziellen Testphase",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "",
        userNameTooltipContent2: "",
        userName3: "Nadine Häring",
        userNameTooltipContent3: "ZFA - Praxis Gallenbach & Zähne",
        userName4: "",
        userNameTooltipContent4: "",
        userName5: "",
        userNameTooltipContent5: "",
        badgeSolidColor: "#0D3F5F",
        badgeSolidTitle: "Verwaltung",
        badgeSolidTooltipContent: "ZFA - Praxis Gallenbach & Zähne",
        comment: "3",
        commentTooltip: "3 Kommentare",
        limitedVisibilty: true,
        attachment: false,
        messageTime: "vor 15 Stunden",
        messageTimeTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        image: "/images/graph-img.png",
        imageToolTip: "Nicht gelesen: 1",
        isChecked: false,
    },
]


export type TdisplayMode = {
    displayNormal: boolean,
    displayCompact: boolean,
    displaySplit: boolean
}

function Index() {

    const [messageData, setMessageData] = useState<IMessageData[]>([]);
    const [isShowChecks, setIsShowChecks] = useState<boolean>(false);
    const [checkAllEntries, setCheckAllEntries] = useState<boolean>(false);
    const [totalAccount, setTotalAccount] = useState<number>(0);
    const [displayMode, setDisplayMode] = useState<TdisplayMode>({ displayNormal: true, displayCompact: false, displaySplit: false });


    useEffect(() => {
        setMessageData(MessageData);
    }, []);


    const HandleSelectAll = () => {
        const tempArray = [...messageData];
        for (var i = 0; i < tempArray.length; i++) {
            tempArray[i].isChecked = true;
        }
        setMessageData(tempArray);
        setTotalAccount(tempArray.length);
    }

    const handleToggleEntryCheck = () => {
        setIsShowChecks(!isShowChecks);
        const tempArray = [...messageData];
        for (var i = 0; i < tempArray.length; i++) {
            tempArray[i].isChecked = false;
        }
        setMessageData(tempArray);
        setTotalAccount(0);
    }

    const handleDisplayMode = (event: React.MouseEvent) => {
        var valueAttr = event.currentTarget.attributes.getNamedItem('value');
        var value = valueAttr ? valueAttr.value : null;
        if (value === 'normal') {
            var temp: TdisplayMode = { ...displayMode };
            temp.displayNormal = true;
            temp.displayCompact = false;
            temp.displaySplit = false;

            setDisplayMode(temp);

        } else {
            var temp: TdisplayMode = { ...displayMode };
            temp.displayCompact = true;
            temp.displayNormal = false;
            temp.displaySplit = false;

            setDisplayMode(temp);
        }
    }






    return (
        <>
            <Row>
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <div className="topbar-select mb-2">
                        <TopbarSelectFilterDropdown
                            showHideEntryCheck={handleToggleEntryCheck}
                            showChecks={isShowChecks}
                            checkAllEntries={checkAllEntries}
                            handleSelectAll={HandleSelectAll}
                            displayMode={displayMode}
                            handleDisplayMode={handleDisplayMode}
                            totalAccount={totalAccount}
                        />
                    </div>
                    <Outlet context={[messageData, setMessageData, displayMode, isShowChecks, setTotalAccount]} />
                </Col>
            </Row>
        </>
    );
}

export default Index;