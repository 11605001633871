import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FaRegImage, FaFont, FaImage } from "react-icons/fa";
import { BsPaperclip, BsFillTagFill } from "react-icons/bs";


function ModelActions(props) {

    return (
        <>
            <div className='d-flex align-items-center justify-content-end mb-3'>
                <Button onClick={props.ImgClick} className='rounded-0 border-end-0 f-14px' size="sm" variant="outline-secondary">
                    <FaRegImage size="14px" />
                </Button>
                {props.isHideFont ?
                    null :
                    <Button onClick={props.FontClick} className='rounded-0 border-end-0 f-14px' size="sm" variant="outline-secondary">
                        <FaFont size="14px" />
                        <span className='ms-1'>Formatierungsoptionen</span>
                    </Button>
                }
                <div className="file-attach rounded-0 f-14px">
                    <input type="file" id="imageInput" />
                    <label for="imageInput" className="image-button"><BsPaperclip size="14px" /> Anhänge hinzufügen</label>
                    <img src="" className="image-preview" />
                    <span className="change-image">Choose different image</span>
                </div>
                <Button onClick={props.TagifyClick} className='rounded-0 f-14px' size="sm" variant="outline-secondary">
                    <BsFillTagFill size="14px" />
                    <span className='ms-1'>Anhänge hinzufügen</span>
                </Button>
            </div>
        </>
    );
}

export default ModelActions;