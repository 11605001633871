
export const tagOneOptions: optionType[] = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
]

export const saluteOptions: optionType[] = [
    {
        label: "Bitte wähle eine Anrede aus",
        value: "1",
    },
    {
        label: 'Sie: "... hat ihre Profilinformationen aktualisiert.',
        value: "2",
    },
    {
        label: 'Er: "... hat seine Profilinformationen aktualisiert',
        value: "3",
    },
];
export const homePageOptions: optionType[] = [
    { label: "Standard", value: "Standard" },
    { label: "Stanseite", value: "Stanseite" },
    { label: "Nachrichten", value: "Nachrichten" },
    { label: "News", value: "News" },
    { label: "Ideen", value: "Ideen" },
    { label: "Handbuch", value: "Handbuch" },
    { label: "Aufgaben", value: "Aufgaben" },
    { label: "Kalender", value: "Kalender" },
    { label: "Reparaturen", value: "Reparaturen" },
];
export const signOutTimeOptions: optionType[] = [
    { label: "nie", value: "nie" },
    { label: "2 Minuten", value: "2 Minuten" },
    { label: "5 Minuten", value: "5 Minuten" },
    { label: "10 Minuten", value: "10 Minuten" },
    { label: "15 Minuten", value: "15 Minuten" },
    { label: "30 Minuten", value: "30 Minuten" },
    { label: "1 Stunde", value: "1 Stunde" },
    { label: "2 Stunden", value: "2 Stunden" },
    { label: "3 Stunden", value: "3 Stunden" },
    { label: "4 Stunden", value: "4 Stunden" },
    { label: "5 Stunden", value: "5 Stunden" },
    { label: "6 Stunden", value: "6 Stunden" },
    { label: "7 Stunden", value: "7 Stunden" },
];
export const languageOptions: optionType[] = [
    { label: "Standard", value: "Standard" },
    { label: "English", value: "English" },
    { label: "Deutsch", value: "Deutsch" },
    {
        label: "Deutsch (Höflichkeitsform)",
        value: "Deutsch (Höflichkeitsform)",
    },
    { label: "Italiano", value: "Italiano" },
    { label: "Türkçe", value: "Türkçe" },
    { label: "Nedenands", value: "Nedenands" },
    { label: "Français", value: "Français" },
    { label: "Español", value: "Español" },
    { label: "Português", value: "Português" },
    { label: "SlovenskY", value: "SlovenskY" },
    { label: "简体中文", value: "eeStina" },
    { label: "Slovenský", value: "Magyar" },
    { label: "čeština", value: "polski" },
    { label: "E-Magyar", value: "E-ÅÅdöa" },
    { label: "Polski", value: "Polski" },
    { label: "Ελλάδα", value: "Ελλάδα" },
    { label: "Hrvatski", value: "Hrvatski" },
    { label: "Dansk", value: "Dansk" },
    { label: "Svenska", value: "Svenska" },
    { label: "Lietuvių", value: "Lietuvių" },
    { label: "Română", value: "Română" },
    { label: "Pусский", value: "Pусский" },
    { label: "Norsk", value: "Norsk" },
    { label: "ქართველი", value: "ქართველი" },
    { label: "Eestlane", value: "Eestlane" },
    { label: "български", value: "български" },
    { label: "Український", value: "Український" },
    { label: "日本語", value: "日本語" },
    { label: "Tiếng Việt ", value: "Tiếng Việt " },
    { label: "Tiếng thái", value: "Tiếng thái" },
    { label: "မြန်မာ ", value: "မြန်မာ " },
    { label: "Melayu", value: "Melayu" },
    { label: "ខ្មែរ", value: "ខ្មែរ" },
    { label: "Bahasa Indonesia", value: "Bahasa Indonesia" },
];

export const voiceOptions: optionType[] = [
    { label: '∞', value: '∞' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
]

export const language: optionType[] = [
    { label: 'English', value: 'English' },
    { label: 'Deutsch', value: 'Deutsch' },
    { label: 'Italiano', value: 'Italiano' },
    { label: 'Türkqe', value: 'Türkqe' },
    { label: 'Dutch', value: 'Dutch' },
    { label: 'Francais', value: 'Francais' },
    { label: 'Espanol', value: 'Espanol' },
    { label: 'Portugués', value: 'Portugués' },
    { label: 'Chinese (Simplified)', value: 'Chinese (Simplified)' },
    { label: 'SlovenskY', value: 'SlovenskY' },
    { label: 'Czech', value: 'Czech' },
    { label: 'Hungarian', value: 'Hungarian' },
    { label: 'Polish', value: 'Polish' },
    { label: 'Greek', value: 'Greek' },
    { label: 'Croatian', value: 'Croatian' },
    { label: 'Danish', value: 'Danish' },
    { label: 'Swedish', value: 'Swedish' },
    { label: 'Lithuanian', value: 'Lithuanian' },
    { label: 'Romanian', value: 'Romanian' },
    { label: 'Russian', value: 'Russian' },
    { label: 'Norwegian', value: 'Norwegian' },
    { label: 'Georgian', value: 'Georgian' },
    { label: 'Estonian', value: 'Estonian' },
    { label: 'Bulgarian', value: 'Bulgarian' },
    { label: 'Ukrainian', value: 'Ukrainian' },
    { label: 'Japanese', value: 'Japanese' },
    { label: 'Vietnamese', value: 'Vietnamese' },
    { label: 'Thai', value: 'Thai' },
    { label: 'Burmese', value: 'Burmese' },
    { label: 'Malay', value: 'Malay' },
    { label: 'Khmer', value: 'Khmer' },
    { label: 'Indonesian', value: 'Indonesian' },
]

export const SelectLanguageDropdown = [
    { title: 'English', },
    { title: 'Deutsch', },
    { title: 'Italiano', },
    { title: 'Türkqe', },
    { title: 'Dutch', },
    { title: 'Francais', },
    { title: 'Espanol', },
    { title: 'Portugués', },
    { title: 'Chinese (Simplified)', },
    { title: 'SlovenskY', },
    { title: 'Czech', },
    { title: 'Hungarian', },
    { title: 'Polish', },
    { title: 'Greek', },
    { title: 'Croatian', },
    { title: 'Danish', },
    { title: 'Swedish', },
    { title: 'Lithuanian', },
    { title: 'Romanian', },
    { title: 'Russian', },
    { title: 'Norwegian', },
    { title: 'Georgian', },
    { title: 'Estonian', },
    { title: 'Bulgarian', },
    { title: 'Ukrainian', },
    { title: 'Japanese', },
    { title: 'Vietnamese', },
    { title: 'Thai', },
    { title: 'Burmese', },
    { title: 'Malay', },
    { title: 'Khmer', },
    { title: 'Indonesian', },
]

export const RelevanceDropdown = [
    {
        title: "Letzte Aktion",
    },
    {
        title: "Nach Relevanz",
    },
    {
        title: "Neueste zuerst",
    },
    {
        title: "Älteste zuerst",
    },
    {
        title: "Neueste zuerst",
    },
]

export const AnytimeDropdown = [
    {
        title: "Heute",
    },
    {
        title: "Diese Woche",
    },
    {
        title: "Dieser Monat",
    },
    {
        title: "Gestern",
    },
    {
        title: "Letzte Woche",
    },
    {
        title: "Letzter Monat",
    },
    {
        title: "Letzte 7 Tage",
    },
    {
        title: "Letzte 30 Tage",
    },
]

export const homePageOptions2: optionType[] = [
    { label: 'Heute', value: 'Heute' },
    { label: 'Diese Woche', value: 'Diese Woche' },
    { label: 'Dieser Monat', value: 'Dieser Monat' },
    { label: 'Dieses Quartal', value: 'Dieses Quartal' },
    { label: 'Dieses Jahr', value: 'Dieses Jahr' },
    { label: 'Letzte Woche', value: 'Letzte Woche' },
    { label: 'Letzter Monat', value: 'Letzter Monat' },
    { label: 'Letztes Quartal', value: 'Letztes Quartal' },
    { label: 'Letztes Jahr', value: 'Letztes Jahr' },
    { label: 'Letzte 7 Tage', value: 'Letzte 7 Tage' },
    { label: 'Letzte 30 Tage', value: 'Letzte 30 Tage' },
    { label: 'Eigenen Zeitraum definieren', value: 'Eigenen Zeitraum definieren' },
]

export const fontSizeOptions: optionType[] = [
    { label: 'Grog', value: 'Grog' },
    { label: 'Normal', value: 'Normal' },
    { label: 'Klein', value: 'Klein' },
]

export const headerLinkOptions: optionType[] = [
    { label: '....', value: '....' },
    { label: 'Logo', value: 'Logo' },
    { label: 'Titel', value: 'Titel' },
    { label: 'Datum', value: 'Datum' },
    { label: 'Seitenzahl', value: 'Seitenzahl' },
    { label: 'Link', value: 'Link' },
    { label: 'Erstellung', value: 'Erstellung' },
]

export const footerLinkOptions: optionType[] = [
    { label: '....', value: '....' },
    { label: 'Titel', value: 'Titel' },
    { label: 'Datum', value: 'Datum' },
    { label: 'Seitenzahl', value: 'Seitenzahl' },
    { label: 'Link', value: 'Link' },
    { label: 'Erstellung', value: 'Erstellung' },
]

export const notificationOptions: optionType[] = [
    { label: '- Bitte auswählen -', value: '- Bitte auswählen -' },
    { label: 'Pornografie', value: 'Pornografie' },
    { label: 'Mobbing und Belästigung', value: 'Mobbing und Belästigung' },
    { label: 'Selbstverletzung', value: 'Selbstverletzung' },
    { label: 'Inhalt mit Gewalt und Grausamkeit', value: 'Inhalt mit Gewalt und Grausamkeit' },
    { label: 'Illegale Aktivitäten', value: 'Illegale Aktivitäten' },
    { label: 'irreführender Inhalt', value: 'irreführender Inhalt' },
    { label: 'Hassrede Oder —symbole', value: 'Hassrede Oder —symbole' },
    { label: 'Andere', value: 'Andere' },
]
export const reportTypeOptions: optionType[] = [
    { label: '- Please choose -', value: '- Please choose -' },
    { label: 'Pornograpny', value: 'Pornograpny' },
    { label: 'Hatred and bullying', value: 'Hatred and bullying' },
    { label: 'Self-harm', value: 'Self-harm' },
    { label: 'Violent, gory and narmtul content', value: 'Violent, gory and narmtul content' },
    { label: 'Illegal activities', value: 'Illegal activities' },
    { label: 'Deceptive content', value: 'Deceptive content' },
    { label: 'Hate speech or symbols', value: 'Hate speech or symbols' },
    { label: 'Other', value: 'Other' },
]

export const CategoryImages: CatagoryType[] = [
    {
        category: "celebrate",
        isSelect: false,
        images: [
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-1.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-2.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-3.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-4.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-5.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-6.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-7.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-8.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-9.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-10.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-11.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-12.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-13.webp" },
            { imageSrc: "/images/assets/home-banner/celebrate/celebrate-banner-14.webp" },
        ]
    },
    {
        category: "newStandard",
        isSelect: false,
        images: [
            { imageSrc: "/images/assets/home-banner/newStandard/standard-banner-1.webp" },
            { imageSrc: "/images/assets/home-banner/newStandard/standard-banner-2.webp" },
            { imageSrc: "/images/assets/home-banner/newStandard/standard-banner-3.webp" },
            { imageSrc: "/images/assets/home-banner/newStandard/standard-banner-4.webp" },
            { imageSrc: "/images/assets/home-banner/newStandard/standard-banner-5.webp" },
            { imageSrc: "/images/assets/home-banner/newStandard/standard-banner-6.webp" },
            { imageSrc: "/images/assets/home-banner/newStandard/standard-banner-7.webp" },
            { imageSrc: "/images/assets/home-banner/newStandard/standard-banner-8.webp" },
            { imageSrc: "/images/assets/home-banner/newStandard/standard-banner-9.webp" },
            { imageSrc: "/images/assets/home-banner/newStandard/standard-banner-10.webp" },
        ]
    },
    {
        category: "checklist",
        isSelect: false,
        images: [
            { imageSrc: "/images/assets/home-banner/checklist/checklist-banner-1.webp" },
            { imageSrc: "/images/assets/home-banner/checklist/checklist-banner-2.webp" },
            { imageSrc: "/images/assets/home-banner/checklist/checklist-banner-3.webp" },
            { imageSrc: "/images/assets/home-banner/checklist/checklist-banner-4.webp" },
            { imageSrc: "/images/assets/home-banner/checklist/checklist-banner-5.webp" },
            { imageSrc: "/images/assets/home-banner/checklist/checklist-banner-6.webp" },
            { imageSrc: "/images/assets/home-banner/checklist/checklist-banner-7.webp" },
            { imageSrc: "/images/assets/home-banner/checklist/checklist-banner-8.webp" },
            { imageSrc: "/images/assets/home-banner/checklist/checklist-banner-9.webp" },
        ]
    },
    {
        category: "christmas",
        isSelect: false,
        images: [
            { imageSrc: "/images/assets/home-banner/christmas/christmas-banner-1.webp" },
            { imageSrc: "/images/assets/home-banner/christmas/christmas-banner-2.webp" },
            { imageSrc: "/images/assets/home-banner/christmas/christmas-banner-3.webp" },
            { imageSrc: "/images/assets/home-banner/christmas/christmas-banner-4.webp" },
            { imageSrc: "/images/assets/home-banner/christmas/christmas-banner-5.webp" },
            { imageSrc: "/images/assets/home-banner/christmas/christmas-banner-6.webp" },
            { imageSrc: "/images/assets/home-banner/christmas/christmas-banner-7.webp" },
            { imageSrc: "/images/assets/home-banner/christmas/christmas-banner-8.webp" },
        ]
    },
    {
        category: "event",
        isSelect: false,
        images: [
            { imageSrc: "/images/assets/home-banner/event/event-banner-1.webp" },
            { imageSrc: "/images/assets/home-banner/event/event-banner-2.webp" },
            { imageSrc: "/images/assets/home-banner/event/event-banner-3.webp" },
            { imageSrc: "/images/assets/home-banner/event/event-banner-4.webp" },
            { imageSrc: "/images/assets/home-banner/event/event-banner-5.webp" },
            { imageSrc: "/images/assets/home-banner/event/event-banner-6.webp" },
        ]
    },
    {
        category: "instruction",
        isSelect: false,
        images: [
            { imageSrc: "/images/assets/home-banner/instruction/instruction-banner-1.webp" },
            { imageSrc: "/images/assets/home-banner/instruction/instruction-banner-2.webp" },
            { imageSrc: "/images/assets/home-banner/instruction/instruction-banner-3.webp" },
            { imageSrc: "/images/assets/home-banner/instruction/instruction-banner-4.webp" },
            { imageSrc: "/images/assets/home-banner/instruction/instruction-banner-5.webp" },
            { imageSrc: "/images/assets/home-banner/instruction/instruction-banner-6.webp" },
            { imageSrc: "/images/assets/home-banner/instruction/instruction-banner-7.webp" },
            { imageSrc: "/images/assets/home-banner/instruction/instruction-banner-8.webp" },
            { imageSrc: "/images/assets/home-banner/instruction/instruction-banner-9.webp" },
            { imageSrc: "/images/assets/home-banner/instruction/instruction-banner-10.webp" },
            { imageSrc: "/images/assets/home-banner/instruction/instruction-banner-11.webp" },
        ]
    },
    {
        category: "recruiting",
        isSelect: false,
        images: [
            { imageSrc: "/images/assets/home-banner/recruiting/recruiting-banner-1.webp" },
            { imageSrc: "/images/assets/home-banner/recruiting/recruiting-banner-2.webp" },
            { imageSrc: "/images/assets/home-banner/recruiting/recruiting-banner-3.webp" },
            { imageSrc: "/images/assets/home-banner/recruiting/recruiting-banner-4.webp" },
            { imageSrc: "/images/assets/home-banner/recruiting/recruiting-banner-5.webp" },
        ]
    },
    {
        category: "team",
        isSelect: false,
        images: [
            { imageSrc: "/images/assets/home-banner/team/team-banner-1.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-2.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-3.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-4.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-5.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-6.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-7.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-8.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-9.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-10.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-11.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-12.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-13.webp" },
            { imageSrc: "/images/assets/home-banner/team/team-banner-14.webp" },
        ]
    },
]

export const CategoryDateJson: CatagoryDateType[] = [
    {
        date: "dueDate",
        isSelect: false,
    },
    {
        date: "readByEveryone",
        isSelect: false,
    },
]

export const categoryOptions: optionType[] = [
    { label: 'Feiern', value: 'celebrate' },
    { label: 'Neue Standards', value: 'newStandard' },
    { label: 'Checklisten', value: 'checklist' },
    { label: 'Weihnachten', value: 'christmas' },
    { label: 'Event', value: 'event' },
    { label: 'Anweisungen', value: 'instruction' },
    { label: 'Recruiting', value: 'recruiting' },
    { label: 'Team', value: 'team' },
]

export const showDateOption: optionType[] = [
    { label: 'Fälligkeitsdatum', value: 'dueDate' },
    { label: 'Von allen gelesen', value: 'readByEveryone' },
]

export const LetzteTageOptions: optionType[] = [
    { label: 'G estern', value: 'G estern' },
    { label: 'Letzte 7 Tage', value: 'Letzte 7 Tage' },
    { label: 'Letzte 30 Tage', value: 'Letzte 30 Tage' },
    { label: 'Letzte 60 Tage', value: 'Letzte 60 Tage' },
    { label: 'Letzte 90 Tage', value: 'Letzte 90 Tage' },
    { label: 'Letzte 180 Tage', value: 'Letzte 180 Tage' },
    { label: 'Gesamt', value: 'Gesamt' },
    { label: 'Benutzerdefiniert', value: 'Benutzerdefiniert' },
]

export const bookmarkOptions: optionType[] = [
    { label: "- Nach Stichwort filtern -", value: "- Nach Stichwort filtern -" },
    { label: "English", value: "English" },
    { label: "Deutsch", value: "Deutsch" },
    { label: "Bahasa Indonesia", value: "Bahasa Indonesia" },
];

export const homeOptions: optionType[] = [
    { label: "Startseite (nicht gesetzt)", value: "Startseite (nicht gesetzt)" },
    { label: "Nachricnten", value: "Nachricnten" },
    { label: "Deutsch", value: "Deutsch" },
    { label: "Italiano", value: "Italiano" },
    { label: "Türkçe", value: "Türkçe" },
    { label: "Nedenands", value: "Nedenands" },
];

export const reportTypeOptions2: optionType[] = [
    { label: 'Bitte wähle eine Anrede aus.', value: 'Bitte wähle eine Anrede aus.' },
    { label: 'Sie: " ... hat ihre Profilinformationen aktua', value: 'Sie: " ... hat ihre Profilinformationen aktua' },
    { label: 'Er: "... hat seine Profilintormationen aktualisiert', value: 'Er: "... hat seine Profilintormationen aktualisiert' },
]

export const homePageOptions3: optionType[] = [
    { label: "", value: "" },
    { label: "Standard", value: "Standard" },
    { label: "Stanseite", value: "Stanseite" },
    { label: "Nachrichten", value: "Nachrichten" },
    { label: "News", value: "News" },
    { label: "Ideen", value: "Ideen" },
    { label: "Handbuch", value: "Handbuch" },
    { label: "Aufgaben", value: "Aufgaben" },
    { label: "Kalender", value: "Kalender" },
    { label: "Reparaturen", value: "Reparaturen" },
];

export const BlockTools = [
    {
        title: "Nachrichten",
        isCheck: false,
    },
    {
        title: "News",
        isCheck: false,
    },
    {
        title: "Ideen",
        isCheck: false,
    },
    {
        title: "Handbuch",
        isCheck: false,
    },
    {
        title: "Aufgaben",
        isCheck: false,
    },
    {
        title: "Kalender",
        isCheck: false,
    },
    {
        title: "Reparaturen",
        isCheck: false,
    },
    {
        title: "Market place",
        isCheck: false,
    },
    {
        title: "Mitglieder",
        isCheck: false,
    },

]

export const statusOptions: optionType[] = [
    { label: 'Aktiviert', value: 'Aktiviert' },
    { label: 'Deaktiviert', value: 'Deaktiviert' },
    { label: 'Gelöscht', value: 'Gelöscht' },
]

export const roleOptions: optionType[] = [
    { label: 'Admin', value: 'Admin' },
    { label: 'Nutzer', value: 'Nutzer' },
    { label: 'Externer Nutzer', value: 'Externer Nutzer' },
]

export const UserData = [
    {
        userImage: "/images/account.png",
        userName: "Thomas Planitzer & Team",
        nameBadgeCircle: "OPTI QM",
        roleInfo: "Customer Success Manager",
        fillBadgeText1: "",
        fillBadgeText2: "",
        fillBadgeText3: "",
    },
    {
        userImage: "/images/account-b.png",
        userName: "APZ APZ ",
        nameBadgeCircle: "",
        roleInfo: "",
        fillBadgeText1: "Abrechnung",
        fillBadgeText2: "",
        fillBadgeText3: "",
    },
    {
        userImage: "/images/assets/driver.png",
        userName: "Anna Behm",
        nameBadgeCircle: "",
        roleInfo: "ZFA",
        fillBadgeText1: "Assistenz",
        fillBadgeText2: "",
        fillBadgeText3: "",
    },
    {
        userImage: "/images/assets/male.png",
        userName: "Anna Behm",
        nameBadgeCircle: "",
        roleInfo: "ZFA",
        fillBadgeText1: "Assistenz",
        fillBadgeText2: "",
        fillBadgeText3: "",
    },
    {
        userImage: "/images/account.png",
        userName: "Dr. Sven Markus Gallenbach",
        nameBadgeCircle: "",
        roleInfo: "Zahnarzt",
        fillBadgeText1: "medikit Champions",
        fillBadgeText2: "Zahnärzte",
        fillBadgeText3: "",
    },
    {
        userImage: "/images/account.png",
        userName: "Emine Mollaoglu ",
        nameBadgeCircle: "OPTI HEALTH CONSULTING",
        roleInfo: "Consultant",
        fillBadgeText1: "OPTI",
        fillBadgeText2: "Zahnärzte",
        fillBadgeText3: "",
    },
    {
        userImage: "/images/account.png",
        userName: "Jhon Deo",
        nameBadgeCircle: "OPTI HEALTH CONSULTING",
        roleInfo: "Consultant",
        fillBadgeText1: "OPTI",
        fillBadgeText2: "Zahnärzte",
        fillBadgeText3: "Assistenz",
    },
]

export const AllUsers = [
    {
        title: "Aktivierte Nutzer",
    },
    {
        title: "Deaktivierte Nutzer",
    },
    {
        title: "Externe Nutzer",
    },
]

export const groupJsonData: groupProps[] = [
    {
        permission: {
            title: "Nachrichten",
            ischeck: false,
            checklist: [
                {
                    label: "Einträge erstellen",
                    ischeck: false,
                },
                {
                    label: "Einträge zu späterem Zeitpunkt veröffentlichen",
                    ischeck: false,
                },
                {
                    label: "Nicht-öffentliche Einträge erstellen",
                    ischeck: false,
                },
                {
                    label: "Kommentieren",
                    ischeck: false,
                },
                {
                    label: "Drucken oder PDFs generieren",
                    ischeck: false,
                },
                {
                    label: "Kommentare schließen",
                    ischeck: false,
                },
                {
                    label: "Fremde Einträge bearbeiten",
                    ischeck: false,
                },
                {
                    label: "Fremde Einträge löschen",
                    ischeck: false,
                },
                {
                    label: "Neue Vorlagen erstellen",
                    ischeck: false,
                },
                {
                    label: "Vorlagenkategorien erstellen und bearbeiten",
                    ischeck: false,
                },
            ]
        }
    },
    {
        permission: {
            title: "News",
            ischeck: false,
            checklist: [
                {
                    label: "Einträge erstellen",
                    ischeck: false,
                },
                {
                    label: "Einträge zu späterem Zeitpunkt veröffentlichen",
                    ischeck: false,
                },
                {
                    label: "Nicht-öffentliche Einträge erstellen",
                    ischeck: false,
                },
                {
                    label: "Kommentieren",
                    ischeck: false,
                },
                {
                    label: "Drucken oder PDFs generieren",
                    ischeck: false,
                },
                {
                    label: "Kann Benachrichtigungen deaktivieren",
                    ischeck: false,
                },
                {
                    label: "Kommentare schließen",
                    ischeck: false,
                },
                {
                    label: "Nicht-öffentliche Einträge lesen",
                    ischeck: false,
                },
                {
                    label: "Fremde Einträge bearbeiten",
                    ischeck: false,
                },
                {
                    label: "Fremde Einträge löschen",
                    ischeck: false,
                },
                {
                    label: "Neue Vorlagen erstellen",
                    ischeck: false,
                },
                {
                    label: "Vorlagenkategorien erstellen und bearbeiten",
                    ischeck: false,
                },
            ]
        }
    },
]

export const standardStreamtyp: optionType[] = [
    { label: 'Nicht definiert', value: 'Nicht definiert' },
    { label: 'Aktivitäten', value: 'Aktivitäten' },
    { label: 'Benachrichtigungen', value: 'Benachrichtigungen' },
    { label: 'Moments', value: 'Moments' },
]


export const IpBlockingDropdown = [
    {
        title: "Zugriff von allen IPs aus erlauben",
    },
    {
        title: "Bestimmte Bereiche für unbekannte IPs sperren",
    },
    {
        title: "Zugriff für unbekannte IPs komplett sperren",
    },
]


export const ArticleVersionComment: optionType[] = [
    { label: "And", value: "And" },
    { label: "No", value: "No" },
];


export const BackupResponsibleDropdown: optionType[] = [
    { label: "Dr. Sven Markus Gallenbach", value: "Dr. Sven Markus Gallenbach" },
    { label: "Nadine Haering", value: "Nadine Haering" },
    { label: "Diana Lauser", value: "Diana Lauser" },
    { label: "Alicia Gojani", value: "Alicia Gojani" },
];


export const PastBackupsData: PastBackupDataType[] = [
    {
        requestedTitle: "18.08.2023 10:39",
        available: "",
        downloaded: "",
        downloadBtn: true,
    },
    {
        requestedTitle: "Automatisch 18.08.2023 04:45",
        available: "18.08.2023 04:50",
        downloaded: "",
        downloadBtn: false,
    },
    {
        requestedTitle: "17.08.2023 16:32",
        available: "17.08.2023 16:37",
        downloaded: "",
        downloadBtn: false,
    },
    {
        requestedTitle: "Automatisch 17.08.2023 04:44",
        available: "17.08.2023 04:49",
        downloaded: "",
        downloadBtn: false,
    },
    {
        requestedTitle: "Automatisch 10.08.2023 07:12",
        available: "10.08.2023 07:17",
        downloaded: "Abgelaufen",
        downloadBtn: false,
    },
    {
        requestedTitle: "Automatisch 03.08.2023 04:41",
        available: "03.08.2023 04:46",
        downloaded: "Abgelaufen",
        downloadBtn: false,
    },
    {
        requestedTitle: "Automatisch 27.07.2023 05:59",
        available: "27.07.2023 06:04",
        downloaded: "Abgelaufen",
        downloadBtn: false,
    },
    {
        requestedTitle: "Automatisch 25.06.2022 06:17",
        available: "",
        downloaded: "",
        downloadBtn: false,
    },
    {
        requestedTitle: "Automatisch 25.06.2022 05:17",
        available: "",
        downloaded: "",
        downloadBtn: false,
    },
    {
        requestedTitle: "Automatisch 25.06.2022 04:17",
        available: "",
        downloaded: "",
        downloadBtn: false,
    },
]


export const AllRepairNumberDropdown: optionType[] = [
    { label: "Keine Nummerierung", value: "Keine Nummerierung" },
    { label: "Erst Jahr. dann fortlaufende Nummer", value: "Erst Jahr. dann fortlaufende Nummer" },
    { label: "Erst fortlaufende Nummer. dann Jahr", value: "Erst fortlaufende Nummer. dann Jahr" },
    { label: "Fortlaufende Nummer mit führenden Nullen", value: "Fortlaufende Nummer mit führenden Nullen" },
    { label: "Einfache Nummerierung", value: "Einfache Nummerierung" },
];

export const MobilePinDropdown: optionType[] = [
    { label: "Sperre deaktiviert", value: "Sperre deaktiviert" },
    { label: "2 Minuten", value: "2 Minuten" },
    { label: "5 Minuten", value: "5 Minuten" },
    { label: "10 Minuten", value: "10 Minuten" },
    { label: "15 Minuten", value: "15 Minuten" },
    { label: "30 Minuten", value: "30 Minuten" },
    { label: "1 Stunde", value: "1 Stunde" },
    { label: "2 Stunden", value: "2 Stunden" },
    { label: "3 Stunden", value: "3 Stunden" },
    { label: "4 Stunden", value: "4 Stunden" },
    { label: "5 Stunden", value: "5 Stunden" },
    { label: "6 Stunden", value: "6 Stunden" },
    { label: "7 Stunden", value: "7 Stunden" },
    { label: "8 Stunden", value: "8 Stunden" },
];