
import React, { useState } from 'react';
import { TopbarSelectFilterDropdown } from '../../Components/TopbarSelectFilterDropdown';
import { Row, Col, Table, DropdownButton, Dropdown, Form, Button } from 'react-bootstrap';
import { BodyText, Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, StatusBadge, StatusBadgeTextColor } from '../../Components/StyledComponents';
import Accordion from 'react-bootstrap/Accordion';
import CustomTooltip from '../../Components/Tooltip';
import { JsxElement, isJsxElement } from 'typescript';
import { FaChevronDown, FaChevronUp, FaLongArrowAltRight } from 'react-icons/fa';
import { CustomCard } from '../../Components/CustomCard';
import BadgeWidget from '../../Components/BadgeWidget';
import { GroupActionButtonWidget } from '../../Components/Widgets/GroupActionButtonWidget';
import CommonModal from '../../Components/Modals/CommonModal';
import { BookMarkOptionModalContent } from '../../Components/Modals/GroupActionButtonModalContent.tsx/BookMarkOptionModalContent';
import ButtonWidget from '../../Components/ButtonWidget';
import { RememberOptionModalContent } from '../../Components/Modals/GroupActionButtonModalContent.tsx/RememberOptionModalContent';
import { PdfSettingsModalContent } from '../../Components/Modals/ModelContents/PdfSettingsModalContent';
import { DeleteRepairOrderModalContent } from '../../Components/Modals/GroupActionButtonModalContent.tsx/DeleteRepairOrderModalContent';
import { TdisplayMode } from '../Message/Index';
import AddEditRepair from './AddEditRepair';



export const MainContent = () => {

    const [arrowAngle, setArrowAngle] = useState<boolean>(true);
    const [statusDropdown, setStatusDropdown] = useState<number | null>(null);
    const [showBookMark, setShowBookMark] = useState<boolean>(false);
    const [showRememberOption, setShowRememberOption] = useState<boolean>(false);
    const [displayMode, setDisplayMode] = useState<TdisplayMode>({ displayNormal: false, displayCompact: false, displaySplit: true });


    const [printPdfSetting, setPrintPdfSetting] = useState<boolean>(false);
    const [deleteOrder, setDeleteOrder] = useState<boolean>(false);
    const [RepairModal, setRepairModal] = useState<boolean>(false);

    const handleArrowAngle = () => {
        setArrowAngle(!arrowAngle);
    }

    function truncate(source: string, size: number) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }

    const handleMouseEnter = (index: number) => {
        setStatusDropdown(index);
    };

    const handleMouseLeave = () => {
        setStatusDropdown(null);
    };

    const handleDisplayMode = (event: React.MouseEvent) => {
        var valueAttr = event.currentTarget.attributes.getNamedItem('value');
        var value = valueAttr ? valueAttr.value : null;
        if (value === 'split') {
            var temp: TdisplayMode = { ...displayMode };
            temp.displaySplit = true;
            temp.displayNormal = false;
            temp.displayCompact = false;

            setDisplayMode(temp);

        } else if (value === 'normal') {
            var temp: TdisplayMode = { ...displayMode };
            temp.displayNormal = true;
            temp.displayCompact = false;
            temp.displaySplit = false;

            setDisplayMode(temp);

        } else {
            var temp: TdisplayMode = { ...displayMode };
            temp.displayCompact = true;
            temp.displayNormal = false;
            temp.displaySplit = false;

            setDisplayMode(temp);
        }
    }

    const handleSelection = (index: number) => {

    }

    return (
        <>
            <div className='repair-section'>
                <div className='top-bar'>
                    <TopbarSelectFilterDropdown
                        showHideEntryCheck={undefined}
                        showChecks={undefined}
                        checkAllEntries={undefined}
                        handleSelectAll={undefined}
                        totalAccount={0}
                        displayMode={displayMode}
                        handleDisplayMode={handleDisplayMode}
                    />
                </div>
                <div className='two-column mt-2'>
                    {displayMode.displaySplit ?
                        <>
                            <Row>
                                <Col md={6}>
                                    <CustomCard isCardHeader={true}
                                        cardBodyPadding="0px"
                                        cardBodyHeight="346px"
                                        title={
                                            <DisplayStart>
                                                <span className='me-2'>
                                                    {arrowAngle ?
                                                        <FaChevronUp size={14} />
                                                        :
                                                        <FaChevronDown size={14} />
                                                    }
                                                </span>
                                                <BodyText fontSize='15px' fontWeight='700'> Normale Priorität </BodyText>
                                            </DisplayStart>
                                        }
                                        cardHeaderBg="#f5f5f5"
                                        cardBodyContent={
                                            <>
                                                {arrowAngle ?
                                                    <div className='table-s'>
                                                        <Table hover responsive>
                                                            <tbody>
                                                                {NormalPriorityJson.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={index} className='cursor-pointer' onClick={() => handleSelection(index)}>
                                                                                <td>
                                                                                    <DisplayStart>
                                                                                        {item.titleTooltipContent ?
                                                                                            <CustomTooltip
                                                                                                ToolTipTrigger={<BodyText fontSize="1.1em" fontWeight="700" textColor="#000">{item.title}</BodyText>}
                                                                                                toolTipContent={item.titleTooltipContent}
                                                                                                toolTipPlacement='top'
                                                                                            />
                                                                                            :
                                                                                            <BodyText fontSize="1.1em" fontWeight="700" textColor="#000">{item.title}</BodyText>
                                                                                        }
                                                                                        <span className='mx-2'> - </span>
                                                                                        <CustomTooltip
                                                                                            ToolTipTrigger={<BodyText mb='0px' fontSize='13px' fontWeight='400'>{item.locationName}</BodyText>}
                                                                                            toolTipContent={item.locationNameTooltipContent}
                                                                                            toolTipPlacement='top'
                                                                                        />
                                                                                    </DisplayStart>
                                                                                    <div className='d-flex align-items-center mt-1'>
                                                                                        <BadgeWidget outiline={true} title={item.badgeOutline} color='#0d3f5f' bgColor='#fff' />
                                                                                        <CustomTooltip
                                                                                            ToolTipTrigger={
                                                                                                <>
                                                                                                    <BodyText mb='0px' fontSize='12px' fontWeight='500' pl='5px'>{truncate(item.userName, 16)}</BodyText>
                                                                                                </>
                                                                                            }
                                                                                            toolTipContent={item.userNameTooltipContent}
                                                                                            toolTipPlacement='top'
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td width='170' className="text-end">
                                                                                    <div className='d-flex align-items-center justify-content-end mt-1'>
                                                                                        <CustomTooltip
                                                                                            ToolTipTrigger={
                                                                                                <>
                                                                                                    <BadgeWidget bordered={true} title={item.labelText} color={item.labelBorderColor} />
                                                                                                </>
                                                                                            }
                                                                                            toolTipContent={
                                                                                                <>
                                                                                                    <ul className="list-unstyled">
                                                                                                        {item.openCardText.map((x, i) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <li className="my-2">
                                                                                                                        <DisplayBetween key={i}>
                                                                                                                            <div className="d-flex me-2 align-items-center">
                                                                                                                                <div className='me-2'>
                                                                                                                                    <img width='20' src={x.image} />
                                                                                                                                </div>
                                                                                                                                <div className='text-nowrap'>
                                                                                                                                    <BodyText mb='0px' fontSize='12px' fontWeight='500'>{x.title}</BodyText>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <BadgeWidget bordered={true} title={item.labelText} color={item.labelBorderColor} />
                                                                                                                            <div>
                                                                                                                                <BodyText mb='0px' fontSize='12px' fontWeight='500'>{x.dateTime}</BodyText>
                                                                                                                            </div>
                                                                                                                        </DisplayBetween>
                                                                                                                    </li>
                                                                                                                </>
                                                                                                            )
                                                                                                        })}
                                                                                                    </ul>
                                                                                                </>
                                                                                            }
                                                                                            toolTipPlacement='top'
                                                                                            contentAlign='center'
                                                                                            maxWidth={'450px'}
                                                                                        />
                                                                                    </div>
                                                                                    <BodyText mb='0px' fontSize='13px' fontWeight='400'>
                                                                                        <CustomTooltip
                                                                                            ToolTipTrigger={<BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'>{item.createdDate}</BodyText >}
                                                                                            toolTipContent={item.createdDateTooltipContent}
                                                                                            toolTipPlacement='top'
                                                                                        />
                                                                                    </BodyText>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    :
                                                    null}
                                            </>
                                        }
                                        handleClick={handleArrowAngle}
                                    />
                                </Col>
                                <Col md={6}>
                                    <CustomCard isCardHeader={false}
                                        cardBodyContent={
                                            <>
                                                <div className='text-center'>
                                                    <BodyText fontSize='22px' fontWeight='700' textColor='#333' pt='5px' pb='10px'> Es wurde nichts ausgewählt </BodyText>
                                                    <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Wähle links den Eintrag aus, der hier angezeigt werden soll </BodyText>
                                                    <Box mt='20px' mb='20px'>
                                                        <img src='/images/assets/repair-notes.png' alt='Repair Notes' />
                                                    </Box>
                                                </div>
                                            </>
                                        }
                                    />
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col md={12}>
                                    <CustomCard isCardHeader={true}
                                        cardBodyPadding="0px"
                                        cardBodyHeight="346px"
                                        title={
                                            <DisplayStart>
                                                <span className='me-2'>
                                                    {arrowAngle ?
                                                        <FaChevronUp size={14} />
                                                        :
                                                        <FaChevronDown size={14} />
                                                    }
                                                </span>
                                                <BodyText fontSize='15px' fontWeight='700'> Normale Priorität </BodyText>
                                            </DisplayStart>
                                        }
                                        cardHeaderBg="#f5f5f5"
                                        cardBodyContent={
                                            <>
                                                {arrowAngle ?
                                                    <div className='table-s'>
                                                        <Table hover responsive>
                                                            <tbody>
                                                                {NormalJsonData.map((item, index: number) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} className='cursor-pointer'>
                                                                                <td>
                                                                                    <DisplayStart>
                                                                                        {item.titleTooltipContent ?
                                                                                            <CustomTooltip
                                                                                                ToolTipTrigger={<BodyText fontSize="1.1em" fontWeight="700" textColor="#000">{item.title}</BodyText>}
                                                                                                toolTipContent={item.titleTooltipContent}
                                                                                                toolTipPlacement='top'
                                                                                            />
                                                                                            :
                                                                                            <BodyText fontSize="1.1em" fontWeight="700" textColor="#000">{item.title}</BodyText>
                                                                                        }
                                                                                        <span className='mx-2'> - </span>
                                                                                        <CustomTooltip
                                                                                            ToolTipTrigger={<BodyText mb='0px' fontSize='13px' fontWeight='400'>{item.locationName}</BodyText>}
                                                                                            toolTipContent={item.locationNameTooltipContent}
                                                                                            toolTipPlacement='top'
                                                                                        />
                                                                                    </DisplayStart>
                                                                                    {displayMode.displayNormal ?
                                                                                        <>
                                                                                            <div className='d-flex align-items-center mt-1'>
                                                                                                <BadgeWidget outiline={true} title={item.badgeOutline} color='#0d3f5f' bgColor='#fff' />
                                                                                                <CustomTooltip
                                                                                                    ToolTipTrigger={
                                                                                                        <>
                                                                                                            <BodyText mb='0px' fontSize='12px' fontWeight='500' pl='5px'>{item.userName}</BodyText>
                                                                                                        </>
                                                                                                    }
                                                                                                    toolTipContent={item.userNameTooltipContent}
                                                                                                    toolTipPlacement='top'
                                                                                                />
                                                                                                <FaLongArrowAltRight className='mx-2' />
                                                                                                <CustomTooltip
                                                                                                    ToolTipTrigger={
                                                                                                        <>
                                                                                                            <BodyText mb='0px' fontSize='12px' fontWeight='500' pl='5px'>
                                                                                                                {statusDropdown === (index) ?
                                                                                                                    <>{truncate(item.userName, 14)}</>
                                                                                                                    :
                                                                                                                    <>{item.userName}</>
                                                                                                                }
                                                                                                            </BodyText>
                                                                                                        </>
                                                                                                    }
                                                                                                    toolTipContent={item.userNameTooltipContent}
                                                                                                    toolTipPlacement='top'
                                                                                                />
                                                                                                {statusDropdown === (index) ?
                                                                                                    <ChangeStatusDropdown />
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                        : null}

                                                                                </td>
                                                                                <td>
                                                                                    <div className='d-flex align-items-center justify-content-end mt-1'>
                                                                                        <CustomTooltip
                                                                                            ToolTipTrigger={
                                                                                                <>

                                                                                                    <BadgeWidget bordered={true} title={item.labelText} color={item.labelBorderColor} />
                                                                                                </>
                                                                                            }
                                                                                            toolTipContent={
                                                                                                <>
                                                                                                    <ul className="list-unstyled">
                                                                                                        {item.openCardText.map((x, i) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <li className="my-2">
                                                                                                                        <DisplayBetween key={i}>
                                                                                                                            <div className="d-flex me-2 align-items-center">
                                                                                                                                <div className='me-2'>
                                                                                                                                    <img width='20' src={x.image} />
                                                                                                                                </div>
                                                                                                                                <div className='text-nowrap'>
                                                                                                                                    <BodyText mb='0px' fontSize='12px' fontWeight='500'>{x.title}</BodyText>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <BadgeWidget bordered={true} title={item.labelText} color={item.labelBorderColor} />
                                                                                                                            <div>
                                                                                                                                <BodyText mb='0px' fontSize='12px' fontWeight='500'>{x.dateTime}</BodyText>
                                                                                                                            </div>
                                                                                                                        </DisplayBetween>
                                                                                                                    </li>
                                                                                                                </>
                                                                                                            )
                                                                                                        })}
                                                                                                    </ul>
                                                                                                </>
                                                                                            }
                                                                                            toolTipPlacement='top'
                                                                                            contentAlign='center'
                                                                                            maxWidth={'450px'}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {statusDropdown === (index) ?
                                                                                        <>
                                                                                            <GroupActionButtonWidget
                                                                                                bookMarkBtn={true}
                                                                                                noticeBtn={true}
                                                                                                isDropdownIcon={true}
                                                                                                pageName="repairPage"
                                                                                                hideStarModal={() => setShowBookMark(true)}
                                                                                                hideNoticeModal={() => setShowRememberOption(true)}
                                                                                                hidePrintPdf={() => setPrintPdfSetting(true)}
                                                                                                hideDeleteOrder={() => setDeleteOrder(true)}
                                                                                                addEditRepairModal={() => setRepairModal(true)}
                                                                                            />
                                                                                        </>
                                                                                        :
                                                                                        <DisplayBetween>
                                                                                            <DisplayStart>
                                                                                                <FaChevronDown size={12} />
                                                                                                <BodyText mb='0px' fontSize='11px' fontWeight='400' textColor='#666' pl='4px'>Normal </BodyText >
                                                                                            </DisplayStart>
                                                                                            <BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'>{item.createdDate}</BodyText >
                                                                                        </DisplayBetween>
                                                                                    }

                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    :
                                                    null}
                                            </>
                                        }
                                        handleClick={handleArrowAngle}
                                    />
                                </Col>
                            </Row>
                        </>
                    }
                </div>
            </div>

            <CommonModal
                showModal={showBookMark}
                hideModal={() => setShowBookMark(false)}
                modalTitle='Lesezeichen-Optionen'
                modalContent={<BookMarkOptionModalContent />}
                modalSize='lg'
                modalFooterContent={<BookMarkFooterContent footerModalArea='bookMarkOption' hideModal={() => setShowBookMark(false)} />}
            />

            <CommonModal
                showModal={showRememberOption}
                hideModal={() => setShowRememberOption(false)}
                modalTitle='Merk-Optionen'
                modalContent={<RememberOptionModalContent />}
                modalSize='lg'
                modalFooterContent={<BookMarkFooterContent footerModalArea='rememberOption' hideModal={() => setShowRememberOption(false)} />}
            />

            <CommonModal
                showModal={printPdfSetting}
                hideModal={() => setPrintPdfSetting(false)}
                modalTitle='PDF-Einstellungen'
                modalContent={<PdfSettingsModalContent />}
                modalSize='md'
            />

            <CommonModal
                showModal={deleteOrder}
                hideModal={() => setDeleteOrder(false)}
                modalTitle='Lösche "Wasserrohre defekt in Wartezimmer"?'
                modalContent={<DeleteRepairOrderModalContent />}
                modalSize='md'
                centered={true}
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <Button className='default-btn f-14px me-1' onClick={() => setDeleteOrder(false)}> Cancel </Button>
                            <Button variant="danger" className='f-14px'> Delete</Button>
                        </DisplayEnd>
                    </>
                }
            />

            <AddEditRepair
                showRepair={RepairModal}
                hideRepair={() => setRepairModal(false)}
            />
        </>
    )
}

type repairTaskType = {
    title: string,
    titleTooltipContent?: string,
    locationName: string,
    locationNameTooltipContent: string,
    badgeOutline: string,
    userName: string,
    userNameTooltipContent: string,


}

const NormalPriorityJson = [
    {
        title: "Wasserrohre defekt",
        titleTooltipContent: "",
        locationName: "Wartezimmer",
        locationNameTooltipContent: "Praxis Gallenbach & Zähne > EG > Wartezimmer",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent: "Zahnarzt - Praxis Gallenbach & Zähne",
        color: "#888",
        labelText: 'Nicht angenommen',
        labelBorderColor: '#888',
        openCardText: [
            {
                image: "/images/account-b.png",
                title: "Dr. Sven Markus Gallenbach",
                dateTime: "06.06.2023 18:02",
            },
        ],
        createdDate: "02. 12. 2022",
        createdDateTooltipContent: "Created: 02.12.2022 10:26",
    },
    {
        title: "repair testing",
        titleTooltipContent: "",
        locationName: "Labor",
        locationNameTooltipContent: "Praxis Gallenbach & Zähne > EG > Personalraum mit Küche > Labor",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent: "Zahnarzt - Praxis Gallenbach & Zähne",
        color: "#888",
        labelText: 'Nicht angenommen',
        labelBorderColor: '#888',
        openCardText: [
            {
                image: "/images/account-b.png",
                title: "Dr. Sven Markus Gallenbach",
                dateTime: "06.06.2023 18:02",
            },
        ],
        createdDate: "30. 11. 2022",
        createdDateTooltipContent: "Created: 30.11.2022 17:26",
    },
    {
        title: "Abdeckung locker",
        titleTooltipContent: "Praxis Gallenbach & Zähne > Elektrik > Beleuchtung > Lichtschalter > Abdeckun locker",
        locationName: "",
        locationNameTooltipContent: "",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent: "Zahnarzt - Praxis Gallenbach & Zähne",
        color: "#888",
        labelText: 'Nicht angenommen',
        labelBorderColor: '#888',
        openCardText: [
            {
                image: "/images/account-b.png",
                title: "Dr. Sven Markus Gallenbach",
                dateTime: "",
            },
        ],
        createdDate: "12. 11. 2022",
        createdDateTooltipContent: "Created: 12.11.2022 17:26",
    },
]

const NormalJsonData = [
    {
        title: "Wasserrohre defekt",
        titleTooltipContent: "",
        locationName: "Wartezimmer",
        locationNameTooltipContent: "Praxis Gallenbach & Zähne > EG > Wartezimmer",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent: "Zahnarzt - Praxis Gallenbach & Zähne",
        color: "#888",
        labelText: 'Nicht angenommen',
        labelBorderColor: '#888',
        openCardText: [
            {
                image: "/images/account-b.png",
                title: "Dr. Sven Markus Gallenbach",
                dateTime: "06.06.2023 18:02",
            },
        ],
        createdDate: "02. 12. 2022",
        createdDateTooltipContent: "Created: 02.12.2022 10:26",
    },
    {
        title: "repair testing",
        titleTooltipContent: "",
        locationName: "Labor",
        locationNameTooltipContent: "Praxis Gallenbach & Zähne > EG > Personalraum mit Küche > Labor",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent: "Zahnarzt - Praxis Gallenbach & Zähne",
        color: "#888",
        labelText: 'Nicht angenommen',
        labelBorderColor: '#888',
        openCardText: [
            {
                image: "/images/account-b.png",
                title: "Dr. Sven Markus Gallenbach",
                dateTime: "06.06.2023 18:02",
            },
        ],
        createdDate: "30. 11. 2022",
        createdDateTooltipContent: "Created: 30.11.2022 17:26",
    },
    {
        title: "Abdeckung locker",
        titleTooltipContent: "Praxis Gallenbach & Zähne > Elektrik > Beleuchtung > Lichtschalter > Abdeckun locker",
        locationName: "",
        locationNameTooltipContent: "",
        badgeOutline: "Praxis Gallenbach & Zähne",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent: "Zahnarzt - Praxis Gallenbach & Zähne",
        color: "#888",
        labelText: 'Nicht angenommen',
        labelBorderColor: '#888',
        openCardText: [
            {
                image: "/images/account-b.png",
                title: "Dr. Sven Markus Gallenbach",
                dateTime: "",
            },
        ],
        createdDate: "12. 11. 2022",
        createdDateTooltipContent: "Created: 12.11.2022 17:26",
    },
]


const ChangeStatusJson = [
    {
        title: "Erledigt markieren",
    },
    {
        title: "Annehmen",
    },
    {
        title: "Verwerfen",
    },
]


export const ChangeStatusDropdown = () => {

    return (
        <>
            <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="Status ändern">
                {ChangeStatusJson.map((item, index) => {
                    return (
                        <>
                            <Dropdown.Item key={index}>
                                <span className='f-14px'>{item.title}</span>
                            </Dropdown.Item>
                        </>
                    )
                })}
            </DropdownButton>
        </>
    )
}

interface BookMarkProps {
    title?: JsxElement | string,
    footerModalArea?: string,
    hideModal: () => void,
}

export const BookMarkFooterContent = (props: BookMarkProps) => {

    const { footerModalArea, hideModal } = props;

    return (
        <>
            {footerModalArea === 'bookMarkOption' ?
                <DisplayBetween className='w-100'>
                    <Box>
                        <ButtonWidget
                            varient='custom'
                            bgColor='#d9534f'
                            textColor='#fff'
                            bgHoverColor='#c9302c'
                            text={'Lesezeichen entfemen'}
                            handleClick={hideModal}
                        />
                    </Box>
                    <DisplayStart>
                        <CustomTooltip
                            ToolTipTrigger={
                                <Form.Check
                                    label={'Diese Optionen nicht automatisch öffnen.'}
                                    name="group1"
                                    type="checkbox"
                                />
                            }
                            toolTipContent='Diese Box Wird das nächste Mal, wenn du einen Eintrag speicherst, nicht mehr angezeigt. IJm sie zu öffnen, musst du den Button erneut drücken.'
                            toolTipPlacement='top'
                        />
                        <ButtonWidget
                            varient='light'
                            text={'Abbrechen'}
                            class='me-2 ms-3'
                            handleClick={hideModal}
                        />
                        <ButtonWidget
                            varient='custom'
                            bgColor='#0d3f5f'
                            textColor='#fff'
                            bgHoverColor='#0c3956'
                            text={'Speichem'}
                        />
                    </DisplayStart>
                </DisplayBetween>
                : null}
            {footerModalArea === 'rememberOption' ?
                <DisplayBetween className='w-100'>
                    <Box>
                        <ButtonWidget
                            varient='custom'
                            bgColor='#d9534f'
                            textColor='#fff'
                            bgHoverColor='#c9302c'
                            text={'Nicht mehr merken'}
                            handleClick={hideModal}
                        />
                    </Box>
                    <DisplayStart>
                        <CustomTooltip
                            ToolTipTrigger={
                                <Form.Check
                                    label={'Diese Optionen nicht automatisch öffnen.'}
                                    name="group1"
                                    type="checkbox"
                                />
                            }
                            toolTipContent='Diese Box Wird das nächste Mal, wenn du einen Eintrag speicherst, nicht mehr angezeigt. IJm sie zu öffnen, musst du den Button erneut drücken.'
                            toolTipPlacement='top'
                        />
                        <ButtonWidget
                            varient='light'
                            text={'Abbrechen'}
                            class='me-2 ms-3'
                            handleClick={hideModal}
                        />
                        <ButtonWidget
                            varient='custom'
                            bgColor='#0d3f5f'
                            textColor='#fff'
                            bgHoverColor='#0c3956'
                            text={'Speichem'}
                        />
                    </DisplayStart>
                </DisplayBetween>
                : null}

        </>
    )
}
