import { Badge, Table } from "react-bootstrap";
import { ImArrowRight } from "react-icons/im";

type TasksPropsType = {
    Name?: string,
    heading?: string,
    Data: any,
}

export const TodayTasksList = (props: TasksPropsType) => {
    return (
        <>
            <Table className={`${props.Name?.replace(/\s/g, '') + "table"}`}>
                <tbody>
                    {props.Data.map((item: any, index: number) => {
                        return (
                            <>
                                <tr className="" key={index}>
                                    <td className="p-1">
                                        <span className="fw-bold">{item.title}</span>
                                    </td>
                                    <td className="p-1">
                                        <span className="me-1">
                                            <ImArrowRight />
                                        </span>
                                        <span className="ng-badge">{item.badge1}</span>
                                        <div className='d-inline-flex'>
                                            <span className='mx-1'>,</span>
                                            <span className='ng-badge'>{item.badge2}</span>
                                        </div>
                                    </td>
                                    <td className="p-1 text-end w-23">
                                        {item.labelColor === "blue" ? (
                                            <div className="d-flex border rounded">
                                                <span className="border-sky mx-2"></span>
                                                <span className="text-sky text-nowrap me-2 fw-400">
                                                    {item.labelText}
                                                </span>
                                            </div>
                                        ) : null}
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </Table>
        </>
    )
}