import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Sidebar from '../Message/SideBar';
import { Outlet } from "react-router-dom";
import { TopbarSelectFilterDropdown } from '../../Components/TopbarSelectFilterDropdown';
import { TdisplayMode } from '../Message/Index';

export interface IdeaData {
    title: string,
    color: string,
    badgeText: string,
    userName: string,
    userNameTooltip: string,
    userTrack: string,
    userTrackTooltip: string,
    role: string,
    dateTime: string,
    limitedVisibilty: boolean,
    limitedVisibiltyTooltip: string,
    attachment: boolean,
    attachmentTooltip: string,
    noticed: boolean,
    noticedTooltip: string,
    image: string,
    isChecked: boolean,
}

const IdeaData = [
    {
        title: "Ideen in Diskussion",
        color: "#fbd300",
        badgeText: "Praxis Gallenbach & Zähne",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        userTrack: "Team, Aus- und Fortbildung",
        userTrackTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        role: "Team, Aus- und Fortbildung",
        dateTime: "05.12.2022 11:46",
        limitedVisibilty: true,
        limitedVisibiltyTooltip: "Sichtbarkeit eingeschränkt",
        attachment: false,
        attachmentTooltip: "",
        noticed: false,
        noticedTooltip: "",
        image: "/images/graph-img.png",
        isChecked: false,
    },
    {
        title: "idea of marketing",
        color: "#077ce0",
        badgeText: "Praxis Gallenbach & Zähne",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        userTrack: "Dr. Sven Markus Gallenbach",
        userTrackTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        role: "Marketing",
        dateTime: "22.11.2022 12:12",
        limitedVisibilty: false,
        limitedVisibiltyTooltip: "",
        attachment: true,
        attachmentTooltip: "Anhang",
        noticed: true,
        noticedTooltip: "gemerkt",
        image: "/images/graph-img.png",
        isChecked: false,
    },
    {
        title: "Titel Idee",
        color: "#fc9136",
        badgeText: "Praxis Gallenbach & Zähne",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        userTrack: "Dr. Sven Markus Gallenbach",
        userTrackTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        role: "Marketing",
        dateTime: "22.11.2022 12:12",
        limitedVisibilty: true,
        limitedVisibiltyTooltip: "Sichtbarkeit eingeschränkt",
        attachment: false,
        attachmentTooltip: "",
        noticed: false,
        noticedTooltip: "",
        image: "/images/graph-img.png",
        isChecked: false,
    },
    {
        title: "idea of marketing",
        color: "#ed6162",
        badgeText: "Praxis Gallenbach & Zähne",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        userTrack: "Dr. Sven Markus Gallenbach",
        userTrackTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        role: "Marketing",
        dateTime: "22.11.2022 12:12",
        limitedVisibilty: false,
        limitedVisibiltyTooltip: "",
        attachment: true,
        attachmentTooltip: "Anhang",
        noticed: false,
        noticedTooltip: "",
        image: "/images/graph-img.png",
        isChecked: false,
    },
    {
        title: "Ideen in Diskussion",
        color: "#80c35c",
        badgeText: "Praxis Gallenbach & Zähne",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        userTrack: "Team, Aus- und Fortbildung",
        userTrackTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        role: "Team, Aus- und Fortbildung",
        dateTime: "05.12.2022 11:46",
        limitedVisibilty: true,
        limitedVisibiltyTooltip: "Sichtbarkeit eingeschränkt",
        attachment: false,
        attachmentTooltip: "",
        noticed: true,
        noticedTooltip: "gemerkt",
        image: "/images/graph-img.png",
        isChecked: false,
    },
]


export const IdeaIndex = () => {

    const [showIdeaData, setShowIdeaData] = useState<IdeaData[]>([]);
    const [isShowChecks, setIsShowChecks] = useState<boolean>(false);
    const [checkAllEntries, setCheckAllEntries] = useState<boolean>(false);
    const [totalAccount, setTotalAccount] = useState<number>(0);
    const [displayMode, setDisplayMode] = useState<TdisplayMode>({ displayNormal: true, displayCompact: false, displaySplit: false });


    useEffect(() => {
        setShowIdeaData(IdeaData);
    }, []);


    const HandleSelectAll = () => {
        const tempArray = [...showIdeaData];
        for (var i = 0; i < tempArray.length; i++) {
            tempArray[i].isChecked = true;
        }
        setShowIdeaData(tempArray);
        setTotalAccount(tempArray.length);
    }

    const handleToggleEntryCheck = () => {
        setIsShowChecks(!isShowChecks);
        const tempArray = [...showIdeaData];
        for (var i = 0; i < tempArray.length; i++) {
            tempArray[i].isChecked = false;
        }
        setShowIdeaData(tempArray);
        setTotalAccount(0);
    }

    const handleDisplayMode = (event: React.MouseEvent) => {
        var valueAttr = event.currentTarget.attributes.getNamedItem('value');
        var value = valueAttr ? valueAttr.value : null;
        if (value === 'normal') {
            var temp: TdisplayMode = { ...displayMode };
            temp.displayNormal = true;
            temp.displayCompact = false;
            temp.displaySplit = false;

            setDisplayMode(temp);

        } else {
            var temp: TdisplayMode = { ...displayMode };
            temp.displayCompact = true;
            temp.displayNormal = false;
            temp.displaySplit = false;

            setDisplayMode(temp);
        }
    }


    return (
        <>
            <Row>
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <div className="news-topbar mb-2">
                        <TopbarSelectFilterDropdown
                            showHideEntryCheck={handleToggleEntryCheck}
                            showChecks={isShowChecks}
                            checkAllEntries={checkAllEntries}
                            handleSelectAll={HandleSelectAll}
                            displayMode={displayMode}
                            handleDisplayMode={handleDisplayMode}
                            totalAccount={totalAccount}
                        />
                    </div>
                    <Outlet context={[showIdeaData, setShowIdeaData, displayMode, isShowChecks, setTotalAccount]} />
                </Col>
            </Row>
        </>
    )
}
