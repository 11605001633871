import React, { useState, useEffect, FC, useRef } from 'react';
import { Row, Col, Form, Button, DropdownButton, Dropdown, Table } from 'react-bootstrap';
import ButtonWidget from '../../../Components/ButtonWidget';
import CommonModal from '../../../Components/Modals/CommonModal';
import { UserAdminModal } from '../AdminModal/UserAdminModal';
import Card from 'react-bootstrap/Card';
import { BodyText, Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart } from '../../../Components/StyledComponents';
import BadgeWidget from '../../../Components/BadgeWidget';
import CustomTooltip from '../../../Components/Tooltip';
import { FaPencilAlt } from 'react-icons/fa';
import FormSelect from '../../../Components/FormSelect';
import { UserData, AllUsers, statusOptions, roleOptions } from '../../../CommonJSON';


export const UserList = () => {

    const formRef = useRef();
    const [addUserAdmin, setAddUserAdmin] = useState<boolean>(false);

    return (
        <>
            <div className='admin-user-list'>
                <div className='topbar-user-section mb-3'>
                    <DisplayBetween>
                        <DisplayStart>
                            <Box>
                                <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="Alle Nutzer">
                                    {AllUsers.map((item, index) => {
                                        return (
                                            <>
                                                <Dropdown.Item key={index}>
                                                    <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                                </Dropdown.Item>
                                            </>
                                        )
                                    })}
                                </DropdownButton>
                            </Box>
                            <Box width='500px'>
                                <Form className="d-flex">
                                    <Form.Control
                                        type="search"
                                        placeholder="Nach Gruppen filtern / Nutzernamen suchen"
                                        className="me-0 rounded-end-0 py-0"
                                        aria-label="Search"
                                    />
                                    <Button className='default-btn f-14px fw-500 rounded-end rounded-start-0' > Suchen </Button>
                                </Form>
                            </Box>
                        </DisplayStart>
                        <Box>
                            <ButtonWidget text={"Nutzer hinzufügen"} varient='primary' handleClick={() => setAddUserAdmin(true)} />
                        </Box>
                    </DisplayBetween>
                </div>
                <Card>
                    <Card.Body className=''>
                        <div className='table-users'>
                            {UserData.map((item, index) => {
                                return (
                                    <>
                                        <Row className='border-bottom my-2' key={index}>
                                            <Col md={6} xs={12}>
                                                <Box>
                                                    <DisplayStart>
                                                        <Box mr="10px"> <img src={item.userImage} height="25" width="25" alt="user-image" /></Box>
                                                        <BodyText fontSize="14px" fontWeight="700" textColor="#000" mr="6px"> {item.userName} </BodyText>
                                                        {item.nameBadgeCircle ?
                                                            <BadgeWidget outiline={true} title={item.nameBadgeCircle} color={"#666"} bgColor={"#666"} />
                                                            : null}
                                                        <BodyText fontSize="12px" fontWeight="600" textColor="#666" ml="4px" mr="6px" className='d-inline'> {item.roleInfo} </BodyText>
                                                        <FaPencilAlt size={14} title="Profil bearbeiten" />
                                                    </DisplayStart>
                                                </Box>
                                            </Col>
                                            <Col md={3} xs={12}>
                                                <Box>
                                                    <DisplayStart>
                                                        {item.fillBadgeText1 ?
                                                            <Box mr="5px">
                                                                <BadgeWidget solid={true} title={item.fillBadgeText1} color={"#0d3f5f"} bgColor={"#d2eaf9"} />
                                                            </Box>
                                                            : null}
                                                        {item.fillBadgeText2 ?
                                                            <Box mr="5px">
                                                                <BadgeWidget solid={true} title={item.fillBadgeText2} color={"#0d3f5f"} bgColor={"#d2eaf9"} />
                                                            </Box>
                                                            : null}
                                                        {item.fillBadgeText3 ?
                                                            <Box>
                                                                <BadgeWidget solid={true} title={item.fillBadgeText3} color={"#0d3f5f"} bgColor={"#d2eaf9"} />
                                                            </Box>
                                                            : null}
                                                    </DisplayStart>
                                                </Box>
                                            </Col>
                                            <Col md={3} xs={12}>
                                                <DisplayCenter>
                                                    <Box pr="8px">
                                                        <FormSelect
                                                            isFormGroup={true}
                                                            selectOptions={statusOptions}
                                                            Size='sm'
                                                            Name='salute'
                                                            Id='salute'
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <FormSelect
                                                            isFormGroup={true}
                                                            selectOptions={roleOptions}
                                                            Size='sm'
                                                            Name='salute'
                                                            Id='salute'
                                                        />
                                                    </Box>
                                                </DisplayCenter>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            })}
                        </div>
                    </Card.Body>
                </Card>
            </div>


            <CommonModal
                showModal={addUserAdmin}
                hideModal={() => setAddUserAdmin(false)}
                modalTitle='Nutzer erstellen'
                modalContent={<UserAdminModal formRef={formRef} />}
                modalSize='lg'
                footerClass={"footer-sticky"}
                reference={formRef}
            />
        </>
    )
}