import React, { useEffect, useState, FC, Dispatch, SetStateAction, MouseEvent, } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import CustomTooltip from '../../Components/Tooltip';
import { FaLongArrowAltRight, FaLock, FaBookmark } from "react-icons/fa";
import { BodyText, DisplayEnd, MultiColorBox } from '../../Components/StyledComponents';
import FormCheck from '../../Components/FormCheck';
import { CgAttachment } from "react-icons/cg";
import { TopbarSelectFilterDropdown } from '../../Components/TopbarSelectFilterDropdown';
import BadgeWidget from '../../Components/BadgeWidget';
import CommonModal from '../../Components/Modals/CommonModal';
import ButtonWidget from '../../Components/ButtonWidget';
import { SendReminderModalContent } from '../../Components/Modals/ModelContents/SendReminderModalContent';
import { IdeaData } from './IdeaIndex';
import { useNavigate } from 'react-router-dom';
import { TdisplayMode } from '../Message/Index';

interface IProps {
    listData: IdeaData[],
    setListData: Dispatch<SetStateAction<IdeaData[]>>,
    displayMode: TdisplayMode,
    showChecks: boolean,
    setCheckCount: Dispatch<SetStateAction<number>>
}


export const IdeaList: FC<IProps> = ({ listData, setListData, displayMode, showChecks, setCheckCount }) => {

    function truncate(source: string, size: number) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }

    const navigate = useNavigate();
    const [sendReminder, setSendReminder] = useState<boolean>(false);


    const HandleCheckbox = (index: number, event: MouseEvent<HTMLInputElement>) => {
        debugger
        let temp = [...listData];
        var count: number = 0;
        temp[index].isChecked = !temp[index].isChecked;

        for (var i = 0; i < listData.length; i++) {
            if (listData[i].isChecked === true) {
                count++;
            }
        }
        setCheckCount(count);
        setListData(temp);
    }
    const handleRowClick = (event: MouseEvent<HTMLElement>) => {
        navigate('/ideas/ideas-detial');
    }
    const handleClickGraph = (event: React.MouseEvent<HTMLTableCellElement>) => {
        event.stopPropagation();
        setSendReminder(true);
    }

    return (
        <>
            <div className='idea'>
                <div className="idea-table mt-2">
                    <Card className='boxShadow'>
                        <Card.Body>
                            <Table responsive hover className='mb-0'>
                                <tbody>
                                    {listData.map((item, index) => {
                                        return (
                                            <>
                                                <tr key={index} onClick={(event: MouseEvent<HTMLElement>) => handleRowClick(event)} className='cursor-pointer'>
                                                    {showChecks ?
                                                        <td width='30'>
                                                            <FormCheck
                                                                type='checkbox'
                                                                name='phone'
                                                                Id='phone'
                                                                value={item.title}
                                                                label=''
                                                                isFormGroup={false}
                                                                isInline={true}
                                                                checked={item.isChecked}
                                                                handleClick={(event: MouseEvent<HTMLInputElement>) => {
                                                                    HandleCheckbox(index, event);
                                                                    event.stopPropagation();
                                                                }}
                                                            />
                                                        </td>
                                                        : null}
                                                    <td width='40'>
                                                        <MultiColorBox bgcolor={item.color} mr='10px'></MultiColorBox>
                                                    </td>
                                                    <td>
                                                        <div className='msg-title'>
                                                            <BodyText mb='0px' fontSize='1.1em' fontWeight='700' textColor='#000'> {item.title} </BodyText>
                                                        </div>
                                                        {displayMode.displayNormal ?
                                                            <>
                                                                <div className='d-flex align-items-center mt-1'>
                                                                    <BadgeWidget outiline={true} title={item.badgeText} color='#0d3f5f' bgColor='#fff' />
                                                                    <div>
                                                                        <CustomTooltip
                                                                            ToolTipTrigger={<BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'> {item.userName} </BodyText>}
                                                                            toolTipContent={item.userNameTooltip}
                                                                            toolTipPlacement='top'
                                                                        />
                                                                    </div>
                                                                    <FaLongArrowAltRight className='mx-2' size="14px" />
                                                                    <div>
                                                                        <CustomTooltip
                                                                            ToolTipTrigger={<BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666'> {truncate(item.userTrack, 14)} </BodyText>}
                                                                            toolTipContent={item.userNameTooltip}
                                                                            toolTipPlacement='top'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null}
                                                    </td>
                                                    <td width='130'>
                                                        <BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666'> {truncate('Team, Aus- und Fortbildung', 16)} </BodyText>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            {item.limitedVisibilty ?
                                                                <CustomTooltip
                                                                    ToolTipTrigger={
                                                                        <>
                                                                            <FaLock className='ms-1' size="14px" />
                                                                        </>
                                                                    }
                                                                    toolTipContent={item.limitedVisibiltyTooltip}
                                                                    toolTipPlacement='top'
                                                                />
                                                                : null}
                                                            {item.attachment ?
                                                                <CustomTooltip
                                                                    ToolTipTrigger={
                                                                        <>
                                                                            <CgAttachment className='ms-1' size="14px" />
                                                                        </>
                                                                    }
                                                                    toolTipContent={item.attachmentTooltip}
                                                                    toolTipPlacement='top'
                                                                />
                                                                : null}
                                                            {item.noticed ?
                                                                <CustomTooltip
                                                                    ToolTipTrigger={
                                                                        <>
                                                                            <FaBookmark className='ms-1' size="14px" />
                                                                        </>
                                                                    }
                                                                    toolTipContent={item.noticedTooltip}
                                                                    toolTipPlacement='top'
                                                                />
                                                                : null}
                                                        </div>
                                                    </td>
                                                    <td width='150'>
                                                        <BodyText fontSize='12px' fontWeight='400' textColor='#666'> {item.dateTime} </BodyText>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex align-items-center justify-content-center mt-1' onClick={(event: React.MouseEvent<HTMLTableCellElement>) => handleClickGraph(event)}>
                                                            <img src={item.image} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <CommonModal
                showModal={sendReminder}
                hideModal={() => setSendReminder(false)}
                modalTitle='Erinnerung senden'
                modalContent={<SendReminderModalContent />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Schließen'}
                                class='ms-3'
                                handleClick={() => setSendReminder(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Ausgewählte erinnem'}
                                disabled={true}
                            />
                        </DisplayEnd>
                    </>
                }
                footerClass={"footer-sticky"}
            />
        </>
    )
}