import { MouseEvent, useState } from 'react';
import { AccountLink, AccountPopoverWrapper, AccountTitle, BorderBox, Box, DisplayBetween, DisplayCenter, ProfileImg } from '../../Components/StyledComponents';
import CommonModal from '../../Components/Modals/CommonModal';
import AuthService from '../../APIService/authService';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import ButtonWidget from '../../Components/ButtonWidget';
import CustomTooltip from '../../Components/Tooltip';

function AccountPopoverContent(props: any) {


    const authService = new AuthService();
    const [cookies, setCookie, removeCookie] = useCookies(['authToken', 'userImage']);
    let navigate = useNavigate();

    const handleLogoutClick = () => {
        authService.logout(removeCookie, navigate);
        props.closeRef.current.setShow(false);
    }

    const handleAdminClick = (event: MouseEvent<HTMLElement>) => {
        navigate('/admin');
    }

    const accountSettingClickHandler = (event: React.MouseEvent | React.TouchEvent) => {
        props.accountModalShow(true)
        props.closeRef.current.setShow(false);
    }
    const securityCenterClickHandler = (event: React.MouseEvent | React.TouchEvent) => {
        props.showSecurityModal(true)
        props.closeRef.current.setShow(false);
    }
    const notificationClickHandler = (event: React.MouseEvent | React.TouchEvent) => {
        props.showNotificationModal(true)
        props.closeRef.current.setShow(false);
    }
    const blockClickHandler = (event: React.MouseEvent | React.TouchEvent) => {
        props.blockUserModal(true)
        props.closeRef.current.setShow(false);
    }



    return (
        <>
            <AccountPopoverWrapper onClick={() => props.closeRef.current.setShow(false)}>
                <AccountTitle>
                    <CustomTooltip
                        ToolTipTrigger={"Dr Sven Markus Gallenbach"}
                        toolTipContent='Zannarzt - Praxis Gallenoach & Zähne'
                        toolTipPlacement='top'
                        contentAlign='center'
                    />
                </AccountTitle>
                <AccountLink className='cursor-pointer' onClick={(event) => accountSettingClickHandler(event)}>
                    Konto-EinsteIIungen
                </AccountLink>
                <AccountLink className='cursor-pointer' onClick={(event) => notificationClickHandler(event)}>
                    Benachrichtigungseinstellungen
                </AccountLink>
                <AccountLink className='cursor-pointer' onClick={(event) => blockClickHandler(event)}>
                    Blockierte Nutzer
                </AccountLink>
                <AccountLink className='cursor-pointer' onClick={(event) => securityCenterClickHandler(event)}>
                    Sicherheitscenter
                </AccountLink>
                <AccountLink className='cursor-pointer' onClick={() => props.closeRef.current.setShow(false)}>
                    Datenschutz-RichtIinie
                </AccountLink>
                <BorderBox borderBottom='none' pt='10px' mt='10px' pr='10px' pl='10px'>
                    <DisplayBetween>
                        <ButtonWidget
                            varient='light'
                            text={
                                <>
                                    <DisplayCenter>
                                        Admin
                                    </DisplayCenter>
                                </>
                            }
                            handleClick={(event: MouseEvent<HTMLElement>) => handleAdminClick(event)}
                        />
                        <ButtonWidget
                            varient='light'
                            text={
                                <>
                                    <DisplayCenter>
                                        Abmelden
                                    </DisplayCenter>
                                </>
                            }
                            handleClick={handleLogoutClick}
                        />
                    </DisplayBetween>
                </BorderBox>
                <ProfileImg src='/images/account-b.png' />
            </AccountPopoverWrapper>


        </>
    )
}

export default AccountPopoverContent;