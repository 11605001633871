import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { FaImage } from "react-icons/fa";
function Moments() {
    const [Show, setShow] = useState(false);
    return (
        <>
            <Card>
                <Card.Body >
                    <div className='py-3' onClick={() => setShow(true)}>
                        {Show ? <Discription /> : <ShareMoment />}
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className="image-upload me-1">
                            <input type="file" accept="image/*" id="imageInput" />
                            <label htmlFor="imageInput" className="image-button"><FaImage className='icon' /> Choose image</label>
                            <img src="" className="image-preview" />
                            <span className="change-image">Choose different image</span>
                        </div>
                        <div className='video-upload ms-1'>
                            <input type="file" accept="video/*" id="imageVideo" />
                            <label htmlFor="imageVideo" className="image-button"><FaImage className='icon' /> Choose Video</label>
                            <img src="" className="image-preview" />
                            <span className="change-image">Choose different image</span>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

const ShareMoment = () => {
    return (
        <>
            <div className='d-flex align-items-center'>
                <div>
                    <img width="50" src="/images/account.png" />
                </div>
                <div className='ms-3'>
                    <p className="m-0 fs-5 fw-600">Teile deinen neuesten Moment...</p>
                </div>
            </div>
        </>
    )
}

const Discription = () => {
    return (
        <>
            <div className='d-flex'>
                <div>
                    <img width="50" src="/images/account.png" />
                </div>
                <div className='ms-3' style={{ width: "100%" }}>
                    <p className="m-0 fs-6 fw-bold">Dr. Sven Markus Gallenbach</p>
                    <Form.Group className="my-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                </div>
            </div>

        </>
    )
}

export default Moments;