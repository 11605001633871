import React, { useEffect, useState, useRef } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { loginApi } from "../../../Api";
import { LoginDto } from "../../../Model/ViewModels/Login/loginDto";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import classNames from "classnames";
import AuthService from "../../../APIService/authService";
import toast, { Toaster } from "react-hot-toast";
import jwtDecode from "jwt-decode";
import { setUserName } from "../../../Redux/Slice/userSlice";
import { setShowLoader } from "../../../Redux/Slice/appSlice";

const formSchema = yup
  .object({
    userName: yup
      .string()
      .required("Email is required")
      .email("Invalid Email Address"),
    password: yup.string().required("Password is required"),
  })
  .required();

export default function Login() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "authToken",
    "userImage",
    "userName",
  ]);
  const authService = new AuthService();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let logBtn = useRef<HTMLButtonElement>(null);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
    formState,
  } = useForm<LoginDto>({
    defaultValues: new LoginDto(),
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });

  const onSubmit: SubmitHandler<LoginDto> = async (
    data: LoginDto,
    event: any
  ) => {
    if (data) {
      dispatch(setShowLoader(true));
      authService
        .login(data)
        .then((result) => {
          dispatch(setUserName(result.userName));
          setCookie("authToken", result.token, { path: "/", maxAge: 7200 });
          setCookie("userImage", result.userImage, { path: "/", maxAge: 7200 });
          setCookie("userName", result.userName, { path: "/", maxAge: 7200 });
          navigate("/home", { replace: true });
          //authService.getUser();
          dispatch(setShowLoader(false));
          toast.success("Login Successful");
        })
        .catch((error) => {
          dispatch(setShowLoader(false));
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.message);
          }
        });
    }
  };

  useEffect(() => {
    onEnter();
  }, []);

  const onEnter = () => {
    document.addEventListener("keydown", function (event) {
      if (event.keyCode === 13) {
        logBtn.current?.click();
      }
    });
  };

  return (
    <>
      <Card className="shadow border-0">
        <Card.Body className="py-5">
          <h6 className="text-center m-0">
            Anmeldung | medikit | Praxis Gallenbach & Zähne
          </h6>
          <div className="w-75 mx-auto mt-4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group
                controlId="formBasicEmail"
                className={classNames("mb-3 form-group", {
                  "has-error": errors.userName,
                })}
              >
                <Form.Control
                  type="email"
                  placeholder="Email"
                  autoFocus
                  isInvalid={!!formState.errors.userName}
                  {...register("userName")}
                />
                {formState.errors.userName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.userName?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group
                controlId="formBasicPassword"
                className={classNames("mb-3 form-group", {
                  "has-error": errors.password,
                })}
              >
                <Form.Control
                  type="password"
                  placeholder="Password"
                  isInvalid={!!formState.errors.password}
                  {...register("password")}
                />
                {formState.errors.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <div className="d-grid gap-2">
                <Button
                  ref={logBtn}
                  className="btn primary-bg"
                  type="submit"
                  disabled={!formState.isValid}
                >
                  Anmelden
                </Button>
              </div>
            </Form>
            <div className="mt-4">
              <p className="m-0 text-center">
                <Link
                  className="primary-color text-decoration-none fw-500 hover-underline"
                  to="/forgetpassword"
                >
                  Passwort vergessen?
                </Link>
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
