import React, { MouseEvent, useEffect, useState } from 'react';
import { BodyText, Box, DisplayEnd, DisplayStart } from '../../StyledComponents';
import { Card, Table } from 'react-bootstrap';
import FormCheck from '../../FormCheck';
import CustomTooltip from '../../Tooltip';
import { Link } from 'react-router-dom';
import ButtonWidget from '../../ButtonWidget';
import CommonModal from '../CommonModal';
import { DeleteVersionModalContent } from './DeleteVersionModalContent';

const CompareData = [
    {
        isCheck: false,
        version: "Version 3",
        title: "Die 10 Gebote unserer Serviceorientierung",
        subTitle: "",
        name: "Dr. Sven Markus Gallenbach",
        nameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        undoDelete: "",
        dateTime: "08.12.2022 11:15",
        openBtn: 'Öffnen',
        comparsionBtn: 'Vergleiche',
    },
    {
        isCheck: false,
        version: "Version 2",
        title: "Die 10 Gebote unserer Serviceorientierung",
        subTitle: "zum Löschen markiert von Dr. Sven Markus Gallenbach - 27.05.2023 16:54",
        name: "",
        nameTooltip: "",
        undoDelete: "Löschen annulieren",
        dateTime: "",
        openBtn: 'Öffnen',
        comparsionBtn: 'Vergleiche',
    },
    {
        isCheck: false,
        version: "Version 1",
        title: "Die 10 Gebote unserer Serviceorientierung",
        subTitle: "",
        name: "Dr. Sven Markus Gallenbach",
        nameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        undoDelete: "",
        dateTime: "15.06.2021 20:03",
        openBtn: 'Öffnen',
        comparsionBtn: 'Vergleiche',
    },
]


export const CompareVersionModalContent = () => {

    const [interruptBtn, setInterruptBtn] = useState<boolean>(false);
    const [deleteVersion, setDeleteVersion] = useState<boolean>(false);
    const [isShowChecks, setIsShowChecks] = useState<boolean>(false);

    const handleInterruptBtn = () => {
        setInterruptBtn(!interruptBtn);
    }

    const handleToggleEntryCheck = () => {
        setIsShowChecks(!isShowChecks);
        const tempArray = [...CompareData];
        for (var i = 0; i < tempArray.length; i++) {
            tempArray[i].isCheck = false;
        }
    }

    return (
        <>
            <Box className='p-1'>
                <BodyText fontSize='13px' fontWeight='400' textColor='#666'>
                    Diesen Kommentar löschen?
                </BodyText>
                <Table className='mb-0'>
                    <tbody>
                        {CompareData.map((item, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <td>
                                            <DisplayStart>
                                                <FormCheck
                                                    type='checkbox'
                                                    name='phone'
                                                    Id='phone'
                                                    value={item.version}
                                                    label=''
                                                    isFormGroup={false}
                                                    isInline={false}
                                                    checked={item.isCheck}
                                                    handleClick={handleToggleEntryCheck}
                                                />
                                                <BodyText mb='0px' fontSize='13px' fontWeight='400' textColor='#000'> {item.version} </BodyText>
                                            </DisplayStart>
                                        </td>
                                        <td>
                                            <BodyText mb='0px' fontSize='13px' fontWeight='400' textColor='#000'> {item.title} </BodyText>
                                            {item.subTitle ?
                                                <BodyText mb='0px' fontSize='13px' fontWeight='400' textColor='#ff0000'> {item.subTitle} </BodyText>
                                                : null}
                                        </td>
                                        <td>
                                            <Box>
                                                <CustomTooltip
                                                    ToolTipTrigger={<Link to="" className='text-decoration-none'>
                                                        <span className='fw-400 w-176px text-truncate text-link-color f-12px'> {item.name} </span>
                                                    </Link>}
                                                    toolTipContent={item.nameTooltip}
                                                    toolTipPlacement='top'
                                                    contentAlign='center'
                                                />
                                                {item.undoDelete ?
                                                    <ButtonWidget
                                                        varient='light'
                                                        text={item.undoDelete}
                                                        class='me-2 f-12px'
                                                        handleClick={() => setDeleteVersion(true)}
                                                    />
                                                    : null}
                                            </Box>
                                        </td>
                                        <td>
                                            <BodyText mb='0px' fontSize='13px' fontWeight='400' textColor='#000'> {item.dateTime} </BodyText>
                                        </td>
                                        <td>
                                            <DisplayEnd>
                                                {interruptBtn ?
                                                    <CustomTooltip
                                                        ToolTipTrigger={<ButtonWidget
                                                            varient='light'
                                                            text={'Abbrechen'}
                                                            class='f-12px'
                                                            handleClick={handleInterruptBtn}
                                                        />}
                                                        toolTipContent={'Vergleich abbrechen'}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    :
                                                    <>
                                                        <Box>
                                                            <ButtonWidget
                                                                varient='light'
                                                                text={item.openBtn}
                                                                class='me-2 f-12px'
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <ButtonWidget
                                                                varient='light'
                                                                text={item.comparsionBtn}
                                                                class='f-12px'
                                                                handleClick={handleInterruptBtn}
                                                            />
                                                        </Box>
                                                    </>
                                                }
                                            </DisplayEnd>
                                        </td>
                                    </tr>
                                </>
                            )
                        })}

                    </tbody>
                </Table>
            </Box>

            <CommonModal
                showModal={deleteVersion}
                hideModal={() => setDeleteVersion(false)}
                modalTitle='Artikel Version nicht löschen'
                modalContent={<DeleteVersionModalContent />}
                modalSize='md'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <Box>
                                <ButtonWidget
                                    varient='light'
                                    text={'Abbrechen'}
                                    class='me-2 f-12px'
                                    handleClick={() => setDeleteVersion(false)}
                                />
                            </Box>
                            <Box>
                                <ButtonWidget
                                    varient='danger'
                                    text={'Löschen annulieren'}
                                    class='f-12px'
                                />
                            </Box>
                        </DisplayEnd>
                    </>
                }
                centered={true}
            />

        </>
    )
}