import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Sidebar } from './Sidebar';
import { MainContent } from './MainContent';

export const MarketIndex = () => {
    return (
        <>
            <Row>
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <MainContent />
                </Col>
            </Row>
        </>
    )
}