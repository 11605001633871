import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ListsOfEntries from '../ListsOfEntries';
import { NotedModule } from '../DashboardModules/NotedModule';
import BookmarksWidget from '../Widgets/BookmarksWidgets';
import EventsWidget from '../DashboardModules/EventsModule';
import { Card } from 'react-bootstrap';
import DoughnutChart from '../Charts/DoughnutChart';
import BarChart from '../Charts/BarChart';
import AreaChart from '../Charts/AreaChart';
import { LatestNewsList } from '../ListOfEntries/LatestNewsList';
import { LatestIdeasList } from '../ListOfEntries/LatestIdeasList';
import { TodayTasksList } from '../ListOfEntries/TodayTasksList';
import { OverdueTasksList } from '../ListOfEntries/OverdueTasksList';
import { LatestRepairOrderList } from '../ListOfEntries/LatestRepairOrderList';
import { selectedModuleType } from '../../App';


interface IProps {
    handleSelectModule: (a: string, b: string) => void,
    showAddModule: boolean,
    hideAddModule: () => void,
}

const AddModuleModal: FC<IProps> = ({ handleSelectModule, showAddModule, hideAddModule }) => {
    const [EveryDay, setEveryDay] = useState(true);
    const [ListEntries, setListEntries] = useState(false);
    const [Statistics, setStatistics] = useState(false);


    const onSelectModule = (key: string, module: string) => {
        debugger
        handleSelectModule(key, module);
    }
    const handleEveryDayChange = () => {
        setEveryDay(true);
        setListEntries(false);
        setStatistics(false);
    }
    const handleListEntries = () => {
        setListEntries(true);
        setEveryDay(false);
        setStatistics(false);
    }
    const handlesatatics = () => {
        setStatistics(true);
        setListEntries(false);
        setEveryDay(false);
    }

    type LatestNewsProps = {
        title: string,
        countBadge?: string,
        dateTime: string,
    }

    const LatestNews: LatestNewsProps[] = [
        {
            title: 'message template for quality testing',
            countBadge: '',
            dateTime: '8 days ago',
        },
        {
            title: 'Medikit as a news portal better than emails and print....',
            countBadge: '1',
            dateTime: '10.04.2022 19:14',
        },
        {
            title: 'Welcome to our team',
            countBadge: '2',
            dateTime: '09.04.2022 15:38',
        },
        {
            title: 'Tips for getting started',
            countBadge: '',
            dateTime: '29.06.2021 12:37',
        },
        {
            title: 'OPTI-QM-Flat: Neue Dokumente (07/2022)',
            countBadge: '',
            dateTime: '10.11.2022 14:45',
        },
    ]


    const LatestNews2: LatestNewsProps[] = [
        {
            title: 'news for quality',
            dateTime: 'vor 9 Tagen',
        },
        {
            title: 'ASCJsacd',
            dateTime: '15.11.2022 14:41',
        },
        {
            title: 'OPTI-QM-Flat: Neue Dokumente (09/2022)',
            dateTime: '10.11.2022 14:45',
        },
        {
            title: 'OPTI-QM-Flat: Neue Dokumente (07/2022)',
            dateTime: '10.11.2022 14:45',
        },
        {
            title: 'OPTI-QM-Flat: Neue Dokumente (06/2022)',
            dateTime: '10.11.2022 14:44',
        },
        {
            title: 'OPTI-QM-Flat: Änderung Bereitstellung',
            dateTime: '10.11.2022 14:42',
        },
        {
            title: 'OPTI-QM-Flat: Neue Dokumente (04/2022)',
            dateTime: '17.06.2022 11:27',
        },
        {
            title: 'NEU: OPTI-Update-Info',
            dateTime: '17.06.2022 11:25',
        },
        {
            title: 'Titel3',
            dateTime: '12.07.2021 02:07',
        },
        {
            title: 'Jetzt alle medikit Tools entdecken!',
            dateTime: '29.06.2021 12:38',
        },
    ]

    type LatestIdeasProps = {
        title: string,
        dateTime: string,
        color: string,
    }

    const LatestIdeas: LatestIdeasProps[] = [
        {
            title: 'testing idea of creating',
            dateTime: 'vor 10 Tagen',
            color: 'blue',
        },
        {
            title: 'idea of marketing',
            dateTime: '22.11.2022 10:46',
            color: 'yellow',
        },
        {
            title: 'Titel Idee',
            dateTime: '12.11.2022 18:04',
            color: 'yellow',
        },
    ]

    type TodayTasksProps = {
        title: string,
        badge1: string,
        badge2: string,
        labelText: string,
        labelColor: string,
    }

    const TodayTasks: TodayTasksProps[] = [
        {
            title: 'Rückinfo  08.12',
            badge1: 'Verwaltung',
            badge2: 'Zahnärzte',
            labelText: 'In Arbeit',
            labelColor: 'blue',
        },
        {
            title: 'Erneute Rückinfos',
            badge1: 'Verwaltung',
            badge2: 'Zahnärzte',
            labelText: 'In Arbeit',
            labelColor: 'blue',
        },
    ]

    type OverdueTasksProps = {
        title: string,
        badge1: string,
        badgeTooltip: string,
        noBadge: string,
        color: string,
        labelText: string,
        labelColor: string,
    }

    const OverdueTasks: OverdueTasksProps[] = [
        {
            title: 'Rückinfo 03.06.',
            badge1: 'Zahnärzte',
            badgeTooltip: '',
            noBadge: '',
            color: 'dark',
            labelText: 'Nicht angenommen',
            labelColor: 'dark',
        },
        {
            title: 'Rückinfo 15.06',
            badge1: 'Zahnärzte',
            badgeTooltip: '',
            noBadge: '',
            color: 'dark',
            labelText: 'Nicht angenommen',
            labelColor: 'dark',
        },
        {
            title: 'Rückinfo 15.06',
            badge1: 'Zahnärzte',
            badgeTooltip: '',
            noBadge: '',
            color: 'dark',
            labelText: 'Nicht angenommen',
            labelColor: 'dark',
        },
        {
            title: 'Termin ausmache :-)',
            badge1: '',
            badgeTooltip: '',
            noBadge: 'Dr. Sven Markus Gallenbach',
            color: 'orange',
            labelText: 'Offen',
            labelColor: 'orange',
        },
        {
            title: 'Rückinfo 21.06',
            badge1: 'Zahnärzte',
            badgeTooltip: '',
            noBadge: '',
            color: 'dark',
            labelText: 'Nicht angenommen',
            labelColor: 'dark',
        },
        {
            title: 'Rückinfo 22.06',
            badge1: 'Zahnärzte',
            badgeTooltip: '',
            noBadge: '',
            color: 'dark',
            labelText: 'Nicht angenommen',
            labelColor: 'dark',
        },
        {
            title: 'Rückinfo 29.06',
            badge1: 'Zahnärzte',
            badgeTooltip: '',
            noBadge: '',
            color: 'dark',
            labelText: 'Nicht angenommen',
            labelColor: 'dark',
        },
        {
            title: 'Rückinfo 30.06',
            badge1: 'Zahnärzte',
            badgeTooltip: '',
            noBadge: '',
            color: 'dark',
            labelText: 'Nicht angenommen',
            labelColor: 'dark',
        },
    ]

    type RepairOrderProps = {
        title: string,
        detail: string,
        labelText: string,
        labelColor: string,
    }

    const LatestRepairOrder: RepairOrderProps[] = [
        {
            title: 'Wartezimmer',
            detail: 'Wasserrohre defekt',
            labelText: 'Nicht angenommen',
            labelColor: 'dark',
        },
        {
            title: 'Labor',
            detail: 'repair testing',
            labelText: 'Nicht angenommen',
            labelColor: 'dark',
        },
        {
            title: '',
            detail: 'Abdeckung locker',
            labelText: 'Nicht angenommen',
            labelColor: 'dark',
        },
    ]

    return (
        <>
            <Modal size="lg" show={showAddModule} onHide={hideAddModule} backdrop="static" keyboard={false} className='shadow-modal' aria-labelledby="ResetModal">
                <Modal.Header closeButton className=''>
                    <Modal.Title id="ResetModal">
                        Modul zum Hinzufügen auswählen
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-2'>
                    <Row>
                        <Col md={2}>
                            <Form.Label className="mb-0">Kategorie</Form.Label>
                        </Col>
                        <Col md={10}>
                            <Form.Check
                                inline
                                label='Täglich wichtig'
                                type='radio'
                                name='category'
                                id='imp-every-day'
                                checked={EveryDay}
                                onChange={() => handleEveryDayChange()}
                            />
                            <Form.Check
                                inline
                                label='Listen von Einträgen'
                                type='radio'
                                name='category'
                                id='list-entries'
                                checked={ListEntries}
                                onChange={() => handleListEntries()}
                            />
                            <Form.Check
                                inline
                                label='Statistiken'
                                type='radio'
                                name='category'
                                id='satatics'
                                checked={Statistics}
                                onChange={() => handlesatatics()}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {EveryDay ?
                            <>
                                <Col md={6} className='pt-4'>
                                    <div className='b-shadow module-dragable'>
                                        <div className='overflow-y-hidden h-100' onClick={() => onSelectModule("item-2", "NotedModule")}>
                                            <NotedModule />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className='pt-4'>
                                    <div className='b-shadow module-dragable'>
                                        <div className='overflow-y-hidden h-100'>
                                            <BookmarksWidget />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className='pt-4'>
                                    <div className='b-shadow module-dragable'>
                                        <div className='overflow-y-hidden h-100'>
                                            <EventsWidget />
                                        </div>
                                    </div>
                                </Col>
                            </>
                            : null
                        }
                        {ListEntries ?
                            <Col md={12}>
                                <div className='list-entries-content overflow-y-hidden'>
                                    <div className='table-header border-ends'>
                                        <h5 className="fw-700">Neueste Nachrichten</h5>
                                    </div>

                                    <div onClick={() => onSelectModule("item-7", "LatestNewsModule")}>
                                        <LatestNewsList Data={LatestNews} Name='Neueste Nachrichten' />
                                    </div>
                                </div>

                                <div className='list-entries-content overflow-y-hidden'>
                                    <div className='table-header border-ends'>
                                        <h5 className="fw-700">Neueste Nachrichten</h5>
                                    </div>
                                    <LatestNewsList Data={LatestNews2} Name='Neueste Nachrichten' />
                                </div>

                                <div className='list-entries-content overflow-y-hidden'>
                                    <div className='table-header border-ends'>
                                        <h5 className="fw-700">Neueste Ideen</h5>
                                    </div>
                                    <LatestIdeasList Data={LatestIdeas} Name="Neueste Ideen" />
                                </div>

                                <div className='list-entries-content overflow-y-hidden'>
                                    <div className='table-header border-ends'>
                                        <h5 className="fw-700">Neueste Reparaturaufträge</h5>
                                    </div>
                                    <LatestRepairOrderList Data={LatestRepairOrder} Name="Neueste Reparaturaufträge" />
                                </div>

                                <div className="list-entries-content overflow-y-hidden">

                                    <div className='table-header border-ends'>
                                        <h5 className="fw-700">Überfällige Aufgaben</h5>
                                    </div>
                                    <OverdueTasksList Data={OverdueTasks} Name="Überfällige Aufgaben" />
                                </div>
                                <div className='list-entries-content overflow-y-hidden'>
                                    <div className='table-header border-ends'>
                                        <h5 className="fw-700">Heutige Aufgaben</h5>
                                    </div>
                                    <TodayTasksList Data={TodayTasks} Name="Heutige Aufgaben" />
                                </div>





                                <div className='list-entries-content'>
                                    <div className='table-header border-ends'>
                                        <h5 className='fw-700'>Kürzlich erledigte Reparaturen</h5>
                                    </div>
                                    <div className='text-center mt-3'>
                                        <span>Aktuell keine Einträge</span>
                                    </div>
                                </div>

                            </Col>
                            : null
                        }
                        {Statistics ?
                            <>
                                <Col md={6} className="mt-4">
                                    <Card className='border-0 rounded-0 b-shadow'>
                                        <Card.Header className='bg-white'>
                                            <p className='m-0 f-16px fw-700'>Status Reparaturen</p>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: '200px' }}>
                                                <DoughnutChart chartData={[5, 0]} chartLabel={["Nicht angenommen", "Erledigt"]} chartRadius={60} />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={6} className="mt-4">
                                    <Card className='border-0 rounded-0 b-shadow'>
                                        <Card.Header className='bg-white'>
                                            <p className='m-0 f-16px fw-700'>Reparaturen</p>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: '200px' }}>
                                                <BarChart labels={["Erstellt", "Eriedigt"]} data={[3, 0]} title={"Reparaturen"} />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={12} className="mt-4">
                                    <Card className='border-0 rounded-0 b-shadow'>
                                        <Card.Header className='bg-white'>
                                            <p className='m-0 f-16px fw-700'>Reparaturen</p>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <AreaChart labels={['January', 'February', 'March', 'April', 'May']} data={[65, 59, 80, 81, 56]} />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={12} className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <Card className='border-0 rounded-0 b-shadow'>
                                                <Card.Header className='bg-white'>
                                                    <p className='m-0 f-16px fw-700'>Anzahl der Reparaturen nach Ort </p>
                                                </Card.Header>
                                                <Card.Body>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <BarChart labels={["Manuelle Orte", "EG"]} data={[1, 2]} />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={6}>

                                        </Col>
                                    </Row>
                                </Col>
                            </>
                            : null
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer className='footer-sticky'>
                    <Button className='button-blue f-14px' onClick={() => onSelectModule}>Modul hinzufügen</Button>
                    <Button className='button-light f-14px me-1' onClick={hideAddModule}>Abbrechen</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddModuleModal;