import * as React from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from '../../FormControl';
import { Box } from '../../StyledComponents';
import { Col, Row } from 'react-bootstrap';

export const BookMarkOptionModalContent = () => {
    return (
        <>
            <Row>
                <Col md={12} xs={12}>
                    <Box className='mb-2'>
                        <FormControl isFormGroup={true} Type='text' Size='sm' Placeholder='Stichwörter' Name='Stichwörter' />
                    </Box>
                </Col>
                <Col md={12} xs={12}>
                    <Box className='mb-2'>
                        <Form.Control as="textarea" placeholder="Notizen" rows={4} />
                    </Box>
                </Col>
            </Row>
        </>
    );
}