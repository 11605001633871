import React, { useState } from 'react';

function UpcomingEvents() {
    return (
        <>
            <div className='d-flex align-items-start justify-content-start'>
                <div className='me-2'>
                    <p className='m-0 f-20px lh-20px fw-700'>25</p>
                    <span>Dez</span>
                </div>
                <div>
                    <div className='d-flex'>
                        <span className='subscribedCalendarColorbox'></span>
                        <p className='m-0 f-13px fw-700'><span className='from'>Mon, 15:26</span> - <span className='to'>Tue 06.12.2022</span></p>
                    </div>
                    <p className='m-0 f-15px fw-700 text-start black'><span>vaccations</span></p>
                </div>
            </div>
        </>
    );
}

export default UpcomingEvents;