
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { BodyText, Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider } from '../../../Components/StyledComponents';
import { GroupActionButtonWidget } from '../../../Components/Widgets/GroupActionButtonWidget';
import CustomTooltip from '../../../Components/Tooltip';
import { FaAngleDown, FaAngleUp, FaArrowLeft, FaArrowRight, FaLink, FaLongArrowAltRight, FaMinusCircle, FaPlusCircle, FaQuestionCircle, FaRegHandPointRight } from 'react-icons/fa';
import BadgeWidget from '../../../Components/BadgeWidget';
import EditorConvertToMarkdown from '../../../Components/TextEditor';
import ModelActions from '../../../Components/ModelActions';
import Tagify from '../../../Components/Tagify';
import AddMediaModal from '../../../Components/Modals/AddMediaModal';
import ButtonWidget from '../../../Components/ButtonWidget';
import { SendReminderModalContent } from '../../../Components/Modals/ModelContents/SendReminderModalContent';
import CommonModal from '../../../Components/Modals/CommonModal';
import { PdfSettingsModalContent } from '../../../Components/Modals/ModelContents/PdfSettingsModalContent';
import { DeleteMessageDetailModalContent } from '../../../Components/Modals/ModelContents/DeleteMessageDetailModalContent';
import { ReportContentModalContent } from '../../../Components/Modals/ModelContents/ReportContentModalContent';
import { UnsavedCommentModalContent } from '../../../Components/Modals/ModelContents/UnsavedCommentModalContent';
import { PermissionModalContent } from '../../../Components/Modals/ModelContents/PermissionModalContent';
import { AddPollModalContent } from '../../../Components/Modals/ModelContents/AddPollModalContent';
import { BookMarkOptionModalContent } from '../../../Components/Modals/GroupActionButtonModalContent.tsx/BookMarkOptionModalContent';
import { BookMarkFooterContent } from '../../Repair/MainContent';
import { RememberOptionModalContent } from '../../../Components/Modals/GroupActionButtonModalContent.tsx/RememberOptionModalContent';
import { ShowHomeScreenModalContent } from '../../../Components/Modals/ModelContents/ShowHomeScreenModalContent';
import { Link } from 'react-router-dom';
import { ConfirmDeleteCommentModalContent } from '../../../Components/Modals/ModelContents/ConfirmDeleteCommentModalContent';
import { AttachmentOptionModalContent } from '../../../Components/Modals/ModelContents/AttachmentOptionModalContent';
import { PleasesModalContent } from '../../../Components/Modals/ModelContents/PleasesModalContent';
import { CommentWidget } from '../../../Components/CommentWidget';
import AddEditNews from '../AddEditNews';


type PropsData = {
    handleDisableNotification?: () => void,
    handleCommentTextBoxToggle?: () => void,
}


export const NewsDetailIndex = (props: PropsData) => {

    const { handleDisableNotification, handleCommentTextBoxToggle } = props;

    const [TextEditorShow, setTextEditorShow] = useState<boolean>(false);
    const [HideFont, setHideFont] = useState<boolean>(false);
    const [TagifyShow, setTagifyShow] = useState<boolean>(false);
    const [AddMediaModalShow, setAddMediaModalShow] = useState<boolean>(false);
    const [AllowReading, setAllowReading] = useState<boolean>(false);
    const [showBookMark, setShowBookMark] = useState<boolean>(false);
    const [showRememberOption, setShowRememberOption] = useState<boolean>(false);
    const [showHomeScreen, setShowHomeScreen] = useState<boolean>(false);
    const [deleteComment, setDeleteComment] = useState<boolean>(false);
    const [attachOption, setAttachOption] = useState<boolean>(false);

    const [sendReminder, setSendReminder] = useState<boolean>(false);
    const [printPdfSetting, setPrintPdfSetting] = useState<boolean>(false);
    const [deleteMessage, setDeleteMessage] = useState<boolean>(false);
    const [reportContent, setReportContent] = useState<boolean>(false);
    const [unsavedComment, setUnsavedComment] = useState<boolean>(false);
    const [permission, setPermission] = useState<boolean>(false);
    const [notificationTextToggle, setNotificationTextToggle] = useState<boolean>(false);
    const [commentTextBoxToggle, setCommentTextBoxToggle] = useState<boolean>(false);
    const [addPoll, setAddPoll] = useState<boolean>(false);
    const [NewsModal, setNewsModal] = useState<boolean>(false);

    const [commentDropdown, setCommentDropdown] = useState<boolean>(false);
    const [showLike, setShowLike] = useState<boolean>(false);
    const [attachLink, setAttachLink] = useState<boolean>(false);
    const [pleases, setPleases] = useState<boolean>(false);

    const handleAttachLink = () => {
        setAttachLink(!attachLink);
    }

    const handleMouseEnter = () => {
        setCommentDropdown(true);
    };

    const handleMouseLeave = () => {
        setCommentDropdown(false);
    };

    const HandleFontClick = () => {
        setTextEditorShow(true);
        setHideFont(true);
    }

    const HandleAllowReding = () => {
        setAllowReading(!AllowReading);
    }

    return (
        <>
            <Card className='boxShadow overflow-hidden'>
                <Card.Header>
                    <DisplayBetween>
                        <Box>
                            <BodyText fontSize='20px' fontWeight='700' textColor='#212b36'>OPTI CONCEPT Coaching</BodyText>
                        </Box>
                        <Box>
                            <GroupActionButtonWidget
                                bookMarkBtn={true}
                                noticeBtn={true}
                                isDropdownIcon={true}
                                hideHomeScreen={() => setShowHomeScreen(true)}
                                hidePrintPdf={() => setPrintPdfSetting(true)}
                                hideStarModal={() => setShowBookMark(true)}
                                hideNoticeModal={() => setShowRememberOption(true)}
                                hideUnsavedContent={() => setUnsavedComment(true)}
                                hideDeleteMessage={() => setDeleteMessage(true)}
                                hideReportContent={() => setReportContent(true)}
                                hidepermission={() => setPermission(true)}
                                hideAddPoll={() => setAddPoll(true)}
                                notificationTextToggle={notificationTextToggle}
                                handleDisableNotification={() => setNotificationTextToggle(!notificationTextToggle)}
                                commentTextBoxToggle={commentTextBoxToggle}
                                handleCommentTextBoxToggle={() => setCommentTextBoxToggle(!commentTextBoxToggle)}
                                addEditNewsModal={() => setNewsModal(true)}
                                pageName="newsDetailPage" />
                        </Box>
                    </DisplayBetween>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={12} xs={12}>
                            <DisplayBetween>
                                <Box>
                                    <DisplayStart>
                                        <Box mr='5px'>
                                            <img width='50' src='/images/account.png' alt="userName-img" />
                                        </Box>
                                        <Box>
                                            <DisplayStart mb='5px'>
                                                <CustomTooltip
                                                    ToolTipTrigger={<BodyText mb='0px' fontSize='14px' fontWeight='700' textColor='#666' pl='5px'> Emine Mollaoglu </BodyText>}
                                                    toolTipContent="Consultant - OPTI Health Consulting"
                                                    toolTipPlacement='top'
                                                />
                                                <FaArrowRight className='mx-1' />
                                                <CustomTooltip
                                                    ToolTipTrigger={<BadgeWidget solid={true} title='Zahnärzte' color='#1e6c7b' bgColor='#d4f2f7' />}
                                                    toolTipContent='Dr. Sven Markus Gallenbach, ZA Robert Stühn'
                                                    toolTipPlacement='top'
                                                    contentAlign='center'
                                                />
                                                <span className='mx-1'> , </span>
                                                <BodyText mb='0px' fontSize='14px' fontWeight='700' textColor='#666' pl='5px'> Emine Mollaoglu </BodyText>

                                            </DisplayStart>
                                            <DisplayStart>
                                                <BodyText mb='0px' fontSize='14px' fontWeight='400' textColor='#666' pl='5px' pr='6px'> Netzwerk </BodyText>
                                                <BadgeWidget outiline={true} title='Praxis Gallenbach & Zähne ' color='#0d3f5f' bgColor='#ffffff' />
                                                <BodyText mb='0px' fontSize='12px' fontWeight='500' textColor='#666' pl='20px'> Erstellt: 22.05.2023 16:22 </BodyText>
                                                <BodyText mb='0px' fontSize='12px' fontWeight='500' textColor='#666' pl='20px'> Last change: 29.11.2022 10:57 </BodyText>
                                            </DisplayStart>
                                        </Box>
                                    </DisplayStart>
                                </Box>
                                <Box>
                                    <div onClick={() => setSendReminder(true)}>
                                        <CustomTooltip
                                            ToolTipTrigger={<img src='/images/graph-img.png' alt='graph-icons' />}
                                            toolTipContent='Gelesen: 2'
                                            toolTipPlacement='top'
                                        />
                                    </div>
                                </Box>
                            </DisplayBetween>
                        </Col>
                        <Divider mt='10px' mb='8px' />
                        <Col md={12} xs={12} className='mb-3'>
                            <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                Liebes Praxisteam, <br />
                            </BodyText>
                            <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                liebe Ansprechpartnerin im Bereich QM! <br />
                            </BodyText>
                            <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                ,,Nichts kommt von allein", - aus diesem Grund wollen wir Ihr QM mit Ihnen nach Vorne bringen und daraus mehr als nur eine gesetzliche Vorgabe machen. <br />
                            </BodyText>
                            <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                Sichern Sie sich im Rahmen Ihres OPTI CONCEPT- Vertrages Ihr Online-Coaching bei mir direkt über folgenden Link: https://bit.ly/3KySKFW (kostenfrei) <br />
                            </BodyText>
                            <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                Ich unterstütze Sie aktiv und individuell bei Ihrem QM, so dass Sie es gewinnbringend und rechtssicher einsetzen können. <br />
                            </BodyText>
                            <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                Bei Fragen kontaktieren Sie mich gerne über den Nachrichten-Button in Ihrem medikit oder schicken Sie mir gerne E – Mail an: mollaoglu@opti-hc.de <br />
                            </BodyText>
                            <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                Ich freue mich auf unseren Coaching-Termin. <br />
                            </BodyText>
                            <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                ILiebe Grüße <br />
                            </BodyText>
                            <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                Zu Beginn darf ich mich kurz vorstellen. Meine Name ist Thomas Planitzer und ich bin Ihr persönlicher Ansprechpartner rund um medikit. Von meiner Seite gilt, wer Fragen hat, darf mir diese gerne jederzeit direkt hier in medikit stellen! Bestimmt möchten Sie nun auch gleich wissen, wie Sie medikit bei Ihrer Arbeit unterstützen kann? Ich gebe Ihnen gerne einen Überblick über alle Bereiche. Los geht’s! <br />
                            </BodyText>
                            <Box mb="10px">
                                <BodyText fontSize='16px' fontWeight='700' textColor='#333' mt="5px" mb="5px">
                                    Aufgaben & Checklisten
                                </BodyText>
                                <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                    Das Übertragen und Ablegen von Papier-Checklisten dauert Ihnen zu langeYawn2? Ab sofort können Sie diese direkt in medikit abarbeiten – besonders toll: auch zwei oder mehrere Kollegen können gleichzeitig an ein und derselben Liste arbeiten. Legen Sie sich Vorlagen an und finden Sie ab sofort alle Informationen blitzschnell!
                                </BodyText>
                            </Box>
                            <Box mb="10px">
                                <BodyText fontSize='16px' fontWeight='700' textColor='#333' mt="5px" mb="5px">
                                    Ideen
                                </BodyText>
                                <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                    Sie haben Ideen, wie Sie Ihre Patienten noch zufriedener machen oder einen Prozess optimaler gestalten können? Im Ideen-Bereich können Sie diese intern diskutieren und Lösungen finden. Auf diesem Weg sind ALLE schnell erreichbar Ligthbulb und Sie können ggf. auch direkt eine Umfrage durchführen. Besonders wertvoll bei den Ideen: Sie können diese locker und zeitversetzt diskutieren - Ideen brauchen oft Zeit, um reifen zu können! Smug
                                </BodyText>
                            </Box>
                            <Box mb="10px">
                                <BodyText fontSize='16px' fontWeight='700' textColor='#333' mt="5px" mb="5px">
                                    Kalender
                                </BodyText>
                                <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="5px">
                                    Mit den verschiedenen themenbezogenen Kalendern können Sie sich im Team schnell organisieren. Der Kalender kann natürlich auch für die Ankündigung von Geburtstagen oder Jubiläen Cake genutzt werden.
                                </BodyText>
                            </Box>
                            <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="5px" mb="10px">
                                Viel Spaß beim Kennenlernen von medikit! <br />
                                Thomas Planitzer <br />
                            </BodyText>
                            <span>
                                <DisplayStart>
                                    <Button className='default-btn f-12px' onClick={() => setShowLike(!showLike)}>
                                        {showLike ?
                                            <span><FaRegHandPointRight className='me-1' />Like this news</span>
                                            :
                                            <span className='me-1'>Unlike</span>
                                        }
                                    </Button>
                                    <CustomTooltip
                                        ToolTipTrigger={<img width='30' height='30' src='/images/account.png' alt="userName-img" className='ms-3' onClick={() => setPleases(true)} />}
                                        toolTipContent='Dr. Sven Markus Gallenbach'
                                        toolTipPlacement='top'
                                        contentAlign='center'
                                    />
                                </DisplayStart>
                            </span>
                        </Col>
                        <Col md={12}>
                            <CommentWidget />
                        </Col>
                        {notificationTextToggle ?
                            <>
                                <Col md={12} xs={12} onClick={handleDisableNotification}>
                                    <Box className='p-2 bg-light' mt='10px' mb='10px'>
                                        <DisplayStart>
                                            <BodyText fontSize='14px' fontWeight='700' textColor='#666' pr='6px'>
                                                Benachrichtigungen deaktiviert
                                            </BodyText>
                                            <CustomTooltip
                                                ToolTipTrigger={<div><FaQuestionCircle size={16} /></div>}
                                                toolTipContent='Diese Nutzer erhalten keine Benachrichti un en über diesen Eintra. Schreibe einen Kommentar mit Name um dies zu ändern.'
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />
                                        </DisplayStart>
                                        <Divider mt='6px' mb='6px' />
                                        <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                                            Dr. Sven Markus Gallenbach
                                        </BodyText>
                                    </Box>
                                </Col>
                            </>
                            :
                            null
                        }
                        <Col md={12}>
                            <Box className='p-2 bg-light my-1'>
                                <DisplayCenter>
                                    <BodyText fontWeight='700' textColor='#666' fontSize='15px'>Die Kommentare wurden geschlossen. </BodyText>
                                    <span>{"("}</span>
                                    <div>
                                        <CustomTooltip
                                            ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' pl='4px'>Dr. Sven Markus Gallenbach</BodyText>}
                                            toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </div>
                                    <span className='mx-1'> - </span>
                                    <div>
                                        <CustomTooltip
                                            ToolTipTrigger={<BodyText fontWeight='400' textColor='#333' fontSize='14px' pr='4px'> vor 22 Minuten </BodyText>}
                                            toolTipContent='21.06.2023 09:27'
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </div>
                                    <span>{")"}</span>
                                </DisplayCenter>
                            </Box>
                        </Col>
                        <Col md={12}>
                            <Box className='p-2 bg-light my-1'>
                                <DisplayStart>
                                    <Box>
                                        <FaLink />
                                    </Box>
                                    <Box ml="10px" mr="10px">
                                        <DisplayStart>
                                            <BodyText fontWeight='500' textColor='#666' fontSize='14px'> Anhang </BodyText>
                                            <Box onClick={handleAttachLink}>
                                                {attachLink ?
                                                    <FaAngleUp size={14} className='ms-2' />
                                                    :
                                                    <FaAngleDown size={14} className='ms-2' />
                                                }
                                            </Box>
                                        </DisplayStart>
                                    </Box>
                                    {attachLink ?
                                        <Box>
                                            <Link to="" className='text-decoration-none' onClick={() => setAttachOption(true)}>
                                                <span className='fw-400 w-176px text-truncate text-link-color f-12px'> Umbenennen </span>
                                            </Link>
                                        </Box>
                                        :
                                        null}
                                </DisplayStart>
                                {attachLink ?
                                    <Box mt="10px">
                                        <img src="images/assets/attachment-img.png" alt="Attachment-img" />
                                    </Box>
                                    : null}
                            </Box>
                        </Col>

                        {commentTextBoxToggle ?
                            <>
                                <Col md={12} xs={12} className='p-2' onClick={handleCommentTextBoxToggle}>
                                    <div className='d-flex align-items-baseline'>
                                        <Box className='me-2'>
                                            <img width='30' height='30' src='/images/account.png' alt="userName-img" />
                                        </Box>
                                        <Box width='100%'>
                                            {TextEditorShow ?
                                                <div className="mb-3">
                                                    <EditorConvertToMarkdown />
                                                </div>
                                                :
                                                <div className="mb-3">
                                                    <Form.Control as="textarea" placeholder="Text" rows={5} />
                                                </div>
                                            }
                                            <ModelActions
                                                ImgClick={() => setAddMediaModalShow(true)}
                                                FontClick={() => HandleFontClick()}
                                                isHideFont={HideFont}
                                                TagifyClick={() => setTagifyShow(!TagifyShow)}
                                            />
                                            {TagifyShow ?
                                                <div className='my-3 tagify'>
                                                    <Tagify />
                                                </div>
                                                : null
                                            }
                                        </Box>
                                    </div>
                                    <div className='text-end'>
                                        <ButtonWidget
                                            varient='primary'
                                            text="Kommentieren"
                                        />
                                    </div>
                                </Col>
                            </>
                            : null}
                    </Row>
                </Card.Body>
            </Card>
            <AddMediaModal displayAddMediaModal={AddMediaModalShow} hideAddMediaModal={() => setAddMediaModalShow(false)} />
            <AddEditNews showNews={NewsModal} hideNews={() => setNewsModal(false)} />

            <CommonModal
                showModal={attachOption}
                hideModal={() => setAttachOption(false)}
                modalTitle='Anhangoptionen'
                modalContent={<AttachmentOptionModalContent />}
                modalSize='md'
                modalFooterContent={
                    <>
                        <ButtonWidget
                            varient='light'
                            text={'Schließen'}
                            handleClick={() => setAttachOption(false)}
                        />
                    </>
                }
            />

            <CommonModal
                showModal={pleases}
                hideModal={() => setPleases(false)}
                modalTitle='Anhangoptionen'
                modalContent={<PleasesModalContent />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <ButtonWidget
                            varient='light'
                            text={'Schließen'}
                            handleClick={() => setPleases(false)}
                        />
                    </>
                }
            />

            <CommonModal
                showModal={deleteComment}
                hideModal={() => setDeleteComment(false)}
                modalTitle='Bestätigung'
                modalContent={<ConfirmDeleteCommentModalContent />}
                modalSize='md'
            />
            <CommonModal
                showModal={showHomeScreen}
                hideModal={() => setShowHomeScreen(false)}
                modalTitle='Auf Startseite anzeigen'
                modalContent={<ShowHomeScreenModalContent />}
                modalSize='lg'
                footerClass={"footer-sticky"}
            />
            <CommonModal
                showModal={showBookMark}
                hideModal={() => setShowBookMark(false)}
                modalTitle='Lesezeichen-Optionen'
                modalContent={<BookMarkOptionModalContent />}
                modalSize='lg'
                modalFooterContent={<BookMarkFooterContent footerModalArea='bookMarkOption' hideModal={() => setShowBookMark(false)} />}
            />

            <CommonModal
                showModal={showRememberOption}
                hideModal={() => setShowRememberOption(false)}
                modalTitle='Merk-Optionen'
                modalContent={<RememberOptionModalContent />}
                modalSize='lg'
                modalFooterContent={<BookMarkFooterContent footerModalArea='rememberOption' hideModal={() => setShowRememberOption(false)} />}
            />

            <CommonModal
                showModal={printPdfSetting}
                hideModal={() => setPrintPdfSetting(false)}
                modalTitle='PDF-Einstellungen'
                modalContent={<PdfSettingsModalContent />}
                modalSize='md'
            />

            <CommonModal
                showModal={deleteMessage}
                hideModal={() => setDeleteMessage(false)}
                modalTitle='Lösche "OPTI CONCEPT Coaching"?'
                modalContent={<DeleteMessageDetailModalContent />}
                modalSize='md'
                centered={true}
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <Button className='default-btn f-14px me-1' onClick={() => setDeleteMessage(false)}> Cancel </Button>
                            <Button variant="danger" className='f-14px'> Delete</Button>
                        </DisplayEnd>
                    </>
                }
            />
            <CommonModal
                showModal={reportContent}
                hideModal={() => setReportContent(false)}
                modalTitle='Inhalt melden'
                modalContent={<ReportContentModalContent />}
                modalSize='md'
            />
            <CommonModal
                showModal={permission}
                hideModal={() => setPermission(false)}
                modalTitle='Berechtigungen:'
                modalContent={<PermissionModalContent />}
                modalSize='md'
            />
            <CommonModal
                showModal={addPoll}
                hideModal={() => setAddPoll(false)}
                modalTitle='Umfrage hinzufügen'
                modalContent={<AddPollModalContent AllowReading={AllowReading} />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayBetween className='w-100'>
                            <Box>
                                <ButtonWidget
                                    varient='light'
                                    text={
                                        <>
                                            {AllowReading ?
                                                <span><FaMinusCircle className='default-btn-icon' /> Optionen ausblenden</span>
                                                :
                                                <span><FaPlusCircle className='default-btn-icon' /> Zeige weitere Optionen</span>
                                            }
                                        </>
                                    }
                                    handleClick={HandleAllowReding}
                                />
                            </Box>
                            <Box>
                                <ButtonWidget
                                    varient='light'
                                    text={'Abbrechen'}
                                    class='me-2'
                                />
                                <ButtonWidget
                                    varient='primary'
                                    text={'Erstellen'}
                                />
                            </Box>
                        </DisplayBetween>
                    </>
                }
                footerClass={"footer-sticky"}
            />
            <CommonModal
                showModal={unsavedComment}
                hideModal={() => setUnsavedComment(false)}
                modalTitle='Ungespeicherter Kommentar'
                modalContent={<UnsavedCommentModalContent />}
                modalSize='md'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Bleiben'}
                                handleClick={() => setUnsavedComment(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Entwurf speichem'}
                                class='mx-1'
                            />
                            <ButtonWidget
                                varient='danger'
                                text={'Verlassen'}
                            />
                        </DisplayEnd>
                    </>
                }
            />

            <CommonModal
                showModal={sendReminder}
                hideModal={() => setSendReminder(false)}
                modalTitle='Erinnerung senden'
                modalContent={<SendReminderModalContent />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Schließen'}
                                class='me-2 ms-3'
                                handleClick={() => setSendReminder(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Ausgewählte erinnem'}
                                disabled={true}
                            />
                        </DisplayEnd>
                    </>
                }
                footerClass={"footer-sticky"}
            />
        </>
    )
}