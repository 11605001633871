import * as React from 'react';
import { useState, useEffect } from 'react';
import { AnchorLink, WrapperCurrent } from './SaveFilter';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import CommonModal from '../../../../Components/Modals/CommonModal';
import SubscribedCalendarContent from '../../../../Components/Modals/ModelContents/CalendarModalsContent/SubscribedCalendarContent';
import AddCalendarContent from '../../../../Components/Modals/ModelContents/CalendarModalsContent/AddCalendarContent';

export const BoxTitle = styled.span`
color: rgb(13, 63, 95);
background-color: rgb(255, 255, 255);
cursor: pointer;
padding: 2px 4px;
background: #fff;
border: 1px solid;
white-space: nowrap;
border-radius: 4px;
font-weight: 400;
text-transform: uppercase;
font-size: 8px;
vertical-align: middle;
margin-right: 5px;
`
export const CheckboxListWrapper = styled.div`
& .form-check {
    min-height:1rem;
}
`

export const BGCHECKBOX = styled.div`
& .form-check-input:checked{
    background-color:${(props: {bg?: number}) => props.bg ? props.bg : "#0d6efd"} ;
    border-color: ${(props: {bg?: number}) => props.bg ? props.bg : "#0d6efd"};
    background-image:unset;

}
& .form-check-input:focus{
    box-shadow:unset;
}
`


export type colorfullCheckbox ={
    title?: string,
    isChecked?: boolean,
}

export interface MyObject {
    [key: string]: any;
  }

const List = [
    {
        title:"Allgemeine Termine",
        isChecked:false
    },
    {
        title:"calendar",
        isChecked:false
    },
    {
        title:"calendar",
        isChecked:false
    },
    {
        title:"Geburtstage / Jubiläen",
        isChecked:false
    },
    {
        title:"Personal calendar",
        isChecked:true
    },
    {
        title:"Urlaube",
        isChecked:false
    },
];


const checkboxColors : MyObject = {
    "Allgemeine Termine" : "rgb(120, 228, 235)",
    "calendar" : "rgb(13, 63, 95)",
    "Geburtstage / Jubiläen" : "rgb(13, 63, 95)",
    "Personal calendar" : "rgb(13, 63, 95)",
    "Urlaube" : "rgb(105, 176, 186)",
}

function PraxisCheckboxList() {
    const [subscribedCalendarModal, setSubscribedCalendarModal] = useState<boolean>(false);
    const [addCalendar, setAddCalendar] = useState<boolean>(false);
    return (
        <>
            <WrapperCurrent notop={true}>
                <div className='px-2'>
                    <BoxTitle>Praxis Gallenbach & Zähne </BoxTitle>
                    <ColorCheckboxTemplate />
                    <AnchorLink href="javascript:;" className='my-2' onClick={() => setSubscribedCalendarModal(true)}>Subscribed Calendars {'>>'}</AnchorLink>
                    <AnchorLink href="javascript:;" className='my-2' onClick={() => setAddCalendar(true)}>Add Calendar  {'>>'}</AnchorLink>
                    <CommonModal
                        modelId={"subscribedCalendarModal"}
                        showModal={subscribedCalendarModal}
                        hideModal={() => setSubscribedCalendarModal(false)}
                        modalTitle='Subscribed calendars'
                        modalContent={<SubscribedCalendarContent closeModal={setSubscribedCalendarModal} />}
                        modalSize='lg'
                        modalFooterContent={<button className='btn btn-default' onClick={() => setSubscribedCalendarModal(false)}>Close</button>}
                    />
                    <CommonModal
                        modelId={"addCalendarModal"}
                        showModal={addCalendar}
                        hideModal={() => setAddCalendar(false)}
                        modalTitle='Add calendar'
                        modalContent={<AddCalendarContent closeModal={setAddCalendar} />}
                        modalSize='lg'
                        modalFooterContent={<button className='btn btn-default' onClick={() => setAddCalendar(false)}>Close</button>}
                    />
                </div>
            </WrapperCurrent>
        </>
    );
}

export default PraxisCheckboxList;


export function ColorCheckboxTemplate() {
    const [checkboxList, setCheckboxList] = useState<colorfullCheckbox[]>(List);

    const onChange = (i:number , checked:boolean) => {
        let prevState = [...checkboxList];
        prevState[i].isChecked = checked;
        setCheckboxList([...prevState]);
    }
    return ( 
            <CheckboxListWrapper className='checkboxListWrapper py-1'>
                {checkboxList.map((x,i) => 
                    <BGCHECKBOX bg={checkboxColors[x.title as string]}>
                        <Form.Check
                            label={x.title}
                            type='checkbox'
                            name='checkboxList'
                            id={'imp-every-day_' + i}
                            checked={x.isChecked}
                            onChange={(e) => onChange(i,e.target.checked)}
                        />
                    </BGCHECKBOX>
                )}
        </CheckboxListWrapper>
     );
}
