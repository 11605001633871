import React from 'react';
import Form from 'react-bootstrap/Form';


type objType = {
    label: string,
    value: string
}
interface FormSelectPropsType {
    Name?: string,
    Id?: string,
    Size?: any,
    isFormGroup?: boolean,
    selectOptions: objType[],
    handleChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void,
}

function FormSelect(props: FormSelectPropsType) {
    const { Name, Id, Size, isFormGroup, selectOptions, handleChange } = props;
    return (
        <>
            {!!isFormGroup ?
                <Form.Group className="mb-3">
                    <Form.Select size={Size} name={Name} id={Id} onChange={handleChange}>
                        {selectOptions.map((item: any, index: number) => {
                            return <>
                                <option key={`${item.label}-${index}`} value={item.value}>{item.label}</option>
                            </>
                        })}
                    </Form.Select>
                </Form.Group>
                :
                <Form.Select size={Size} name={Name} id={Id} onChange={handleChange}>
                    {selectOptions.map((item: any, index: number) => {
                        return <>
                            <option key={`${item.label}-${index}`} value={item.value}>{item.label}</option>
                        </>
                    })}
                </Form.Select>
            }
        </>
    );
}

export default FormSelect;

