import React, { useState, useEffect, FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import ButtonWidget from '../../../Components/ButtonWidget';
import CommonModal from '../../../Components/Modals/CommonModal';
import Card from 'react-bootstrap/Card';
import { AddGroupAdminModal } from '../AdminModal/AddGroupAdminModal';
import { BodyText, Box, DisplayBetween, DisplayStart } from '../../../Components/StyledComponents';
import { EditGroupAdminModal } from '../AdminModal/EditGroupAdminModal ';
import BadgeWidget from '../../../Components/BadgeWidget';
import { Link } from 'react-router-dom';
import { FaAngleDoubleRight } from 'react-icons/fa';
import CustomTooltip from '../../../Components/Tooltip';
import Swal from 'sweetalert2';

export const GroupList = () => {

    const [addGroupModal, setAddGroupModal] = useState<boolean>(false);
    const [editGroupModal, setEditGroupModal] = useState<boolean>(false);

    const handleDeleteGroup = () => {
        Swal.fire({
            title: 'Diese Gruppe wirklich löschen?',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonText: `Abbrechen`,
            confirmButtonText: 'Löschen',
            customClass: {
                container: 'discardTask-alert',
                confirmButton: 'f-14px btn-danger fw-400',
                cancelButton: 'f-14px default-btn fw-400',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Verworfen!', '', 'success');
            }
        })
    }

    return (
        <>
            <div className='group-admin-list'>
                <Card>
                    <Card.Body>
                        <DisplayBetween>
                            <Box>
                                <BodyText fontSize="18px" fontWeight="600" textColor="#000" className='text-uppercase'> Gruppen </BodyText>
                                <BodyText fontSize="14px" fontWeight="400" textColor="#666"> Der Betrieb kann basierend auf verschiedenen Gruppen organisiert werden. </BodyText>
                                <BodyText fontSize="14px" fontWeight="400" textColor="#666"> Dies hilft relevante Informationen mit den zuständigen Personen zu teilen. </BodyText>
                            </Box>
                            <Box>
                                <DisplayStart>
                                    <Box>
                                        <ButtonWidget text={"Gruppenrechte bearbeiten"} varient='primary' handleClick={() => setEditGroupModal(true)} />
                                    </Box>
                                    <Box ml="8px">
                                        <ButtonWidget text={"Gruppe hinzufügen"} varient='primary' handleClick={() => setAddGroupModal(true)} />
                                    </Box>
                                </DisplayStart>
                            </Box>
                        </DisplayBetween>
                        <Row className='mt-3'>
                            <Col md={6} className='py-3'>
                                <Box className='rounded-4 p-3 boxShadow h-100'>
                                    <DisplayStart className='align-items-start'>
                                        <Box>
                                            <BadgeWidget solid={true} title={"Abrechnung"} color={"#0d3f5f"} bgColor={"#d2eaf9"} />
                                        </Box>
                                        <Box pl="18px">
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => setAddGroupModal(true)}> Gruppe bearbeiten  » </BodyText>
                                            </Box>
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => handleDeleteGroup()}> Gruppe löschen » </BodyText>
                                            </Box>
                                            <Box>
                                                <DisplayStart className='text-decoration-none'>
                                                    <BodyText fontSize="14px" fontWeight="400" textColor="#666"> 1 Nutzer: </BodyText>
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="5px"> APZ APZ </BodyText>}
                                                        toolTipContent={"Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                </DisplayStart>
                                            </Box>
                                        </Box>
                                    </DisplayStart>
                                </Box>
                            </Col>
                            <Col md={6} className='py-3'>
                                <Box className='rounded-4 p-3 boxShadow h-100'>
                                    <DisplayStart className='align-items-start'>
                                        <Box>
                                            <BadgeWidget solid={true} title={"Assistenz"} color={"#0d3f5f"} bgColor={"#d2eaf9"} />
                                        </Box>
                                        <Box pl="18px">
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => setAddGroupModal(true)}> Gruppe bearbeiten » </BodyText>
                                            </Box>
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => handleDeleteGroup()}> Gruppe löschen » </BodyText>
                                            </Box>
                                            <Box>
                                                <div className='d-flex flex-wrap'>
                                                    <BodyText fontSize="14px" fontWeight="400" textColor="#666"> 5 Nutzer: </BodyText>
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="5px"> Hoa Trinh <span className='mx-1'>,</span> </BodyText>}
                                                        toolTipContent={"ZFA- Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="2px"> Irene Gantert <span className='mx-1'>,</span> </BodyText>}
                                                        toolTipContent={"ZFA - Praxis Gellenbech & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="2px"> Alicia Gojani  <span className='mx-1'>,</span> </BodyText>}
                                                        toolTipContent={"ZFA- Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="2px"> Cara Bianco <span className='mx-1'>,</span> </BodyText>}
                                                        toolTipContent={"Auszubildende - Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="2px"> Anna Behm </BodyText>}
                                                        toolTipContent={"ZFA- Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                </div>
                                            </Box>
                                        </Box>
                                    </DisplayStart>
                                </Box>
                            </Col>
                            <Col md={6} className='py-3'>
                                <Box className='rounded-4 p-3 boxShadow h-100'>
                                    <DisplayStart className='align-items-start'>
                                        <Box>
                                            <BadgeWidget solid={true} title={"medikit Champions"} color={"#0d3f5f"} bgColor={"#d2eaf9"} />
                                        </Box>
                                        <Box pl="18px">
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => setAddGroupModal(true)}> Gruppe bearbeiten » </BodyText>
                                            </Box>
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => handleDeleteGroup()}> Gruppe löschen » </BodyText>
                                            </Box>
                                            <Box>
                                                <div className='d-flex flex-wrap'>
                                                    <BodyText fontSize="14px" fontWeight="400" textColor="#666"> 4 Nutzer: </BodyText>
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="5px"> Dr. Sven Markus Gallenbach <span className='mx-1'>,</span> </BodyText>}
                                                        toolTipContent={"Zahnarzt - Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="2px"> Nadine Häring  <span className='mx-1'>,</span> </BodyText>}
                                                        toolTipContent={"ZFA - Praxis Gellenbech & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="2px"> Diana Lauser <span className='mx-1'>,</span> </BodyText>}
                                                        toolTipContent={"ZFA- Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="2px">  Alicia Gojani </BodyText>}
                                                        toolTipContent={"Auszubildende - Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                </div>
                                            </Box>
                                        </Box>
                                    </DisplayStart>
                                </Box>
                            </Col>
                            <Col md={6} className='py-3'>
                                <Box className='rounded-4 p-3 boxShadow h-100'>
                                    <DisplayStart className='align-items-start'>
                                        <Box>
                                            <BadgeWidget solid={true} title={"OPTI"} color={"#0d3f5f"} bgColor={"#d2eaf9"} />
                                        </Box>
                                        <Box pl="18px">
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => setAddGroupModal(true)}> Gruppe bearbeiten » </BodyText>
                                            </Box>
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => handleDeleteGroup()}> Gruppe löschen » </BodyText>
                                            </Box>
                                            <Box>
                                                <div className='d-flex flex-wrap'>
                                                    <BodyText fontSize="14px" fontWeight="400" textColor="#666"> 1 Nutzer: </BodyText>
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="5px"> Emine Mollaoglu </BodyText>}
                                                        toolTipContent={"Consultant - OPTI Health Consulting"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                </div>
                                            </Box>
                                        </Box>
                                    </DisplayStart>
                                </Box>
                            </Col>
                            <Col md={6} className='py-3'>
                                <Box className='rounded-4 p-3 boxShadow h-100'>
                                    <DisplayStart className='align-items-start'>
                                        <Box>
                                            <BadgeWidget solid={true} title={"Prophylaxe"} color={"#0d3f5f"} bgColor={"#d2eaf9"} />
                                        </Box>
                                        <Box pl="18px">
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => setAddGroupModal(true)}> Gruppe bearbeiten » </BodyText>
                                            </Box>
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => handleDeleteGroup()}> Gruppe löschen » </BodyText>
                                            </Box>
                                            <Box>
                                                <div className='d-flex flex-wrap'>
                                                    <BodyText fontSize="14px" fontWeight="400" textColor="#666"> 3 Nutzer: </BodyText>
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="5px"> Diana Lauser <span className='mx-1'>,</span> </BodyText>}
                                                        toolTipContent={"Zahnarzt - Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="2px"> Ellen Klotz  <span className='mx-1'>,</span> </BodyText>}
                                                        toolTipContent={"ZFA - Praxis Gellenbech & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="2px"> Irene Gantert </BodyText>}
                                                        toolTipContent={"ZFA- Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                </div>
                                            </Box>
                                        </Box>
                                    </DisplayStart>
                                </Box>
                            </Col>
                            <Col md={6} className='py-3'>
                                <Box className='rounded-4 p-3 boxShadow h-100'>
                                    <DisplayStart className='align-items-start'>
                                        <Box>
                                            <BadgeWidget solid={true} title={"QM"} color={"#0d3f5f"} bgColor={"#d2eaf9"} />
                                        </Box>
                                        <Box pl="18px">
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => setAddGroupModal(true)}> Gruppe bearbeiten » </BodyText>
                                            </Box>
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => handleDeleteGroup()}> Gruppe löschen » </BodyText>
                                            </Box>
                                            <Box>
                                                <div className='d-flex flex-wrap'>
                                                    <BodyText fontSize="14px" fontWeight="400" textColor="#666"> 0 Nutzer: </BodyText>
                                                </div>
                                            </Box>
                                        </Box>
                                    </DisplayStart>
                                </Box>
                            </Col>
                            <Col md={6} className='py-3'>
                                <Box className='rounded-4 p-3 boxShadow h-100'>
                                    <DisplayStart className='align-items-start'>
                                        <Box>
                                            <BadgeWidget solid={true} title={"Verwaltung"} color={"#0d3f5f"} bgColor={"#d2eaf9"} />
                                        </Box>
                                        <Box pl="18px">
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => setAddGroupModal(true)}> Gruppe bearbeiten » </BodyText>
                                            </Box>
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => handleDeleteGroup()}> Gruppe löschen » </BodyText>
                                            </Box>
                                            <Box>
                                                <div className='d-flex flex-wrap'>
                                                    <BodyText fontSize="14px" fontWeight="400" textColor="#666"> 2 Nutzer: </BodyText>
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="5px"> Nadine Häring <span className='mx-1'>,</span> </BodyText>}
                                                        toolTipContent={"Zahnarzt - Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="2px"> Alicia Gojani </BodyText>}
                                                        toolTipContent={"ZFA- Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                </div>
                                            </Box>
                                        </Box>
                                    </DisplayStart>
                                </Box>
                            </Col>
                            <Col md={6} className='py-3'>
                                <Box className='rounded-4 p-3 boxShadow h-100'>
                                    <DisplayStart className='align-items-start'>
                                        <Box>
                                            <BadgeWidget solid={true} title={"Zahnärzte"} color={"#0d3f5f"} bgColor={"#d2eaf9"} />
                                        </Box>
                                        <Box pl="18px">
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color'> Gruppe bearbeiten » </BodyText>
                                            </Box>
                                            <Box className='text-decoration-none cursor-pointer'>
                                                <BodyText fontSize="14px" fontWeight="500" textColor="#0d3f5f" className='text-link-color' onClick={() => handleDeleteGroup()}> Gruppe löschen » </BodyText>
                                            </Box>
                                            <Box>
                                                <div className='d-flex flex-wrap'>
                                                    <BodyText fontSize="14px" fontWeight="400" textColor="#666"> 2 Nutzer: </BodyText>
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="5px"> Dr. Sven Markus Gallenbach <span className='mx-1'>,</span> </BodyText>}
                                                        toolTipContent={"Zahnarzt - Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' ml="2px"> ZA Robert Stühn </BodyText>}
                                                        toolTipContent={"Zahnarzt - Praxis Gallenbach & Zähne"}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                </div>
                                            </Box>
                                        </Box>
                                    </DisplayStart>
                                </Box>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>


            <CommonModal
                showModal={editGroupModal}
                hideModal={() => setEditGroupModal(false)}
                modalTitle='Gruppenrechte bearbeiten'
                modalContent={<EditGroupAdminModal />}
                modalSize='lg'
                footerClass={"footer-sticky"}
            />

            <CommonModal
                showModal={addGroupModal}
                hideModal={() => setAddGroupModal(false)}
                modalTitle='Gruppe erstellen'
                modalContent={<AddGroupAdminModal />}
                modalSize='lg'
                footerClass={"footer-sticky"}
            />

        </>
    )
}