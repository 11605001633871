import React, { useState, useEffect } from 'react';
import { DisplayEnd } from '../../Components/StyledComponents';
import { useOutletContext } from "react-router-dom";
import CommonModal from '../../Components/Modals/CommonModal';
import { SendReminderModalContent } from '../../Components/Modals/ModelContents/SendReminderModalContent';
import ButtonWidget from '../../Components/ButtonWidget';
import { NewsList } from './NewsList';



export const NewsListWrapper = () => {

    const [showNewsData, setShowNewsData, displayLayout, isChecktEntry, setTotalAccount]: any = useOutletContext();

    const [sendReminder, setSendReminder] = useState<boolean>(false);

    return (
        <>
            <NewsList
                listData={showNewsData}
                setListData={setShowNewsData}
                displayMode={displayLayout}
                showChecks={isChecktEntry}
                setCheckCount={setTotalAccount}
            />


            <CommonModal
                showModal={sendReminder}
                hideModal={() => setSendReminder(false)}
                modalTitle='Erinnerung senden'
                modalContent={<SendReminderModalContent />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Schließen'}
                                class='me-2 ms-3'
                                handleClick={() => setSendReminder(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Ausgewählte erinnem'}
                                disabled={true}
                            />
                        </DisplayEnd>
                    </>
                }
                footerClass={"footer-sticky"}
            />
        </>
    )
}