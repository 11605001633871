import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const AbortAlrt = (props: any) => {
    return (
        <>
            <Modal show={props.displayAlertModal} onHide={props.hideAlertModal} backdrop="static" keyboard={false} className='shadow-modal' aria-labelledby="AlertModal">
                <Modal.Header closeButton className=''>
                    <Modal.Title id="AlertModal">
                        Verlassen
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='m-0'>
                        Möchtest du das Fenster wirklich verlassen und den Beitrag verwerfen? Du kannst deinen Fortschritt als Entwurf speichern, um später fortzufahren.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='default-btn f-14px me-1' >Bleiben</Button>
                    <Button className='default-btn f-14px me-1' >Entwurf speichern</Button>
                    <Button variant="danger" className='f-12px' onClick={props.hideAlertModal}>Entwurf löschen</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AbortAlrt;