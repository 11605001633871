import React from "react";
import Form from "react-bootstrap/Form";
import FormControl from "../../FormControl";
import { Box, HighlightBox } from "../../StyledComponents";
import { useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputTextControl } from "../../Widgets/InputControls/InputTextControl";

const formSchema = yup
  .object({
    firstName: yup
      .string()
      .typeError("First name is required")
      .required("First name is required"),
  })
  .required();

function ChangePasswordModalContent() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
    formState,
    watch,
  } = useForm<UserProfileDto>({
    defaultValues: new UserProfileDto(),
    resolver: yupResolver(formSchema) as any,
    // mode: "onChange",
    //resolver: yupResolver(formSchema),
  });
  return (
    <>
      <Box className="mb-2">
        <Form.Label>Altes Passwort</Form.Label>
        {/* <FormControl isFormGroup={true} Type='password' Size='sm' Placeholder='' Name='oldPassword' Id='oldPassword' /> */}
        <InputTextControl
          control={control}
          fieldName={"firstName"}
          error={errors.firstName}
          placeholder="Old Password"
          fieldType="password"
          size={"sm"}
        />
      </Box>
      <Box className="mb-2">
        <Form.Label>Neues Passwort</Form.Label>
        {/* <FormControl isFormGroup={true} Type='password' Size='sm' Placeholder='' Name='newPassword' Id='newPassword' /> */}
        <InputTextControl
          control={control}
          fieldName={"firstName"}
          error={errors.firstName}
          placeholder="New Password"
          fieldType="password"
          size={"sm"}
        />
      </Box>
      <Box className="mb-2">
        <Form.Label>Passwort bestätigen</Form.Label>
        <FormControl
          isFormGroup={true}
          Type="password"
          Size="sm"
          Placeholder=""
          Name="confirmPassword"
          Id="confirmPassword"
        />
      </Box>
      <HighlightBox
        className="mb-2"
        bgColor="#d9edf7"
        borderColor="#bce8f1"
        textColor="#31708f"
      >
        Das Passwort muss beinhalten:
        <ul className="m-0">
          <li>mindestens undefined Zeichen</li>
        </ul>
      </HighlightBox>
    </>
  );
}

export default ChangePasswordModalContent;
