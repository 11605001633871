import React, { useState } from 'react';
import { BodyText, Box, DisplayEnd, Divider } from '../../../StyledComponents';
import { Link } from 'react-router-dom';
import BadgeWidget from '../../../BadgeWidget';
import ButtonWidget from '../../../ButtonWidget';
import CommonModal from '../../CommonModal';
import { RequestMoreInformationModalContent } from './RequestMoreInformationModalContent';
import { Button } from 'react-bootstrap';

export const MessangerModalContent = () => {

    const [requestInfo, setRequestInfo] = useState<boolean>(false);

    return (
        <>
            <div className='modal-moment'>
                <Box>
                    <DisplayEnd>
                        <ButtonWidget
                            text="Request More Information"
                            varient="custom"
                            bgColor="#aad655"
                            textColor="#fff"
                            textColorHover="#0D3F5F"
                            bgHoverColor="#b4e664"
                            handleClick={() => setRequestInfo(true)}
                        />
                    </DisplayEnd>
                </Box>
                <Box mt="20px" mb="20px" className='text-center'>
                    <img src="/images/assets/messanger-icon.png" className='integration-preview-img' />
                </Box>
                <Box mb="15px">
                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' mt="10px" mb="10px">
                        Facebook Messenger Integration
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="10px" mb="10px">
                        This interface enables direct communication with the patient via Facebook Messenger. Patients can send their inquiries, requests and concerns via Facebook Messenger to the hotel. These requests can be answered by the hotel staff directly in medikit and a request can be forwarded internally.
                    </BodyText>
                </Box>
                <Box mb="15px">
                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' mt="10px" mb="10px">
                        Functions
                    </BodyText>
                    <Box mt="10px" mb="10px">
                        <ul>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'>Facebook Messenger conversations that are initiated by a guest or prospective customer are displayed directly in the medikit messages</BodyText></li>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'>You can chat directly from medikit with the Facebook user and any inquiries can be answered</BodyText></li>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'>At the same time, it is aslo possible to communicate in the chat area internally with the responsible colleagues. With the @-function responsibilities can be distributed to individuals or groups</BodyText></li>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'> The default recipients of the Facebook Messenger messages can be individually selected and the visibility (public/ non-public) can be defined </BodyText></li>
                        </ul>
                    </Box>
                </Box>
                <Box mb="15px">
                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' mt="10px" mb="10px">
                        Advantages
                    </BodyText>
                    <Box mt="10px" mb="10px">
                        <ul>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'> Quick and easy communication with patients before, during and after their stay </BodyText></li>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'> Virtual reception to meet patient requests and advise them </BodyText></li>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'> Short response times regardless of office hours and location (also available on mobile devices) </BodyText></li>
                            <li><BodyText fontSize='13px' fontWeight='400' textColor='#333'> No login to Facebook necessary </BodyText></li>
                        </ul>
                    </Box>
                </Box>
                <Box mb="15px">
                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' mt="10px" mb="10px">
                        Activation & Costs
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="10px" mb="10px">
                        Choose the interface Facebook Messenger and contact your medikit Customer Success Manager by clicking on ‘request more information’. We are happy to support you personally with the activation of Facebook Messenger in your hotelkit. A one-time activation fee of 199 EUR applies.
                    </BodyText>
                </Box>
                <Box mb="15px">
                    <BodyText fontSize='0.88em' fontWeight='600' textColor='#333' mt="10px" mb="10px">
                        Contact Details
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                        hotelkit GmbH
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                        +43 662 238080
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                        <Link to="" className='text-decoration-none'>
                            <span className='w-176px text-truncate text-link-color f-14px text-primary'> info@hotelkit.net </span>
                        </Link>
                    </BodyText>
                    <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                        <Link to="" className='text-decoration-none'>
                            <span className='w-176px text-truncate text-link-color f-14px text-primary'> www.hotelkit.net </span>
                        </Link>
                    </BodyText>
                </Box>
                <Divider mt="5px" mb="6px" />
                <Box className='text-center' mt="5px">
                    <BadgeWidget solid={true} title="Integrations" color="#fff" bgColor="#337ab7" />
                </Box>
            </div>

            <CommonModal
                showModal={requestInfo}
                hideModal={() => setRequestInfo(false)}
                modalTitle='Request more information'
                modalContent={<RequestMoreInformationModalContent />}
                modalSize='md'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <Button className="default-btn f-14px me-2" onClick={() => setRequestInfo(false)}>
                                Close
                            </Button>
                            <Button
                                className="primary-bg f-14px">
                                Submit
                            </Button>
                        </DisplayEnd>
                    </>
                }
            />
        </>
    )
}
