import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CommonList from '../../../CommonList';
import BadgeWidget from '../../../BadgeWidget';
import { FaCaretDown, FaCaretRight, FaPlusCircle } from 'react-icons/fa';
import { BodyText, BorderBox, Box, DisplayBetween, DisplayEnd } from '../../../StyledComponents';
import ButtonWidget from '../../../ButtonWidget';
import FormControl from '../../../FormControl';
import Tagify from '../../../Tagify';
import WidgetTreeView from '../../../WidgetTreeView';


// type listType = {
//    title: string,
//    isActive: boolean,
// }
// const listData: listType[] = [
//    { title: 'Assistenz', isActive: false },
//    { title: 'Hygiene', isActive: false },
//    { title: 'Prophylaxe', isActive: false },
//    { title: 'Qualitätsmanagement', isActive: false },
//    { title: 'Sonstiges', isActive: false },
//    { title: 'Verwaltung', isActive: false },
//    { title: 'Zahnärzte', isActive: false },
// ];




const jsonData = [
   {
      id: 1,
      label: 'Assistenz',
      isActive: false
   },
   {
      id: 2,
      label: 'Hygiene',
      isActive: false
   },
   {
      id: 3,
      label: 'Prophylaxe',
      isActive: false
   },
   {
      id: 4,
      label: 'Qualitätsmanagement',
      isActive: false
   },
   {
      id: 5,
      label: 'Sonstiges',
      isActive: false
   },
   {
      id: 6,
      label: 'Verwaltung',
      isActive: false
   },
   {
      id: 7,
      label: 'Zahnärzte',
      isActive: false
   },
];





function ManageListModalContent() {
   const [isShow, setIsShow] = useState<boolean>(false);
   const [activeLabel, setActiveLabel] = useState<string>('');
   const [mainName, setMainName] = useState<string>('');
   const handleExpandClick = () => {
      setActiveLabel('');
      if (mainName === 'Praxis Gallenbach & Zähne') {
         setMainName('');
      } else {
         setMainName('Praxis Gallenbach & Zähne');
      }
   }
   return (
      <>
         <Row>
            <Col lg={3} className='pt-3 pb-0 border-end'>
               <Box mb='10px' cursor='pointer' onClick={() => { setIsShow(!isShow); handleExpandClick(); }}>
                  {isShow ?
                     <FaCaretDown size={16} />
                     :
                     <FaCaretRight size={16} />
                  }
                  <BadgeWidget outiline={true} title='Praxis Gallenbach & Zähne ' color='#0d3f5f' />
               </Box>
               {isShow ?
                  <Box>
                     {/* <CommonList listData={listData} getListItem={setActiveListItem} /> */}
                     <WidgetTreeView treeData={jsonData} getActiveLabel={setActiveLabel} />
                  </Box>
                  :
                  null
               }
            </Col>
            <Col lg={9} className='pt-3 pb-0 px-0'>
               <Box pl='15px' pr='12px'>
                  <Row>
                     {isShow ?
                        <>
                           <Col lg={6} className='pb-sm-10px'>
                              <BodyText fontSize='14px' fontWeight='700'>Liste {!!activeLabel ? <span>{activeLabel}</span> : mainName} bearbeiten</BodyText>
                           </Col>
                           <Col lg={6} className='ps-lg-0 pb-sm-20px'>
                              <DisplayEnd verticalAlign='start'>
                                 <Box>
                                    {!!activeLabel ?
                                       <>
                                          <ButtonWidget
                                             text='Löschen'
                                             varient='danger'
                                             class='f-12px'
                                          />
                                          <ButtonWidget
                                             text='Verschieben'
                                             varient='light'
                                             class='default-btn f-12px mx-2'
                                          />
                                       </>
                                       :
                                       null
                                    }
                                    <ButtonWidget
                                       text='Unterliste hinzufügen'
                                       varient='light'
                                       class='default-btn f-12px'
                                    />
                                 </Box>
                              </DisplayEnd>
                           </Col>
                        </>
                        :
                        <Col md={12}>
                           <BodyText mb="11px" fontSize='14px' fontWeight='700'>Liste bearbeiten</BodyText>
                        </Col>
                     }
                  </Row>
               </Box>
               {!!activeLabel ?
                  <>
                     <BorderBox mt='15px' pb='15px' pl='15px' pr='12px'>
                        <Row>
                           <Col md={12}>
                              <Form.Label>Name</Form.Label>
                              <FormControl isFormGroup={false} Type='text' Size='sm' Value={activeLabel} Name='name' Id='name' />
                           </Col>
                        </Row>
                     </BorderBox>
                     <BorderBox borderTop='none' pb='15px' pl='15px' pr='12px'>
                        <Row>
                           <Col md={9}>
                              <Form.Label>Standard-Empfänger</Form.Label>
                              <Tagify placeholder='' />
                           </Col>
                           <Col md={3} className='align-self-end ps-0'>
                              <ButtonWidget
                                 text={<><FaPlusCircle className='default-btn-icon' /> Alle anzeigen</>}
                                 varient='light'
                                 class='default-btn w-lg-100'
                              />
                           </Col>
                        </Row>
                     </BorderBox>
                     <BorderBox borderTop='none' pl='15px' pr='12px' pb='15px'>
                        <Row>
                           <Col md={9}>
                              <Form.Label>Standardmäßige Abonnenten:</Form.Label>
                              <Tagify placeholder='' />
                           </Col>
                           <Col md={3} className='align-self-end ps-0'>
                              <ButtonWidget
                                 text={<><FaPlusCircle className='default-btn-icon' />Alle anzeigen</>}
                                 varient='custom'
                                 class='default-btn w-lg-100'
                              />
                           </Col>
                        </Row>
                     </BorderBox>
                     <DisplayEnd mt="15px" mb="15px" pr='12px'>
                        <ButtonWidget varient='primary' text="Erstellen" />
                     </DisplayEnd>
                  </>
                  :
                  null
               }
            </Col>
         </Row>
      </>
   );
}

export default ManageListModalContent;