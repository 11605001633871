import { FC, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { CheckControl } from "../../../Components/Widgets/InputControls/CheckControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { BodyText, Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider } from "../../../Components/StyledComponents";
import { InputTextControl } from "../../../Components/Widgets/InputControls/InputTextControl";
import ButtonWidget from "../../../Components/ButtonWidget";
import { SelectControl } from "../../../Components/Widgets/InputControls/SelectControl";
import { AllRepairNumberDropdown } from "../../../CommonJSON";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import Recipient from "../../../Components/Modals/Recipient";
import Swal from 'sweetalert2';


interface IProps { }


const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
        lastName: yup
            .string()
            .typeError("Last name is required")
            .required("Last name is required"),
        position: yup
            .string()
            .typeError("Position is required")
            .required("Position is required"),
    })
    .required();


const Passwords: FC<IProps> = ({ }) => {

    const [addException, setAddException] = useState<boolean>(false);
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    const [clickCount, setClickCount] = useState<number>(0);


    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });
    const fn = watch("firstName");
    const { gender } = watch();
    console.log(fn);

    const handleAddException = () => {
        // if (clickCount <= 0) {
        setClickCount(clickCount + 1);
        setAddException(true);
        // }
    }

    const handleDeletePassword = () => {
        Swal.fire({
            title: 'Möchtest du diese Einstellung ändern?',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonText: `Abbrechen`,
            confirmButtonText: 'Löschen',
            customClass: {
                container: 'discardTask-alert',
                confirmButton: 'f-14px btn-danger fw-400',
                cancelButton: 'f-14px default-btn fw-400',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Verworfen!', '', 'success');
            }
        })
    }


    return (
        <>

            <Row>
                <Col md={6} className="mt-1">
                    <Box>
                        <h3> Passwort zurücksetzen </h3>
                        <BodyText fontSize='15px' fontWeight='400' textColor='#666' mt="10px" mb="15px"> Definiere Rechte zum Zurücksetzen von Passwörtern </BodyText>
                    </Box>
                    <Card>
                        <Card.Body>
                            <Box>
                                <BodyText fontSize='18px' fontWeight='500' textColor='#333'> Einstellungen </BodyText>
                                <Divider mt="10px" mb="10px" />
                            </Box>
                            <Box mb="10px">
                                <CheckControl
                                    fieldType={"radio"}
                                    control={control}
                                    fieldName={"gender"}
                                    error={errors.gender}
                                    value={"Erlaube Admins Passwörter zurückzusetzen"}
                                    label={"Erlaube Admins Passwörter zurückzusetzen"}
                                    isInline={false}
                                    disabled={false}
                                    checked={undefined}
                                    defaultChecked={false}
                                />
                                <Divider mt="10px" mb="10px" />
                                <CheckControl
                                    fieldType={"radio"}
                                    control={control}
                                    fieldName={"gender"}
                                    error={errors.gender}
                                    value={"Erlaube nur Main Admin Passwörter zurückzusetzen"}
                                    label={"Erlaube nur Main Admin Passwörter zurückzusetzen"}
                                    isInline={false}
                                    disabled={false}
                                    checked={undefined}
                                    defaultChecked={false}
                                />
                                <Divider mt="10px" mb="10px" />
                                <CheckControl
                                    fieldType={"radio"}
                                    control={control}
                                    fieldName={"gender"}
                                    error={errors.gender}
                                    value={"Passwort zurücksetzen für alle verbieten"}
                                    label={"Passwort zurücksetzen für alle verbieten"}
                                    isInline={false}
                                    disabled={false}
                                    checked={undefined}
                                    defaultChecked={false}
                                />
                                <Divider mt="10px" mb="10px" />
                                <DisplayEnd className="mt-3">
                                    <ButtonWidget
                                        text="Speichern"
                                        varient="primary"
                                        class=""
                                    />
                                </DisplayEnd>
                            </Box>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Box mt="15px" mb="10px">
                        <Divider mt="" mb="3px" />
                        <Divider mt="" mb="" />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mt-1">
                    <Box>
                        <h3> Passwort Sicherheitseinstellungen </h3>
                        <BodyText fontSize='15px' fontWeight='400' textColor='#666' mt="10px" mb="15px"> Einstellungen ändern, wie sicher neue Passwörter sein sollen und wie oft Nutzer sie ändern müssen. </BodyText>
                    </Box>
                    <Card>
                        <Card.Body>
                            <Box className="main-box-wrapper">
                                <Box>
                                    <BodyText fontSize='18px' fontWeight='500' textColor='#333'> Allgemeine Einstellungen </BodyText>
                                    <BodyText fontSize='15px' fontWeight='400' textColor='#666' mt="10px" mb="15px"> Wird für alle Benutzer ohne weiter angegebene Gruppeneinstellung verwendet. </BodyText>
                                </Box>
                                <Box>
                                    <BodyText fontSize='18px' fontWeight='600' textColor='#333'> Minimale Passwortlänge </BodyText>
                                </Box>
                                <Box mt="8px" mb="8px" width="300px">
                                    <InputTextControl
                                        control={control}
                                        fieldName={"firstName"}
                                        error={errors.firstName}
                                        placeholder=""
                                        fieldType="number"
                                        size={"sm"}
                                    />
                                </Box>
                                <Box mb="10px" mt="10px">
                                    <BodyText fontSize='18px' fontWeight='600' textColor='#333' mb="10px"> Notwendige Zeichen </BodyText>
                                    <CheckControl
                                        fieldType={"radio"}
                                        control={control}
                                        fieldName={"gender"}
                                        error={errors.gender}
                                        value={"mindestens ein Großbuchstabe"}
                                        label={"mindestens ein Großbuchstabe"}
                                        isInline={false}
                                        disabled={false}
                                        checked={undefined}
                                        defaultChecked={false}
                                    />
                                    <CheckControl
                                        fieldType={"radio"}
                                        control={control}
                                        fieldName={"gender"}
                                        error={errors.gender}
                                        value={"mindestens eine Zahl"}
                                        label={"mindestens eine Zahl"}
                                        isInline={false}
                                        disabled={false}
                                        checked={undefined}
                                        defaultChecked={false}
                                    />
                                    <CheckControl
                                        fieldType={"radio"}
                                        control={control}
                                        fieldName={"gender"}
                                        error={errors.gender}
                                        value={"mindestens ein Sonderzeichen"}
                                        label={"mindestens ein Sonderzeichen"}
                                        isInline={false}
                                        disabled={false}
                                        checked={undefined}
                                        defaultChecked={false}
                                    />
                                </Box>
                                <Box mt="6px" mb="6px">
                                    <BodyText fontSize='18px' fontWeight='600' textColor='#333'> Passwortablauf </BodyText>
                                </Box>
                                <Box mt="8px" mb="8px">
                                    <BodyText fontSize='15px' fontWeight='400' textColor='#333' mb="6px"> Benutzer werden gebeten, ihr Passwort nach x Tagen zu ändern </BodyText>
                                    <Box width="300px">
                                        <InputTextControl
                                            control={control}
                                            fieldName={"firstName"}
                                            error={errors.firstName}
                                            placeholder=""
                                            fieldType="number"
                                            size={"sm"}
                                            width={'150px'}
                                        />
                                    </Box>
                                </Box>
                                <Divider mt="15px" mb="10px" />
                                <DisplayEnd className="mt-3">
                                    <ButtonWidget
                                        text="Speichern"
                                        varient="primary"
                                        class=""
                                    />
                                </DisplayEnd>
                            </Box>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {addException &&
                <>
                    {[...Array(clickCount)].map((_, index) => (
                        <Row>
                            <Col md={6} className="my-3" key={index}>
                                <Card>
                                    <Card.Body>
                                        <Box className="main-box-wrapper">
                                            <Box>
                                                <DisplayBetween mb="10px">
                                                    <BodyText fontSize='18px' fontWeight='500' textColor='#333'> Spezielle Gruppeneinstellungen </BodyText>
                                                    <ButtonWidget
                                                        varient="danger"
                                                        text={
                                                            <>
                                                                <FaTrashAlt size={15} />
                                                            </>
                                                        }
                                                        class="pb-2"
                                                        handleClick={() => handleDeletePassword()}
                                                    />
                                                </DisplayBetween>
                                                <DisplayStart>
                                                    <Box mr="10px" width="300px">
                                                        <InputTextControl
                                                            control={control}
                                                            fieldName={"firstName"}
                                                            error={errors.firstName}
                                                            placeholder="Gruppen hinzufügen"
                                                            fieldType="text"
                                                            size={"md"}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <ButtonWidget
                                                            varient="light"
                                                            text={
                                                                <>
                                                                    <FaPlusCircle size={15} />
                                                                </>
                                                            }
                                                            handleClick={() => setRecipientModal(true)}
                                                        />
                                                    </Box>
                                                </DisplayStart>
                                            </Box>
                                            <Box mt="10px">
                                                <BodyText fontSize='18px' fontWeight='600' textColor='#333'> Minimale Passwortlänge </BodyText>
                                            </Box>
                                            <Box mt="8px" mb="8px" width="300px">
                                                <InputTextControl
                                                    control={control}
                                                    fieldName={"firstName"}
                                                    error={errors.firstName}
                                                    placeholder=""
                                                    fieldType="text"
                                                    size={"sm"}
                                                />
                                            </Box>
                                            <Box mb="10px" mt="10px">
                                                <BodyText fontSize='18px' fontWeight='600' textColor='#333' mb="10px"> Notwendige Zeichen </BodyText>
                                                <CheckControl
                                                    fieldType={"radio"}
                                                    control={control}
                                                    fieldName={"gender"}
                                                    error={errors.gender}
                                                    value={"mindestens ein Großbuchstabe"}
                                                    label={"mindestens ein Großbuchstabe"}
                                                    isInline={false}
                                                    disabled={false}
                                                    checked={undefined}
                                                    defaultChecked={false}
                                                />
                                                <CheckControl
                                                    fieldType={"radio"}
                                                    control={control}
                                                    fieldName={"gender"}
                                                    error={errors.gender}
                                                    value={"mindestens eine Zahl"}
                                                    label={"mindestens eine Zahl"}
                                                    isInline={false}
                                                    disabled={false}
                                                    checked={undefined}
                                                    defaultChecked={false}
                                                />
                                                <CheckControl
                                                    fieldType={"radio"}
                                                    control={control}
                                                    fieldName={"gender"}
                                                    error={errors.gender}
                                                    value={"mindestens ein Sonderzeichen"}
                                                    label={"mindestens ein Sonderzeichen"}
                                                    isInline={false}
                                                    disabled={false}
                                                    checked={undefined}
                                                    defaultChecked={false}
                                                />
                                            </Box>
                                            <Box mt="6px" mb="6px">
                                                <BodyText fontSize='18px' fontWeight='600' textColor='#333'> Passwortablauf </BodyText>
                                            </Box>
                                            <Box mt="8px" mb="8px">
                                                <BodyText fontSize='15px' fontWeight='400' textColor='#333' mb="6px"> Benutzer werden gebeten, ihr Passwort nach x Tagen zu ändern </BodyText>
                                                <Box width="300px">
                                                    <InputTextControl
                                                        control={control}
                                                        fieldName={"firstName"}
                                                        error={errors.firstName}
                                                        placeholder=""
                                                        fieldType="number"
                                                        size={"sm"}
                                                        width={'150px'}
                                                    />
                                                </Box>
                                            </Box>
                                            <Divider mt="15px" mb="10px" />
                                            <DisplayEnd className="mt-3">
                                                <ButtonWidget
                                                    text="Speichern"
                                                    varient="primary"
                                                    class=""
                                                />
                                            </DisplayEnd>
                                        </Box>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    ))}
                </>
            }

            <Box mt="15px">
                <ButtonWidget
                    text="Ausnahme hinzufügen"
                    varient="primary"
                    class=""
                    handleClick={handleAddException}
                />
            </Box>

            <Recipient
                displayRecipientModal={RecipientModal}
                hideRecipientModal={() => setRecipientModal(false)}
            />

        </>
    );
}

export default Passwords;