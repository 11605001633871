import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BodyText, Box, DisplayEnd } from '../../../Components/StyledComponents';
import { FaSearch } from 'react-icons/fa';
import Form from "react-bootstrap/Form";
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CommonModal from '../../../Components/Modals/CommonModal';


const TutorialData = [
    {
        image: "/images/assets/fitImage.png",
        duration: "20:14",
        title: " Basic Training",
        description: "Get to know all the basic functions of hotelkit to get started"
    },
    {
        image: "/images/assets/fitImage.png",
        duration: "10:34",
        title: "Simple Training",
        description: "Get to know all the basic functions of hotelkit"
    },
    {
        image: "/images/assets/fitImage.png",
        duration: "10:34",
        title: "Advance Training",
        description: "Get to know all the basic functions of hotelkit"
    },
    {
        image: "/images/assets/fitImage.png",
        duration: "10:34",
        title: "Advance Training",
        description: "Get to know all the basic functions of hotelkit"
    },
    {
        image: "/images/assets/fitImage.png",
        duration: "10:34",
        title: "International Training",
        description: "Get to know all the basic functions of hotelkit"
    },
]

export const TutorialVideo = () => {

    const videoUrl = 'https://www.youtube.com/watch?v=PP8fDCs-4kk'; // Replace with your video URL

    const [videoPlayModal, setVideoPlayModal] = useState<boolean>(false);

    const handleImageClick = () => {
        setVideoPlayModal(true);
    };


    return (
        <>
            <div className='topbar-video-tutorial mb-3'>
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <BodyText fontSize='14px' fontWeight='bold' textColor='#666' className='text-uppercase'> Tutorial Videos </BodyText>
                    </div>
                    <div>
                        <div>
                            <Form className="d-flex">
                                <Form.Control
                                    type="search"
                                    placeholder="Search by name"
                                    className="me-0 rounded-end-0 py-0"
                                    aria-label="Search"
                                />
                                <Button className='default-btn f-14px fw-500 rounded-end rounded-start-0' >Search</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='video-card-sec'>
                <Container>
                    <Row>
                        {TutorialData.map((item, index) => {
                            return (
                                <>
                                    <Col lg={3} md={4} sm={12} className='cursor-pointer card-tutorial-video mb-2' key={index}>
                                        <Card className='tutorial-card'>
                                            <Card.Body className='p-3'>
                                                <Box onClick={handleImageClick}><img src={item.image} className='integration-preview-img' /> </Box>
                                                <DisplayEnd>
                                                    <BodyText fontSize='14px' fontWeight='600' textColor='#fff' mb="12px" className='bg-dark px-1'> {item.duration} </BodyText>
                                                </DisplayEnd>
                                                <div className='text-center'>
                                                    <Box>
                                                        <BodyText fontSize='18px' fontWeight='600' textColor='#666' mb="12px"> {item.title} </BodyText>
                                                    </Box>
                                                    <Card.Text>
                                                        <BodyText fontSize='13px' fontWeight='400' textColor='#666'> {item.description} </BodyText>
                                                    </Card.Text>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </>
                            )
                        })}

                    </Row>
                </Container>
                <CommonModal
                    showModal={videoPlayModal}
                    hideModal={() => setVideoPlayModal(false)}
                    modalTitle='Videoanleitung'
                    modalContent={
                        <>
                            <video width="100%" height="440" controls>
                                <source src="https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4" type="video/mp4"></source>
                            </video>
                        </>
                    }
                    modalSize='lg'
                />
            </div>
        </>
    )
}
