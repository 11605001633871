import { BodyText } from "../../StyledComponents"

interface type {
  deleteModal?: string,
}

export const DeleteModuleModalContent = (props: type) => {

  const { deleteModal } = props;

  return (
    <>
      <div className="pb-1">
        {deleteModal === "StatusDeleteModal" ?
          <BodyText fontSize="14px" fontWeight="400">
            Soll dieses Modul wirklich gelöscht werden?
          </BodyText>
          : null}
        {deleteModal === "upcomingEvents" ?
          <BodyText fontSize="14px" fontWeight="400">
            Soll dieses Modul wirklich gelöscht werden?
          </BodyText>
          : null}
        {deleteModal === "OverdueTasksList" ?
          <BodyText fontSize="14px" fontWeight="400">
            Soll dieses Modul wirklich gelöscht werden?
          </BodyText>
          : null}
      </div>
    </>
  )
}