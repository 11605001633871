import React, { FC, useState, createRef, Ref } from 'react';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Col, Row } from 'react-bootstrap';
import { Box, DisplayCenter } from '../../StyledComponents';
import ButtonWidget from '../../ButtonWidget';
import { FaCrop } from "react-icons/fa";

interface IProps {
    ImgSrc: string,
    CropRef: any
}

const CropImgModalContent: FC<IProps> = ({ ImgSrc, CropRef }) => {
    const cropperRef = createRef<ReactCropperElement>();

    // const getCropData = () => {
    //     if (typeof cropperRef.current?.cropper !== "undefined") {
    //         CropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    //     }
    // };

    return (
        <>
            <Row>
                <Col md={12}>
                    <Cropper
                        ref={CropRef}
                        style={{ height: 400, width: "100%" }}
                        zoomTo={0.1}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={ImgSrc}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                    />
                </Col>
            </Row>
        </>
    );
}

export default CropImgModalContent;