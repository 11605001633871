import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ModalHeader } from "../StyledComponents";

interface modalProps {
  modalTitle: string;
  modalContent: any;
  showModal: boolean;
  hideModal: () => void;
  centered?: boolean;
  modalSize?: any;
  modalFooterContent?: any;
  bodyStyleClass?: any;
  modelId?: any;
  reference?: any;
  footerClass?: any;
}
function CommonModal(props: modalProps) {
  const {
    modalTitle,
    modalContent,
    modalSize,
    showModal,
    hideModal,
    modalFooterContent,
    modelId,
    reference,
    centered,
    footerClass,
  } = props;


  return (
    <>
      <Modal
        id={modelId}
        size={modalSize}
        show={showModal}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="common-modal-title"
        centered={centered}
      >
        <ModalHeader closeButton bgColor="#d8d8d8">
          <Modal.Title id="common-modal-title">{modalTitle}</Modal.Title>
        </ModalHeader>
        <Modal.Body
          className={props.bodyStyleClass ? props.bodyStyleClass : null}
        >
          {modalContent}
        </Modal.Body>
        <Modal.Footer className={`justify-content-end ${footerClass}`}>
          {!!modalFooterContent ?
            (
              <>
                {modalFooterContent}
              </>
            ) : (
              <>
                <Button className="default-btn f-14px me-1" onClick={hideModal}>
                  Abbrechen
                </Button>
                <Button
                  className="primary-bg f-14px"
                  onClick={() => {
                    reference.current.dispatchEvent(
                      new Event("submit", { cancelable: true, bubbles: true })
                    );
                  }}
                >
                  Erstellen
                </Button>
              </>
            )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommonModal;
