import React, { useState, useEffect } from 'react';
import { Row, Col, Table, DropdownButton, Dropdown, } from 'react-bootstrap';
import { AccountSettingImg, BodyText, Box, DisplayStart, Divider } from '../../StyledComponents';
import Form from 'react-bootstrap/Form';
import FormControl from '../../FormControl';
import FormCheck from '../../FormCheck';
import FormSelect from '../../FormSelect';
import ButtonWidget from '../../ButtonWidget';
import { AnytimeDropdown, RelevanceDropdown, SelectLanguageDropdown, language } from '../../../CommonJSON';


export const ArticleTranslationModalContent = () => {

    const [addTranslate, setAddTranslate] = useState<boolean>(false);
    const [existArticle, setExistArticle] = useState<boolean>(false);
    const [createArticle, setCreateArticle] = useState<boolean>(false);


    const handleAddTranslate = () => {
        setAddTranslate(!addTranslate);
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Titel</th>
                                <th>Sprache</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Box>
                                        <BodyText fontSize='14px' fontWeight='400' textColor='#666'>Die 10 Gebote unserer Serviceorientierung</BodyText>
                                        <BodyText fontSize='12px' fontWeight='400' textColor='#008000'> Aktueller Artikel </BodyText>
                                    </Box>
                                </td>
                                <td>
                                    <div>
                                        <FormSelect
                                            isFormGroup={true}
                                            selectOptions={language}
                                            Size='sm'
                                            Name='salute'
                                            Id='salute'
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Box>
                        <DisplayStart>
                            <ButtonWidget
                                varient='light'
                                text={'Übersetzung hinzufügen'}
                                handleClick={handleAddTranslate}
                            />
                            {addTranslate ?
                                <>
                                    <Box ml='8px'>
                                        <Form.Group controlId='Anrede' className="mb-0">
                                            <DisplayStart>
                                                <Form.Check
                                                    inline
                                                    label="Bestehender Artikel"
                                                    name="group1"
                                                    type="radio"
                                                    id="public"
                                                    onChange={() => { setExistArticle(true); setCreateArticle(false) }}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Neuen Artikel erstellen"
                                                    name="group1"
                                                    type="radio"
                                                    id="toalluser"
                                                    onChange={() => { setExistArticle(false); setCreateArticle(true) }}
                                                />
                                                {createArticle ?
                                                    <>
                                                        <DropdownButton size="sm" className="template-sort f-12px mx-1" title="Sprache wählen">
                                                            {SelectLanguageDropdown.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <Dropdown.Item key={index}>
                                                                            <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                                                        </Dropdown.Item>
                                                                    </>
                                                                )
                                                            })}
                                                        </DropdownButton>
                                                    </>
                                                    : null}
                                            </DisplayStart>
                                        </Form.Group>
                                    </Box>
                                </>
                                : null}
                        </DisplayStart>
                    </Box>
                </Col>
                <Divider mb='12px' mt='12px' />
                {existArticle ?
                    <>
                        <Col md={12}>
                            <Box>
                                <DisplayStart>
                                    <BodyText mb='0px' fontSize='13px' fontWeight='400' textColor='#000'> Suchen: </BodyText>
                                    <Form.Control size="sm" type="text" placeholder="Artikel suchen..." className='ms-2' />
                                    <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="Beliebige Zeit">
                                        {AnytimeDropdown.map((item, index) => {
                                            return (
                                                <>
                                                    <Dropdown.Item key={index}>
                                                        <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                                    </Dropdown.Item>
                                                </>
                                            )
                                        })}
                                    </DropdownButton>
                                    <DropdownButton size="sm" className="template-sort f-12px" id="new-item" title="Sortierung: nach Relevanz">
                                        {RelevanceDropdown.map((item, index) => {
                                            return (
                                                <>
                                                    <Dropdown.Item key={index}>
                                                        <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                                    </Dropdown.Item>
                                                </>
                                            )
                                        })}
                                    </DropdownButton>
                                </DisplayStart>
                            </Box>
                        </Col>
                    </>
                    : null}
            </Row>
        </>
    )
}