import { ChangeEvent, FC, useState } from "react";
import { Button, Card, Dropdown, DropdownButton, Form, Nav, Table } from "react-bootstrap";
import { BodyText, Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider, HighlightBox } from "../../../Components/StyledComponents";
import { Col, Row } from 'react-bootstrap';
import ButtonWidget from "../../../Components/ButtonWidget";
import { InputTextControl } from "../../../Components/Widgets/InputControls/InputTextControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { setThemeColor } from "../../../Redux/Slice/appSlice";
import { useDispatch } from "react-redux";
import { FaCogs, FaPlusCircle, FaRegCalendarAlt } from "react-icons/fa";
import Recipient from "../../../Components/Modals/Recipient";
import Swal from 'sweetalert2';
import { FaClock } from "react-icons/fa";
import { HiChatBubbleBottomCenterText } from "react-icons/hi2";
import { FaAddressBook } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaBookmark } from "react-icons/fa";
import { HiStar } from "react-icons/hi2";
import { FaUserTie, FaSearch } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { HiLightBulb } from "react-icons/hi";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ArticleVersionComment, BackupResponsibleDropdown, IpBlockingDropdown, PastBackupsData } from "../../../CommonJSON";
import { SelectControl } from "../../../Components/Widgets/InputControls/SelectControl";
import { CheckControl } from "../../../Components/Widgets/InputControls/CheckControl";


interface IProps { }

const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
        lastName: yup
            .string()
            .typeError("Last name is required")
            .required("Last name is required"),
        position: yup
            .string()
            .typeError("Position is required")
            .required("Position is required"),
    })
    .required();

const BackUp: FC<IProps> = ({ }) => {

    let dispatch = useDispatch();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });
    const fn = watch("firstName");
    const { gender } = watch();
    console.log(fn);


    const [addIpAddress, setAddIpAddress] = useState<boolean>(false);
    const [requestNew, setRequestNew] = useState<boolean>(false);

    const handleIpAddress = () => {
        setAddIpAddress(!addIpAddress);
    }

    const handleRequestNew = () => {
        setRequestNew(!requestNew);
    }

    return (
        <>
            <div className="backup-wrapper">
                <Card className='boxShadow'>
                    <Card.Body>
                        <Row>
                            <Col md={12} xs={12}>
                                <Box className="mb-4 pb-3 px-3 border-bottom" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' className="text-uppercase"> Backup </BodyText>
                                </Box>
                                <Box mb="15px" mt="15px">
                                    <HighlightBox
                                        className="text-start"
                                        bgColor="#eee"
                                        borderColor="#888"
                                        textColor="#333"
                                    >
                                        Die Sicherheit & Verfügbarkeit deiner Daten ist uns ein sehr wichtiges Anliegen. Zusätzlich zu unseren Datensicherungsvorkehrungen (tägliche Sicherungen aller Daten auf 3 redundanten Systemen) bieten wir die Möglichkeit eigene Backups herunterzuladen. <br />
                                        Das passwortgeschützte Back-up kannst du an einem Speicherort deiner Wahl aufbewahren. Da es sich um eine Systemdatei handelt, kann diese von dir jedoch nicht geöffnet werden. <br />
                                        Wir generieren im wöchentlichen Rythmus eine zip-Datei, die du ganz einfach herunterladen kannst. Zusätzlich hast du die Möglichkeit jederzeit selbst ein Backup anzufordern, welches dann nach kurzer Zeit als Download zur Verfügung steht.
                                    </HighlightBox>
                                </Box>
                                <Box mt="20px">
                                    <BodyText fontSize='18px' fontWeight='600' textColor='#333'> Backup-Einstellungen </BodyText>
                                </Box>
                                <Box mt="10px">
                                    <CheckControl
                                        fieldType={"radio"}
                                        control={control}
                                        fieldName={"gender"}
                                        error={errors.gender}
                                        value={"Backup automatisch erstellen, einmal pro Woche"}
                                        label={"Backup automatisch erstellen, einmal pro Woche"}
                                        isInline={false}
                                        disabled={false}
                                        checked={undefined}
                                        defaultChecked={false}
                                    />
                                    <CheckControl
                                        fieldType={"radio"}
                                        control={control}
                                        fieldName={"gender"}
                                        error={errors.gender}
                                        value={"Backup automatisch erstellen, einmal pro Monat"}
                                        label={"Backup automatisch erstellen, einmal pro Monat"}
                                        isInline={false}
                                        disabled={false}
                                        checked={undefined}
                                        defaultChecked={false}
                                    />
                                    <CheckControl
                                        fieldType={"radio"}
                                        control={control}
                                        fieldName={"gender"}
                                        error={errors.gender}
                                        value={"Backups nur manuell anfordern"}
                                        label={"Backups nur manuell anfordern"}
                                        isInline={false}
                                        disabled={false}
                                        checked={undefined}
                                        defaultChecked={false}
                                    />
                                    <CheckControl
                                        fieldType={"checkbox"}
                                        control={control}
                                        fieldName={"gender"}
                                        error={errors.gender}
                                        value={"Backup-Erinnerungen deaktivieren"}
                                        label={"Backup-Erinnerungen deaktivieren"}
                                        isInline={false}
                                        disabled={false}
                                        checked={undefined}
                                        defaultChecked={false}
                                    />
                                </Box>
                                {requestNew ?
                                    <>
                                        <Box mb="15px" mt="15px">
                                            <HighlightBox
                                                className="text-center"
                                                bgColor="#dff0d8"
                                                borderColor="#d6e9c6"
                                                textColor="#3c763d"
                                            >
                                                Wir bitten um etwas Geduld. Das Backup wird nun generiert. <br />
                                                Du brauchst nicht auf dieser Seite verweilen, sondern wirst mittels einer Benachrichtigung informiert, wenn das Backup zum Download verfügbar ist.
                                            </HighlightBox>
                                        </Box>
                                    </>
                                    :
                                    <>
                                        <Box>
                                            <BodyText fontSize='18px' fontWeight='600' textColor='#333' mt="10px" mb="10px"> Neues Backup </BodyText>
                                            <ButtonWidget varient="light" text="Neues Backup anfordern" class='text-nowrap' handleClick={handleRequestNew} />
                                        </Box>
                                    </>}
                                <Box mb="15px" mt="15px">
                                    <Box>
                                        <BodyText fontSize='18px' fontWeight='600' textColor='#333'> Backup-Verantwortlicher </BodyText>
                                        <BodyText fontSize='14px' fontWeight='600' textColor='#333'> Hier kannst du eine Person auswählen, die für das Herunterladen der Backups verantwortlich ist. </BodyText>
                                    </Box>
                                    <Box width="250px" mt="10px">
                                        <SelectControl
                                            fieldId={"salutationId"}
                                            fieldName={"salutationId"}
                                            control={control}
                                            error={errors.salutationId}
                                            selectOptions={BackupResponsibleDropdown}
                                        />
                                    </Box>
                                </Box>
                                <div className="table-data backups">
                                    <Box mt="20px">
                                        <BodyText fontSize='18px' fontWeight='600' textColor='#333'> Vergangene Backups </BodyText>
                                    </Box>
                                    <Table responsive striped className="mt-2">
                                        <thead>
                                            <tr className="table-primary">
                                                <th> Angefragt </th>
                                                <th> Verfügbar </th>
                                                <th> Heruntergeladen </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {PastBackupsData.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td>{item.requestedTitle}</td>
                                                            <td>{item.available}</td>
                                                            <td>
                                                                {item.downloaded}
                                                                {item.downloadBtn &&
                                                                    <ButtonWidget varient="primary" text="Backup herunteriaden" class='text-nowrap' />
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default BackUp;