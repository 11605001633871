
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { BodyText, Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider } from '../../../Components/StyledComponents';
import { GroupActionButtonWidget } from '../../../Components/Widgets/GroupActionButtonWidget';
import CustomTooltip from '../../../Components/Tooltip';
import { FaAngleDown, FaAngleUp, FaArrowLeft, FaArrowRight, FaLink, FaLongArrowAltRight, FaMinusCircle, FaPencilAlt, FaPlusCircle, FaQuestionCircle, FaRegHandPointRight } from 'react-icons/fa';
import BadgeWidget from '../../../Components/BadgeWidget';
import EditorConvertToMarkdown from '../../../Components/TextEditor';
import ModelActions from '../../../Components/ModelActions';
import Tagify from '../../../Components/Tagify';
import AddMediaModal from '../../../Components/Modals/AddMediaModal';
import ButtonWidget from '../../../Components/ButtonWidget';
import { SendReminderModalContent } from '../../../Components/Modals/ModelContents/SendReminderModalContent';
import CommonModal from '../../../Components/Modals/CommonModal';
import { PdfSettingsModalContent } from '../../../Components/Modals/ModelContents/PdfSettingsModalContent';
import { DeleteMessageDetailModalContent } from '../../../Components/Modals/ModelContents/DeleteMessageDetailModalContent';
import { ReportContentModalContent } from '../../../Components/Modals/ModelContents/ReportContentModalContent';
import { UnsavedCommentModalContent } from '../../../Components/Modals/ModelContents/UnsavedCommentModalContent';
import { PermissionModalContent } from '../../../Components/Modals/ModelContents/PermissionModalContent';
import { AddPollModalContent } from '../../../Components/Modals/ModelContents/AddPollModalContent';
import { BookMarkOptionModalContent } from '../../../Components/Modals/GroupActionButtonModalContent.tsx/BookMarkOptionModalContent';
import { BookMarkFooterContent } from '../../Repair/MainContent';
import { RememberOptionModalContent } from '../../../Components/Modals/GroupActionButtonModalContent.tsx/RememberOptionModalContent';
import { ShowHomeScreenModalContent } from '../../../Components/Modals/ModelContents/ShowHomeScreenModalContent';
import { Link } from 'react-router-dom';
import { CompareVersionModalContent } from '../../../Components/Modals/ModelContents/CompareVersionModalContent';
import { SendEmailModalContent } from '../../../Components/Modals/ModelContents/SendEmailModalContent';
import { ArticleTranslationModalContent } from '../../../Components/Modals/ModelContents/ArticleTranslationModalContent';
import AddEditArticle from '../../Article/AddEditArticle';
import { HandBoockOrgContext, contextOrgType } from '../../../Components/Tables/handBoockOrgContext';


const ContextOrgTableData: contextOrgType[] = [
    {
        interestGroup: 'Patienten',
        intrestInTheClinic: ['Hochwertige und schadensfreie Behandlung', 'Faire Preise', 'Gute Beratung', 'Kurze Wartezeiten', 'Diskretion', 'Schnell Folgetermin erhalten', 'Einfache Anreise / Parkplatz', 'Schutz der personenbezogenen Daten'],
        risks: ['Produktionsfehler', 'Prozessfehler', 'Missverständnisse'],
        opportunities: ['Differenzierung von Wettbewerbern'],
        measures: ['Systematisches Qualitätsmanagement', 'Prozessübersicht Fehler- und Beschwerdemanagement', 'Einhaltung der DSGVO'],
    },
    {
        interestGroup: 'Angehörige der Patienten',
        intrestInTheClinic: ['Kurze Wartezeiten', 'Optimale Betreuung', 'Umfangreiche Dokumentation/klare Rechnunge'],
        risks: ['Missverständnisse'],
        opportunities: ['Neupatienten unter den Angehörigen nach positiver Behandlung'],
        measures: ['Informationen für Angehörige auf Webseite (Anfahrt etc.)'],
    },
    {
        interestGroup: 'Mitarbeiter',
        intrestInTheClinic: ['Gute Arbeitsbedingungen', 'Nette Kollegen', 'Angemessenes Gehalt', 'Gute Führung durch die Klinikleitung und durch Ärzte', 'Fortbildungschancen'],
        risks: ['Unzufriedenheit', 'Fehlverhalten einzelner', 'Konflikte'],
        opportunities: ['Halten der Mitarbeiter', 'Steigende Versorgungsqualität durch gute Teamarbeit'],
        measures: ['Mitarbeiterbefragung', 'Führungsschulungen', 'Planung systematischer Mitarbeitergespräche', 'Prozessübersicht Personal'],
    },
    {
        interestGroup: 'Behandler',
        intrestInTheClinic: ['Gute Arbeitsbedingungen', 'Angemessenes Gehalt', 'Vollständige Akte (inkl. Anamnes)', 'Umsetzung des Therapieplan in sinnvolle Terminfolge', 'Reputationsgewinn', 'Positive Bewertungen von Patienten', 'Fortbildungschancen'],
        risks: ['Unzufriedenheit'],
        opportunities: ['Halten talentierter Behandler', 'Weiterempfehlung als Arbeitgeber'],
        measures: ['Standardvorgabe für Terminplanung auf Basis der Therapieplanung'],
    },
    {
        interestGroup: 'Gesetzgeber',
        intrestInTheClinic: ['Einhaltung der gesetzlichen Vorgaben', 'Einhaltung der DSGVO'],
        risks: ['Kosten und Reputationsverlust aufgrund von Rechtsstreitigkeiten', 'Strafen'],
        opportunities: ['Positive Wahrnehmung beim Patienten'],
        measures: ['Gezielte Schulungen aller Mitarbeiter', 'Dokumentation der gesetzlichen Vorgaben im Handbuch', 'Umgehende Umsetzung bei Veränderung von Gesetzen'],
    },
    {
        interestGroup: 'Zulieferer',
        intrestInTheClinic: ['Bezahlung der Rechnungen', 'Für Ausführung von Aufträgen notwendige Informationen'],
        risks: ['Unzureichende Leistungserbringung', 'Ausfall (z.B. durch Insolvenz)', 'Direkter Ansprechpartner fällt aus ohne adäquaten Ersatz'],
        opportunities: ['Effiziente Auslagerung von Prozessen, die wir intern weniger effizient schaffen'],
        measures: ['Systematische Lieferantenauswertung', 'Zusammenarbeit mit soliden und bewährten Partnern', 'Definition von Prozessen', 'Interne Strukturen für optimale Abwicklung sicherstellen'],
    },
    {
        interestGroup: 'Anwohner',
        intrestInTheClinic: ['Keine Belästigungen durch Geräusche, Gerüche'],
        risks: ['Reputationsverlust', 'Rechtsstreitigkeiten'],
        opportunities: ['Positive Wahrnehmung im wichtigen Heimmarkt'],
        measures: ['Messung Geräuschbelästigung'],
    },
    {
        interestGroup: 'Überweiser',
        intrestInTheClinic: ['Behandlung der überwiesenen Patienten', 'Rücküberweisung'],
        risks: ['Streitigkeiten', 'Interessenskonflikte bei gravierend schlechtem Qualitätsniveau beim Überweiser', 'Zu lange Wartezeiten bei Kapazitätsengpässen auf unserer Seite'],
        opportunities: ['Mehr Überweisungen bei korrekter Abwicklung'],
        measures: ['Standardablauf festlegen', 'Informationen für Überweiser auf Webseite'],
    },
    {
        interestGroup: 'Ausbildungseinrichtungen (Berufsschulen, Universitäten)',
        intrestInTheClinic: ['Ausbildungsplätze bzw. Arbeitsplätze', 'Ordentliche Ausbildung der Azubis'],
        risks: ['Niedriges Niveau an den Schulen führt zu erheblichen Mehraufwand in der Ausbildung im Betrieb'],
        opportunities: ['Nachschub qualifizierter Mitarbeiter'],
        measures: ['Sammlung Ausbildungsveranstaltungen an Schulen'],
    },
    {
        interestGroup: 'Externer Datenschutzbeauftragter',
        intrestInTheClinic: ['Einhaltung der DSGVO', 'Einhaltung des Dienstvertrages'],
        risks: ['Nichtkonformität wird aufgrund mangelhafter Zusammenarbeit nicht festgestellt und behoben'],
        opportunities: ['Rechtssicherheit'],
        measures: ['Detaillierte Projektsteuerung durch Vertreter der obersten Leitung'],
    },
]






type PropsData = {
    handleDisableNotification?: () => void,
    handleCommentTextBoxToggle?: () => void,
}


export const HandbookDetail = (props: PropsData) => {

    const { handleDisableNotification, handleCommentTextBoxToggle } = props;

    const [AllowReading, setAllowReading] = useState<boolean>(false);
    const [showBookMark, setShowBookMark] = useState<boolean>(false);
    const [showRememberOption, setShowRememberOption] = useState<boolean>(false);
    const [showHomeScreen, setShowHomeScreen] = useState<boolean>(false);
    const [ArtikelModal, setArtikelModal] = useState(false);
    const [sendEmail, setSendEmail] = useState<boolean>(false);
    const [articleTranslation, setArticleTranslation] = useState<boolean>(false);

    const [sendReminder, setSendReminder] = useState<boolean>(false);
    const [printPdfSetting, setPrintPdfSetting] = useState<boolean>(false);
    const [deleteMessage, setDeleteMessage] = useState<boolean>(false);
    const [permission, setPermission] = useState<boolean>(false);
    const [notificationTextToggle, setNotificationTextToggle] = useState<boolean>(false);
    const [commentTextBoxToggle, setCommentTextBoxToggle] = useState<boolean>(false);
    const [addPoll, setAddPoll] = useState<boolean>(false);
    const [compareVersion, setCompareVersion] = useState<boolean>(false);
    const [allVersion, setAllVersion] = useState<boolean>(false);

    const [currentVersion, setCurrentVersion] = useState<boolean>(false);


    const handleCurrentVersion = () => {
        setCurrentVersion(!currentVersion);
    }

    const HandleAllowReding = () => {
        setAllowReading(!AllowReading);
    }

    const handleAllVersion = () => {
        setAllVersion(!allVersion);
    }

    return (
        <>
            <Card className='boxShadow'>
                <Card.Header >
                    <DisplayBetween>
                        <Box>
                            <BodyText fontSize='20px' fontWeight='700' textColor='#212b36'>OPTI CONCEPT Coaching</BodyText>
                            <DisplayStart>
                                <Box>
                                    <CustomTooltip
                                        ToolTipTrigger={<Link to="" className='text-decoration-none'>
                                            <span className='fw-400 w-176px text-truncate text-link-color f-12px'> Dr. Sven Markus Gallenbach</span>
                                        </Link>}
                                        toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                        toolTipPlacement='top'
                                        contentAlign='center'
                                    />
                                </Box>
                                <Box mr='5px' ml='5px'> , </Box>
                                <Box>
                                    <BodyText fontSize='12px' fontWeight='400' textColor='#666'> 15.06.2021 20:03 </BodyText>
                                </Box>
                            </DisplayStart>
                            <DisplayStart>
                                <Box>
                                    <BodyText fontSize='12px' fontWeight='400' textColor='#666'> Letzte Änderung: </BodyText>
                                </Box>
                                <Box ml='5px'>
                                    <CustomTooltip
                                        ToolTipTrigger={<Link to="" className='text-decoration-none'>
                                            <span className='fw-400 w-176px text-truncate text-link-color f-12px'> Dr. Sven Markus Gallenbach</span>
                                        </Link>}
                                        toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                        toolTipPlacement='top'
                                        contentAlign='center'
                                    />
                                </Box>
                                <Box mr='5px' ml='5px'> , </Box>
                                <Box>
                                    <BodyText fontSize='12px' fontWeight='400' textColor='#666'> 15.06.2021 20:03 (Version 3)  </BodyText>
                                </Box>
                            </DisplayStart>
                        </Box>
                        <Box>
                            <DisplayEnd>
                                <Box onClick={handleCurrentVersion}>
                                    <ButtonWidget
                                        varient='light'
                                        text={currentVersion ? 'Aktuelle Version' : 'Mit vorheriger Version vergleichen'}
                                        class='me-3'
                                    />
                                </Box>
                                <Box>
                                    <GroupActionButtonWidget
                                        bookMarkBtn={true}
                                        noticeBtn={true}
                                        isDropdownIcon={true}
                                        hideEditHandbook={() => setArtikelModal(true)}
                                        hideHomeScreen={() => setShowHomeScreen(true)}
                                        hidePrintPdf={() => setPrintPdfSetting(true)}
                                        hideStarModal={() => setShowBookMark(true)}
                                        hideNoticeModal={() => setShowRememberOption(true)}
                                        hideDeleteMessage={() => setDeleteMessage(true)}
                                        hidepermission={() => setPermission(true)}
                                        hideCompareVersion={() => setCompareVersion(true)}
                                        hideSendEmail={() => setSendEmail(true)}
                                        hideArticleTranslation={() => setArticleTranslation(true)}
                                        hideAddPoll={() => setAddPoll(true)}
                                        notificationTextToggle={notificationTextToggle}
                                        handleDisableNotification={() => setNotificationTextToggle(!notificationTextToggle)}
                                        commentTextBoxToggle={commentTextBoxToggle}
                                        handleCommentTextBoxToggle={() => setCommentTextBoxToggle(!commentTextBoxToggle)}
                                        pageName="handbookDetailPage" />
                                </Box>
                            </DisplayEnd>
                        </Box>
                    </DisplayBetween>
                </Card.Header>
                <Card.Body className='pb-2'>
                    <Row>
                        {currentVersion ?
                            <>
                                <Col md={12}>
                                    <Box className='light-yellow px-3 py-2'>
                                        <Row>
                                            <Col md={2} xs={12}>
                                                <Box>
                                                    <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                                                        Änderungen von
                                                    </BodyText>
                                                    <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                                                        im Vergleich zu
                                                    </BodyText>
                                                </Box>
                                            </Col>
                                            <Col md={2} xs={12}>
                                                <Box>
                                                    <BodyText fontSize='13px' fontWeight='600' textColor='#333'>
                                                        Version 3 (aktuell)
                                                    </BodyText>
                                                    <BodyText fontSize='13px' fontWeight='600' textColor='#333'>
                                                        Version 2
                                                    </BodyText>
                                                </Box>
                                            </Col>
                                            <Col md={5} xs={12}>
                                                <Box>
                                                    <DisplayStart>
                                                        <Box>
                                                            <CustomTooltip
                                                                ToolTipTrigger={<FaPencilAlt size={14} />}
                                                                toolTipContent='Erstellt von'
                                                                toolTipPlacement='top'
                                                                contentAlign='center'
                                                            />
                                                        </Box>
                                                        <Box ml='5px'>
                                                            <CustomTooltip
                                                                ToolTipTrigger={<Link to="" className='text-decoration-none'>
                                                                    <span className='fw-400 w-176px text-truncate text-link-color f-13px'> Dr. Sven Markus Gallenbach</span>
                                                                </Link>}
                                                                toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                                                toolTipPlacement='top'
                                                                contentAlign='center'
                                                            />
                                                        </Box>
                                                        <Box mr='5px' ml='5px'> , </Box>
                                                        <Box>
                                                            <BodyText fontSize='12px' fontWeight='400' textColor='#666'> 15.06.2021 20:03 (Version 3)  </BodyText>
                                                        </Box>
                                                    </DisplayStart>
                                                    <DisplayStart>
                                                        <Box>
                                                            <CustomTooltip
                                                                ToolTipTrigger={<FaPencilAlt size={14} />}
                                                                toolTipContent='Erstellt von'
                                                                toolTipPlacement='top'
                                                                contentAlign='center'
                                                            />
                                                        </Box>
                                                        <Box ml='5px'>
                                                            <CustomTooltip
                                                                ToolTipTrigger={<Link to="" className='text-decoration-none'>
                                                                    <span className='fw-400 w-176px text-truncate text-link-color f-13px'> Dr. Sven Markus Gallenbach</span>
                                                                </Link>}
                                                                toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                                                toolTipPlacement='top'
                                                                contentAlign='center'
                                                            />
                                                        </Box>
                                                        <Box mr='5px' ml='5px'> , </Box>
                                                        <Box>
                                                            <BodyText fontSize='12px' fontWeight='400' textColor='#666'> 15.06.2021 20:03 (Version 3)  </BodyText>
                                                        </Box>
                                                    </DisplayStart>
                                                </Box>
                                            </Col>
                                            <Col md={3} className='align-self-center'>
                                                <DisplayEnd>
                                                    <ButtonWidget
                                                        varient='light'
                                                        text={'Weitere Versionen vergleichen'}
                                                        class='f-12px'
                                                        handleClick={() => setCompareVersion(true)}
                                                    />
                                                </DisplayEnd>
                                            </Col>
                                        </Row>
                                    </Box>
                                </Col>
                            </>
                            :
                            null
                        }
                        <Col md={12} xs={12}>
                            <Box>
                                <BodyText fontSize='13px' fontWeight='400' textColor='#333' mb="8px">
                                    1. Der Patient ist die wichtigste Person in der Praxis.
                                </BodyText>
                                <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="8px" mb="8px">
                                    2. Der Patient verdient die höflichste und aufmerksamste Behandlung, die wir ihm bieten können.
                                </BodyText>
                                <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="8px" mb="8px">
                                    3. Der Patient unterbricht nicht unsere Arbeit, er ist der Zweck unseres Tuns.
                                </BodyText>
                                <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="8px" mb="8px">
                                    4. Der Patient ist ein Teil unserer Praxis und kein Aussenstehender.
                                </BodyText>
                                <BodyText fontSize='13px' fontWeight='400' textColor='#333' mt="8px" mb="8px">
                                    5. Der Patient ist keine Kartei, sondern ein Mensch aus Fleisch und Blut mit Gefühlen, genau wie wir.
                                </BodyText>
                            </Box>
                            <Box className='mt-3'>
                                <HandBoockOrgContext ContextOrgData={ContextOrgTableData} />
                            </Box>
                        </Col>
                        <Col md={12}>
                            <Box>
                                <BodyText fontSize='13px' fontWeight='700' textColor='#333' mt="8px">
                                    Stichwörter
                                </BodyText>
                            </Box>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className='bg-white'>
                    <Box>
                        <BodyText fontSize='12px' fontWeight='500' textColor='#333' mb="8px">
                            Gebot
                        </BodyText>
                    </Box>
                    {notificationTextToggle ?
                        <>
                            <Box onClick={handleDisableNotification}>
                                <Box className='p-2 bg-light' mt='10px' mb='10px'>
                                    <DisplayStart>
                                        <BodyText fontSize='14px' fontWeight='700' textColor='#666' pr='6px'>
                                            Benachrichtigungen deaktiviert
                                        </BodyText>
                                        <CustomTooltip
                                            ToolTipTrigger={<FaQuestionCircle size={16} />}
                                            toolTipContent='Diese Nutzer erhalten keine Benachrichti un en über diesen Eintra. Schreibe einen Kommentar mit Name um dies zu ändern.'
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </DisplayStart>
                                    <Divider mt='6px' mb='6px' />
                                    <BodyText fontSize='13px' fontWeight='400' textColor='#333'>
                                        Dr. Sven Markus Gallenbach
                                    </BodyText>
                                </Box>
                            </Box>
                        </>
                        :
                        null
                    }
                    <Box className='p-2 bg-light my-1'>
                        <DisplayCenter>
                            <BodyText fontWeight='700' textColor='#666' fontSize='15px'>Die Kommentare wurden geschlossen. </BodyText>
                            ( <CustomTooltip
                                ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px' pl='4px'>Dr. Sven Markus Gallenbach</BodyText>}
                                toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                toolTipPlacement='top'
                                contentAlign='center'
                            />
                            <span className='mx-1'> - </span>
                            <CustomTooltip
                                ToolTipTrigger={<BodyText fontWeight='400' textColor='#333' fontSize='14px' pr='4px'> vor 22 Minuten </BodyText>}
                                toolTipContent='21.06.2023 09:27'
                                toolTipPlacement='top'
                                contentAlign='center'
                            />
                            )
                        </DisplayCenter>
                    </Box>
                </Card.Footer>
            </Card>

            <AddEditArticle
                showArticle={ArtikelModal}
                hideArticle={() => setArtikelModal(false)}
            />

            <CommonModal
                showModal={showHomeScreen}
                hideModal={() => setShowHomeScreen(false)}
                modalTitle='Auf Startseite anzeigen'
                modalContent={<ShowHomeScreenModalContent />}
                modalSize='lg'
                footerClass={"footer-sticky"}
            />
            <CommonModal
                showModal={showBookMark}
                hideModal={() => setShowBookMark(false)}
                modalTitle='Lesezeichen-Optionen'
                modalContent={<BookMarkOptionModalContent />}
                modalSize='lg'
                modalFooterContent={<BookMarkFooterContent footerModalArea='bookMarkOption' hideModal={() => setShowBookMark(false)} />}
            />

            <CommonModal
                showModal={showRememberOption}
                hideModal={() => setShowRememberOption(false)}
                modalTitle='Merk-Optionen'
                modalContent={<RememberOptionModalContent />}
                modalSize='lg'
                modalFooterContent={<BookMarkFooterContent footerModalArea='rememberOption' hideModal={() => setShowRememberOption(false)} />}
            />

            <CommonModal
                showModal={printPdfSetting}
                hideModal={() => setPrintPdfSetting(false)}
                modalTitle='PDF-Einstellungen'
                modalContent={<PdfSettingsModalContent />}
                modalSize='md'
            />

            <CommonModal
                showModal={deleteMessage}
                hideModal={() => setDeleteMessage(false)}
                modalTitle='Lösche "Die 10 Gebote unserer Serviceorientierung"?'
                modalContent={<DeleteMessageDetailModalContent />}
                modalSize='md'
                centered={true}
            />
            <CommonModal
                showModal={permission}
                hideModal={() => setPermission(false)}
                modalTitle='Berechtigungen:'
                modalContent={<PermissionModalContent />}
                modalSize='md'
            />

            <CommonModal
                showModal={sendEmail}
                hideModal={() => setSendEmail(false)}
                modalTitle='Als E-Mail senden'
                modalContent={<SendEmailModalContent />}
                modalSize='lg'
            />

            <CommonModal
                showModal={articleTranslation}
                hideModal={() => setArticleTranslation(false)}
                modalTitle='Artikel Übersetzungen'
                modalContent={<ArticleTranslationModalContent />}
                modalSize='lg'
            />

            <CommonModal
                showModal={compareVersion}
                hideModal={() => setCompareVersion(false)}
                modalTitle='Artikel Versionen'
                modalContent={<CompareVersionModalContent />}
                modalSize='xl'
                modalFooterContent={
                    <>
                        <DisplayBetween className='w-100'>
                            <Box>
                                <ButtonWidget
                                    varient='light'
                                    text={'Delete selected version(s)'}
                                    class='f-12px'
                                />
                            </Box>
                            <Box>
                                <DisplayStart>
                                    <Box>
                                        <ButtonWidget
                                            varient='light'
                                            text={allVersion ? <span> Kleine Änderungen ausblenden </span> : <span> Zeige alle Versionen </span>}
                                            class='me-2 f-12px'
                                            handleClick={handleAllVersion}
                                        />
                                    </Box>
                                    <Box>
                                        <ButtonWidget
                                            varient='light'
                                            text={'Schließen'}
                                            class='f-12px'
                                        />
                                    </Box>
                                </DisplayStart>
                            </Box>
                        </DisplayBetween>
                    </>
                }
            />

            <CommonModal
                showModal={addPoll}
                hideModal={() => setAddPoll(false)}
                modalTitle='Umfrage hinzufügen'
                modalContent={<AddPollModalContent AllowReading={AllowReading} />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayBetween className='w-100'>
                            <Box>
                                <ButtonWidget
                                    varient='light'
                                    text={
                                        <>
                                            {AllowReading ?
                                                <span><FaMinusCircle className='default-btn-icon' /> Optionen ausblenden</span>
                                                :
                                                <span><FaPlusCircle className='default-btn-icon' /> Zeige weitere Optionen</span>
                                            }
                                        </>
                                    }
                                    handleClick={HandleAllowReding}
                                />
                            </Box>
                            <Box>
                                <ButtonWidget
                                    varient='light'
                                    text={'Abbrechen'}
                                    class='me-2'
                                />
                                <ButtonWidget
                                    varient='primary'
                                    text={'Erstellen'}
                                />
                            </Box>
                        </DisplayBetween>
                    </>
                }
            />

            <CommonModal
                showModal={sendReminder}
                hideModal={() => setSendReminder(false)}
                modalTitle='Erinnerung senden'
                modalContent={<SendReminderModalContent />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Schließen'}
                                class='me-2 ms-3'
                                handleClick={() => setSendReminder(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Ausgewählte erinnem'}
                                disabled={true}
                            />
                        </DisplayEnd>
                    </>
                }
                footerClass={"footer-sticky"}
            />
        </>
    )
}