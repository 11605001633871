import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import ForgetPasswordDto from "../../../Model/ViewModels/ForgetPassword/forgetPasswordDto";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import AuthService from "../../../APIService/authService";
import toast, { Toaster } from "react-hot-toast";
import { setShowLoader } from "../../../Redux/Slice/appSlice";

function Login() {
  const [cookies, setCookie, removeCookie] = useCookies(["authToken"]);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const authService = new AuthService();

  const formSchema = yup
    .object({
      email: yup
        .string()
        .required("Email is required")
        .email("Invalid Email Address"),
    })
    .required();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
    formState,
  } = useForm<ForgetPasswordDto>({
    defaultValues: new ForgetPasswordDto(),
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });

  const onSubmit: SubmitHandler<ForgetPasswordDto> = async (
    data: ForgetPasswordDto,
    event: any
  ) => {
    if (data) {
      dispatch(setShowLoader(true));
      authService
        .forgetPassword(data.email!)
        .then((result) => {
          console.log(result);
          if (result) {
            toast.success("Email Sent Successfully!");
            event.target.reset();
          }
          dispatch(setShowLoader(false));
        })
        .catch((error) => {
          dispatch(setShowLoader(false));
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.message);
          }
        });
    }
  };

  return (
    <>
      <Card className="shadow border-0">
        <Card.Body className="py-5">
          <h6 className="text-center m-0">
            medikit | Praxis Gallenbach & Zähne | Passwort vergessen
          </h6>
          <div className="w-75 mx-auto mt-4">
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  autoFocus
                  isInvalid={!!formState.errors.email}
                  {...register("email")}
                />
                {formState.errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <div className="d-grid gap-2">
                <Button
                  type="submit"
                  className="primary-bg"
                  disabled={!formState.isValid}
                >
                  Neues Passwort anfordern
                </Button>
              </div>
            </Form>
            <div className="mt-4">
              <p className="m-0 text-center">
                <a
                  onClick={() => navigate(-1)}
                  className="primary-color text-decoration-none fw-500 hover-underline"
                  style={{ cursor: "pointer" }}
                >
                  « Zurück zum Login
                </a>
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
export default Login;
