import React, { FC } from 'react';
import { Draggable } from "react-beautiful-dnd";


interface IProps {
    showActions: () => void;
    isRed: boolean;
    dragData: any;
    compIds: any
}

export const SidebarLeft: FC<IProps> = ({ showActions, isRed, dragData, compIds }) => {
    return (
        <>
            {dragData.columns["column-1"].dragableItems.map((dragableItem: any, index: number) => (
                <>
                    {dragData.tasks[dragableItem].isDragDisabled ? (
                        <Draggable key={dragableItem} draggableId={dragableItem} index={index} isDragDisabled={true}>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    className="left-sidebar-item"
                                >
                                    {compIds[dragData.tasks[dragableItem].content]}
                                </div>
                            )}
                        </Draggable>
                    )
                        :
                        (
                            <Draggable key={dragableItem} draggableId={dragableItem} index={index}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className="left-sidebar-item"
                                    >
                                        {compIds[dragData.tasks[dragableItem].content]({ ...provided.dragHandleProps })}
                                    </div>
                                )}
                            </Draggable>
                        )

                    }
                </>

            ))}
            {/* <div>
                <DashboardWidget showActions={showActions} isRed={isRed} />
            </div>
            <div className='mt-4'>
                <NotedWidget />
            </div> */}
        </>
    );
}
