import { FC, useState } from "react"
import { LatestNewsList } from "../ListOfEntries/LatestNewsList"
import { BodyText, DisplayBetween, DisplayStart } from "../StyledComponents"
import { Card, Dropdown, DropdownButton } from "react-bootstrap"
import { RiDragMove2Line } from "react-icons/ri"
import FormCheck from "../FormCheck"


type LatestNewsProps = {
    title: string,
    countBadge?: string,
    dateTime: string,
}
const LatestNews: LatestNewsProps[] = [
    {
        title: 'message template for quality testing',
        countBadge: '',
        dateTime: '8 days ago',
    },
    {
        title: 'Medikit as a news portal better than emails and print....',
        countBadge: '1',
        dateTime: '10.04.2022 19:14',
    },
    {
        title: 'Welcome to our team',
        countBadge: '2',
        dateTime: '09.04.2022 15:38',
    },
    {
        title: 'Tips for getting started',
        countBadge: '',
        dateTime: '29.06.2021 12:37',
    },
    {
        title: 'OPTI-QM-Flat: Neue Dokumente (07/2022)',
        countBadge: '',
        dateTime: '10.11.2022 14:45',
    },
]


interface IProps {
    isDragMode?: boolean,
    dragHandleProps?: any,
    addedInDashboard?: boolean,
}

export const LatestNewsModule: FC<IProps> = ({ isDragMode, dragHandleProps, addedInDashboard }) => {

    return (
        <>
            <Card className='border-0 rounded-0 b-shadow'>
                <Card.Header className='bg-white'>
                    <DisplayBetween>
                        <BodyText fontSize='18px' textColor='#666' fontWeight='600'> Aktuelle Termine </BodyText>
                        <DisplayStart>
                            {isDragMode ?
                                <div {...dragHandleProps}>
                                    <RiDragMove2Line size="18px" color="#666" />
                                </div>
                                :
                                null
                            }
                            <DropdownButton size="sm" className="template-withouticon f-12px ms-2" id="new-item" title={<></>}>
                                <Dropdown.Item>
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Bearbeiten </BodyText>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <FormCheck
                                        type='checkbox'
                                        name='phone'
                                        Id='phone'
                                        value='Automatische Höhe'
                                        label='Automatische Höhe'
                                        isFormGroup={false}
                                        isInline={false}
                                        disabled={false}
                                    />
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Löschen </BodyText>
                                </Dropdown.Item>
                            </DropdownButton>
                        </DisplayStart>
                    </DisplayBetween>
                </Card.Header>
                <Card.Body className='h-190px'>
                    <LatestNewsList Data={LatestNews} Name={"Neueste Nachrichten"} />
                </Card.Body>
            </Card>

        </>
    )
}