import * as React from 'react';
import { useState, useEffect } from 'react';
import FormControl from '../../../FormControl';
import FormCheck from '../../../FormCheck';
import { BoxTitle } from '../../../../Pages/mycalendar/left/SaveCurrentFilter/PraxisCheckboxList';
import { ChromePicker, ColorChangeHandler, ColorResult } from 'react-color';
import styled from 'styled-components';

const ColorTriggerWrapper = styled.div`
padding: 5px;
background: #fff;
border-radius: 1px;
box-shadow: 0 0 0 1px rgba(0,0,0,.1);
display: inline-block;
cursor: pointer;

& .displayColor{
    width: 36px;
    height: 14px;
    border-radius: 2px;
    background: ${(props: { color?: string }) => props.color}
}
`
const ColorPickerPopover = styled.div`
position: absolute;
z-index: 2;

`


interface Iprops {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}


const AddCalendarContent: React.FC<Iprops> = ({ closeModal }) => {
    const [calendarTitle, setCalendarTitle] = useState<string>("");
    const [color, setColor] = useState<string>("#666");
    const [showColorPicker, setShowColorPicker] = useState(false);
    const handleChangeColor = (color: ColorResult) => {

        setColor(color.hex)
    }

    return (
        <>
            <div className="row">
                <div className='col-md-6'>
                    <div className='d-flex align-items-center'>
                        <FormControl Value={calendarTitle} handleChange={(e) => setCalendarTitle(e.target.value)} Placeholder='Calendar Title' isFormGroup={false} Type='text' Size='sm' Name={'CalendarTitle-input'} Id={'CalendarTitle-input'} />
                        <div className='position-relative ms-3'>
                            <ColorTriggerWrapper color={color} onClick={() => setShowColorPicker(!showColorPicker)}>
                                <div className='displayColor'></div>
                            </ColorTriggerWrapper>
                            {showColorPicker && <ColorPickerPopover>
                                <ChromePicker
                                    color={color}
                                    onChange={handleChangeColor}
                                />
                            </ColorPickerPopover>}
                        </div>
                    </div>
                </div>
                <div className='col-md-6 align-self-center'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <label className="me-3">Kit:</label>
                        <div>
                            <FormCheck
                                type='radio'
                                name='Kit'
                                Id='Kit'
                                value='Kit'
                                isFormGroup={false}
                                isInline={true}
                                disabled={false}
                                checked={true}
                            />
                            <BoxTitle>Praxis Gallenbach & Zähne </BoxTitle>
                        </div>
                    </div>
                </div>
            </div>
            <AlreadDefinedAccessTable />
        </>
    );
}

export default AddCalendarContent;


const AlreadDefinedAccessTable = () => {

    return (
        <div className='mt-5'>
            <h5 className='mb-2 fw-bold'>Already Defined Access</h5>
            <table className='table table-bordered table-responsive'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th style={{ width: '400px' }}>Action</th>
                        <th style={{ width: '180px' }} className='text-center'>Allowed to unsubscribe</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Dr. Sven Markus Gallenbach
                        </td>
                        <td>
                            <AccessSelectControl />

                        </td>
                        <td className='text-center'>
                            <FormCheck
                                type='checkbox'
                                name='Kit'
                                Id='Kit'
                                value='Kit'
                                isFormGroup={false}
                                isInline={true}
                                disabled={false}
                                checked={true}
                            />
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td> All other users</td>
                        <td>
                            <AccessSelectControl />
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>

    )
}


const AccessSelectControl = () => {

    return (
        <select className='form-select'>
            <option>No access</option>
            <option>Read only</option>
            <option>Edit own appointments</option>
            <option>Edit all appointments</option>
            <option>Edit all appointments and calendar options</option>
        </select>
    )
}