import React, { useState } from 'react';
import { Maincontent } from './Maincontent';
import { Row, Col } from 'react-bootstrap';
import { Sidebar } from './Sidebar';

export const IndexUser = () => {
    return (
        <>
            <Row>
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <Maincontent />
                </Col>
            </Row>
        </>
    )
}