import { FC, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { CheckControl } from "../../../Components/Widgets/InputControls/CheckControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { BodyText, Box, DisplayEnd, DisplayStart } from "../../../Components/StyledComponents";
import { InputTextControl } from "../../../Components/Widgets/InputControls/InputTextControl";
import ButtonWidget from "../../../Components/ButtonWidget";
import { SelectControl } from "../../../Components/Widgets/InputControls/SelectControl";
import { MobilePinDropdown } from "../../../CommonJSON";
import { FaPlusCircle } from "react-icons/fa";
import Recipient from "../../../Components/Modals/Recipient";


interface IProps { }


const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
        lastName: yup
            .string()
            .typeError("Last name is required")
            .required("Last name is required"),
        position: yup
            .string()
            .typeError("Position is required")
            .required("Position is required"),
    })
    .required();


const MobilePIN: FC<IProps> = ({ }) => {

    const [statusChange, setStatusChange] = useState<boolean>(false);
    const [simpleStatus, setSimpleStatus] = useState<boolean>(false);
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);


    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });
    const fn = watch("firstName");
    const { gender } = watch();
    console.log(fn);

    const handleStatusChange = () => {
        setStatusChange(!statusChange);
    }

    const handleSimpleStatus = () => {
        setSimpleStatus(!simpleStatus);
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <h3> Mobile PIN Einstellungen </h3>
                    <BodyText fontSize='15px' fontWeight='400' textColor='#666' mt="10px" mb="15px"> Einstellen, ob Nutzer nach einer bestimmten Zeit Inaktivität ihr Mobilgerät per PIN entsperren müssen. </BodyText>
                    <Box>
                        <BodyText fontSize='15px' fontWeight='600' textColor='#666'> App auf Mobilgerät mit PIN sperren, nachdem sie neu gestartet ist oder inaktiv war für: </BodyText>
                    </Box>
                    <Box mt="8px" mb="8px" width="200px">
                        <SelectControl
                            fieldId={"salutationId"}
                            fieldName={"salutationId"}
                            control={control}
                            error={errors.salutationId}
                            selectOptions={MobilePinDropdown}
                        />
                    </Box>


                </Col>
                <Row>
                    <Col md={6} className="mt-1">
                        <Box>
                            <BodyText fontSize='15px' fontWeight='500' textColor='#333'> PIN-Sperre gilt für: </BodyText>
                        </Box>
                        <Box mb="10px">
                            <CheckControl
                                fieldType={"radio"}
                                control={control}
                                fieldName={"gender"}
                                error={errors.gender}
                                value={"alle"}
                                label={"alle"}
                                isInline={true}
                                disabled={false}
                                checked={undefined}
                                defaultChecked={false}
                            />
                            <CheckControl
                                fieldType={"radio"}
                                control={control}
                                fieldName={"gender"}
                                error={errors.gender}
                                value={"nur für folgende Gruppen"}
                                label={"nur für folgende Gruppen"}
                                isInline={true}
                                disabled={false}
                                checked={undefined}
                                defaultChecked={false}
                            />
                            <CheckControl
                                fieldType={"radio"}
                                control={control}
                                fieldName={"gender"}
                                error={errors.gender}
                                value={"nicht für folgende Gruppen"}
                                label={"nicht für folgende Gruppen"}
                                isInline={true}
                                disabled={false}
                                checked={undefined}
                                defaultChecked={false}
                            />
                        </Box>
                    </Col>
                    <Col md={6} className="mt-1">
                        <DisplayStart>
                            <Box mr="10px" width="320px">
                                <InputTextControl
                                    control={control}
                                    fieldName={"firstName"}
                                    error={errors.firstName}
                                    placeholder="Gruppen hinzufügen"
                                    fieldType="text"
                                    size={"md"}
                                />
                            </Box>
                            <Box>
                                <ButtonWidget
                                    varient="light"
                                    text={
                                        <>
                                            <DisplayEnd>
                                                <FaPlusCircle className="default-btn-icon mt-2" />
                                                <span className="ms-1 text-nowrap"> Alle anzejgen </span>
                                            </DisplayEnd>
                                        </>
                                    }
                                    handleClick={(event) => setRecipientModal(true)}
                                />
                            </Box>
                        </DisplayStart>
                    </Col>
                </Row>
                <Col md={12}>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
            </Row>

            <Recipient
                displayRecipientModal={RecipientModal}
                hideRecipientModal={() => setRecipientModal(false)}
            />

        </>
    );
}

export default MobilePIN;