import React, { useState, MouseEvent } from 'react';
import { Card, Dropdown, DropdownButton, Table } from "react-bootstrap";
import { BodyText, Box, DisplayStart } from '../../Components/StyledComponents';
import BadgeWidget from '../../Components/BadgeWidget';
import CustomTooltip from '../../Components/Tooltip';
import Form from "react-bootstrap/Form";
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const UserData = [
    {
        userImage: "/images/account.png",
        userName: "Thomas Planitzer & Team",
        nameBadgeText: "OPTI QM",
        nameBadgeColor: "#666",
        nameBadgeBG: "#666",
        roleInfo: "Team Lead Customer Success Management",
        email: true,
        emailInfo: "barbara.wasmer@hotelkit.net",
        phone: true,
        phoneInfo: "+43 (0) 662 / 23 80 80 17",
        chainBadgeText: "OPTI QMH Updates",
        chainBadgeColor: "#e4005f",
        chainBadgeBG: "#e4005f",
        fillBadgeText: "",
        fillBadgeColor: "",
        fillBadgeBG: "",
        datetimeTooltip: "24.07.2023 19:19",
        datetimeTooltipContent: "Last Login",
    },
    {
        userImage: "/images/account-b.png",
        userName: "Barbara Wasmer & Team",
        nameBadgeText: "OPTI QM",
        nameBadgeColor: "#666",
        nameBadgeBG: "#666",
        roleInfo: "Team Lead Customer Success Management",
        email: true,
        emailInfo: "barbara.wasmer@hotelkit.net",
        phone: true,
        phoneInfo: "+43 (0) 662 / 23 80 80 17",
        chainBadgeText: "OPTI QMH Updates",
        chainBadgeColor: "#e4005f",
        chainBadgeBG: "#e4005f",
        fillBadgeText: "Assistenz",
        fillBadgeColor: "#0d3f5f",
        fillBadgeBG: "#d2eaf9",
        datetimeTooltip: "19.07.2023 17:58",
        datetimeTooltipContent: "Last Login",
    },
    {
        userImage: "/images/assets/opti-image.jpg",
        userName: "APZ APZ",
        nameBadgeText: "Praxis Gallenbach & Zähne",
        nameBadgeColor: "#0d3f5f",
        nameBadgeBG: "#fff",
        roleInfo: "",
        email: false,
        emailInfo: "",
        phone: false,
        phoneInfo: "",
        chainBadgeText: "Praxis Gallenbach & Zähne",
        chainBadgeColor: "#0d3f5f",
        chainBadgeBG: "#fff",
        fillBadgeText: "Abrechnung",
        fillBadgeColor: "#0d3f5f",
        fillBadgeBG: "#d2eaf9",
        datetimeTooltip: "25.07.2023 11:28",
        datetimeTooltipContent: "Last Login",
    },
    {
        userImage: "/images/account-b.png",
        userName: "Barbara Wasmer & Team",
        nameBadgeText: "Praxis Gallenbach & Zähne",
        nameBadgeColor: "#0d3f5f",
        nameBadgeBG: "#fff",
        roleInfo: "Zahnarzt",
        email: true,
        emailInfo: "sven.gallenbach@gmxcom",
        phone: true,
        phoneInfo: "062711040",
        chainBadgeText: "OPTI QMH Updates",
        chainBadgeColor: "#e4005f",
        chainBadgeBG: "#e4005f",
        fillBadgeText: "Assistenz",
        fillBadgeColor: "#0d3f5f",
        fillBadgeBG: "#d2eaf9",
        datetimeTooltip: "25.07.2023 11:28",
        datetimeTooltipContent: "Last Login",
    },
    {
        userImage: "/images/account-b.png",
        userName: "Emine Mollaoglu",
        nameBadgeText: "OPT' HEALTH CONSULTING",
        nameBadgeColor: "#0d3f5f",
        nameBadgeBG: "#fff",
        roleInfo: "Consultant - Digitale Systeme",
        email: true,
        emailInfo: "sven.gallenbach@gmxcom",
        phone: false,
        phoneInfo: "",
        chainBadgeText: "OPTI QMH Updates",
        chainBadgeColor: "#e4005f",
        chainBadgeBG: "#e4005f",
        fillBadgeText: "Zahnärzte",
        fillBadgeColor: "#0d3f5f",
        fillBadgeBG: "#d2eaf9",
        datetimeTooltip: "25.07.2023 11:28",
        datetimeTooltipContent: "Last Login",
    },
]

const SortBy = [
    {
        title: "sort by prename",
    },
    {
        title: "Sort by surname",
    },
    {
        title: "Sort by last login",
    },
    {
        title: "sort by position",
    },
    {
        title: "sort by department",
    },
]


export const Maincontent = () => {

    const navigate = useNavigate();

    const handleRowClick = (event: MouseEvent<HTMLElement>) => {
        navigate('/user/user-detail');
    }

    return (
        <>
            <div className='users-list-sec'>
                <div className='user-detail mb-3'>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <Form className="d-flex">
                                <Form.Control
                                    type="search"
                                    placeholder="Search by name"
                                    className="me-0 rounded-end-0 py-0"
                                    aria-label="Search"
                                />
                                <Button className='default-btn f-14px fw-500 rounded-end rounded-start-0' >Search</Button>
                            </Form>
                        </div>
                        <div>
                            <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="Sort by surname">
                                {SortBy.map((item, index) => {
                                    return (
                                        <>
                                            <Dropdown.Item key={index}>
                                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                            </Dropdown.Item>
                                        </>
                                    )
                                })}
                            </DropdownButton>
                        </div>
                    </div>
                </div>
                <Card className='boxShadow'>
                    <Card.Body>
                        <Table hover className='mb-0'>
                            <tbody>
                                {UserData.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="cursor-pointer" key={index} onClick={(event: MouseEvent<HTMLElement>) => handleRowClick(event)}>
                                                <td className='width-8 text-center align-middle'>
                                                    <img src={item.userImage} height="45" width="45" alt="user-image" />
                                                </td>
                                                <td className='w-25'>
                                                    <Box>
                                                        <BodyText fontSize="1.1em" fontWeight="700" textColor="#000"> {item.userName} </BodyText>

                                                        <BadgeWidget outiline={true} title={item.nameBadgeText} color={item.nameBadgeColor} bgColor={item.nameBadgeBG} />
                                                        <BodyText fontSize="12px" fontWeight="600" textColor="#666" ml="4px" className='d-inline'> {item.roleInfo} </BodyText>

                                                        {item.email &&
                                                            <>
                                                                <BodyText fontSize="12px" fontWeight="400" textColor="#666"> Email: {item.emailInfo} </BodyText>
                                                            </>
                                                        }
                                                        {item.phone &&
                                                            <>

                                                                <BodyText fontSize="12px" fontWeight="400" textColor="#666"> Phone: {item.phoneInfo}</BodyText>
                                                            </>
                                                        }
                                                    </Box>
                                                </td>
                                                <td className='width-15 text-center align-middle'>
                                                    <BadgeWidget outiline={true} title={item.chainBadgeText} color={item.chainBadgeColor} bgColor={item.chainBadgeBG} />
                                                </td>
                                                <td className='w-75 align-middle'>
                                                    <BadgeWidget solid={true} title={item.fillBadgeText} color={item.fillBadgeColor} bgColor={item.fillBadgeBG} />
                                                </td>
                                                <td width='15' className='text-center align-middle'>
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText fontSize="12px" fontWeight="400" textColor="#666" className='text-end'> {item.datetimeTooltip} </BodyText>}
                                                        toolTipContent={item.datetimeTooltipContent}
                                                        toolTipPlacement='top'
                                                        contentAlign='center'
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>

                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}