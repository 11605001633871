import { FC } from 'react';
import BadgeWidget from '../../BadgeWidget';
import { Box, DisplayStart } from '../../StyledComponents';
import { FaArrowRight } from 'react-icons/fa';
import FormControl from '../../FormControl';
import { DDItemColorSelectType } from '../../../Pages/Idea/details/IdeaDetailIndex';

interface IProps {
    previusStatus: DDItemColorSelectType,
    currentStatus: DDItemColorSelectType
}

const ChangeIdeaStatusModalContent: FC<IProps> = ({ previusStatus, currentStatus }) => {
    return (
        <>
            <DisplayStart>
                <BadgeWidget solid={true} title={previusStatus.value} color='#fff' bgColor={previusStatus.color} />
                <FaArrowRight className='mx-2' />
                <BadgeWidget solid={true} title={currentStatus.value} color='#fff' bgColor={currentStatus.color} />
            </DisplayStart>
            <Box mt='15px'>
                <label>Reason or result</label>
                <FormControl
                    Name=''
                    Type="textarea"
                    rows={5}
                />
            </Box>
        </>
    )
};

export default ChangeIdeaStatusModalContent;
