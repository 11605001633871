import React, { useEffect, useState, useRef } from "react";
import HeaderRightTop from "./HeaderRightTop";
import { FaClock } from "react-icons/fa";
import { HiChatBubbleBottomCenterText } from "react-icons/hi2";
import { FaAddressBook } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaBookmark } from "react-icons/fa";
import { HiStar } from "react-icons/hi2";
import { FaUserTie, FaSearch } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaPlus } from "react-icons/fa";
import AddEditArticle from "../../Pages/Article/AddEditArticle";
import AddEditMessage from "../../Pages/Message/AddEditMessage";
import AddEditNews from "../../Pages/News/AddEditNews";
import AddEditIdea from "../../Pages/Idea/AddEditIdea";
import AddEditTask from "../../Pages/Task/AddEditTask";
import AddEditAppointment from "../../Pages/Appointment/AddEditAppointment";
import AddEditRepair from "../../Pages/Repair/AddEditRepair";
import { HiLightBulb } from "react-icons/hi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { AppLogout } from "../../Api";
import CustomDropdown from "../../Components/Dropdown";
import CustomPopover from "../../Components/Popover";
import AccountIcon from "./AccountIcon";
import AccountPopoverContent from "./AccountPopoverContent";
import CommonModal from "../../Components/Modals/CommonModal";
import AccountSettingsModalContent from "../../Components/Modals/ModelContents/AccountSettingsModalContent";
import { SecurityCenterModalContent } from "../../Components/Modals/ModelContents/SecurityCenterModalContent";
import { NotificationSettingsModalContent } from "../../Components/Modals/ModelContents/NotificationSettingsModalContent";
import { BlockedUserModalContent } from "../../Components/Modals/ModelContents/BlockedUserModalContent";
import CustomTooltip from "../../Components/Tooltip";
import { BookmarkPopoverContent } from "./BookmarkPopoverContent";
import { NotedPopoverContent } from "./NotedPopoverContent";
import { NotificationPopoverContent } from "./NotificationPopoverContent";

const Header = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "authToken",
    "userName",
  ]);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const popOverRef = useRef<any>(null);
  const formRef = useRef<any>(null);

  const [ArtikelModal, setArtikelModal] = useState<boolean>(false);
  const [MessageModal, setMessageModal] = useState<boolean>(false);
  const [NewsModal, setNewsModal] = useState<boolean>(false);
  const [IdeaModal, setIdeaModal] = useState<boolean>(false);
  const [TaskModal, setTaskModal] = useState<boolean>(false);
  const [AppointmentModal, setAppointmentModal] = useState<boolean>(false);
  const [RepairModal, setRepairModal] = useState<boolean>(false);
  const [showAccountModal, setShowAccountModal] = useState<boolean>(false);
  const [showSecurityModal, setShowSecurityModal] = useState<boolean>(false);
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
  const [blockUserModal, setBlockUserModal] = useState<boolean>(false);
  const [isShowDropdown, setisShowDropdown] = useState<boolean>(false);

  const appLogout = () => {
    AppLogout(removeCookie, navigate, setCookie);
  };
  const hideAccountModal = () => {
    setShowAccountModal(false);
  };
  const hideSecurityCenterModal = () => {
    setShowSecurityModal(false);
  };
  const hideNotificationModal = () => {
    setShowNotificationModal(false);
  };

  return (
    <>
      <div className="header-main w-100 shadow">
        <Navbar collapseOnSelect className="pt-0" expand="lg">
          <Container fluid>
            <Navbar.Brand>
              <NavLink to="/home">
                <img className="logo" src="/images/logo.png" />
              </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <div>
                <Nav className="me-2">
                  <NavLink to="/messages" className="nav-link">
                    <div className="icon-box">
                      <FaClock size="20px" />
                      <span className="icon-title">Nachrichten</span>
                    </div>
                  </NavLink>
                  <NavLink to="/news" className="nav-link">
                    <div className="icon-box">
                      <HiChatBubbleBottomCenterText size="20px" />
                      <span className="icon-title">News</span>
                    </div>
                  </NavLink>
                  <NavLink to="/ideas" className="nav-link">
                    <div className="icon-box">
                      <HiLightBulb size="20px" className="gray-color" />
                      <span className="icon-title">Ideen</span>
                    </div>
                  </NavLink>
                  <NavLink to="/handbooks" className="nav-link">
                    <div className="icon-box">
                      <img width="20" src="/images/diaryGray.png" />
                      <span className="icon-title">Handbuch</span>
                    </div>
                  </NavLink>
                  <NavLink to="/tasks" className="nav-link">
                    <div className="icon-box">
                      <img width="20" src="/images/shopping-bag.png" />
                      <span className="icon-title">Aufgaben</span>
                    </div>
                  </NavLink>
                  <NavLink to="/calendar" className="nav-link">
                    <div className="icon-box">
                      <FaRegCalendarAlt size="20px" />
                      <span className="icon-title">Kalender</span>
                    </div>
                  </NavLink>
                  <NavLink to="/repair" className="nav-link">
                    <div className="icon-box">
                      <img width="20" src="/images/working.png" />
                      <span className="icon-title">Reparaturen</span>
                    </div>
                  </NavLink>
                </Nav>

                <Form className="d-flex me-auto sf-header mt-1">
                  <div
                    className={`${isShowDropdown
                      ? "position-relative w-95 me-2"
                      : "position-relative w-55 me-2"
                      }`}
                  >
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2 Search"
                      aria-label="Search"
                      size="sm"
                      onFocus={() => setisShowDropdown(true)}
                      onBlur={() => setisShowDropdown(false)}
                    />
                    <FaSearch className="search-icon" />
                  </div>
                  {/* <Button variant="outline-primary"><FaPlus className='me-2' />Neuer Eintrag</Button> */}
                  {isShowDropdown ? null : (
                    <DropdownButton
                      id="new-item"
                      title="Neuer Eintrag"
                      size="sm"
                      className="btn-outline-primary header-dropdown"
                    >
                      <NavDropdown.Item
                        className="py-0 pe-none"
                        href="#action/3.4"
                      >
                        <h5 className="m-0 text-uppercase">Erstelle...</h5>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <Dropdown.Item
                        href="javascript:;"
                        onClick={() => {
                          setMessageModal(true);
                        }}
                      >
                        <FaClock className="gray-icon-color" size="20px" />
                        <span className=" ms-2">Nachricht</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="javascript:;"
                        onClick={() => setNewsModal(true)}
                      >
                        <HiChatBubbleBottomCenterText
                          className="gray-icon-color"
                          size="20px"
                        />
                        <span className="gray-color ms-2">News</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="javascript:;"
                        onClick={() => setIdeaModal(true)}
                      >
                        <HiLightBulb size="20px" className="gray-icon-color" />
                        <span className="gray-color ms-2">Idee</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="javascript:;"
                        onClick={() => setArtikelModal(true)}
                      >
                        <img width="20" src="/images/diaryGray.png" />
                        <span className="gray-color ms-2">Artikel</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="javascript:;"
                        onClick={() => setTaskModal(true)}
                      >
                        <img width="20" src="/images/shopping-bag.png" />
                        <span className="gray-color ms-2">Aufgabe</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="javascript:;"
                        onClick={() => setAppointmentModal(true)}
                      >
                        <FaRegCalendarAlt
                          className="gray-icon-color"
                          size="20px"
                        />
                        <span className="gray-color ms-2">Termin</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="javascript:;"
                        onClick={() => setRepairModal(true)}
                      >
                        <img width="20" src="/images/working.png" />
                        <span className="gray-color ms-2">Reparatur</span>
                      </Dropdown.Item>
                    </DropdownButton>
                  )}
                </Form>
              </div>
              <div className="header-right">
                <div className="tool-box-top">
                  <HeaderRightTop />
                </div>
                <div className="tool-box">
                  <div className="tool-box-inner">
                    <Nav.Link>
                      <CustomPopover
                        ref={popOverRef}
                        triggerEle={
                          <span className="icon-bg-rounded notification">
                            <FaBell size="16px" className="text-white" />
                            <span className="notifi-text">99+</span>
                          </span>
                        }
                        content={<NotificationPopoverContent closeRef={popOverRef} />}
                      />
                    </Nav.Link>
                    <Nav.Link>
                      <CustomPopover
                        ref={popOverRef}
                        triggerEle={
                          <CustomTooltip
                            ToolTipTrigger={
                              <>
                                <span className="icon-bg-rounded savedLater">
                                  <FaBookmark
                                    size="16px"
                                    className="text-white"
                                  />
                                  <span className="SL-text">6</span>
                                </span>
                              </>
                            }
                            toolTipContent={"Für später gemerkt(6)"}
                            toolTipPlacement="top"
                          />
                        }
                        content={<NotedPopoverContent closeRef={popOverRef} />}
                      />
                    </Nav.Link>
                    <Nav.Link>
                      <CustomPopover
                        ref={popOverRef}
                        triggerEle={
                          <CustomTooltip
                            ToolTipTrigger={
                              <>
                                <span className="icon-bg-rounded savedLater">
                                  <HiStar size="16px" className="text-white" />
                                  <span className="SL-text">7</span>
                                </span>
                              </>
                            }
                            toolTipContent={"Lesezeichen(0)"}
                            toolTipPlacement="top"
                          />
                        }
                        content={<BookmarkPopoverContent closeRef={popOverRef} />}
                      />
                    </Nav.Link>
                    <Nav.Link className="accountLink">
                      <CustomPopover
                        ref={popOverRef}
                        triggerEle={<AccountIcon userName="" />}
                        content={
                          <AccountPopoverContent
                            accountModalShow={setShowAccountModal}
                            showSecurityModal={setShowSecurityModal}
                            showNotificationModal={setShowNotificationModal}
                            blockUserModal={setBlockUserModal}
                            closeRef={popOverRef}
                          />
                        }
                      />
                    </Nav.Link>
                  </div>
                </div>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <AddEditArticle
        showArticle={ArtikelModal}
        hideArticle={() => setArtikelModal(false)}
      />
      <AddEditMessage
        showMessange={MessageModal}
        hideMessange={() => setMessageModal(false)}
      />
      <AddEditNews showNews={NewsModal} hideNews={() => setNewsModal(false)} />
      <AddEditIdea showIdea={IdeaModal} hideIdea={() => setIdeaModal(false)} />
      <AddEditTask showTask={TaskModal} hideTask={() => setTaskModal(false)} />
      <AddEditAppointment
        showAppointment={AppointmentModal}
        hideAppointment={() => setAppointmentModal(false)}
      />
      <AddEditRepair
        showRepair={RepairModal}
        hideRepair={() => setRepairModal(false)}
      />
      <CommonModal
        showModal={showAccountModal}
        hideModal={hideAccountModal}
        modalTitle={"Konto-Einstellungen: " + cookies.userName}
        modalContent={<AccountSettingsModalContent formRef={formRef} />}
        modalSize="xl"
        reference={formRef}
      />

      <CommonModal
        showModal={showSecurityModal}
        hideModal={hideSecurityCenterModal}
        modalTitle="Sicherheitscenter"
        modalContent={<SecurityCenterModalContent />}
        modalSize="lg"
      />
      <CommonModal
        showModal={showNotificationModal}
        hideModal={hideNotificationModal}
        modalTitle="Benachrichtigungseinstellungen"
        modalContent={<NotificationSettingsModalContent />}
        modalSize="lg"
      />
      <CommonModal
        showModal={blockUserModal}
        hideModal={() => setBlockUserModal(false)}
        modalTitle="Blockierte Nutzer"
        modalContent={<BlockedUserModalContent pageName="" />}
        modalSize="md"
      />
    </>
  );
};
export default Header;
