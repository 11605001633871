import React, { useState, useEffect } from 'react';
import { Row, Col, } from 'react-bootstrap';
import { BodyText, BorderBox, Box, CustomButton, DisplayBetween, DisplayStart, Divider } from '../../StyledComponents';
import FormControl from '../../FormControl';
import CustomTooltip from '../../Tooltip';
import { Link } from 'react-router-dom';


export const PleasesModalContent = () => {

    const [bothRead, setBothRead] = useState<boolean>(false);

    return (
        <>
            <Row>
                <Col md={12} xs={12}>
                    <Box>
                        <FormControl isFormGroup={true} Type='text' Size='sm' Placeholder='Suche nach ...' Name='searchFor' Id='searchFor' />
                    </Box>
                    <Box>
                        <BodyText fontSize='18px' fontWeight='700'> Gelesen von</BodyText>
                        <DisplayBetween>
                            <DisplayStart className='badge-box p-2'>
                                <Box>
                                    <img width='50' src='/images/account.png' />
                                </Box>
                                <Box ml="8px">
                                    <CustomTooltip
                                        ToolTipTrigger={<BodyText textColor='#bbb' fontSize='16' fontWeight='500'> Dr. Sven Markus Gallenbach </BodyText>}
                                        toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                        toolTipPlacement='top'
                                    />
                                    <BodyText textColor='#bbb' fontSize='14'> Read: 07/05/2023 09:28 </BodyText>
                                </Box>
                            </DisplayStart>
                        </DisplayBetween>
                    </Box>
                </Col>
                <Divider mt='10px' mb='8px' />
            </Row>
        </>
    );
}

