import React, { FC } from 'react';
import { Draggable } from "react-beautiful-dnd";


interface Iprops {
    dragData: any;
    compIds: any
}


const SidebarRight: FC<Iprops> = ({ dragData, compIds }) => {
    return (
        <>
            {dragData.columns["column-3"].dragableItems.map((dragableItem: any, index: number) => (
                <>
                    {dragData.tasks[dragableItem].isDragDisabled ? (
                        <Draggable key={dragableItem} draggableId={dragableItem} index={index}>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="left-sidebar-item"
                                >
                                    {compIds[dragData.tasks[dragableItem].content]}
                                </div>
                            )}
                        </Draggable>
                    ) : (
                        <Draggable key={dragableItem} draggableId={dragableItem} index={index}>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className="left-sidebar-item"
                                >
                                    {compIds[dragData.tasks[dragableItem].content]({ ...provided.dragHandleProps })}
                                </div>
                            )}
                        </Draggable>
                    )

                    }
                </>
            ))}
            {/* <div>
                <StatusRepairsWidget />
            </div>
            <div className='mt-4'>
                <EventsWidget />
            </div> */}
        </>
    );
}

export default SidebarRight;