import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonWidget from '../../Components/ButtonWidget';

function HomeBanner() {
    return (
        <>
            <Card className='border-0 rounded-0 b-shadow mt-4'>
                <Card.Header className='bg-white'>
                    <Card.Title>Wichtig</Card.Title>
                </Card.Header>
                <Card.Body className='p-0 position-relative text-white'>
                    <div className='p-5 pb-3 home-banner-bg'>
                        <h6 className='fs-16px fw-700'>29.06.2021 12:38</h6>
                        <h2 className='my-4 f-32px fw-700'>Jetzt alle medikit Tools entdecken!</h2>
                        <h6 className='mb-5 fs-16px fw-700'>Sie haben Fragen? Gerne stehe ich Ihnen zur Verfügung!</h6>
                        <div className='mt-5 d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img width='30' src="/images/account-b.png" />
                                <h6 className='ms-3 mb-0 fs-16px fw-700'>Erstellt von:<em> Entfernter Nutzer</em></h6>
                            </div>
                            <div className='d-flex align-items-center'>
                                <h6 className='mb-0 me-5 fs-16px fw-700'>0 Kommentare </h6>
                                <ButtonWidget
                                    varient='primary'
                                    text={'Öffnen'}
                                    class='fs-16px fw-700'
                                />
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default HomeBanner;