import React, { useEffect, useState, MouseEvent } from 'react';
import { TopbarSelectFilterDropdown } from '../../../Components/TopbarSelectFilterDropdown';
import { Card, Table } from 'react-bootstrap';
import FormCheck from '../../../Components/FormCheck';
import { FaBookmark, FaPencilAlt, FaRegFileAlt, FaRegFolder } from 'react-icons/fa';
import { BodyText, Box, DisplayStart } from '../../../Components/StyledComponents';
import CustomTooltip from '../../../Components/Tooltip';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';

export interface HandbookDetailType {
    folderIcon: boolean,
    title: string,
    userName: string,
    userNameTooltip: string,
    updateIcon: boolean,
    updateIconTooltip: string,
    dateTime: string,
    noticeIcon: boolean
    noticeIconTooltip: string,
    versionText: string,
    isChecked: boolean,
}

const HandbookDetailData: HandbookDetailType[] = [
    {
        folderIcon: true,
        title: "Die 10 Gebote unserer Serviceorientierung",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        updateIcon: true,
        updateIconTooltip: "Letzte Änderung",
        dateTime: "08.12.2022 11:15",
        noticeIcon: true,
        noticeIconTooltip: "gemerkt",
        versionText: "Version 3",
        isChecked: false,
    },
    {
        folderIcon: true,
        title: "OPTI-QM-Flat: Neue Dokumente",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        updateIcon: true,
        updateIconTooltip: "Letzte Änderung",
        dateTime: "",
        noticeIcon: true,
        noticeIconTooltip: "gemerkt",
        versionText: "Version 8",
        isChecked: false,
    },
    {
        folderIcon: true,
        title: "Die 10 Gebote unserer Serviceorientierung",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        updateIcon: false,
        updateIconTooltip: "",
        dateTime: "08.12.2022 11:15",
        noticeIcon: true,
        noticeIconTooltip: "gemerkt",
        versionText: "Version 9",
        isChecked: false,
    },
    {
        folderIcon: true,
        title: "OPTI-QM-Flat: Neue Dokumente",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        updateIcon: true,
        updateIconTooltip: "Letzte Änderung",
        dateTime: "08.12.2022 11:15",
        noticeIcon: true,
        noticeIconTooltip: "gemerkt",
        versionText: "Version 12",
        isChecked: false,
    },
]


export const HandbookDetailIndex: React.FC = () => {

    const [handbookDetailData, setHandbookDetailData, displayMode, isShowChecksHandBookDetail, setTotalAccountHandBookDetail]: any = useOutletContext();

    function truncate(source: string, size: number) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }

    const [selectEntry, setSelectEntry] = useState<boolean>(false);
    // const [totalAccount, setTotalAccount] = useState<number>(0);
    // const [handbookDetailData, setHandbookDetailData] = useState<HandbookDetailType[]>([]);

    useEffect(() => {
        setHandbookDetailData(HandbookDetailData)
    }, []);

    const HandleCheckbox = (index: number, event: MouseEvent<HTMLInputElement>) => {
        debugger
        let temp = [...handbookDetailData];
        var count: number = 0;
        temp[index].isChecked = !temp[index].isChecked;

        for (var i = 0; i < handbookDetailData.length; i++) {
            if (handbookDetailData[i].isChecked === true) {
                count++;
            }
        }
        setTotalAccountHandBookDetail(count);
        setHandbookDetailData(temp);
    }

    // const HandleSelectAll = () => {
    //     const tempArray = [...handbookDetailData];
    //     for (var i = 0; i < tempArray.length; i++) {
    //         tempArray[i].isChecked = true;
    //     }
    //     setHandbookDetailData(tempArray);
    //     setTotalAccount(tempArray.length);
    // }

    // const HandleSelectClose = () => {
    //     setSelectEntry(!selectEntry);
    //     const tempArray = [...handbookDetailData];
    //     for (var i = 0; i < tempArray.length; i++) {
    //         tempArray[i].isChecked = false;
    //     }
    //     setHandbookDetailData(tempArray);
    //     setTotalAccount(0);
    // }

    const navigate = useNavigate();

    // const handleRowClick = (event: MouseEvent<HTMLElement>) => {
    //     navigate('/handbooks/handbooks-detial-id');
    // }


    return (
        <>
            <div className='handbook-section'>
                <Card className='boxShadow'>
                    <Card.Body>
                        <Table responsive hover className='mb-0'>
                            <tbody>
                                {handbookDetailData.map((item: HandbookDetailType, index: number) => {
                                    return (
                                        <>
                                            <tr key={index} onClick={() => { navigate('/handbooks/handbooks-detial-id') }} className='cursor-pointer'>
                                                {isShowChecksHandBookDetail ?
                                                    <td width='30'>
                                                        <FormCheck
                                                            type='checkbox'
                                                            name='phone'
                                                            Id='phone'
                                                            value={item.title}
                                                            label=''
                                                            isFormGroup={false}
                                                            isInline={true}
                                                            checked={item.isChecked}
                                                            handleClick={(event: MouseEvent<HTMLInputElement>) => {
                                                                HandleCheckbox(index, event);
                                                                event.stopPropagation();
                                                            }}
                                                        />
                                                    </td>
                                                    : null}
                                                <td width="30">
                                                    {item.folderIcon ?
                                                        <span><FaRegFileAlt size={18} /></span>
                                                        : null}
                                                </td>
                                                <td>
                                                    <div className='msg-title'>
                                                        <BodyText mb='0px' fontSize='1.1em' fontWeight='700' textColor='#000' pl='7px'>{item.title}</BodyText>
                                                    </div>
                                                </td>
                                                <td>
                                                    <DisplayStart>
                                                        <Box mr="10px">
                                                            {item.updateIcon ?
                                                                <CustomTooltip
                                                                    ToolTipTrigger={<FaPencilAlt size={14} className='me-2' />}
                                                                    toolTipContent={item.updateIconTooltip}
                                                                    toolTipPlacement='top'
                                                                />
                                                                : null}
                                                        </Box>
                                                        <Box>
                                                            <CustomTooltip
                                                                ToolTipTrigger={
                                                                    <>
                                                                        <Link to="" className='text-decoration-none'>
                                                                            <span className='fw-500 w-176px text-truncate text-link-color f-12px'> {item.userName} </span>
                                                                        </Link>
                                                                    </>
                                                                }
                                                                toolTipContent={item.userNameTooltip}
                                                                toolTipPlacement='top'
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <BodyText mb='0px' fontSize='12px' fontWeight='500' pl='5px'>{truncate(item.dateTime, 10)}</BodyText>
                                                        </Box>
                                                    </DisplayStart>
                                                </td>
                                                <td width={50}>
                                                    {item.noticeIcon ?
                                                        <CustomTooltip
                                                            ToolTipTrigger={<FaBookmark size={16} />}
                                                            toolTipContent={item.noticeIconTooltip}
                                                            toolTipPlacement='top'
                                                        />
                                                        : null}
                                                </td>
                                                <td>
                                                    <Box>
                                                        <BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'>{item.versionText}</BodyText>
                                                    </Box>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}