import { configureStore } from '@reduxjs/toolkit';
import userReducer from './Slice/userSlice';
import calendarReducer from "./Slice/calendarSlice";
import appReducer from './Slice/appSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    calendar: calendarReducer,
    app: appReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch