import React, { useState, useEffect, FC, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { BodyText, BorderBox, Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider } from '../../../Components/StyledComponents';
import FormCheck from '../../../Components/FormCheck';
import FormSelect from '../../../Components/FormSelect';
import { FaChevronDown, FaChevronRight, FaChevronUp, FaCrop, FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import ButtonWidget from '../../../Components/ButtonWidget';
import BadgeWidget from '../../../Components/BadgeWidget';
import CustomTooltip from '../../../Components/Tooltip';
import Recipient from '../../../Components/Modals/Recipient';
import { Link } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UserProfileDto } from '../../../Model/ViewModels/User/UserProfileDto';
import { useForm } from 'react-hook-form';
import { InputTextControl } from '../../../Components/Widgets/InputControls/InputTextControl';
import { SelectControl } from '../../../Components/Widgets/InputControls/SelectControl';
import { CheckControl } from '../../../Components/Widgets/InputControls/CheckControl';
import { groupJsonData, homeOptions } from '../../../CommonJSON';

const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
    })
    .required();

export const AddGroupAdminModal = () => {

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });

    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    const [arrowAngle, setArrowAngle] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<typeof groupJsonData>([]);

    const handleArrowAngle = () => {
        let temp = [...selectAll];
        var count = 0;
        for (var x = 0; x < temp.length; x++) {
            // if (x == true) {
            count++;
            setArrowAngle(!arrowAngle);
            // }
        }
        // setArrowAngle(!arrowAngle);
    }

    useEffect(() => {
        setSelectAll(groupJsonData);
    }, []);

    const handleSelectAll = (index: number) => {
        let temp = [...selectAll];
        temp[index].permission.ischeck = !temp[index].permission.ischeck;
        for (var i = 0; i < temp[index].permission.checklist.length; i++) {
            if (temp[index].permission.ischeck === true) {
                temp[index].permission.checklist[i].ischeck = true;
            } else {
                temp[index].permission.checklist[i].ischeck = false;
            }
        }
        setSelectAll(temp);
    }

    const singleCheckboxSet = (index: number, i: number) => {
        debugger
        let temp = [...selectAll];
        var count = 0;
        if (temp[index].permission.ischeck === false) {
            temp[index].permission.checklist[i].ischeck = !temp[index].permission.checklist[i].ischeck;
        } else {
            temp[index].permission.ischeck = false;
            temp[index].permission.checklist[i].ischeck = !temp[index].permission.checklist[i].ischeck;
        }
        for (var x = 0; x < temp[index].permission.checklist.length; x++) {
            if (temp[index].permission.checklist[x].ischeck === true) {
                count++;
            }
        }
        if (temp[index].permission.checklist.length === count) {
            temp[index].permission.ischeck = true;
        }
        setSelectAll(temp);
    }

    return (
        <>
            <Form>
                <Row>
                    <Col md={6}>
                        <Box mb="10px">
                            <InputTextControl
                                control={control}
                                fieldName={"firstName"}
                                error={errors.firstName}
                                placeholder="Gruppenname"
                                fieldType="text"
                                size={"sm"}
                            />
                        </Box>
                    </Col>
                    <Col md={6}>
                        <DisplayBetween>
                            <BodyText fontSize='14px' fontWeight='500' textColor='#666' className='me-2 text-nowrap'>
                                Startseite:
                            </BodyText>
                            <Box>
                                <div className='homePage-select-mb'>
                                    <SelectControl
                                        fieldId={"homePageId"}
                                        fieldName={"homePageId"}
                                        control={control}
                                        error={errors.salutationId}
                                        selectOptions={homeOptions}
                                    />
                                </div>
                            </Box>
                            <Box ml="8px">
                                <InputTextControl
                                    control={control}
                                    fieldName={"colorPicker"}
                                    error={errors.firstName}
                                    placeholder=""
                                    fieldType="color"
                                    size={"sm"}
                                />
                            </Box>
                        </DisplayBetween>
                    </Col>
                </Row>
                <Divider mt="6px" mb="10px" />
                <Row>
                    <Col md={2}>
                        <Box mt="5px">
                            <BodyText fontSize='14px' fontWeight='500' textColor='#666'>
                                Mitglieder:
                            </BodyText>
                        </Box>
                    </Col>
                    <Col md={10}>
                        <DisplayBetween>
                            <Box mb="10px" className='w-100' mr="8px">
                                <InputTextControl
                                    control={control}
                                    fieldName={"firstName"}
                                    error={errors.firstName}
                                    placeholder="Auswählen..."
                                    fieldType="text"
                                    size={"sm"}
                                />
                            </Box>
                            <Box mb="4px">
                                <ButtonWidget
                                    varient="light"
                                    text={
                                        <>
                                            <DisplayEnd>
                                                <FaPlusCircle className="default-btn-icon mt-2" />
                                                <span className="ms-1 text-nowrap"> Bearbeiten </span>
                                            </DisplayEnd>
                                        </>
                                    }
                                    handleClick={(event) => setRecipientModal(true)}
                                />
                            </Box>
                        </DisplayBetween>
                    </Col>
                </Row>
                <Divider mt="6px" mb="14px" />
                <Box>
                    <DisplayBetween>
                        <BodyText fontSize='14px' fontWeight='600' textColor='#666'>
                            Berechtigungen:
                        </BodyText>
                        <ButtonWidget
                            varient="light"
                            text={'Alle offnen'}
                            handleClick={handleArrowAngle}
                        />
                    </DisplayBetween>
                </Box>
                <Divider mt="6px" mb="14px" />
                {selectAll.map((item, index) => {
                    return (
                        <>
                            <Box key={index}>
                                <DisplayBetween onClick={() => handleArrowAngle()} className='cursor-pointer'>
                                    <Box>
                                        <Link to="" className='text-decoration-none'>
                                            <span className='fw-600 w-176px text-truncate text-link-color f-14px'> {item.permission.title} </span>
                                        </Link>
                                    </Box>
                                    <Box>
                                        <span className='cursor-pointer'>
                                            {arrowAngle ?
                                                <FaChevronUp size={14} />
                                                :
                                                <FaChevronRight size={14} />
                                            }
                                        </span>
                                    </Box>
                                </DisplayBetween>
                                <Box className={`toggle-text-group ${arrowAngle ? 'show-text-group' : 'hide-text-group'}`}>
                                    {arrowAngle ?
                                        <>
                                            <Row className='mt-3'>
                                                <Col md={10}>
                                                    {item.permission.checklist.map((x, i) => {
                                                        return (
                                                            <>
                                                                <Box mt="5px" mb="5px" key={index}>
                                                                    {/* <CheckControl
                                                                        fieldType={"checkbox"}
                                                                        control={control}
                                                                        fieldName={"gender"}
                                                                        error={errors.gender}
                                                                        value={x.label}
                                                                        label={x.label}
                                                                        isInline={true}
                                                                        disabled={false}
                                                                        checked={x.ischeck}
                                                                        defaultChecked={undefined}
                                                                        onChange={() => singleCheckboxSet(index, i)}
                                                                    /> */}
                                                                    <FormCheck
                                                                        type='checkbox'
                                                                        name='phone'
                                                                        Id='phone'
                                                                        value={x.label}
                                                                        label={x.label}
                                                                        isFormGroup={false}
                                                                        isInline={true}
                                                                        handleChange={() => singleCheckboxSet(index, i)}
                                                                        checked={x.ischeck}
                                                                    />
                                                                </Box>
                                                            </>
                                                        )
                                                    })}
                                                </Col>
                                                <Col md={2}>
                                                    <DisplayEnd>
                                                        <Box>
                                                            <ButtonWidget
                                                                varient="light"
                                                                text={'setzen'}
                                                                handleClick={() => handleSelectAll(index)}
                                                            />
                                                        </Box>
                                                    </DisplayEnd>
                                                </Col>
                                            </Row>
                                        </>
                                        : null}
                                </Box>
                                <Divider mt="8px" mb="8px" />
                            </Box>
                        </>
                    )
                })}

            </Form>

            <Recipient
                displayRecipientModal={RecipientModal}
                hideRecipientModal={() => setRecipientModal(false)}
            />

        </>
    )
}
