import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FaBook, FaCalendar, FaCalendarCheck, FaChevronLeft, FaChevronRight, FaPlus, FaQuestion, FaRegFolderOpen, FaSortAlphaDown, FaSortNumericDownAlt, FaStop, FaThLarge, FaUser } from 'react-icons/fa';

const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,

  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


function ToolBar(props) {
  let navigate = useNavigate()
  const [view, setview] = useState("day");
  const [aufteilung_open, setAufteilung_open] = useState(false);
  const [aufteilung, setaufteilung] = useState(0);
  const [sortierung, setsortierung] = useState(0);
  const [klemmbrett, setklemmbrett] = useState(0);
  const [klemmbrettCount, setKlemmbrettCount] = useState(0);


  const setView = (view) => {
    console.log("setView: " + view);

    setview(view)
    props.methods.setView(view);
  }

  const setDate = (val) => {
    console.log("setDate");

    props.methods.setDate(val);
  }


  const setOpen = (val) => {
    console.log("setOpen: ");

    setAufteilung_open(val)
  }


  const setAufteilung = (val) => {
    props.filter(val);
    setaufteilung(val);
    setOpen(false);
  }

  const setSortierung = (val) => {
    props.sort(val);
    setsortierung(val);
    setOpen(false);
  }

  const setKlemmbrett = () => {
    console.log("setKlemmbrett: ");

    setklemmbrett(!klemmbrett)
    props.methods.setKlemmbrett(!props.klemmbrett)
  }

  const moveToForm = () => {
    props.setShow(true)
  }

  const add = () => {
    return (
      <>
        <div className="btn-group pull-left" data-ds-rt="buttonGroup" data-ds-rid="buttonGroup1" id="wsK6hx1cSKyUwzNW7i3EHQ">
          <button onClick={moveToForm} className="btn-default btn" data-ds-rt="button" data-ds-rid="addTerminBt" id="wSXA-yhyQw65Cb1cMQvI_Q">
            <span className="ds-b-cm ds-b-c">
              <span data-ds-rid="icon1" id="wZ_JYTdET5WTsF5NwUoJyw" data-ds-rt="icon" className="ds-i">
                <FaPlus style={{ color: "hsl(0, 0%, var(--hsL0))" }} className="fa-fw  fal fa-plus" />
              </span>
              <span className="ds-hidden-tiny ds-hidden-xs ds-hidden-sm ds-hidden-md ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wHhNzN5vS-Suieg7DuPspA">Hinzufügen</span>
            </span>
          </button>
        </div>
      </>
    )
  }

  const calendar = () => {
    return <div className="btn-group pull-left" data-ds-rt="buttonGroup" data-ds-rid="buttonGroup3" id="wkjY83j2T-GK5bsuTEM44w">
      <button className="btn-default btn" onClick={() => setDate("today")} data-ds-rt="button" data-ds-rid="todayBt" id="wRAJWUvpSWebSUOnNpfxmA">
        <span className="ds-b-cm ds-b-c">
          <span data-ds-rid="ic1" id="w4bNakuvToWw-9SF9QV78Q" data-ds-rt="icon" className="ds-i">
            <FaCalendarCheck className="fa-fw  fal fa-calendar-check" />
          </span>
          <span className="ds-hidden-tiny ds-hidden-xs ds-hidden-sm ds-hidden-md ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="weYjBQCLRDWutJscKHnlng">Heute</span>
        </span>
      </button>
      <button className="btn-default btn" onClick={() => setDate("prev")} data-ds-rt="button" data-ds-rid="prevBt" id="wIXReZowQuqYhOtg9soQDw">
        <span className="ds-b-c">
          <span data-ds-rid="icon5" id="wgVJpoSrQD6_9KedDSZcxg" data-ds-rt="icon" className="ds-i">
            <FaChevronLeft style={{ color: "hsl(0, 0%, var(--hsL0))" }} className="fa-fw  fal fa-chevron-left" />
          </span>
          <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wvc3n4BERV6baoPYEB8TRA"></span>
        </span>
      </button>
      <button className="btn-default btn" onClick={() => setDate("next")} data-ds-rt="button" data-ds-rid="nextBt" id="wCLvVCz3TMOBQZtBCjvbWQ">
        <span className="ds-b-c">
          <span data-ds-rid="icon6" id="wACqY6J-Q-CVlsO5HjhbgA" data-ds-rt="icon" className="ds-i">
            <FaChevronRight style={{ color: "hsl(0, 0%, var(--hsL0))" }} className="fa-fw  fal fa-chevron-right" />
          </span>
          <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wah7KFvTTIiHllS5LthQ3A"></span>
        </span>
      </button>
    </div>

  }

  const Aufteilung = () => {
    return <>
      {view !== "list" ?
        <div className="btn-group pull-right" data-ds-rt="buttonGroup" data-ds-rid="buttonGroup4" id="wqRZsUTDQIuDEDzGT-jq8w">
          <div
            data-ds-rid="spaltenAufteilungDd"
            id="wQN_NmOnQrOEF3IfzEeOVw"
            //style="position:relative;" 
            style={{ position: "relative" }}
            data-ds-rt="dropdownmenu"
            //className="dropdown"
            className={(aufteilung_open == true) ? "dropdown open" : "dropdown"}>
            <button data-toggle="dropdown" className="btn-default btn " onClick={() => setOpen(true)} data-ds-rt="button" data-ds-rid="spaltenaufteilungBt" id="wFos7mMYT1mzx8B8BsKdBQ">
              <span className="ds-b-c">
                <span data-ds-rid="user-light" id="wXJdzVaFTBmYd7u5AhcZNA" data-ds-rt="icon" className="ds-i">
                  <FaUser className="fa-fw  fal fa-user" />
                </span>
                <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wg84ha8PRB2nwev6nuQ01A"></span>
                <b className="caret"></b>
              </span>
            </button>


            <ul aria-labelledby="dropdownMenu" role="menu"  className="dropdown-menu">
              <li>
                <button className={(aufteilung == 0) ? "btn-default btn active" : "btn-default btn"} data-ds-rt="button" onClick={() => setAufteilung(0)} data-ds-rid="ohne AufteilungBt" id="wRVIt5svRaWQz4mrEg9JHw">
                  <span className="ds-b-cm ds-b-c">
                    <span data-ds-rid="ohne AufteilungIcon" id="wMwYSgRNQgGHeP882bazkA" data-ds-rt="icon" className="ds-i">
                      <FaCalendar className="fa-fw  fal fa-calendar" />
                    </span>
                    <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wGDC6Pp1RbWLQLli0tHA2g">ohne Aufteilung</span>
                  </span>
                </button>
              </li>

              <li>
                <button data-toggled="true" className={(aufteilung == 1) ? "btn-default btn active" : "btn-default btn"} onClick={() => setAufteilung(1)} data-ds-rt="button" data-ds-rid="nach MitarbeiterBt" id="wQL_E9ZDQv2PYHwHc7aaQA">
                  <span className="ds-b-cm ds-b-c">
                    <span data-ds-rid="nach MitarbeiterIcon" id="wwdOzhe7RL-kJWXzXFGUeg" data-ds-rt="icon" className="ds-i">
                    <FaUser className="fa-fw  fal fa-user" />
                    </span>
                    <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="woExSeucSu-jt-HX9ZHDKw">nach Mitarbeiter</span>
                  </span>
                </button>
              </li>

              <li>
                <button data-ds-rt="button" className={(aufteilung == 2) ? "btn-default btn active" : "btn-default btn"} onClick={() => setAufteilung(2)} data-ds-rid="nach ZimmerBt" id="wPIAvtNwTn6RPsRumZaLLQ">
                  <span className="ds-b-cm ds-b-c">
                    <span data-ds-rid="nach ZimmerIcon" id="wEkZKv7vSAuSqf9G7bWM4Q" data-ds-rt="icon" className="ds-i">
                      <FaStop className="fa-fw  fal fa-stop" />
                    </span>
                    <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wc1Mu6OjTkWUfZ_gsi3Vpw">nach Zimmer</span>
                  </span>
                </button>
              </li>

              <li>
                <button data-ds-rt="button" className={(aufteilung == 3) ? "btn-default btn active" : "btn-default btn"} onClick={() => setAufteilung(3)} data-ds-rid="nach KategorieBt" id="wY6CsTftTCqdz1fWXGGSTA">
                  <span className="ds-b-cm ds-b-c">
                    <span data-ds-rid="nach KategorieIcon" id="w3l_VRmZRyelUoutxSltHw" data-ds-rt="icon" className="ds-i">
                      <FaThLarge className="fa-fw  fal fa-th-large" />
                    </span>
                    <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wFSwh3KETwK2Lg58nHR7Fg">nach Kategorie</span>
                  </span>
                </button>
              </li>

              <li>
                <button data-ds-rt="button" className={(aufteilung == 4) ? "btn-default btn active" : "btn-default btn"} onClick={() => setAufteilung(4)} data-ds-rid="nach TerminbuchBt" id="w-mA0_lcQgusRjHci-lFIA">
                  <span className="ds-b-cm ds-b-c">
                    <span data-ds-rid="nach TerminbuchIcon" id="wRTBNADxRViG7EdOzQD_uw" data-ds-rt="icon" className="ds-i">
                      <FaBook className="fa-fw  fal fa-book" />
                    </span>
                    <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wHJFCXtKR4GvTdwQU67muw">nach Terminbuch</span>
                  </span>
                </button>
              </li>

              <li>
                <hr className="" data-ds-rt="HorizontalSeparator" data-ds-rid="c643d829-18dc-42fc-9ac8-cd04b872a76a" id="wemBmPZ7S8qoP5sb1HIn-w"></hr>
              </li>

              <li>
                <button data-toggled="true" className={(sortierung == 0) ? "btn-default btn active" : "btn-default btn"} onClick={() => setSortierung(0)} data-ds-rt="button" data-ds-rid="TerminbuchBt" id="wcrGh_2bQu6kAJqpsUhB-A">
                  <span className="ds-b-cm ds-b-c">
                    <span data-ds-rid="TerminbuchIcon" id="w6om7Rv1QOOev8aQ0SshTg" data-ds-rt="icon" className="ds-i">
                    <FaSortAlphaDown className="fa-fw  fal fa-sort-alpha-down" />
                    </span>
                    <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="w7KuW5o_Q7CRBTDu-xMMBw">Terminbuch</span>
                  </span>
                </button>
              </li>

              <li>
                <button data-ds-rt="button" className={(sortierung == 1) ? "btn-default btn active" : "btn-default btn"} onClick={() => setSortierung(1)} data-ds-rid="NameBt" id="wAbG8ZwyTu2DPKrgQ0Fgnw">
                  <span className="ds-b-cm ds-b-c">
                    <span data-ds-rid="NameIcon" id="w80z_ngqTqqYhQgX3El91w" data-ds-rt="icon" className="ds-i">
                      <FaSortAlphaDown className="fa-fw  fal fa-sort-alpha-down" />
                    </span>
                    <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wKTDifsQSUuWCnBd2xseSQ">Name</span>
                  </span>
                </button>
              </li>

              <li>
                <button data-ds-rt="button" className={(sortierung == 2) ? "btn-default btn active" : "btn-default btn"} onClick={() => setSortierung(2)} data-ds-rid="MitarbeiternummerBt" id="wNPI-CF0S-qME0ZRTO8Hew">
                  <span className="ds-b-cm ds-b-c">
                    <span data-ds-rid="MitarbeiternummerIcon" id="wB1KiGa_R32-APOfrqLGgg" data-ds-rt="icon" className="ds-i">
                      <FaSortNumericDownAlt className="fa-fw  fal fa-sort-numeric-down" />
                    </span>
                    <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wALZQVe4QyuQLUC5Ce2N4g">Mitarbeiternummer</span>
                  </span>
                </button>
              </li>

            </ul>

          </div>
        </div>
        : null}
    </>

  }

  const View = () => {
    return <div className="btn-group pull-right" data-ds-rt="buttonGroup" data-ds-rid="buttonGroup5" id="w_vUixdqSXWa2R-E0DPMbQ">
      <a target="_self" data-toggled="false" data-can-toggle="true" className={(view == "day") ? "ds-hidden-tiny ds-hidden-xs btn btn-default active" : "ds-hidden-tiny ds-hidden-xs btn btn-default"} data-ds-rt="viewLink" data-ds-rid="dayBt" onClick={() => setView('day')} id="w1kPXCxEQR6sXOiEi5kQzw">
        <span className="ds-b-c">
          <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wh_AimGAR1WNCaatelVKrQ">Tag</span>
        </span>
      </a>
      <a target="_self" data-toggled="true" data-can-toggle="true" className={(view == "week") ? "ds-hidden-tiny ds-hidden-xs btn btn-default active" : "ds-hidden-tiny ds-hidden-xs btn btn-default"} data-ds-rt="viewLink" data-ds-rid="weekBt" onClick={() => setView('week')} id="wXWNtOnLQNeHG4R2n9q4uw">
        <span className="ds-b-c">
          <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wov2apHVT7uTKgEiA2Z-xA">Woche</span>
        </span>
      </a>
      <a target="_self" data-toggled="false" data-can-toggle="true" className={(view == "month") ? "ds-hidden-tiny ds-hidden-xs btn btn-default active" : "ds-hidden-tiny ds-hidden-xs btn btn-default"} data-ds-rt="viewLink" data-ds-rid="monthBt" onClick={() => setView('month')} id="wYkIMHVHT5SX2DeO9wy8iw">
        <span className="ds-b-c">
          <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wE1aB0cqS2y4niSWplxRzA">Monat</span>
        </span>
      </a>
      <a target="_self" data-toggled="false" data-can-toggle="true" className={(view == "overview") ? "ds-hidden-tiny ds-hidden-xs btn btn-default active" : "ds-hidden-tiny ds-hidden-xs btn btn-default"} data-ds-rt="viewLink" data-ds-rid="uebersichtBt" onClick={() => setView('overview')} id="wxhPFaETR9SDg3RjHpZwYw">
        <span className="ds-b-c">
          <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="w5c5e0u_Q5Kir7nO41drUA">Übersicht</span>
        </span>
      </a>
      <a target="_self" data-toggled="false" data-can-toggle="true" className={(view == "list") ? "ds-hidden-tiny ds-hidden-xs btn btn-default active" : "ds-hidden-tiny ds-hidden-xs btn btn-default"} data-ds-rt="viewLink" data-ds-rid="listBt" onClick={() => setView('list')} id="wdAIzn15TXiw2ko2QzRrgQ">
        <span className="ds-b-c">
          <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wSG1UVSVTU6DKmd4LEEqeg">Liste</span>
        </span>
      </a>
    </div>
  }

  const zoom = () => {
    return <div className="btn-group pull-right" data-ds-rt="buttonGroup" data-ds-rid="buttonGroup2" id="whTxoBEeTCuxV8PD_xx4Ng">
      <div
        data-ds-rid="zoomDd"
        id="wbLhcIygRc-0DsJ1jtPSbQ"
        //style="position:relative;" 
        style={{ position: "relative" }}
        data-ds-rt="dropdownmenu"
        className="dropdown ds-right-aligned">

        {/* <button data-toggle="dropdown" className="btn-default btn dropdown-toggle" data-ds-rt="button" data-ds-rid="zoomBt" id="w2J6dI5ATPKTMza8lxfHbg">
          <span className="ds-b-c">
            <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wEjD5HLiRg6OePAFLctPpQ">100%</span>
            <b className="caret"></b>
          </span>
        </button> */}
        <ZoomMenu setZoom={props.methods.setZoom} view={view} />
        {/* <ul aria-labelledby="dropdownMenu" role="menu" className="dropdown-menu">

            <li>
              <button className="btn-default btn" data-ds-rt="button" data-ds-rid="1Bt" id="wmsn3PiNQ7unV9NF_O3WOg">
                <span className="ds-b-c">
                  <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wLoSOH4rT8mPCegRxWI-7Q">50%</span>
                </span>
              </button>
            </li>

            <li>
              <button data-toggled="true" className="btn-default btn active" data-ds-rt="button" data-ds-rid="2Bt" id="wI-lxxAYSmSb5n65xSGxiQ">
                <span className="ds-b-c">
                  <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="w6YOT8IZRGe6PvjFWIaxvg">100%</span>
                </span>
              </button>
            </li>

            <li>
              <button className="btn-default btn" data-ds-rt="button" data-ds-rid="3Bt" id="wBTNttw3RAOZlcDI8oQNJg">
                <span className="ds-b-c">
                  <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="w2GjOQ7eQdanDXlJK1QFCg">150%</span>
                </span>
              </button>
            </li>

            <li>
              <button className="btn-default btn" data-ds-rt="button" data-ds-rid="4Bt" id="wv4pQCzdTvKNnRgUxCmnAw">
                <span className="ds-b-c">
                  <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="w8U1wUG6SKSjNd9rgT_Whg">200%</span>
                </span>
              </button>
            </li>

            <li>
              <button className="btn-default btn" data-ds-rt="button" data-ds-rid="6Bt" id="w48_cSzmQlu0dBAe9n_ABA">
                <span className="ds-b-c">
                  <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wTyeJTocQAWDcflNf_KtGg">300%</span>
                </span>
              </button>
            </li>

        </ul> */}

      </div>
    </div>
  }

  const Klemmbrett = () => {
    return <div className="btn-group pull-right" data-ds-rt="buttonGroup" data-ds-rid="buttonGroup6" id="wg8j0_GxSS-M5WIflmg3kQ">
      <button data-toggled="false" data-can-toggle="true" className="btn-default btn ds-has-overlay" onClick={() => setKlemmbrett()} data-ds-rt="button" data-ds-rid="klemmbrettBt" id="wjSK_3GkRImxiUxYejAcHA">

        <span className="ds-b-c">
          <span data-ds-rid="icon8" id="w1M1fv0CQimgnuM3546NHA" data-ds-rt="icon" className="ds-i">
            <FaRegFolderOpen style={{ color: "hsl(0, 0%, var(--hsL0))" }} className="fa-fw  fal fa-folder-open" />
          </span>
          <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wCa8GzsuSka0uDi1BR8yGg"></span>
        </span>

        <div className="ds-overlay-right ds-overlay-top ds-overlay-label">
          <span className="label label-info ds-tag-b" data-ds-rt="tag" data-ds-rid="klemmbrettOverlayTag" id="whigL1QWTRSyIfhuYWiDCw">{klemmbrettCount > 0 ? klemmbrettCount : ""}</span>
        </div>

      </button>
    </div>
  }

  const help = () => {
    return <div className="btn-group pull-right" data-ds-rt="buttonGroup" data-ds-rid="buttonGroup7" id="wN6wDMFoRKyviAeXAZwTww">
      <a target="_blank" className="ds-hidden-tiny ds-hidden-xs btn btn-default" data-ds-rt="viewLink" data-ds-rid="helpBt" href="https://test.termin.dampsoft.net/online-terminbuch/hilfe#appName=app%2Donline%2Dterminbuch&amp;hilfeFile=termin%2Fwoche%2Ehtml" id="wI7ePyXwRymo7H_0VxtVAw">
        <span className="ds-b-c">
          <span data-ds-rid="helpBtIcon" id="wc7dKW0ORCmz-SALX4SOpg" data-ds-rt="icon" className="ds-i">
            <FaQuestion className="fa-fw  fal fa-question" />
          </span>
          <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wagF8sOVQXqZH-ymXJd-WQ"></span>
        </span>
      </a>
    </div>
  }


  return (
    <>
      {/* <AppBar/> */}
      <div className="btn-toolbar ds-animation mt-4" data-ds-rt="toolbar" data-ds-rid="toolbar1" id="w1kz6fYIQf-nMTcAJRyOtQ">


        {add()}

        {calendar()}



        {help()}

        {Klemmbrett()}

        {zoom()}

        {View()}

        {Aufteilung()}

      </div>
    </>

  )
}

export default ToolBar;



export function ZoomMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = useState("100%");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setValue(e.target.innerText);
    props.setZoom(e.target.getAttribute("name"));
    setAnchorEl(null);
  };
  useEffect(() => {
    if (props.view === "overview") {
      setValue("50%");
      props.setZoom(0.5);
    }
    else {
      setValue("100%");
      props.setZoom(1);
    }
  }, [props.view]);

  return (
    <>
      <Button
        id="basic-button"
        variant='outlined'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="btn-default btn"
        style={{ display: "inline-flex" }}
        disabled={props.view === "overview" || props.view === "list" ? true : false}
      >
        {value} <ArrowDropDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose} name="0.5">50%</MenuItem>
        <MenuItem onClick={handleClose} name="1">100%</MenuItem>
        <MenuItem onClick={handleClose} name="1.5">150%</MenuItem>
        <MenuItem onClick={handleClose} name="2">200%</MenuItem>
        <MenuItem onClick={handleClose} name="3">300%</MenuItem>
      </Menu>
    </>
  );
}

