import { ChangeEvent, FC, useState } from "react";
import { Button, Card, Dropdown, DropdownButton, Form, Nav, Table } from "react-bootstrap";
import { BodyText, Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider, HighlightBox } from "../../../Components/StyledComponents";
import { Col, Row } from 'react-bootstrap';
import ButtonWidget from "../../../Components/ButtonWidget";
import { InputTextControl } from "../../../Components/Widgets/InputControls/InputTextControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { setThemeColor } from "../../../Redux/Slice/appSlice";
import { useDispatch } from "react-redux";
import { FaBullseye, FaCogs, FaPlusCircle, FaRegCalendarAlt } from "react-icons/fa";
import Recipient from "../../../Components/Modals/Recipient";
import Swal from 'sweetalert2';
import { FaClock } from "react-icons/fa";
import { HiChatBubbleBottomCenterText } from "react-icons/hi2";
import { FaAddressBook } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaBookmark } from "react-icons/fa";
import { HiStar } from "react-icons/hi2";
import { FaUserTie, FaSearch } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { HiLightBulb } from "react-icons/hi";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ArticleVersionComment, BackupResponsibleDropdown, IpBlockingDropdown, PastBackupsData } from "../../../CommonJSON";
import { SelectControl } from "../../../Components/Widgets/InputControls/SelectControl";
import { CheckControl } from "../../../Components/Widgets/InputControls/CheckControl";
import { TextAreaControl } from "../../../Components/Widgets/InputControls/TextareaControl";


interface IProps { }

const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
        lastName: yup
            .string()
            .typeError("Last name is required")
            .required("Last name is required"),
        position: yup
            .string()
            .typeError("Position is required")
            .required("Position is required"),
    })
    .required();

const DataProtection: FC<IProps> = ({ }) => {

    let dispatch = useDispatch();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });
    const fn = watch("firstName");
    const { gender } = watch();
    console.log(fn);


    const [addIpAddress, setAddIpAddress] = useState<boolean>(false);
    const [requestNew, setRequestNew] = useState<boolean>(false);

    const handleIpAddress = () => {
        setAddIpAddress(!addIpAddress);
    }

    const handleRequestNew = () => {
        setRequestNew(!requestNew);
    }

    return (
        <>
            <div className="backup-wrapper">
                <Card className='boxShadow'>
                    <Card.Body>
                        <Row>
                            <Col md={12} xs={12}>
                                <Box className="mb-4 pb-3 px-3 border-bottom" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                                    <BodyText fontSize='1.5em' fontWeight='600' textColor='#333' className="text-uppercase"> Datenschutz / DSGVO </BodyText>
                                </Box>
                                <Box mb="15px" mt="15px">
                                    <BodyText fontSize='15px' fontWeight='400' textColor='#666' mt="12px" mb="15px"> Im Rahmen der Nutzung von hotelkit in deinem Unternehmen werden personenbezogene Daten durch die hotelkit GmbH verarbeitet, welche als Auftragsverarbeiter im Sinne der DSGVO handelt. Um die DSGVO-Compliance herzustellen ist es daher notwendig, dass dein Unternehmen mit der hotelkit GmbH eine Vereinbarung zur Auftragsverarbeitung gemäß Artikel 28 DSGVO abschließt. Die DSGVO tritt mit Ende der Übergangszeit zum 25.05.2018 in Kraft; der Abschluss der Vereinbarung muss bis dahin erfolgt sein. </BodyText>
                                </Box>
                                <Box mt="20px">
                                    <BodyText fontSize='18px' fontWeight='600' textColor='#333'> Vereinbarung zur Auftragsverarbeitung vorbereiten </BodyText>
                                </Box>
                                <Box mt="10px">
                                    <BodyText fontSize='15px' fontWeight='400' textColor='#666'> Um den Vertrag abzuschließen, benötigen wir noch einige Daten zu deinem Unternehmen. </BodyText>
                                </Box>
                                <Box>
                                    <BodyText fontSize='15px' fontWeight='600' textColor='#333' mt="10px" mb="10px"> Vertragspartner </BodyText>
                                    <BodyText fontSize='15px' fontWeight='400' textColor='#666'> Bitte trage nachfolgend den korrekten und aktuellen Namen sowie die Anschrift des Unternehmens ein, auf dessen Namen die Vereinbarung abgeschlossen werden soll: </BodyText>
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Box mb="15px" mt="15px">
                                    <Form.Label>Backup-Verantwortlicher</Form.Label>
                                    <InputTextControl
                                        control={control}
                                        fieldName={"firstName"}
                                        error={errors.firstName}
                                        fieldType="text"
                                        size={"sm"}
                                    />
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Box mb="15px">
                                    <Form.Label>Adresse des Unternehmens</Form.Label>
                                    <TextAreaControl
                                        control={control}
                                        fieldName={"firstName"}
                                        error={errors.firstName}
                                        fieldType="textarea"
                                        size={"sm"}
                                        rows={4}
                                    />
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Box mb="15px">
                                    <Form.Label>Sprache des Vereinbarungstextes:</Form.Label>
                                    <CheckControl
                                        fieldType={"radio"}
                                        control={control}
                                        fieldName={"language"}
                                        value="deutsch"
                                        label="Deutsch"
                                        isInline={false}
                                        disabled={false}
                                        defaultChecked={false}
                                    />
                                    <CheckControl
                                        fieldType={"radio"}
                                        control={control}
                                        fieldName={"language"}
                                        value="english"
                                        label="Englisch"
                                        isInline={false}
                                        disabled={false}
                                        defaultChecked={false}
                                    />
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Box>
                                    <BodyText fontSize='15px' fontWeight='600' textColor='#333' mb="10px"> Weisungsberechtigte Person </BodyText>
                                    <BodyText fontSize='15px' fontWeight='400' textColor='#666'> hotelkit verarbeitet personenbezogene Daten im Auftrag und zu einem von deinem Unternehmen bestimmten Zweck. Die Verarbeitung der Daten erfolgt dabei auf Weisung deines Unternehmens. Bitte nenne uns eine Person in deinem Unternehmen, welche gegenüber hotelkit als weisungsberechtigt gilt: </BodyText>
                                </Box>
                            </Col>
                            <Col md={6}>
                                <Box mb="15px" mt="15px">
                                    <Form.Label>Name</Form.Label>
                                    <InputTextControl
                                        control={control}
                                        fieldName={"authorizedName"}
                                        error={errors.firstName}
                                        fieldType="text"
                                        size={"sm"}
                                    />
                                </Box>
                            </Col>
                            <Col md={6}>
                                <Box mb="15px" mt="15px">
                                    <Form.Label>E-Mail-Adresse</Form.Label>
                                    <InputTextControl
                                        control={control}
                                        fieldName={"authorizedEmail"}
                                        error={errors.firstName}
                                        fieldType="text"
                                        size={"sm"}
                                    />
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Box mt='10px'>
                                    <BodyText fontSize='15px' fontWeight='600' textColor='#333' mb="10px"> Ansprechpartner für Fragen rund um Datenschutz </BodyText>
                                    <BodyText fontSize='15px' fontWeight='400' textColor='#666'> Bitte nenne uns außerdem eine Person in deinem Unternehmen, die für Datenschutzfragen zuständig ist - beispielsweise den Datenschutzbeauftragten oder eine andere Person, die sich mit dem Thema Datenschutz in deinem Unternehmen befasst: </BodyText>
                                </Box>
                            </Col>
                            <Col md={6}>
                                <Box mb="15px" mt="15px">
                                    <Form.Label>Name</Form.Label>
                                    <InputTextControl
                                        control={control}
                                        fieldName={"contactName"}
                                        error={errors.firstName}
                                        fieldType="text"
                                        size={"sm"}
                                    />
                                </Box>
                            </Col>
                            <Col md={6}>
                                <Box mb="15px" mt="15px">
                                    <Form.Label>E-Mail-Adresse</Form.Label>
                                    <InputTextControl
                                        control={control}
                                        fieldName={"contactEmail"}
                                        error={errors.firstName}
                                        fieldType="text"
                                        size={"sm"}
                                    />
                                </Box>
                            </Col>
                        </Row>
                        <Box className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                            <DisplayStart className="px-3">
                                <ButtonWidget
                                    text="Store information about the agreement"
                                    varient="primary"
                                    class="py-2 px-3"
                                />
                            </DisplayStart>
                        </Box>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default DataProtection;