import * as React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { BrowserRouter } from "react-router-dom";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import App from "./App";
import { Toaster } from "react-hot-toast";

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "black",
              color: "white",
            },
          },
          error: {
            style: {
              background: "black",
              color: "white",
            },
          },
        }}
        position="top-right"
        reverseOrder={false}
      />
      <App />
    </BrowserRouter>
  </Provider>
);
