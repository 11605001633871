import { FC, Fragment, forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Control, Controller, FieldError } from "react-hook-form";
import { IReactDatePickerProps } from "../Interface/IReactDatePickerProps";
import { Form } from "react-bootstrap";

export interface IProps extends IReactDatePickerProps {
  //add custom own extended props here
  error?: FieldError;
  errorMsg?: string;
  control: Control<any, any>;
  handleChange?: (e: any, value?: any) => void;
}

export const DateTimeControl: FC<IProps> = ({
  id,
  name,
  required,
  startDate,
  endDate,
  timeCaption,
  showTimeInput,
  showTimeSelect,
  shouldCloseOnSelect = true,
  closeOnScroll,
  isClearable,
  handleChange,
  error,
  control,
  disabled,
  errorMsg,
  ...rest
}) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [val, setVal] = useState<any>(startDate);
  function handleKeyUp() {
    setIsDirty(true);
  }

  useEffect(() => {
    setVal(startDate);
  }, [startDate]);

  function handleDateSelect(
    date: Date,
    e: React.SyntheticEvent<any> | undefined
  ) {
    // let { name, type, checked } = e.target;
    // let inputValue = e.target.value;
    // onChange && onChange(date, e);
  }

  if (isClearable == null || isClearable == undefined) {
    isClearable = true;
  }
//@ts-ignore
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
     <Form.Control
    name={name}
    value={value}
    //@ts-ignore
    ref={ref}
    isInvalid={!!error}
    onClick={onClick}
  />
     {error && (
        <Form.Control.Feedback type="invalid">
        {error.message}
      </Form.Control.Feedback>
      )}
    </>
   
 
  ));







  return (
    <Fragment>
       {control && (
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        {...rest}
        render={({
          field: { onChange: innerOnChange, onBlur, value, ref },
        }) => {
          let dt = isValidDate(value);
          if (!dt && value) {
            try {
              value = new Date(value);
            } catch (error) {
              value = null;
            }
          }
          return (
            <>
              <DatePicker
                className="form-control"
                disabled={disabled}
                timeFormat={"hh:mm aa"} //"hh:mm aa"
                dateFormat={
                  showTimeInput ? "dd/MM/yyyy hh:mm aa" : "dd/MM/yyyy"
                }
                timeCaption={timeCaption || "Time"}
                showTimeInput={showTimeInput}
                showTimeSelect={showTimeSelect}
                shouldCloseOnSelect={shouldCloseOnSelect}
                closeOnScroll={closeOnScroll}
                isClearable={isClearable}
                selected={isValidDate(value) ? value : null}
                onSelect={handleDateSelect}
                customInput={<ExampleCustomInput />}
                onChange={(date: Date, e: any) => {
                  if (!e || !e.target) {
                    e = {
                      target: {
                        name: "",
                        value: "",
                      },
                    };
                  }

                  if (date) {
                    date = new Date(
                      date.getTime() - date.getTimezoneOffset() * 60000
                    );
                  }

                  if (e && e.target) {
                    e.target.name = id ?? name;
                    e.target.value = date;
                  }

                  innerOnChange(date);
                  handleChange && handleChange(date, e);
                }}
                onBlur={onBlur}
                showIcon
              />
            </>
          );
        }}
      />
      )}

     
    </Fragment>
  );
};

export const isValidDate = (d: any): boolean => {
  return d instanceof Date && !isNaN(d as any);
};
