import React, { useEffect, useState } from 'react';
import { BodyText, BorderBox, Box, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider, InlineBox } from '../../../Components/StyledComponents';
import { Button, Card, Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import ButtonWidget from '../../../Components/ButtonWidget';
import { HiCog } from 'react-icons/hi';
import CustomTooltip from '../../../Components/Tooltip';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import CommonModal from '../../../Components/Modals/CommonModal';
import Swal from 'sweetalert2';


type deviceProps = {
    title: string,
    lastAccess: string,
    lastAccessTooltip: boolean,
    accessDateTime: string,
    image: string,
    imageTooltipContent: string,
    userProfileImage: string,
    ip: string,
    browser: string,
    userAgent: string,
    sessionStatus: boolean,
    detailBtn: boolean,
}

const deviceData: deviceProps[] = [
    {
        title: "unknown-F4CDEED1-9263-4AB6-A84B-2E32E64EFB1A",
        lastAccess: "vor 37 Stunden",
        lastAccessTooltip: true,
        accessDateTime: "24.05.2023 19:42",
        image: "/images/assets/header/unknownDevice.png",
        imageTooltipContent: "Unbekannter Gerätetyp",
        userProfileImage: "/images/assets/female.png",
        ip: "2a02:8109:3140:29ee:8072:b2a1:6322:a220",
        browser: "UKNOWN 0",
        userAgent: "medikit/444 CFNetwork/1408.0.4 Darwin/22.5.0",
        sessionStatus: false,
        detailBtn: false,
    },
    {
        title: "unknown-F4CDEED1-9263-4AB6-A84B-2E32E64EFB1A",
        lastAccess: "vor 31 Stunden",
        lastAccessTooltip: true,
        accessDateTime: "24.05.2023 19:42",
        image: "/images/assets/header/unknownDevice.png",
        imageTooltipContent: "Unbekannter Gerätetyp",
        userProfileImage: "/images/assets/female.png",
        ip: "2a02:8109:3140:29ee:8072:b2a1:6322:a220",
        browser: "UKNOWN 0",
        userAgent: "medikit/444 CFNetwork/1408.0.4 Darwin/22.5.0",
        sessionStatus: false,
        detailBtn: false,
    },
    {
        title: "unknown-F4CDEED1-9263-4AB6-A84B",
        lastAccess: "vor 44 Stunden",
        lastAccessTooltip: true,
        accessDateTime: "24.05.2023 19:42",
        image: "/images/assets/header/unknownDevice.png",
        imageTooltipContent: "Unbekannter Gerätetyp",
        userProfileImage: "/images/assets/female.png",
        ip: "2a02:8109:3140:29ee:8072:b2a1:6322:a220",
        browser: "UKNOWN 10",
        userAgent: "medikit/444 CFNetwork/1408.0.4 Darwin/22.5.0",
        sessionStatus: false,
        detailBtn: false,
    },
    {
        title: "unknown-F4CDEED1-9263-4AB6-A84B",
        lastAccess: "vor 44 Stunden",
        lastAccessTooltip: true,
        accessDateTime: "24.05.2023 19:42",
        image: "/images/assets/header/unknownDevice.png",
        imageTooltipContent: "Unbekannter Gerätetyp",
        userProfileImage: "/images/assets/female.png",
        ip: "2a02:8109:3140:29ee:8072:b2a1:6322:a220",
        browser: "UKNOWN 10",
        userAgent: "medikit/444 CFNetwork/1408.0.4 Darwin/22.5.0",
        sessionStatus: false,
        detailBtn: false,
    },
]

export const DeviceManagerList = () => {

    const [deviceApproval, setDeviceApproval] = useState<boolean>(false);
    const [showDetail, setShowDetail] = useState<typeof deviceData>([]);

    useEffect(() => {
        setShowDetail(deviceData);
    }, []);

    const HandleShowInfo = (index: number) => {
        console.log(...showDetail);
        const temp = [...showDetail];
        temp[index].detailBtn = !temp[index].detailBtn;
        setShowDetail(temp)
    }

    const handleDeleteSession = () => {
        Swal.fire({
            title: 'Möchtest du diese Sitzung wirklich beenden? Der Benutzer wird auf diesem Gerät abgemeldet und braucht erneut deine Genehmigung.',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonText: `Abbrechen`,
            confirmButtonText: 'Löschen',
            customClass: {
                container: 'discardTask-alert',
                confirmButton: 'f-14px btn-danger fw-400',
                cancelButton: 'f-14px default-btn fw-400',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Verworfen!', '', 'success');
            }
        })
    }


    return (
        <>
            <div className='device-manager-wrapper'>
                <div className='device-manager-topbar'>
                    <div className='topbar-user-section mb-3 mt-2'>
                        <DisplayBetween>
                            <Box width='500px'>
                                <Form className="d-flex">
                                    <Form.Control
                                        type="search"
                                        placeholder="Nach Gerät oder Nutzer suchen"
                                        className="me-0 rounded-end-0 py-0"
                                        aria-label="Search"
                                    />
                                    <Button className='default-btn f-14px fw-500 rounded-end rounded-start-0' > Suchen </Button>
                                </Form>
                            </Box>
                            <Box>
                                <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title={<><HiCog size="18px" /></>}>
                                    <Dropdown.Item onClick={() => setDeviceApproval(true)}>
                                        <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Gerätegenehmigung erzwingen </BodyText>
                                    </Dropdown.Item>
                                </DropdownButton>
                            </Box>
                        </DisplayBetween>
                    </div>
                </div>
                <div className='device-card'>
                    <Card className='boxShadow'>
                        <Card.Body>
                            {showDetail?.map((item, index) => {
                                return (
                                    <>
                                        <Row key={index}>
                                            <Col md={2} xs={4}>
                                                <CustomTooltip
                                                    ToolTipTrigger={
                                                        <>
                                                            <div className='d-flex align-items-center'>
                                                                <img className='rounded-circle' src={item.image} alt='Desktop Pc' width={'60px'} height={'100%'} />
                                                            </div>
                                                        </>
                                                    }
                                                    toolTipContent={item.imageTooltipContent}
                                                    toolTipPlacement='top'
                                                />
                                            </Col>
                                            <Col className='align-self-center' md={8} xs={12}>
                                                <Box pl='25px'>
                                                    {item.detailBtn ?
                                                        <>
                                                            <Row>
                                                                <Col md={5}>
                                                                    <div>
                                                                        <span>Geräte-ID:</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>IP:</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>Letzter Zugriff:</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>Browser:</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>User-Agent:</span>
                                                                    </div>
                                                                </Col>
                                                                <Col md={7}>
                                                                    <div>
                                                                        <span>{item.title}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>{item.ip}</span>
                                                                    </div>
                                                                    <div>
                                                                        {item.lastAccessTooltip ?
                                                                            <InlineBox>
                                                                                <CustomTooltip
                                                                                    ToolTipTrigger={item.lastAccess}
                                                                                    toolTipContent={item.accessDateTime}
                                                                                    toolTipPlacement='top'
                                                                                />
                                                                            </InlineBox>
                                                                            :
                                                                            <InlineBox>
                                                                                {item.accessDateTime}
                                                                            </InlineBox>
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <span>{item.browser}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>{item.userAgent}</span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            <Row>
                                                                <Col md={2} xs={12}>
                                                                    <div className='d-flex align-items-center'>
                                                                        <img className='rounded-circle' src={item.userProfileImage} alt='Desktop Pc' width={'40px'} height={'100%'} />
                                                                    </div>
                                                                </Col>
                                                                <Col md={4} xs={12}>
                                                                    <BodyText>{item.title}</BodyText>
                                                                </Col>
                                                                <Col md={3}>
                                                                    {item.lastAccessTooltip ?
                                                                        <InlineBox>
                                                                            <CustomTooltip
                                                                                ToolTipTrigger={item.lastAccess}
                                                                                toolTipContent={item.accessDateTime}
                                                                                toolTipPlacement='top'
                                                                            />
                                                                        </InlineBox>
                                                                        :
                                                                        <InlineBox>
                                                                            {item.accessDateTime}
                                                                        </InlineBox>
                                                                    }
                                                                </Col>
                                                                <Col md={3} xs={12}>
                                                                    <div className='mt-0'>
                                                                        {item.detailBtn ?
                                                                            null
                                                                            :
                                                                            <>
                                                                                <Button className='default-btn f-12px text-nowwrap' onClick={() => HandleShowInfo(index)}>
                                                                                    <span className='me-1'>Details anzeigen </span>
                                                                                    <FaSortDown className='default-btn-icon' />
                                                                                </Button>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </>
                                                    }
                                                    <div className='my-3'>
                                                        {item.detailBtn ?
                                                            <>
                                                                <Button className='default-btn f-12px text-nowwrap' onClick={() => HandleShowInfo(index)}>
                                                                    <span className='me-1'>Detaillierte Informationen anzeigen </span>
                                                                    <FaSortUp className='default-btn-icon mt-1' />
                                                                </Button>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </Box>
                                            </Col>
                                            <Col className='align-self-start' md={2}>
                                                {item.sessionStatus ?
                                                    null
                                                    :
                                                    <ButtonWidget
                                                        varient='custom'
                                                        bgColor='#d9534f'
                                                        textColor='#fff'
                                                        bgHoverColor='#c9302c'
                                                        class='fs-12px'
                                                        text={
                                                            <>
                                                                <DisplayCenter>
                                                                    Sitzung beenden
                                                                </DisplayCenter>
                                                            </>
                                                        }
                                                        handleClick={() => handleDeleteSession()}
                                                    />
                                                }
                                            </Col>
                                            <Divider mt="8px" mb="10px" />
                                        </Row>
                                    </>
                                )
                            })}
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <CommonModal
                showModal={deviceApproval}
                hideModal={() => setDeviceApproval(false)}
                modalTitle='Gerätegenehmigung'
                modalContent={
                    <>
                        <div className='p-2'>
                            <BodyText>
                                Du bist dabei die Gerätefreischaltungsfunktion zu aktivieren. Wenn diese aktiviert ist, können sich Benutzer nur von mobilen Geräten aus anmelden, die ein Administrator aktiv freigeschaltet hat. Jeder Benutzer, der versucht, sich von einem nicht genehmigten Gerät aus anzumelden, muss warten, bis ein Administrator ihn freischaltet. ACHTUNG! Wenn du sie aktivierst, ohne alle Geräte freizuschalten, werden alle eingeloggten Benutzer auf Mobilgeräten gesperrt, bis sie eine Freischaltung erhalten. Bist du sicher?
                            </BodyText>
                        </div>
                    </>
                }
                modalSize='lg'
                centered={true}
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Abbrechen'}
                                handleClick={() => setDeviceApproval(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Gerätesperre aktivieren'}
                                class='mx-2'
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Gerätesperre aktivieren UND alle Geräte freischalten'}
                            />
                        </DisplayEnd>
                    </>
                }
            />
        </>
    )
}