import axios from "axios";
import { SERVER_URL } from "../Config";

export default class BaseService {
  baseURL = SERVER_URL;
  isInstanceOfRespInfo: boolean = false;

  constructor() {
    //app api call
    axios.defaults.headers["Content-Type"] = "application/json";
    // Add JWT token to every request
    axios.interceptors.request.use((config) => {
      const token = this.getCookie("authToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  }

  public async GET<T>(
    url: string,
    parameters: object = {},
    data: object = {}
  ): Promise<T> {
    return await axios.get(this.baseURL + url, {
      params: parameters,
      data: data,
    });
  }

  public async POST<T>(url: string, data: object = {}): Promise<T> {
    const response = await axios.post(this.baseURL + url, data, {
      transformResponse: (response) => {
        try {
          return JSON.parse(response);
        } catch (error) {
          console.error(error);
          return null;
        }
      },
    });
    return response.data;
  }

  public async DELETE<T>(
      url: string,
      parameters: object = {},
      data: object = {}
    ): Promise<T> {
      return await axios.delete(this.baseURL + url, {
        params: parameters,
        data: data,
      });
    }

  public async POSTWithFile<T>(url: string, data: object = {}): Promise<T> {
      axios.defaults.headers["Content-Type"] = "multipart/form-data";
      return await axios.post(this.baseURL + url, data);
    }

  public getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
