import React, { FC, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import BadgeWidget from '../../../BadgeWidget';
import { FaCaretDown, FaCaretRight, FaPlusCircle } from 'react-icons/fa';
import { BodyText, BorderBox, Box, DisplayBetween, DisplayEnd } from '../../../StyledComponents';
import ButtonWidget from '../../../ButtonWidget';
import FormControl from '../../../FormControl';
import Tagify from '../../../Tagify';
import WidgetTreeView from '../../../WidgetTreeView';



const jsonData = [
    {
        id: 1,
        label: 'UG',
        isActive: false,
        children: [
            {
                id: 2,
                parentId: 1,
                label: 'Heizungsraum',
                isActive: false,
            }
        ]
    },
    {
        id: 3,
        label: 'EG',
        isActive: false,
        children: [
            {
                id: 4,
                parentId: 3,
                label: 'Toilette',
                isActive: false,
            },
            {
                id: 5,
                parentId: 3,
                label: 'Windfang',
                isActive: false,
            },
            {
                id: 6,
                parentId: 3,
                label: 'Rezeption',
                isActive: false,
            },
            {
                id: 7,
                parentId: 3,
                label: 'Wartezimmer',
                isActive: false,
            },
            {
                id: 8,
                parentId: 3,
                label: 'Büros',
                isActive: false,
                children: [
                    {
                        id: 9,
                        parentId: 8,
                        label: 'Büro 1',
                        isActive: false,
                    },
                    {
                        id: 10,
                        parentId: 8,
                        label: 'Back Office',
                        isActive: false,
                    },
                ]
            },
            {
                id: 11,
                parentId: 3,
                label: 'Behandlungszimmer',
                isActive: false,
                children: [
                    {
                        id: 12,
                        parentId: 11,
                        label: 'Zimmer 1',
                        isActive: false,
                    },
                    {
                        id: 13,
                        parentId: 11,
                        label: 'Zimmer 2',
                        isActive: false,
                    },
                    {
                        id: 14,
                        parentId: 11,
                        label: 'Zimmer 3',
                        isActive: false,
                    },
                    {
                        id: 15,
                        parentId: 11,
                        label: 'Prophylaxe',
                        isActive: false,
                    },
                ]
            },
            {
                id: 16,
                parentId: 3,
                label: 'Gang',
                isActive: false,
                children: [
                    {
                        id: 17,
                        parentId: 16,
                        label: 'Steriraum',
                        isActive: false,
                    },
                    {
                        id: 18,
                        parentId: 16,
                        label: 'Röntgenraum',
                        isActive: false,
                    },
                ]
            },
            {
                id: 19,
                parentId: 3,
                label: 'Personalraum mit Küche',
                isActive: false,
                children: [
                    {
                        id: 20,
                        parentId: 19,
                        label: 'Labor',
                        isActive: false,
                    },
                ]
            },
        ]
    },
    {
        id: 21,
        label: 'Außenbereich',
        isActive: false,
        children: [
            {
                id: 22,
                parentId: 21,
                label: 'Müllplatz',
                isActive: false,
            },
            {
                id: 23,
                parentId: 21,
                label: 'Parkplatz',
                isActive: false,
            }
        ]
    }
];



interface IManagePlacesModalContent {
}
const ManagePlacesModalContent: FC = () => {
    const [isShow, setIsShow] = useState<boolean>(false);
    const [activeLabel, setActiveLabel] = useState<string>('');
    const [mainName, setMainName] = useState<string>('');
    const handleExpandClick = () => {
        setActiveLabel('');
        if (mainName === 'Praxis Gallenbach & Zähne') {
            setMainName('');
        } else {
            setMainName('Praxis Gallenbach & Zähne');
        }
    }
    return (
        <>
            <Row>
                <Col lg={3} className='pt-3 pb-0 border-end'>
                    <Box mb='10px' cursor='pointer' onClick={() => { setIsShow(!isShow); handleExpandClick(); }}>
                        {isShow ?
                            <FaCaretDown size={16} />
                            :
                            <FaCaretRight size={16} />
                        }
                        <BadgeWidget outiline={true} title='Praxis Gallenbach & Zähne ' color='#0d3f5f' />
                    </Box>
                    {isShow ?
                        <Box>
                            {/* <CommonList listData={listData} getListItem={setActiveListItem} /> */}
                            <WidgetTreeView treeData={jsonData} getActiveLabel={setActiveLabel} />
                        </Box>
                        :
                        null
                    }
                </Col>
                <Col lg={9} className='pt-3 pb-0 px-0'>
                    <Box pl='15px' pr='12px'>
                        <Row>
                            {isShow ?
                                <>
                                    <Col lg={6} className='pb-sm-10px'>
                                        <BodyText fontSize='14px' fontWeight='700'>Liste {!!activeLabel ? <span>{activeLabel}</span> : mainName} bearbeiten</BodyText>
                                    </Col>
                                    <Col lg={6} className='ps-lg-0 pb-sm-20px'>
                                        <DisplayEnd verticalAlign='start'>
                                            <Box>
                                                {!!activeLabel ?
                                                    <>
                                                        <ButtonWidget
                                                            text='Löschen'
                                                            varient='danger'
                                                            class='f-12px'
                                                        />
                                                        <ButtonWidget
                                                            text='Verschieben'
                                                            varient='light'
                                                            class='default-btn f-12px mx-2'
                                                        />
                                                    </>
                                                    :
                                                    null
                                                }
                                                <ButtonWidget
                                                    text='Unterliste hinzufügen'
                                                    varient='light'
                                                    class='default-btn f-12px'
                                                />
                                            </Box>
                                        </DisplayEnd>
                                    </Col>
                                </>
                                :
                                <Col md={12}>
                                    <BodyText mb="11px" fontSize='14px' fontWeight='700'>Liste bearbeiten</BodyText>
                                </Col>
                            }
                        </Row>
                    </Box>
                    {!!activeLabel ?
                        <>
                            <BorderBox mt='15px' pb='15px' pl='15px' pr='12px'>
                                <Row>
                                    <Col md={12}>
                                        <Form.Label>Name</Form.Label>
                                        <FormControl isFormGroup={false} Type='text' Size='sm' Value={activeLabel} Name='name' Id='name' />
                                    </Col>
                                </Row>
                            </BorderBox>
                            <DisplayEnd mt="15px" mb="15px" pr='12px'>
                                <ButtonWidget varient='primary' text="Erstellen" />
                            </DisplayEnd>
                        </>
                        :
                        null
                    }
                </Col>
            </Row>
        </>
    );
}

export default ManagePlacesModalContent;