import React from 'react';
import { styled } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
    normal: {
        //flexGrow: 1,

    },
    highlighted: {

    },
}));

export class DatePicker extends React.Component {
    constructor(props) {
        super(props);

        var now = new Date(),
            today = new Date(now);

        //now.setMonth(now.getMonth() - 1);
        //today.setMonth(today.getMonth() - 1);

        today.setHours(0, 0, 0, 0);
        var monday = this.calcGetMonday(today);

        this.state = {
            _highlights: {
                "2021-04-01": { "backgroundColor": "7fff7f", "date": "2021-04-01", "description": "Osterferien 2021" },
                "2021-04-02": { "backgroundColor": "7fff7f", "date": "2021-04-02", "description": "Osterferien 2021" },
                "2021-04-03": { "backgroundColor": "7fff7f", "date": "2021-04-03", "description": "Osterferien 2021" },
                "2021-04-04": { "backgroundColor": "7fff7f", "date": "2021-04-04", "description": "Osterferien 2021" },
                "2021-04-05": { "backgroundColor": "7fff7f", "date": "2021-04-05", "description": "Osterferien 2021" },
                "2021-04-06": { "backgroundColor": "7fff7f", "date": "2021-04-06", "description": "Osterferien 2021" },
                "2021-04-07": { "backgroundColor": "7fff7f", "date": "2021-04-07", "description": "Osterferien 2021" },
                "2021-04-08": { "backgroundColor": "7fff7f", "date": "2021-04-08", "description": "Osterferien 2021" },
                "2021-04-09": { "backgroundColor": "7fff7f", "date": "2021-04-09", "description": "Osterferien 2021" },
                "2021-04-10": { "backgroundColor": "7fff7f", "date": "2021-04-10", "description": "Osterferien 2021" },
                "2021-04-11": { "backgroundColor": "7fff7f", "date": "2021-04-11", "description": "Osterferien 2021" },
                "2021-04-12": { "backgroundColor": "7fff7f", "date": "2021-04-12", "description": "Osterferien 2021" },
                "2021-04-13": { "backgroundColor": "7fff7f", "date": "2021-04-13", "description": "Osterferien 2021" },
                "2021-04-14": { "backgroundColor": "7fff7f", "date": "2021-04-14", "description": "Osterferien 2021" },
                "2021-04-15": { "backgroundColor": "7fff7f", "date": "2021-04-15", "description": "Osterferien 2021" },
                "2021-04-16": { "backgroundColor": "7fff7f", "date": "2021-04-16", "description": "Osterferien 2021" }
            },
            _holidays: {
                "2021-04-02": { "description": "Karfreitag 2021", "type": "holiday" },
                "2021-04-04": { "description": "Ostersonntag 2021", "type": "holiday" },
                "2021-04-05": { "description": "Ostermontag 2021", "type": "holiday" },
                "2021-05-01": { "description": "Maifeiertag", "type": "holiday" },
            },
            highlights: {},
            holidays: {},

            today: today,//red border
            view: "week",
            activeDate: today.addDays(+2),//orange - selected Day
            selectedRange: { //brown - selected Range
                from: monday,
                until: monday.addDays(6),
            },

            currentDate: today.addDays(0),//invisible, month and year 
        };

        this.updateDates = this.updateDates.bind(this);

        this.onDateClick = this.onDateClick.bind(this);
        this.setNext = this.setNext.bind(this);

        this.setMonth = this.setMonth.bind(this);
        this.setYear = this.setYear.bind(this);
    }

    componentDidMount() {
        var self = this;

    }

    componentWillUnmount() {
        var self = this;

    }

    isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    updateDates() {
        var now = new Date(),
            today = new Date(now);
        today.setHours(0, 0, 0, 0);

        this.state.today = today;
        //-----
        if (this.props.view == "day" || this.props.view == "week") {
            this.state.view = this.props.view;
        }
        else {
            this.state.view = "day";
        }
        //-----
        if (this.props.highlights) {
            this.state.highlights = this.props.highlights;
        }
        else {
            this.state.highlights = [];
        }
        if (this.props.holidays) {
            this.state.holidays = this.props.holidays;
        }
        else {
            this.state.holidays = [];
        }
        //-----
        if (this.props.selected && this.isValidDate(this.props.selected)) {
            if (this.state.activeDate.getTime() != this.props.selected.getTime()) {
                this.state.currentDate = this.props.selected.addDays(0);
            }
            this.state.activeDate = this.props.selected.addDays(0);
        }
        else {
            this.state.activeDate = today;
        }
        //-----

        this.state.calendarStruct = this.calcGetCalendarStruct(this.state.currentDate);

        if (this.state.view == "week") {

            this.state.selectedRange.from = this.calcGetMonday(this.state.activeDate);
            this.state.selectedRange.until = this.state.selectedRange.from.addDays(6);
        }
        else {
            this.state.selectedRange.from = this.state.activeDate;
            this.state.selectedRange.until = this.state.selectedRange.from;
        }
        //console.log("calendarStruct: " + JSON.stringify(this.state.calendarStruct));

        //console.log("activeDate: " + this.state.activeDate);
        //console.log("today: " + this.state.today);

        this.state.todayTime = this.state.today.getTime();
        this.state.activeDateTime = this.state.activeDate.getTime();
        this.state.currentDateTime = this.state.currentDate.getTime();

        this.state.currentDateMonth = this.state.currentDate.getMonth() + 1;
        this.state.currentDateFullYear = this.state.currentDate.getFullYear();

        this.state.selectedRange.fromTime = this.state.selectedRange.from.getTime();
        this.state.selectedRange.untilTime = this.state.selectedRange.until.getTime();
    }

    onDateClick(date) {
        //debugger
        var d = new Date();
        var h = d.getHours();
        var m = d.getMinutes();
        var s = d.getSeconds();
        var secondsUntilEndOfDate = (24 * 60 * 60) - (h * 60 * 60) - (m * 60) - s;
        //this.props.setCookie("calendarSelectDate", date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate(), { path: '/', maxAge: secondsUntilEndOfDate })
        //alert("Date: "+ date);
        this.props.setCookie("calendarSelectDate", date, { path: '/', maxAge: secondsUntilEndOfDate })
        this.state.currentDate = date.addDays(0);
        this.state.activeDate = date.addDays(0);

        this.props.onChange(this.state.activeDate);
        /*
        this.setState(state => ({
            activeDate: state.activeDate,
        }));
        */
    }

    setNext(next) {
        this.state.currentDate.setMonth(this.state.currentDate.getMonth() + (next ? +1 : -1));
        this.setState(state => ({
            currentDate: state.currentDate,
        }));
    }

    setMonth(event) {
        //console.log("setMonth: " + month);
        this.state.currentDate.setMonth(event.target.value);
        this.setState(state => ({
            currentDate: state.currentDate,
        }));
    }

    setYear(event) {
        this.state.currentDate.setFullYear(event.target.value);
        this.setState(state => ({
            currentDate: state.currentDate,
        }));

    }


    getMonthPicker() {
        var month = this.state.currentDate.getMonth();

        return <select className="ui-datepicker-month" value={month} onChange={this.setMonth} readOnly={true} data-handler="selectMonth" data-event="change">
            <option value="0">Jan</option>
            <option value="1">Feb</option>
            <option value="2">Mär</option>
            <option value="3" /*selected="selected"*/>Apr</option>
            <option value="4">Mai</option>
            <option value="5">Jun</option>
            <option value="6">Jul</option>
            <option value="7">Aug</option>
            <option value="8">Sep</option>
            <option value="9">Okt</option>
            <option value="10">Nov</option>
            <option value="11">Dez</option>
        </select>;
    }

    getYearPicker(year) {
        var year = this.state.currentDate.getFullYear();

        return <select className="ui-datepicker-year" value={year} onChange={this.setYear} readOnly={true} data-handler="selectYear" data-event="change">
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021" /*selected="selected"*/>2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
        </select>;
    }

    getTHead() {
        return <thead>
            <tr>
                <th className="ui-datepicker-week-col">KW</th>
                <th scope="col"><span title="Montag">Mo</span></th>
                <th scope="col"><span title="Dienstag">Di</span></th>
                <th scope="col"><span title="Mittwoch">Mi</span></th>
                <th scope="col"><span title="Donnerstag">Do</span></th>
                <th scope="col"><span title="Freitag">Fr</span></th>
                <th scope="col" className="ui-datepicker-week-end"><span title="Samstag">Sa</span></th>
                <th scope="col" className="ui-datepicker-week-end"><span title="Sonntag">So</span></th>
            </tr>
        </thead>;

    }

    getTBody() {
        return <tbody>
            {this.state.calendarStruct.weeks.map((value, index) => {
                //console.log("weekStruct: " + JSON.stringify(value));

                return this.getWeek(index, value)
            })}
        </tbody>;
    }

    calcGetWeekNumber(datetime) {
        var date = new Date(datetime.getTime());
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        var week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
            - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    calcGetFirstLastDate(datetime) {
        //console.log("calcGetFirstLastDate");

        var first_date = new Date(datetime.getTime());
        //first_date.setHours(0, 0, 0, 0);
        first_date.setDate(1);
        //console.log("first_date: " + first_date);

        var last_date = new Date(first_date.getTime());
        last_date.setMonth(last_date.getMonth() + 1);
        //console.log("last_date: " + last_date);
        last_date = last_date.addDays(-1);
        //console.log("last_date: " + last_date);

        //console.log("first_date: " + first_date);
        //console.log("last_date: " + last_date);
        return {
            first: first_date,
            last: last_date,
        };
    }

    calcGetMonday(date) {
        var day = date.getDay(),
            monday;

        if (day == 0) {
            monday = date.addDays(-6);
        }
        else {
            monday = date.addDays(-(day - 1));
        }
        return monday;
    }

    calcGetWeekDates(monday) {
        var first_date = new Date(monday.getTime());

        return [
            first_date,
            first_date.addDays(1),
            first_date.addDays(2),
            first_date.addDays(3),
            first_date.addDays(4),
            first_date.addDays(5),
            first_date.addDays(6),
        ];
    }

    calcDaysBetween(firstDate, secondDate) {
        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        //const firstDate = new Date(2008, 1, 12);
        //const secondDate = new Date(2008, 1, 22);

        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        return diffDays;
    }

    calcGetCalendarStruct(date) {
        //console.log("calcGetCalendarStruct");
        //console.log("date: " + date);

        var firstLast = this.calcGetFirstLastDate(date);
        //console.log("firstLast: " + JSON.stringify(firstLast));

        //console.log("firstLast.first: " + firstLast.first);
        //console.log("firstLast.last: " + firstLast.last);

        var firstMonday = this.calcGetMonday(firstLast.first),
            lastMonday = this.calcGetMonday(firstLast.last),
            daysCount = this.calcDaysBetween(firstMonday, lastMonday),//lastMonday - firstMonday,
            weeksArr = [],
            weeksCount = 0;

        //console.log("firstMonday: " + firstMonday);
        //console.log("lastMonday: " + lastMonday);

        //console.log("daysCount: " + daysCount);
        weeksCount = daysCount / 7 + 1;
        if (weeksCount == 0 || weeksCount > 6) {
            weeksCount = 0;
        }
        //console.log("weeksCount: " + weeksCount);

        for (var i = 0; i < weeksCount; i++) {
            var current = firstMonday.addDays(i * 7),
                weekNumber = this.calcGetWeekNumber(current),
                weekDates = this.calcGetWeekDates(current);

            weeksArr.push({
                weekNumber: weekNumber,
                otherMonth: i == 0 || i == weeksCount - 1,
                weekDates: weekDates
            });
        }
        return {
            firstLast: firstLast,
            weeks: weeksArr,
        };
    }

    getWeek(index, weekStruct) {
        return <tr key={index}>
            <td className="ui-datepicker-week-col">{weekStruct.weekNumber}</td>
            {this.getDay(0, weekStruct)}
            {this.getDay(1, weekStruct)}
            {this.getDay(2, weekStruct)}
            {this.getDay(3, weekStruct)}
            {this.getDay(4, weekStruct)}
            {this.getDay(5, weekStruct)}
            {this.getDay(6, weekStruct)}
        </tr>;
    }

    getDay(index, weekStruct) {
        //console.log("getDay2");
        //console.log("index: " + index);
        //console.log("weekStruct: " + JSON.stringify(weekStruct));

        var currentDate = weekStruct.weekDates[index],
            currentTime = currentDate.getTime(),

            currFullYear = currentDate.getFullYear(),
            currMonth = (currentDate.getMonth() + 1),
            currDate = currentDate.getDate(),

            date_string = currFullYear + "-" + ("0" + currMonth).slice(-2) + "-" + ("0" + currDate).slice(-2);

        //console.log("getDay2: " + currentDate);
        var today = this.state.todayTime == currentTime,
            active = this.state.activeDateTime == currentTime,
            selected = this.state.selectedRange.fromTime <= currentTime && this.state.selectedRange.untilTime >= currentTime,
            weekend = (index == 5 || index == 6),
            sunday = index == 6,
            otherMonth,

            holiday = this.state.holidays[date_string],
            highlight = this.state.highlights[date_string],
            title = ((holiday ? holiday.description : "") + "\n" + (highlight ? highlight.description : "")),
            backgroundColor = (highlight ? "#" + highlight.backgroundColor : "");


        if (weekStruct.otherMonth &&
            (this.state.currentDateMonth != currMonth ||
                this.state.currentDateFullYear != currFullYear)) {

            otherMonth = 1;
        };

        var td_classname = "",
            a_classname = "ui-state-default",
            a_style = {};
        if (active) {
            td_classname += " ui-datepicker-current-day";
        }
        if (otherMonth) {
            td_classname += " ui-datepicker-other-month";
        }
        if (weekend) {
            td_classname += " ui-datepicker-week-end";
        }
        if (today) {
            //console.log("today: " + currentDate);
            td_classname += " ui-datepicker-today";
        }

        if (active) {
            //console.log("active: " + currentDate);
            a_classname += " ui-state-active";
            //a_classname += " ds-period-selected";
        }
        if (otherMonth) {
            a_classname += " ui-priority-secondary";
        }
        if (sunday) {
            a_classname += " ds-sunday";
        }
        if (holiday) {
            //console.log("holiday: " + currentDate);
            a_classname += " ds-holiday";
        }
        if (today) {
            //console.log("today: " + currentDate);
            a_classname += " ui-state-highlight-today";
        }
        if (selected) {
            //console.log("selected: " + currentDate);
            a_classname += " ds-period-selected";
        }
        if (highlight) {
            a_style = {
                borderBottomColor: backgroundColor/*"rgb(127, 255, 127)"*/,
                //borderTopColor: backgroundColor/*"rgb(127, 255, 127)"*/, 
                //borderLeftColor: backgroundColor/*"rgb(127, 255, 127)"*/, 
                //borderRightColor: backgroundColor/*"rgb(127, 255, 127)"*/, 
                backgroundColor: backgroundColor
            };
        }
        else {
            a_style = { backgroundColor: "" };
        }

        return <td className={td_classname} data-handler="selectDay" data-event="click" data-day={currDate} data-month={currMonth} data-year={currFullYear} title={title}>
            <a className={a_classname} style={a_style} onClick={() => this.onDateClick(currentDate)}>{currDate}</a>
        </td>;
    }

    render() {
        this.updateDates();

        return <div data-range-type="week" data-ds-rt="datepicker" data-ds-rid="miniCal" id="wPXdIkrJRISqzk_kkuFdIg" className="hasDatepicker">
            <div className="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all" style={{ display: "block" }}>
                <div className="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
                    <a className="ui-datepicker-prev ui-corner-all" data-handler="prev" onClick={() => this.setNext(0)} data-event="click" title="<Zurück">
                        <span className="fa fa-fw fa-chevron-left"></span>
                    </a>
                    <a className="ui-datepicker-next ui-corner-all" data-handler="next" onClick={() => this.setNext(1)} data-event="click" title="Vor>">
                        <span className="fa fa-fw fa-chevron-right"></span>
                    </a>
                    <div className="ui-datepicker-title">
                        {this.getMonthPicker()}

                        {this.getYearPicker()}
                    </div>
                </div>
                <table className="ui-datepicker-calendar">
                    {this.getTHead()}

                    {this.getTBody()}
                </table>
            </div>
        </div>;
    }
}

export default function DateComponent(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['appToken']);

    let navigate = useNavigate();
    return (
        <>
            <DatePicker
                view={props.view}
                selected={props.selected}
                onChange={props.onChange}

                highlights={props.highlights}
                holidays={props.holidays}
                setCookie={setCookie} />
        </>
    )
}

