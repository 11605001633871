import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { AccountSettingImg, Box, DisplayCenter, DisplayEnd, DisplayStart, ThemeColorPicker, UploadImgLabel } from "../../../Components/StyledComponents";
import { useDispatch } from "react-redux";
import { setThemeColor } from "../../../Redux/Slice/appSlice";
import ButtonWidget from "../../../Components/ButtonWidget";
import { Col, Row } from "react-bootstrap";
import { GrUploadOption } from "react-icons/gr";
import { FaCrop, FaRegTimesCircle } from "react-icons/fa";
import CommonModal from "../../../Components/Modals/CommonModal";
import CropImgModalContent from "../../../Components/Modals/ModelContents/CropImgModalContent";
import { CropImgModalFooterContent } from "../../../Components/Modals/ModelContents/AccountSettingsModalContent";
import Cropper, { ReactCropperElement } from "react-cropper";

interface IProps { }

export const Option: FC<IProps> = ({ }) => {
    let dispatch = useDispatch();
    const cropperRef = useRef<ReactCropperElement>();

    const [selectedColor, setSelectedColor] = useState<string>('');
    const [ImgSource, setImgSource] = useState<string>("");
    const [cropImgModal, setCropImgModal] = useState<boolean>(false);


    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedColor(event.target.value);
    };
    const handleImgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            var src = URL.createObjectURL(selectedFile);
            setImgSource(src);
        }
    };
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setImgSource(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setCropImgModal(false);
        }
    };
    return (
        <>

            <Row>
                <Col md={12}>
                    <h2>Ansicht</h2>
                    <p>Farbe und Logo dieses Kits ändern</p>
                </Col>
                <Col md={3}>
                    <div className="mt-2 theme-color-picker">
                        <h4>Ansicht</h4>
                        <ThemeColorPicker onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event)} />
                    </div>
                </Col>
                <Col md={9} className="align-self-center">
                    <Row className="mt-2">
                        <Col md={8} className="pe-0 align-self-center">
                            <DisplayStart>
                                <Box mb="10px">
                                    <input
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="upload-img"
                                        type="file"
                                        onChange={handleImgChange}
                                    />
                                    <UploadImgLabel htmlFor="upload-img" className="w-100">
                                        <DisplayCenter>
                                            <GrUploadOption />
                                            <span className="ms-1">Bild hochladen</span>
                                        </DisplayCenter>
                                    </UploadImgLabel>
                                </Box>
                                <Box mb="10px" ml='10px' mr='10px'>
                                    {!!ImgSource ? (
                                        <ButtonWidget
                                            varient="light"
                                            class="w-100"
                                            text={
                                                <>
                                                    <DisplayCenter>
                                                        <FaRegTimesCircle />
                                                        <span className="ms-1">Bild löschen</span>
                                                    </DisplayCenter>
                                                </>
                                            }
                                            handleClick={() => setImgSource("")}
                                        />
                                    ) : null}
                                </Box>
                                <Box mb="10px">
                                    {!!ImgSource ? (
                                        <ButtonWidget
                                            varient="light"
                                            class="w-100"
                                            text={
                                                <>
                                                    <DisplayCenter>
                                                        <FaCrop />
                                                        <span className="ms-1">Bild zuschneiden</span>
                                                    </DisplayCenter>
                                                </>
                                            }
                                            handleClick={() => setCropImgModal(true)}
                                        />
                                    ) : null}
                                </Box>
                            </DisplayStart>
                        </Col>
                        <Col md={4}>
                            <DisplayCenter>
                                <img alt="logo" className="uploaded-logo" src={`${!!ImgSource ? ImgSource : "/images/logo.png"}`}
                                />
                            </DisplayCenter>
                        </Col>
                    </Row>
                </Col>
                <Col md={12}>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                handleClick={() => dispatch(setThemeColor(selectedColor))}
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
            </Row>



            <CommonModal
                showModal={cropImgModal}
                hideModal={() => setCropImgModal(false)}
                modalTitle="Bild zuschneiden"
                modalContent={
                    <CropImgModalContent ImgSrc={ImgSource} CropRef={cropperRef} />
                }
                modalSize="lg"
                modalFooterContent={
                    <CropImgModalFooterContent
                        hideModel={() => setCropImgModal(false)}
                        saveCrop={getCropData}
                    />
                }
            />

        </>
    )
}