import React, { useState, useEffect } from 'react';
import { Row, Col, Table, } from 'react-bootstrap';
import { AccountSettingImg, DisplayStart, Divider } from '../../StyledComponents';
import Form from 'react-bootstrap/Form';
import FormControl from '../../FormControl';
import FormCheck from '../../FormCheck';
import FormSelect from '../../FormSelect';
import { fontSizeOptions, footerLinkOptions, headerLinkOptions } from '../../../CommonJSON';


export const PdfSettingsModalContent = () => {
    return (
        <>
            <Row>
                <Col md={6} xs={12}>
                    <div className='pb-3'>
                        <Form.Label>Darstellungsform wählen:</Form.Label>
                        <Form.Group controlId='Anrede' className="mb-3" style={{ height: '31px' }}>
                            <FormCheck
                                // handleChange={handleFormCheck}
                                type='radio'
                                name='phone'
                                Id='phone'
                                value='Liste (Desktop Ansicht)Weiblich'
                                label='Liste (Desktop Ansicht)'
                                isFormGroup={false}
                                isInline={false}
                            // checked={salutation === 'Weiblich' ? true : false}
                            />
                            <FormCheck
                                // handleChange={handleFormCheck}
                                type='radio'
                                name='phone'
                                Id='phone2'
                                value='Tabelle'
                                label='Tabelle'
                                isFormGroup={false}
                                isInline={false}
                            // checked={salutation === 'Männlich' ? true : false}
                            />
                        </Form.Group>
                    </div>
                </Col>
                <Col md={6} xs={12}>
                    <div className='pb-3'>
                        <Form.Label>Export-Format:</Form.Label>
                        <Form.Group controlId='Anrede' className="mb-3" style={{ height: '31px' }}>
                            <FormCheck
                                type='radio'
                                name='phone'
                                Id='phone'
                                value='PDF'
                                label='PDF'
                                isFormGroup={false}
                                isInline={false}
                            />
                            <FormCheck
                                type='radio'
                                name='phone'
                                Id='phone2'
                                value='HTML'
                                label='HTML'
                                isFormGroup={false}
                                isInline={false}
                            />
                        </Form.Group>
                    </div>
                </Col>
                <Col md={6} xs={12}>
                    <div className='pb-3'>
                        <Form.Label> Inhalte auswählen: </Form.Label>
                        <Form.Group controlId='Anrede' className="mb-3" style={{ height: '31px' }}>
                            <FormCheck
                                type='checkbox'
                                name='phone'
                                Id='phone'
                                value='Text'
                                label='Text'
                                isFormGroup={false}
                                isInline={false}
                            />
                            <FormCheck
                                type='checkbox'
                                name='phone'
                                Id='phone2'
                                value='Wiederholung anzeigen'
                                label='Wiederholung anzeigen'
                                isFormGroup={false}
                                isInline={false}
                            />
                            <FormCheck
                                type='checkbox'
                                name='phone'
                                Id='phone2'
                                value='Wiederholung anzeigen'
                                label={
                                    <>
                                        <span className='mb-1'>Zeige angehängte Bilder -</span>
                                        <DisplayStart width='170px' mt='15px' mb='5px'>
                                            <FormControl Type='text' Placeholder='2' Name='Bilder pro Zeile' Id='Bilder pro Zeile' />
                                            <Form.Label className='ms-3 text-nowrap'> Bilder pro Zeile </Form.Label>
                                        </DisplayStart>
                                    </>}
                                isFormGroup={false}
                                isInline={false}
                            />
                            <FormCheck
                                type='checkbox'
                                name='phone'
                                Id='phone2'
                                value='Kommentare'
                                label='Kommentare'
                                isFormGroup={false}
                                isInline={false}
                            />
                        </Form.Group>
                    </div>
                </Col>
                <Col md={6} xs={12}>
                    <div className='pb-3'>
                        <Form.Label>Papierformat wählen:</Form.Label>
                        <Form.Group controlId='Anrede' className="mb-3" style={{ height: '31px' }}>
                            <FormCheck
                                type='radio'
                                name='phone'
                                Id='phone'
                                value='Hochformat'
                                label='Hochformat'
                                isFormGroup={false}
                                isInline={false}
                            />
                            <FormCheck
                                type='radio'
                                name='phone'
                                Id='phone2'
                                value='Querformat'
                                label='Querformat'
                                isFormGroup={false}
                                isInline={false}
                            />
                        </Form.Group>
                    </div>
                    <div className='pb-3'>
                        <Form.Label>Wähle eine Schriftgröße</Form.Label>
                        <FormSelect
                            isFormGroup={true}
                            selectOptions={fontSizeOptions}
                            Size='sm'
                            Name='salute'
                            Id='salute'
                        />
                    </div>
                </Col>
            </Row>
            <Divider mb='16px' mt="10px" />
            <Row>
                <Col md={12}>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Links</th>
                                <th>Mitte</th>
                                <th>Erstellung</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> Header </td>
                                <td>
                                    <div>
                                        <FormSelect
                                            isFormGroup={true}
                                            selectOptions={headerLinkOptions}
                                            Size='sm'
                                            Name='salute'
                                            Id='salute'
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <FormSelect
                                            isFormGroup={true}
                                            selectOptions={headerLinkOptions}
                                            Size='sm'
                                            Name='salute'
                                            Id='salute'
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <FormSelect
                                            isFormGroup={true}
                                            selectOptions={headerLinkOptions}
                                            Size='sm'
                                            Name='salute'
                                            Id='salute'
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td> Footer </td>
                                <td>
                                    <div>
                                        <FormSelect
                                            isFormGroup={true}
                                            selectOptions={footerLinkOptions}
                                            Size='sm'
                                            Name='salute'
                                            Id='salute'
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <FormSelect
                                            isFormGroup={true}
                                            selectOptions={footerLinkOptions}
                                            Size='sm'
                                            Name='salute'
                                            Id='salute'
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <FormSelect
                                            isFormGroup={true}
                                            selectOptions={footerLinkOptions}
                                            Size='sm'
                                            Name='salute'
                                            Id='salute'
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}