import { FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CheckControl } from "../../../Components/Widgets/InputControls/CheckControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { BodyText, Box, DisplayEnd, DisplayStart } from "../../../Components/StyledComponents";
import { InputTextControl } from "../../../Components/Widgets/InputControls/InputTextControl";
import ButtonWidget from "../../../Components/ButtonWidget";
import AddEditMessage from "../../Message/AddEditMessage";


interface IProps { }


const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
        lastName: yup
            .string()
            .typeError("Last name is required")
            .required("Last name is required"),
        position: yup
            .string()
            .typeError("Position is required")
            .required("Position is required"),
    })
    .required();


const MessagesConfig: FC<IProps> = ({ }) => {

    const [MessageModal, setMessageModal] = useState<boolean>(false);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });
    const fn = watch("firstName");
    const { gender } = watch();
    console.log(fn);

    return (
        <>
            <Row>
                <Col md={12}>
                    <h2>Nachrichten</h2>
                    <CheckControl
                        fieldType={"checkbox"}
                        control={control}
                        fieldName={"gender"}
                        error={errors.gender}
                        value={"Aktiviere eine Warnmeldung, falls mehr als"}
                        label={
                            <>
                                <DisplayStart>
                                    <BodyText className='text-nowrap' fontSize='15px' fontWeight='400' textColor='#666'> Aktiviere eine Warnmeldung, falls mehr als </BodyText>
                                    <Box ml="6px" mr="6px" width="100px">
                                        <InputTextControl
                                            control={control}
                                            fieldName={"firstName"}
                                            error={errors.firstName}
                                            placeholder=""
                                            fieldType="number"
                                            size={"sm"}
                                        />
                                    </Box>
                                    <BodyText className='text-nowrap' fontSize='15px' fontWeight='400' textColor='#666'> % der Nutzer als Empfänger ausgewählt wurden. </BodyText>
                                </DisplayStart>
                            </>}
                        isInline={true}
                        disabled={false}
                        checked={undefined}
                        defaultChecked={false}
                    />
                    <BodyText fontSize='15px' fontWeight='400' textColor='#666' mt="12px" mb="15px"> Vorlage ändern, die für alle neu erstellten Nachrichten verwendet wird, für die keine Vorlage festgelegt wurden. </BodyText>
                    <ButtonWidget varient="primary" text="Standard Nachrichten bearbeiten" class='text-nowrap' handleClick={() => setMessageModal(true)} />
                </Col>
                <Col md={12}>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
            </Row>

            <AddEditMessage
                showMessange={MessageModal}
                hideMessange={() => setMessageModal(false)}
            />
        </>
    );
}

export default MessagesConfig;