import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { BsPaperclip } from "react-icons/bs";

function AddMediaModal(props: any) {
    const [Appendix, setAppendix] = useState(true);
    const [ExternalImage, setExternalImage] = useState(false);
    const [ExternalLink, setExternalLink] = useState(false);
    const [YoutubeVideo, setYoutubeVideo] = useState(false);

    const handleAppendix = () => {
        setAppendix(true);
        setExternalImage(false);
        setExternalLink(false);
        setYoutubeVideo(false);
    }
    const handleExternalImage = () => {
        setAppendix(false);
        setExternalImage(true);
        setExternalLink(false);
        setYoutubeVideo(false);
    }
    const handleExternalLink = () => {
        setAppendix(false);
        setExternalImage(false);
        setExternalLink(true);
        setYoutubeVideo(false);
    }
    const handleYoutubeVideo = () => {
        setAppendix(false);
        setExternalImage(false);
        setExternalLink(false);
        setYoutubeVideo(true);
    }
    return (
        <>
            <Modal size="lg" className='shadow-modal' show={props.displayAddMediaModal} onHide={props.hideAddMediaModal} backdrop="static" keyboard={false} aria-labelledby="template-modal" >
                <Modal.Header closeButton>
                    <Modal.Title id="template-modal">
                        Medien in den Text einfügen
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>
                        Quelle
                    </Form.Label>
                    <div className='mb-3'>
                        <Form.Check
                            inline
                            label="Aus dem Anhang"
                            type="radio"
                            name="media-type"
                            id=""
                            checked={Appendix}
                            onChange={handleAppendix}
                        />
                        <Form.Check
                            inline
                            label="Externes Bild"
                            type="radio"
                            name="media-type"
                            id=""
                            onChange={handleExternalImage}
                        />
                        <Form.Check
                            inline
                            label="Externer Link"
                            type="radio"
                            name="media-type"
                            id=""
                            onChange={handleExternalLink}
                        />
                        <Form.Check
                            inline
                            label="Youtube Video"
                            type="radio"
                            name="media-type"
                            id=""
                            onChange={handleYoutubeVideo}
                        />
                    </div>
                    {Appendix ?
                        <div className='row'>
                            <div className='col-md-9'>
                                <div className='file-holder'>
                                    <div className='text-center'>
                                        <span>Dateien ablegen und hochladen</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="In-medai-modal file-attach rounded-0 f-14px">
                                    <input type="file" id="imageInput" />
                                    <label htmlFor="imageInput" className="image-button"><BsPaperclip size="14px" /> Anhänge hinzufügen</label>
                                    <img src="" className="image-preview" />
                                    <span className="change-image">Choose different image</span>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                    {ExternalImage ?
                        <div>
                            <Form.Control
                                type="text"
                                id="ex-img"
                                aria-describedby="ex-img"
                                placeholder="Bitte Bild-URL einfügen"
                            />
                        </div>
                        : null
                    }
                    {ExternalLink ?
                        <div>
                            <Form.Control
                                type="text"
                                id="ex-link"
                                aria-describedby="ex-link"
                                placeholder="Link URL einfügen"
                            />
                        </div>
                        : null
                    }
                    {YoutubeVideo ?
                        <div>
                            <Form.Control
                                type="text"
                                id="yt-video"
                                aria-describedby="yt-video"
                                placeholder="Bitte Video-URL oder Code  einfügen"
                            />
                        </div>
                        : null
                    }


                </Modal.Body>
                <Modal.Footer className='justify-content-end'>
                    <Button onClick={props.hideAddMediaModal} className='default-btn f-14px me-2' >Schließen</Button>
                    <Button disabled className='primary-bg f-14px'>Ausgewählte einfügen</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AddMediaModal;