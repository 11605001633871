import React from 'react';
import { BorderBadge, InlineBox, OutlineBadge, SolidBadge } from './StyledComponents';
import { FaTimes } from "react-icons/fa";

interface type {
    title: string,
    color: string,
    solid?: boolean,
    outiline?: boolean,
    bordered?: boolean,
    bgColor?: string,
    mr?: string,
    closeIcon?: boolean,
    handleBadgeClick?: (event: React.MouseEvent | React.TouchEvent) => void,
}

function BadgeWidget(props: type) {
    const { title, color, outiline, bgColor, mr, closeIcon, handleBadgeClick, bordered, solid } = props
    return (
        <>
            {outiline ?
                <OutlineBadge color={color} mr={mr} >
                    {title}
                </OutlineBadge>
                :
                null
            }
            {solid ?
                <>
                    {closeIcon ?
                        <SolidBadge color={color} bgColor={bgColor} mr={mr} cursor='pointer' onClick={handleBadgeClick}>
                            <InlineBox>{title}</InlineBox>
                            <InlineBox ml='5px'><FaTimes color='#fff' /></InlineBox>
                        </SolidBadge>
                        :
                        <SolidBadge color={color} bgColor={bgColor} mr={mr} >
                            {title}
                        </SolidBadge>
                    }
                </>
                :
                null
            }
            {bordered ?
                <BorderBadge color={color} mr={mr} cursor='pointer'>
                    {title}
                </BorderBadge>
                :
                null
            }
        </>
    );
}

export default BadgeWidget;