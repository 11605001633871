import { useState } from 'react';
import { AccountPopoverWrapper, AccountTitle, BodyText, Box, DisplayBetween, DisplayEnd, DisplayStart, Divider } from '../../Components/StyledComponents';
import FormSelect from '../../Components/FormSelect';
import CustomTooltip from '../../Components/Tooltip';
import BadgeWidget from '../../Components/BadgeWidget';
import { Link } from 'react-router-dom';
import { FaClipboardCheck, FaTimes, FaClock } from 'react-icons/fa';
import { bookmarkOptions } from '../../CommonJSON';

const NotificationData = [
    {
        iconFolder: <FaClipboardCheck size={20} color='#666' />,
        title: "Quartalseinreichung ist gemacht + PVS übermittelt. JS",
        commentedBy: "Nadine Häring",
        createdBy: "APZ APZ",
        doneBy: "APZ APZ",
        userImage: "/images/account-b.png",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#0d3f5f",
        timeAgo: "vor 22 Stunden",
        timeAgoTooltip: "05.07.2023 12:04",
        dateTime: "",
    },
    {
        iconFolder: <FaClipboardCheck size={20} color='#666' />,
        title: "Pat. 5994",
        commentedBy: "Nadine Häring",
        createdBy: "APZ APZ",
        doneBy: "APZ APZ",
        userImage: "/images/account-b.png",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#0d3f5f",
        timeAgo: "",
        timeAgoTooltip: "",
        dateTime: "06.04.2023 12:11",
    },
    {
        iconFolder: <FaClipboardCheck size={20} color='#666' />,
        title: "!!!!Dringend- Pat. 317",
        commentedBy: "Alicia Gojani",
        createdBy: "APZ APZ",
        doneBy: "APZ APZ",
        userImage: "/images/account-b.png",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#0d3f5f",
        timeAgo: "",
        timeAgoTooltip: "",
        dateTime: "06.04.2023 12:11",
    },
    {
        iconFolder: <FaClock size={20} color='#666' />,
        title: "Chef besprechen. Erinnerung !",
        commentedBy: "",
        createdBy: "Nadine Häring",
        doneBy: "",
        userImage: "/images/account-b.png",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#0d3f5f",
        timeAgo: "",
        timeAgoTooltip: "",
        dateTime: "06.04.2023 12:11",
    },
    {
        iconFolder: <FaClipboardCheck size={20} color='#666' />,
        title: "Pat. 5645",
        commentedBy: "",
        createdBy: "APZ APZ",
        doneBy: "APZ APZ",
        userImage: "/images/account-b.png",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#0d3f5f",
        timeAgo: "",
        timeAgoTooltip: "",
        dateTime: "06.04.2023 12:11",
    },
    {
        iconFolder: <FaClipboardCheck size={20} color='#666' />,
        title: "6808",
        commentedBy: "Alicia Gojani",
        createdBy: "APZ APZ",
        doneBy: "APZ APZ",
        userImage: "/images/account-b.png",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#0d3f5f",
        timeAgo: "",
        timeAgoTooltip: "",
        dateTime: "06.04.2023 12:11",
    },
]



export const NotificationPopoverContent = (props: any) => {

    const [showIcon, setShowIcon] = useState<number | null>(null);

    const handleMouseEnter = (index: any) => {
        setShowIcon(index);
    };

    const handleMouseLeave = () => {
        setShowIcon(null);
    };

    return (
        <>
            <AccountPopoverWrapper onClick={() => props.closeRef.current.setShow(false)}>
                <BodyText fontSize='16px' fontWeight='600' textColor='#000' mb='5px'> BENACHRICHTIGUNGEN </BodyText>
                <Box>
                    <FormSelect
                        isFormGroup={true}
                        selectOptions={bookmarkOptions}
                        Size="sm"
                        Name="salute"
                        Id="salute"
                    />
                </Box>
                <Divider mt='5px' mb='5px' />
                <Box className='popover-scrollable'>
                    {NotificationData.map((item, index) => {
                        return (
                            <>
                                <Box key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                                    <DisplayStart width='100%' verticalAlign="flex-start">
                                        <Box mr='10px'>
                                            {item.iconFolder}
                                        </Box>
                                        <Box>
                                            <DisplayBetween verticalAlign="flex-start">
                                                <Box>
                                                    <BodyText fontSize="16px" fontWeight="700" textColor="#0d3f5f">
                                                        <Link to="" className='text-decoration-none text-wrap'>
                                                            <span className='text-link-color'>
                                                                {item.title}
                                                            </span>
                                                        </Link>
                                                    </BodyText>
                                                    {item.createdBy ?
                                                        <>
                                                            <DisplayStart>
                                                                <Box mr='10px'>
                                                                    <img src={item.userImage} width={20} height={20} alt="notification-image" />
                                                                </Box>
                                                                <Box>
                                                                    <BodyText fontSize='16px' fontWeight='400' textColor='#000' mb='0px'> erstellt von, <span className='fw-600'> {item.createdBy} </span></BodyText>
                                                                </Box>
                                                            </DisplayStart>
                                                        </>
                                                        : null}
                                                    {item.commentedBy ?
                                                        <>
                                                            <DisplayStart>
                                                                <Box mr='10px'>
                                                                    <img src={item.userImage} width={20} height={20} alt="notification-image" />
                                                                </Box>
                                                                <Box>
                                                                    <BodyText fontSize='16px' fontWeight='400' textColor='#000'> kommentiert von, <span className='fw-600'> {item.commentedBy} </span></BodyText>
                                                                </Box>
                                                            </DisplayStart>
                                                        </>
                                                        : null}
                                                    {item.doneBy ?
                                                        <>
                                                            <DisplayStart>
                                                                <Box mr='10px'>
                                                                    <img src={item.userImage} width={20} height={20} alt="notification-image" />
                                                                </Box>
                                                                <Box>
                                                                    <BodyText fontSize='16px' fontWeight='400' textColor='#000'> erledigt von, <span className='fw-600'> {item.doneBy} </span></BodyText>
                                                                </Box>
                                                            </DisplayStart>
                                                        </>
                                                        : null}
                                                </Box>
                                                <Box>
                                                    {showIcon === (index) ?
                                                        <>
                                                            <DisplayEnd>
                                                                <Box>
                                                                    <FaTimes size={14} color='#666' />
                                                                </Box>
                                                            </DisplayEnd>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </Box>
                                            </DisplayBetween>
                                            <div className='d-flex align-items-center mt-1'>
                                                {item.timeAgo ?
                                                    <>
                                                        <CustomTooltip
                                                            ToolTipTrigger={<BodyText mb='0px' fontSize='12px' fontWeight='400' pr='8px'> {item.timeAgo}</BodyText>}
                                                            toolTipContent={item.timeAgoTooltip}
                                                            toolTipPlacement='top'
                                                            contentAlign='center'
                                                        />
                                                    </>
                                                    : null}
                                                {item.dateTime ?
                                                    <>
                                                        <BodyText mb='0px' fontSize='12px' fontWeight='400' pr='8px'> {item.dateTime}</BodyText>
                                                    </>
                                                    : null}
                                                <BadgeWidget outiline={true} title={item.badgeText} color={item.badgeTextColor} bgColor='#fff' />
                                            </div>
                                        </Box>

                                    </DisplayStart>
                                    <Divider mt='10px' mb='5px' />
                                </Box>
                            </>
                        )
                    })}
                </Box>
                <Divider mt='5px' mb='5px' />
                <BodyText fontSize="13px" fontWeight="700" textColor="#0d3f5f" className='text-center'>
                    <Link to="" className='text-decoration-none text-wrap'>
                        <span className='text-link-color'>
                            Alle Benachrichtigungen anzeigen
                        </span>
                    </Link>
                </BodyText>
            </AccountPopoverWrapper>
        </>
    )
}