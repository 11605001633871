import React, { useState, MouseEvent } from 'react';
import { Card, Table } from "react-bootstrap";
import { BodyText, Box, DisplayEnd, DisplayStart } from '../../../Components/StyledComponents';
import ButtonWidget from '../../../Components/ButtonWidget';
import Swal from 'sweetalert2';


const DashboardData = [
    {
        title: "Dashboard",
    },
    {
        title: "Dashboard",
    },
    {
        title: "Dashboard",
    },
    {
        title: "Dashboard",
    },
    {
        title: "Dashboard",
    },
    {
        title: "Dashboard",
    },
    {
        title: "Dashboard",
    },
    {
        title: "Dashboard",
    },
    {
        title: "Dashboard",
    },
    {
        title: "Dashboard",
    },
]

export const DashboardList = () => {

    const handleDeleteDashboard = () => {
        Swal.fire({
            title: 'Dieses Dashboard löschen?',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonText: `Abbrechen`,
            confirmButtonText: 'Löschen',
            customClass: {
                container: 'discardTask-alert',
                confirmButton: 'f-14px btn-danger fw-400',
                cancelButton: 'f-14px default-btn fw-400',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Verworfen!', '', 'success');
            }
        })
    }

    return (
        <>
            <div className='dashboard-list-sec'>
                <div className='dashboard-detail mb-2'>
                    <DisplayEnd>
                        <ButtonWidget text={"Dashboard hinzufügen"} varient='primary' />
                    </DisplayEnd>
                </div>
                <Card className='boxShadow'>
                    <Card.Body>
                        <Table className='mb-0' responsive>
                            <tbody>
                                {DashboardData.map((item, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td className='align-middle'>
                                                    <Box>
                                                        <BodyText fontSize="14px" fontWeight="500" textColor="#666"> {item.title} </BodyText>
                                                    </Box>
                                                </td>
                                                <td className="d-flex align-items-center justify-content-end">
                                                    <DisplayStart>
                                                        <Box className='cursor-pointer'>
                                                            <ButtonWidget class="me-2 fs-12" text={"Bearbeiten"} varient='light' />
                                                        </Box>
                                                        <Box className='cursor-pointer'>
                                                            <ButtonWidget text={"Löschen"} varient='danger' class='fs-12' handleClick={() => handleDeleteDashboard()} />
                                                        </Box>
                                                    </DisplayStart>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>

                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}