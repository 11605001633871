import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaInfoCircle } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { FaAngleRight } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CustomTooltip from '../../Components/Tooltip';

function AppointmentNotification() {
    return (
        <>
            <Card className='border-0 rounded-0 b-shadow'>
                <Card.Body>
                    <Row>
                        <Col md={1} className='pe-0'>
                            <div className='d-flex align-items-center'>
                                <FaInfoCircle size="16px" className='me-2' />
                                <span className='f-16px'>TPP</span>
                            </div>
                        </Col>
                        <Col md={7}>
                            <p className='m-0'>
                                <b>Teilnehmer bei Terminen</b>
                                <br />
                                <span>Teilnehmer hinzufügen</span><a className='text-decoration-none text-dark' href='javascript:;'> <b>Details<FaAngleRight size="14px" className='ms-1' /></b></a>
                            </p>
                        </Col>
                        <Col md={4} className="ps-0 self-center">
                            <div className='d-flex justify-content-end'>
                                <div className='me-1'>
                                    <CustomTooltip
                                        ToolTipTrigger={<Button className='py-1 light-gray-btn' variant="secondary">
                                            <FaCheck className='me-1' size="14px" />
                                            Gesehen
                                        </Button>}
                                        toolTipContent='Dieser Tipp wird nicht mehr angezeigt.'
                                        toolTipPlacement='top'
                                        contentAlign='center'
                                    />
                                </div>
                                <div>
                                    <CustomTooltip
                                        ToolTipTrigger={<Button className='py-1 light-gray-btn' variant="secondary">
                                            Weiter
                                            <FaAngleRight size="14px" className='ms-1' />
                                        </Button>}
                                        toolTipContent='Weiter!'
                                        toolTipPlacement='top'
                                        contentAlign='center'
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
}

export default AppointmentNotification;