import { FC } from "react";
import { Badge, Table } from "react-bootstrap";

type IdeasPropsType = {
    Name?: string,
    heading?: string,
    Data: any,
}

export const LatestIdeasList: FC<IdeasPropsType> = (props) => {
    return (
        <>
            <Table className={`${props.Name?.replace(/\s/g, '') + "table"}`}>
                <tbody>
                    {props.Data.map((item: any, index: number) => {
                        return (
                            <>
                                <tr className="" key={index}>
                                    <td className="p-1">
                                        <span className="fw-bold">{item.title}</span>
                                    </td>
                                    <td className="p-1 datetimecolumn">
                                        <span className="">{item.dateTime}</span>
                                    </td>
                                    <td className="p-1 text-end">
                                        {item.color === "blue" ? (
                                            <span className="blue-state"></span>
                                        ) : null}
                                        {item.color === "yellow" ? (
                                            <span className="yellow-state"></span>
                                        ) : null}
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </Table>

        </>
    )
}