import React , {useState} from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import nextId from "react-id-generator";
import Controls from './controls';
import { FaThumbtack } from 'react-icons/fa';

const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,

  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function CollapseContainer(props) {
  const [collapse_hidden, setCollapse_hidden] = useState(false);
  const [collapsed, setcollapsed] = useState(false);

  const setCollapsed = () =>  {
    setcollapsed(!collapsed)
  }



  return (  
    <div className="ds-fi ds-collapse-container" data-ds-rt="flexItem" data-ds-rid="filterItem" id="wIbK_TDPSUyxn4Meoqceug">

      <div className="ds-fi ds-collapse-container" data-ds-rt="sizePanel" data-ds-rid="sizePanel1" id="w6LCIO0JTdqaSWVB3owJBA">

        <div className={props.collapse_hidden ? "ds-collapse-bar hidden" : "ds-collapse-bar"}>
          <div className="btn-group-vertical ds-collapse-btn-group" id="w6LCIO0JTdqaSWVB3owJBA_grp">

            <div className="ds-collapse-grp-inner">
              <button className="ds-collapse-pin ds-hidden-tiny ds-hidden-none ds-hidden-tiny btn btn-default ds-pin-btn-left" onClick={setCollapsed} id="w6LCIO0JTdqaSWVB3owJBA_pin">
                <FaThumbtack className="fal fa-fw fa-thumbtack ds-i" />
              </button>
              <button className="ds-collapse-expand-btn btn btn-default ds-expand-btn-left" id="w6LCIO0JTdqaSWVB3owJBA_btn" style={{height: 796 + "px", display: "block"}}>
                <i className="fal fa-fw ds-i"></i>
              </button>
            </div>

          </div>
        </div>

        <div 
          style={{minHeight: 100 + "px", width: 202 + "px", minWidth: 0 + "px", height: 825 + "px", flex: "0 0 " + 202 + "px"}}
          className={collapsed ? "ds-sizepanel-wrapper ds-expand-height-parent ds-collapse-left ds-collapseable ds-collapsed ds-not-visible" : "ds-sizepanel-wrapper ds-expand-height-parent ds-collapse-left"}>
          <div data-ds-rt="verticalPanel" data-ds-rid="w" id="w3psEQVSSwG4assoxL3YWA">
            <div>
              <div className="ds-grid ds-grid-spacing-default" data-ds-rt="grid" data-ds-rid="verticalFilterGrid" id="wdRJlVj2TuiubZE5breEbQ">

                <Controls view={props.view}/>

              </div>
            </div>
          </div>
        </div>  

      </div>

    </div>
  );
}

export default CollapseContainer;



