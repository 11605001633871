import React, { FC, useState } from 'react';
import Card from 'react-bootstrap/Card';
import DoughnutChart from '../Charts/DoughnutChart';
import { BodyText, DisplayBetween, DisplayEnd, DisplayStart } from '../StyledComponents';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import CommonModal from '../Modals/CommonModal';
import { DeleteModuleModalContent } from '../Modals/ModelContents/DeleteModuleModalContent';
import EditStaticModuleModalContent from '../Modals/ModelContents/EditStaticModuleModalContent';
import { RiDragMove2Line } from "react-icons/ri";

interface IProps {
    isDragMode?: boolean,
    dragHandleProps?: any
}

const StatusRepairsModule: FC<IProps> = ({ dragHandleProps, isDragMode }) => {

    const [deleteModule, setDeleteModule] = useState<boolean>(false);
    const [editStaticModule, setEditStaticModule] = useState<boolean>(false);

    return (
        <>
            <Card className='border-0 rounded-0 b-shadow'>
                <Card.Header className='bg-white'>
                    <DisplayBetween>
                        <BodyText fontSize='18px' textColor='#666' fontWeight='600'> Status Reparaturen </BodyText>
                        <DisplayStart>
                            {isDragMode ?
                                <div {...dragHandleProps}>
                                    <RiDragMove2Line size="18px" color="#666" />
                                </div>
                                :
                                null
                            }
                            <DropdownButton size="sm" className="template-withouticon f-12px ms-2" id="new-item" title={<></>}>
                                <Dropdown.Item onClick={(event) => setEditStaticModule(true)}>
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Bearbeiten </BodyText>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(event) => setDeleteModule(true)}>
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Löschen </BodyText>
                                </Dropdown.Item>
                            </DropdownButton>
                        </DisplayStart>
                    </DisplayBetween>
                </Card.Header>
                <Card.Body >
                    <div className='Chart-Div'>
                        <DoughnutChart chartData={[5, 0]} chartLabel={["Nicht angenommen", "Erledigt"]} chartRadius={70} />
                    </div>
                </Card.Body>
            </Card>

            <CommonModal
                showModal={deleteModule}
                hideModal={() => setDeleteModule(false)}
                modalTitle='Dieses Modul löschen'
                modalContent={<DeleteModuleModalContent deleteModal="StatusDeleteModal" />}
                modalSize='md'
                centered={true}
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <Button className='default-btn f-14px me-1' onClick={() => setDeleteModule(false)}> Cancel </Button>
                            <Button variant="danger" className='f-14px'> Delete</Button>
                        </DisplayEnd>
                    </>
                }
            />

            <CommonModal
                showModal={editStaticModule}
                hideModal={() => setEditStaticModule(false)}
                modalTitle='Statistikmodul bearbeiten'
                modalContent={<EditStaticModuleModalContent />}
                modalSize='lg'
            />
        </>
    );
}

export default StatusRepairsModule;


