import React, { FC } from 'react';
import { Card, Dropdown, DropdownButton } from 'react-bootstrap';
import { FaClock } from 'react-icons/fa';
import { HiChatBubbleBottomCenterText } from 'react-icons/hi2';
import { HiLightBulb } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { BodyText, DisplayBetween, DisplayStart } from '../StyledComponents';
import FormCheck from '../FormCheck';
import { RiDragMove2Line } from "react-icons/ri";

interface IProps {
    isDragMode?: boolean,
    styleClass?: string;
    dragHandleProps?: any,
}



export const NotedModule: FC<IProps> = ({ styleClass, dragHandleProps, isDragMode }) => {
    return (
        <>
            <Card className={`border-0 rounded-0 b-shadow ${!!styleClass ? styleClass : ''}`}>
                <Card.Header className='bg-white'>
                    <DisplayBetween>
                        <BodyText fontSize='18px' textColor='#666' fontWeight='600'> Gemerkt </BodyText>
                        <DisplayStart>
                            {isDragMode ?
                                <div {...dragHandleProps}>
                                    <RiDragMove2Line size="18px" color="#666" />
                                </div>
                                :
                                null
                            }
                            <DropdownButton size="sm" className="template-withouticon f-12px ms-2" id="new-item" title={<></>}>
                                <Dropdown.Item >
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Bearbeiten </BodyText>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <FormCheck
                                        type='checkbox'
                                        name='phone'
                                        Id='phone'
                                        value='Automatische Höhe'
                                        label='Automatische Höhe'
                                        isFormGroup={false}
                                        isInline={false}
                                        disabled={false}
                                    />
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Löschen </BodyText>
                                </Dropdown.Item>
                            </DropdownButton>
                        </DisplayStart>
                    </DisplayBetween>
                </Card.Header>
                <Card.Body className='px-0'>
                    <div className='d-flex align-items-center justify-content-start border-bottom pb-2 px-3'>
                        <Link to="" className='text-decoration-none'>
                            <img width="20" src='/images/diaryGray.png' />
                            <span className='ms-4 fw-700 w-176px text-truncate text-link-color'>article of testing</span>
                        </Link>
                    </div>
                    <div className='d-flex align-items-center justify-content-start border-bottom py-2 px-3'>
                        <Link to="" className='text-decoration-none'>
                            <HiLightBulb className='gray-icon-color' size="20px" />
                            <span className='ms-4 fw-700 w-176px text-truncate text-link-color'>idea of marketing</span>
                        </Link>
                    </div>
                    <div className='d-flex align-items-center justify-content-start border-bottom py-2 px-3'>
                        <Link to="" className='text-decoration-none'>
                            <FaClock className='gray-icon-color' size="20px" />
                            <span className='ms-4 fw-700 w-176px text-truncate text-link-color'>message to allisia</span>
                        </Link>
                    </div>
                    <div className='d-flex align-items-center justify-content-start border-bottom py-2 px-3'>
                        <Link to="" className='text-decoration-none'>
                            <HiChatBubbleBottomCenterText className='gray-icon-color' size="20px" />
                            <span className='ms-4 fw-700 w-176px text-truncate text-link-color'>ASCJsacd</span>
                        </Link>
                    </div>
                    <div className='d-flex align-items-center justify-content-start border-bottom py-2 px-3'>
                        <Link to="" className='text-decoration-none'>
                            <HiChatBubbleBottomCenterText className='gray-icon-color' size="20px" />
                            <span className='ms-4 fw-700 w-176px text-truncate text-link-color'>OPTI-QM-Flat: </span>
                        </Link>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}
