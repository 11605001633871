import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
function DashboardActions(props: any) {
    return (
        <>
            <div className='d-flex align-items-center justify-content-end mb-3'>
                <Button className='button-red f-12px' onClick={props.resetAction}>Standardansicht wiederherstellen</Button>
                <Button className='button-blue mx-2 f-12px' onClick={props.addModule}>Modul hinzufügen</Button>
                <Button className='button-light f-12px' onClick={props.exitEdit}>Bearbeitungsmodus beenden</Button>
            </div>
        </>
    );
}

export default DashboardActions;