import { useState } from 'react';
import { AccountPopoverWrapper, AccountTitle, BodyText, Box, DisplayBetween, DisplayEnd, DisplayStart, Divider } from '../../Components/StyledComponents';
import FormSelect from '../../Components/FormSelect';
import CustomTooltip from '../../Components/Tooltip';
import BadgeWidget from '../../Components/BadgeWidget';
import { Link } from 'react-router-dom';
import { FaClipboardCheck, FaClock, FaCommentDots, FaFileAlt, FaLightbulb, FaPencilAlt, FaTimes, FaTools } from 'react-icons/fa';
import { bookmarkOptions } from '../../CommonJSON';

const BookmarkData = [
    {
        iconFolder: <FaFileAlt size={20} color='#666' />,
        title: "Die 10 Gebote unserer Serviceorientierung",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#0d3f5f",
        nameBookmark: "Dr. Sven Markus Gallenbach",
    },
    {
        iconFolder: <FaLightbulb size={20} color='#666' />,
        title: "testing idea of creating",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#0d3f5f",
        nameBookmark: "Dr. Sven Markus Gallenbach",
    },
    {
        iconFolder: <FaClock size={20} color='#666' />,
        title: "OPTI CONCEPT Coaching",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#0d3f5f",
        nameBookmark: "Emine Mollaoglu, 21.02.2023 14:41",
    },
    {
        iconFolder: <FaCommentDots size={20} color='#666' />,
        title: "OPTI-QM-Flat: Neue Dokumente",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#e4005f",
        nameBookmark: "OPTI Updates, 21.02.2023 14:41",
    },
    {
        iconFolder: <FaTools size={20} color='#666' />,
        title: "Wasserrohre defekt in Wartezimmer",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#0d3f5f",
        nameBookmark: "Dr. Sven Markus Gallenbach, 21.02.2023 14:41",
    },
    {
        iconFolder: <FaClipboardCheck size={20} color='#666' />,
        title: "5026",
        badgeText: "PRAXIS GALLENBACH & ZAHNE",
        badgeTextColor: "#0d3f5f",
        nameBookmark: " APZ APZ, 30.05.2023 13:26",
    },
]



export const NotedPopoverContent = (props: any) => {

    const [showIcon, setShowIcon] = useState<number | null>(null);


    function truncate(source: string, size: number) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }

    const handleMouseEnter = (index: any) => {
        setShowIcon(index);
    };

    const handleMouseLeave = () => {
        setShowIcon(null);
    };

    return (
        <>
            <AccountPopoverWrapper onClick={() => props.closeRef.current.setShow(false)}>
                <BodyText fontSize='16px' fontWeight='600' textColor='#000' mb='5px'> GEMERKT </BodyText>
                <Box>
                    <FormSelect
                        isFormGroup={true}
                        selectOptions={bookmarkOptions}
                        Size="sm"
                        Name="salute"
                        Id="salute"
                    />
                </Box>
                <Divider mt='5px' mb='5px' />
                <Box className='popover-scrollable'>
                    {BookmarkData.map((item, index) => {
                        return (
                            <>
                                <Box key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                                    <DisplayStart width='100%'>
                                        <Box mr='10px'>
                                            {item.iconFolder}
                                        </Box>
                                        <Box>
                                            <DisplayBetween>
                                                <Box>
                                                    <BodyText fontSize="13px" fontWeight="700" textColor="#666">
                                                        <Link to="" className='text-decoration-none'>
                                                            <span className='text-link-color'>
                                                                {truncate(item.title, 28)}
                                                            </span>
                                                        </Link>
                                                    </BodyText>
                                                </Box>
                                                <Box>
                                                    {showIcon === (index) ?
                                                        <>
                                                            <DisplayEnd>
                                                                <Box mr='10px'>
                                                                    <FaPencilAlt size={14} color='#666' />
                                                                </Box>
                                                                <Box>
                                                                    <FaTimes size={14} color='#666' />
                                                                </Box>
                                                            </DisplayEnd>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </Box>
                                            </DisplayBetween>
                                            <div className='d-flex align-items-center mt-1'>
                                                <BadgeWidget outiline={true} title={item.badgeText} color={item.badgeTextColor} bgColor='#fff' />
                                                <BodyText mb='0px' fontSize='12px' fontWeight='400' pl='5px'>{truncate(item.nameBookmark, 20)}</BodyText>
                                            </div>
                                        </Box>

                                    </DisplayStart>
                                    <Divider mt='10px' mb='5px' />
                                </Box>
                            </>
                        )
                    })}
                </Box>
            </AccountPopoverWrapper>
        </>
    )
}