import React from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from '../../FormControl';
import { Box, HighlightBox } from '../../StyledComponents';
import FormCheck from '../../FormCheck';

function ResetPasswordModalContent() {
    return (
        <>
            <Box className='mb-2'>
                <Form.Label>Altes Passwort</Form.Label>
                <FormControl isFormGroup={true} Type='password' Size='sm' Placeholder='' Name='oldPassword' Id='oldPassword' />
            </Box>
            <Box className='mb-2'>
                <Form.Label>Neues Passwort</Form.Label>
                <FormControl isFormGroup={true} Type='password' Size='sm' Placeholder='' Name='newPassword' Id='newPassword' />
            </Box>
            <Box className='mb-2'>
                <Form.Label>Passwort bestätigen</Form.Label>
                <FormControl isFormGroup={true} Type='password' Size='sm' Placeholder='' Name='confirmPassword' Id='confirmPassword' />
            </Box>
            <HighlightBox className='mb-2' bgColor='#d9edf7' borderColor='#bce8f1' textColor='#31708f'>
                Das Passwort muss beinhalten:
                <ul className='m-0'>
                    <li>mindestens 8 Zeichen</li>
                </ul>
            </HighlightBox>
            <Box>
                <FormCheck
                    type='checkbox'
                    name='confirmTerm'
                    Id='confirmTerm'
                    value=''
                    label='Ich bestätige hiermit, dass ich das Passwort nicht benutze, um mir unrechtmäßig Zugang zum Konto zu verschaffen.'
                    isFormGroup={true}
                    isInline={true}
                    disabled={false}
                />
            </Box>
        </>
    );
}

export default ResetPasswordModalContent;