import React from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import ResetPasswordDto from "../../../Model/ViewModels/ResetPassword/resetPasswordDto";
import AuthService from "../../../APIService/authService";
import toast, { Toaster } from "react-hot-toast";
import { setShowLoader } from "../../../Redux/Slice/appSlice";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

function ResetPassword() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "authToken",
    "userImage",
    "userName",
  ]);
  const authService = new AuthService();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const formSchema = yup
    .object()
    .shape({
      password: yup.string().required("Please enter Password"),
      confirmPassword: yup
        .string()
        .required("This field is required")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    .required();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
    formState,
  } = useForm<ResetPasswordDto>({
    defaultValues: new ResetPasswordDto(),
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<ResetPasswordDto> = async (
    data: ResetPasswordDto,
    event: any
  ) => {
    if (data) {
      data.email = params.get("email")!;
      data.token = params.get("token")!;
      dispatch(setShowLoader(true));
      authService
        .resetPassword(data)
        .then((result) => {
          console.log(result);
          if (result) {
            toast.success("Password reset Successfully!");
            navigate("/");
          }
          dispatch(setShowLoader(false));
        })
        .catch((error) => {
          dispatch(setShowLoader(false));
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.message);
          }
        });
    }
  };

  return (
    <>
      <Card className="shadow border-0">
        <Card.Body className="py-5">
          <div className="w-75 mx-auto mt-4">
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="password"
                  placeholder="Wachtwoord"
                  autoFocus
                  isInvalid={!!formState.errors.password}
                  {...register("password")}
                />
                {formState.errors.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="bevestig wachtwoord"
                  isInvalid={!!formState.errors.confirmPassword}
                  {...register("confirmPassword")}
                />
                {formState.errors.confirmPassword && (
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <div className="d-grid gap-2">
                <Button
                  className="btn primary-bg"
                  type="submit"
                  disabled={!formState.isValid}
                >
                  Verander wachtwoord
                </Button>
              </div>
            </Form>
            <div className="mt-4">
              <p className="m-0 text-center">
                <Link
                  className="primary-color text-decoration-none fw-500 hover-underline"
                  to="/"
                >
                  « Zurück zum Login
                </Link>
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default ResetPassword;
