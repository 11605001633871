import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, } from 'react-bootstrap';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import EditorConvertToMarkdown from '../../Components/TextEditor';
import Badge from 'react-bootstrap/Badge';
import SelectTemplateModal from '../../Components/Modals/SelectTemplateModal';
import AddTemplateModal from '../../Components/Modals/AddTemplateModal';
import Recipient from '../../Components/Modals/Recipient';
import AbortAlrt from '../../Components/Modals/AbortAlrt';
import TreeViewModal from '../../Components/Modals/TreeViewModal';
import ViewRecipient from '../../Components/ViewRecipient';
import AddMediaModal from '../../Components/Modals/AddMediaModal';
import ModelActions from '../../Components/ModelActions';
import Tagify from '../../Components/Tagify';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { ModalHeader } from '../../Components/StyledComponents';
import { truncate } from 'fs/promises';
import CategoryTemplateModal from '../../Components/Modals/ManageTemplateCategoryModal';
import ManageTemplateCategoryModal from '../../Components/Modals/ManageTemplateCategoryModal';
import ChooseTemplateCategoryModal from '../../Components/Modals/ChooseTemplateCategoryModal';
import TreeView from './TreeView';


const treeData = [
    {
        key: "0",
        label: "Documents",
        isLast: false,
        children: [
            {
                key: "0-0",
                label: "Document 1-1",
                isLast: false,
                children: [
                    {
                        key: "0-1-1",
                        label: "Document-0-1.doc",
                        isLast: true,
                    },
                    {
                        key: "0-1-2",
                        label: "Document-0-2.doc",
                        isLast: true,
                    },
                ],
            },
        ],
    },
    {
        key: "1",
        label: "Desktop",
        isLast: false,
        children: [
            {
                key: "1-0",
                label: "document1.doc",
                isLast: true,
            },
            {
                key: "0-0",
                label: "documennt-2.doc",
                isLast: true,
            },
        ],
    },
    {
        key: "2",
        label: "Downloads",
        isLast: true,
        children: [],
    },
];




interface articlePropsType {
    showArticle: boolean,
    hideArticle: () => void,
}

function AddEditArticle(props: articlePropsType) {
    // State for modals Begin
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    const [AlertModal, setAlertModal] = useState<boolean>(false);
    const [selectTemplateModal, setSelectTemplateModal] = useState<boolean>(false);
    const [AddTemplateModalShow, setAddTemplateModalShow] = useState<boolean>(false);
    const [TreeModalShow, setTreeModalShow] = useState<boolean>(false);
    const [AddMediaModalShow, setAddMediaModalShow] = useState<boolean>(false);
    const [CategoryTemplateShow, setCategoryTemplateShow] = useState<boolean>(false);
    const [chooseTempCategoryModal, setChooseTempCategoryModal] = useState<boolean>(false);
    const [manageTempCategoryModal, setManageTempCategoryModal] = useState<boolean>(false);
    const [categoryContent, setcategoryContent] = useState();
    // State for modals End

    // State for Hide Show Begin
    const [AddUser, setAddUser] = useState<boolean>(false);
    const [AllowReadingAddUser, setAllowReadingAddUser] = useState<boolean>(false);
    const [AllowEditingAddUser, setAllowEditingAddUser] = useState<boolean>(false);
    const [AllowReading, setAllowReading] = useState<boolean>(false);
    const [TextEditorShow, setTextEditorShow] = useState<boolean>(false);
    const [HideFont, setHideFont] = useState<boolean>(false);
    const [TagifyShow, setTagifyShow] = useState<boolean>(false)
    // State for Hide Show Begin

    const HandleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'on' && e.target.id === 'Individuelle Auswahl') {
            setAddUser(true);
        }
        else {
            setAddUser(false);
        }
    }
    const HandleAllowReding = () => {
        setAllowReading(!AllowReading);
    }
    const HandleFontClick = () => {
        setTextEditorShow(true);
        setHideFont(true);
    }
    const handleChooseCategoryClick = () => {
        setChooseTempCategoryModal(true);
    }
    return (
        <>

            <Modal size="lg" show={props.showArticle} onHide={props.hideArticle} backdrop="static" keyboard={false} aria-labelledby="example-modal-sizes-title-lg" >
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Artikel erstellen
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={7}>
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Control size="sm" type="text" placeholder="Titel" />
                                    </Form.Group>
                                </Col>
                                <Col md={5} className='ps-0'>
                                    <div className='d-flex align-items-center'>
                                        <DropdownButton size="sm" className="template-sort f-12px me-1 w-50" id="new-item" title="Zuletzt vemendet">
                                            <Dropdown.Item href="javascript:;">
                                                <span className='f-14px'>Standard Arbeitsanweisung</span>
                                            </Dropdown.Item>
                                        </DropdownButton>
                                        <Button className='default-btn f-12px w-50' onClick={() => setSelectTemplateModal(true)}>Vorlage auswählen</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col md={9}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Titel" />
                            </Form.Group>
                        </Col>
                        <Col md={3} className='text-end'>
                            <Button className='w-100 default-btn f-12px' onClick={() => setTemplateModalShow(true)}>Vorlage auswählen</Button>
                        </Col> */}
                        <Col md={9}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Kategorie" />
                            </Form.Group>
                        </Col>
                        <Col md={3} className='text-end'>
                            <Button className='w-100 default-btn f-12px' onClick={handleChooseCategoryClick}><FaPlusCircle className='default-btn-icon' />Alle Kategorien anzeigen</Button>
                        </Col>
                        <Col md={12}>
                            {TextEditorShow ?
                                <div className="mb-3">
                                    <EditorConvertToMarkdown />
                                </div>
                                :
                                <div className="mb-3">
                                    <Form.Control as="textarea" placeholder="Text" rows={5} />
                                </div>
                            }
                        </Col>
                        <Col md={12}>
                            <ModelActions
                                ImgClick={() => setAddMediaModalShow(true)}
                                FontClick={() => HandleFontClick()}
                                isHideFont={HideFont}
                                TagifyClick={() => setTagifyShow(!TagifyShow)}
                            />
                            {TagifyShow ?
                                <div className='my-3 tagify'>
                                    <Tagify />
                                </div>
                                : null
                            }
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Label className="mb-2">Benachrichtigungen über Erstellen und Bearbeiten an</Form.Label>
                                <div className='d-flex justify-content-between w-lg-85'>
                                    <div>
                                        <Form.Check
                                            inline
                                            label="Ersteller"
                                            name="group1"
                                            type="checkbox"
                                            id="Ersteller"
                                        />
                                        <Form.Check
                                            inline
                                            label="Bearbeiter"
                                            name="group1"
                                            type="checkbox"
                                            id="Bearbeiter"
                                        />
                                    </div>
                                    <div>
                                        <Form.Label>und zusätzlich </Form.Label>
                                    </div>
                                    <div>
                                        <Form.Check
                                            inline
                                            label="Niemand"
                                            name="group1"
                                            type="radio"
                                            id="Niemand"
                                            onChange={e => HandleRadioChange(e)}

                                        />
                                        <Form.Check
                                            inline
                                            label="Individuelle Auswahl"
                                            name="group1"
                                            type="radio"
                                            id="Individuelle Auswahl"
                                            onChange={e => HandleRadioChange(e)}
                                        />
                                        <Form.Check
                                            inline
                                            label="Alle"
                                            name="group1"
                                            type="radio"
                                            id="Alle"
                                            onChange={e => HandleRadioChange(e)}
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    {AddUser ?
                        <ViewRecipient showModel={() => setRecipientModal(true)} Palaceholder="Bitte Gruppe oder Nutzer eingeben..." BtnText="Alle anzeigen" />
                        : null
                    }
                    {AllowReading ?
                        <div>
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label className="mb-2">Lesen erlauben für</Form.Label>
                                        <div>
                                            <div className='form-check form-check-inline'>
                                                <input onChange={() => setAllowReadingAddUser(false)} type="radio" name='AllowReading' className='form-check-input me-2' value="" />
                                                <label> Alle</label>
                                            </div>
                                            <div className='form-check form-check-inline'>
                                                <input onChange={() => setAllowReadingAddUser(true)} type="radio" name='AllowReading' className='form-check-input me-2' value="" />
                                                <Badge bg="dark" className='f-12px me-2'>Admin</Badge>
                                                <label> und ...</label>
                                            </div>
                                            <div className='form-check form-check-inline'>
                                                <input type="checkbox" name='extra' className='form-check-input me-2' value="" />
                                                <label> externe Nutzer einbeziehen</label>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                {AllowReadingAddUser ?
                                    <Col md={12}>
                                        <ViewRecipient showModel={() => setRecipientModal(true)} Palaceholder="Bitte Gruppe oder Nutzer eingeben..." BtnText="Alle anzeigen" />
                                    </Col>
                                    : null
                                }

                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label className="mb-2">Bearbeiten erlauben für</Form.Label>
                                        <div>
                                            <div className='form-check form-check-inline'>
                                                <input onChange={() => setAllowEditingAddUser(false)} type="radio" name='AllowEditing' className='form-check-input me-2' value="" />
                                                <label> Alle</label>
                                            </div>
                                            <div className='form-check form-check-inline'>
                                                <input onChange={() => setAllowEditingAddUser(true)} type="radio" name='AllowEditing' className='form-check-input me-2' value="" />
                                                <Badge bg="dark" className='f-12px me-2'>Admin</Badge>
                                                <label> und ...</label>
                                            </div>
                                            <div className='form-check form-check-inline'>
                                                <input type="checkbox" name='extra' className='form-check-input me-2' value="" />
                                                <label> externe Nutzer aus ausgewählten Gruppen einbeziehen</label>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                {AllowEditingAddUser ?
                                    <Col md={12}>
                                        <ViewRecipient showModel={() => setRecipientModal(true)} Palaceholder="Bitte Gruppe oder Nutzer eingeben..." BtnText="Alle anzeigen" />
                                    </Col>
                                    : null
                                }
                            </Row>
                        </div>
                        : null
                    }

                </Modal.Body>
                <Modal.Footer className='justify-content-between footer-sticky'>
                    <div>
                        <Button className='default-btn f-14px' onClick={HandleAllowReding}>
                            {AllowReading ? <FaMinusCircle className='default-btn-icon' /> : <FaPlusCircle className='default-btn-icon' />}
                            <span>{AllowReading ? "Optionen ausblenden" : "Zugriffsrechte definieren"}</span>
                        </Button>
                    </div>
                    <div>
                        <Button className='default-btn f-14px me-1'>
                            Entwurf speichern
                        </Button>
                        <Button className='default-btn f-14px me-1' onClick={() => setAddTemplateModalShow(true)}>
                            Als Vorlage hinzufügen
                        </Button>
                        <Button className='default-btn f-14px me-1' onClick={() => setAlertModal(true)}>
                            Abbrechen
                        </Button>
                        <Button className='primary-bg f-14px'>
                            Erstellen
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <SelectTemplateModal
                displayTemplateModal={selectTemplateModal}
                hideTemplateModal={() => setSelectTemplateModal(false)}
            />
            <AddTemplateModal
                displayAddTemplateModal={AddTemplateModalShow}
                hideAddTemplateModal={() => setAddTemplateModalShow(false)}
                chooseCategoryClickHandler={() => setChooseTempCategoryModal(true)}
                recipientClickHandler={() => setRecipientModal(true)}
            />

            {/* ====== Inner Modal of Add Template modal Begin ====== */}
            <ChooseTemplateCategoryModal
                displayCategoryModal={chooseTempCategoryModal}
                hideCategoryModal={() => setChooseTempCategoryModal(false)}
                modalContent={<TreeView treeData={treeData} />}
            />
            <ManageTemplateCategoryModal
                displayCategoriesTemplateModal={manageTempCategoryModal}
                modalTitle="Vorlagenkategorien verwalten"
                hideCategoriesTemplateModal={() => setManageTempCategoryModal(false)}
            />
            {/* ====== Inner Modal of Add Template modal Begin ====== */}


            <Recipient displayRecipientModal={RecipientModal} hideRecipientModal={() => setRecipientModal(false)} />
            <AbortAlrt displayAlertModal={AlertModal} hideAlertModal={() => setAlertModal(false)} />
            {/* <TreeViewModal displayTreeModal={TreeModalShow} hideTreeModal={() => setTreeModalShow(false)} manageCategoriesClick={() => setCategoryTemplateShow(true)}/> */}
            <AddMediaModal displayAddMediaModal={AddMediaModalShow} hideAddMediaModal={() => setAddMediaModalShow(false)} />


        </>
    );
}







export default AddEditArticle;