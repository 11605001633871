import { FC } from 'react';
import Table from 'react-bootstrap/Table';

export type contextOrgType = {
    interestGroup: string,
    intrestInTheClinic: string[],
    risks: string[],
    opportunities: string[],
    measures: string[]
}


export interface IProps {
    ContextOrgData: contextOrgType[];
}

export const HandBoockOrgContext: FC<IProps> = ({ ContextOrgData }) => {
    return (
        <>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>Interessensgruppe</th>
                        <th>Interessensgruppe</th>
                        <th>Risiken</th>
                        <th>Chancen</th>
                        <th>Maßnahmen</th>
                    </tr>
                </thead>
                <tbody>
                    {ContextOrgData.map((item, index) => {
                        return <>
                            <tr key={index}>
                                <td className='align-middle'>{item.interestGroup}</td>
                                <td>
                                    <ul>
                                        {item.intrestInTheClinic.map((innerItem, innerIndex) => {
                                            return <>
                                                <li key={index + '-' + innerIndex}>{innerItem}</li>
                                            </>
                                        })}
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        {item.measures.map((innerItem, innerIndex) => {
                                            return <>
                                                <li key={index + '-' + innerIndex}>{innerItem}</li>
                                            </>
                                        })}
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        {item.opportunities.map((innerItem, innerIndex) => {
                                            return <>
                                                <li key={index + '-' + innerIndex}>{innerItem}</li>
                                            </>
                                        })}
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        {item.risks.map((innerItem, innerIndex) => {
                                            return <>
                                                <li key={index + '-' + innerIndex}>{innerItem}</li>
                                            </>
                                        })}
                                    </ul>
                                </td>
                            </tr>
                        </>
                    })}
                </tbody>
            </Table>
        </>
    )
} 
