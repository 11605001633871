import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { FaRegCalendarAlt } from "react-icons/fa";
import { Col, Form, Row } from 'react-bootstrap';
import FormControl from '../../FormControl';
import FormCheck from '../../FormCheck';
import TimePicker from '../../TimePicker';
import { Divider } from '../../StyledComponents';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

type notificationSettingProps = {
    mainCheckBox: {
        label: string,
        ischeck: boolean,
        checklist: {
            label: string,
            ischeck: boolean,
        }[],
    },
}


const notificationSetting: notificationSettingProps[] = [
    {
        mainCheckBox: {
            label: "News",
            ischeck: false,
            checklist: [
                {
                    label: "... jemand News erstellt.",
                    ischeck: false
                },
                {
                    label: "... jemand News kommentiert.",
                    ischeck: false
                },
                {
                    label: "... jemand News bearbeitet.",
                    ischeck: false
                },
                {
                    label: "... jemand dich an News erinnert.",
                    ischeck: false
                },
            ]
        }
    },
    {
        mainCheckBox: {
            label: "Kalender",
            ischeck: false,
            checklist: [
                {
                    label: "... jemand die Teilnahme zu einem Termin bestätigt oder abgelehnt hat.",
                    ischeck: false
                },
                {
                    label: "... jemand einen Termin für dich erstellt.",
                    ischeck: false
                },
                {
                    label: "... jemand einen Termin kommentiert.",
                    ischeck: false
                },
                {
                    label: "... jemand einen Termin bearbeitet.",
                    ischeck: false
                },
                {
                    label: "... Du eine Erinnerung für einen Termin erhältst.",
                    ischeck: false
                },
            ]
        }
    },
    {
        mainCheckBox: {
            label: "Ideen",
            ischeck: false,
            checklist: [
                {
                    label: "... jemand eine Idee erstellt.",
                    ischeck: false
                },
                {
                    label: "... jemand eine Idee kommentiert.",
                    ischeck: false
                },
                {
                    label: "... jemand eine Idee bearbeitet.",
                    ischeck: false
                },
                {
                    label: "... jemand dich an eine Idee erinnert.",
                    ischeck: false
                },
                {
                    label: "... der Status einer Idee geändert wird.",
                    ischeck: false
                },
            ]
        }
    },
    {
        mainCheckBox: {
            label: "Aufgaben",
            ischeck: false,
            checklist: [
                {
                    label: "... der Status einer Aufgabe geändert wurde.",
                    ischeck: false
                },
                {
                    label: "... jemand eine Aufgabe für dich erstellt.",
                    ischeck: false
                },
                {
                    label: "... jemand eine Aufgabe kommentiert.",
                    ischeck: false
                },
                {
                    label: "... jemand eine Aufgabe bearbeitet.",
                    ischeck: false
                },
                {
                    label: "... eine Aufgabe eskaliert.",
                    ischeck: false
                },
                {
                    label: "... Du eine Erinnerung für eine Aufgabe erhältst.",
                    ischeck: false
                },
            ]
        }
    },
    {
        mainCheckBox: {
            label: "Nachrichten",
            ischeck: false,
            checklist: [
                {
                    label: "...jemand dir eine Nachricht sendet.",
                    ischeck: false
                },
                {
                    label: "... jemand eine Nachricht kommentiert.",
                    ischeck: false
                },
                {
                    label: "... jemand eine Nachricht bearbeitet hat.",
                    ischeck: false
                },
                {
                    label: "...jemand dir eine Erinnerung für eine Nachricht sendet.",
                    ischeck: false
                },
            ]
        }
    },
    {
        mainCheckBox: {
            label: "Umfrage",
            ischeck: false,
            checklist: [
                {
                    label: "... jemand eine Umfrage erstellt.",
                    ischeck: false
                },
                {
                    label: "... jemand eine Umfrage ändert.",
                    ischeck: false
                },
                {
                    label: "... eine Umfrage abgeschlossen ist.",
                    ischeck: false
                },
                {
                    label: "... jemand eine Reparatur kommentiert.",
                    ischeck: false
                },
                {
                    label: "... jemand dich an eine Umfrage erinnert.",
                    ischeck: false
                },
                {
                    label: "... jemand in deiner Umfrage abgestimmt hat.",
                    ischeck: false
                },
                {
                    label: "... eine Abstimmung zurückgesetzt wurde.",
                    ischeck: false
                },
            ]
        }
    },
    {
        mainCheckBox: {
            label: "Handbuch",
            ischeck: false,
            checklist: [
                {
                    label: "... jemand einen neuen Handbuch-Artikel verfasst.",
                    ischeck: false
                },
                {
                    label: "... jemand einen Handbuch-Artikel bearbeitet.",
                    ischeck: false
                },
                {
                    label: "... jemand dich an einen Artikel erinnert.",
                    ischeck: false
                },
                {
                    label: "... zu Veränderungen von Freigaben.",
                    ischeck: false
                },
            ]
        }
    },

    {
        mainCheckBox: {
            label: "Reparaturen",
            ischeck: false,
            checklist: [
                {
                    label: "... der Status eines Auftrags geändert wird.",
                    ischeck: false
                },
                {
                    label: "... jemand eine von dir zu verteilende Reparatur erstellt.",
                    ischeck: false
                },
                {
                    label: "... jemand einen Reparaturauftrag hinzufügt.",
                    ischeck: false
                },
                {
                    label: "... jemand eine Reparatur kommentiert.",
                    ischeck: false
                },
                {
                    label: "... jemand eine von dir zu verteilende Reparatur bearbeitet.",
                    ischeck: false
                },
                {
                    label: "... jemand einen Reparaturauftrag bearbeitet.",
                    ischeck: false
                },
                {
                    label: "... jemand eine Reparatur kommentiert.",
                    ischeck: false
                },
                {
                    label: "... eine Reparatur eskaliert.",
                    ischeck: false
                },
                {
                    label: "... jemand dich an einen Reparaturauftrag erinnert.",
                    ischeck: false
                },
            ]
        }
    },
    {
        mainCheckBox: {
            label: "Moments",
            ischeck: false,
            checklist: [
                {
                    label: "... jemand dich in einem Moment erwähnt.",
                    ischeck: false
                },
                {
                    label: "... jemand auf deine Moments reagiert.",
                    ischeck: false
                },
                {
                    label: "... jemand einen Moment erstellt.",
                    ischeck: false
                },
                {
                    label: "... jemand eins deiner Moments kommentiert.",
                    ischeck: false
                },
                {
                    label: "... jemand einen Kommentar von einem deiner Moments bearbeitet.",
                    ischeck: false
                },
                {
                    label: "... eine Zusammenfassung neuer Moments während du abgemeldet warst.",
                    ischeck: false
                },
            ]
        }
    },
]

export const NotificationSettingsModalContent = () => {

    const [emailFormat, setEmailFormat] = useState<boolean>();
    const [emailNotificationSetting, setEmailNotificationSetting] = useState<typeof notificationSetting>([]);
    const [appNotificationSetting, setAppNotificationSetting] = useState<typeof notificationSetting>([]);

    useEffect(() => {
        setEmailNotificationSetting(notificationSetting);
        setAppNotificationSetting(notificationSetting);
    }, []);

    const emailNotificationSettingOuter = (index: number) => {
        let temp = [...appNotificationSetting];
        temp[index].mainCheckBox.ischeck = !temp[index].mainCheckBox.ischeck;
        for (var i = 0; i < temp[index].mainCheckBox.checklist.length; i++) {
            if (temp[index].mainCheckBox.ischeck === true) {
                temp[index].mainCheckBox.checklist[i].ischeck = true;
            } else {
                temp[index].mainCheckBox.checklist[i].ischeck = false;
            }
        }
        setEmailNotificationSetting(temp);
    }
    const emailNotificationSettingInner = (index: number, i: number) => {
        let temp = [...appNotificationSetting];
        var count = 0;
        if (temp[index].mainCheckBox.ischeck === false) {
            temp[index].mainCheckBox.checklist[i].ischeck = !temp[index].mainCheckBox.checklist[i].ischeck;
        } else {
            temp[index].mainCheckBox.ischeck = false;
            temp[index].mainCheckBox.checklist[i].ischeck = !temp[index].mainCheckBox.checklist[i].ischeck;
        }
        for (var x = 0; x < temp[index].mainCheckBox.checklist.length; x++) {
            if (temp[index].mainCheckBox.checklist[x].ischeck === true) {
                count++;
            }
        }
        if (temp[index].mainCheckBox.checklist.length === count) {
            temp[index].mainCheckBox.ischeck = true;
        }
        setEmailNotificationSetting(temp);
    }


    const appNotificationSettingOuter = (index: number) => {
        let temp = [...appNotificationSetting];
        temp[index].mainCheckBox.ischeck = !temp[index].mainCheckBox.ischeck;
        for (var i = 0; i < temp[index].mainCheckBox.checklist.length; i++) {
            if (temp[index].mainCheckBox.ischeck === true) {
                temp[index].mainCheckBox.checklist[i].ischeck = true;
            } else {
                temp[index].mainCheckBox.checklist[i].ischeck = false;
            }
        }
        setAppNotificationSetting(temp);
    }
    const appNotificationSettingInner = (index: number, i: number) => {
        let temp = [...appNotificationSetting];
        var count = 0;
        if (temp[index].mainCheckBox.ischeck === false) {
            temp[index].mainCheckBox.checklist[i].ischeck = !temp[index].mainCheckBox.checklist[i].ischeck;
        } else {
            temp[index].mainCheckBox.ischeck = false;
            temp[index].mainCheckBox.checklist[i].ischeck = !temp[index].mainCheckBox.checklist[i].ischeck;
        }
        for (var x = 0; x < temp[index].mainCheckBox.checklist.length; x++) {
            if (temp[index].mainCheckBox.checklist[x].ischeck === true) {
                count++;
            }
        }
        if (temp[index].mainCheckBox.checklist.length === count) {
            temp[index].mainCheckBox.ischeck = true;
        }
        setAppNotificationSetting(temp);
    }

    return (
        <>
            <Card className='border-0 p-0'>
                <Card.Body className='border-top-none activity-accordion p-0'>
                    <Accordion flush>
                        <Accordion.Item className='notification-setting-tab-item' eventKey="0">
                            <div className='pe-auto'>
                                <Accordion.Header className='cursor-pointer'> E-Mail-Benachrichtigungseinstellungen ausblenden </Accordion.Header>
                            </div>
                            <Accordion.Body>
                                <Row className='p-2'>
                                    <Col md={6}>
                                        <Form.Label>E-Mail-Adresse</Form.Label>
                                        <FormControl Type='text' Size='sm' Placeholder='sven.gallenbach@gmx.com' Name='emailAddress' Id='emailAddress' />
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label>E-Mail Format</Form.Label>
                                        <Form.Group controlId='Anrede' className="mb-3" style={{ height: '31px' }}>
                                            <FormCheck
                                                type='radio'
                                                name='phone'
                                                Id='phone'
                                                value='Html'
                                                label='Html'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                                checked={emailFormat === true}
                                                handleChange={() => setEmailFormat(true)}
                                            />
                                            <FormCheck
                                                type='radio'
                                                name='phone'
                                                Id='phone2'
                                                value='Text'
                                                label='Text'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                                checked={emailFormat === false}
                                                handleChange={() => setEmailFormat(false)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Divider mb='6px' />
                                    <Col md={9}>
                                        <Form.Label className="mb-2">Tägliche Zusammenfassung</Form.Label>
                                        <Form.Group controlId='Anrede' className="mb-3" style={{ height: '31px' }}>
                                            <FormCheck
                                                type='checkbox'
                                                name='phone'
                                                Id='phone'
                                                value='Sende mir eine Zusammenfassung der ungelesenen Benachrichtigungen täglich um'
                                                label='Sende mir eine Zusammenfassung der ungelesenen Benachrichtigungen täglich um'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} className='align-self-center'>
                                        <TimePicker />
                                    </Col>
                                    <Divider mb='6px' />
                                    <Col md={12}>
                                        <Form.Label>Auslöser für Benachrichtigungen</Form.Label>
                                        <Form.Group controlId='Anrede' className="mb-2" style={{ height: '31px' }}>
                                            <Form.Label className='me-3'> Sende eine E-Mail wenn </Form.Label>
                                            <FormCheck
                                                type='radio'
                                                name='group1'
                                                Id='group1'
                                                value='Bei jeder Benachrichtigung'
                                                label='Bei jeder Benachrichtigung'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                            />
                                            <FormCheck
                                                type='radio'
                                                name='group1'
                                                Id='group1'
                                                value='Nie'
                                                label='Nie'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                            />
                                            <FormCheck
                                                type='radio'
                                                name='group1'
                                                Id='group1'
                                                value='Anpassen'
                                                label='Anpassen'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {emailNotificationSetting?.map((item, index) => {
                                        return (
                                            <>
                                                <Col md={6}>
                                                    <FormCheck
                                                        type='checkbox'
                                                        name='phone'
                                                        Id='phone'
                                                        value={item.mainCheckBox.label}
                                                        label={item.mainCheckBox.label}
                                                        isFormGroup={false}
                                                        isInline={true}
                                                        handleChange={() => emailNotificationSettingOuter(index)}
                                                        checked={item.mainCheckBox.ischeck}

                                                    />
                                                    <ul className='list-unstyled ps-3 mb-0'>
                                                        {item.mainCheckBox.checklist.map((x, i) => {
                                                            return (
                                                                <li key={i}>
                                                                    <FormCheck
                                                                        type='checkbox'
                                                                        name='phone'
                                                                        Id='phone'
                                                                        value={x.label}
                                                                        label={x.label}
                                                                        isFormGroup={false}
                                                                        isInline={true}
                                                                        handleChange={() => emailNotificationSettingInner(index, i)}
                                                                        checked={x.ischeck}
                                                                    />
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </Col>

                                            </>
                                        )
                                    })}
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='notification-setting-tab-item mt-2 mb-3' eventKey="1">
                            <div>
                                <Accordion.Header>App Benachrichtigungseinstellungen einblenden</Accordion.Header>
                            </div>
                            <Accordion.Body>
                                <Row className='p-2'>
                                    <Col md={12}>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Group controlId='Anrede' className="mb-3" style={{ height: '31px' }}>
                                            <FormCheck
                                                // handleChange={handleFormCheck}
                                                type='radio'
                                                name='group2'
                                                Id='group2'
                                                value='An'
                                                label='An'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                            // checked={salutation === 'Weiblich' ? true : false}
                                            />
                                            <FormCheck
                                                // handleChange={handleFormCheck}
                                                type='radio'
                                                name='group2'
                                                Id='group2'
                                                value='Aus'
                                                label='Aus'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                            // checked={salutation === 'Männlich' ? true : false}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Divider mb='6px' />
                                    <Col md={12}>
                                        <h6 className='fw-bolder'>Zeitraum</h6>
                                        <Form.Label className="mb-2">Wann möchtest du App-Benachrichtigungen erhalten?</Form.Label>
                                        <Form.Group controlId='Anrede' className="mb-3" style={{ height: '31px' }}>
                                            <FormCheck
                                                type='radio'
                                                name='group3'
                                                Id='group3'
                                                value='Jederzeit'
                                                label='Jederzeit'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                            />
                                            <div className='d-flex align-items-center'>
                                                <FormCheck
                                                    type='radio'
                                                    name='group3'
                                                    Id='group3'
                                                    value='Von'
                                                    label='Von'
                                                    isFormGroup={false}
                                                    isInline={true}
                                                    disabled={false}
                                                />
                                                <div className='w-15'>
                                                    <TimePicker />
                                                </div>
                                                <div>
                                                    <span className='mx-2'>bis</span>
                                                </div>
                                                <div className='w-15'>
                                                    <TimePicker />
                                                </div>
                                                <div>
                                                    <span className='mx-2'>am</span>
                                                </div>
                                                <div>
                                                    <ToggleButtonList />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Divider mb='6px' mt='25px' />
                                    <Col md={12}>
                                        <Form.Label>Auslöser für Benachrichtigungen</Form.Label>
                                        <Form.Group controlId='Anrede' className="mb-2" style={{ height: '31px' }}>
                                            <Form.Label className='me-3'> Sende eine E-Mail wenn </Form.Label>
                                            <FormCheck
                                                type='radio'
                                                name='group4'
                                                Id='group4'
                                                value='Bei jeder Benachrichtigung'
                                                label='Bei jeder Benachrichtigung'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                            />
                                            <FormCheck
                                                type='radio'
                                                name='group4'
                                                Id='group4'
                                                value='Nie'
                                                label='Nie'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                            />
                                            <FormCheck
                                                type='radio'
                                                name='group4'
                                                Id='group4'
                                                value='Anpassen'
                                                label='Anpassen'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {appNotificationSetting.map((item, index) => {
                                        return (
                                            <>
                                                <Col md={6}>
                                                    <FormCheck
                                                        type='checkbox'
                                                        name='phone'
                                                        Id='phone'
                                                        value={item.mainCheckBox.label}
                                                        label={item.mainCheckBox.label}
                                                        isFormGroup={false}
                                                        isInline={true}
                                                        handleChange={() => appNotificationSettingOuter(index)}
                                                        checked={item.mainCheckBox.ischeck}
                                                    />
                                                    <ul className='list-unstyled ps-3 mb-0'>
                                                        {item.mainCheckBox.checklist.map((x, i) => {
                                                            return (
                                                                <li key={i}>
                                                                    <FormCheck
                                                                        type='checkbox'
                                                                        name='phone'
                                                                        Id='phone'
                                                                        value={x.label}
                                                                        label={x.label}
                                                                        isFormGroup={false}
                                                                        isInline={true}
                                                                        handleChange={() => appNotificationSettingInner(index, i)}
                                                                        checked={x.ischeck}
                                                                    />
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </Col>

                                            </>
                                        )
                                    })}
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </Card.Body>
            </Card>
        </>
    )
}


export const ToggleButtonList = () => {

    const [value, setValue] = useState([7, 8]);
    const handleChange = (val: any) => setValue(val);

    return (
        <>
            <ToggleButtonGroup type="checkbox" value={value} onChange={handleChange}>
                <ToggleButton id="tbg-btn-1" value={1}>
                    Mo
                </ToggleButton>
                <ToggleButton id="tbg-btn-2" value={2}>
                    Di
                </ToggleButton>
                <ToggleButton id="tbg-btn-3" value={3}>
                    Mi
                </ToggleButton>
                <ToggleButton id="tbg-btn-4" value={4}>
                    Mi
                </ToggleButton>
                <ToggleButton id="tbg-btn-5" value={5}>
                    Do
                </ToggleButton>
                <ToggleButton id="tbg-btn-6" value={6}>
                    Fr
                </ToggleButton>
                <ToggleButton id="tbg-btn-7" value={7}>
                    Sa
                </ToggleButton>
                <ToggleButton id="tbg-btn-8" value={8}>
                    So
                </ToggleButton>
            </ToggleButtonGroup>
        </>
    )
}