import * as React from 'react';
import { useState, useEffect } from 'react';
import { AnchorLink, filtesListType } from '../../../../Pages/mycalendar/left/SaveCurrentFilter/SaveFilter';
import FormControl from '../../../FormControl';
import FormCheck from '../../../FormCheck';




interface Iprops {
    setFiltersList: React.Dispatch<React.SetStateAction<filtesListType[]>>
    data: filtesListType[]
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}


const ManageSavedFilterContent: React.FC<Iprops> = ({ setFiltersList, data, closeModal }) => {
    const [saveDefaultFilter, setsaveDefaultFilter] = useState<boolean>(false);
    const [title, settitle] = useState<string>("");
    const [selectedItem, setSelectedItem] = useState<filtesListType>({ title: "", isChecked: false });
    useEffect(() => {
        debugger
        if(selectedItem.title !==  ""){
            settitle(selectedItem.title);
            setsaveDefaultFilter(selectedItem.isChecked);
        }
    }, [selectedItem]);

    return (
        <>
            <div className='row'>
                <div className='col-md-6 border-end'>
                    <div className='ms-2 mb-2'>
                        {data.map((x, i) => <AnchorLink fs={14} onClick={() => setSelectedItem(x)} className='text-start' href="javascript:;">{x.title} {i === 0 && "*"}</AnchorLink>)}
                    </div>
                </div>
                <div className='col-md-6'>
                   {selectedItem.title !== "" ?  <div>
                        <FormControl Value={title} handleChange={(e) => settitle(e.target.value)} Placeholder='Title...' isFormGroup={false} Type='text' Size='sm' Name={'subtaskItem-input'} Id={'subtaskItem-input'} />
                        <div className='mt-2 d-flex align-items-center justify-content-between'>
                            <FormCheck
                                handleChange={(event) => setsaveDefaultFilter(event.target.checked)}
                                type='checkbox'
                                name='saveDefaultFilter'
                                Id='saveDefaultFilter'
                                value='saveDefaultFilter'
                                label='Save as default filter*'
                                isFormGroup={true}
                                isInline={true}
                                disabled={false}
                                checked={saveDefaultFilter}
                            />
                            <div>
                                <button className='btn btn-danger primary-danger me-2' onClick={() => { closeModal(false) }}>Delete</button>
                                <button className='btn btn-primary primary-bg' onClick={() => { setFiltersList([...data, { title: title, isChecked: saveDefaultFilter }]); closeModal(false) }}>Save</button>

                            </div>
                        </div>
                    </div> : <div>Please select a saved filter...</div>
                    }
                </div>
            </div>
        </>
    );
}

export default ManageSavedFilterContent;