import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Sidebar from '../Message/SideBar';
import { MainContent } from './MainContent';
import { TopbarSelectFilterDropdown } from '../../Components/TopbarSelectFilterDropdown';
import { TdisplayMode } from '../Message/Index';
import { Outlet } from 'react-router-dom';


type taskStatusType = {
    open: boolean,
    done: boolean,
    inProgress: boolean,
    discard: boolean,
    remind: boolean,
}


export interface ITaskData {
    title: string,
    badgeOutline: string,
    profileImg: string,
    userName1: string,
    userNameTooltipContent1: string,
    userName2: string,
    userNameTooltipContent2: string,
    badgeRoleColor1: string,
    badgeRoleTitle1: string,
    badgeRoleTooltipContent1: string,
    badgeRoleColor2: string,
    badgeRoleTitle2: string,
    badgeRoleTooltipContent2: string,
    color: string,
    labelText: string,
    labelBorderColor: string,
    limitedVisibilty: boolean,
    limitedVisibiltyTooltip: string,
    attachment: boolean,
    attachmentTooltip: string,
    doneIcon: boolean,
    doneIconTooltip: string,
    recipientList: {
        profileImg?: string,
        userName: string,
        userTooltipText: string,
        taskAssignTime: string,
        taskStatus: taskStatusType,
    }[],
    comment: string,
    commentTooltip: string,
    prioritySubtitle: string,
    taskCreatedTime: string,
    taskCreatedTimeTooltip: string,
    list?: string,
    changeStatus: boolean,
}

const TaskData = [
    {
        title: "5026",
        badgeOutline: "Praxis Gallenbach & Zähne",
        profileImg: '',
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "",
        userNameTooltipContent2: "",
        badgeRoleColor1: "#d4f2f7",
        badgeRoleTitle1: "Verwaltung",
        badgeRoleTooltipContent1: "Alicia Gojani, Nadine Häring",
        badgeRoleColor2: "#d4f2f7",
        badgeRoleTitle2: "Zahnärzte",
        badgeRoleTooltipContent2: "Dr. Sven Markus Gallenbach, ZA Robert Stühn",
        color: "#fc9136",
        labelText: 'Offen',
        labelBorderColor: '#fc9136',
        recipientList: [
            {
                profileImg: "/images/account-b.png",
                userName: "Dr. Sven Markus Gallenbach",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account-b.png",
                userName: "Nadine Häring",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "Alicia Gojani",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "ZA Robert Stühn",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
        ],
        comment: "1",
        commentTooltip: "Ein Kommentar",
        limitedVisibilty: true,
        limitedVisibiltyTooltip: "Sichtbarkeit eingeschränkt",
        attachment: false,
        attachmentTooltip: "",
        doneIcon: false,
        doneIconTooltip: "",
        prioritySubtitle: "Normal",
        taskCreatedTime: "30.05.2023 12:40 Pakistan Standard Time",
        taskCreatedTimeTooltip: "Erstellt: 30.05.2023 12:40",
        list: 'Various',
        changeStatus: false,
    },
    {
        title: "6775",
        badgeOutline: "Praxis Gallenbach & Zähne",
        profileImg: '',
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "",
        userNameTooltipContent2: "",
        badgeRoleColor1: "#d4f2f7",
        badgeRoleTitle1: "Verwaltung",
        badgeRoleTooltipContent1: "Alicia Gojani, Nadine Häring",
        badgeRoleColor2: "#d4f2f7",
        badgeRoleTitle2: "Zahnärzte",
        badgeRoleTooltipContent2: "Dr. Sven Markus Gallenbach, ZA Robert Stühn",
        color: "#fc9136",
        labelText: 'Offen',
        labelBorderColor: '#fc9136',
        limitedVisibilty: false,
        limitedVisibiltyTooltip: "",
        attachment: false,
        attachmentTooltip: "",
        doneIcon: false,
        doneIconTooltip: "",
        recipientList: [
            {
                profileImg: "/images/account-b.png",
                userName: "Dr. Sven Markus Gallenbach",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account-b.png",
                userName: "Nadine Häring",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "Alicia Gojani",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "ZA Robert Stühn",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
        ],
        comment: "1",
        commentTooltip: "Ein Kommentar",
        prioritySubtitle: "Normal",
        taskCreatedTime: "30.05.2023 12:40 Pakistan Standard Time",
        taskCreatedTimeTooltip: "Erstellt: 30.05.2023 12:40",
        list: 'Various',
        changeStatus: false,
    },
    {
        title: "6686",
        badgeOutline: "Praxis Gallenbach & Zähne",
        profileImg: '',
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "",
        userNameTooltipContent2: "",
        badgeRoleColor1: "#d4f2f7",
        badgeRoleTitle1: "Verwaltung",
        badgeRoleTooltipContent1: "Alicia Gojani, Nadine Häring",
        badgeRoleColor2: "#d4f2f7",
        badgeRoleTitle2: "Zahnärzte",
        badgeRoleTooltipContent2: "Dr. Sven Markus Gallenbach, ZA Robert Stühn",
        color: "#fc9136",
        labelText: 'Offen',
        labelBorderColor: '#fc9136',
        limitedVisibilty: false,
        limitedVisibiltyTooltip: "",
        attachment: false,
        attachmentTooltip: "",
        doneIcon: false,
        doneIconTooltip: "",
        recipientList: [
            {
                profileImg: "/images/account-b.png",
                userName: "Dr. Sven Markus Gallenbach",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account-b.png",
                userName: "Nadine Häring",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "Alicia Gojani",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "ZA Robert Stühn",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
        ],
        comment: "1",
        commentTooltip: "Ein Kommentar",
        prioritySubtitle: "Normal",
        taskCreatedTime: "30.05.2023 12:40 Pakistan Standard Time",
        taskCreatedTimeTooltip: "Erstellt: 30.05.2023 11:50",
        list: 'Various',
        changeStatus: false,
    },
    {
        title: "PVS übermittelt. JS",
        badgeOutline: "Praxis Gallenbach & Zähne",
        profileImg: '',
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "",
        userNameTooltipContent2: "",
        badgeRoleColor1: "#d4f2f7",
        badgeRoleTitle1: "Verwaltung",
        badgeRoleTooltipContent1: "Alicia Gojani, Nadine Häring",
        badgeRoleColor2: "#d4f2f7",
        badgeRoleTitle2: "Zahnärzte",
        badgeRoleTooltipContent2: "Dr. Sven Markus Gallenbach, ZA Robert Stühn",
        color: "#fc9136",
        labelText: 'Offen',
        labelBorderColor: '#fc9136',
        limitedVisibilty: true,
        limitedVisibiltyTooltip: "Sichtbarkeit eingeschränkt",
        attachment: false,
        attachmentTooltip: "",
        doneIcon: false,
        doneIconTooltip: "",
        recipientList: [
            {
                profileImg: "/images/account-b.png",
                userName: "Dr. Sven Markus Gallenbach",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account-b.png",
                userName: "Nadine Häring",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "Alicia Gojani",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "ZA Robert Stühn",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
        ],
        comment: "",
        commentTooltip: "",
        prioritySubtitle: "Normal",
        taskCreatedTime: "30.05.2023 12:40 Pakistan Standard Time",
        taskCreatedTimeTooltip: "Erstellt: 30.05.2023 11:50",
        list: 'Various',
        changeStatus: false,
    },
    {
        title: "6900",
        badgeOutline: "Praxis Gallenbach & Zähne",
        profileImg: '',
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "",
        userNameTooltipContent2: "",
        badgeRoleColor1: "#d4f2f7",
        badgeRoleTitle1: "Verwaltung",
        badgeRoleTooltipContent1: "Alicia Gojani, Nadine Häring",
        badgeRoleColor2: "#d4f2f7",
        badgeRoleTitle2: "Zahnärzte",
        badgeRoleTooltipContent2: "Dr. Sven Markus Gallenbach, ZA Robert Stühn",
        color: "#888",
        labelText: 'Nicht angenommen',
        labelBorderColor: '#888',
        limitedVisibilty: true,
        limitedVisibiltyTooltip: "Sichtbarkeit eingeschränkt",
        attachment: false,
        attachmentTooltip: "",
        doneIcon: true,
        doneIconTooltip: "0/9 erledigt",
        recipientList: [
            {
                profileImg: "/images/account-b.png",
                userName: "Dr. Sven Markus Gallenbach",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account-b.png",
                userName: "Nadine Häring",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "Alicia Gojani",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "ZA Robert Stühn",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
        ],
        comment: "",
        commentTooltip: "",
        prioritySubtitle: "Normal",
        taskCreatedTime: "30.05.2023 12:40 Pakistan Standard Time",
        taskCreatedTimeTooltip: "Erstellt: 30.05.2023 11:50",
        list: 'Various',
        changeStatus: false,
    },
    {
        title: "6900",
        badgeOutline: "Praxis Gallenbach & Zähne",
        profileImg: '',
        userName1: "APZ APZ",
        userNameTooltipContent1: "Praxis Gallenbach & Zähne",
        userName2: "",
        userNameTooltipContent2: "",
        badgeRoleColor1: "#d4f2f7",
        badgeRoleTitle1: "Verwaltung",
        badgeRoleTooltipContent1: "Alicia Gojani, Nadine Häring",
        badgeRoleColor2: "#d4f2f7",
        badgeRoleTitle2: "Zahnärzte",
        badgeRoleTooltipContent2: "Dr. Sven Markus Gallenbach, ZA Robert Stühn",
        color: "#888",
        labelText: 'Nicht angenommen',
        labelBorderColor: '#888',
        limitedVisibilty: true,
        limitedVisibiltyTooltip: "Sichtbarkeit eingeschränkt",
        attachment: false,
        attachmentTooltip: "",
        doneIcon: false,
        doneIconTooltip: "",
        recipientList: [
            {
                profileImg: "/images/account-b.png",
                userName: "Dr. Sven Markus Gallenbach",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account-b.png",
                userName: "Nadine Häring",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "Alicia Gojani",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
            {
                profileImg: "/images/account.png",
                userName: "ZA Robert Stühn",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "30.05.2023 12:40 Pakistan Standard Time",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
        ],
        comment: "1",
        commentTooltip: "Ein Kommentar",
        prioritySubtitle: "Normal",
        taskCreatedTime: "30.05.2023 12:40 Pakistan Standard Time",
        taskCreatedTimeTooltip: "Erstellt: 30.05.2023 11:50",
        list: 'Various',
        changeStatus: false,
    },
    {
        title: "testing task",
        badgeOutline: "Praxis Gallenbach & Zähne",
        profileImg: '',
        userName1: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent1: "Zahnarzt - Praxis Gallenbach & Zähne",
        userName2: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent2: "Zahnarzt - Praxis Gallenbach & Zähne",
        badgeRoleColor1: "",
        badgeRoleTitle1: "",
        badgeRoleTooltipContent1: "",
        badgeRoleColor2: "",
        badgeRoleTitle2: "",
        badgeRoleTooltipContent2: "Dr. Sven Markus Gallenbach, ZA Robert Stühn",
        color: "#04a0a0",
        labelText: 'In Arbeit',
        labelBorderColor: '#04a0a0',
        limitedVisibilty: false,
        limitedVisibiltyTooltip: "",
        attachment: true,
        attachmentTooltip: "Anhang",
        doneIcon: true,
        doneIconTooltip: "3/9 erledigt",
        comment: "",
        recipientList: [
            {
                profileImg: "/images/account-b.png",
                userName: "Dr. Sven Markus Gallenbach",
                userTooltipText: "Zahnarzt - Praxis Gallenbach & Zähne",
                taskAssignTime: "25.11.2022 14:34",
                taskStatus: {
                    open: true,
                    done: false,
                    inProgress: false,
                    discard: false,
                    remind: false,
                },
            },
        ],
        commentTooltip: "",
        prioritySubtitle: "Normal",
        taskCreatedTime: "30.05.2023 12:40 Pakistan Standard Time",
        taskCreatedTimeTooltip: "Erstellt: 30.05.2023 11:50",
        list: 'Various',
        changeStatus: false,
    },
]

export const TaskIndex = () => {
    const [taskData, setTaskData] = useState<ITaskData[]>([]);
    const [displayMode, setDisplayMode] = useState<TdisplayMode>({ displayNormal: true, displayCompact: false, displaySplit: false });

    const handleDisplayMode = (event: React.MouseEvent) => {
        var valueAttr = event.currentTarget.attributes.getNamedItem('value');
        var value = valueAttr ? valueAttr.value : null;
        if (value === 'normal') {
            var temp: TdisplayMode = { ...displayMode };
            temp.displayNormal = true;
            temp.displayCompact = false;
            temp.displaySplit = false;

            setDisplayMode(temp);

        } else {
            var temp: TdisplayMode = { ...displayMode };
            temp.displayCompact = true;
            temp.displayNormal = false;
            temp.displaySplit = false;

            setDisplayMode(temp);
        }
    }
    useEffect(() => {
        setTaskData(TaskData);
    }, []);

    return (
        <>
            <Row>
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <div className="task-topbar mb-2">
                        <TopbarSelectFilterDropdown
                            showHideEntryCheck={undefined}
                            showChecks={undefined}
                            checkAllEntries={undefined}
                            handleSelectAll={undefined}
                            displayMode={displayMode}
                            handleDisplayMode={handleDisplayMode}
                            totalAccount={undefined}
                        />
                    </div>
                    <Outlet context={[taskData, setTaskData, displayMode]} />
                </Col>
            </Row>
        </>
    );
}
