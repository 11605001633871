import React, { useState, useEffect, forwardRef } from 'react';
import PatientSelect, { BeschreibungSelect, ColorSelect } from './patientselect';
import DatePicker from 'react-datepicker';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import Select, { StylesConfig } from 'react-select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setRerenderCalendar } from '../../../Redux/Slice/calendarSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheck, FaCut, FaTimes, FaTrash } from 'react-icons/fa';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({ ...styles, ...dot(data.color) }),
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const Labels = styled.label`
font-size: 11px;
color: #aaa;
`
function AddEditForm(props) {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let appFormData = useSelector(state => state.calendar.appointmentFormData);
    const [cookies, setCookie, removeCookie] = useCookies(['appToken', 'fromEditMode', 'appointmentId', 'reCallApis', 'reCallCalendarApis']);

    const [practitionerDDL, setPractitionerDDL] = useState([]);
    const [otherPractitionerDDL, setOtherPractitionerDDL] = useState([]);
    const [recallDDL, setRecallDDL] = useState([]);
    const [categoryDDL, setCategoryDDL] = useState([]);
    const [colorDDL, setColorDDL] = useState([]);
    const [roomDDL, setRoomDDL] = useState([]);
    const [statusDDL, setStatusDDL] = useState([]);
    const [timeDDL, setTimeDDL] = useState([]);
    const [alarmDDL, setAlarmDDL] = useState([]);
    const [descriptionDDL, setDescriptionDDL] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [description, setDescription] = useState("");
    const [unKnownDDL, setUnKnownDDL] = useState([]);
    const [formData, setFormData] = useState({});
    const [getOtherPractitiionerIds, setGetOtherPractitiionerIds] = useState({});
    const [closed, setClosed] = useState("");
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (props.open) {
            pageData();
        }
    }, [props.open]);

    useEffect(() => {
        if (cookies.fromEditMode === "true") {
            setEditMode(true);
        }
    }, [cookies.fromEditMode]);

    const pageData = async () => {
        // let formDropdownUrl = "online-terminbuch/getFormDropdownData";
        // let res = await dynamicGetApi(formDropdownUrl, navigate, setCookie);
        // let formDesDropdownUrl = "online-terminbuch/getDescriptionDropdownData";
        // let result = await dynamicGetApi(formDesDropdownUrl, navigate, setCookie);
        // let desList = result.data;
        // let desArr = [];
        // desList.map((v) => {
        //     desArr.push({ name: v.test, color: v.color, colorName: v.color, time: v.time });
        // })
        // setDescriptionDDL(desArr);
        // //Practitiion
        // let practitionerList = res.data.filter(x => x.type === "Practitioner");
        // let practitionerArr = [];
        // practitionerList.map((v) => {
        //     practitionerArr.push({ value: v.value, label: v.label });
        // })
        // setPractitionerDDL(practitionerArr);
        // setOtherPractitionerDDL(practitionerArr);
        // //Recall
        // let recallList = res.data.filter(x => x.type === "Recall");
        // let recallArr = [];
        // recallList.map((v) => {
        //     recallArr.push({ value: v.value, label: v.label });
        // })
        // setRecallDDL(recallArr);
        // //Category
        // let categoryList = res.data.filter(x => x.type === "Category");
        // let categoryArr = [];
        // categoryList.map((v) => {
        //     categoryArr.push({ value: v.value, label: v.label });
        // })
        // setCategoryDDL(categoryArr);
        // //Color
        // let colorList = res.data.filter(x => x.type === "Color");
        // let colorArr = [];
        // colorList.map((v) => {
        //     colorArr.push({ value: v.value, label: v.label, color: v.label });
        // })
        // setColorDDL(colorArr);
        // //Room
        // let roomList = res.data.filter(x => x.type === "Room");
        // let roomArr = [];
        // roomList.map((v) => {
        //     roomArr.push({ value: v.value, label: v.label });
        // })
        // setRoomDDL(roomArr);
        // //Status
        // let statusList = res.data.filter(x => x.type === "Status");
        // let statusArr = [];
        // statusList.map((v) => {
        //     statusArr.push({ value: v.value, label: v.label });
        // })
        // setStatusDDL(statusArr);
        // //Time
        // let timeList = res.data.filter(x => x.type === "Time");
        // let timeArr = [];
        // timeList.map((v) => {
        //     timeArr.push({ value: v.value, label: v.label });
        // })
        // setTimeDDL(timeArr);
        // //Time
        // let alarmList = res.data.filter(x => x.type === "Alarm");
        // let alarmArr = [];
        // alarmList.map((v) => {
        //     alarmArr.push({ value: v.value, label: v.label });
        // })
        // setAlarmDDL(alarmArr);
        // //UnKnown
        // let unKnownList = res.data.filter(x => x.type === "Code");
        // let unKnownArr = [];
        // unKnownList.map((v) => {
        //     unKnownArr.push({ value: v.value, label: v.label });
        // })
        // setUnKnownDDL(unKnownArr);
    }
    useEffect(async () => {
        if (editMode) {
            // let formDataUrl = "online-terminbuch/editAppointment?apId=" + parseInt(cookies.appointmentId);
            // let response = await dynamicGetApi(formDataUrl, navigate, setCookie);
            // setFormData(response.data);
        }
    }, [editMode]);

    const formik = useFormik({
        initialValues: {
            id: null,
            patientId: null,
            practitionerId: null,
            otherPractitionerIds: '',
            recallId: null,
            description: '',
            categoryId: null,
            colorId: null,
            roomId: null,
            bookingDate: new Date(),
            startTime: new Date(),
            endTime: new Date(),
            duration: null,
            preProcessing: null,
            postProcessing:null,
            statusId: null,
            sms_Erinnerung: false,
            phoneNumber: '',
            reminder1: false,
            reminder1TimeId: null,
            reminder1Date: new Date(),
            reminder1Time: new Date(),
            reminder2: false,
            reminder2TimeId: null,
            reminder2Date: new Date(),
            reminder2Time: new Date(),
            alarmId: null,
            unKnownId: null,
            programStart: false,
            appointmentStart: false,
            hourBefore: false,
            fiveMinutesBefore: false,
            dayBefore: '',
            bookingNote: ''
        },
        onSubmit: async values => {
            // await dynamicPostApi(values, "online-terminbuch/createAppointment", navigate, setCookie)
            onClose();
            dispatch(setRerenderCalendar(true))
        },
    });

    const handleChange = (e, name) => {
        if (name === "practitionerId") {
            let available = practitionerDDL.filter(x => x.value !== e);
            setOtherPractitionerDDL(available);
            formik.setFieldValue(name, e)
            if (Object.keys(getOtherPractitiionerIds).length > 0) {
                let otherPractitionerCheck = getOtherPractitiionerIds.find(x => x.value === e);
                if (!!otherPractitionerCheck) {
                    setGetOtherPractitiionerIds(getOtherPractitiionerIds.filter(x => x.value !== otherPractitionerCheck.value));
                }
            }
        }
        if (name === "otherPractitionerIds") {
            let selectedValues = [];
            e.map((v) => { selectedValues.push(v.value) });
            formik.setFieldValue(name, selectedValues.toString());
            let selectedOtherPractitiionerIdsArr = [];
            e.map((x) => selectedOtherPractitiionerIdsArr.push(x));
            setGetOtherPractitiionerIds(selectedOtherPractitiionerIdsArr);
        }
        if (name === "recallId") {
            formik.setFieldValue(name, e);
        }
        if (name === "categoryId") {
            formik.setFieldValue(name, e);
        }
        if (name === "colorId") {
            formik.setFieldValue(name, e);
        }
        if (name === "roomId") {
            formik.setFieldValue(name, e);
        }
        if (name === "statusId") {
            formik.setFieldValue(name, e);
        }
        if (name === "reminder1TimeId") {
            formik.setFieldValue(name, e);
        }
        if (name === "reminder2TimeId") {
            formik.setFieldValue(name, e);
        }
        if (name === "alarmId") {
            formik.setFieldValue(name, e);
        }
        if (name === "unKnownId") {
            formik.setFieldValue(name, e);
        }
        if (name === "bookingDate") {
            formik.setFieldValue(name, e);
        }
        if (name === "startTime") {
            formik.setFieldValue(name, e);
        }
        if (name === "endTime") {
            if (!!formik.values.startTime && e > formik.values.startTime) {
                let end = e;
                let start = formik.values.startTime;
                var diffMs = (end - start); // milliseconds between start & end
                var diffMins;
                if (diffMs !== 3600000) {
                    diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                }
                else {
                    diffMins = 60;
                }
                formik.setFieldValue(name, e);
                formik.setFieldValue("duration", diffMins);
                formik.setFieldValue("preProcessing", "");
                formik.setFieldValue("postProcessing", "");
            }
            else {
                alert("test")
            }
        }
        if (name === "reminder1Date") {
            formik.setFieldValue(name, e);
        }
        if (name === "reminder1Time") {
            formik.setFieldValue(name, e);
        }
        if (name === "reminder2Date") {
            formik.setFieldValue(name, e);
        }
        if (name === "reminder2Time") {
            formik.setFieldValue(name, e);
        }
        if (name === "preProcessing") {
            let value = e.target.value;
            let preVal = formik.values.preProcessing === null || formik.values.preProcessing === "" ? 0:parseInt(formik.values.preProcessing);
            if (!!value && parseInt(value) < parseInt(formik.values.duration)) {
                    formik.setFieldValue("duration", ((parseInt(formik.values.duration) + preVal) - parseInt(value)).toString());
                    formik.setFieldValue(name, value);
            }
            else{
                if(parseInt(value) < parseInt(formik.values.duration)){
                    formik.setFieldValue(name, value);
                }
                else{
                    toast("Pre processing should not null or greater than duration.");
                }
            }
        }
        if (name === "postProcessing") {
            let value = e.target.value;
            let preVal = formik.values.postProcessing === null || formik.values.postProcessing === "" ? 0:parseInt(formik.values.postProcessing)
            let postVal = formik.values.preProcessing === null || formik.values.preProcessing === "" ? 0:parseInt(formik.values.preProcessing);
            var totalCheck = parseInt(formik.values.duration) - parseInt(value);
            if (!!value && postVal > 0) {
                if(totalCheck > 0){
                    formik.setFieldValue("duration", ((parseInt(formik.values.duration) + preVal) - parseInt(value)).toString());
                    (parseInt(formik.values.duration))
                    formik.setFieldValue(name, value);
                }
                else{
                    toast("Post processing and Post processing should not null or greater than duration.");
                }
            }
            else{
                if(parseInt(value) < parseInt(formik.values.duration) && postVal > 0){
                    formik.setFieldValue(name, value);
                }
                else{
                    toast("Post processing and Post processing should not null or greater than duration.");
                }
            }
        }
    }

    useEffect(() => {
        if (props.open) {
            var element = document.querySelector('body');
            element.classList.add('addOverflow');
            return () => {
                element.classList.remove('addOverflow')
            }
        }
    }, [props.open]);

    useEffect(() => {
        formik.setFieldValue("patientId", selectedPatient);
    }, [selectedPatient]);

    useEffect(() => {
        if (!!description) {
            formik.setFieldValue("description", description);
        }
    }, [description]);

    useEffect(() => {
        if (!!formData && Object.keys(formData).length > 0) {
            if (!!formData.patientId) { formik.setFieldValue("patientId", formData.patientId.toString()); }
            if (!!formData.id) { formik.setFieldValue("id", formData.id.toString()); }
            if (!!formData.practitionerId) { formik.setFieldValue("practitionerId", formData.practitionerId.toString()); }
            if (!!formData.otherPractitionerIds) { formik.setFieldValue("otherPractitionerIds", formData.otherPractitionerIds.toString()); }
            if (!!formData.recallId) { formik.setFieldValue("recallId", formData.recallId.toString()); }
            if (!!formData.description) { formik.setFieldValue("description", formData.description); }
            if (!!formData.categoryId) { formik.setFieldValue("categoryId", formData.categoryId.toString()); }
            if (!!formData.colorId) { formik.setFieldValue("colorId", formData.colorId.toString()); }
            if (!!formData.roomId) { formik.setFieldValue("roomId", formData.roomId.toString()); }
            if (!!formData.bookingDate) { formik.setFieldValue("bookingDate", new Date(formData.bookingDate)); }
            if (!!formData.startTime) { formik.setFieldValue("startTime", new Date(formData.startTime)); }
            if (!!formData.endTime) { formik.setFieldValue("endTime", new Date(formData.endTime)); }
            if (!!formData.duration) { formik.setFieldValue("duration", formData.duration.toString()); }
            if (!!formData.preProcessing) { formik.setFieldValue("preProcessing", formData.preProcessing.toString()); }
            if (!!formData.postProcessing) { formik.setFieldValue("postProcessing", formData.postProcessing.toString()); }
            if (!!formData.statusId) { formik.setFieldValue("statusId", formData.statusId.toString()); }
            if (!!formData.sms_Erinnerung) { formik.setFieldValue("sms_Erinnerung", formData.sms_Erinnerung); }
            if (!!formData.phoneNumber) { formik.setFieldValue("phoneNumber", formData.phoneNumber); }
            if (!!formData.reminder1) { formik.setFieldValue("reminder1", formData.reminder1); }
            if (!!formData.reminder1TimeId) { formik.setFieldValue("reminder1TimeId", formData.reminder1TimeId.toString()); }
            if (!!formData.reminder1Date) { formik.setFieldValue("reminder1Date", new Date(formData.reminder1Date)); }
            if (!!formData.reminder1Time) { formik.setFieldValue("reminder1Time", new Date(formData.reminder1Time)); }
            if (!!formData.reminder2) { formik.setFieldValue("reminder2", formData.reminder2); }
            if (!!formData.reminder2TimeId) { formik.setFieldValue("reminder2TimeId", formData.reminder2TimeId.toString()); }
            if (!!formData.reminder2Date) { formik.setFieldValue("reminder2Date", new Date(formData.reminder2Date)); }
            if (!!formData.reminder2Time) { formik.setFieldValue("reminder2Time", new Date(formData.reminder2Time)); }
            if (!!formData.alarmId) { formik.setFieldValue("alarmId", formData.alarmId.toString()); }
            if (!!formData.unKnownId) { formik.setFieldValue("unKnownId", formData.unKnownId.toString()); }
            if (!!formData.programStart) { formik.setFieldValue("programStart", formData.programStart); }
            if (!!formData.appointmentStart) { formik.setFieldValue("appointmentStart", formData.appointmentStart); }
            if (!!formData.hourBefore) { formik.setFieldValue("hourBefore", formData.hourBefore); }
            if (!!formData.fiveMinutesBefore) { formik.setFieldValue("fiveMinutesBefore", formData.fiveMinutesBefore); }
            if (!!formData.dayBefore) { formik.setFieldValue("dayBefore", formData.dayBefore); }
            if (!!formData.bookingNote) { formik.setFieldValue("bookingNote", formData.bookingNote); }
            let available = practitionerDDL.filter(x => x.value !== formData.practitionerId.toString());
            setOtherPractitionerDDL(available);
            if (!!formData.otherPractitionerIds) {
                let selectedOtherPractitiionerIds = formData.otherPractitionerIds.split(',');
                let selectedOtherPractitiionerIdsArr = [];
                selectedOtherPractitiionerIds.map((x) => practitionerDDL.filter(y => y.value === x).map(yy => selectedOtherPractitiionerIdsArr.push(yy)));
                setGetOtherPractitiionerIds(selectedOtherPractitiionerIdsArr);
            }
        }
    }, [formData]);

    useEffect(() => {
        if (Object.keys(appFormData).length > 1) {
            formik.setFieldValue("practitionerId", appFormData.practitionerId);
            formik.setFieldValue("bookingDate", appFormData.date);
            formik.setFieldValue("startTime", appFormData.startTime);
            formik.setFieldValue("endTime", appFormData.endTime);
            formik.setFieldValue("duration", '60');
        }
    }, [appFormData]);

    const caldendarToClipboard = async (e) => {

        let url = "online-terminbuch/caldendarToClipboard?appId=" + e;
        // let res = await dynamicGetApi(url, navigate, setCookie);
        onClose();
        setCookie("reCallApis", true);
        setCookie('reCallCalendarApis', true);
    }

    const deleteAllClipboardItems = async (e) => {
        let url = "online-terminbuch/deleteAppointmentFromCalendar?appId=" + e;
        // let res = await dynamicGetApi(url, navigate, setCookie);
        onClose();
        setCookie("reCallApis", true);
        setCookie('reCallCalendarApis', true);
    }

    const onClose = () => {
        removeCookie('fromEditMode');
        removeCookie('appointmentId');
        props.setOpen(false);
        formik.resetForm();
        setGetOtherPractitiionerIds({});
        setDescription("");
        setClosed("closed");
        setEditMode(false);
    }

    // Patient
    const row_02 = () => {
        return <div class="row controls controls-row">

            <div class="ds-cell col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                <div class="ds-grid ds-grid-spacing-none" data-ds-rt="grid" data-ds-rid="patientCardGrid" id="ws9ZoUy9QEawfWaWSCbjKg">

                    <div class="row controls controls-row"></div>

                    <div class="row controls controls-row">
                        <div class="ds-cell col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            {PatientSelect({ setSelectedPatient }, formik.values.patientId, closed)}

                        </div>
                    </div>

                </div>
            </div>

        </div>;
    }

    // Terminbuch - weitere Teilnehmer
    const row_03 = () => {
        return <div class="row controls controls-row mb-3">
            <div className='col-md-6'>
                <Labels>Terminbuch</Labels>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={practitionerDDL.filter(x => x.value === formik.values.practitionerId)}
                    isClearable={true}
                    isSearchable={true}
                    name="practitionerId"
                    options={practitionerDDL}
                    onChange={(e) => handleChange(e.value, "practitionerId")}
                />
            </div>
            <div className='col-md-6'>
                <Labels>weitere Teilnehmer</Labels>

                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={Object.keys(getOtherPractitiionerIds).length > 0 ? getOtherPractitiionerIds : null}
                    isMulti
                    isClearable={true}
                    isSearchable={true}
                    name="otherPractitionerIds"
                    options={otherPractitionerDDL}
                    onChange={(e) => handleChange(e, "otherPractitionerIds")}
                />
            </div>
        </div>;
    }

    // Recall - Beschreibung
    const row_04 = () => {
        return <div class="row controls controls-row mb-3">
            <div className='col-md-4'>
                <Labels>Recall</Labels>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={recallDDL.filter(x => x.value === formik.values.recallId)}
                    isClearable={true}
                    isSearchable={true}
                    name="recallId"
                    options={recallDDL}
                    onChange={(e) => handleChange(e.value, "recallId")}
                />
            </div>
            <div className='col-md-8'>
                <Labels>Beschreibung</Labels>
                {BeschreibungSelect(descriptionDDL, { setDescription }, formData.description)}
            </div>

        </div>;
    }

    // Kategorie - Farbe
    const row_05 = () => {
        return <div class="row controls controls-row mb-3">
            <div className='col-md-6'>
                <Labels>Kategorie</Labels>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={categoryDDL.filter(x => x.value === formik.values.categoryId)}
                    isClearable={true}
                    isSearchable={true}
                    name="categoryId"
                    options={categoryDDL}
                    onChange={(e) => handleChange(e.value, "categoryId")}
                    id="categoryId"
                />
            </div>
            <div className='col-md-6'>
                <Labels>Farbe</Labels>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="colorId"
                    value={colorDDL.filter(x => x.value === formik.values.colorId)}
                    options={colorDDL}
                    styles={colourStyles}
                    onChange={(e) => handleChange(e.value, "colorId")}
                />
            </div>
        </div>;
    }
    // Zimmer
    const row_06 = () => {
        return <div class="row controls controls-row mb-3">

            <div className='col-md-6'>
                <Labels>Zimmer</Labels>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={roomDDL.filter(x => x.value === formik.values.roomId)}
                    isClearable={true}
                    isSearchable={true}
                    name="roomId"
                    options={roomDDL}
                    onChange={(e) => handleChange(e.value, "roomId")}
                />
            </div>

        </div>;
    }

    const ExampleCustomInput = forwardRef(({ value, onClick, label, width }, ref) => (
        <TextField size='small' fullWidth={width} className='w-100' onClick={onClick} ref={ref} value={value} id="filled-basic" label={label} variant="outlined" />
    ));

    const row_07 = () => {
        return <div class="row controls controls-row mb-3">

            <div className='col-md-2'>
                <Labels>Datum</Labels>
                <DatePicker selected={formik.values.bookingDate} name="bookingDate" customInput={<ExampleCustomInput label="" />} onChange={(e) => handleChange(e, "bookingDate")} />
            </div>
            <div className='col-md-2'>
                <Labels>von</Labels>
                <DatePicker selected={formik.values.startTime} name="startTime" customInput={<ExampleCustomInput label="" />} onChange={(e) => handleChange(e, "startTime")} showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa" />
            </div>
            <div className='col-md-2'>
                <Labels>bis</Labels>
                <DatePicker selected={formik.values.endTime} name="endTime" customInput={<ExampleCustomInput label="" />} onChange={(e) => handleChange(e, "endTime")} showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa" />
            </div>

        </div>
    }
    const row_08 = () => {
        return <div class="row controls controls-row mb-3">

            <div className='col-md-4'>
                <Labels>Dauer</Labels>
                <div className='d-flex align-items-center' style={{ display: "flex", alignItems: "center" }}>

                    <TextField size='small' sx={{ width: 120 }} value={formik.values.duration} onChange={formik.handleChange} name="duration" id="filled-basic" label={""} variant="outlined" /> <span className='ml-2'>Minuten</span>

                </div>
            </div>
            <div className='col-md-4'>
                <Labels>Vorbereitung</Labels>
                <div className='d-flex align-items-center' style={{ display: "flex", alignItems: "center" }}>
                    <TextField size='small' sx={{ width: 120 }} value={formik.values.preProcessing} onChange={(e) => handleChange(e, "preProcessing")} name="preProcessing" id="filled-basic" label={""} variant="outlined" /> <span className='ml-2'>Minuten</span>
                </div>
            </div>
            <div className='col-md-4'>
                <Labels>Nachbereitung</Labels>
                <div className='d-flex align-items-center' style={{ display: "flex", alignItems: "center" }}>
                    <TextField size='small' sx={{ width: 120 }} value={formik.values.postProcessing} onChange={(e) => handleChange(e, "postProcessing")} name="postProcessing" id="filled-basic" label={""} variant="outlined" /> <span className='ml-2'>Minuten</span>
                </div>
            </div>

        </div>

    }

    const row_09 = () => {
        return <div class="row controls controls-row mb-3">

            <div className='col-md-6'>
                <Labels>Status</Labels>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={statusDDL.filter(x => x.value === formik.values.statusId)}
                    isClearable={true}
                    isSearchable={true}
                    name="statusId"
                    options={statusDDL}
                    onChange={(e) => handleChange(e.value, "statusId")}
                />
            </div>

        </div>;
    }

    const row_10 = () => {
        return <div class="row controls controls-row mb-3">

            <div className='col-md-6'>
                <FormGroup>
                    <FormControlLabel control={<Checkbox name='sms_Erinnerung' checked={formik.values.sms_Erinnerung} onChange={(e) => formik.handleChange(e)} />} label="SMS-Erinnerung" />
                </FormGroup>
            </div>

        </div>;
    }
    const row_11 = () => {
        return <div class="row controls controls-row mb-3">

            <div className='col-md-6'>
                <Labels>Telefonnummer</Labels>
                <TextField size='small' fullWidth value={formik.values.phoneNumber} onChange={formik.handleChange} name="phoneNumber" id="filled-basic" label={""} variant="outlined" />
            </div>

        </div>;
    }
    const row_12 = () => {
        return <div class="row controls controls-row mb-3">

            <div className='col-md-4 mb-3'>
                <FormGroup>
                    <FormControlLabel control={<Checkbox name='reminder1' checked={formik.values.reminder1} onChange={(e) => formik.handleChange(e)} />} label="Erinnerung 1" />
                </FormGroup>
            </div>
            <div className='col-md-6 mb-3'>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={timeDDL.filter(x => x.value === formik.values.reminder1TimeId)}
                    isClearable={true}
                    isSearchable={true}
                    name="reminder1TimeId"
                    options={timeDDL}
                    onChange={(e) => handleChange(e.value, "reminder1TimeId")}
                />
            </div>
            {!!formik.values.reminder1TimeId && timeDDL.length > 0 && timeDDL.filter(x => x.value === formik.values.reminder1TimeId)[0].label === "Do not generate automatically" ?
                <>
                    <div className='col-md-6 '>
                        <DatePicker selected={formik.values.reminder1Date} name="reminder1Date" customInput={<ExampleCustomInput width={true} label="Sendedatum" />} onChange={(e) => handleChange(e, "reminder1Date")} />
                    </div>
                    <div className='col-md-6'>
                        <DatePicker selected={formik.values.reminder1Time} name="reminder1Time" customInput={<ExampleCustomInput width={true} label="Sendezeit" />} onChange={(e) => handleChange(e, "reminder1Time")} showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa" />
                    </div>
                </>
                : null}
        </div>;
    }

    const row_13 = () => {
        return <div class="row controls controls-row mb-3">

            <div className='col-md-4 mb-3'>
                <FormGroup>
                    <FormControlLabel control={<Checkbox name='reminder2' checked={formik.values.reminder2} onChange={(e) => formik.handleChange(e)} />} label="Erinnerung 2" />
                </FormGroup>
            </div>
            <div className='col-md-6 mb-3'>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={timeDDL.filter(x => x.value === formik.values.reminder2TimeId)}
                    isClearable={true}
                    isSearchable={true}
                    name="reminder2TimeId"
                    options={timeDDL}
                    onChange={(e) => handleChange(e.value, "reminder2TimeId")}
                />
            </div>
            {!!formik.values.reminder2TimeId && timeDDL.length > 0 && timeDDL.filter(x => x.value === formik.values.reminder2TimeId)[0].label === "Do not generate automatically" ?
                <>
                    <div className='col-md-6 '>
                        <DatePicker selected={formik.values.reminder2Date} name="reminder2Date" customInput={<ExampleCustomInput width={true} label="Sendedatum" />} onChange={(e) => handleChange(e, "reminder2Date")} />
                    </div>
                    <div className='col-md-6'>
                        <DatePicker selected={formik.values.reminder2Date} name="reminder2Date" customInput={<ExampleCustomInput width={true} label="Sendezeit" />} onChange={(e) => handleChange(e, "reminder2Date")} showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa" />
                    </div>
                </>
                : null}

        </div>;
    }
    const row_14 = () => {
        return <div class="row controls controls-row mb-3">

            <div className='col-md-6 mb-3'>
                <Labels>Alarmfunktion</Labels>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={alarmDDL.filter(x => x.value === formik.values.alarmId)}
                    isClearable={true}
                    isSearchable={true}
                    name="alarmId"
                    options={alarmDDL}
                    onChange={(e) => handleChange(e.value, "alarmId")}
                />
            </div>
            {!!formik.values.alarmId && alarmDDL.length > 0 && alarmDDL.filter(x => x.value === formik.values.alarmId)[0].label === "Fur Station" ?
                <>
                    <div className='col-md-6 mb-3'>
                        <Labels>&nbsp;</Labels>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            value={unKnownDDL.filter(x => x.value === formik.values.unKnownId)}
                            isClearable={true}
                            isSearchable={true}
                            name="unKnownId"
                            options={unKnownDDL}
                            onChange={(e) => handleChange(e.value, "unKnownId")}
                        />
                    </div>
                    {/* {
                        ["bei Programmstart","bei Terminbeginn","1 Stunde vorher" , "5 Minuten vorher"].map((x,i) => <div className='col-md-6 mb-3'><FormGroup>
                        <FormControlLabel checked={formik.values.alarmValues.programStart} name={formik.values.alarmValues[i]} control={<Checkbox  />} label={x} />
                      </FormGroup></div>)

                    } */}
                    <div className='col-md-6 mb-3'><FormGroup>
                        <FormControlLabel checked={formik.values.programStart} onChange={formik.handleChange} name="programStart" control={<Checkbox />} label={"bei Programmstart"} />
                    </FormGroup></div>
                    <div className='col-md-6 mb-3'><FormGroup>
                        <FormControlLabel checked={formik.values.appointmentStart} onChange={formik.handleChange} name="appointmentStart" control={<Checkbox />} label={"bei Terminbeginn"} />
                    </FormGroup></div>
                    <div className='col-md-6 mb-3'><FormGroup>
                        <FormControlLabel checked={formik.values.hourBefore} onChange={formik.handleChange} name="hourBefore" control={<Checkbox />} label={"1 Stunde vorher"} />
                    </FormGroup></div>
                    <div className='col-md-6 mb-3'><FormGroup>
                        <FormControlLabel checked={formik.values.fiveMinutesBefore} onChange={formik.handleChange} name="fiveMinutesBefore" control={<Checkbox />} label={"5 Minuten vorher"} />
                    </FormGroup></div>
                    <div className='col-md-6'>
                        <Labels>Tag/e vorher</Labels>
                        <TextField size='small' fullWidth id="filled-basic" label={""} variant="outlined" name="dayBefore" value={formik.values.dayBefore} onChange={formik.handleChange} />

                    </div>
                </>
                : null}

        </div>;
    }

    const row_15 = () => {
        return <div class="row controls controls-row mb-3">

            <div className='col-md-12'>
                <Labels>Termininfo</Labels>
                <TextField
                    id="outlined-multiline-static"
                    label=""
                    multiline
                    fullWidth
                    rows={6}
                    value={formik.values.bookingNote}
                    name="bookingNote"
                    onChange={formik.handleChange}
                />

            </div>
        </div>;
    }

    return (
        <>
         <ToastContainer />
        <div lang="de" data-ds-rt="view" data-ds-rid="Main" id="wu5O0ufwT4CkvpjvOfnjlQ">

            {/* <div class="ds-messagebar-fixed" data-ds-rt="messageBar" data-ds-rid="viewInternalMessageBar" id="woEuZBEcT3KrA6x6c6qYKw" style={{display: "none"}}> */}
            <div class="ds-messagebar-fixed d-none" data-ds-rt="messageBar" data-ds-rid="viewInternalMessageBar" id="woEuZBEcT3KrA6x6c6qYKw" >
                <div>
                    <div></div>
                </div>
            </div>

            <div class="ds-grid ds-grid-spacing-default ds-grid-spacing-bottom p-2 row" data-ds-rt="grid" data-ds-rid="grid1" id="wdPU3QaWTGqEbi5WaOMpJw">
                <div className='col-md-7'>
                    {/* {row_01()} */}
                    <div>
                        <Dialog
                            open={props.open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => onClose()}
                            aria-describedby="alert-dialog-slide-description"
                            fullWidth={"lg"}
                            maxWidth={"lg"}
                        >
                            <DialogTitle>
                                <div className='modelHeaderSpBwt'>

                                    <div> {"Add/Edit Appointment"}</div>
                                    <div>
                                        <FaTimes className='fa fa-times' onClick={onClose} />
                                    </div>

                                </div></DialogTitle>
                            <DialogContent>
                                {row_02()}

                                {row_03()}

                                {row_04()}

                                {row_05()}

                                {row_06()}

                                {row_07()}

                                {row_08()}

                                {row_09()}

                                {row_10()}

                                {formik.values.sms_Erinnerung === true ? row_11() : null}
                                {formik.values.sms_Erinnerung === true ? row_12() : null}
                                {formik.values.sms_Erinnerung === true && formik.values.reminder1 === true ? row_13() : null}

                                {row_14()}

                                {row_15()}

                                {/* {row_16()} */}
                            </DialogContent>
                            <DialogActions className='w-100-Between'>
                                <div className=''>
                                    {!!cookies.fromEditMode && cookies.fromEditMode === "true" && <div data-docking="top" class="btn-toolbar ds-animation ds-topDocking" data-ds-rt="toolbar" data-ds-rid="toolbar1" id="wEyIFlIuSPeE7iwlZZ7f9g" data-docked="false">
                                        <div class="btn-group pull-left" data-ds-rt="buttonGroup" data-ds-rid="buttonGroup2" id="wcc6dYrnScq6GRGAqZLbTg">

                                            <button class="btn btn-default p-2 mr-2 text-dark" data-disabled="true" data-ds-rt="button" data-ds-rid="cutBt" id="w4H9HWliTba2jf39xepbDw" onClick={() => caldendarToClipboard(formik.values.id)}>
                                                <span class="ds-b-cm ds-b-c">
                                                    <span data-ds-rid="icon2" id="wj-zwZqUSCO8zItRJsMIsQ" data-ds-rt="icon" class="ds-i">
                                                        <FaCut class="fa-fw  fal fa-cut" />
                                                    </span>
                                                    <span class="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wPbJlccdTCaLiSeuPOGZRQ">Ausschneiden</span>
                                                </span>
                                            </button>
                                            <button class="btn btn-default p-2 text-dark" data-disabled="true" data-ds-rt="button" data-ds-rid="deleteBt" id="wA_qNg2zRV6ah424xvtZFA" onClick={() => deleteAllClipboardItems(formik.values.id)}>
                                                <span class="ds-b-cm ds-b-c">
                                                    <span data-ds-rid="icon3" id="wUXMPwsVRemndfy6bX9KkA" data-ds-rt="icon" class="ds-i">
                                                        <FaTrash class="fa-fw  fal fa-trash" />
                                                    </span>
                                                    <span class="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wxYFps_eRvicEfNbQ0hZUQ">Löschen</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>}
                                </div>
                                <div className=''>
                                    <button onClick={() => onClose()} className='btn btn-default p-3 mr-2'><FaTimes className='fa fa-times' /> Abbrechen</button>
                                    <button onClick={formik.handleSubmit} className='btn btn-dark p-3'><FaCheck className='fa fa-check' /> Speichern</button>
                                </div>

                            </DialogActions>
                        </Dialog>
                    </div>
                </div>

            </div>

        </div>
        </>
                   
    );
}

export default AddEditForm;