import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import FormCheck from '../FormCheck';
import CustomTooltip from '../Tooltip';
import BadgeWidget from '../BadgeWidget';
import { Divider, ModalHeader } from '../StyledComponents';
import FormControl from '../FormControl';
import { Link } from 'react-router-dom';

interface modalProps {
    displayRecipientModal: boolean,
    hideRecipientModal: () => void,
    modalSize?: any
}

const Recipient = (props: modalProps) => {

    const [networkToogle, setNetworkToogle] = useState<boolean>(false);

    const HandleNetworkToggle = () => {
        setNetworkToogle(!networkToogle);
    }

    return (
        <>
            <Modal size="lg" className='shadow-modal' show={props.displayRecipientModal} onHide={props.hideRecipientModal} backdrop="static" keyboard={false} aria-labelledby="recipient-modal" >
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="recipient-modal">
                        Empfänger finden
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body className=''>
                    <Form.Group className="mb-3">
                        <FormControl isFormGroup={true} Type='text' Size='sm' Placeholder='Suchen' Name='searchFor' Id='searchFor' />
                    </Form.Group>
                    <Divider mt='10px' mb='8px' />
                    <Form.Group controlId='Anrede' className="mb-1">
                        <FormCheck
                            type='radio'
                            name='group1'
                            Id='group1'
                            value='nutzer'
                            label='Nutzer'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                        />
                        <FormCheck
                            type='radio'
                            name='group1'
                            Id='group1'
                            value='gruppen'
                            label='Gruppen'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                        />
                        <FormCheck
                            type='radio'
                            name='group1'
                            Id='group1'
                            value='beides'
                            label='Beides'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                        />
                    </Form.Group>
                    <Divider mt='10px' mb='8px' />
                    <Col md={12}>
                        <Form.Group controlId='Anrede' className="mb-1">
                            <FormCheck
                                // handleChange={handleNetworkCheck}
                                type='radio'
                                name='phone'
                                Id='phone1'
                                value='alleNetzwerke'
                                label='Alle Netzwerke'
                                isFormGroup={false}
                                isInline={true}
                                disabled={false}
                            />
                            <FormCheck
                                // handleChange={handleNetworkCheck}
                                type='radio'
                                name='phone'
                                Id='phone2'
                                value='optionUpdates'
                                label={<BadgeWidget outiline={true} title='OPTI QMH Updates' color='#e4005f' />}
                                isFormGroup={false}
                                isInline={true}
                                disabled={false}
                            />
                            <Link to="" className='text-decoration-underline ms-3' onClick={HandleNetworkToggle}>
                                {networkToogle ?
                                    <span className='text-nowrap'>Netzwerke verbergen</span>
                                    :
                                    <span className='text-nowrap'>Netzwerk ändern</span>
                                }
                            </Link>
                        </Form.Group>
                        {networkToogle ?
                            <FormCheck
                                // handleChange={handleNetworkCheck}
                                type='radio'
                                name='phone'
                                Id='phone3'
                                value='praxisGallenbach'
                                label={<BadgeWidget outiline={true} title='Praxis Gallenbach & Zähne' color='#0d3f5f' />}
                                isFormGroup={false}
                                isInline={true}
                                disabled={false}
                            />
                            :
                            null
                        }
                    </Col>
                    <Divider mt='10px' mb='8px' />
                    <div className='p-2 pb-3'>
                        <Row>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={<BadgeWidget solid={true} title='Abrechnung' color='#fff' bgColor='#0D3F5F' />}
                                        toolTipContent='APZ APZ'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={<BadgeWidget solid={true} title='Assistenz' color='#fff' bgColor='#678ea4' />}
                                        toolTipContent='Alicia Gojani, Anna Behm, Cara Bianco, Hoa Trinh, Irene Gantert'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={<BadgeWidget solid={true} title='Medikit Champions' color='#fff' bgColor='#0D3F5F' />}
                                        toolTipContent='Alicia Gojani, Diana Lauser, Dr. Sven Markus Gallenbach, Nadine Häring'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={<BadgeWidget solid={true} title='OPTI' color='#fff' bgColor='#0D3F5F' />}
                                        toolTipContent='Emine Mollaoglu'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={<BadgeWidget solid={true} title='Prophylaxe' color='#fff' bgColor='#3c6685' />}
                                        toolTipContent='Diana Lauser, Ellen Klotz, Irene Gantert'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={<BadgeWidget solid={true} title='Verwaltung' color='#fff' bgColor='#2b9cb2' />}
                                        toolTipContent='Alicia Gojani, Nadine Häring'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={<BadgeWidget solid={true} title='Zahnärzte' color='#fff' bgColor='#2b9cb2' />}
                                        toolTipContent='Dr. Sven Markus Gallenbach, ZA Robert Stühn'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={
                                            <>
                                                <div className='d-flex align-items-center'>
                                                    <img className='rounded-circle me-2' src='/images/userFemale.png' alt='abc' />
                                                    <span>Alicia Gojani</span>
                                                </div>
                                            </>
                                        }
                                        toolTipContent='ZFA - Praxis Gallenbach & Zähne'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={
                                            <>
                                                <div className='d-flex align-items-center'>
                                                    <img className='rounded-circle me-2' src='/images/userFemale.png' alt='abc' />
                                                    <span>Anna Behm</span>
                                                </div>
                                            </>
                                        }
                                        toolTipContent='ZFA - Praxis Gallenbach & Zähne'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={
                                            <>
                                                <div className='d-flex align-items-center'>
                                                    <img className='rounded-circle me-2' src='/images/userFemale.png' alt='abc' />
                                                    <span>APZ APZ</span>
                                                </div>
                                            </>
                                        }
                                        toolTipContent='Praxis Gallenbach & Zähne'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={
                                            <>
                                                <div className='d-flex align-items-center'>
                                                    <img className='rounded-circle me-2' src='/images/userFemale.png' alt='abc' />
                                                    <span>Cara Bianco</span>
                                                </div>
                                            </>
                                        }
                                        toolTipContent='Auszubildende - Praxis Gallenbach & Zähne'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='badge-box p-2'>
                                    <CustomTooltip
                                        ToolTipTrigger={
                                            <>
                                                <div className='d-flex align-items-center'>
                                                    <img className='rounded-circle me-2' src='/images/userFemale.png' alt='abc' />
                                                    <span>Diana Lauser</span>
                                                </div>
                                            </>
                                        }
                                        toolTipContent='ZFA - Praxis Gallenbach & Zähne'
                                        toolTipPlacement='top'
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <div>
                        <Button className='default-btn f-14px me-2' >Alle auswählen</Button>
                    </div>
                    <div>
                        <Button onClick={props.hideRecipientModal} className='default-btn f-14px me-2' >Abbrechen</Button>
                        <Button className='primary-bg f-12px' >Auswählen</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Recipient;