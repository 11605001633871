import React, { FC, ChangeEvent, useState, SetStateAction, Dispatch, useRef } from 'react';
import { Col, InputGroup, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { HiX, HiPlusCircle } from "react-icons/hi";
import Form from 'react-bootstrap/Form';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Box, ChecklistDragContainer, DisplayCenter, WayPointBox } from './StyledComponents';
import { RiDragMove2Fill } from "react-icons/ri";
import FormControl from './FormControl';
import { IChecklist, ManageListFooterContent, ManagePlaceFooterContent, ShowAllListFooterContent, ShowAllPlacesFooterContent, TypeSubtask } from '../Pages/Task/AddEditTask';
import { AlertWidget } from './AlertWidget';
import ButtonWidget from './ButtonWidget';
import { GrUploadOption } from 'react-icons/gr';
import { FaPlusCircle } from 'react-icons/fa';
import FormCheck from './FormCheck';
import Index from '../Pages/Message/Index';
import CommonModal from './Modals/CommonModal';
import ShowAllPlacesModalContent from './Modals/ModelContents/TaskModalsContent/ShowAllPlacesModalContent';
import ManagePlacesModalContent from './Modals/ModelContents/TaskModalsContent/ManagePlacesModalContent';
import ShowAllListModalContent from './Modals/ModelContents/TaskModalsContent/ShowAllListsModalContent';
import ManageListModalContent from './Modals/ModelContents/TaskModalsContent/ManageListModalContent';
import Recipient from './Modals/Recipient';

const reorder = <T extends any[]>(list: T, startIndex: number, endIndex: number): T => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result as T;
};

interface ICheckListWidget {
    RemoveOne: (index: number) => void;
    addOptions: (index: number, optionText: string) => void;
    removeOption: (index: number, selectionIndex: number) => void;
    removeSubheading: (index: number) => void;
    handleSubtask: (event: ChangeEvent<HTMLSelectElement>, index: number) => void;
    removeWaypoint: (index: number) => void;
    removeInspection: (index: number) => void;
    handleWaypointTagChange: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
    Data: IChecklist[];
    setData: Dispatch<SetStateAction<IChecklist[]>>;

}

const CheckListWidget: FC<ICheckListWidget> = ({ RemoveOne, Data, handleSubtask, addOptions, removeOption, removeSubheading, setData, handleWaypointTagChange, removeWaypoint, removeInspection }) => {

    const [showAllPlacesModal, setShowAllPlacesModal] = useState<boolean>(false);
    const [managePlaceModal, setManagePlaceModal] = useState<boolean>(false);

    const [showAllListModal, setShowAllListModal] = useState<boolean>(false);
    const [manageListModal, setManageListModal] = useState<boolean>(false);
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);

    const [subtaskCollapse, setSubtaskCollapse] = useState<boolean>(true);


    const [optionValue, setOptionValue] = useState<string>('');
    const [optionAlertMsg, setOptionAlertMsg] = useState<boolean>(false);
    const [subtaskData, setSubtaskData] = useState<TypeSubtask[]>([]);
    const [inspectionCreatePost, setInspectionCreatePost] = useState<boolean>(false);

    const handleOptionAlert = (text: string) => {
        if (!text) {
            setOptionAlertMsg(true);
        } else {
            setOptionAlertMsg(false);
        }
    }
    const handleOptionValue = (text: string) => {
        if (!text) {
            setOptionAlertMsg(false);
        }
    }

    const handleCreatePost = () => {
        if (inspectionCreatePost === false) {
            setInspectionCreatePost(true);
        } else {
            setInspectionCreatePost(false);
        }
    }
    const handleInspectionCategoryChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        var temp = [...Data];
        if (temp[index]?.inspection) {
            // @ts-expect-error
            temp[index].inspection.inspectionCategory = event.target.value;
        }

        setData(temp);
    }

    const handleInspectionTypeChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        var temp = [...Data];
        if (temp[index]?.inspection) {
            // @ts-expect-error
            temp[index].inspection.inspectionType = event.target.value;
        }
        setData(temp);
    }
    const handleInspectionLocationChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        var temp = [...Data];
        if (temp[index]?.inspection) {
            // @ts-expect-error
            temp[index].inspection.inspectionLocation = event.target.value;
        }
        setData(temp);
    }


    const onDragStart = (e: any) => {
        setSubtaskCollapse(false);
    }

    const onDragEnd = (e: any) => {
        if (!e.destination) {
            return;
        }
        const newData = reorder(Data, e.source.index, e.destination.index);

        setData(newData);
        setSubtaskCollapse(true);
    };


    const subtaskCollapseRef = useRef(null);
    return (
        <>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId="ROOT" type="group">
                    {
                        (provider, _) => (
                            <div  {...provider.droppableProps} ref={provider.innerRef}>
                                {
                                    <>
                                        {Data.map((item, index) => {
                                            return <>
                                                {item.hasOwnProperty('subtask') ?
                                                    <Draggable key={'1' + index} draggableId={index + 'subtaskItem'} index={index}>
                                                        {(provided, snapshot) => (
                                                            <ChecklistDragContainer {...provided.draggableProps} ref={provided.innerRef} className={`${snapshot.isDragging ? 'draggable-item-dragging' : null}`}>
                                                                <Row>
                                                                    <Col md={8}>
                                                                        <InputGroup size="sm" className={`${subtaskCollapse === false ? 'mb-3' : 'mb-3'}`}>
                                                                            <Button {...provided.dragHandleProps} variant='light' className='remove-checkList-btn' id={`${"button-sort-subtaskItem" + index}`}>
                                                                                <RiDragMove2Fill size="20px" />
                                                                            </Button>
                                                                            <FormControl Class='checklist-input' isFormGroup={false} Type='text' Size='sm' Name={'subtaskItem-input' + index} Id={'subtaskItem-input' + index} />
                                                                            <ButtonWidget
                                                                                varient='light'
                                                                                text={
                                                                                    <>
                                                                                        <DisplayCenter>
                                                                                            <HiPlusCircle size="20px" />
                                                                                        </DisplayCenter>
                                                                                    </>
                                                                                }
                                                                                class='remove-checkList-btn'
                                                                                handleClick={() => RemoveOne(index)}
                                                                            />
                                                                        </InputGroup>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <div className={`d-flex align-items-center justify-content-between ${subtaskCollapse === false ? 'mb-3' : 'mb-3'}`}>
                                                                            <Form.Label className='me-2 mb-0'>Antwort:</Form.Label>
                                                                            <Form.Select value={item.subtask?.answer} size="sm" onChange={(event: ChangeEvent<HTMLSelectElement>) => handleSubtask(event, index)}>
                                                                                <option value="none">keine</option>
                                                                                <option value="optional">Text - freiwillig</option>
                                                                                <option value="required">Text - erforderlich</option>
                                                                                <option value="number">Zahl</option>
                                                                                <option value="selection">Auswahl</option>
                                                                            </Form.Select>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {/* {!!subtaskCollapse ? */}
                                                                <Row>
                                                                    {item.subtask?.number.isSelect ?
                                                                        <>
                                                                            <Col md={4}>
                                                                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                                                                    <Form.Label className='me-2 mb-0'>Min:</Form.Label>
                                                                                    <FormControl Class='checklist-input' isFormGroup={false} Type='text' Size='sm' Placeholder='min ' Name={'min-' + index} Id={'min-' + index} />
                                                                                </div>
                                                                            </Col>

                                                                            <Col md={4}>
                                                                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                                                                    <Form.Label className='me-2 mb-0'>Schritte:</Form.Label>
                                                                                    <Form.Select size="sm">
                                                                                        <option value="none" selected>0.001</option>
                                                                                        <option value="optional">0.01</option>
                                                                                        <option value="required">0.1</option>
                                                                                        <option value="number">1</option>
                                                                                        <option value="select">10</option>
                                                                                        <option value="select">100</option>
                                                                                    </Form.Select>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={4}>
                                                                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                                                                    <Form.Label className='me-2 mb-0'>Min:</Form.Label>
                                                                                    <FormControl Class='checklist-input' isFormGroup={false} Type='text' Size='sm' Placeholder='max ' Name={'max-' + index} Id={'max-' + index} />
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                    {item.subtask?.selection.isSelect ?
                                                                        <>
                                                                            <Col md={6}>
                                                                                {item.subtask?.selection.options.map((optionItem, selectionIndex) => {
                                                                                    return <>
                                                                                        <InputGroup size="sm" className="option-input">
                                                                                            <FormControl Class='checklist-input' isFormGroup={false} Type='text' Size='sm' Value={optionItem.option} Name={'option-' + selectionIndex} Id={'option-' + selectionIndex} />
                                                                                            <ButtonWidget
                                                                                                varient='light'
                                                                                                text={
                                                                                                    <>
                                                                                                        <DisplayCenter>
                                                                                                            <HiX size="20px" />
                                                                                                        </DisplayCenter>
                                                                                                    </>
                                                                                                }
                                                                                                class='remove-checkList-btn'
                                                                                                handleClick={() => removeOption(index, selectionIndex)}
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </>
                                                                                })}
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <InputGroup size="sm" className="mb-3">
                                                                                    <FormControl Class='checklist-input' handleChange={(event) => { setOptionValue(event.target.value); handleOptionValue(optionValue) }} isFormGroup={false} Type='text' Size='sm' Placeholder='Option ' Name={'option-' + index} Id={'option-' + index} />
                                                                                    <ButtonWidget
                                                                                        varient='light'
                                                                                        text={
                                                                                            <>
                                                                                                <DisplayCenter>
                                                                                                    <HiPlusCircle size="20px" />
                                                                                                </DisplayCenter>
                                                                                            </>
                                                                                        }
                                                                                        class='remove-checkList-btn'
                                                                                        handleClick={() => { addOptions(index, optionValue); handleOptionAlert(optionValue) }}
                                                                                    />
                                                                                </InputGroup>
                                                                                {optionAlertMsg ?
                                                                                    <AlertWidget variant='danger' text='Bitte gib einen Optionen Text ein!' />
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </Col>
                                                                        </>

                                                                        :
                                                                        null
                                                                    }
                                                                </Row>
                                                                {/* :
                                                                    null
                                                                } */}
                                                            </ChecklistDragContainer>
                                                        )}
                                                    </Draggable>
                                                    :
                                                    null
                                                }
                                                {item.hasOwnProperty('subheading') ?
                                                    <>
                                                        <Draggable key={'1' + index} draggableId={index + 'subheading'} index={index}>
                                                            {(provided, snapshot) => (
                                                                <ChecklistDragContainer {...provided.draggableProps} ref={provided.innerRef} className={`${snapshot.isDragging ? 'draggable-item-dragging' : null}`}>
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <InputGroup size="sm" className="mb-3">
                                                                                <Button {...provided.dragHandleProps} variant='light' className='remove-checkList-btn' id={`${"button-sort-subheading" + index}`}>
                                                                                    <RiDragMove2Fill size="20px" />
                                                                                </Button>
                                                                                <FormControl Class='checklist-input' isFormGroup={false} Type='text' Size='sm' Name={'subheading-input' + index} Id={'subheading-input' + index} />
                                                                                <ButtonWidget
                                                                                    varient='light'
                                                                                    text={
                                                                                        <>
                                                                                            <DisplayCenter>
                                                                                                <HiX size="20px" />
                                                                                            </DisplayCenter>
                                                                                        </>
                                                                                    }
                                                                                    class='remove-checkList-btn'
                                                                                    handleClick={() => removeSubheading(index)}
                                                                                />
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </ChecklistDragContainer>
                                                            )}
                                                        </Draggable>
                                                    </>
                                                    :
                                                    null
                                                }
                                                {item.hasOwnProperty('waypoint') ?
                                                    <>
                                                        <Draggable key={'1' + index} draggableId={index + 'waypoint'} index={index}>
                                                            {(provided, snapshot) => (
                                                                <ChecklistDragContainer {...provided.draggableProps} ref={provided.innerRef} className={`${snapshot.isDragging ? 'draggable-item-dragging' : null}`}>
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <WayPointBox>
                                                                                <InputGroup size="sm" className="mb-3">
                                                                                    <Button {...provided.dragHandleProps} variant='light' className='remove-checkList-btn' id={`${"button-sort-waypoint" + index}`}>
                                                                                        <RiDragMove2Fill size="20px" />
                                                                                    </Button>
                                                                                    <FormControl Class='checklist-input' isFormGroup={false} Type='text' Size='sm' Name={'waypoint-input' + index} Id={'waypoint-input' + index} />
                                                                                    <ButtonWidget
                                                                                        varient='light'
                                                                                        text={
                                                                                            <>
                                                                                                <DisplayCenter>
                                                                                                    <FaPlusCircle />
                                                                                                    <span className='ms-1'>Alle anzeigen</span>
                                                                                                </DisplayCenter>
                                                                                            </>

                                                                                        }
                                                                                        class='fs-12px'
                                                                                        handleClick={() => setRecipientModal(true)}
                                                                                    />
                                                                                    <ButtonWidget
                                                                                        varient='light'
                                                                                        text={
                                                                                            <>
                                                                                                <DisplayCenter>
                                                                                                    <HiX size="20px" />
                                                                                                </DisplayCenter>
                                                                                            </>
                                                                                        }
                                                                                        class='remove-checkList-btn'
                                                                                        handleClick={() => removeWaypoint(index)}
                                                                                    />
                                                                                </InputGroup>
                                                                            </WayPointBox>
                                                                            <div className='mb-3'>
                                                                                <FormCheck
                                                                                    handleChange={(event) => handleWaypointTagChange(event, index)}
                                                                                    type='checkbox'
                                                                                    name='NcfTag'
                                                                                    Id='NcfTag'
                                                                                    value='NcfTag requrid'
                                                                                    label='NFC Tag notwendig'
                                                                                    isFormGroup={false}
                                                                                    isInline={true}
                                                                                    disabled={false}
                                                                                    checked={item.waypoint?.tagValue === "NcfTag requrid"}
                                                                                />
                                                                                <FormCheck
                                                                                    handleChange={(event) => handleWaypointTagChange(event, index)}
                                                                                    type='checkbox'
                                                                                    name='NcfTag'
                                                                                    Id='QRTag'
                                                                                    value='QR or NcfTag requrid'
                                                                                    label='QR Tag oder NFC Tag notwendig'
                                                                                    isFormGroup={false}
                                                                                    isInline={true}
                                                                                    disabled={false}
                                                                                    checked={item.waypoint?.tagValue === "QR or NcfTag requrid"}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </ChecklistDragContainer>
                                                            )}
                                                        </Draggable>
                                                    </>
                                                    :
                                                    null
                                                }
                                                {item.hasOwnProperty('inspection') ?
                                                    <>
                                                        <Draggable key={'1' + index} draggableId={index + 'waypoint'} index={index}>
                                                            {(provided, snapshot) => (
                                                                <ChecklistDragContainer {...provided.draggableProps} ref={provided.innerRef} className={`${snapshot.isDragging ? 'draggable-item-dragging' : null}`}>
                                                                    <Box>
                                                                        <Row>
                                                                            <Col md={8}>
                                                                                <InputGroup size="sm" className="mb-3">
                                                                                    <Button {...provided.dragHandleProps} variant='light' className='remove-checkList-btn' id={`${"button-sort-Inspection" + index}`}>
                                                                                        <RiDragMove2Fill size="20px" />
                                                                                    </Button>
                                                                                    <FormControl Class='checklist-input' isFormGroup={false} Type='text' Size='sm' Name={'Inspection-input' + index} Id={'Inspection-input' + index} />
                                                                                    <ButtonWidget
                                                                                        varient='light'
                                                                                        text={
                                                                                            <>
                                                                                                <DisplayCenter>
                                                                                                    <HiPlusCircle size="20px" />
                                                                                                </DisplayCenter>
                                                                                            </>
                                                                                        }
                                                                                        class='remove-checkList-btn'
                                                                                        handleClick={() => removeInspection(index)}
                                                                                    />
                                                                                </InputGroup>
                                                                            </Col>
                                                                            <Col md={4}>
                                                                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                                                                    <Form.Label className='me-2 mb-0'>Antwort:</Form.Label>
                                                                                    <Form.Select value={item.inspection?.answer} size="sm">
                                                                                        <option value="none">Ja / Nein</option>
                                                                                        <option value="optional">OK / nicht OK</option>
                                                                                        <option value="required">Ja / Nein / N/A</option>
                                                                                        <option value="number">Ok / Nicht ok / N/A</option>
                                                                                    </Form.Select>
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={5}>
                                                                                <Box className='mb-2'>
                                                                                    <FormCheck
                                                                                        type='checkbox'
                                                                                        name='createPost'
                                                                                        Id='createPost'
                                                                                        value='createPostIfNegativeAnswer'
                                                                                        label='Beitrag erstellen, falls negative Antwort'
                                                                                        isFormGroup={false}
                                                                                        isInline={true}
                                                                                        disabled={false}
                                                                                        handleChange={handleCreatePost}
                                                                                    />
                                                                                </Box>
                                                                            </Col>
                                                                            <Col md={7}>
                                                                                {!!inspectionCreatePost ?
                                                                                    <Box className='mb-2' width='150px'>
                                                                                        <Form.Select
                                                                                            value={item.inspection?.inspectionCategory}
                                                                                            onChange={(event: ChangeEvent<HTMLSelectElement>) => handleInspectionCategoryChange(event, index)} size="sm">
                                                                                            <option value="news">Nachricht</option>
                                                                                            <option value="repair">Reparatur</option>
                                                                                            <option value="task">Aufgabe</option>
                                                                                        </Form.Select>
                                                                                    </Box>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </Col>
                                                                            {!!inspectionCreatePost && item.inspection?.inspectionCategory !== 'news' ?
                                                                                <>
                                                                                    <Col md={8}>
                                                                                        <Box className='mb-1'>
                                                                                            <Form.Label>Typ</Form.Label>
                                                                                            <Box>
                                                                                                <FormCheck
                                                                                                    type='radio'
                                                                                                    name='inspectionType'
                                                                                                    Id='inspectionNoType'
                                                                                                    value='no'
                                                                                                    label='Keine'
                                                                                                    isFormGroup={false}
                                                                                                    isInline={true}
                                                                                                    disabled={false}
                                                                                                    defaultChecked={true}
                                                                                                    handleChange={(event: ChangeEvent<HTMLInputElement>) => handleInspectionTypeChange(event, index)}
                                                                                                />
                                                                                                <FormCheck
                                                                                                    type='radio'
                                                                                                    name='inspectionType'
                                                                                                    Id='custom'
                                                                                                    value='custom'
                                                                                                    label='Benutzerdefiniert'
                                                                                                    isFormGroup={false}
                                                                                                    isInline={true}
                                                                                                    disabled={false}
                                                                                                    handleChange={(event: ChangeEvent<HTMLInputElement>) => handleInspectionTypeChange(event, index)}
                                                                                                />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Col>
                                                                                    {item.inspection?.inspectionType === 'custom' ?
                                                                                        <Col md={4}>
                                                                                            <DisplayCenter verticalAlign='end' className='h-100'>
                                                                                                <InputGroup size="sm" className="mb-3">
                                                                                                    <FormControl Class='checklist-input' isFormGroup={false} Type='text' Size='sm' Placeholder='Liste' Name={'option-' + index} Id={'option-' + index} />
                                                                                                    <ButtonWidget
                                                                                                        varient='light'
                                                                                                        text={
                                                                                                            <>
                                                                                                                <DisplayCenter>
                                                                                                                    <HiPlusCircle size="20px" />
                                                                                                                </DisplayCenter>
                                                                                                            </>
                                                                                                        }
                                                                                                        class='remove-checkList-btn'
                                                                                                        handleClick={() => setShowAllListModal(true)}
                                                                                                    />
                                                                                                </InputGroup>
                                                                                            </DisplayCenter>
                                                                                        </Col>
                                                                                        :
                                                                                        null
                                                                                    }

                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                            {!!inspectionCreatePost && item.inspection?.inspectionCategory !== 'news' ?
                                                                                <>
                                                                                    <Col md={8}>
                                                                                        <Box className='mb-1'>
                                                                                            <Form.Label>Ort</Form.Label>
                                                                                            <Box>
                                                                                                <FormCheck
                                                                                                    type='radio'
                                                                                                    name='inspectionLocation'
                                                                                                    Id='inspectionNoLocation'
                                                                                                    value='no'
                                                                                                    label='Keine'
                                                                                                    isFormGroup={false}
                                                                                                    isInline={true}
                                                                                                    disabled={false}
                                                                                                    defaultChecked={true}
                                                                                                    handleChange={(event: ChangeEvent<HTMLInputElement>) => handleInspectionLocationChange(event, index)}
                                                                                                />
                                                                                                <FormCheck
                                                                                                    type='radio'
                                                                                                    name='inspectionLocation'
                                                                                                    Id='lastWaypoint'
                                                                                                    value='lastWaypoint'
                                                                                                    label='Letzter Wegpunkt'
                                                                                                    isFormGroup={false}
                                                                                                    isInline={true}
                                                                                                    disabled={false}
                                                                                                    handleChange={(event: ChangeEvent<HTMLInputElement>) => handleInspectionLocationChange(event, index)}
                                                                                                />
                                                                                                <FormCheck
                                                                                                    type='radio'
                                                                                                    name='inspectionLocation'
                                                                                                    Id='identicalTask'
                                                                                                    value='identicalTask'
                                                                                                    label='Letzter Wegpunkt'
                                                                                                    isFormGroup={false}
                                                                                                    isInline={true}
                                                                                                    disabled={false}
                                                                                                    handleChange={(event: ChangeEvent<HTMLInputElement>) => handleInspectionLocationChange(event, index)}
                                                                                                />
                                                                                                <FormCheck
                                                                                                    type='radio'
                                                                                                    name='inspectionLocation'
                                                                                                    Id='chooseTask'
                                                                                                    value='choose'
                                                                                                    label='Auswählen'
                                                                                                    isFormGroup={false}
                                                                                                    isInline={true}
                                                                                                    disabled={false}
                                                                                                    handleChange={(event: ChangeEvent<HTMLInputElement>) => handleInspectionLocationChange(event, index)}
                                                                                                />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Col>
                                                                                    {item.inspection?.inspectionLocation === 'choose' ?
                                                                                        <Col md={4}>
                                                                                            <DisplayCenter verticalAlign='end' className='h-100'>
                                                                                                <InputGroup size="sm" className="mb-2">
                                                                                                    <FormControl Class='checklist-input' isFormGroup={false} Type='text' Size='sm' Placeholder='Ort' Name={'option-' + index} Id={'option-' + index} />
                                                                                                    <ButtonWidget
                                                                                                        varient='light'
                                                                                                        text={
                                                                                                            <>
                                                                                                                <DisplayCenter>
                                                                                                                    <HiPlusCircle size="20px" />
                                                                                                                </DisplayCenter>
                                                                                                            </>
                                                                                                        }
                                                                                                        class='remove-checkList-btn'
                                                                                                        handleClick={() => setShowAllPlacesModal(true)}
                                                                                                    />
                                                                                                </InputGroup>
                                                                                            </DisplayCenter>

                                                                                        </Col>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                        </Row>
                                                                    </Box>
                                                                </ChecklistDragContainer>
                                                            )}
                                                        </Draggable>
                                                    </>
                                                    :
                                                    null
                                                }
                                            </>
                                        })
                                        }
                                    </>
                                }
                            </div>

                        )
                    }
                </Droppable>
            </DragDropContext>


            <CommonModal
                showModal={showAllListModal}
                hideModal={() => setShowAllListModal(false)}
                modalTitle='Wähle eine Liste'
                modalContent={<ShowAllListModalContent />}
                modalSize='lg'
                modalFooterContent={<ShowAllListFooterContent showModal={() => setManageListModal(true)} hideModal={() => setShowAllListModal(false)} />}
            />
            <CommonModal
                showModal={manageListModal}
                hideModal={() => setManageListModal(false)}
                modalTitle='Listen verwalten'
                modalContent={<ManageListModalContent />}
                modalSize='lg'
                modalFooterContent={<ManageListFooterContent hideModal={() => setManageListModal(false)} />}
                bodyStyleClass='pt-0 pb-0'
                modelId='manageList'
            />


            <CommonModal
                showModal={showAllPlacesModal}
                hideModal={() => setShowAllPlacesModal(false)}
                modalTitle='Wähle einen Ort'
                modalContent={<ShowAllPlacesModalContent />}
                modalSize='lg'
                modalFooterContent={<ShowAllPlacesFooterContent showModal={() => setManagePlaceModal(true)} hideModal={() => setShowAllPlacesModal(false)} />}
            />
            <CommonModal
                showModal={managePlaceModal}
                hideModal={() => setManagePlaceModal(false)}
                modalTitle='Orte verwalten'
                modalContent={<ManagePlacesModalContent />}
                modalSize='lg'
                modalFooterContent={<ManagePlaceFooterContent hideModal={() => setManagePlaceModal(false)} />}
                bodyStyleClass='pt-0 pb-0'
                modelId='managePlace'
            />
            <Recipient displayRecipientModal={RecipientModal} hideRecipientModal={() => setRecipientModal(false)} />

        </>
    );
}
export default CheckListWidget;



