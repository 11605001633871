import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, } from 'react-bootstrap';
import CategoryTemplateModal from './ManageTemplateCategoryModal';
import { ModalHeader } from '../StyledComponents';
import { JsxElement } from 'typescript';

interface CategoryModalType {
    displayCategoryModal: boolean,
    hideCategoryModal: () => void,
    manageCategoriesClickHandler?: () => void,
    modalContent?: any
}

const ChooseTemplateCategoryModal = (props: CategoryModalType) => {
    return (
        <>
            <Modal size="lg" className='shadow-modal' show={props.displayCategoryModal} onHide={props.hideCategoryModal} backdrop="static" keyboard={false} aria-labelledby="template-modal" >
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="template-modal">
                        Kategorie auswählen
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    {!!props.modalContent ?
                        <>
                            {props.modalContent}
                        </>
                        :
                        <Row>
                            <Col md={12}>
                                <div className='empty-category'>
                                    <span>Es wurden noch keine Kategorien erstellt</span>
                                </div>
                            </Col>
                        </Row>
                    }

                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <div>
                        <Button className='default-btn f-14px me-2' onClick={props.manageCategoriesClickHandler}>Kategorien verwalten</Button>
                    </div>
                    <div>
                        <Button className='primary-bg f-14px me-1'>Auswählen</Button>
                        <Button onClick={props.hideCategoryModal} className='default-btn f-14px me-2' >Abbrechen</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ChooseTemplateCategoryModal;