import React, { FC } from "react";
import { IInputProps } from "../Interface/IInputProps";
import { Control, FieldError, Controller } from "react-hook-form";
import { InlineBox } from "../../StyledComponents";
import { Form } from "react-bootstrap";

interface IProps extends IInputProps {
  fieldType?: any;
  fieldName: string;
  fieldId?: string;
  control: Control<any, any>;
  error?: FieldError;
  label?: any;
  value?: any;
  isInline?: boolean;
  disabled?: any;
  checked?: boolean;
  defaultChecked?: boolean;
}

export const CheckControl: FC<IProps> = ({
  required,
  min,
  minLength,
  max,
  maxLength,
  fieldType,
  fieldName,
  fieldId,
  control,
  error,
  label,
  value,
  isInline,
  disabled,
  checked,
  defaultChecked,
}) => {
  return (
    <>
      {control && (
        <Controller
          name={fieldName}
          control={control}
          rules={{ required, min, minLength, max, maxLength }}
          render={({ field: { name, onBlur, onChange, ref } }) => (
            <>
              {isInline ? (
                <InlineBox key={`reverse-${fieldType}`}>
                  <Form.Check
                    inline
                    label={label}
                    name={name}
                    id={fieldId}
                    type={fieldType}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={checked}
                    defaultChecked={defaultChecked}
                    ref={ref}
                    isInvalid={!!error}
                  />
                </InlineBox>
              ) : (
                <div key={`reverse-${fieldType}`}>
                  <Form.Check
                    label={label}
                    name={name}
                    id={fieldId}
                    type={fieldType}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={checked}
                    defaultChecked={defaultChecked}
                    ref={ref}
                    isInvalid={!!error}
                  />
                </div>
              )}
            </>
          )}
        />
      )}

      {error && (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  );
};
