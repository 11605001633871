import { FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BodyText, Box, DisplayEnd } from "../../../Components/StyledComponents";
import ButtonWidget from "../../../Components/ButtonWidget";
import AddEditIdea from "../../Idea/AddEditIdea";

interface IProps { }


const IdeasConfig: FC<IProps> = ({ }) => {

    const [IdeaModal, setIdeaModal] = useState<boolean>(false);

    return (
        <>
            <Row>
                <Col md={12}>
                    <h2> Ideen </h2>
                    <BodyText fontSize='15px' fontWeight='400' textColor='#666' mt="12px" mb="15px"> Vorlage ändern, die für alle neu erstellten Ideen verwendet wird, für die keine Vorlage eingestellt wurden. </BodyText>
                    <ButtonWidget varient="primary" text="Standard Idee bearbeiten" class='text-nowrap' handleClick={() => setIdeaModal(true)} />
                </Col>
                <Col md={12}>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
            </Row>

            <AddEditIdea showIdea={IdeaModal} hideIdea={() => setIdeaModal(false)} />
        </>
    );
}

export default IdeasConfig;