import React, { useState, useEffect } from 'react';
import { DisplayEnd } from '../../Components/StyledComponents';
import { useOutletContext } from "react-router-dom";
import CommonModal from '../../Components/Modals/CommonModal';
import { SendReminderModalContent } from '../../Components/Modals/ModelContents/SendReminderModalContent';
import ButtonWidget from '../../Components/ButtonWidget';
import { MainContent } from './MainContent';
import { TaskList } from './TaskList';



export const TaskListWrapper = () => {

    const [taskData, setTaskData, displayMode]: any = useOutletContext();

    const [sendReminder, setSendReminder] = useState<boolean>(false);

    return (
        <>


            <TaskList
                listData={taskData}
                setListData={setTaskData}
                displayMode={displayMode}
            />

            <CommonModal
                showModal={sendReminder}
                hideModal={() => setSendReminder(false)}
                modalTitle='Erinnerung senden'
                modalContent={<SendReminderModalContent />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Schließen'}
                                class='me-2 ms-3'
                                handleClick={() => setSendReminder(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Ausgewählte erinnem'}
                                disabled={true}
                            />
                        </DisplayEnd>
                    </>
                }
                footerClass={"footer-sticky"}
            />
        </>
    )
}