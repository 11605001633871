import React, { useState, useEffect, useRef, forwardRef } from "react";
import { styled } from '@mui/material/styles';
import ChangeEventParameteres from "./changeeventparameterspopup";
import CalendarList from "./calendarlist";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import style from 'styled-components';
import { GrUser, GrUserFemale, GrPhone, GrClose } from "react-icons/gr";
import Badge from '@mui/material/Badge';
import { useSelector, useDispatch } from 'react-redux';
import { setRerenderAppointmentData, setmoveWaitingCheck } from '../../Redux/Slice/calendarSlice';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import moment from 'moment';
import { FaCircle, FaCut, FaHourglassHalf, FaPencilAlt, FaTrash } from "react-icons/fa";





const Row = style.div`
display:flex;
flex-wrap:wrap;
margin-left: -6px;
    margin-right: -6px;
`


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SpaceBetween = style.div`
display:flex;
justify-content:space-between;
align-items:center;`

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _weekdays: [
        "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa",
      ],
      _timelineWidth: 50,
      _appointmentWidth: 95,
      _time_rows: [
        "0:00", "1:00", "2:00", "3:00", "4:00", "5:00",
        "6:00", "7:00", "8:00", "9:00", "10:00", "11:00",
        "12:00", "13:00", "14:00", "15:00", "16:00", "17:00",
        "18:00", "19:00", "20:00", "21:00", "22:00", "23:00",
      ],
      _real_time_rows: [],
      _resizeHeight: 8,

      zeitraum: { type: "week", uuid: "wwyDaa2-RVCu98imABQQzg", date: "2021-5-18" },
      range: { end: "2021-05-23", start: "2021-05-17" },

      anonyme: { active: false, patienId: 0 },

      start: new Date(),
      _daysNumber: 1,
      _highlightsNumber: 0,

      _dayStart: 7,
      _dayEnd: 20,

      _dayHeightBase: 2880,
      _dayHeightMulti: 1,
      _dayHeight: 2880,
      _hourHeight: 120,
      show: false,
      mylatesttap: null,
      Last_handleDoubletapHeader: null,
      Last_handleDoubletapSpaltenHeader: null,
      Last_handleDoubletapFullDay: null,
      TriggerParent: true,
      showAppPopup: false,
      showAppDetail: false,
      selectedAppointmentId: null,
      screenWidth: 0
    }

    this._handleAppMove = this._handleAppMove.bind(this);
    this._handleAppResize = this._handleAppResize.bind(this);

    this._handleDoubleClickHeader = this._handleDoubleClickHeader.bind(this);
    this._handleDoubleClickSpaltenHeader = this._handleDoubleClickSpaltenHeader.bind(this);
    this._handleDoubleClickFullDay = this._handleDoubleClickFullDay.bind(this);
    this._handleDoubletapHeader = this._handleDoubletapHeader.bind(this);
    this._handleDoubletapSpaltenHeader = this._handleDoubletapSpaltenHeader.bind(this);
    this._handleDoubletapFullDay = this._handleDoubletapFullDay.bind(this);

    this._handleDoubleClickSpaltenBody = this._handleDoubleClickSpaltenBody.bind(this);
    this._handleDoubleClickItem = this._handleDoubleClickItem.bind(this);
    this.ShowPopUP = this.ShowPopUP.bind(this);
    this.Sectiondoubletap = this.Sectiondoubletap.bind(this);

    this.initDrag = this.initDrag.bind(this);
    this.doDrag = this.doDrag.bind(this);
    this.stopDrag = this.stopDrag.bind(this);

    this.drag = this.drag.bind(this);
    this.allowDrop = this.allowDrop.bind(this);
    this.drop = this.drop.bind(this);

    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.openAppointmentDetail = this.openAppointmentDetail.bind(this);
    this.hideAppointmentPopup = this.hideAppointmentPopup.bind(this);
    this.appointmentMoveToClipBoard = this.appointmentMoveToClipBoard.bind(this);
    this.delAppFromCalendar = this.delAppFromCalendar.bind(this);
    this.hideAppointmentPopupFun = this.hideAppointmentPopupFun.bind(this);
    this.dragablediv = React.createRef();
    this.endhere = React.createRef();

  }

  ShowPopUP(val) {
    this.setState({
      show: val
    })
  }


  componentDidMount() {
    var self = this;
    this.setState({
      screenWidth: document.querySelector(".ds-calendar").clientWidth - 700
    })
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    var self = this;
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    console.log(document.querySelector(".ds-calendar").clientWidth - 700)
    if (document.querySelector(".ds-calendar").clientWidth - 700 > 450) {
      this.setState({
        screenWidth: document.querySelector(".ds-calendar").clientWidth - 700
      })
    } else {
      this.setState({
        screenWidth: 320

      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    // do things with nextProps.someProp and prevState.cachedSomeProp
    if (nextProps.view === "overview") {
      return {
        _timelineWidth: 100,
        _dayHeight: 2880 * parseFloat(nextProps.zoomValue),
        _dayHeightBase: 2880 * parseFloat(nextProps.zoomValue)
        // ... other derived state properties
      };
    }
    else {
      return {
        _timelineWidth: 50,
        _dayHeight: 2880 * parseFloat(nextProps.zoomValue),
        _dayHeightBase: 2880 * parseFloat(nextProps.zoomValue)
        // ... other derived state properties
      };
    }

  }
  _handleDoubletapHeader(e) {
    e.preventDefault();
    e.stopPropagation()
    var now = new Date().getTime();
    var timesince = now - this.state.Last_handleDoubletapHeader;
    if ((timesince < 300) && (timesince > 0)) {
      // double tap   
      this._handleDoubleClickHeader(e)
    }

    this.state.Last_handleDoubletapHeader = new Date().getTime();
  }
  _handleDoubletapSpaltenHeader(e) {
    e.preventDefault();
    e.stopPropagation()
    var now = new Date().getTime();
    var timesince = now - this.state.Last_handleDoubletapSpaltenHeader;
    if ((timesince < 300) && (timesince > 0)) {

      // double tap   
      this._handleDoubleClickSpaltenHeader(e)
      console.log("_handleDoubleClickSpaltenHeader worked")

    }

    this.state.Last_handleDoubletapSpaltenHeader = new Date().getTime();
  }
  _handleDoubletapFullDay(e) {
    e.preventDefault();
    e.stopPropagation()
    var now = new Date().getTime();
    var timesince = now - this.state.Last_handleDoubletapFullDay;
    if ((timesince < 300) && (timesince > 0)) {

      // double tap   
      this._handleDoubleClickFullDay(e)
      console.log("_handleDoubleClickFullDay worked")

    }

    this.state.Last_handleDoubletapFullDay = new Date().getTime();
  }
  openAppointmentDetail = (e, id) => {
    e.stopPropagation()
    let currentUrl = window.location.href;
    if (currentUrl.toLowerCase().indexOf("kanban") === -1) {
      this.setState({
        showAppPopup: true,
        selectedAppointmentId: id
      })
    }
    else {
      this.setState({
        showAppDetail: true,
        selectedAppointmentId: id
      })
    }
  }

  hideAppointmentPopup = () => {
    this.setState({
      showAppPopup: false
    })
  }
  hideAppointmentPopupFun = () => {
    this.setState({
      showAppDetail: false
    })
  }
  appointmentMoveToClipBoard = (e) => {
    this.props.moveAppToClipBorad(e)
  }
  delAppFromCalendar = (e) => {
    this.props.deleteAppFromCalendar(e)
  }

  Sectiondoubletap(e) {
    e.preventDefault();
    e.stopPropagation()
    let currentUrl = window.location.href;
    if (currentUrl.toLowerCase().indexOf("kanban") === -1) {
      this._handleDoubleClickSpaltenBody(e);
      this.props.setShow(true);

        

    }
    //   if(this.state.TriggerParent === true){
    //   var now = new Date().getTime();
    //   var timesince = now - this.state.mylatesttap;
    //   if((timesince < 300) && (timesince > 0)){

    //    // double tap   
    //    console.log("Sectiondoubletap worked")

    //   }

    //   this.state.mylatesttap = new Date().getTime();
    // }
    //  else{
    //   this.state.TriggerParent = true
    // }
  }
  _handleAppMove(index, newFrom, section, year, month, date) {
    this.props.onCalendarEvent("onEventMove", {
      index: index,
      newFrom: newFrom,
      section: section,
      year: year,
      month: month,
      date: date,
    })
  }

  _handleAppResize(index, newLength) {
    this.props.onCalendarEvent("onEventResize", {
      index: index,
      newLength: newLength,
    })
  }
  //-----header click
  _handleDoubleClickHeader(ev) {
    ev.stopPropagation();
    let item = ev.target;
    for (var i = 0; i < 10; i++) {
      if (item.dataset.dsRt != "columnhead") {
        item = item.parentNode;
      }
      else {
        this.props.onCalendarEvent("onHeaderClick", {
          year: item.dataset.year,
          month: item.dataset.month,
          date: item.dataset.date,
        })
        break;
      }
    }
  }

  _handleDoubleClickSpaltenHeader(ev) {
    ev.stopPropagation();
    let item = ev.target;
    for (var i = 0; i < 10; i++) {
      if (item.dataset.dsRt != "spaltenhead") {
        item = item.parentNode;
      }
      else {
        this.props.onCalendarEvent("onSectionHeaderClick", {
          year: item.dataset.year,
          month: item.dataset.month,
          date: item.dataset.date,
          section: item.dataset.section,
        })
        break;
      }
    }
  }

  _handleDoubleClickFullDay(ev) {
    ev.stopPropagation();
    let item = ev.target;
    for (var i = 0; i < 10; i++) {
      if (item.dataset.dsRt != "timeblock") {
        //console.log("dsRt: " + item.dataset.dsRt);
        item = item.parentNode;
      }
      else {
        this.props.onCalendarEvent("onFulldayClick", {
          type: item.dataset.type,
          index: item.dataset.index,
        })
        break;
      }
    }
  }

  //-----body click
  _handleDoubleClickSpaltenBody(ev) {
    ev.stopPropagation();
    let item = ev.target;
    for (var i = 0; i < 10; i++) {
      if (item.dataset.dsRt != "spaltenbody") {
        //console.log("dsRt: " + item.dataset.dsRt);
        item = item.parentNode;
      }
      else {

        var rect = item.getBoundingClientRect();
        //var x = ev.clientX - rect.left; //x position within the element.
        var y = ev.clientY - rect.top;  //y position within the element.

        let hours = y / this.state._hourHeight;
        let _hours = parseInt(y);
        let _rest = hours - _hours - 0.15;
        let _rest2 = parseInt(_rest / 0.25);

        let newFrom = (this.state._dayStart + _hours) * 60 + _rest2 * 15;

        let top = parseInt(document.defaultView.getComputedStyle(item).top, 10)

        this.props.onCalendarEvent("onSpalteClick", {
          year: item.dataset.year,
          month: item.dataset.month,
          date: item.dataset.date,
          section: item.dataset.section,
          time: newFrom,
        })
        break;
      }
    }
  }

  _handleDoubleClickItem(ev) {
    ev.stopPropagation();
    let item = ev.target;
    for (var i = 0; i < 10; i++) {
      if (item.dataset.dsRt != "timeblock") {
        //console.log("dsRt: " + item.dataset.dsRt);
        item = item.parentNode;
      }
      else {
        // this.props.onCalendarEvent("onEventClick", {
        //   index: item.dataset.index,
        // })
        break;
      }
    }
  }


  //------resize start
  initDrag(e) {
    //console.log("initDrag");
    let currentUrl = window.location.href;
    if (currentUrl.toLowerCase().indexOf("kanban") === -1) {
    this.state.currentResizeEl = e.target.parentNode;

    this.state.resizeStartX = e.clientX;
    this.state.resizeStartY = e.clientY;

    this.state.resizeStartWidth = parseInt(document.defaultView.getComputedStyle(this.state.currentResizeEl).width, 10);
    this.state.resizeStartHeight = parseInt(document.defaultView.getComputedStyle(this.state.currentResizeEl).height, 10);

    document.documentElement.addEventListener('mousemove', this.doDrag, false);
    document.documentElement.addEventListener('mouseup', this.stopDrag, false);
    }
  }

  doDrag(e) {
    //console.log("doDrag");

    //this.state.currentResizeEl.style.width = (this.state.resizeStartWidth + e.clientX - this.state.startX) + 'px';
    //this.state.currentResizeEl.style.height = (this.state.resizeStartHeight + e.clientY - this.state.resizeStartY) + 'px';
    let currentUrl = window.location.href;
    if (currentUrl.toLowerCase().indexOf("kanban") === -1) {
    let height = (this.state.resizeStartHeight + e.clientY - this.state.resizeStartY);
    console.log("do drag")
    /*
    let hours = height / this.state._hourHeight;
    let _hours = parseInt(height);
    let _rest = hours - _hours - 0.15;
    let _rest2 = parseInt(_rest / 0.25);
    //console.log("resize hours: " + hours);
    //console.log("resize _hours: " + _hours);
    //console.log("resize _rest: " + _rest);
    //console.log("resize _rest2: " + _rest2);

    let correct = (_hours + _rest2 * 0.25) * this.state._hourHeight;
    //console.log("resize correct: " + correct);
    this.state.resizeCorrectHeight = Math.max(correct, this.state._hourHeight / 4);
    */
    /*
    console.log("doDrag this.state.resizeStartHeight: " + this.state.resizeStartHeight);
    console.log("doDrag height: " + height);
    console.log("doDrag _hourHeight: " + this.state._hourHeight);

    let hours = height / this.state._hourHeight;
    let _hours = parseInt(hours);
    let _rest = hours - _hours;

    console.log("doDrag hours: " + hours);
    console.log("doDrag _hours: " + _hours);
    console.log("doDrag _rest: " + _rest);

    height = hours * this.state._hourHeight;

    console.log("doDrag height: " + height);
    */
    this.state.currentResizeEl.style.height = height + 'px';}
  }

  stopDrag(e) {
    //console.log("stopDrag");
    
    let currentUrl = window.location.href;
    if (currentUrl.toLowerCase().indexOf("kanban") === -1) {
    let height = (this.state.resizeStartHeight + e.clientY - this.state.resizeStartY);

    let hours = height / this.state._hourHeight;
    let _hours = parseInt(height);
    let _rest = hours - _hours - 0.15;
    let _rest2 = parseInt(_rest / 0.25);
    //console.log("resize hours: " + hours);
    //console.log("resize _hours: " + _hours);
    //console.log("resize _rest: " + _rest);
    //console.log("resize _rest2: " + _rest2);

    let correct = (_hours + _rest2 * 0.25) * this.state._hourHeight;
    //console.log("resize correct: " + correct);
    this.state.resizeCorrectHeight = Math.max(correct, this.state._hourHeight / 4);

    let index = this.state.currentResizeEl.dataset.index;
    let newLength = this.state.resizeCorrectHeight / this.state._hourHeight * 60;
      console.log("handle resize call ho gaya")
    this._handleAppResize(index, newLength);

    //this.state.currentResizeEl.style.height = this.state.resizeCorrectHeight + 'px';


    document.documentElement.removeEventListener('mousemove', this.doDrag, false);
    document.documentElement.removeEventListener('mouseup', this.stopDrag, false);
    }
  }
  //------resize end

  //------dnd start
  drag(ev) {
    //debugger
    this.state.currentDnDEl = ev.target.parentNode;
    //ev.dataTransfer.setData('text', ev.target.id);
    console.log("dragging")
    this.state.DnDStartX = ev.clientX;
    this.state.DnDStartY = ev.clientY;
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  drop(ev) {
    debugger
    ev.preventDefault();
    //var data = ev.dataTransfer.getData('text');
    //ev.item.appendChild(document.getElementById(data));

    //ev.item.appendChild(this.state.currentDnDEl);
    let item = ev.target;
    for (var i = 0; i < 10; i++) {
      if (item.dataset.dsRt != "spaltenbody") {
        //console.log("className: " + item.className);
        item = item.parentNode;
      }
      else {
        //this.state.currentDnDEl.style.width = (this.state.resizeStartHeight + ev.clientX - this.state.DnDStartX) + 'px';
        //this.state.currentDnDEl.style.height = (this.state.resizeStartHeight + ev.clientY - this.state.DnDStartY) + 'px';

        let top = parseInt(document.defaultView.getComputedStyle(this.state.currentDnDEl).top, 10)
        let diff = ev.clientY - this.state.DnDStartY;

        let hours = (top + diff) / this.state._hourHeight;
        let _hours = parseInt(hours);
        let _rest = hours - _hours + 0.08;
        let _rest2 = parseInt(_rest / 0.25);
        let correct = (_hours + _rest2 * 0.25) * this.state._hourHeight;

        let index = this.state.currentDnDEl.dataset.index;
        let newFrom = (this.state._dayStart + _hours) * 60 + _rest2 * 15;
        let dataset = item.dataset;
        //let date = new Date(dataset.year, dataset.month-1, dataset.date);

        console.log("index: " + this.state.currentDnDEl.dataset.index);
        this._handleAppMove(index, newFrom, dataset.section, dataset.year, dataset.month, dataset.date);

        
        //this.state.currentDnDEl.style.top = correct + 'px';
        //item.appendChild(this.state.currentDnDEl);
        break;
      }
    }
  }
  //------dnd end  

  handleDrag(ev) {
    let item = ev.target;
    for (var i = 0; i < 10; i++) {
      if (item.dataset.dsRt != "timeblock") {
        console.log("dsRt: " + item.dataset.dsRt);
        item = item.parentNode;
      }
      else {
        this.state.currentDnDEl = item;
        this.state.DnDStartX = ev.targetTouches[0].clientX;
        this.state.DnDStartY = ev.targetTouches[0].clientY;
        break;
      }
    }
  }

  handleMove(ev) {
    // debugger
    this.state.DnDCurrentX = ev.targetTouches[0].clientX;
    this.state.DnDCurrentY = ev.targetTouches[0].clientY;
    console.log("check clientX = " + ev.targetTouches[0].clientX)
    console.log("check clientX = " + ev.targetTouches[0].clientY)
  }

  handleDrop(ev) {
    ev.preventDefault();
    //var data = ev.dataTransfer.getData('text');
    //ev.item.appendChild(document.getElementById(data));

    var changedTouch = ev.changedTouches[0];
    var elem = document.elementFromPoint(changedTouch.clientX, changedTouch.clientY);
    //ev.item.appendChild(this.state.currentDnDEl);
    let item = elem;
    for (var i = 0; i < 10; i++) {
      if (item.dataset != null) {
        if (item.dataset.dsRt != "spaltenbody") {
          console.log("dsRt: " + item.dataset.dsRt);
          item = item.parentNode;
        }
        else {
          //this.state.currentDnDEl.style.width = (this.state.resizeStartHeight + ev.clientX - this.state.DnDStartX) + 'px';
          //this.state.currentDnDEl.style.height = (this.state.resizeStartHeight + ev.clientY - this.state.DnDStartY) + 'px';

          let top = parseInt(document.defaultView.getComputedStyle(this.state.currentDnDEl).top, 10)
          let diff = this.state.DnDCurrentY - this.state.DnDStartY;

          let hours = (top + diff) / this.state._hourHeight;
          let _hours = parseInt(hours);
          let _rest = hours - _hours + 0.08;
          let _rest2 = parseInt(_rest / 0.25);

          let correct = (_hours + _rest2 * 0.25) * this.state._hourHeight;

          let index = this.state.currentDnDEl.dataset.index;
          let newFrom = (this.state._dayStart + _hours) * 60 + _rest2 * 15;
          let dataset = item.dataset;
          //let date = new Date(dataset.year, dataset.month-1, dataset.date);
          console.log("index: " + this.state.currentDnDEl.dataset.index);
          this._handleAppMove(index, newFrom, dataset.section, dataset.year, dataset.month, dataset.date);
          this.state.currentDnDEl = null;
          //this.state.currentDnDEl.style.top = correct + 'px';
          //item.appendChild(this.state.currentDnDEl);
          break;
        }
      }
    }
  }

  handleTouchStart(ev) {
    this.state.TriggerParent = false

    ev.stopPropagation();
    console.log("handleTouchStart");
    var now = new Date().getTime();
    var timesince = now - this.state.mylatesttap;
    if ((timesince < 300) && (timesince > 0)) {

      // double tap   
      this._handleDoubleClickItem(ev)
      console.log("Appoinmentdoubletap worked")

    } else {
      this.handleDrag(ev);
    }

    this.state.mylatesttap = new Date().getTime();




  }

  handleTouchMove(ev) {

    //console.log("handleTouchMove");

    ev.stopPropagation();


    this.handleMove(ev);
  }

  handleTouchEnd(ev) {
    console.log("handleTouchEnd");

    if (this.state.currentDnDEl !== undefined && this.state.currentDnDEl !== null) {

      this.handleDrop(ev);
    } else {
      console.log("it is null")
    }
  }


  drawCalendarTimeRow(key, top, time) {
    return <div key={key}
      style={{ top: top + "px" }}
      className="ds-calendar-row"
    >
      <div
        style={{ width: this.state._timelineWidth - 5/*95*/ + "px" }}
        className="ds-calendar-row-label"
      >{time}</div>
    </div>;
  }

  //-----header start
  calendarHeader() {
    var height = 60;
    height += this.state._highlightsNumber * 22;
    let year = this.props.date.getFullYear();

    return <div>
      <div className="ds-calendar-top-header">
        {this.props.view == "month" || this.props.view == "overview" ? <div style={{ marginLeft: "100px", height: "24px" }} className="ds-calendar-header-month"></div> : null}
        <div
          style={{ height: height + 2/*84*/ + "px" }}
          className="ds-calendar-header-back"
        >
          <div
            style={{ width: this.state._timelineWidth/*100*/ + "px", height: height/*82*/ + "px" }}
            className="ds-calendar-header-column ds-calendar-header-column-timeline"
          ></div>
          <div style={{ width: this.state._timelineWidth/*100*/ + "px", left: 0 + "px" }} className="ds-calendar-year-text">{year}</div>
          <div
            style={{ marginLeft: this.state._timelineWidth/*100*/ + "px" }}
            className="ds-calendar-header-column-area"
          >

            {this.state.dates.map((value, index) => {

              return this.drawHeaderColumn(index, this.state._daysNumber)
            })}

          </div>
        </div>
      </div>
    </div>;

  }

  getMonthName(date) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const d = date;
    localStorage.setItem("CurrentMonthName", monthNames[d.getMonth()] + " " + d.getFullYear());
    return monthNames[d.getMonth()] + " " + d.getFullYear();
  }
  getMonthnum(date) {
    const d = date;
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay;
  }
  drawHeaderColumn(index, count) {
    var date_entry = this.state.dates[index];
    var date = date_entry.date, higlights_entry = date_entry.highlight, holidays_entry = date_entry.holiday;

    var day = date_entry.day,
      daytext = date_entry.daytext,
      today = date_entry.today;
    //console.log("date: " + date.getDate());
    //console.log("today: " + today);

    var sectionwidth = 100 / count;
    //var holiday = 0;
    var holiday_text = "";
    if (holidays_entry) {
      holiday_text = holidays_entry.description;//"Christi Himmelfahrt 2021";
    }

    var columnclass = "ds-calendar-header-column";
    if (day == 6 || day == 0) {
      columnclass += " ds-calendar-weekend";
    }
    if (day == 0) {
      columnclass += " ds-calendar-sunday";
    }
    if (today) {
      columnclass += " ds-calendar-today";
    }
    // { section:1, id: 1, label: "Greifenberg, Wolfram"}

    return <div key={index}
      // onDoubleClick={this._handleDoubleClickHeader}
      data-year={date_entry.date.getFullYear()}
      data-month={date_entry.date.getMonth() + 1}
      data-date={date_entry.date.getDate()}
      onClick={this._handleDoubletapHeader}

      style={{ width: sectionwidth + "%", left: index * sectionwidth + "%" }}
      data-column-id="20210412"
      className={columnclass}
      data-ds-rt="columnhead"
      data-ds-rid="20210412_head"
      id="wYRDdHDdTU-wD7HjwAwUSw"
    >

      <div
        className={holidays_entry ? "ds-calendar-weekday ds-calendar-feiertag" : "ds-calendar-weekday"}
      >
        <div className="ds-calendar-column-title" >
          {index === 0 || this.getMonthnum(date).toString() == date.toString() ?
            <div class="ds-calendar-header-month-text">{this.getMonthName(date)}</div>
            : null}
          {this.props.view === "month" || this.props.view === "overview" ?
            <>
              <div
                className="ds-calendar-date-header"
                style={{ height: 20 + "px" }}
              >{daytext.split(" ")[0]}</div>
              <div
                className="ds-calendar-date-header"
                style={{ height: 18 + "px" }}
              >{daytext.split(" ")[1]}</div>
            </>
            :
            <div
              className="ds-calendar-date-header"
              style={{ height: 20 + "px" }}
            >{daytext}</div>
          }

          {!!holiday_text && <div
            className={holidays_entry ? "ds-calendar-date-header ds-calendar-feiertag-subtitle" : "ds-calendar-date-header"}
            style={{ height: 18 + "px" }}
          >{holiday_text}</div>
          }
        </div>
        <div style={{ height: 40 + "px" }} className="ds-calendar-day-highlight"></div>
        <div style={{ height: 40 + "px" }} className="ds-calendar-day-highlight"></div>
        <div style={{ height: 40 + "px" }} className="ds-calendar-day-highlight"></div>

        {this.state.sections.map((value, index) => {
          return this.drawSpaltenHeader(date_entry, index, this.state.sections.length)
        })}


      </div>
    </div>;
  }

  drawSpaltenHeader(date_entry, index, count) {
    var spaltenwidth = 100 / count;
    var spaltenheight = 22;

    spaltenheight += this.state._highlightsNumber * 22;

    var section = this.state.sections[index];

    var higlights_entry = date_entry.highlight;
    var fulldays = date_entry.fulldays;
    var section_fulldays = fulldays["section_" + section.id]
    var label = section.label;


    return this.props.view !== "overview" ? <div key={index}
      // onDoubleClick={this._handleDoubleClickSpaltenHeader}
      onClick={this._handleDoubletapSpaltenHeader}
      data-year={date_entry.date.getFullYear()}
      data-month={date_entry.date.getMonth() + 1}
      data-date={date_entry.date.getDate()}
      data-section={section.id}

      data-spaltendefinition-id="1"
      style={{ height: spaltenheight/*44*/ + "px", left: index * spaltenwidth + "%", width: spaltenwidth + "%" }}
      className="ds-calendar-spalten-header ds-calendar-spalten-unterteilung timeblock-ganztag-drop-accept"
      data-ds-rt="spaltenhead"
      data-ds-rid="1"
      id="wezNNpghRtCHfefpGr-ZoA"
    >

      <div className="ds-calendar-spalten-title">
        <span
          className="ds-t-nw ds-t-oe"
          data-ds-rt="label"
          data-ds-rid="1_tw"
          id="wuzuqDrxStOFdl9a12wSLw"
        >{this.props.view !== "month" && this.props.view !== "overview" ? label : null}</span>
      </div>

      {section_fulldays && section_fulldays.map((value, index) => {
        //console.log("spaltenHighlight: " + JSON.stringify(value));
        return this.drawSpaltenHighlight(value, index, this.state._highlightsNumber)
      })}

      {section_fulldays && section_fulldays.map((value, index) => {
        //console.log("fulldayTimeblock: " + JSON.stringify(value));
        return this.drawFulldayTimeblock(value, index, value.length)
      })}

    </div> :
      <div data-spaltendefinition-id="_singleColumn" style={{ height: "33px", left: "0%", width: "100%" }} data-ds-rt="spaltenhead" data-ds-rid="_singleColumn" className="ds-calendar-spalten-header ds-calendar-spalten-unterteilung timeblock-ganztag-drop-accept" id="wib9HI0mT-y5Lsh5akEVrQ"><div className="ds-calendar-spalten-title"></div></div>
      ;
  }

  //-----fulldays
  drawSpaltenHighlight(entry, index, count) {
    //console.log("drawSpaltenHighlight");
    let blockentry = entry.entry;
    //console.log("type: " + entry.type);
    //console.log("backgroundColor: " + blockentry.backgroundColor);
    return <div key={index}
      style={{ height: (22 + count * 22) + "px", backgroundColor: blockentry.backgroundColor }}
      className="ds-calendar-spalten-highlight">
    </div>;
  }

  drawFulldayTimeblock(entry, index, count) {
    //console.log("drawFulldayTimeblock");
    let blockentry = entry.entry;
    //console.log("border: " + blockentry.border);
    //console.log("backgroundColor: " + blockentry.backgroundColor);
    //console.log("color: " + blockentry.color);
    //console.log("description: " + blockentry.description);
    return <div key={index}
      // onDoubleClick={this._handleDoubleClickFullDay}
      onClick={this._handleDoubletapFullDay}
      data-index={entry.index}
      data-type={entry.type}

      style={{ top: (22 + index * 22) + "px" }}
      className="ds-timeblock ds-timeblock-ganztags"
      data-ds-rt="timeblock"
      data-ds-rid="1000100020"
      id="w7SXIX5TR-eVhJRapYea1A"
    >

      <div
        lang="de"
        data-ds-rt="view"
        data-ds-rid="1000100020_timeblockContentView"
        id="wr9ucvxBQNGLrOF8lePiCg"
      >

        <div
          style={{
            border: "1px solid " + blockentry.border/*"hsl(30, 100%, var(--hsL50))"*/,
            backgroundColor: blockentry.backgroundColor/*"hsl(30, 100%, var(--hsL50))"*/,
            color: blockentry.color/*"hsl(0, 0%, var(--hsL98))"*/
          }
          }
          className="ds-timeblockcontent"
          data-ds-rt="timeblockcontentwidget"
          data-ds-rid="1000100020_widget"
          id="wW4nvCbOTtixkhhOKUR34w"
        >

          <div style={{ color: blockentry.color/*"hsl(0, 0%, var(--hsL98))"*/ }} className="ds-timeblockcontentheader">
            <span
              className="ds-t-nw ds-t-oe"
              data-ds-rt="label"
              data-ds-rid="1000100020_def"
              id="wLOx1nUuQjyKtIN4XJbgYQ"
            >{blockentry.description}</span>
          </div>

          {false &&
            <div
              className="ds-timeblockcontent-topdiv"
            >
              <div
                style={{ marginLeft: 5 + "px", whiteSpace: "normal" }}
                className="ds-timefield ds-righttimeblockcontent"
              >
                <span
                  className="ds-t-nw ds-t-oe"
                  data-ds-rt="label"
                  data-ds-rid="wW4nvCbOTtixkhhOKUR34w_time"
                  id="wxcwH7qKRlu1fVr7QUkR5g"
                >00:00 - 24:00</span>
              </div>
            </div>
          }

        </div>

      </div>
    </div>
  }

  //-----header end
  getNumber(number) {
    if (number >= 10)
      return "" + number;
    else {
      return "0" + number;
    }
  }

  sort_appointments_entries(appointments_entries) {
    function compare(a, b) {
      if (a.entry.von_min < b.entry.von_min) {
        return -1;
      }
      if (a.entry.von_min > b.entry.von_min) {
        return 1;
      }
      return 0;
    }
    appointments_entries.sort(compare);
    return appointments_entries;
  }

  calculate_app_borders(appointments_entries) {

    appointments_entries = this.sort_appointments_entries(appointments_entries);

    //von_min: 420,
    //bis_min: 480,

    var appointment_grid = [];
    for (var i = 0; i < appointments_entries.length; i++) {
      var current = appointments_entries[i];

      var grid_element = { index: 0, upper: 1, lower: 1, middle: 0, count: 1, width: 1, others: [] };
      var others = [];
      grid_element.others = others;

      appointment_grid.push(grid_element)

      for (var j = 0; j < appointments_entries.length; j++) {
        var other = appointments_entries[j];

        if (i != j) {
          if (current.entry.von_min >= other.entry.von_min && current.entry.von_min < other.entry.bis_min) {
            //console.log("upper: current: " + current.id + " other: " + other.id);

            //console.log("upper: current.von_min: " + current.von_min);
            //console.log("upper: other.von_min: " + other.von_min + " other.bis_min: " + other.bis_min);
            //console.log("other: " + other.id);
            grid_element.upper += 1;
            if (!others.includes(j)) {
              others.push(j);
            }
          }
          if (current.entry.von_min < other.entry.von_min && current.entry.bis_min > other.entry.bis_min) {
            //console.log("middle: current: " + current.id + " other: " + other.id);

            //console.log("middle: current.von_min: " + current.von_min + " current.bis_min: " + current.bis_min);
            //console.log("middle: other.von_min: " + other.von_min + " other.bis_min: " + other.bis_min);
            //console.log("other: " + other.id);
            grid_element.middle += 1;
            if (!others.includes(j)) {
              others.push(j);
            }
          }
          if (current.entry.bis_min > other.entry.von_min && current.entry.bis_min <= other.entry.bis_min) {
            //console.log("lower: current: " + current.id + " other: " + other.id);

            //console.log("lower: current.bis_min: " + current.bis_min);
            //console.log("lower: other.von_min: " + other.von_min + " other.bis_min: " + other.bis_min);
            //console.log("other: " + other.id);
            grid_element.lower += 1;
            if (!others.includes(j)) {
              others.push(j);
            }
          }
        }
        grid_element.count = Math.max(grid_element.upper, grid_element.lower) + grid_element.middle;
      }
    }
    for (var i = 0; i < appointment_grid.length; i++) {
      var current_grid = appointment_grid[i];

      for (var j = 0; j < current_grid.others.length; j++) {
        var other = current_grid.others[j];
        var other_grid = appointment_grid[other];

        current_grid.count = Math.max(current_grid.count, other_grid.count)
      }
    }
    var second_grid = [];
    for (var i = 0; i < appointment_grid.length; i++) {
      var current_grid = appointment_grid[i];

      var free = 0;
      for (var j = 0; j < current_grid.count; j++) {
        var other_i = second_grid[j];

        if (current_grid.others.includes(other_i)) {

        }
        else {
          if (free) {
            //current_grid.width += 1;
          }
          else {
            current_grid.index = j;
            second_grid[j] = i;
            free = 1;
          }
          //break;
        }

      }

    }
    return appointment_grid;
  }

  prepareAppointments(date_entry, section) {
    var appointments_entries = [];

    //console.log("col date_string: " + date_entry.date_string);
    //console.log("col appointments: " + JSON.stringify(this.props.appointments));
    if (this.props.appointments) {
      //console.log("col app");

      let appointments = date_entry.appointments;
      for (var i = 0; i < appointments.length; i++) {
        var current_index = appointments[i];
        var current = this.props.appointments[current_index];

        if (current.section == section.id || current.sidesection == section.id) {
          appointments_entries.push({ index: current_index, entry: current });
        }
      }
    }
    return appointments_entries;
  }
  calendarBody() {
    var height = this.state._dayHeight/*2880*/ * (this.state._dayEnd - this.state._dayStart) / 24;
    return <div>
      <div
        style={{ height: 743 + "px", backgroundColor: "rgba(100, 100, 100, 0.2)", cursor: "default" }}
        className="ds-calendar-scroll-body"
      >
        <div
          style={{ height: this.props.view !== "overview" ? height/*2880*/ + "px" : 60 * this.state._real_time_rows.length + "px" }}
          className="ds-calendar-back"
        >
          <div style={{ width: this.state._timelineWidth/*100*/ + "px" }} className="ds-calendar-column ds-calendar-column-timeline"></div>

          <div style={{ marginLeft: this.state._timelineWidth/*100*/ + "px" }} className="ds-calendar-column-area">

            {this.state.dates.map((value, index) => {
              return this.drawBodyColumn(index, this.state._daysNumber)
            })}

          </div>

          {this.state._real_time_rows.map((value, index) => {
            return this.drawCalendarTimeRow(index, index * this.state._dayHeight / 24, value)
          })}


        </div>
      </div>
    </div>;
  }

  drawBodyColumn(index, count) {
    var sectionwidth = 100 / count;

    var date_entry = this.state.dates[index];
    var day = date_entry.day;
    var weekend = (day == 6 || day == 0);
    var sunday = (day == 0);
    return <div key={index}
      style={{ backgroundColor: weekend ? "rgba(100, 100, 100, 0.1)" : "", left: index * sectionwidth + "%", width: sectionwidth + "%" }}
      data-column-id="20210413"
      className={sunday ? "ds-calendar-column  ds-calendar-sunday" : "ds-calendar-column"}
      data-ds-rt="columnbody"
      data-ds-rid="20210413_body"
      id="wmmdayFiT66ujFG6_mgG_g">

      {this.state.sections.map((value, index) => {
        return this.drawBodyColumnSection(date_entry, value, index, this.state.sections.length)
      })}

    </div>;
  }

  drawBodyColumnSection(date_entry, section, index, count) {
    var sectionwidth = 100 / count;

    var higlights_entry = date_entry.highlight;

    var section = this.state.sections[index];
    var fulldays = date_entry.fulldays;
    var section_fulldays = fulldays["section_" + section.id]
    //console.log("section_fulldays: " + JSON.stringify(section_fulldays));

    //----- behandlungszeit
    var behandlungszeit_entries = date_entry.behandlungszeit["section_" + section.id];//this.prepareBehandlungszeit(date_entry, section);
    //console.log("behandlungszeit_entries: " + JSON.stringify(behandlungszeit_entries));
    //-----behandlungszeit

    //----- reserviert
    var reserviert_entries = date_entry.reserviert["section_" + section.id];//this.prepareReserviert(date_entry, section);
    //console.log("reserviert_entries: " + JSON.stringify(reserviert_entries));
    //-----reserviert

    //----- appointments
    var appointments_entries = this.prepareAppointments(date_entry, section);
    var appointments_grid = this.calculate_app_borders(appointments_entries);
    //-----appointments


    //console.log("section: " + section.section);


    return <div key={index}
      onTouchEnd={this.handleTouchEnd}
      onDrop={this.drop}
      onDragOver={this.allowDrop}
      onDoubleClick={this._handleDoubleClickSpaltenBody}
      // onClick={this.Sectiondoubletap}

      ref={this.endhere}
      data-year={date_entry.date.getFullYear()}
      data-month={date_entry.date.getMonth() + 1}
      data-date={date_entry.date.getDate()}
      data-section={section.id}
      //style="left:0%; width:33.3333%;" 
      style={{ left: index * sectionwidth + "%", width: sectionwidth + "%" }}
      className="ds-calendar-spalte timeblock-drop-accept ds-calendar-spalten-unterteilung"
      data-ds-rt="spaltenbody"
      data-ds-rid="1"
      id="wcmYkOAIRKO4cvu0Gw9bIA">
      <a type="<<<fullday start>>>"></a>
      {section_fulldays && section_fulldays.map((value, index) => {
        return this.drawBodyFullDayBlock(index, value);
      })}
      {false && higlights_entry && <div style={{ backgroundColor: "#7fff7f" }} className="ds-calendar-highlight"></div>}
      {false && <div className="ds-calendar-highlight"></div>}
      {false && <div className="ds-calendar-highlight"></div>}
      <a type="<<<fullday end>>>"></a>

     
      {behandlungszeit_entries.map((value, index) => {
        return this.drawBehandlungszeit(index, value)
      })}

      {reserviert_entries.map((value, index) => {
        return this.drawReserviert(index, value)
      })}

      {appointments_entries.map((value, index) => {
        //console.log("app: " + JSON.stringify(value));
        var current_grid = appointments_grid[index];
        //console.log("current_grid: " + JSON.stringify(current_grid));
        switch (value.entry.app_type) {
          case 0:
            //console.log("0");
            return this.drawAppointment(index, value, current_grid.index, current_grid.count, current_grid.width)
          case 2:
            //console.log("2");
            return this.drawAppointment(index, value, current_grid.index, current_grid.count, current_grid.width)
          case 3:
            //console.log("3");
            return this.drawAppointment(index, value, current_grid.index, current_grid.count, current_grid.width)
        }
      })}

    </div>;
  }

  //-----

  drawBodyFullDayBlock(key, entry) {
    let blockentry = entry.entry;
    //console.log("bodyFullDayBlock: " + JSON.stringify(entry));

    return <div key={key}
      style={{ backgroundColor: blockentry.backgroundColor/*"#7fff7f"*/ }}
      className="ds-calendar-highlight"></div>;
  }

  drawBehandlungszeit(key, index) {
    let entry = this.props.behandlungszeit[index];
    // let timearr = this.state._real_time_rows[index];
    /*var params = {
      von_min: 840,
      bis_min: 300, 
    };*/
    var von_perc = (entry.von_min - this.state._dayStart * 60) / 1440, bis_perc = (entry.bis_min - this.state._dayStart * 60) / 1440,

      top = von_perc * this.state._dayHeight,//2880,
      bottom = bis_perc * this.state._dayHeight,//2880,
      height = bottom - top + 1;


      

    return <div key={key}
      onClick={this.Sectiondoubletap}
      style={{ top: top + "px", left: 0.000000 + "%", height: height + "px", width: 100.000000 + "%" }}
      className="ds-timeblock no-blocking no-hover"
      data-ds-rt="timeblock"
      data-ds-rid="1015078_0#1"
      id="wF8IxdvLSB2SQcAlXKARvA"
      >
      
      <div
        style={{ backgroundColor: "hsl(0, 0%, var(--hsL100))" }}
        className="ds-timeblockcontent whitearea"
        data-ds-rt="timeblockcontentwidget"
        data-ds-rid="1015078_0#1_widget"
        id="wM6-mmWmTF6LgaVQkApBdg"></div>
    </div>;
  }

  drawReserviert(key, index) {
    let entry = this.props.reserviert[index];

    /*var params = {
      von_min: 1050,
      bis_min: 1080,
      text: "reserviert (nur Schmerzbehandlung)",
    };*/

    //var von_perc = params.von_min / 1440, bis_perc = params.bis_min / 1440,
    var von_perc = (entry.von_min - this.state._dayStart * 60) / 1440, bis_perc = (entry.bis_min - this.state._dayStart * 60) / 1440,

      top = von_perc * this.state._dayHeight,//2880,
      bottom = bis_perc * this.state._dayHeight,//2880,
      height = bottom - top + 1,

      von_text = this.getNumber(parseInt(entry.von_min / 60)) + ":" + this.getNumber(entry.von_min % 60),//"17:30",
      bis_text = this.getNumber(parseInt(entry.bis_min / 60)) + ":" + this.getNumber(entry.bis_min % 60);//"18:00";

    return <div key={key}
      style={{ top: top/*2100*/ + "px", left: 0.000000 + "%", height: height/*61*/ + "px", width: 100.000000 + "%" }}
      className="ds-timeblock no-blocking no-hover"
      data-ds-rt="timeblock"
      data-ds-rid="1015078_3#1"
      id="wapQ_HoGRdOTVzM_cfI3hg">
      <div
        //background-color: "hsl(60, 100%, var(--hsL75))", color:"hsl(0, 0%, var(--hsL2))"
        style={{ backgroundColor: "hsl(" + entry.hue + "180, 100%, var(--hsL75))", color: "hsl(0, 0%, var(--hsL2))" }}
        className="ds-timeblockcontent"
        data-ds-rt="timeblockcontentwidget"
        data-ds-rid="1015078_3#1_widget"
        id="wt4vYSUETOK7PJsUiaW93A">

        <div className="ds-timeblockcontent-topdiv">
          <div style={{ marginLeft: 5 + "px", textAlign: "left", float: "left" }}>
            <span
              className="ds-t-nw ds-t-oe"
              data-ds-rt="label"
              data-ds-rid="1015078_3_title"
              id="w79RpWfRRNuQ-wc5C2LtsA">{entry.text}</span>
          </div>
          <div
            style={{ marginLeft: 5 + "px", textAlign: "left", whiteSpace: "normal" }}
            className="ds-timefield ds-righttimeblockcontent">
            <span
              className="ds-t-nw ds-t-oe"
              data-ds-rt="label"
              data-ds-rid="wt4vYSUETOK7PJsUiaW93A_time"
              id="w4-bG92qTvS1CQIz1pS0Kw">{von_text + " - " + bis_text}</span>
          </div>
        </div>

      </div>
    </div>;
  }


  //-----appointment start
  drawAppointment(key, params, gridIndex, gridCount, gridWidth) {
    var sectionWidth = this.state._appointmentWidth / gridCount;

    let index = params.index, entry = params.entry;

    var params_ = {
      title: "PZR 60",
      patient: "Anonym",
      behandler: "Greifenberg, Wolfgang",
      behandlung: "Prophylaxe",
      type: "Bestell-Patient",

      von_min: 420,
      bis_min: 480,

      color: "#FFF2CC",
      border_color: "#006666",
      vorbereitung: 0,
      nachbereitung: 0,
    };
    //var von_perc = params.von_min / 1440, bis_perc = params.bis_min / 1440,
    var von_perc = (entry.von_min - this.state._dayStart * 60) / 1440, bis_perc = (entry.bis_min - this.state._dayStart * 60) / 1440,

      top = von_perc * this.state._dayHeight,//2880,
      bottom = bis_perc * this.state._dayHeight,//2880,
      height = bottom - top + 1,

      von_text = this.getNumber(parseInt(entry.von_min / 60)) + ":" + this.getNumber(entry.von_min % 60),//"17:30",
      bis_text = this.getNumber(parseInt(entry.bis_min / 60)) + ":" + this.getNumber(entry.bis_min % 60),//"18:00";
      length_text = entry.bis_min - entry.von_min + " Minuten";

    return <div key={key}
      onDoubleClick={this._handleDoubleClickItem}
      data-index={index}
      ref={this.dragablediv}
      onTouchStart={this.handleTouchStart}
      onTouchMove={this.handleTouchMove}
      onClick={(e) => { this.openAppointmentDetail(e, entry.id) }}

      //style="top:960px; left:0.000000%; height:121px; width:90.000000%;" 
      style={{
        top: top/*860*/ + "px",
        left: gridIndex * sectionWidth/*0.000000*/ + "%",
        height: height/*91*/ + "px",
        width: gridWidth * sectionWidth/*90.000000*/ + "%"
      }}
      className="ds-timeblock"
      data-ds-rt="timeblock"
      data-ds-rid="1000096"
      id="whDD3h85Q_SwSJKZEbM7ng"
    >

      <div
        style={{ height: this.state._resizeHeight/*10*/ + "px" }}
        className="resize-bottom"
        onMouseDown={this.initDrag}
      ></div>


      <div
        onDragStart={this.drag}
        lang="de"
        data-ds-rt="view"
        draggable="true"
        data-ds-rid="1000096_timeblockContentView"
        id="w4pG_DElTZKxkbs-ruA55Q"
      >
        <div
          //style="border:1px solid hsl(180, 100%, var(--hsL25));" 
          style={{ border: 1 + "px solid " + entry.border_color,/*"px solid hsl(180, 100%, var(--hsL25))",*/ }}
          className="ds-timeblockcontent"
          data-ds-rt="timeblockcontentwidget"
          data-ds-rid="1000096_widget"
          id="wvcAIM91SFGuznhA4eJ-wA"
        >

          <div style={{ height: entry.vorbereitung + "%" }} className="ds-timeblockvorbereitung"></div>
          <div style={{ height: entry.nachbereitung + "%" }} className="ds-timeblocknachbereitung"></div>

          {this.drawAppointmentContentHeader(entry)}

          {this.drawAppointmentTopdiv(entry, von_text, bis_text, length_text)}

          {this.drawAppointmentVerticalPanel(entry)}


        </div>
      </div>

    </div>;
  }

  //ContentHeader start
  drawAppointmentContentHeader(entry) {
    return <div className="ds-timeblockcontentheader">
      <p data-ds-rid="1000096_ico" id="wzeEpi9XRta7tYCR_t8JXA" data-ds-rt="icon" className="text-left ds-i">
        <span><FaCircle style={{ color: entry.border_color, }} className="fa-fw  fas fa-circle" /></span>
      </p>
      <div className="ds-t-nw" data-ds-rt="horizontalPanel" data-ds-rid="1000096_titel" id="wLFbNpMZThaOhQ1t3SOgxA">
        <div style={{ display: "inline-block" }}>
          <div className="ds-text-panel ds-t-nw ds-s-d" data-ds-rt="textPanel" data-ds-rid="1000096_patLbl" id="wo_NVuxSS5-nT8zol61K1Q">

            {entry.pat_nr && this.drawAppointmentBadge(entry)}

            {this.drawAppointmentPatLbl(entry)}

          </div>
        </div>
      </div>
    </div>
  }

  drawAppointmentBadge(entry) {
    return <span
      //style="background-color:hsl(200, 50%, var(--hsL45)); border-color:hsl(200, 50%, var(--hsL45)); color:hsl(0, 0%, var(--hsL98));"
      style={{ backgroundColor: entry.badge_color/*"hsl(200, 50%, var(--hsL45))"*/, borderColor: "hsl(200, 50%, var(--hsL45))", color: "hsl(0, 0%, var(--hsL98))" }}
      className="badge ds-badge-round" data-ds-rt="badge" data-ds-rid="1000063_patLbl_badge" id="wf6l5Fk_QAyxce9s3_wsIA">{entry.pat_nr}</span>;
  }

  drawAppointmentPatLbl(entry) {
    return <p
      data-ds-rid="1000096_patLbl_lbl"
      id="wAC29VJhRk6vGOsPlvdp_w"
      data-ds-rt="label"
      className={entry.pat_nr ? "text-left ds-t-nw ds-t-oe" : "text-left text-muted ds-t-nw ds-t-oe"}>

      <strong>{entry.patient}</strong>
    </p>;
  }
  //ContentHeader end

  drawAppointmentTopdiv(entry, von_text, bis_text, length_text) {
    return <div className="ds-timeblockcontent-topdiv">
      <div style={{ float: "left", textAlign: "left", marginLeft: 5 + "px" }}>
        <span
          className="ds-t-nw ds-t-oe"
          data-ds-rt="label"
          data-ds-rid="1000096_bodydef"
          id="wc1fURe0Q8u5dUWPcUqayQ"
        >{entry.title}</span>
      </div>
      <div style={{ marginLeft: 5 + "px" }} className="ds-timefield ds-righttimeblockcontent">
        <span
          className="ds-t-nw ds-t-oe"
          data-ds-rt="label"
          data-ds-rid="wvcAIM91SFGuznhA4eJ-wA_time"
          id="wgxANkfOQde-PgteW5Aa-A"
        >{von_text + " - " + bis_text}</span>
      </div>
      <div className="ds-durationfield ds-righttimeblockcontent">
        <span
          className="ds-t-nw ds-t-oe"
          data-ds-rt="label"
          data-ds-rid="wvcAIM91SFGuznhA4eJ-wA_duration"
          id="wNXtFSlaQom7XZv6cWGPig"
        >{length_text}</span>
      </div>
    </div>
  }

  drawAppointmentVerticalPanel(entry) {
    return <div
      data-ds-rt="verticalPanel"
      data-ds-rid="1000096_body"
      id="wj7XjV17R-O9xCwXgPnV2w">
      <div>
        <span
          className="ds-t-nw ds-t-oe"
          data-ds-rt="label"
          data-ds-rid="1000096_org"
          id="wkcb8dpkTlWApCynIqLL4g"
        >
          <strong>{entry.behandler}</strong>
        </span>
      </div>
      <div>
        <span
          className="ds-t-nw ds-t-oe"
          data-ds-rt="label"
          data-ds-rid="1000096_zimmer"
          id="w8B_e2AtQiit54dJEMN0XA"
        >{entry.zimmer}</span>
      </div>
      <div>
        <span
          className="ds-t-nw ds-t-oe"
          data-ds-rt="label"
          data-ds-rid="1000096_kategorie"
          id="w-6n6EFCQkCfgElx2fhpXw"
        >{entry.kategorie}</span>
      </div>
    </div>;
  }
  //-----appointment end


  getHighlight(dateString) {
    for (var i = 0; i < this.props.highlights.length; i++) {
      var current = this.props.highlights[i];
      if (current.date == dateString) {
        return { type: "highlight", index: i, entry: current };
      }
    }
  }

  getHoliday(dateString) {
    for (var i = 0; i < this.props.holidays.length; i++) {
      var current = this.props.holidays[i];
      if (current.date == dateString) {
        return { index: i, entry: current };
      }
    }
  }

  getFulldays(dateString, highlight) {
    let entries = [],
      result = {
        maxCount: 0,
        entries,
      };
    result.entries = entries;

    for (var j = 0; j < this.state.sections.length; j++) {
      var section = this.state.sections[j];
      //console.log("section: " + section.id);

      let highlightsNumber = 0;
      var temp_fulldays = [];
      if (highlight) {
        temp_fulldays.push({ type: "highlight", index: highlight.index, entry: highlight.entry });
        highlightsNumber += 1;
      }
      for (var k = 0; k < this.props.fulldays.length; k++) {
        var fullDay_appointment = this.props.fulldays[k];
        if (fullDay_appointment && fullDay_appointment.date == dateString) {
          //console.log("fullDay_appointment: " + JSON.stringify(fullDay_appointment));

          if (section == -1 || fullDay_appointment.sections.length == 0 ||
            fullDay_appointment.sections.includes(section.id)) {

            temp_fulldays.push({ type: "fullday", index: k, entry: fullDay_appointment });
            highlightsNumber += 1;
          }
        }
      }
      if (temp_fulldays.length > 0) {
        result.entries["section_" + section.id] = temp_fulldays;
      }
      result.maxCount = Math.max(result.maxCount, highlightsNumber);
    }
    return result;
  }

  getBehandlungszeit(dateString) {
    let result = [];
    for (var i = 0; i < this.state.sections.length; i++) {
      var section = this.state.sections[i];

      let arr = [];
      result["section_" + section.id] = arr;
    }
    let array = [];
    for (var i = 0; i < this.props.behandlungszeit.length; i++) {
      var current = this.props.behandlungszeit[i];

      if (current.date == dateString) {
        for (var j = 0; j < this.state.sections.length; j++) {
          var section = this.state.sections[j];
          if (section == -1 || current.section == section.id) {
            result["section_" + section.id].push(i);
          }
        }
      }
    }
    return result;
  }

  getReserviert(dateString) {
    let result = [];
    for (var i = 0; i < this.state.sections.length; i++) {
      var section = this.state.sections[i];

      let arr = [];
      result["section_" + section.id] = arr;
    }
    let array = [];
    for (var i = 0; i < this.props.reserviert.length; i++) {
      var current = this.props.reserviert[i];

      if (current.date == dateString) {
        for (var j = 0; j < this.state.sections.length; j++) {
          var section = this.state.sections[j];
          if (section == -1 || current.section == section.id) {
            result["section_" + section.id].push(i);
          }
        }
      }
    }
    return result;
  }

  getAppointments(dateString) {
    let appointments = [];
    for (var i = 0; i < this.props.appointments.length; i++) {
      var current = this.props.appointments[i];
      if (current.date == dateString) {
        appointments.push(i);
      }
    }
    return appointments;
  }


  updateState() {
    var date = this.props.date;
    var view = this.props.view;
    var start, daysNumber;
    var day = date.getDay();

    if (this.props.sections && this.props.sections.length > 0) {
      this.state.sections = this.props.sections;
    }
    else {
      this.state.sections = [-1];
    }
    this.state._dayHeight = this.state._dayHeightBase * this.state._dayHeightMulti;
    this.state._hourHeight = this.state._dayHeight / 24;
    //console.log("date: " + date);
    //console.log("view: " + view);
    if (view == "day") {
      start = date;
      daysNumber = 1;
    }
    if (view == "week") {
      if (day == 0) {
        start = date.addDays(-6);
      }
      else {
        start = date.addDays(-(day - 1));
      }
      daysNumber = 7;
    }
    if (view == "month") {
      start = date;
      let days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      daysNumber = parseInt(this.state.screenWidth / days);
    }
    if (view == "overview") {
      start = date;
      let days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      let overDate = parseInt(this.state.screenWidth / days) > 31 ? parseInt(this.state.screenWidth / days) : 31;
      daysNumber = overDate;
    }
    if (view == "list") {

    }
    if (start != this.state.start || daysNumber != this.state._daysNumber) {
      this.state.start = start;
      this.state._daysNumber = daysNumber;
    }
    var dates = [];

    var now = new Date();
    now.setHours(0, 0, 0, 0);

    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      today_time = today.getTime();

    this.state._highlightsNumber = 0;

    //console.log("today: " + today);
    for (var i = 0; i < this.state._daysNumber; i++) {
      var current = this.state.start.addDays(i);

      var day = current.getDay();
      var daytext = this.state._weekdays[day] + " " + ("0" + (current.getDate())).slice(-2) + "." + ("0" + (current.getMonth() + 1)).slice(-2);
      var monthDaytext = this.state._weekdays[day] + " " + ("0" + (current.getDate())).slice(-2);

      var date_string = current.getFullYear() + "-" + ("0" + (current.getMonth() + 1)).slice(-2) + "-" + ("0" + (current.getDate())).slice(-2);

      let appointments = this.getAppointments(date_string);

      //console.log("is today: " + (current == today));
      //console.log("current: " + current);
      var higlights_entry = this.getHighlight(date_string);
      var holidays_entry = this.getHoliday(date_string);
      //var fullDays_entry = this.props.fulldays[date_string];

      let behandlungszeit = this.getBehandlungszeit(date_string);
      let reserviert = this.getReserviert(date_string);

      let fulldaysStruct = this.getFulldays(date_string, higlights_entry);
      //console.log("fulldaysStruct: " + JSON.stringify(fulldaysStruct));
      this.state._highlightsNumber = Math.max(this.state._highlightsNumber, fulldaysStruct.maxCount);


      dates.push({
        date: current,
        day: day,
        today: current.getTime() == today_time,
        date_string: date_string,
        daytext: view == "month" || view == "overview" ? monthDaytext : daytext,

        highlight: higlights_entry,
        holiday: holidays_entry,
        fulldays: fulldaysStruct.entries,

        behandlungszeit: behandlungszeit,
        reserviert: reserviert,
        appointments: appointments,
      });
      //console.log("i: " + i + " " + this.state.start.addDays(i));

      //console.log("this.state._highlightsNumber: " + this.state._highlightsNumber);
    }
    this.state.dates = dates;

    //console.log("_highlightsNumber: " + this.state._highlightsNumber);

    this.state._real_time_rows = [];
    if (view === "overview") {
      this.state.sections.map(x => this.state._real_time_rows.push(x.label));

    }
    else {
      for (var i = this.state._dayStart; i < this.state._dayEnd; i++) {
        this.state._real_time_rows.push(this.state._time_rows[i]);
      }
    }
  }

  render() {

    this.updateState();

    return <>
      {this.props.view !== "list" ?
        <div className="ds-fi ds-fi-fhp" data-ds-rt="flexItem" data-ds-rid="calendarItem" id="w8GjQTOSQhuQeflOwRkqfg">
          <div className="ds-sizepanel-container" data-ds-rt="sizePanel" data-ds-rid="calPanel" id="wcENWmJzShW-itwr6VOiCw">

            <div style={{ minHeight: 240 + "px", minWidth: 100 + "px", height: 827 + "px", flex: "0 0 " + 1041 + "px" }}

              className="ds-sizepanel-wrapper ds-expand-width-parent ds-collapse-undefined">
              <div data-ds-rt="verticalPanel" data-ds-rid="w" id="wE01qypnRauJVT2f94A_Wg">
                <div>
                  <div className="ds-calendar" data-ds-rt="calendar" data-ds-rid="weekCal" id="wC4QJJvZTYug4Md50ZQfow" style={{ opacity: this.props.fog ? "0.5" : "1" }}>

                    {this.calendarHeader()}

                    <div className="ds-shadow-top"></div>

                    {this.calendarBody()}

                    <div className="ds-shadow-bottom"></div>
                    <ChangeEventParameteres setopen={this.ShowPopUP} open={this.state.show} />

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        : <div className="ds-fi ds-fi-fhp" data-ds-rt="flexItem" data-ds-rid="calendarItem" id="w8GjQTOSQhuQeflOwRkqfg"><CalendarList setShow={this.props.setShow} /></div>}
      <AppointmentDetail getNumber={this.getNumber} delAppointmentFromCalendar={this.delAppFromCalendar} moveAppointmentToCb={this.appointmentMoveToClipBoard} show={this.state.showAppPopup} setShow={this.hideAppointmentPopup} openFormPopup={this.props.setShow} selectedAppointmentId={this.state.selectedAppointmentId} />
      <AppointmentDetailFun getNumber={this.getNumber} show={this.state.showAppDetail} setShow={this.hideAppointmentPopupFun} selectedAppointmentId={this.state.selectedAppointmentId} />
    </>;
  }
}

export function AppointmentDetail(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['appToken']);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let checking = useSelector(state => state.calendar.setRerenderAppointmentData);

  const [open, setOpen] = React.useState(false);
  const [appointmentData, setAppointmentData] = useState({});
  const [von_text, setVon_text] = useState("");
  const [bis_text, setBis_text] = useState("");

  useEffect(() => {
    if (props.show === true) {
      apiCalls();
    }
  }, [props.show]);

  useEffect(() => {
    if (checking) {
      apiCalls();
      dispatch(setRerenderAppointmentData(false));
    }
  }, [checking]);

  const apiCalls = async () => {
    let url = "online-terminbuch/getAppointmentDataById?appId=" + props.selectedAppointmentId;
    // let res = await dynamicGetApi(url, navigate, setCookie);
    // setOpen(props.show);
    // setAppointmentData(res.data);
    // setVon_text(props.getNumber(parseInt(res.data.von_min / 60)) + ":" + props.getNumber(res.data.von_min % 60));
    // setBis_text(props.getNumber(parseInt(res.data.bis_min / 60)) + ":" + props.getNumber(res.data.bis_min % 60));
  }

  const handleClose = () => {
    setOpen(false);
    props.setShow(false);
  };

  const openFrom = () => {
    setCookie("fromEditMode", true);
    setCookie("appointmentId", appointmentData.id);
    handleClose();
    setTimeout(() => {
      props.openFormPopup(true);
    }, 500);
  };

  const moveToClipboard = (e) => {
    props.moveAppointmentToCb(e);
    handleClose();
  }
  const deleteAppointmentFromCalendar = (e) => {
    props.delAppointmentFromCalendar(e);
    handleClose();
  }

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={"sm"}
        maxWidth={"sm"}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Termin
        </BootstrapDialogTitle>
        <DialogContent dividers>

          <div className="row " style={{ marginLeft: "0px", marginRight: "0px" }}>
            <div className='row w-100 px-3' style={{ marginBottom: "10px" }}>
              <div className='col-md-1 ' style={{ textAlign: "center" }}>
                <GrUser fontSize={28} />
              </div>
              <div className='col-md-9'>
                <Badge bg="secondary"></Badge> {appointmentData.patient}
                <div><GrPhone />01234567890</div>
              </div>
              <div className='col-md-2'>
                <SpaceBetween>
                  <span>{moment(new Date()).format("DD.MM.YYYY")}</span>
                </SpaceBetween>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">{appointmentData.title}</div>
              <div className="col-sm-12">So {appointmentData.date} {von_text} - {bis_text}, {appointmentData.bis_min - appointmentData.von_min} Minuten</div>
              <div className="col-sm-12">{appointmentData.zimmer}</div>
              <div className="col-sm-12">{appointmentData.kategorie}</div>
              <div className="col-sm-12"><b>{appointmentData.behandler}</b></div>

            </div>
          </div>

        </DialogContent>
        <DialogActions>
          <div className="row controls controls-row mb-3">

            <div className='col-md-12 text-right'>
              <div data-docking="top" class="btn-toolbar ds-animation ds-topDocking" data-ds-rt="toolbar" data-ds-rid="toolbar1" id="wEyIFlIuSPeE7iwlZZ7f9g" data-docked="false">
                <div class="btn-group pull-left" data-ds-rt="buttonGroup" data-ds-rid="buttonGroup2" id="wcc6dYrnScq6GRGAqZLbTg">
                  <button onClick={() => openFrom()} class="ds-disabled btn-default btn" data-disabled="true" data-ds-rt="button" data-ds-rid="cutBt" id="w4H9HWliTba2jf39xepbDw">
                    <span class="ds-b-cm ds-b-c">
                      <span data-ds-rid="icon2" id="wj-zwZqUSCO8zItRJsMIsQ" data-ds-rt="icon" class="ds-i">
                        <FaPencilAlt class="fa-fw  fal fa-pencil" />
                      </span>
                      <span class="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wPbJlccdTCaLiSeuPOGZRQ">Bearbeiten</span>
                    </span>
                  </button>
                  <button class="ds-disabled btn-default btn" data-disabled="true" data-ds-rt="button" data-ds-rid="cutBt" id="w4H9HWliTba2jf39xepbDw" onClick={() => moveToClipboard(appointmentData.id)}>
                    <span class="ds-b-cm ds-b-c">
                      <span data-ds-rid="icon2" id="wj-zwZqUSCO8zItRJsMIsQ" data-ds-rt="icon" class="ds-i">
                        <FaCut class="fa-fw  fal fa-cut" />
                      </span>
                      <span class="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wPbJlccdTCaLiSeuPOGZRQ">Ausschneiden</span>
                    </span>
                  </button>
                  <button class="ds-disabled btn-default btn" data-disabled="true" data-ds-rt="button" data-ds-rid="deleteBt" id="wA_qNg2zRV6ah424xvtZFA" onClick={() => deleteAppointmentFromCalendar(appointmentData.id)}>
                    <span class="ds-b-cm ds-b-c">
                      <span data-ds-rid="icon3" id="wUXMPwsVRemndfy6bX9KkA" data-ds-rt="icon" class="ds-i">
                        <FaTrash class="fa-fw  fal fa-trash" />
                      </span>
                      <span class="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wxYFps_eRvicEfNbQ0hZUQ">Löschen</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </>
  )
}

export function AppointmentDetailFun(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['appToken']);
  let navigate = useNavigate();
  let dispatch = useDispatch();


  const [open, setOpen] = React.useState(false);
  const [appointmentData, setAppointmentData] = useState({});
  const [von_text, setVon_text] = useState("");
  const [bis_text, setBis_text] = useState("");
  const [priority, setPriority] = useState(1);

  useEffect(() => {
    if (props.show === true) {
      apiCalls();
    }
  }, [props.show]);


  const apiCalls = async () => {
    let url = "online-terminbuch/getAppointmentDataById?appId=" + props.selectedAppointmentId;
    // let res = await dynamicGetApi(url, navigate, setCookie);
    // setOpen(props.show);
    // setAppointmentData(res.data);
    // setVon_text(props.getNumber(parseInt(res.data.von_min / 60)) + ":" + props.getNumber(res.data.von_min % 60));
    // setBis_text(props.getNumber(parseInt(res.data.bis_min / 60)) + ":" + props.getNumber(res.data.bis_min % 60));
  }

  const handleClose = () => {
    setOpen(false);
    props.setShow(false);
  };

  const handleChange = (e) => {
    setPriority(e);
  }

  const moveToWaitingArea = async (e) => {
    let url = "online-terminbuch/kanban/moveToWaitingArea?appId=" + e + "&priority="+priority;
    // let res = await dynamicGetApi(url, navigate, setCookie);
    // if (res.data) {
    //   dispatch(setmoveWaitingCheck(true));
    //   handleClose();
    // }
  }
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={"sm"}
        maxWidth={"sm"}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Termin
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
            <div className='col-md-9'>
              <Row>
                <div className='col-md-1' style={{ textAlign: "center" }}>
                  <GrUser fontSize={28} />
                </div>
                <div className='col-md-8' style={{ paddingLeft: "10px"}}>
                  <Badge bg="secondary"></Badge> {appointmentData.patient}
                  <div><GrPhone />{appointmentData.pat_nr}</div>
                </div>
                <div className='col-md-3'>
                  <SpaceBetween>
                    <span>{moment(new Date()).format("DD.MM.YYYY")}</span>
                  </SpaceBetween>
                </div>
                <div className='col-md-12' style={{ marginTop: "15px"}}>
                  <div>{appointmentData.title}</div>
                  <div>So {appointmentData.date} {von_text} - {bis_text}, {appointmentData.bis_min - appointmentData.von_min} Minuten</div>
                  <div>{appointmentData.zimmer}</div>
                  <div>{appointmentData.kategorie}</div>
                  <div><b>{appointmentData.behandler}</b></div>
                </div>
              </Row>
            </div>
            <div className='col-md-3'>
              <FormControl>
                <b>Set Priority</b>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={1} name="Priority" control={<Radio />} label="Low"  onClick={() => handleChange(1)}/>
                  <FormControlLabel value={2} name="Priority" control={<Radio />} label="Medium" onClick={() => handleChange(2)}/>
                  <FormControlLabel value={3} name="Priority" control={<Radio />} label="High" onClick={() => handleChange(3)}/>
                </RadioGroup>
              </FormControl>
            </div>

          </Row>

        </DialogContent>
        <DialogActions >
          <div className=''>
            <button onClick={() => moveToWaitingArea(appointmentData.id)} className='btn btn-dark p-3'><FaHourglassHalf class="fa fa-solid fa-hourglass-half" /> Waiting</button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </>
  )
}
