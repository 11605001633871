import React, { FC } from 'react';
import HomeBanner from './HomeBanner';
import AppointmentNotification from './AppointmentNotification';
import { Draggable } from "react-beautiful-dnd";
import { useOutletContext } from "react-router-dom";


interface IProps {
}

const Home: FC<IProps> = ({ }) => {
    const [board, compIds]: any = useOutletContext();
    return (
        <>
            <AppointmentNotification />
            <HomeBanner />

            {board.columns["column-2"].dragableItems.map((dragableItem: any, index: number) => (
                <>
                    {board.tasks[dragableItem].isDragDisabled ?
                        (
                            <Draggable key={dragableItem} draggableId={dragableItem} index={index} isDragDisabled={true}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="left-sidebar-item"
                                    >
                                        {compIds[board.tasks[dragableItem].content]}
                                    </div>
                                )}
                            </Draggable>
                        )
                        :
                        (
                            <Draggable key={dragableItem} draggableId={dragableItem} index={index}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className="left-sidebar-item"
                                    >
                                        {compIds[board.tasks[dragableItem].content]({ ...provided.dragHandleProps })}
                                    </div>
                                )}
                            </Draggable>
                        )
                    }

                </>
            ))}
            {/* <CustomCard
                isCardHeader={true}
                title="Überfällige Aufgaben"
                isHeaderDropdown={true}
                cardBodyContent={<OverdueTasksList Data={OverdueTasks} isSeletable={false} />}
                titleFontSize="16px"
            />
            <InformationTabs /> */}
        </>
    );
}

export default Home;
