import { FC } from "react";
import Form from "react-bootstrap/Form";
import { Control, FieldError, Controller } from "react-hook-form";
import { IInputProps } from "../Interface/IInputProps";

interface IProps extends IInputProps {
  fieldType?: string;
  placeholder?: string;
  fieldName: string;
  fieldId?: string;
  size?: any;
  control: Control<any, any>;
  error?: FieldError;
  cssClass?: any;
  rows?: number
}
export const TextAreaControl: FC<IProps> = ({
  required,
  min,
  minLength,
  max,
  maxLength,
  cssClass,
  fieldType,
  placeholder,
  fieldName,
  fieldId,
  size,
  control,
  error,
  rows
}) => {
  return (
    <>
      {control && (
        <Controller
          name={fieldName}
          control={control}
          rules={{ required, min, minLength, max, maxLength }}
          render={({ field: { name, onBlur, onChange, ref, value } }) => (
            <Form.Control
              id={fieldId}
              name={name}
              onBlur={onBlur}
              value={value}
              className={cssClass}
              size={size}
              type={fieldType}
              placeholder={placeholder}
              ref={ref}
              onChange={onChange}
              isInvalid={!!error}
              as="textarea"
              rows={rows}
            />
          )}
        />
      )}

      {error && (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  );
};
