import styled from "styled-components";
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Card } from "react-bootstrap";
import { createGlobalStyle } from 'styled-components';
import { hexToRgba } from "../App";
import { NavLink } from "react-router-dom";


export const GlobalStyles = createGlobalStyle`
.primary-color {
    color: ${(props: any) => props.theme.colors.primaryColor};
}
.primary-bg {
    background-color: ${(props: any) => props.theme.colors.primaryColor} !important;
}
.btn.primary-bg {
    background-color: ${(props: any) => props.theme.colors.primaryColor} !important;
    border-color: ${(props: any) => props.theme.colors.primaryColor} !important;
    color: #fff !important;
}
.btn-outline-primary.dropdown button {
    background: transparent !important;
    border-width: 1px !important;
    border-stayle:solid !important;
    border-color: ${(props: any) => props.theme.colors.primaryColor} !important;
    color:${(props: any) => props.theme.colors.primaryColor} !important;
    padding: 2px !important;
}

.btn-outline-primary.dropdown button:hover {
    background: ${(props: any) => props.theme.colors.primaryColor} !important;
    border-width: 1px !important;
    border-stayle:solid !important;
    border-color: ${(props: any) => props.theme.colors.primaryColor} !important;
    color: #fff !important;
}
.side-nav input[type=checkbox]:checked~a {
    border-left-width: 3px !important;
    border-left-style: solid !important;
    border-left-color: ${(props: any) => props.theme.colors.primaryColor} !important;
    background-color: ${(props: any) => hexToRgba(props.theme.colors.primaryColor, '0.1')} !important;
}
.side-nav a.active {
    border-left-width: 3px !important;
    border-left-style: solid !important;
    border-left-color: ${(props: any) => props.theme.colors.primaryColor} !important;
    color:${(props: any) => props.theme.colors.primaryColor} !important;
    background-color: ${(props: any) => hexToRgba(props.theme.colors.primaryColor, '0.1')} !important;
}
.back-to-top {
    position: fixed;
    bottom: 85px;
    right: 0px;
    background: ${(props: any) => props.theme.colors.primaryColor} !important;
    padding: 8px;
    color: white;
    border-radius: 50%;
    border: none;
    box-shadow: 0 5px 10px #ccc;
    z-index: 9999;
}
.side-nav a {
    display: block;
    padding: 10px;
    color: #666;
    text-decoration: none;
    text-transform: uppercase;
    margin: 5px 0px !important;
}
.side-nav .submenu a:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #666;
    line-height: 5px !important;
}
.side-nav .submenu a.active:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${(props: any) => props.theme.colors.primaryColor} !important;
    line-height: 5px !important;
}
.admin-icon,.admin-title{
    color:#fff !important;
}
.side-nav .submenu a.active {
    border-left: none !important;
    background-color: transparent !important;
    color: ${(props: any) => props.theme.colors.primaryColor} !important;
}
`;





interface IColorObj {
    Indiscussion: string;
    Inimplementation: string;
    Forlater: string;
    Discarded: string;
    Accomplished: string;
    [key: string]: string;
}
const colorObj: IColorObj = {
    'Indiscussion': '#fff5e1',
    'Inimplementation': '#eaf1f9',
    'Forlater': '#fff2e7',
    'Discarded': '#ffeded',
    'Accomplished': '#f0ffe1'
}



export const AccountPopoverWrapper = styled.div`
position:relative;
padding-bottom:5px !important;
`
export const AccountTitle = styled.div`
padding:2px 15px;
font-size:17px;
font-weight:600 !important;
color:#666 !important;
`
export const AccountLink = styled.div`
width:100%;
padding:2px 15px;
font-size:13px;
cursor:pointer;
color:#666 !important;
&:hover{
    background-color: #e8e8e8;
}
`
export const ProfileImg = styled.img`
position:absolute;
width:100px;
top:5px;
right:5px;
`
export const AccountSettingImg = styled.img`
width:100px;
height:100px;
object-fit:cover;
object-position:top;
border-radius:50% !important;
`
export const TextInput = styled.input.attrs(props => ({ type: props.type || "text" }))`
width:100%;
padding: ${(props: {
    size?: string,
}) => props.size === 'sm' ? '4px 8px;' : props.size === 'lg' ? '8px 16px;' : '6px 12px'};
font-size: ${props => props.size === 'sm' ? '14px' : props.size === 'lg' ? '20px' : '16px'};
border-radius: ${props => props.size === 'sm' ? '4px' : props.size === 'lg' ? '8px' : '6px'};
&:focus{
    border-color: #0D3F5F;
    outline: 0;
    box-shadow: 0 0 0 4px rgb(13 63 95 / 15%) !important;
}
`
export const OutlineBadge = styled.span`
    padding: 1px 4px;
    background-color: #fff !important;
    color: ${(props: {
    color?: string,
    mr?: string
}) => props.color ? props.color : '#000'};
    border: 1px solid ${props => props.color ? props.color : '#000'};
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 10px;
    white-space: nowrap;
    margin-right:${props => props.mr ? props.mr : '0px'};
`
export const SolidBadge = styled(Badge)`
    color: ${(props: {
    color?: string,
    bgColor: string,
    mr?: string,
    cursor?: string
}) => props.color ? props.color : '#fff'}!important;
background-color: ${props => props.bgColor ? props.bgColor : '#000'} !important;
font-size: 12px !important;
margin-right:${props => props.mr ? props.mr : '0px'};
cursor:${props => props.cursor ? props.cursor : null};
`
export const BorderBadge = styled(Badge)`
    color: ${(props: {
    color: string,
    mr?: string,
    cursor?: string,
    bgColor?: string
}) => props.color ? props.color : '#fff'}!important;
background-color:${(props: {
    bgColor?: string
}) => props.bgColor ? props.bgColor : '#fff'} !important;
border: 0.5px solid #d6dde1;
font-size: 12px !important;
margin-right:${props => props.mr ? props.mr : '0px'};
cursor:${props => props.cursor ? props.cursor : null};
position:relative;
display:inline-flex;
align-items:center;
&:before{
    content:'';
    height:12px;
    width:3px;
    background-color:${props => props.color ? props.color : null};
    display:inline-block;
    margin-right:5px;
}
`

export const ModalHeader = styled(Modal.Header)`
background-color:${(props: {
    bgColor?: string
}) => props.bgColor ? props.bgColor : '#fff'} !important;
`
export const HeadingWithDivider = styled.div`
font-size:${(props: {
    fontSize?: string,
    fontWeight?: string,
    color?: string,
    mb?: string,
    ms?: string,
    dividerColor?: string

}) => props.fontSize ? props.fontSize : '24px'};
font-weight:${props => props.fontWeight ? props.fontWeight : '500'};
color:${props => props.color ? props.color : '#666666'};
position: relative;
margin-bottom:${props => props.mb ? props.mb : '0px'};
margin-left:${props => props.ms ? props.ms : '0px'};
&:before{
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 3%;
    background-color: ${props => props.dividerColor ? props.dividerColor : '#0D3F5F'}; 
}
&:after{
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 20px;
    left: -1%;
    background-color: ${props => props.dividerColor ? props.dividerColor : '#0D3F5F'};
}
`
export const DateBox = styled.div`
position:relative;
`
export const DateIcon = styled.div`
right:8px;
top:5%;
position:absolute;
`
export const DisplayStart = styled.div`
width: ${(props: {
    width?: string,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
    verticalAlign?: string,
}) => props.width ? props.width : 'auto'};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
margin-top:${props => props.mt ? props.mt : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
display:flex;
align-items:${props => props.verticalAlign ? props.verticalAlign : 'center'};
justify-content:start;
`
export const DisplayCenter = styled.div`
width: ${(props: {
    width?: string,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
    verticalAlign?: string,
}) => props.width ? props.width : 'auto'};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
margin-top:${props => props.mt ? props.mt : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
display:flex;
align-items:${props => props.verticalAlign ? props.verticalAlign : 'center'};
justify-content:center;
`
export const DisplayEnd = styled.div`
width: ${(props: {
    width?: string,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
    verticalAlign?: string,
}) => props.width ? props.width : 'auto'};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
margin-top:${props => props.mt ? props.mt : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
display:flex;
align-items:${props => props.verticalAlign ? props.verticalAlign : 'center'};
justify-content:end;
`
export const DisplayBetween = styled.div`
width: ${(props: {
    width?: string,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
    verticalAlign?: string,
}) => props.width ? props.width : 'auto'};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
margin-top:${props => props.mt ? props.mt : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
display:flex;
align-items:${props => props.verticalAlign ? props.verticalAlign : 'center'};
justify-content:space-between;
`

export const InlineBox = styled.div`
margin-left:${(props: {
    ml?: string,
    cursor?: string
}) => props.ml ? props.ml : '0px'};
display:inline-block;
`
export const Box = styled.div`
display:block;
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
    cursor?: string,
    width?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
margin-top:${props => props.mt ? props.mt : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
cursor:${props => props.cursor ? props.cursor : null};
width:${props => props.width ? props.width : null};
`
export const UploadImgBtn = styled.label`
color: #333 !important;
background-color: #fff !important;
border: 1px solid #ccc !important;
padding:0.375rem 0.75rem;
border-radius:0.375rem;
width:100%;
font-size: 14px !important;
cursor:pointer;
&:hover{
    color: #333 !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad !important;
}
`
export const BorderBox = styled.div`
border-top:${(props: {
    borderTop?: string,
    borderBottom?: string,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.borderTop ? props.borderTop : '1px solid #dee2e6'};
border-bottom:${props => props.borderBottom ? props.borderBottom : '1px solid #dee2e6'};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
margin-top:${props => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
padding-top:${props => props.pt ? props.pt : '8px'};
padding-bottom:${props => props.pb ? props.pb : '8px'};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
`
export const Heading = styled.div`
font-size:${(props: {
    fontSize?: string,
    fontWeight?: string,
    color?: string,
    mb?: string,
    mt?: string,
}) => props.fontSize ? props.fontSize : '24px'};
font-weight:${props => props.fontWeight ? props.fontWeight : '500'};
color:${props => props.color ? props.color : '#666666'};
position: relative;
margin-bottom:${props => props.mb ? props.mb : '0px'};
margin-top:${props => props.mt ? props.mt : '0px'};
`
export const BodyText = styled.div`
font-size:${(props: {
    fontSize?: string,
    fontWeight?: string,
    textColor?: string,
    textAlign?: string,
    lineHeight?: string,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pl?: string,
    pr?: string,
    pt?: string,
    pb?: string,
    display?: string,

}) => props.fontSize ? props.fontSize : '14px'};
font-weight:${props => props.fontWeight ? props.fontWeight : '400'};
line-height:${props => props.lineHeight ? props.lineHeight : '1.5'};
color:${props => props.textColor ? props.textColor : '#666666'};
margin-top:${props => props.mt ? props.mt : '0px'};
margin-bottom:${props => props.mb ? props.mb : '0px'};
margin-left:${props => props.ml ? props.ml : '0px'};
margin-right:${props => props.mr ? props.mr : '0px'};
text-align:${props => props.textAlign ? props.textAlign : 'left'};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
margin-bottom:${props => props.mb ? props.mb : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
display:${props => props.display ? props.display : null};
`
export const CustomButton = styled(Button)`
font-size:${(props: {
    fontSize?: string,
    fontWeight?: string,
    bgColor?: string,
    borderColor?: string,
    textColor?: string,
    textColorHover?: string,
    disabled?: boolean,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
}) => props.fontSize ? props.fontSize : '14px'};
font-weight:${props => props.fontWeight ? props.fontWeight : '400'};
background-color:${props => props.bgColor ? props.bgColor : '#fff'};
border-color:${props => props.borderColor ? props.borderColor : ''};
color:${props => props.textColor ? props.textColor : '#666666'};
margin-top:${props => props.mt ? props.mt : '0px'};
margin-bottom:${props => props.mb ? props.mb : '0px'};
margin-left:${props => props.ml ? props.ml : '0px'};
margin-right:${props => props.mr ? props.mr : '0px'};
&.btn:hover{
    background-color:${props => props.bgHoverColor ? props.bgHoverColor : '#fff'} !important;
    border-color:${props => props.borderColor ? props.borderColor : ''}!important;
    color:${props => props.textColorHover ? props.textColorHover : '#fff'};
}
&.btn:disabled{
    background-color:${props => props.bgColor ? props.bgColor : '#fff'};
    border-color:${props => props.borderColor ? props.borderColor : ''};
    color:${props => props.textColor ? props.textColor : '#666666'};
    opacity:0.25;
}
`
export const HighlightBox = styled.div`
background-color:${(props: {
    bgColor: string,
    textColor?: string,
    borderColor?: string,
}) => props.bgColor ? props.bgColor : null};
color:${props => props.textColor ? props.textColor : ''};
border: 1px solid transparent;
border-color:${props => props.borderColor ? props.borderColor : null} !important;
padding:7px 10px;
`
export const AlertBox = styled.div`
background-color:${(props: {
    bgcolor: string,
    textColor?: string,
    padding?: string,
    borderColor?: string
}) => props.bgcolor ? props.bgcolor : null};
padding:${props => props.padding ? props.padding : '7px'};
background-color: ${props => props.bgcolor ? props.bgcolor : null};
color: ${props => props.textColor ? props.textColor : '#000'};
border:${props => props.borderColor ? `1px solid ${props.borderColor}` : null} ;
border-radius: 4px;
text-align: center;
`

export const Divider = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
border-bottom: 1px solid #dee2e6;
`

export const MultiColorBox = styled.span`
background-color:${(props: {
    bgcolor: string,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
}) => props.bgcolor ? props.bgcolor : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-top:${props => props.mt ? props.mt : null};
margin-right:${props => props.mr ? props.mr : null};
display: inline-block;
width: 12px;
height: 12px;
`

export const StatusBadge = styled.span`
background-color:${(props: {
    bgcolor?: string,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    borderColor?: string
}) => props.bgcolor ? props.bgcolor : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-top:${props => props.mt ? props.mt : null};
margin-right:${props => props.mr ? props.mr : null};
border:${props => props.borderColor ? `2px solid ${props.borderColor}` : null};
`
export const StatusBadgeTextColor = styled.span`
background-color:${(props: {
    bgcolor?: string,
    textColor?: string,
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
}) => props.bgcolor ? props.bgcolor : null};
color: ${props => props.textColor ? props.textColor : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-top:${props => props.mt ? props.mt : null};
margin-right:${props => props.mr ? props.mr : null};
line-height: 1.667;
font-size: 12px;
font-weight: 600;
text-transform: capitalize;
`

export const CustomCardBody = styled(Card.Body)`
height: ${(props: {
    height?: string,
    padding?: string,
}) => props.height ? props.height : null};
padding: ${props => props.padding ? props.padding : null};
`

export const CustomList = styled.ul`
list-style-position: outside;
margin-bottom: 0px;
padding: 0px;
`
export const CustomListItem = styled.li`
list-style:none;
margin-top: 5px;
padding: 3px 10px;
background-color:${(props: { isActive?: boolean }) => props.isActive ? '#0D3F5F' : '#efefef'};
color:${(props: { isActive?: boolean }) => props.isActive ? '#fff' : '#666666'};
cursor:pointer;
&:hover{
background-color:${(props: { isActive?: boolean }) => props.isActive ? '#0D3F5F' : '#d8d8d8'};
color:${(props: { isActive?: boolean }) => props.isActive ? '#fff' : '#000'};
}
`
export const ChecklistDragContainer = styled.div`
width:100%;
background:#fff;
&.draggable-item-dragging{
    padding-top: 8px;
    padding-bottom: 10px;
    padding-left:5px;
    padding-right:5px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    height:auto !important;
}
`
export const ChecklistDragHandler = styled.div`
display:inline-block;
`
export const ImageBannerWrap = styled.div`
cursor: pointer;
    display: inline-block;
    text-align: center;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    font-size: 13px;
    vertical-align: top;
    word-wrap: break-word;
    &:hover {
        background: #e0e0e0;
    }
    &:active {
        background: #b3cee1 !important;
      }
`
export const CustomTr = styled.tr`
background-color: ${(props: {
    BgColor?: string,
}) => props.BgColor ? props.BgColor : null};
`
export const WayPointBox = styled.div`
position:relative;
`
export const UploadImgLabel = styled.label`
color: #333 !important;
    background-color: #fff !important;
    font-size: 14px !important;
    padding: 0.375rem 0.75rem !important;
    border:1px solid #ccc !important;
    border-radius:0.375rem !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor:pointer !important;
&:hover{
    color: #333 !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad !important;
}
`
export const CustomDropDownItem = styled.a`
display: block;
width: 100%;
padding-top:6px;
padding-bottom:6px;
padding-left:16px;
padding-right:16px;
clear: both;
font-weight: 400;
color: #212529;
text-align: inherit;
text-decoration: none;
white-space: nowrap;
background-color: transparent;
border: 0;
border-radius: none;
&:hover{
    color: #fff !important;
    background-color: #0D3F5F !important;
}
& > div {
    height:18px;
}
`
export const TaskWrapper = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
`
export const TaskInfo = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
`
export const TaskDetails = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
`
export const CommentWrapper = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
`
export const CommentItem = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};

`
export const CommentBox = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
`
export const Comment = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
`
export const ReplyItem = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : 'auto'};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
width:96%;
`
export const ReplyBox = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : 'auto'};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
width:96%;
`
export const InnerReplyItem = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : 'auto'};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
width:100%;
`
export const TaskRecipientInfo = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : 'auto'};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
`
export const DropdownItemColor = styled.div`
font-size:${(props: {
    fontSize?: string,
    fontWeight?: string,
    textColor?: string,
    itemColor?: string,
}) => props.fontSize ? props.fontSize : '14px'};
position:relative;
text-align:left !important;
&:before{
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: 5px !important;
    background-color:${props => props.itemColor ? props.itemColor : '#fff'}
}
`
export const IdeaWrapper = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
`
export const IdeaInfo = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
padding-top:${props => props.pt ? props.pt : null};
padding-bottom:${props => props.pb ? props.pb : null};
padding-left:${props => props.pl ? props.pl : null};
padding-right:${props => props.pr ? props.pr : null};
`
export const IdeaDetails = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
}) => props.mt ? props.mt : null};
`
export const Ideastatus = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    pt?: string,
    pb?: string,
    pl?: string,
    pr?: string,
    status: string
}) => props.mt ? props.mt : null};
    background-color:${(props) => (colorObj[props.status] ? colorObj[props.status] : '#F8F9FA')};
    border-left-width: 3px;
    border-left-style:solid;
    border-left-color:${props => props.status === 'Indiscussion' ? '#fbd300' : props.status === 'Inimplementation' ? '#077ce0' : props.status === 'Forlater' ? '#fc9136'
        : props.status === 'Discarded' ? '#ed6162' : props.status === 'Accomplished' ? '#80c35c' : '#F8F9FA'};

`
export const ContentBox = styled.div`
margin-top:${(props: {
    mt?: string,
    mb?: string,
    ml?: string,
    mr?: string,
    bgColor?: string,
}) => props.mt ? props.mt : null};
padding:16px;
margin-bottom:${props => props.mb ? props.mb : null};
margin-left:${props => props.ml ? props.ml : null};
margin-right:${props => props.mr ? props.mr : null};
background-color:${props => props.bgColor ? props.bgColor : 'rgba(249, 249, 249,0.5)'};
`

export const TabBox = styled.div`
display:flex;
align-items:center;
justify-content:start;
flex-wrap:wrap;
`
export const TabItem = styled(NavLink)`
padding:15px 20px;
color:#fff;
font-weight:600;
text-decoration:none;
text-align:center;
flex-grow:1;
background-color:${(props: any) => props.theme.colors.primaryColor};
&.active{
    background-color:#fff !important;
    color:${(props: any) => props.theme.colors.primaryColor};
}
`
export const ThemeColorPicker = styled.input.attrs(props => ({ type: 'color' }))`
width:50px;
height:50px;
`
