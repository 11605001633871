import React, { useState, useEffect } from 'react';
import { AlertBox, BodyText, BorderBox, Box, DisplayCenter, DisplayEnd, DisplayStart, HeadingWithDivider, InlineBox } from '../../StyledComponents';
import { Button, Col, Row } from 'react-bootstrap';
import ButtonWidget from '../../ButtonWidget';
import CustomTooltip from '../../Tooltip';
import BadgeWidget from '../../BadgeWidget';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import ChangePasswordModalContent from './ChangePasswordModalContent';
import CommonModal from '../CommonModal';
import ChangePINModalContent from './ChangePINModalContent';
import { AlertWidget } from '../../AlertWidget';

type securityDataProps = {
    title: string,
    lastAccess: string,
    lastAccessTooltip: boolean,
    accessDateTime: string,
    image: string,
    imageTooltipContent: string,
    operatingSystem: string,
    ip: string,
    land: string,
    city: string,
    userAgent: string,
    sessionStatus: boolean,
    detailBtn: boolean,
}

const securityData: securityDataProps[] = [
    {
        title: "Pakistan, in der Nähe von Islamabad",
        lastAccess: "vor 39 Stunden",
        lastAccessTooltip: true,
        accessDateTime: "24.05.2023 19:42",
        image: "/images/assets/header/desktop-img.png",
        imageTooltipContent: "Desktop PC",
        operatingSystem: "Windows",
        ip: "182.176.147.55",
        land: "Pakistan",
        city: "Islamabad",
        userAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
        sessionStatus: false,
        detailBtn: false,
    },
    {
        title: "Pakistan, in der Nähe von Islamabad",
        lastAccess: "vor 4 Tagen",
        lastAccessTooltip: true,
        accessDateTime: "24.05.2023 19:42",
        image: "/images/assets/header/desktop-img.png",
        imageTooltipContent: "Desktop PC",
        operatingSystem: "Windows",
        ip: "182.176.147.55",
        land: "Pakistan",
        city: "Islamabad",
        userAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
        sessionStatus: false,
        detailBtn: false,
    },
    {
        title: "Germany, in der Nähe von Eberbach",
        lastAccess: "",
        lastAccessTooltip: false,
        accessDateTime: "12.05.2023 05:02",
        image: "/images/assets/header/desktop-img.png",
        imageTooltipContent: "Desktop PC",
        operatingSystem: "Windows",
        ip: "79.193.161.13",
        land: "Germany",
        city: "Eberbach",
        userAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Safari/537.36",
        sessionStatus: false,
        detailBtn: false,
    },
    {
        title: "Pakistan, in der Nähe von Islamabad",
        lastAccess: "",
        lastAccessTooltip: false,
        accessDateTime: "11.05.2023 18:30",
        image: "/images/assets/header/desktop-img.png",
        imageTooltipContent: "Desktop PC",
        operatingSystem: "Windows",
        ip: "182.176.147.55",
        land: "Pakistan",
        city: "Islamabad",
        userAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36",
        sessionStatus: false,
        detailBtn: false,
    },
    {
        title: "Pakistan, in der Nähe von Islamabad",
        lastAccess: "vor 13 Tagen",
        lastAccessTooltip: false,
        accessDateTime: "13.06.2023 18:30",
        image: "/images/assets/header/desktop-img.png",
        imageTooltipContent: "Desktop PC",
        operatingSystem: "Windows",
        ip: "182.176.147.55",
        land: "Pakistan",
        city: "Islamabad",
        userAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36",
        sessionStatus: false,
        detailBtn: false,
    },
    {
        title: "Pakistan, in der Nähe von Karachi",
        lastAccess: "",
        lastAccessTooltip: false,
        accessDateTime: "",
        image: "/images/assets/header/desktop-img.png",
        imageTooltipContent: "Desktop PC",
        operatingSystem: "Windows",
        ip: "175.107.233.133",
        land: "Pakistan",
        city: "Karachi",
        userAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
        sessionStatus: true,
        detailBtn: false,
    },

]

export const SecurityCenterModalContent = () => {

    const [changePasswordModal, setChangePasswordModal] = useState<boolean>(false);
    const [changePINModal, setChangePINModal] = useState<boolean>(false);
    const [endSession, setEndSession] = useState<boolean>(false);
    const [showDetail, setShowDetail] = useState<typeof securityData>([]);

    useEffect(() => {
        setShowDetail(securityData)
    }, []);

    const HandleShowInfo = (index: number) => {
        console.log(...showDetail);
        const temp = [...showDetail];
        temp[index].detailBtn = !temp[index].detailBtn;
        setShowDetail(temp)
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <HeadingWithDivider mb='20px' ms='10px'> Password </HeadingWithDivider>
                </Col>
                <Col md={12} className='align-self-center'>
                    <AlertWidget variant='success' text='Last password change: never 09.11.2022 12:43.' />
                </Col>
                <Col md={12}>
                    <div className='my-3'>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='custom'
                                bgColor='#5bc0de'
                                textColor='#fff'
                                bgHoverColor='#31b0d5'
                                text={
                                    <>
                                        <DisplayCenter>
                                            Passwort ändern ...
                                        </DisplayCenter>
                                    </>
                                }
                                handleClick={(event) => setChangePasswordModal(true)}
                            />
                            <div className='ms-2'>
                                <ButtonWidget
                                    varient='custom'
                                    bgColor='#5bc0de'
                                    textColor='#fff'
                                    bgHoverColor='#31b0d5'
                                    text={
                                        <>
                                            <DisplayCenter>
                                                PIN ändern ...
                                            </DisplayCenter>
                                        </>
                                    }
                                    handleClick={(event) => setChangePINModal(true)}
                                />
                            </div>
                        </DisplayEnd>
                    </div>
                </Col>
                <BorderBox borderBottom='none' mt='4px' pt='0px' pb='0px'></BorderBox>
                <Col md={12}>
                    <HeadingWithDivider mb='20px' ms='10px'> Sitzungen und Geräte </HeadingWithDivider>
                </Col>
            </Row>
            {showDetail?.map((item, index) => {
                return (
                    <>
                        <Row key={index}>
                            <Col md={2}>
                                <CustomTooltip
                                    ToolTipTrigger={
                                        <>
                                            <div className='d-flex align-items-center'>
                                                <img className='rounded-circle' src={item.image} alt='Desktop Pc' />
                                            </div>
                                        </>
                                    }
                                    toolTipContent={item.imageTooltipContent}
                                    toolTipPlacement='top'
                                />
                            </Col>
                            <Col className='align-self-center' md={7}>
                                <Box pl='25px'>
                                    {item.detailBtn ?
                                        <>
                                            <Row>
                                                <Col md={5}>
                                                    <div>
                                                        <span>Betriebssystem:</span>
                                                    </div>
                                                    <div>
                                                        <span>IP:</span>
                                                    </div>
                                                    <div>
                                                        <span>Land:</span>
                                                    </div>
                                                    <div>
                                                        <span>Stadt:</span>
                                                    </div>
                                                    <div>
                                                        <span>Letzter Zugriff:</span>
                                                    </div>
                                                    <div>
                                                        <span>User-Agent:</span>
                                                    </div>
                                                </Col>
                                                <Col md={7}>
                                                    <div>
                                                        <span>{item.operatingSystem}</span>
                                                    </div>
                                                    <div>
                                                        <span>{item.ip}</span>
                                                    </div>
                                                    <div>
                                                        <span>{item.land}</span>
                                                    </div>
                                                    <div>
                                                        <span>{item.city}</span>
                                                    </div>
                                                    <div>
                                                        {item.lastAccessTooltip ?
                                                            <InlineBox>
                                                                <CustomTooltip
                                                                    ToolTipTrigger={item.lastAccess}
                                                                    toolTipContent={item.accessDateTime}
                                                                    toolTipPlacement='top'
                                                                />
                                                            </InlineBox>
                                                            :
                                                            <InlineBox>
                                                                {item.accessDateTime}
                                                            </InlineBox>
                                                        }
                                                    </div>
                                                    <div>
                                                        <span>{item.userAgent}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <BodyText>{item.title}</BodyText>
                                            {item.lastAccessTooltip ?
                                                <InlineBox>
                                                    <CustomTooltip
                                                        ToolTipTrigger={item.lastAccess}
                                                        toolTipContent={item.accessDateTime}
                                                        toolTipPlacement='top'
                                                    />
                                                </InlineBox>
                                                :
                                                <InlineBox>
                                                    {item.accessDateTime}
                                                </InlineBox>
                                            }

                                        </>
                                    }
                                    <div className='mt-2'>
                                        <Button className='default-btn f-12px' onClick={() => HandleShowInfo(index)}>
                                            {item.detailBtn ?
                                                <>
                                                    <span className='me-1'>Detaillierte Informationen anzeigen </span>
                                                    <FaSortUp className='default-btn-icon mt-1' />
                                                </>
                                                :
                                                <>
                                                    <span className='me-1'>Detaillierte Informationen ausblenden </span>
                                                    <FaSortDown className='default-btn-icon' />
                                                </>
                                            }
                                        </Button>
                                    </div>
                                </Box>
                            </Col>
                            <Col className='align-self-center' md={3}>
                                {item.sessionStatus ?
                                    <ButtonWidget
                                        varient='custom'
                                        bgColor='#dff0d8'
                                        textColor='#3c763d'
                                        bgHoverColor='#d6e9c6'
                                        class='pe-none fs-12px'
                                        text={
                                            <>
                                                <DisplayCenter>
                                                    Aktuelle Sitzung
                                                </DisplayCenter>
                                            </>
                                        }
                                    />
                                    :
                                    <ButtonWidget
                                        varient='custom'
                                        bgColor='#d9534f'
                                        textColor='#fff'
                                        bgHoverColor='#c9302c'
                                        class='fs-12px'
                                        text={
                                            <>
                                                <DisplayCenter>
                                                    Sitzung beenden
                                                </DisplayCenter>
                                            </>
                                        }
                                        handleClick={(event) => setEndSession(true)}
                                    />
                                }
                            </Col>
                            <BorderBox borderBottom='none' mt='7px'></BorderBox>
                        </Row>
                    </>
                )
            })}

            <CommonModal
                showModal={changePasswordModal}
                hideModal={() => setChangePasswordModal(false)}
                modalTitle='Passwort ändern'
                modalContent={<ChangePasswordModalContent />}
                modalSize='md'
            />
            <CommonModal
                showModal={changePINModal}
                hideModal={() => setChangePINModal(false)}
                modalTitle='PIN ändern'
                modalContent={<ChangePINModalContent />}
                modalSize='md'
            />
            <CommonModal
                showModal={endSession}
                hideModal={() => setEndSession(false)}
                modalTitle='Sitzung beenden'
                modalContent={
                    <>
                        <div className='p-3'>
                            <BodyText>
                                Möchtest du diese Sitzung wirklich beenden? Du wirst auf diesem Gerät/Browser abgemeldet und musst dich erneut anmelden.
                            </BodyText>
                        </div>
                    </>
                }
                modalSize='md'
                centered={true}
            />

        </>
    )
}
