import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';

function AccordionMenu() {
    return (
        <Accordion className="Accordion-menu" defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Link style={{ textDecoration: "none" }} to=''>
                    <Accordion.Header>
                        Meine
                    </Accordion.Header>
                </Link>
                <Accordion.Body>
                    <ul className='Acc-menu'>
                        <li className='Acc-menu-item'>
                            <Link to="" className='Acc-menu-link'>Home</Link>
                        </li>
                        <li className='Acc-menu-item'>
                            <Link to="" className='Acc-menu-link'>About</Link>
                        </li>
                        <li className='Acc-menu-item'>
                            <Link to="" className='Acc-menu-link'>Contact</Link>
                        </li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Mein Archiv</Accordion.Header>
                <Accordion.Body>
                    <ul className='Acc-menu'>
                        <li className='Acc-menu-item'>
                            <Link to="" className='Acc-menu-link'>Home</Link>
                        </li>
                        <li className='Acc-menu-item'>
                            <Link to="" className='Acc-menu-link'>About</Link>
                        </li>
                        <li className='Acc-menu-item'>
                            <Link to="" className='Acc-menu-link'>Contact</Link>
                        </li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default AccordionMenu;