import React, { KeyboardEvent, KeyboardEventHandler } from 'react';
import CreatableSelect from 'react-select/creatable';
import { StylesConfig } from 'react-select';

type selectedValueType = {
    label: string,
    value: string
}

type tagifyType = {
    placeholder?: string,
    selectedValues?: selectedValueType[],
    isDisabled?: boolean
}





const components = {
    DropdownIndicator: null,
};

const createOption = (label: string) => ({
    label,
    value: label,
});

function Tagify(props: tagifyType) {
    const [inputValue, setInputValue] = React.useState<string>('');
    const [value, setValue] = React.useState<readonly selectedValueType[]>([]);

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setValue((prev) => [...prev, createOption(inputValue)]);
                setInputValue('');
                event.preventDefault();
        }
    };

    return (
        <CreatableSelect
            classNames={{
                control: (state) =>
                    state.isFocused ? 'tagify-custom' : 'tagify-custom',
            }}
            classNamePrefix="react-select"
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: state.isFocused ? '32px' : '32px',
                }),
            }}
            components={components}
            inputValue={inputValue}
            isClearable={false}
            isMulti
            isDisabled={props.isDisabled}
            menuIsOpen={false}
            onChange={(newValue) => setValue(newValue)}
            onInputChange={(newValue) => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            placeholder={props.placeholder}
            value={!!props.selectedValues ? props.selectedValues : value}
        />
    );
};

export default Tagify;