import { FC } from "react";
import { Badge, Table } from "react-bootstrap";

type RepairOrderType = {
    Name?: string,
    heading?: string,
    Data: any,
}

export const LatestRepairOrderList: FC<RepairOrderType> = (props) => {
    return (
        <>
            <Table className={`${props.Name?.replace(/\s/g, '') + "table"}`}>
                <tbody>
                    {props.Data.map((item: any, index: number) => {
                        return (
                            <>
                                <tr className="" key={index}>
                                    <td className="p-1">
                                        <span className="fw-bold">{item.title}</span>
                                    </td>
                                    <td className="p-1">
                                        <span className="fw-bold">{item.detail}</span>
                                    </td>
                                    <td className="p-1 text-end w-23">
                                        {item.labelColor === "dark" ? (
                                            <div className="d-flex border rounded">
                                                <span className="border-left-1 mx-2"></span>
                                                <span className="text-nowrap me-2 fw-400">
                                                    {item.labelText}
                                                </span>
                                            </div>
                                        ) : null}
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </Table>

        </>
    )
}