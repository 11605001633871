import { FC } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BodyText, Box, DisplayEnd, DisplayStart, Divider } from "../../../Components/StyledComponents";
import ButtonWidget from "../../../Components/ButtonWidget";
import { InputTextControl } from "../../../Components/Widgets/InputControls/InputTextControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { SelectControl } from "../../../Components/Widgets/InputControls/SelectControl";
import { ArticleVersionComment } from "../../../CommonJSON";


interface IProps { }

const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
        lastName: yup
            .string()
            .typeError("Last name is required")
            .required("Last name is required"),
        position: yup
            .string()
            .typeError("Position is required")
            .required("Position is required"),
    })
    .required();

const Article: FC<IProps> = ({ }) => {

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });
    const fn = watch("firstName");
    const { gender } = watch();
    console.log(fn);

    return (
        <>
            <div className="article-wrapper">
                <Card className='boxShadow'>
                    <Card.Body>
                        <Row>
                            <Col md={12} xs={12}>
                                <Box mt="15px" mb="15px">
                                    <BodyText fontSize='18px' fontWeight='600' textColor='#333' className="text-uppercase"> Artikel Liste <span className="fs-12">(ZUM LÖSCHEN MARKIERT)</span> </BodyText>
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12}>
                                <Box>
                                    <BodyText fontSize='15px' fontWeight='600' textColor='#333'> Kommentar benötigt zum Update der Version </BodyText>
                                </Box>
                                <Box width="140px" mt="10px">
                                    <SelectControl
                                        fieldId={"salutationId"}
                                        fieldName={"salutationId"}
                                        control={control}
                                        error={errors.salutationId}
                                        selectOptions={ArticleVersionComment}
                                    />
                                </Box>
                            </Col>
                            <Col md={6}>
                                <Box>
                                    <BodyText fontSize='15px' fontWeight='600' textColor='#333'> Finale Lösch-Zeit (in Tagen) für markierte Artikel/Versionen </BodyText>
                                </Box>
                                <Box mt="8px" mb="8px">
                                    <DisplayStart>
                                        <InputTextControl
                                            control={control}
                                            fieldName={"firstName"}
                                            error={errors.firstName}
                                            placeholder="Enter Time"
                                            fieldType="number"
                                            size={"md"}
                                        />
                                        <Box ml="10px">
                                            <ButtonWidget varient="primary" text="Speichern" class='text-nowrap' />
                                        </Box>
                                    </DisplayStart>
                                </Box>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default Article;