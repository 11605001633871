import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateBox, DateIcon } from './StyledComponents';
import { GoCalendar } from "react-icons/go";
function DateTimePicker() {
    const [startDate, setStartDate] = useState<any>(new Date());
    return (
        <>
            <DateBox>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className='form-control form-control-sm'
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat="d-MM-yyyy h:mm aa"
                />
                <DateIcon>
                    <GoCalendar size={15} />
                </DateIcon>
            </DateBox>
        </>
    );
}

export default DateTimePicker;