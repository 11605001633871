import React, { useState, useEffect, FC } from 'react';
import { BodyText } from '../../StyledComponents';

interface UserIProps {
    pageName?: string | any,
}

export const BlockedUserModalContent: FC<UserIProps> = (props) => {

    const { pageName } = props;

    return (
        <>
            <div className='p-2'>
                {pageName === "block-user-detail" ?
                    <>
                        <BodyText fontSize='13px' fontWeight='400' textColor='#666' mb="10px">
                            By blocking a user, all user-created content by this user will be hidden for you. Admins will be notified to review the blocking. Do you really want to block Barbara Wasmer & Team?
                        </BodyText>
                    </>
                    : null}
            </div>
        </>
    )
}