import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import nextId from "react-id-generator";
import { FaCircle, FaSearch } from 'react-icons/fa';


const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,

  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function CalendarList() {




 const calendarListHeader = () => {
    return <div className="dataTables_scrollHead" style={{overflow: "hidden", position: "relative", border: 0+"px", width: 100+"%"}}>
      <div className="dataTables_scrollHeadInner" style={{width: 915+"px", paddingRight: 17+"px"}}>
          <table className="table table-striped table-hover" style={{marginLeft: 0+"px", width: 915+"px"}}>
              <thead>
                  <tr role="row">
                      <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="Datum" style={{width: 180+"px"}}>
                          <div className="ds-col-header">
                            <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="DateColumn" id="wyJlYsMwTl660gjjarbHjQ">Datum</span>
                            <div className="ds-sort-head fal fa-fw fa-sort"></div>
                          </div>
                      </th>
                      <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="Farbe" style={{width: 56+"px"}}>
                          <div className="ds-col-header">
                            <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="TypColumn" id="wLg19A5uTROb2q5v5OjbpA">Farbe</span>
                            <div className="ds-sort-head fal fa-fw fa-sort"></div>
                          </div>
                      </th>
                      <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="Patient" style={{width: 224+"px"}}>
                          <div className="ds-col-header">
                            <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="PatientColumn" id="wEIoXJv_T_6_hQH5LDbL8g">Patient</span>
                            <div className="ds-sort-head fal fa-fw fa-sort"></div>
                          </div>
                      </th>
                      <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="Beschreibung" style={{width: 196+"px"}}>
                          <div className="ds-col-header">
                            <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="infoColumn" id="wZJWUPdFT3CxTPQybns4-g">Beschreibung</span>
                            <div className="ds-sort-head fal fa-fw fa-sort"></div>
                          </div>
                      </th>
                      <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="Mitarbeiter" style={{width: 122+"px"}}>
                          <div className="ds-col-header">
                            <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="organisatorColumn" id="wcTODAiVRk2WH7VnPat8oA">Mitarbeiter</span>
                            <div className="ds-sort-head fal fa-fw fa-sort"></div>
                          </div>
                      </th>
                      <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="weitere Teilnehmer" style={{width: 137+"px"}}>
                          <div className="ds-col-header">
                              <p data-ds-rid="weitereTnColumn" id="we70HlzrRzm9vlWK2Zkguw" data-ds-rt="label" className="text-left ds-t-nw ds-t-oe">weitere Teilnehmer</p>
                              <div className="ds-sort-head fal fa-fw fa-sort"></div>
                          </div>
                      </th>
                  </tr>
              </thead>
          </table>
      </div>
    </div>;



  }

 const calendarListBody = () => {
    return <div className="dataTables_scrollBody" style={{overflow: "auto", height: 764 + "px", position: "relative", width: 100 +"%"}}>
								
								
      <table id="w9pKQgVgSxG3LEFK4Yf2tQtable" className="table table-striped table-hover" aria-describedby="w9pKQgVgSxG3LEFK4Yf2tQtable_info" tabIndex="0" style={{marginLeft: 0+"px", position: "absolute", top: 0+"px", left: 0+"px", width: 100+"%"}}>

        <thead>
          <tr role="row" style={{height: 0 +"px"}}>
            <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="Datum" style={{width: 180+"px", paddingTop: 0+"px", paddingBottom: 0+"px", borderTopWidth: 0+"px", borderBottomWidth: 0+"px", height: 0+"px"}}></th>
            <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="Farbe" style={{paddingTop: 0+"px", paddingBottom: 0+"px", borderTopWidth: 0+"px", borderBottomWidth: 0+"px", height: 0+"px", width: 56+"px"}}></th>
            <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="Patient" style={{paddingTop: 0+"px", paddingBottom: 0+"px", borderTopWidth: 0+"px", borderBottomWidth: 0+"px", height: 0+"px", width: 224+"px"}}></th>
            <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="Beschreibung" style={{paddingTop: 0+"px", paddingBottom: 0+"px", borderTopWidth: 0+"px", borderBottomWidth: 0+"px", height: 0+"px", width: 196+"px"}}></th>
            <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="Mitarbeiter" style={{paddingTop: 0+"px", paddingBottom: 0+"px", borderTopWidth: 0+"px", borderBottomWidth: 0+"px", height: 0+"px", width: 122+"px"}}></th>
            <th className="sorting_disabled" tabIndex="0" rowSpan="1" colSpan="1" aria-label="weitere Teilnehmer" style={{paddingTop: 0+"px", paddingBottom: 0+"px", borderTopWidth: 0+"px", borderBottomWidth: 0+"px", height: 0+"px", width: 137+"px"}}></th>
          </tr>
        </thead>


        {calendarListTBody()}

      </table>
									
									
      <div style={{position: "absolute", top: 0+"px", left: 0+"px", width: 1+"px", height: 1170+"px"}}></div>
    </div>;

  }

  const calendarListTBody = () => {
    return <tbody role="alert" aria-live="polite" aria-relevant="all">  

      <tr className="odd">
        <td className="">
          <a style={{maxWidth: 180 + "px"}} target="_self" className="ds-b-l btn btn-link btn-xs ds-fixed-col" data-ds-rt="viewLink" data-ds-rid="1000104_date" href="/online-terminbuch/addTermin?terminInstanz=1000104" id="wGqFoLnhQyuXbi3LqlHR9g">
            <span className="ds-b-c"><span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wKtf_A2FSq2IYqjdKNMQgA">16.05.2021 08:00 - 09:00</span></span>
          </a>
        </td>

        <td className="">
          <p data-ds-rid="1000104_type" id="wLIgOhCUQsSGQ3vwRc-EFw" data-ds-rt="icon" className="text-center ds-i ds-scaling-col">
            <span><FaCircle style={{color:"hsl(180, 100%, var(--hsL50))"}} className="fa-fw  fas fa-circle" /></span>
          </p>
        </td>

        <td className="">
          <div className="ds-text-panel ds-t-nw ds-s-d ds-scaling-col" data-ds-rt="textPanel" data-ds-rid="1000104_patient" id="w8vGLYotR2CtKvGPFZiLaA">
            <span style={{backgroundColor:"hsl(200, 50%, var(--hsL45))", borderColor:"hsl(200, 50%, var(--hsL45))", color:"hsl(0, 0%, var(--hsL98))"}} className="badge ds-badge-round" data-ds-rt="badge" data-ds-rid="1000104_patient_badge" id="wn0wjZKJSgqmDDmLywALnQ">11</span>
            <p data-ds-rid="1000104_patient_lbl" id="wdov61YpQuCDb6dkE7AALw" data-ds-rt="label" className="text-left ds-t-nw ds-t-oe"><strong>Gerber, Susanna</strong></p>
          </div>
        </td>

        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000104_info" id="wzFAS0XbRgGajr8YVmjJgA">PAR 3 Quadrant</span></td>
        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000104_organizer" id="wsOt0KmkRnWMwu4d5rGnmg">Greifenberg, Wolfram</span></td>
        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000104_further" id="wgZ7JvGXS8qLiJe3JljdlA"></span></td>
      </tr>

      <tr className="even">
        <td className="">
          <a style={{maxWidth: 180 + "px"}} target="_self" className="ds-b-l btn btn-link btn-xs ds-fixed-col" data-ds-rt="viewLink" data-ds-rid="1000133_date" href="/online-terminbuch/addTermin?terminInstanz=1000133" id="wIpNK0QsQwiuQWOveFhR2w">
            <span className="ds-b-c"><span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wXl_wdmaR2uuxX6jtwrBSg">16.05.2021 10:00 - 11:00</span></span>
          </a>
        </td>
        <td className="">
          <p data-ds-rid="1000133_type" id="wDpGWoGyTtuIacnpnf7h6A" data-ds-rt="icon" className="text-center ds-i ds-scaling-col">
            <span><FaCircle style={{color:"hsl(180, 100%, var(--hsL25))"}} className="fa-fw  fas fa-circle" /></span>
          </p>
        </td>
        <td className="">
          <div className="ds-text-panel ds-t-nw ds-s-d ds-scaling-col" data-ds-rt="textPanel" data-ds-rid="1000133_patient" id="w_toM8-FS-iOLIIrgmyaJA">
            <span style={{backgroundColor:"hsl(200, 50%, var(--hsL45))", borderColor:"hsl(200, 50%, var(--hsL45)) color:hsl(0, 0%, var(--hsL98))"}} className="badge ds-badge-round" data-ds-rt="badge" data-ds-rid="1000133_patient_badge" id="wqWY6q6XQm-8-5m7fnYzEw">12</span>
            <p data-ds-rid="1000133_patient_lbl" id="wyHhJJBmTB-fuDNTi_ifDg" data-ds-rt="label" className="text-left ds-t-nw ds-t-oe"><strong>Ivers, Rolf</strong></p>
          </div>
        </td>
        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000133_info" id="wAhmnRcYRQqNjo5PoEan-A">PZR 60</span></td>
        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000133_organizer" id="wPfTsPXiRRugt16HzsiXdA">Greifenberg, Wolfram</span></td>
        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000133_further" id="wuLoS_GkS6SusDrZekWiUw"></span></td>
      </tr>

      <tr className="odd">
        <td className=""><a style={{maxWidth: 180 + "px"}} target="_self" className="ds-b-l btn btn-link btn-xs ds-fixed-col" data-ds-rt="viewLink" data-ds-rid="1000110_date" href="/online-terminbuch/addTermin?terminInstanz=1000110" id="wI56Hg_gQWKGHIz5n__1fw"><span className="ds-b-c"><span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wCPcye8fSPeJoQL8fg0M2w">16.05.2021 11:00 - 11:45</span></span></a></td>
        <td className="">
          <p data-ds-rid="1000110_type" id="whvI6WKzQTSg-I0_bfEveg" data-ds-rt="icon" className="text-center ds-i ds-scaling-col"><span><FaCircle style={{color:"hsl(300, 100%, var(--hsL50))"}} className="fa-fw  fas fa-circle" /></span></p>
        </td>
        <td className="">
          <div className="ds-text-panel ds-t-nw ds-s-d ds-scaling-col" data-ds-rt="textPanel" data-ds-rid="1000110_patient" id="w15nwwRATiC9Yl6leto40Q">
            <p data-ds-rid="1000110_patient_lbl" id="whQHqQ0ER0SRwI-xT_5fOg" data-ds-rt="label" className="text-left text-muted ds-t-nw ds-t-oe"><strong>Anonym</strong></p>
          </div>
        </td>
        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000110_info" id="w76gxDTmT2qbyIFRSOgZJg">Präp Gerüstanprobe</span></td>
        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000110_organizer" id="wwPD23GHSM6lneMK0cUv7Q">Greifenberg, Wolfram</span></td>
        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000110_further" id="wm5yTRqnRSCjOM_Uyyit7A"></span></td>
      </tr>

      <tr className="even">
        <td className=""><a style={{maxWidth: 180 + "px"}} target="_self" className="ds-b-l btn btn-link btn-xs ds-fixed-col" data-ds-rt="viewLink" data-ds-rid="1000135_date" href="/online-terminbuch/addTermin?terminInstanz=1000135" id="wzXMRkmLTHCJhdAGs5zmig"><span className="ds-b-c"><span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wunUJ0SNRQ2-Qp6jxpbaUA">16.05.2021 17:00 - 18:00</span></span></a></td>
        <td className="">
          <p data-ds-rid="1000135_type" id="wWh-ro0RQcWg1QZRZ8U7gQ" data-ds-rt="icon" className="text-center ds-i ds-scaling-col"><span><FaCircle style={{color:"hsl(180, 100%, var(--hsL25))"}} className="fa-fw  fas fa-circle" /></span></p>
        </td>
        <td className="">
          <div className="ds-text-panel ds-t-nw ds-s-d ds-scaling-col" data-ds-rt="textPanel" data-ds-rid="1000135_patient" id="wWBAWSMjSQmUTxeGWprxJQ"><span style={{backgroundColor:"hsl(200, 50%, var(--hsL45))", borderColor:"hsl(200, 50%, var(--hsL45))", color:"hsl(0, 0%, var(--hsL98))"}} className="badge ds-badge-round" data-ds-rt="badge" data-ds-rid="1000135_patient_badge" id="wLTmAdOJRF2rFXAffYo92A">27</span>
            <p data-ds-rid="1000135_patient_lbl" id="wnHfgTHeQUibxf-3FmYu7Q" data-ds-rt="label" className="text-left ds-t-nw ds-t-oe"><strong>Keller, Shana-Grace</strong></p>
          </div>
        </td>
        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000135_info" id="wQXh2QUkQhqFOTglTLLatg">PZR 60</span></td>
        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000135_organizer" id="w7qnBg5rSzmdy0Fj38LHig">Greifenberg, Wolfram</span></td>
        <td className=""><span className="ds-t-nw ds-t-oe ds-scaling-col" data-ds-rt="label" data-ds-rid="1000135_further" id="w6PSjm3KQeuMww0x3wXetA"></span></td>
      </tr>

    </tbody>;
  }


  return ( 
    <div className="ds-fi ds-fi-fhp" data-ds-rt="flexItem" data-ds-rid="calendarItem" id="wtxNmC61RgaPrTmD_MJFGA">
    <div className="ds-sizepanel-container" data-ds-rt="sizePanel" data-ds-rid="calPanel" id="wwgix9MTRBSV6Jw-l-gp1g">
      <div style={{minHeight: 240+"px", minWidth: 100+"px", height: 854+"px", flex: "0 0 " + 897 + "px"}} className="ds-sizepanel-wrapper ds-expand-width-parent ds-collapse-undefined">
        <div data-ds-rt="verticalPanel" data-ds-rid="w" id="wDHNL0FDTSCA-1kLH5BtAg">
          <div>
        
            <div data-ds-rt="datatable" data-ds-rid="terminDataTable" id="w9pKQgVgSxG3LEFK4Yf2tQ">
              <div id="w9pKQgVgSxG3LEFK4Yf2tQtable_wrapper" className="dataTables_wrapper DTS" role="grid">
          
          
                <div className="top">
                  <div className="dataTables_filter" id="w9pKQgVgSxG3LEFK4Yf2tQtable_filter">
                    <label className="input-group col-xs-12 col-sm-12 col-md-12 col-lg-6">
                      <input type="text" aria-controls="w9pKQgVgSxG3LEFK4Yf2tQtable" className="form-control"></input>
                      <span className="input-group-addon"><FaSearch className="fal fa-fw fa-search" /></span>
                    </label>
                  </div>
                  <div className="clear"></div>
                </div>

                <div id="w9pKQgVgSxG3LEFK4Yf2tQtable_processing" className="dataTables_processing" style={{visibility: "hidden"}}>
                  <div data-ds-rt="progressbar" className="progress progress-striped active">
                    <div style={{width: 100+"%"}} className="progress-bar">
                      <div></div>
                    </div>
                  </div>
                </div>

                <div className="dataTables_scroll">
            
                  {calendarListHeader()}

                  {calendarListBody()}
            

                </div>

        
                <div className="bottom">
                  <div className="dataTables_info" id="w9pKQgVgSxG3LEFK4Yf2tQtable_info">1 bis 26 von 39 Einträgen</div>
                  <div data-selection-counter-id="w9pKQgVgSxG3LEFK4Yf2tQ_selcount" className="label label-info ds-table-selectcounter hidden">0 ausgewählt</div>
                </div>
  
                <div className="clear"></div>


              </div>     
            </div>

          </div>     
        </div>
      </div>
    </div>
  </div>
   );
}

export default CalendarList;

// export default class CalendarList extends React.Component {
//   constructor(props) {
//     super(props);

 
//   }

//   componentDidMount() {
//     var self = this;



// 	  }

// 	componentWillUnmount() {
//     var self = this;


// 	}

    



//   render() {
//     return;  
//   }
// }

