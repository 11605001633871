import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Activities from './Activities';
import Notification from './Notification';
import Moments from './Moments';

function InformationTabs() {
    const [key, setKey] = useState('home');
    return (
        <>
            <div className='InformationTabs'>
                <Tabs
                    id="InformationTabs"
                    activeKey={key}
                    onSelect={(k: any) => setKey(k)}
                    className="mb-3"

                >
                    <Tab eventKey="home" title="Aktivitäten">
                        <Activities />
                    </Tab>
                    <Tab eventKey="profile" title="Benachrichtigungen">
                        <Notification />
                    </Tab>
                    <Tab eventKey="contact" title="Moments">
                        <Moments />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}

export default InformationTabs;