import { FC } from "react";
import { Box, DisplayEnd } from "../../../Components/StyledComponents";
import ButtonWidget from "../../../Components/ButtonWidget";
import { CheckControl } from "../../../Components/Widgets/InputControls/CheckControl";
import { SubmitHandler, useForm } from "react-hook-form";

interface IProps { }

export const EmbeddedVideos: FC<IProps> = ({ }) => {
    const { control, register, handleSubmit, formState: { errors, defaultValues }, reset, formState, watch, } = useForm();
    return (
        <>
            <h2>Eingebettete Videos</h2>
            <p>Erlaube das Einbinden von Videos von folgenden Plattformen:</p>
            <div className="mt-2">
                <Box className='mb-1'>
                    <CheckControl
                        fieldType={"checkbox"}
                        control={control}
                        fieldName={"YouTube"}
                        value="YouTube"
                        label="YouTube"
                        isInline={true}
                        disabled={false}
                        defaultChecked={false}
                    />
                </Box>
            </div>
            <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                <DisplayEnd className="px-3">
                    <ButtonWidget
                        text="Speichern"
                        varient="primary"
                        class="py-2 px-3"
                    />
                </DisplayEnd>
            </div>
        </>
    )
}