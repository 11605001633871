import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Row, Col, } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
const ViewRecipient = (props: any) => {
    return (
        <>
            <Row>
                <Col md={9}>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Control size="sm" type="email" placeholder={props.Palaceholder} />
                    </Form.Group>
                </Col>
                <Col md={3} className='text-end'>
                    <Button className='default-btn f-12px w-100' onClick={props.showModel}><FaPlusCircle className='default-btn-icon' />{props.BtnText}</Button>
                </Col>
            </Row>
        </>
    )
}
export default ViewRecipient;