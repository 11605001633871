import { FC } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { ContentBox } from "../../../Components/StyledComponents"

interface IProps {
}
export const AdminDashboard: FC<IProps> = ({ }) => {
    return (
        <>
            <Card className='boxShadow'>
                <Card.Body>
                    <Row className="justify-content-center py-5">
                        <Col md={8}>
                            <ContentBox className="p-5">
                                <img src='/images/MediDash.webp' className="w-100" />
                            </ContentBox>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}