import * as React from 'react';
import { useState, useEffect } from 'react';
import FormControl from '../../../FormControl';
import FormCheck from '../../../FormCheck';
import { filtesListType } from '../../../../Pages/mycalendar/left/SaveCurrentFilter/SaveFilter';

interface Iprops {
    setFiltersList:React.Dispatch<React.SetStateAction<filtesListType[]>>
    data:filtesListType[]
    closeModal:React.Dispatch<React.SetStateAction<boolean>>
}

const SaveCurrentFilterContent: React.FC<Iprops> = ({setFiltersList,data , closeModal})  => {
    const [saveDefaultFilter, setsaveDefaultFilter] = useState<boolean>(false);
    const [title, settitle] = useState<string>("");        
    return (
        <>
            <FormControl handleChange={(e) => settitle(e.target.value)} Placeholder='Title...' isFormGroup={false} Type='text' Size='sm' Name={'subtaskItem-input'} Id={'subtaskItem-input'} />
            <div className='mt-2 d-flex align-items-center justify-content-between'>
                <FormCheck
                    handleChange={(event) => setsaveDefaultFilter(event.target.checked)}
                    type='checkbox'
                    name='saveDefaultFilter'
                    Id='saveDefaultFilter'
                    value='saveDefaultFilter'
                    label='Save as default filter*'
                    isFormGroup={true}
                    isInline={true}
                    disabled={false}
                    checked={saveDefaultFilter}
                />
                <button className='btn btn-primary primary-bg' onClick={() => {setFiltersList([...data , {title:title,isChecked:saveDefaultFilter}]) ; closeModal(false)}}>Save</button>
            </div>
           

        </>
    );
}

export default SaveCurrentFilterContent;