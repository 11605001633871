import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, } from 'react-bootstrap';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import ChooseCategoryModal from './ChooseTemplateCategoryModal';
import Badge from 'react-bootstrap/Badge';
import Recipient from './Recipient';
import TimePicker from '../TimePicker';
import { DisplayStart, ModalHeader } from '../StyledComponents';
import FormCheck from '../FormCheck';
import CustomTooltip from '../Tooltip';
import BadgeWidget from '../BadgeWidget';

interface PropsType {
    displayAddTemplateModal: boolean,
    hideAddTemplateModal: () => void,
    isAppointment?: boolean,
    isTask?: boolean,
    chooseCategoryClickHandler?: () => void
    recipientClickHandler?: () => void

}

function AddTemplateModal(props: PropsType) {
    // const [RecipientModal, setRecipientModal] = useState<boolean>(false);


    const [Time, setTime] = useState<boolean>(true);
    const [TimeNotSpecified, setTimeNotSpecified] = useState<boolean>(true);
    const [AllowReading, setAllowReading] = useState<boolean>(false);
    const [AllowReadingAddUser, setAllowReadingAddUser] = useState<string>('');
    const [AllowEditingAddUser, setAllowEditingAddUser] = useState<string>('');

    useEffect(() => {
        console.log(AllowReadingAddUser)
    }, [AllowReadingAddUser]);
    useEffect(() => {
        console.log(AllowEditingAddUser)
    }, [AllowEditingAddUser]);

    const allowUseChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        debugger
        setAllowReadingAddUser(e.target.value);
    }
    const allowEdittingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        debugger
        setAllowEditingAddUser(e.target.value);
    }

    return (
        <>
            <Modal size="lg" className='shadow-modal' show={props.displayAddTemplateModal} onHide={props.hideAddTemplateModal} backdrop="static" keyboard={false} aria-labelledby="template-modal" >
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="template-modal">
                        Vorlage auswählen
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <Row>
                        <Col md={9}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Vorlagenkategorie eingeben..." />
                            </Form.Group>
                        </Col>
                        <Col md={3} className='text-end'>
                            <Button className='default-btn f-12px w-md-100' onClick={props.chooseCategoryClickHandler}><FaPlusCircle className='default-btn-icon' /> Kategorie auswählen</Button>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Title" />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Beschreibung" />
                            </Form.Group>
                        </Col>
                        {props.isTask ?
                            <Col md={12}>
                                <Row className='border-top border-bottom py-3 mb-3'>
                                    <Col md={2} className='align-self-center'>
                                        <Form.Label className='mb-0'>Fälligkeit</Form.Label>
                                    </Col>
                                    <Col md={10}>
                                        <div className='d-flex align-items-center'>
                                            <div className='me-2'>
                                                <Form.Check
                                                    label=""
                                                    name=""
                                                    type="radio"
                                                    id=""
                                                />
                                            </div>
                                            <div className='me-2'>
                                                <Form.Control size="sm" type="number" placeholder='0' />
                                            </div>
                                            <div>
                                                <p className='m-0'>Tage nach Erstellung</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2} className='align-self-center mb-3'>
                                        <Form.Label className='mb-0'>Zeit</Form.Label>
                                    </Col>
                                    <Col md={10} className='mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <div className='me-2'>
                                                <Form.Check
                                                    label="Feste Zeit"
                                                    type="radio"
                                                    id="set-time"
                                                    onChange={() => setTime(true)}
                                                    checked={Time === true}
                                                />
                                            </div>
                                            <div className='me-2'>
                                                <TimePicker />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-self-center mb-3'>
                                    </Col>
                                    <Col md={10} className='mb-3'>
                                        <div className='d-flex align-items-center' onClick={() => setTime(false)}>
                                            <div className='me-2'>
                                                <Form.Check
                                                    label="Relative Zeit"
                                                    type="radio"
                                                    id="relative-time"
                                                    onChange={() => setTime(false)}
                                                    checked={Time === false}
                                                />
                                            </div>
                                            <div className='me-2'>
                                                <Form.Select>
                                                    {(() => {
                                                        let options = [];
                                                        for (let i = 0; i <= 23; i++) {
                                                            options.push(<option value={i}>{i}</option>);
                                                        }
                                                        return options;
                                                    })()}
                                                </Form.Select>
                                            </div>
                                            <div className='me-2'>
                                                <p className='m-0'>Stunden</p>
                                            </div>
                                            <div className='me-2'>
                                                <Form.Select>
                                                    {(() => {
                                                        let options = [];
                                                        for (let i = 0; i <= 59; i++) {
                                                            options.push(<option value={i}>{i}</option>);
                                                        }
                                                        return options;
                                                    })()}
                                                </Form.Select>
                                            </div>
                                            <div>
                                                <p className='m-0'>Minuten nach Erstellung</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            : null
                        }
                        {props.isAppointment ?
                            <Col md={12}>
                                <Row className='border-top py-3'>
                                    <Col md={12}>
                                        <div>
                                            <Form.Check
                                                label="Zeit nicht Vorgegeben"
                                                type="checkbox"
                                                checked={TimeNotSpecified}
                                                onChange={() => setTimeNotSpecified(!TimeNotSpecified)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='border-top border-bottom py-3 mb-3'>
                                    <Col md={2} className='align-self-center'>
                                        <Form.Label className='mb-0'>Beginn</Form.Label>
                                    </Col>
                                    <Col md={10}>
                                        <div className='d-flex align-items-center'>
                                            <div className='me-2'>
                                                <Form.Check
                                                    label=""
                                                    name=""
                                                    type="radio"
                                                    id=""
                                                    disabled={TimeNotSpecified === true}
                                                />
                                            </div>
                                            <div className='me-2'>
                                                <Form.Control size="sm" type="number" placeholder='0' disabled={TimeNotSpecified === true} />
                                            </div>
                                            <div>
                                                <p className='m-0'>Tage nach Erstellung</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='border-bottom'>
                                    <Col md={2} className='align-self-center mb-3'>
                                        <Form.Label className='mb-0'>Zeit</Form.Label>
                                    </Col>
                                    <Col md={10} className='mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <div className='me-2'>
                                                <Form.Check
                                                    label="Feste Zeit"
                                                    type="radio"
                                                    id="set-time"
                                                    onChange={() => setTime(true)}
                                                    checked={Time === true}
                                                    disabled={TimeNotSpecified === true}
                                                />
                                            </div>
                                            <div className='me-2'>
                                                <TimePicker disabled={TimeNotSpecified === true} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-self-center mb-3'>
                                    </Col>
                                    <Col md={10} className='mb-3'>
                                        <div className='d-flex align-items-center' onClick={() => setTime(false)}>
                                            <div className='me-2'>
                                                <Form.Check
                                                    label="Relative Zeit"
                                                    type="radio"
                                                    id="relative-time"
                                                    onChange={() => setTime(false)}
                                                    checked={Time === false}
                                                    disabled={TimeNotSpecified === true}
                                                />
                                            </div>
                                            <div className='me-2'>
                                                <Form.Select disabled={TimeNotSpecified === true}>
                                                    {(() => {
                                                        let options = [];
                                                        for (let i = 0; i <= 23; i++) {
                                                            options.push(<option value={i}>{i}</option>);
                                                        }
                                                        return options;
                                                    })()}
                                                </Form.Select>
                                            </div>
                                            <div className='me-2'>
                                                <p className='m-0'>Stunden</p>
                                            </div>
                                            <div className='me-2'>
                                                <Form.Select disabled={TimeNotSpecified === true}>
                                                    {(() => {
                                                        let options = [];
                                                        for (let i = 0; i <= 59; i++) {
                                                            options.push(<option value={i}>{i}</option>);
                                                        }
                                                        return options;
                                                    })()}
                                                </Form.Select>
                                            </div>
                                            <div>
                                                <p className='m-0'>Minuten nach Erstellung</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pt-3'>
                                    <Col md={2} className='align-self-center'>
                                        <Form.Label className='mb-0'>Dauer</Form.Label>
                                    </Col>
                                    <Col md={10}>
                                        <div className='d-flex align-items-center'>
                                            <div className='me-2'>
                                                <Form.Control size="sm" type="number" placeholder='0' disabled={TimeNotSpecified === true} />
                                            </div>
                                            <div className='me-2'>
                                                <p className='m-0'>Tage</p>
                                            </div>
                                            <div className='me-2'>
                                                <Form.Select disabled={TimeNotSpecified === true}>
                                                    {(() => {
                                                        let options = [];
                                                        for (let i = 0; i <= 23; i++) {
                                                            options.push(<option value={i}>{i}</option>);
                                                        }
                                                        return options;
                                                    })()}
                                                </Form.Select>
                                            </div>
                                            <div className='me-2'>
                                                <p className='m-0'>Stunden</p>
                                            </div>
                                            <div className='me-2'>
                                                <Form.Select disabled={TimeNotSpecified === true}>
                                                    {(() => {
                                                        let options = [];
                                                        for (let i = 0; i <= 59; i++) {
                                                            options.push(<option value={i}>{i}</option>);
                                                        }
                                                        return options;
                                                    })()}
                                                </Form.Select>
                                            </div>
                                            <div>
                                                <p className='m-0'>Minuten</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            : null
                        }
                    </Row>
                    {AllowReading ?
                        <div>
                            <Row className='border-top pt-3 mt-3'>
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label className="mb-2">Benutzung erlauben für</Form.Label>
                                        <div>
                                            <FormCheck
                                                handleChange={allowUseChanges}
                                                type='radio'
                                                name='AllowUse'
                                                Id='allowUseAt'
                                                value='allowUseAt'
                                                label='Alle'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                                checked={AllowReadingAddUser === 'allowUseAt' ? true : false}
                                            />
                                            <FormCheck
                                                handleChange={allowUseChanges}
                                                type='radio'
                                                name='AllowUse'
                                                Id='allowUseAdmin'
                                                value='allowUseAdmin'
                                                label={<>
                                                    <DisplayStart>
                                                        <CustomTooltip
                                                            ToolTipTrigger={<BadgeWidget solid={true} title='Admin' color='#000' bgColor='#e6e6e6' />}
                                                            toolTipContent='Alicia Gojani, Diana Lauser, Dr Sven Markus Gallenbach, Nadine Häring, Thomas Planitzer & Team'
                                                            toolTipPlacement='top'
                                                            contentAlign='center'
                                                        /><label className='ms-2'> und ...</label>
                                                    </DisplayStart>
                                                </>}
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                                checked={AllowReadingAddUser === 'allowUseAdmin' ? true : false}
                                            />
                                            <div className='form-check form-check-inline'>
                                                <input type="checkbox" name='extra' className='form-check-input me-2' value="" />
                                                <label> externe Nutzer einbeziehen</label>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                {AllowReadingAddUser === 'allowUseAdmin' ?
                                    <Col md={12}>
                                        <ViewRecipient showModel={props.recipientClickHandler} />
                                    </Col>
                                    :
                                    null
                                }
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label className="mb-2">Bearbeiten erlauben für</Form.Label>
                                        <div>
                                            <FormCheck
                                                handleChange={allowEdittingChange}
                                                type='radio'
                                                name='AllowEditing'
                                                Id='allowEditingAt'
                                                value='allowEditingAt'
                                                label='Alle'
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                                checked={AllowEditingAddUser === 'allowEditingAt' ? true : false}
                                            />
                                            <FormCheck
                                                handleChange={allowEdittingChange}
                                                type='radio'
                                                name='AllowEditing'
                                                Id='allowEditingAdmin'
                                                value='allowEditingAdmin'
                                                label={<>
                                                    <DisplayStart>
                                                        <CustomTooltip
                                                            ToolTipTrigger={<BadgeWidget solid={true} title='Admin' color='#000' bgColor='#e6e6e6' />}
                                                            toolTipContent='Alicia Gojani, Diana Lauser, Dr Sven Markus Gallenbach, Nadine Häring, Thomas Planitzer & Team'
                                                            toolTipPlacement='top'
                                                            contentAlign='center'
                                                        /><label className='ms-2'> und ...</label>
                                                    </DisplayStart>
                                                </>}
                                                isFormGroup={false}
                                                isInline={true}
                                                disabled={false}
                                                checked={AllowEditingAddUser === 'allowEditingAdmin' || AllowReadingAddUser === 'allowUseAdmin' ? true : false}
                                            />
                                            <div className='form-check form-check-inline'>
                                                <input type="checkbox" name='extra' className='form-check-input me-2' value="" />
                                                <label> externe Nutzer aus ausgewählten Gruppen einbeziehen</label>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                {AllowEditingAddUser === 'allowEditingAdmin' || AllowReadingAddUser === 'allowUseAdmin' ?
                                    <Col md={12}>
                                        <ViewRecipient showModel={props.recipientClickHandler} />
                                    </Col>
                                    :
                                    null
                                }
                            </Row>
                        </div>
                        :
                        null
                    }
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <div>
                        <Button className='default-btn f-14px' onClick={() => setAllowReading(!AllowReading)}>
                            {AllowReading ?
                                <>
                                    <FaMinusCircle className='default-btn-icon' />
                                    <span> Optionen ausblenden</span>
                                </>
                                :
                                <>
                                    <FaPlusCircle className='default-btn-icon' />
                                    <span>Zugriffsrechte definieren</span>
                                </>
                            }

                        </Button>
                    </div>
                    <div>
                        <Button onClick={props.hideAddTemplateModal} className='default-btn f-14px me-2' >Abbrechen</Button>
                        <Button className='primary-bg f-14px btn btn-primary' >Ersteuen</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* <Recipient displayRecipientModal={RecipientModal} hideRecipientModal={() => setRecipientModal(false)} /> */}

        </>
    )
}

interface ViewRecipientType {
    showModel?: () => void,
}
const ViewRecipient = (props: ViewRecipientType) => {
    return (
        <>
            <Row>
                <Col md={9}>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Control size="sm" type="text" placeholder="Bitte Gruppe oder Nutzer eingeben..." />
                    </Form.Group>
                </Col>
                <Col md={3} className='text-end'>
                    <Button className='default-btn f-12px w-md-100' onClick={props.showModel}><FaPlusCircle className='default-btn-icon' />Alle anzeigen</Button>
                </Col>
            </Row>
        </>
    )
}
export default AddTemplateModal;