import * as React from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from '../../FormControl';
import { Box, DisplayStart, Divider } from '../../StyledComponents';
import FormCheck from '../../FormCheck';
import TimePicker from '../../TimePicker';
import DateTimePicker from '../../DateTimePicker';
import FormSelect from '../../FormSelect';
import { Col, Row } from 'react-bootstrap';
import { tagOneOptions } from '../../../CommonJSON';

export const RememberOptionModalContent = () => {

    return (
        <>
            <Row className='mb-2'>
                <Col md={12}>
                    <Form.Label className="mb-2">Erinnere mich...</Form.Label>
                </Col>
                <Col md={2}>
                    <Box>
                        <FormCheck
                            type='radio'
                            name='group3'
                            Id='group3'
                            value='nie'
                            label='nie'
                            isFormGroup={false}
                            isInline={false}
                            disabled={false}
                        />
                    </Box>
                </Col>
                <Col md={4}>
                    <DisplayStart ml='50px'>
                        <FormCheck
                            type='radio'
                            name='group3'
                            Id='group3'
                            value='in'
                            label='in'
                            isFormGroup={false}
                            isInline={false}
                            disabled={false}
                        />
                        <div className='mx-2'>
                            <FormSelect
                                isFormGroup={false}
                                selectOptions={tagOneOptions}
                                Size='sm'
                                Name='salute'
                                Id='salute'
                            />
                        </div>
                        <div>
                            <span className='mx-2'> Tag </span>
                        </div>
                    </DisplayStart>
                </Col>
                <Col md={4}>
                    <DisplayStart>
                        <FormCheck
                            type='radio'
                            name='group3'
                            Id='group3'
                            value='in'
                            label={undefined}
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                        />
                        <DateTimePicker />
                    </DisplayStart>
                </Col>
            </Row>
            <Divider mt='10px' mb='10px' />
            <Row>
                <Col md={12}>
                    <Box className='mb-2'>
                        <FormControl isFormGroup={true} Type='text' Size='sm' Placeholder='Stichwörter' Name='Stichwörter' />
                    </Box>
                    <Divider mt='8px' mb='12px' />
                </Col>
                <Col md={12}>
                    <Box className='mb-2'>
                        <Form.Control as="textarea" placeholder="Notizen" rows={4} />
                    </Box>
                </Col>
            </Row>


        </>
    );
}