import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { GrUser, GrUserFemale, GrPhone, GrClose } from "react-icons/gr";
import Badge from '@mui/material/Badge';
import moment from 'moment';
// import { dynamicGetApi } from './../../Common'
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { useCookies } from 'react-cookie';

const BadgeColor = styled.span`
background : ${props => props.bg};
width: 20px;
height: 20px;
border-radius: 25px;
display:inline-block
`
const CenterAlign = styled.div`
display:flex;
align-items:center
`
const SpaceBetween = styled.div`
display:flex;
justify-content:space-between;
align-items:center;`

export default function PatientSelect(props,selectedPatientId,closed) {
  let navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [checkSelected, setCheckSelected] = useState(null);
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    pageData();
  }, []);
  useEffect(() => {
    if(closed === "closed"){
      setCheckSelected(null);
    }
  }, [closed]);
  useEffect(() => {
    if(!!checkSelected){
      props.setSelectedPatient(checkSelected.patientId);
    }
    else{
      props.setSelectedPatient(null);
    }
  }, [checkSelected]);
  useEffect(() => {
    if(!!selectedPatientId){
      let select = userList.find(x=>x.patientId.toString() === selectedPatientId.toString());
      setCheckSelected(select);
    }
  }, [selectedPatientId]);
  const pageData = async () => {
    let patientUrl = "online-terminbuch/patientDropdown";
    // let patientList = await dynamicGetApi(patientUrl,navigate, setCookie);
    // setUserList(patientList.data);
  }
  return (
    !!checkSelected ?

      <div className="row " style={{ marginLeft: "0px", marginRight: "0px" }}>
        <div className='row w-100 px-3' style={{ marginBottom: "10px" }}>
          <div className='col-md-1 ' style={{ textAlign: "center" }}>
            {checkSelected.gender === "male" ? <GrUser fontSize={28} /> : <GrUserFemale fontSize={28} />}
          </div>
          <div className='col-md-9'>
            <Badge bg="secondary">{checkSelected.countryCode}</Badge> {checkSelected.name}
            <div><GrPhone /> {checkSelected.phoneNo}</div>
          </div>
          <div className='col-md-2'>
            <SpaceBetween>
              <span>{moment(checkSelected.dob).format("DD/MM/YYYY")}</span>
              <button className='btn btn-dark p-1' onClick={() => setCheckSelected(null)}> <GrClose /> </button>
            </SpaceBetween>
          </div>
        </div>
      </div>
      :
      <Autocomplete
        id="country-select-demo"
        sx={{ width: 300 }}
        options={userList}
        size="small"
        onChange={(e, value) => setCheckSelected(value)}
        autoHighlight
        getOptionLabel={(option) => option.name}

        renderOption={(props, option) => (

          <li {...props} className="row " style={{ marginLeft: "0px", marginRight: "0px" }}>
            <div className='row w-100 px-3' style={{ marginBottom: "10px" }}>
              <div className='col-md-1 ' style={{ textAlign: "center" }}>
                {option.gender === "male" ? <GrUser fontSize={28} /> : <GrUserFemale fontSize={28} />}
              </div>
              <div className='col-md-9'>

                {option.name}
                <div><GrPhone /> {option.phoneNo}</div>
              </div>
              <div className='col-md-2'>
                {moment(option.dob).format("DD/MM/YYYY")}
              </div>
            </div>
          </li>

        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />

  );
}
export function BeschreibungSelect(input,props,setValue) {
  const [checkSelected, setCheckSelected] = useState(null);
  useEffect(() => {
    if (!!checkSelected) {
      props.setDescription(checkSelected.name);
    }
  }, [checkSelected]);
  useEffect(() => {
    if(!!setValue){
      let data = input.find(x=>x.name === setValue);
      setCheckSelected(data);
    }
  }, [setValue]);
  return (
    <Autocomplete
    freeSolo
    disableClearable
      id="country-select-demo"
      value={checkSelected}
      options={input}
      size="small"
      onChange={(e, value) => setCheckSelected(value)}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (

        <li {...props} className="row " style={{ marginLeft: "0px", marginRight: "0px" }}>
          <div className='row w-100 px-3 mb-3' style={{ marginBottom: "10px" }}>
            <div className='col-md-4 '>
              {option.name}
            </div>
            <div className='col-md-3'>
              <CenterAlign >
                <BadgeColor bg={option.color} className="mr-2"></BadgeColor>   {option.colorName}
              </CenterAlign>
            </div>
            <div className='col-md-5 text-right'>
              {option.time}
            </div>
          </div>
        </li>

      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}