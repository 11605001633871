import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { BodyText, Box, DisplayBetween, Divider } from '../../../Components/StyledComponents';
import { Button } from 'react-bootstrap';
import { FaClock } from 'react-icons/fa';
import BadgeWidget from '../../../Components/BadgeWidget';
import CustomTooltip from '../../../Components/Tooltip';
import CommonModal from '../../../Components/Modals/CommonModal';
import { ReportUserModalContent } from '../../../Components/Modals/ModelContents/ReportUserModalContent';
import { BlockedUserModalContent } from '../../../Components/Modals/ModelContents/BlockedUserModalContent';
import ButtonWidget from '../../../Components/ButtonWidget';

export const SidebarDetail = () => {

    const [reportContent, setReportContent] = useState<boolean>(false);
    const [blockUserModal, setBlockUserModal] = useState<boolean>(false);

    return (
        <>
            <div className='sidebar-users'>
                <Card className='boxShadow border-0 rounded-0'>
                    <Card.Header className='bg-white'>
                        <Card.Title className='mb-0 text-center fw-400 f-16px'>Thomas Planitzer & Team</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div className='user-detail'>
                            <div className='text-center'>
                                <img src='/images/account-b.png' height="130" width="130" alt="user-image" />
                                <div>
                                    <Link to="" className='text-decoration-none'>
                                        <span className='fw-700 w-176px text-truncate text-link-color f-14px'> Edit Profile </span>
                                    </Link>
                                </div>
                            </div>
                            <Divider mt="10px" mb="10px" />
                            <div className='d-grid gap-2'>
                                <Button className='primary-bg f-15px d-flex align-items-center justify-content-center'> Add message <FaClock className='ms-2' size="14px" /></Button>
                            </div>
                            <Divider mt="10px" mb="10px" />
                            <BodyText fontSize="13px" fontWeight="400" textColor="#666"> Not member of any group </BodyText>
                            <Box mt="4px" mb="4px">
                                <BadgeWidget outiline={true} title="Praxis Gallenbach & Zähne " color="#0d3f5f" bgColor="#fff" />
                            </Box>
                            <Box>
                                <CustomTooltip
                                    ToolTipTrigger={<BadgeWidget solid={true} title="Assistenz" color="#395260" bgColor="#d4eaf7" />}
                                    toolTipContent="Gojani, Anna Benm, Cara Hoa Trinh, Irene Gantert"
                                    toolTipPlacement='top'
                                    contentAlign='center'
                                />
                            </Box>
                            <Divider mt="10px" mb="10px" />
                            <div className='info-dd'>
                                <dl>
                                    <dt><BodyText fontSize="14px" fontWeight="600" textColor="#666"> Gender: </BodyText></dt>
                                    <dd><BodyText fontSize="13px" fontWeight="400" textColor="#666" ml="15px"> male </BodyText></dd>
                                    <dt><BodyText fontSize="14px" fontWeight="600" textColor="#666"> Email: </BodyText></dt>
                                    <dd><BodyText fontSize="13px" fontWeight="400" textColor="#666" ml="15px"> thomas.planitzer@hotelkit.net </BodyText></dd>
                                    <dt><BodyText fontSize="14px" fontWeight="600" textColor="#666"> Phone: </BodyText></dt>
                                    <dd><BodyText fontSize="13px" fontWeight="400" textColor="#666" ml="15px"> +43662238080 78 </BodyText></dd>
                                    <dt><BodyText fontSize="14px" fontWeight="600" textColor="#666"> Position: </BodyText></dt>
                                    <dd><BodyText fontSize="13px" fontWeight="400" textColor="#666" ml="15px"> Customer Success Manager </BodyText></dd>
                                    <dt><BodyText fontSize="14px" fontWeight="600" textColor="#666"> Department: </BodyText></dt>
                                    <dd><BodyText fontSize="13px" fontWeight="400" textColor="#666" ml="15px"> hotelkit </BodyText></dd>
                                    <dt><BodyText fontSize="14px" fontWeight="600" textColor="#666"> Last login: </BodyText></dt>
                                    <dd><BodyText fontSize="13px" fontWeight="400" textColor="#666" ml="15px"> 24M 2023 19:19 </BodyText></dd>
                                </dl>
                            </div>
                            <div className='text-center'>
                                <div className='mb-3'>
                                    <Link to="" className='text-decoration-none' onClick={() => setBlockUserModal(true)}>
                                        <span className='fw-500 w-176px text-truncate text-link-color f-14px text-primary'> Block user </span>
                                    </Link>
                                </div>
                                <div className='mb-3'>
                                    <Link to="" className='text-decoration-none' onClick={() => setReportContent(true)}>
                                        <span className='fw-500 w-176px text-truncate text-link-color f-14px text-danger'> Report user </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>

            <CommonModal
                showModal={reportContent}
                hideModal={() => setReportContent(false)}
                modalTitle='Report content'
                modalContent={<ReportUserModalContent />}
                modalSize='md'
            />

            <CommonModal
                showModal={blockUserModal}
                hideModal={() => setBlockUserModal(false)}
                modalTitle="Blockierte Nutzer"
                modalContent={<BlockedUserModalContent pageName="block-user-detail" />}
                modalSize="md"
                modalFooterContent={
                    <>
                        <DisplayBetween className='w-100'>
                            <ButtonWidget text={"Cancel"} varient='light' handleClick={() => setBlockUserModal(false)} />
                            <ButtonWidget text={"Block user"} varient='danger' handleClick={() => setBlockUserModal(true)} />
                        </DisplayBetween>
                    </>
                }
            />

        </>
    )
}