import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  checkrerenderCalendar: false,
  reRenderAppointmentData: false,
  appointmentFormData: {},
  moveWaitingCheck : false
}

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {

    setRerenderCalendar: (state, action) => {
      state.checkrerenderCalendar = action.payload
    },
    setRerenderAppointmentData: (state, action) => {
      state.reRenderAppointmentData = action.payload
    },
    setAppointmenFormData: (state, action) => {
      state.appointmentFormData = action.payload
    },
    setmoveWaitingCheck: (state, action) => {
      state.moveWaitingCheck = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setRerenderCalendar, setRerenderAppointmentData, setAppointmenFormData, setmoveWaitingCheck} = calendarSlice.actions

export default calendarSlice.reducer