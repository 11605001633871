import React from 'react';
import Card from 'react-bootstrap/Card';
function BookmarksWidget() {
    return (
        <>
            <Card className='border-0 rounded-0 b-shadow'>
                <Card.Header className='bg-white'>
                    <Card.Title>Status Reparaturen</Card.Title>
                </Card.Header>
                <Card.Body className='h-190px'>
                    <Card.Text className='text-center'>Aktuell keine Einträge</Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default BookmarksWidget;