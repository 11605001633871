import React, { useState, useEffect, FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import ButtonWidget from '../../../Components/ButtonWidget';
import CommonModal from '../../../Components/Modals/CommonModal';
import { UserAdminModal } from '../AdminModal/UserAdminModal';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { TabBox, TabItem } from '../../../Components/StyledComponents';
import { Outlet } from 'react-router-dom';



interface IProps { }

export const SettingsIndex: FC<IProps> = ({ }) => {

    const [addUserAdmin, setAddUserAdmin] = useState<boolean>(false);

    return (
        <>
            <Card className='boxShadow'>
                <Card.Body className='p-0'>
                    <TabBox>
                        <TabItem to='option' style={{ borderTopLeftRadius: '0.375rem' }}>Ansicht</TabItem>
                        <TabItem to='language'>Spracheinstellungen</TabItem>
                        <TabItem to='welcome-meessage'>Willkommensnachricht</TabItem>
                        <TabItem to='pdf-setting'>PDF-EinsteIIungen</TabItem>
                        <TabItem to='standard-streamtyp'>Standard-Streamtyp</TabItem>
                        <TabItem to='embedded-videos' style={{ borderTopRightRadius: '0.375rem' }}>Eingebettete Videos</TabItem>
                    </TabBox>
                    <div className='py-4 px-3'>
                        <Outlet />
                    </div>
                </Card.Body>
            </Card>

            <CommonModal
                showModal={addUserAdmin}
                hideModal={() => setAddUserAdmin(false)}
                modalTitle='Nutzer erstellen'
                modalContent={<UserAdminModal />}
                modalSize='lg'
                footerClass={"footer-sticky"}
            />
        </>
    )
}