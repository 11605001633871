import React, { useState, useEffect } from 'react';
import { Row, Col, } from 'react-bootstrap';
import { BodyText, BorderBox, Box, CustomButton, DisplayBetween, DisplayCenter, DisplayStart, Divider } from '../../StyledComponents';
import Form from 'react-bootstrap/Form';
import FormControl from '../../FormControl';
import FormCheck from '../../FormCheck';
import CustomTooltip from '../../Tooltip';
import ButtonWidget from '../../ButtonWidget';
// import { RxCrossCircled } from "react-icons/rx";
import { FaRegTimesCircle } from "react-icons/fa";
import BadgeWidget from '../../BadgeWidget';
import Recipient from '../Recipient';
import { Link } from 'react-router-dom';

type commentRoleType = {
    creation: boolean,
    lastChange: boolean,
    latestComment: boolean,
}

export const SendReminderModalContent = () => {
    const [networkToogle, setNetworkToogle] = useState<boolean>(false);
    const [notRead, setNotRead] = useState<boolean>(false);
    const [byRead, setByRead] = useState<boolean>(false);
    const [bothRead, setBothRead] = useState<boolean>(false);
    const [showNetwork, setShowNetwork] = useState<string>('');
    const [showComment, setShowComment] = useState<string>('');
    const [showbyRead, setShowByRead] = useState<string>('');
    const [commentRole, setCommentRole] = useState<commentRoleType>({
        creation: false,
        lastChange: false,
        latestComment: false,
    });

    const handleNetworkCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowNetwork(event.currentTarget.value);
    };
    const handleCommentCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowComment(event.currentTarget.value);
        if (event.currentTarget.value === 'erstellung') {
            setCommentRole({
                creation: true,
                lastChange: false,
                latestComment: false,
            })
        }
        if (event.currentTarget.value === 'letzteBearbeitung') {
            setCommentRole({
                creation: false,
                lastChange: true,
                latestComment: false,
            })
        }
        if (event.currentTarget.value === 'neusterKommentar') {
            setCommentRole({
                creation: false,
                lastChange: false,
                latestComment: true,
            })
        }
    };
    const handleByReadCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        debugger;
        setShowByRead(event.currentTarget.value);
        if (event.currentTarget.value === 'nichtGelesenVon') {
            setNotRead(true);
            setByRead(false);
            setBothRead(false);
        }
        if (event.currentTarget.value === 'gelesenVon') {
            setByRead(true);
            setNotRead(false);
            setBothRead(false);
        }
        if (event.currentTarget.value === 'beides') {
            setBothRead(true);
            setByRead(false);
            setNotRead(false);
        }
    };


    useEffect(() => {
        console.log(showNetwork);
    }, [showNetwork]);

    const HandleNetworkToggle = () => {
        setNetworkToogle(!networkToogle);
    }


    return (
        <>
            <Row>
                <Col md={12}>
                    <FormControl isFormGroup={true} Type='text' Size='sm' Placeholder='Suche nach ...' Name='searchFor' Id='searchFor' />
                </Col>
                <Divider mt='10px' mb='8px' />
                <Col md={12}>
                    <Form.Group controlId='Anrede' className="mb-1">
                        <FormCheck
                            handleChange={handleNetworkCheck}
                            type='radio'
                            name='phone'
                            Id='phone1'
                            value='alleNetzwerke'
                            label='Alle Netzwerke'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showNetwork === 'alleNetzwerke' ? true : false}
                        />
                        <FormCheck
                            handleChange={handleNetworkCheck}
                            type='radio'
                            name='phone'
                            Id='phone2'
                            value='optionUpdates'
                            label={<BadgeWidget outiline={true} title='OPTI QMH Updates' color='#e4005f' />}
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showNetwork === 'optionUpdates' ? true : false}
                        />
                        <Link to="" className='text-decoration-underline ms-3' onClick={HandleNetworkToggle}>
                            {networkToogle ?
                                <span className='text-nowrap'>Netzwerke verbergen</span>
                                :
                                <span className='text-nowrap'>Netzwerk ändern</span>
                            }
                        </Link>
                    </Form.Group>
                    {networkToogle ?
                        <FormCheck
                            handleChange={handleNetworkCheck}
                            type='radio'
                            name='phone'
                            Id='phone3'
                            value='praxisGallenbach'
                            label={<BadgeWidget outiline={true} title='Praxis Gallenbach & Zähne' color='#0d3f5f' />}
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showNetwork === 'praxisGallenbach' ? true : false}
                        />
                        :
                        null
                    }
                </Col>
                <Divider mt='10px' mb='8px' />
                <Col md={12}>
                    <Form.Group controlId='Anrede' className="mb-1">
                        <FormCheck
                            handleChange={handleCommentCheck}
                            type='radio'
                            name='creation'
                            Id='creationId'
                            value='erstellung'
                            label='Erstellung'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showComment === 'erstellung' ? true : false}
                        />
                        <FormCheck
                            handleChange={handleCommentCheck}
                            type='radio'
                            name='lastChange'
                            Id='lastChangeId'
                            value='letzteBearbeitung'
                            label='Letzte Bearbeitung'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showComment === 'letzteBearbeitung' ? true : false}
                        />
                        <FormCheck
                            handleChange={handleCommentCheck}
                            type='radio'
                            name='latestComment'
                            Id='latestCommentId'
                            value='neusterKommentar'
                            label='Neuster Kommentar'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showComment === 'neusterKommentar' ? true : false}
                        />
                    </Form.Group>
                </Col>
                <Divider mt='10px' mb='8px' />
                <Col md={12}>
                    <Form.Group controlId='Anrede' className="mb-1">
                        <FormCheck
                            handleChange={handleByReadCheck}
                            type='radio'
                            name='byRead'
                            Id='byRead1'
                            value='nichtGelesenVon'
                            label='Nicht gelesen von'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showbyRead === 'nichtGelesenVon' ? true : false}
                        />
                        <FormCheck
                            handleChange={handleByReadCheck}
                            type='radio'
                            name='byRead'
                            Id='byRead2'
                            value='gelesenVon'
                            label='Gelesen von'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showbyRead === 'gelesenVon' ? true : false}
                        />
                        <FormCheck
                            handleChange={handleByReadCheck}
                            type='radio'
                            name='byRead'
                            Id='byRead3'
                            value='beides'
                            label='Beides'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showbyRead === 'beides' ? true : false}
                        />
                    </Form.Group>
                </Col>
                <Divider mt='10px' mb='8px' />
            </Row>

            {notRead ?
                <BodyText fontSize='18px' fontWeight='700'>Nicht gelesen von</BodyText>
                : null}

            <Row>
                <Col md={6}>
                    <>
                        {byRead ?
                            <BodyText fontSize='18px' fontWeight='700'> Gelesen von</BodyText>
                            : null}

                        {byRead && commentRole.creation === true ?
                            <DisplayBetween>
                                <DisplayStart className='badge-box p-2'>
                                    <Box>
                                        <img width='50' src='/images/account.png' />
                                    </Box>
                                    <Box ml="8px">
                                        <CustomTooltip
                                            ToolTipTrigger={<BodyText textColor='#bbb' fontSize='16' fontWeight='500'> Dr. Sven Markus Gallenbach </BodyText>}
                                            toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                            toolTipPlacement='top'
                                        />
                                        <BodyText textColor='#bbb' fontSize='14'> Gelesen 09.06.2023 11:51 </BodyText>
                                    </Box>
                                </DisplayStart>
                                <DisplayStart className='badge-box p-2'>
                                    <Box>
                                        <img width='50' src='/images/assets/opti-image.jpg' />
                                    </Box>
                                    <Box ml="8px">
                                        <CustomTooltip
                                            ToolTipTrigger={<BodyText textColor='#bbb' fontSize='16' fontWeight='500'> OPTI Updates</BodyText>}
                                            toolTipContent='OPTI QMH Updates'
                                            toolTipPlacement='top'
                                        />
                                        <BodyText textColor='#bbb' fontSize='14'> Gelesen 21.02.2023 14:41 </BodyText>
                                    </Box>
                                </DisplayStart>
                            </DisplayBetween>
                            : null}
                    </>
                </Col>
            </Row >

            {
                bothRead ?
                    <>
                        < Row >
                            <Col md={6}>
                                <BodyText fontSize='18px' fontWeight='700'>Nicht gelesen von</BodyText>
                                <DisplayBetween>
                                    <DisplayStart className='badge-box p-2'>
                                        <Box>
                                            <img width='50' src='/images/account.png' />
                                        </Box>
                                        <Box ml="8px">
                                            <CustomTooltip
                                                ToolTipTrigger={<BodyText textColor='#bbb' fontSize='16' fontWeight='500'> Dr. Sven Markus Gallenbach </BodyText>}
                                                toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                                toolTipPlacement='top'
                                            />
                                            <BodyText textColor='#bbb' fontSize='14'> Gelesen 09.06.2023 11:51 </BodyText>
                                        </Box>
                                    </DisplayStart>
                                    <DisplayStart className='badge-box p-2'>
                                        <Box>
                                            <img width='50' src='/images/assets/opti-image.jpg' />
                                        </Box>
                                        <Box ml="8px">

                                            <CustomTooltip
                                                ToolTipTrigger={<BodyText textColor='#bbb' fontSize='16' fontWeight='500'> OPTI Updates</BodyText>}
                                                toolTipContent='OPTI QMH Updates'
                                                toolTipPlacement='top'
                                            />
                                            <BodyText textColor='#bbb' fontSize='14'> Gelesen 21.02.2023 14:41 </BodyText>
                                        </Box>
                                    </DisplayStart>
                                </DisplayBetween>
                                <DisplayBetween>
                                    <DisplayStart className='badge-box p-2'>
                                        <Box>
                                            <img width='50' src='/images/account.png' />
                                        </Box>
                                        <Box ml="8px">
                                            <CustomTooltip
                                                ToolTipTrigger={<BodyText textColor='#bbb' fontSize='16' fontWeight='500'> Dr. Sven Markus Gallenbach </BodyText>}
                                                toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                                toolTipPlacement='top'
                                            />
                                            <BodyText textColor='#bbb' fontSize='14'> Gelesen 09.06.2023 11:51 </BodyText>
                                        </Box>
                                    </DisplayStart>
                                    <DisplayStart className='badge-box p-2'>
                                        <Box>
                                            <img width='50' src='/images/assets/opti-image.jpg' />
                                        </Box>
                                        <Box ml="8px">

                                            <CustomTooltip
                                                ToolTipTrigger={<BodyText textColor='#bbb' fontSize='16' fontWeight='500'> OPTI Updates</BodyText>}
                                                toolTipContent='OPTI QMH Updates'
                                                toolTipPlacement='top'
                                            />
                                            <BodyText textColor='#bbb' fontSize='14'> Gelesen 21.02.2023 14:41 </BodyText>
                                        </Box>
                                    </DisplayStart>
                                </DisplayBetween>
                            </Col>
                            <Col md={6}>
                                <BodyText fontSize='18px' fontWeight='700'> Gelesen von </BodyText>
                                <DisplayBetween>
                                    <DisplayStart className='badge-box p-2'>
                                        <Box>
                                            <img width='50' src='/images/account.png' />
                                        </Box>
                                        <Box ml="8px">
                                            <CustomTooltip
                                                ToolTipTrigger={<BodyText textColor='#bbb' fontSize='16' fontWeight='500'> Dr. Sven Markus Gallenbach </BodyText>}
                                                toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                                toolTipPlacement='top'
                                            />
                                            <BodyText textColor='#bbb' fontSize='14'> Gelesen 09.06.2023 11:51 </BodyText>
                                        </Box>
                                    </DisplayStart>
                                    <DisplayStart className='badge-box p-2'>
                                        <Box>
                                            <img width='50' src='/images/assets/opti-image.jpg' />
                                        </Box>
                                        <Box ml="8px">

                                            <CustomTooltip
                                                ToolTipTrigger={<BodyText textColor='#bbb' fontSize='16' fontWeight='500'> OPTI Updates</BodyText>}
                                                toolTipContent='OPTI QMH Updates'
                                                toolTipPlacement='top'
                                            />
                                            <BodyText textColor='#bbb' fontSize='14'> Gelesen 21.02.2023 14:41 </BodyText>
                                        </Box>
                                    </DisplayStart>
                                </DisplayBetween>
                            </Col>
                        </Row >
                    </>
                    : null}
        </>
    );
}

