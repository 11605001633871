import React, { useState, useEffect } from 'react';
import { Row, Col, Button, } from 'react-bootstrap';
import { BodyText, DisplayBetween, Divider, HeadingWithDivider } from '../../StyledComponents';
import Form from 'react-bootstrap/Form';
import FormCheck from '../../FormCheck';
import DatePicker from '../../DatePicker';
import FormSelect from '../../FormSelect';
import DoughnutChart from '../../Charts/DoughnutChart';
import { CustomCard } from '../../CustomCard';
import { AlertWidget } from '../../AlertWidget';
import { LetzteTageOptions } from '../../../CommonJSON';


export const ViewReportsModalContent = () => {

    const [activeMode, setActiveMode] = useState<boolean>(false);

    const handleActivity = () => {
        setActiveMode(!activeMode);
    }



    return (
        <>
            <HeadingWithDivider mb='20px' ms='10px'> Übersicht </HeadingWithDivider>
            <Row>
                <Col md={4}>
                    <div>
                        <FormSelect
                            isFormGroup={true}
                            selectOptions={LetzteTageOptions}
                            Size='sm'
                            Name='olderRepair'
                            Id='olderRepair'
                        />
                    </div>
                </Col>
                <Col md={4}>
                    <DisplayBetween>
                        <Form.Group className='mb-2 w-100'>
                            <DatePicker />
                        </Form.Group>
                        <div className='ms-3'>
                            <span> - </span>
                        </div>
                    </DisplayBetween>
                </Col>
                <Col md={4}>
                    <div>
                        <Form.Group className='mb-2'>
                            <DatePicker />
                        </Form.Group>
                    </div>
                </Col>
            </Row>
            {activeMode ?
                <>
                    <Row>
                        <Col md={4}>
                            <div>
                                <FormSelect
                                    isFormGroup={true}
                                    selectOptions={LetzteTageOptions}
                                    Size='sm'
                                    Name='lastDays'
                                    Id='lastDays'
                                />
                            </div>
                        </Col>
                        <Col md={4}>
                            <DisplayBetween>
                                <Form.Group className='mb-2 w-100'>
                                    <DatePicker />
                                </Form.Group>
                                <div className='ms-3'>
                                    <span> - </span>
                                </div>
                            </DisplayBetween>
                        </Col>
                        <Col md={4}>
                            <div>
                                <Form.Group className='mb-2'>
                                    <DatePicker />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                </>
                : null}
            <Row>
                <Col md={12}>
                    <div className='pb-3'>
                        <FormCheck
                            type='checkbox'
                            name='phone'
                            Id='phone'
                            value='Include older repairs completed during the period'
                            label='Include older repairs completed during the period'
                            isFormGroup={false}
                            isInline={false}
                        />
                        <Button className='default-btn f-12px my-2' onClick={() => handleActivity()}>
                            {activeMode ?
                                <>
                                    <span> Vergleichsmodus deaktivieren </span>
                                </>
                                :
                                <>
                                    <span> Vergleichsmodus aktivieren </span>
                                </>
                            }
                        </Button>
                    </div>
                </Col>
            </Row>
            <Divider mb='16px' />
            {activeMode ?
                <>
                    <Row>
                        <Col md={5} className='py-3'>
                            <CustomCard isCardHeader={true} title="Erstellte/Erledigte Reparaturen" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="300px"
                                cardBodyContent={
                                    <>
                                        <Row>
                                            <Col md={6}>
                                                <BodyText fontSize='15px' fontWeight='600' pb={'6px'}> 06.06.2023 - 06.06.2023 </BodyText>
                                                <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                            </Col>
                                            <Col md={6}>
                                                <BodyText fontSize='15px' fontWeight='600' pb={'6px'}> 07.04.2023 - 07.05.2023 </BodyText>
                                                <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                            </Col>
                                        </Row>
                                    </>
                                } />
                        </Col>
                        <Col md={7} className='py-3'>
                            <CustomCard isCardHeader={true} title="Erledigung Reparaturen nach Priorität" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="300px"
                                cardBodyContent={
                                    <>
                                        <Row>
                                            <Col md={6}>
                                                <BodyText fontSize='15px' fontWeight='600' pb={'6px'}> 06.06.2023 - 06.06.2023 </BodyText>
                                                <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                            </Col>
                                            <Col md={6}>
                                                <BodyText fontSize='15px' fontWeight='600' pb={'6px'}> 07.04.2023 - 07.05.2023 </BodyText>
                                                <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                            </Col>
                                        </Row>
                                    </>
                                } />
                        </Col>
                        <Col md={12} className='py-3'>
                            <CustomCard isCardHeader={true} title="Erstellte und erledigte Reparaturen nach Stunde des Tages" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="300px"
                                cardBodyContent={
                                    <>
                                        <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                    </>
                                } />
                        </Col>
                        <Col md={6} className='py-3'>
                            <CustomCard isCardHeader={true} title="Top 5 Reparatur Typen" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="300px"
                                cardBodyContent={
                                    <>
                                        <Row>
                                            <Col md={6}>
                                                <BodyText fontSize='15px' fontWeight='600' pb={'6px'}> 06.06.2023 - 06.06.2023 </BodyText>
                                                <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                            </Col>
                                            <Col md={6}>
                                                <BodyText fontSize='15px' fontWeight='600' pb={'6px'}> 07.04.2023 - 07.05.2023 </BodyText>
                                                <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                            </Col>
                                        </Row>
                                    </>
                                } />
                        </Col>
                        <Col md={6} className='py-3'>
                            <CustomCard isCardHeader={true} title="Top 5 Reparatur Orte" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="300px"
                                cardBodyContent={
                                    <>
                                        <Row>
                                            <Col md={6}>
                                                <BodyText fontSize='15px' fontWeight='600' pb={'6px'}> 06.06.2023 - 06.06.2023</BodyText>
                                                <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                            </Col>
                                            <Col md={6}>
                                                <BodyText fontSize='15px' fontWeight='600' pb={'6px'}> 07.04.2023 - 07.05.2023</BodyText>
                                                <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                            </Col>
                                        </Row>
                                    </>
                                } />
                        </Col>
                    </Row>
                </>
                :
                <>
                    <Row>
                        <Col md={4} className='py-3'>
                            <CustomCard isCardHeader={true} title="Erstellt/Erledigt/Verworfen Reparaturen" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="300px"
                                cardBodyContent={
                                    <>
                                        <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                    </>
                                } />
                        </Col>
                        <Col md={8} className='py-3'>
                            <CustomCard isCardHeader={true} title="Erstellte/Erledigte Reparaturen im Zeitverlauf" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="300px"
                                cardBodyContent={
                                    <>
                                        <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                    </>
                                } />
                        </Col>
                        <Col md={12} className='py-3'>
                            <CustomCard isCardHeader={true} title="Anzahl der Reparaturen nach Ort" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="300px"
                                cardBodyContent={
                                    <>
                                        <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                    </>
                                } />
                        </Col>
                        <Col md={12} className='py-3'>
                            <CustomCard isCardHeader={true} title="Anzahl der Reparaturen nach Typ" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="300px"
                                cardBodyContent={
                                    <>
                                        <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                    </>
                                } />
                        </Col>
                        <Col md={6} className='py-3'>
                            <CustomCard isCardHeader={true} title="Erledigung Reparaturen nach Priorität" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="300px"
                                cardBodyContent={
                                    <>
                                        <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                    </>
                                } />
                        </Col>
                        <Col md={6} className='py-3'>
                            <CustomCard isCardHeader={true} title="Erledigung von Reparaturen in % nach Zeit" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="300px"
                                cardBodyContent={
                                    <>
                                        <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                    </>
                                } />
                        </Col>
                        <Col md={7} className='py-3'>
                            <CustomCard isCardHeader={true} title="Erstellte und erledigte Reparaturen nach Stunde des Tages" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="500px"
                                cardBodyContent={
                                    <>
                                        <AlertWidget variant='danger' text='Für den gegebenen Zeitraum sind keine Daten vorhanden.' />
                                    </>
                                } />
                        </Col>
                        <Col md={5} className='py-3'>
                            <CustomCard isCardHeader={true} title="Reparaturen nach Status" cardHeaderBg="#eee" cardBorder="undefined"
                                cardBodyHeight="500px"
                                cardBodyContent={
                                    <>
                                        <div className='Chart-Div'>
                                            <DoughnutChart chartData={[5, 0]} chartLabel={["Nicht angenommen", "Erledigt"]} chartRadius={70} />
                                        </div>
                                    </>
                                } />
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}