import React, { useState, useEffect, FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import ButtonWidget from '../../../Components/ButtonWidget';
import CommonModal from '../../../Components/Modals/CommonModal';
import { UserAdminModal } from '../AdminModal/UserAdminModal';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { TabBox, TabItem } from '../../../Components/StyledComponents';
import { Outlet } from 'react-router-dom';


interface IProps { }

const SecurityIndex: FC<IProps> = ({ }) => {
    return (
        <>
            <Card className='boxShadow'>
                <Card.Body className='p-0'>
                    <TabBox>
                        <TabItem to='mobilepin' style={{ borderTopLeftRadius: '0.375rem' }}> Mobile PIN  </TabItem>
                        <TabItem to='passwords' style={{ borderTopRightRadius: '0.375rem' }}> Passworter  </TabItem>
                    </TabBox>
                    <div className='py-4 px-3'>
                        <Outlet />
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default SecurityIndex;