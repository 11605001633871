import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, ButtonGroup, SplitButton, } from 'react-bootstrap';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import SelectTemplateModal from '../../Components/Modals/SelectTemplateModal';
import AddTemplateModal from '../../Components/Modals/AddTemplateModal';
import Recipient from '../../Components/Modals/Recipient';
import AbortAlrt from '../../Components/Modals/AbortAlrt';
import EditorConvertToMarkdown from '../../Components/TextEditor';
import DatePicker from '../../Components/DatePicker';
import TreeViewModal from '../../Components/Modals/TreeViewModal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import AddMediaModal from '../../Components/Modals/AddMediaModal';
import ModelActions from '../../Components/ModelActions';
import Tagify from '../../Components/Tagify';
import { HiTrendingUp } from 'react-icons/hi';
import TimePicker from '../../Components/TimePicker';
import { Box, DisplayStart, DropdownItemColor, ModalHeader } from '../../Components/StyledComponents';
import ChooseTemplateCategoryModal from '../../Components/Modals/ChooseTemplateCategoryModal';
import ManageTemplateCategoryModal from '../../Components/Modals/ManageTemplateCategoryModal';
import BadgeWidget from '../../Components/BadgeWidget';

interface appointmentProps {
    showAppointment: boolean,
    hideAppointment: () => void,
}

export type DDItemColorSelectType = {
    key: string,
    value: string,
    color: string
}

export type DDItemCalenderSelectType = {
    key: string,
    badgeTitle: string | any,
    value: string,
    colorBox: string | any,
}

function AddEditAppointment(props: appointmentProps) {
    // State for modals Begin
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    const [AlertModal, setAlertModal] = useState<boolean>(false);
    const [selectTemplateModalShow, setSelectTemplateModalShow] = useState<boolean>(false);
    const [AddTemplateModalShow, setAddTemplateModalShow] = useState<boolean>(false);
    const [TreeModalShow, setTreeModalShow] = useState<boolean>(false);
    const [AddMediaModalShow, setAddMediaModalShow] = useState<boolean>(false);
    const [chooseTempCategoryModal, setChooseTempCategoryModal] = useState<boolean>(false);
    const [manageTempCategoryModal, setManageTempCategoryModal] = useState<boolean>(false);
    // State for modals End

    // State for Hide Show Begin
    const [MoreOpetion, setMoreOpetion] = useState<boolean>(false);
    const [RepeateAppointment, setRepeateAppointment] = useState<boolean>(false);
    const [RepeateWeekly, setRepeateWeekly] = useState<boolean>(true);
    const [RepeatMonthly, setRepeatMonthly] = useState<boolean>(false);
    const [AllDays, setAllDays] = useState(true);
    const [TextEditorShow, setTextEditorShow] = useState<boolean>(false);
    const [HideFont, setHideFont] = useState<boolean>(false);
    const [TagifyShow, setTagifyShow] = useState<boolean>(false);
    // State for Hide Show Begin

    const repeatInterval = (value: string) => {
        switch (value) {
            case "daily":
                setRepeateWeekly(false);
                setRepeatMonthly(false);
                break;
            case "weekly":
                setRepeateWeekly(true);
                setRepeatMonthly(false);
                break;

            case "monthly":
                setRepeateWeekly(false);
                setRepeatMonthly(true);
                break;
            case "annual":
                setRepeateWeekly(false);
                setRepeatMonthly(false);
                break;
            default:
                setRepeateWeekly(false);
                setRepeatMonthly(false);
        }
    }
    const HandleMoreOpetion = () => {
        setMoreOpetion(!MoreOpetion);
    }
    const HandleFontClick = () => {
        setTextEditorShow(true);
        setHideFont(true);
    }

    const [itemCalenderSelect, setItemCalenderSelect] = useState<DDItemCalenderSelectType>({ key: '0', badgeTitle: null, value: 'Select calendar', colorBox: null });


    const calenderStatusChange = (eventKey: any) => {

        setItemCalenderSelect(eventKey);

        switch (eventKey) {
            case '1':
                setItemCalenderSelect({ key: eventKey, badgeTitle: 'Praxis Gallenbach & Zähne', value: 'Allgemeine Termine', colorBox: '#78e4eb', })
                break;
            case '2':
                setItemCalenderSelect({ key: eventKey, badgeTitle: 'Praxis Gallenbach & Zähne', value: 'calendar', colorBox: '#0d3f5f', })
                break;
            case '3':
                setItemCalenderSelect({ key: eventKey, badgeTitle: 'Praxis Gallenbach & Zähne', value: 'calendar', colorBox: '#0d3f5f', })
                break;
            case '4':
                setItemCalenderSelect({ key: eventKey, badgeTitle: 'Praxis Gallenbach & Zähne', value: 'Geourtstage I Jubiläen', colorBox: '#0d3f5f', })
                break;
            case '5':
                setItemCalenderSelect({ key: eventKey, badgeTitle: 'Praxis Gallenbach & Zähne', value: 'personal calendar', colorBox: '#0d3f5f', })
                break;
            case '6':
                setItemCalenderSelect({ key: eventKey, badgeTitle: 'Praxis Gallenbach & Zähne', value: 'test sadf', colorBox: '#ccebff', })
                break;
            case '7':
                setItemCalenderSelect({ key: eventKey, badgeTitle: 'Praxis Gallenbach & Zähne', value: 'I-Jrlaube', colorBox: '#69b0ba', })
                break;
            default:
                setItemCalenderSelect({ key: '0', badgeTitle: '', value: 'Select calendar', colorBox: '', })
        }
    }


    return (
        <>

            <Modal size="lg" show={props.showAppointment} onHide={props.hideAppointment} backdrop="static" keyboard={false} aria-labelledby="example-modal-sizes-title-lg" >
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Termin erstellen
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body className='pb-0'>
                    <Row>
                        <Col md={9}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Titel" />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Button className='default-btn f-12px w-100' onClick={() => setSelectTemplateModalShow(true)}>Vorlage auswählen</Button>
                        </Col>
                    </Row>
                    <div className='border-top border-bottom py-2'>
                        <Form.Group controlId="">
                            <Form.Check
                                inline
                                label="Öffentlich"
                                name="group1"
                                type="radio"
                                id="public"
                            />
                            <Form.Check
                                inline
                                label="Nicht öffentlich"
                                name="group1"
                                type="radio"
                                id="notpublic"
                            />
                        </Form.Group>
                    </div>
                    <Row className='mt-3'>
                        <Col md={2} className='mb-3'>
                            <Form.Label>Kalender</Form.Label>
                        </Col>
                        <Col md={6} className='mb-3'>
                            <div className='calender-dropdown'>

                                <DropdownButton
                                    id={`dropdown-button-drop-custom-calender`}
                                    size="sm"
                                    variant="light"
                                    title={
                                        <>
                                            <DisplayStart>
                                                {itemCalenderSelect.key === '0' ?
                                                    <span className='fs-14 fw-400'>Select calendar</span>
                                                    :
                                                    <>
                                                        <Box mr="10px">
                                                            <BadgeWidget outiline={true} title={itemCalenderSelect.badgeTitle} color='#0d3f5f' bgColor='#fff' />
                                                        </Box>
                                                        <Box>
                                                            <DropdownItemColor itemColor={itemCalenderSelect.colorBox}> {itemCalenderSelect.value} </DropdownItemColor>
                                                        </Box>
                                                    </>
                                                }

                                            </DisplayStart>
                                        </>
                                    }
                                    onSelect={(eventKey: any) => calenderStatusChange(eventKey)}
                                >
                                    <Dropdown.Item eventKey={0} active={itemCalenderSelect.key === '0' ? true : false}>
                                        Select calendar
                                    </Dropdown.Item>
                                    <Dropdown.Item className='calender-item' eventKey={1} active={itemCalenderSelect.key === '1' ? true : false}>
                                        <DisplayStart>
                                            <Box mr="10px">
                                                <BadgeWidget outiline={true} title={itemCalenderSelect.badgeTitle} color='#0d3f5f' bgColor='#fff' />
                                            </Box>
                                            <Box>
                                                <DropdownItemColor itemColor="#78e4eb"> Allgemeine Termine </DropdownItemColor>
                                            </Box>
                                        </DisplayStart>
                                    </Dropdown.Item>
                                    <Dropdown.Item className='calender-item' eventKey={2} active={itemCalenderSelect.key === '2' ? true : false}>
                                        <DisplayStart>
                                            <Box mr="10px">
                                                <BadgeWidget outiline={true} title={itemCalenderSelect.badgeTitle} color='#0d3f5f' bgColor='#fff' />
                                            </Box>
                                            <Box>
                                                <DropdownItemColor itemColor="#0d3f5f"> calendar </DropdownItemColor>
                                            </Box>
                                        </DisplayStart>
                                    </Dropdown.Item>
                                    <Dropdown.Item className='calender-item' eventKey={3} active={itemCalenderSelect.key === '3' ? true : false}>
                                        <DisplayStart>
                                            <Box mr="10px">
                                                <BadgeWidget outiline={true} title={itemCalenderSelect.badgeTitle} color='#0d3f5f' bgColor='#fff' />
                                            </Box>
                                            <Box>
                                                <DropdownItemColor itemColor="#0d3f5f"> calendar </DropdownItemColor>
                                            </Box>
                                        </DisplayStart>
                                    </Dropdown.Item>
                                    <Dropdown.Item className='calender-item' eventKey={4} active={itemCalenderSelect.key === '4' ? true : false}>
                                        <DisplayStart>
                                            <Box mr="10px">
                                                <BadgeWidget outiline={true} title={itemCalenderSelect.badgeTitle} color='#0d3f5f' bgColor='#fff' />
                                            </Box>
                                            <Box>
                                                <DropdownItemColor itemColor="#0d3f5f"> Geourtstage I Jubiläen </DropdownItemColor>
                                            </Box>
                                        </DisplayStart>
                                    </Dropdown.Item>
                                    <Dropdown.Item className='calender-item' eventKey={5} active={itemCalenderSelect.key === '5' ? true : false}>
                                        <DisplayStart>
                                            <Box mr="10px">
                                                <BadgeWidget outiline={true} title={itemCalenderSelect.badgeTitle} color='#0d3f5f' bgColor='#fff' />
                                            </Box>
                                            <Box>
                                                <DropdownItemColor itemColor="#0d3f5f"> personal calendar </DropdownItemColor>
                                            </Box>
                                        </DisplayStart>
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey={6} active={itemCalenderSelect.key === '6' ? true : false}>
                                        <DisplayStart>
                                            <Box mr="10px">
                                                <BadgeWidget outiline={true} title={itemCalenderSelect.badgeTitle} color='#0d3f5f' bgColor='#fff' />
                                            </Box>
                                            <Box>
                                                <DropdownItemColor itemColor="#ccebff"> test sadf </DropdownItemColor>
                                            </Box>
                                        </DisplayStart>
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey={7} active={itemCalenderSelect.key === '7' ? true : false}>
                                        <DisplayStart>
                                            <Box mr="10px">
                                                <BadgeWidget outiline={true} title={itemCalenderSelect.badgeTitle} color='#0d3f5f' bgColor='#fff' />
                                            </Box>
                                            <Box>
                                                <DropdownItemColor itemColor="#69b0ba"> I-Jrlaube </DropdownItemColor>
                                            </Box>
                                        </DisplayStart>
                                    </Dropdown.Item>

                                </DropdownButton>

                            </div>
                        </Col>
                        <Col md={9}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Teilnehmer" />
                            </Form.Group>
                        </Col>
                        <Col md={3} className='text-end'>
                            <div className='d-flex align-items-center mb-3'>
                                <Button className='default-btn f-12px w-lg-100' onClick={() => setRecipientModal(true)}><FaPlusCircle className='default-btn-icon me-1' />Alle anzeigen</Button>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='mb-3'>
                                <div className='d-flex'>
                                    <p className='my-0 me-3'>von</p>
                                    <div>
                                        <div className='d-flex'>
                                            <div className={`${AllDays ? "w-120px" : "w-177px"}`}>
                                                <DatePicker />
                                            </div>
                                            {AllDays ?
                                                <div className='ms-1 w-100px'>
                                                    <TimePicker />
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <p className='my-0 ms-3'>bis</p>
                                </div>
                                <p className='m-0'>Pakistan Standard Time</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='mb-3'>
                                <div className='d-flex align-items-center'>
                                    <div className={`${AllDays ? "w-120px" : "w-177px"}`} >
                                        <DatePicker />
                                    </div>
                                    {AllDays ?
                                        <div className='ms-1 w-100px'>
                                            <TimePicker />
                                        </div>
                                        : null
                                    }
                                    <div className='ms-2'>
                                        <Form.Check
                                            label="ganztägig"
                                            name=""
                                            type="checkbox"
                                            id=""
                                            className='mb-0'
                                            onChange={() => setAllDays(!AllDays)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='border-top border-bottom pt-3 mb-3'>
                        <Form.Group controlId="">
                            <Form.Check
                                inline
                                label="Wiederhole diesen Termin"
                                name=""
                                type="checkbox"
                                id=""
                                className='mb-3'
                                onChange={() => setRepeateAppointment(!RepeateAppointment)}
                            />
                        </Form.Group>
                        {RepeateAppointment ?
                            <Row>
                                <Col md={4}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Intervall wiederholen</Form.Label>
                                        <Form.Select size="sm" onChange={(e) => repeatInterval(e.target.value)}>
                                            <option value="daily">Täglich</option>
                                            <option selected value="weekly">Wöchentlich</option>
                                            <option value="monthly">Monatlich</option>
                                            <option value="annual">Jährlich</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Wiederhole bis</Form.Label>
                                        <DatePicker />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Wiederhole alle</Form.Label>
                                        <div className='d-flex align-items-center'>
                                            <Form.Select size="sm">
                                                {(() => {
                                                    let options = [];
                                                    for (let i = 0; i <= 30; i++) {
                                                        options.push(<option value={i}>{i}</option>);
                                                    }
                                                    return options;
                                                })()}
                                            </Form.Select>
                                            <span className='ms-2'>Wochen</span>
                                        </div>
                                    </Form.Group>
                                </Col>
                                {RepeateWeekly ?
                                    <Col md={12}>
                                        <Form.Label>wiederholen am</Form.Label>
                                        <Form.Group>
                                            <Form.Check
                                                inline
                                                label="Sonntag"
                                                name="Sunday"
                                                type="checkbox"
                                                id="Sunday"
                                                className='mb-3'
                                            />
                                            <Form.Check
                                                inline
                                                label="Montag"
                                                name="Monday"
                                                type="checkbox"
                                                id="Monday"
                                                className='mb-3'
                                            />
                                            <Form.Check
                                                inline
                                                label="Dienstag"
                                                name="Tuesday"
                                                type="checkbox"
                                                id="Tuesday"
                                                className='mb-3'
                                            />
                                            <Form.Check
                                                inline
                                                label="Mittwoch"
                                                name="Wednesday"
                                                type="checkbox"
                                                id="Wednesday"
                                                className='mb-3'
                                            />
                                            <Form.Check
                                                inline
                                                label="Donnerstag"
                                                name="Thursday"
                                                type="checkbox"
                                                id="Thursday"
                                                className='mb-3'
                                            />
                                            <Form.Check
                                                inline
                                                label="Freitag"
                                                name="Friday"
                                                type="checkbox"
                                                id="Friday"
                                                className='mb-3'
                                            />
                                            <Form.Check
                                                inline
                                                label="Samstag"
                                                name="Saturday"
                                                type="checkbox"
                                                id="Saturday"
                                                className='mb-3'
                                            />
                                        </Form.Group>
                                    </Col>
                                    : null
                                }
                                {RepeatMonthly ?
                                    <Col md={12}>
                                        <Form.Label>wiederholen am</Form.Label>
                                        <div>
                                            <Form.Check
                                                inline
                                                label="26. Tag des Monats"
                                                name="dayOfMonth"
                                                type="radio"
                                                id=""
                                                className='mb-3'
                                            />
                                            <Form.Check
                                                inline
                                                label="4. Samstag des Monats"
                                                name="dayOfMonth"
                                                type="radio"
                                                id=""
                                                className='mb-3'
                                            />
                                        </div>
                                    </Col>
                                    : null
                                }
                            </Row>
                            :
                            null
                        }
                    </div>
                    <div>
                        {TextEditorShow ?
                            <div className="mb-3">
                                <EditorConvertToMarkdown />
                            </div>
                            :
                            <div className="mb-3">
                                <Form.Control as="textarea" placeholder="Text" rows={5} />
                            </div>
                        }
                    </div>
                    <div>
                        <ModelActions
                            ImgClick={() => setAddMediaModalShow(true)}
                            FontClick={() => HandleFontClick()}
                            isHideFont={HideFont}
                            TagifyClick={() => setTagifyShow(!TagifyShow)}
                        />
                        {TagifyShow ?
                            <div className='my-3 tagify'>
                                <Tagify />
                            </div>
                            : null
                        }
                    </div>
                    <Row>
                        <Col md={9}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Ort" />
                            </Form.Group>
                        </Col>
                        <Col md={3} className='text-end'>
                            <div className='d-flex align-items-center mb-3'>
                                <Button className='default-btn f-12px w-lg-100' onClick={() => setTreeModalShow(true)}><FaPlusCircle className='default-btn-icon me-1' />Alle Orte anzeigen</Button>
                            </div>
                        </Col>
                    </Row>
                    {MoreOpetion ?
                        <div>
                            <div className='border-top border-bottom pt-3 pb-2 mb-3'>
                                <Row>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Form.Label>Erinnere mich</Form.Label>
                                            <Form.Select size='sm'>
                                                <option value="no">Nie</option>
                                                <option value="0" selected>Zu Beginn</option>
                                                <option value="5">5 Minuten vor Beginn</option>
                                                <option value="15">15 Minuten vor Beginn</option>
                                                <option value="30">30 Minuten vor Beginn</option>
                                                <option value="60">1 Stunde vor Beginn</option>
                                                <option value="120">2 Stunden vor Beginn</option>
                                                <option value="180">3 Stunden vor Beginn</option>
                                                <option value="360">6 Stunden vor Beginn</option>
                                                <option value="480">8 Stunden vor Beginn</option>
                                                <option value="600">10 Stunden vor Beginn</option>
                                                <option value="1440">1 Tag vor Beginn</option>
                                                <option value="2880">2 Tage vor Beginn</option>
                                                <option value="4320">3 Tage vor Beginn</option>
                                                <option value="7200">5 Tage vor Beginn</option>
                                                <option value="10080">7 Tage vor Beginn</option>
                                                <option value="14400">10 Tage vor Beginn</option>
                                                <option value="20160">14 Tage vor Beginn</option>
                                                <option value="43200">30 Tage vor Beginn</option>
                                                <option value="64800">45 Tage vor Beginn</option>
                                                <option value="86400">60 Tage vor Beginn</option>
                                                <option value="129600">90 Tage vor Beginn</option>
                                                <option value="172800">120 Tage vor Beginn</option>
                                                <option value="259200">180 Tage vor Beginn</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Form.Label>Erinnere Abonnenten</Form.Label>
                                            <Form.Select size='sm'>
                                                <option value="no" selected>Nie</option>
                                                <option value="0">Zu Beginn</option>
                                                <option value="5">5 Minuten vor Beginn</option>
                                                <option value="15">15 Minuten vor Beginn</option>
                                                <option value="30">30 Minuten vor Beginn</option>
                                                <option value="60">1 Stunde vor Beginn</option>
                                                <option value="120">2 Stunden vor Beginn</option>
                                                <option value="180">3 Stunden vor Beginn</option>
                                                <option value="360">6 Stunden vor Beginn</option>
                                                <option value="480">8 Stunden vor Beginn</option>
                                                <option value="600">10 Stunden vor Beginn</option>
                                                <option value="1440">1 Tag vor Beginn</option>
                                                <option value="2880">2 Tage vor Beginn</option>
                                                <option value="4320">3 Tage vor Beginn</option>
                                                <option value="7200">5 Tage vor Beginn</option>
                                                <option value="10080">7 Tage vor Beginn</option>
                                                <option value="14400">10 Tage vor Beginn</option>
                                                <option value="20160">14 Tage vor Beginn</option>
                                                <option value="43200">30 Tage vor Beginn</option>
                                                <option value="64800">45 Tage vor Beginn</option>
                                                <option value="86400">60 Tage vor Beginn</option>
                                                <option value="129600">90 Tage vor Beginn</option>
                                                <option value="172800">120 Tage vor Beginn</option>
                                                <option value="259200">180 Tage vor Beginn</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col md={12}>
                                    <h6>Benachrichtigungen</h6>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-3'>
                                        <Form.Label>Über Erstellung</Form.Label>
                                        <div>
                                            <Form.Check
                                                inline
                                                label='Ersteller'
                                                type='checkbox'
                                                name=''
                                                id=''
                                            />
                                            <Form.Check
                                                inline
                                                label='Abonnenten'
                                                type='checkbox'
                                                name=''
                                                id=''
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-3'>
                                        <Form.Label>Über Kommentare</Form.Label>
                                        <div>
                                            <Form.Check
                                                inline
                                                label='Ersteller'
                                                type='checkbox'
                                                name=''
                                                id=''
                                            />
                                            <Form.Check
                                                inline
                                                label='Abonnenten'
                                                type='checkbox'
                                                name=''
                                                id=''
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        : null
                    }
                </Modal.Body>
                <Modal.Footer className='justify-content-between footer-sticky'>
                    <div>
                        <Button className='default-btn f-14px' onClick={HandleMoreOpetion}>
                            {MoreOpetion ? <FaMinusCircle className='default-btn-icon' /> : <FaPlusCircle className='default-btn-icon' />}
                            <span>{MoreOpetion ? "Optionen ausblenden" : "Zeige weitere Optionen"}</span>
                        </Button>
                    </div>
                    <div>
                        <Button className='default-btn f-14px me-1'>
                            Entwurf speichern
                        </Button>
                        <Button className='default-btn f-14px me-1' onClick={() => setAddTemplateModalShow(true)}>
                            Als Vorlage hinzufügen
                        </Button>
                        <Button className='default-btn f-14px me-1' onClick={() => setAlertModal(true)}>
                            Abbrechen
                        </Button>
                        <Button className='primary-bg f-14px'>
                            Erstellen
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <SelectTemplateModal
                displayTemplateModal={selectTemplateModalShow}
                hideTemplateModal={() => setSelectTemplateModalShow(false)}
            />
            <AddTemplateModal
                displayAddTemplateModal={AddTemplateModalShow}
                hideAddTemplateModal={() => setAddTemplateModalShow(false)}
                isAppointment={true}
                chooseCategoryClickHandler={() => setChooseTempCategoryModal(true)}
                recipientClickHandler={() => setRecipientModal(true)}
            />

            {/* ====== Inner Modal of Add Template modal Begin ====== */}
            <ChooseTemplateCategoryModal
                displayCategoryModal={chooseTempCategoryModal}
                hideCategoryModal={() => setChooseTempCategoryModal(false)}
                manageCategoriesClickHandler={() => setManageTempCategoryModal(true)}
            />
            <ManageTemplateCategoryModal
                displayCategoriesTemplateModal={manageTempCategoryModal}
                modalTitle="Vorlagenkategorien verwalten"
                hideCategoriesTemplateModal={() => setManageTempCategoryModal(false)}
            />
            {/* ====== Inner Modal of Add Template modal Begin ====== */}

            <Recipient displayRecipientModal={RecipientModal} hideRecipientModal={() => setRecipientModal(false)} />
            <AbortAlrt displayAlertModal={AlertModal} hideAlertModal={() => setAlertModal(false)} />
            <TreeViewModal displayTreeModal={TreeModalShow} hideTreeModal={() => setTreeModalShow(false)} />
            <AddMediaModal displayAddMediaModal={AddMediaModalShow} hideAddMediaModal={() => setAddMediaModalShow(false)} />
        </>
    );
}

export default AddEditAppointment;