import React, { useEffect, useState, FC, Dispatch, SetStateAction, MouseEvent, } from 'react';
import { DisplayEnd } from '../../Components/StyledComponents';
import { useOutletContext } from "react-router-dom";
import CommonModal from '../../Components/Modals/CommonModal';
import { SendReminderModalContent } from '../../Components/Modals/ModelContents/SendReminderModalContent';
import ButtonWidget from '../../Components/ButtonWidget';
import { MainContent } from './MainContent';
import { Card, Table } from 'react-bootstrap';
import FormCheck from '../../Components/FormCheck';
import { FaRegFolder } from 'react-icons/fa';
import { BodyText } from '../../Components/StyledComponents';
import { IHandbookProps } from './HandBookIndex';
import { useLocation, useNavigate, } from 'react-router-dom';


export const HandBookList = () => {

    // const [handBookData, setHandBookData, displayMode, isShowChecks, setTotalAccount]: any = useOutletContext();
    const [handBookData, setHandBookData, displayMode, isShowChecksHandBook, setTotalAccountHandBook]: any = useOutletContext();

    const [sendReminder, setSendReminder] = useState<boolean>(false);

    const navigate = useNavigate();

    const HandleCheckbox = (index: number, event: MouseEvent<HTMLInputElement>) => {
        debugger
        let temp = [...handBookData];
        var count: number = 0;
        temp[index].isChecked = !temp[index].isChecked;

        for (var i = 0; i < handBookData.length; i++) {
            if (handBookData[i].isChecked === true) {
                count++;
            }
        }
        setTotalAccountHandBook(count);
        setHandBookData(temp);
    }

    return (
        <>
            <div className='handbook-section'>
                <div className='handbook-maincontent'>
                    <Card className='boxShadow'>
                        <Card.Body>
                            <Table responsive hover className='mb-0'>
                                <tbody>
                                    {handBookData.map((item: any, index: number) => {
                                        return (
                                            <>
                                                <tr key={index} onClick={() => { navigate('/handbooks/handbooks-detial') }} className='cursor-pointer'>
                                                    {isShowChecksHandBook ?
                                                        <td width='30'>
                                                            <FormCheck
                                                                type='checkbox'
                                                                name='phone'
                                                                Id='phone'
                                                                value={item.title}
                                                                label=''
                                                                isFormGroup={false}
                                                                isInline={true}
                                                                checked={item.isChecked}
                                                                handleClick={(event: MouseEvent<HTMLInputElement>) => {
                                                                    HandleCheckbox(index, event);
                                                                    event.stopPropagation();
                                                                }}
                                                            />
                                                        </td>
                                                        : null}
                                                    <td width="40">
                                                        {item.iconMail ?
                                                            <span><FaRegFolder size={18} /></span>
                                                            : null}
                                                    </td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <BodyText mb='0px' fontSize='1.1em' fontWeight='700' textColor='#000'> {item.serialNumber} </BodyText>
                                                            <div className='msg-title'>
                                                                <BodyText mb='0px' fontSize='1.1em' fontWeight='700' textColor='#000' pl='7px'>{item.title}</BodyText>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}

                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            {/* <MainContent
                listData={handBookData}
                setListData={setHandBookData}
                displayMode={displayMode}
                showChecks={isShowChecks}
                setCheckCount={setTotalAccount}
            /> */}

            <CommonModal
                showModal={sendReminder}
                hideModal={() => setSendReminder(false)}
                modalTitle='Erinnerung senden'
                modalContent={<SendReminderModalContent />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Schließen'}
                                class='me-2 ms-3'
                                handleClick={() => setSendReminder(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Ausgewählte erinnem'}
                                disabled={true}
                            />
                        </DisplayEnd>
                    </>
                }
                footerClass={"footer-sticky"}
            />
        </>
    )
}