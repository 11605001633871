import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FaRegCheckCircle } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
function EmailConfirmation() {
    return (
        <>
            <Card className="shadow border-0">
                <Card.Body className='py-5'>
                    <div className='w-75 mx-auto'>
                        <div className='text-center'>
                            <FaRegCheckCircle className='text-success' size="60px" />
                            <p className='mt-3'>
                                <span className='f-22px'><b>E-mail is bijen verzonden</b></span><br />
                                <span className='f-16px'>Controleer uw e-mail</span>
                            </p>
                        </div>
                        {/* <div className="d-grid gap-2 mx-3">
                            <Button className='btn primary-bg' type="submit" >Verander wachtwoord</Button>
                        </div> */}
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default EmailConfirmation;