import React from 'react';
import { HiOutlineCog } from 'react-icons/hi';
import { HiCog } from 'react-icons/hi';
import { FaInfoCircle } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CustomTooltip from '../../Components/Tooltip';
import { AppLogout } from "../../Api";
import { NavLink, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";


const HeaderRightTop = () => {

    const [cookies, setCookie, removeCookie] = useCookies([
        "authToken",
        "userName",
    ]);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const appLogout = () => {
        AppLogout(removeCookie, navigate, setCookie);
    };

    return (
        <>
            <div className='heder-top'>
                <div className='tool-box w-100'>
                    <NavLink to="/" className='nav-link me-2 cursor-pointer'>
                        <HiCog size="20px" color='#9E9E9E' />
                    </NavLink>
                    <NavLink to="/market" className='me-2 text-uppercase text-decoration-none gray-text fw-500 cursor-pointer'>
                        Market Place
                    </NavLink>
                    <NavLink to="infos/tips" className='nav-link me-3 cursor-pointer'>
                        <CustomTooltip
                            ToolTipTrigger={<span className=""> <FaInfoCircle size="18px" color='#9E9E9E' /> </span>}
                            toolTipContent={'Infos'}
                            toolTipPlacement='bottom'
                        />
                    </NavLink>
                    <NavLink to="/user" className='nav-link cursor-pointer'>
                        <CustomTooltip
                            ToolTipTrigger={<span className=""> <FaUserAlt size="18px" color='#9E9E9E' /> </span>}
                            toolTipContent={'Mitarbeitende'}
                            toolTipPlacement='bottom'
                        />
                    </NavLink>

                </div>
            </div>

        </>
    )
}
export default HeaderRightTop;