import React, { useState, useEffect, ChangeEvent } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, } from 'react-bootstrap';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import SelectTemplateModal from '../../Components/Modals/SelectTemplateModal';
import AddTemplateModal from '../../Components/Modals/AddTemplateModal';
import Recipient from '../../Components/Modals/Recipient';
import AbortAlrt from '../../Components/Modals/AbortAlrt';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import EditorConvertToMarkdown from '../../Components/TextEditor';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaInfoCircle, FaTasks, FaTimes } from 'react-icons/fa';
import DateTimePicker from '../../Components/DateTimePicker';
import TimePicker from '../../Components/TimePicker';
import DatePicker from '../../Components/DatePicker';
import Badge from 'react-bootstrap/Badge';
import InputGroup from 'react-bootstrap/InputGroup';
import { HiOutlineSwitchVertical, HiX } from "react-icons/hi";
import AddMediaModal from '../../Components/Modals/AddMediaModal';
import ModelActions from '../../Components/ModelActions';
import Tagify from '../../Components/Tagify';
import ChooseTemplateCategoryModal from '../../Components/Modals/ChooseTemplateCategoryModal';
import ManageTemplateCategoryModal from '../../Components/Modals/ManageTemplateCategoryModal';
import CommonModal from '../../Components/Modals/CommonModal';
import ShowAllListModalContent from '../../Components/Modals/ModelContents/TaskModalsContent/ShowAllListsModalContent';
import ShowAllPlacesModalContent from '../../Components/Modals/ModelContents/TaskModalsContent/ShowAllPlacesModalContent';
import { Box, DisplayBetween, DisplayEnd, ModalHeader, } from '../../Components/StyledComponents';
import ManageListModalContent from '../../Components/Modals/ModelContents/TaskModalsContent/ManageListModalContent';
import ManagePlacesModalContent from '../../Components/Modals/ModelContents/TaskModalsContent/ManagePlacesModalContent';
import DragTest2 from '../../Components/DragTest2';
import DragTest3 from '../../Components/DragTest3';
import CheckListWidget from '../../Components/CheckListWidget';

interface taskProps {
    showTask: boolean,
    hideTask: () => void,
}

type TypeSection = {
    option: string
}

export type TypeSubtask = {
    value: string,
    answer: string,
    number: {
        isSelect: boolean,
        min: number,
        steps: number,
        max: number
    },
    selection: {
        isSelect: boolean,
        options: TypeSection[]
    }
}

type TypeSubHeading = {
    subheading: string,
}

type TypeWaypoint = {
    value: string,
    showModal: boolean,
    tagValue: string
}

type TypeInspection = {
    value: string,
    answer: string,
    createPost: string,
    inspectionCategory: string
    inspectionType: string,
    inspectionTypeValue?: string,
    inspectionLocation: string,
    inspectionLocationValue?: string,
}

export interface IChecklist {
    subtask?: TypeSubtask,
    subHeadings?: TypeSubHeading,
    waypoint?: TypeWaypoint,
    inspection?: TypeInspection
}

function AddEditTask(props: taskProps) {
    // State for modals Begin
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    const [AlertModal, setAlertModal] = useState<boolean>(false);
    const [selectTemplateModalShow, setSelectTemplateModalShow] = useState<boolean>(false);
    const [AddTemplateModalShow, setAddTemplateModalShow] = useState<boolean>(false);
    const [AddMediaModalShow, setAddMediaModalShow] = useState<boolean>(false);
    const [chooseTempCategoryModal, setChooseTempCategoryModal] = useState<boolean>(false);
    const [manageTempCategoryModal, setManageTempCategoryModal] = useState<boolean>(false);
    const [showAllListModal, setShowAllListModal] = useState<boolean>(false);
    const [showAllPlacesModal, setShowAllPlacesModal] = useState<boolean>(false);
    const [manageListModal, setManageListModal] = useState<boolean>(false);
    const [managePlaceModal, setManagePlaceModal] = useState<boolean>(false);
    // State for modals End

    // State for Hide Show Begin
    const [AddUser, setAddUser] = useState<boolean>(true);
    const [AllowReading, setAllowReading] = useState<boolean>(false);
    const [ShowSubscriber, setShowSubscriber] = useState<boolean>(false);
    const [RejectDuedate, setRejectDuedate] = useState<boolean>(false);
    const [AcceptDuedate, setAcceptDuedate] = useState<boolean>(true);
    const [IsRepeat, setIsRepeat] = useState<boolean>(false);
    const [CustomizeReminder, setCustomizeReminder] = useState<boolean>(false);
    const [AddEscalation, setAddEscalation] = useState<boolean>(false);
    const [CustomizeNotification, setCustomizeNotification] = useState<boolean>(false);
    const [MoreOption, setMoreOption] = useState<boolean>(false);
    const [TextEditorShow, setTextEditorShow] = useState<boolean>(false);
    const [HideFont, setHideFont] = useState<boolean>(false);
    const [TagifyShow, setTagifyShow] = useState<boolean>(false)
    const [isAddAllChecklist, setIsAddAllChecklist] = useState<boolean>(true);
    // State for Hide Show Begin



    //State for Checklist Question Begin
    const [ChecklistQuestions, setChecklistQuestions] = useState<IChecklist[]>([]);
    //State for Checklist Question End

    //----- Handling Check list Actions Begins -----// 
    const handleAnswerChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        const value = event.target.value;
        const prevState = [...ChecklistQuestions];

        if (value === 'number') {
            if (prevState[index]?.subtask?.number) {
                // @ts-expect-error
                prevState[index].subtask.number.isSelect = true;
            }
            if (prevState[index]?.subtask?.selection) {
                // @ts-expect-error
                prevState[index].subtask.selection.isSelect = false;
            }
        } else if (value === 'selection') {
            if (prevState[index]?.subtask?.selection) {
                // @ts-expect-error
                prevState[index].subtask.selection.isSelect = true;
            }
            if (prevState[index]?.subtask?.number) {
                // @ts-expect-error
                prevState[index].subtask.number.isSelect = false;
            }
        } else {
            if (prevState[index]?.subtask?.selection) {
                // @ts-expect-error
                prevState[index].subtask.selection.isSelect = false;
            }
            if (prevState[index]?.subtask?.number) {
                // @ts-expect-error
                prevState[index].subtask.number.isSelect = false;
            }
        }

        if (prevState[index]?.subtask) {
            // @ts-expect-error
            prevState[index].subtask.answer = value;
        }

        setChecklistQuestions([...prevState]);
    }
    const addAllChecklist = () => {
        var temp: IChecklist[] = [...ChecklistQuestions];
        if (temp.length === 0) {
            for (var i = 0; i < 4; i++) {
                let newQuestion = {
                    subtask: {
                        value: '',
                        answer: '',
                        number: {
                            isSelect: false,
                            min: 0,
                            steps: 0,
                            max: 0
                        },
                        selection: {
                            isSelect: false,
                            options: [
                                { option: 'Option A' },
                                { option: 'Option B' },
                            ],
                        },
                    }
                }
                temp.push(newQuestion);
            }
            setChecklistQuestions(temp);
        }
    }
    const addOneQuestion = () => {
        var temp = [...ChecklistQuestions]
        let newQuestion = {
            subtask: {
                value: '',
                answer: '',
                number: {
                    isSelect: false,
                    min: 0,
                    steps: 0,
                    max: 0
                },
                selection: {
                    isSelect: false,
                    options: [
                        { option: 'Option A' },
                        { option: 'Option B' },
                    ],
                },
            }
        }
        temp.push(newQuestion);
        setChecklistQuestions(temp);
    }
    const removeAllQuestion = () => {
        setChecklistQuestions([]);
    }
    const removeOneQuestion = (index: number) => {
        var temp = [...ChecklistQuestions]
        if (index != -1) {
            temp.splice(index, 1);
        }
        setChecklistQuestions(temp);
    }
    const addOptions = (index: number, optionText: string) => {
        var temp = [...ChecklistQuestions];
        var currentObj = { ...temp[index] };

        if (!!optionText) {
            var newOption = {
                option: optionText
            }

            currentObj.subtask?.selection.options.push(newOption);
            temp[index] = currentObj;
            setChecklistQuestions(temp);
        }
    }
    const removeOption = (index: number, innerIndex: number) => {
        var temp = [...ChecklistQuestions];
        var currentObj = { ...temp[index] };

        var optionsLength = currentObj.subtask?.selection.options.length;
        // @ts-expect-error
        if (optionsLength > 2) {
            if (innerIndex != -1) {
                currentObj.subtask?.selection.options.splice(innerIndex, 1);
            }
            temp[index] = currentObj;
            setChecklistQuestions(temp);
        }

    }
    const addSubheading = () => {
        var temp = [...ChecklistQuestions];
        var newQuestion: any = {
            subheading: null,
        }
        temp.push(newQuestion)
        setChecklistQuestions(temp);
    }
    const removeSubheading = (index: number) => {
        var temp = [...ChecklistQuestions];
        temp.splice(index, 1);
        setChecklistQuestions(temp);
    }

    const addWaypoint = () => {
        var temp = [...ChecklistQuestions];
        var newQuestion: any = {
            waypoint: {
                value: 'NcfTag requrid',
                showModal: false,
                tagValue: ''
            }
        }
        temp.push(newQuestion)
        setChecklistQuestions(temp);
    }

    const removeWaypoint = (index: number) => {
        var temp = [...ChecklistQuestions];
        temp.splice(index, 1);
        setChecklistQuestions(temp);
    }

    const handleWaypointTagChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        var temp = [...ChecklistQuestions];
        temp[index].waypoint!.tagValue = event.target.value;
        setChecklistQuestions(temp);
    }

    const addInspection = () => {
        var temp = [...ChecklistQuestions];
        var newQuestion: any = {
            inspection: {
                value: '',
                answer: '',
                createPost: 'createPostIfNegativeAnswer',
                inspectionCategory: 'task',
                inspectionType: 'no',
                inspectionTypeValue: null,
                inspectionLocation: 'no',
                inspectionLocationValue: null,
            }
        }
        temp.push(newQuestion)
        setChecklistQuestions(temp);
    }

    const removeInspection = (index: number) => {
        var temp = [...ChecklistQuestions];
        temp.splice(index, 1);
        setChecklistQuestions(temp);
    }
    //----- Handling Check list Actions End -----// 



    const HandleAllowReding = () => {
        setAllowReading(!AllowReading);
    }
    const HandleFontClick = () => {
        setTextEditorShow(true);
        setHideFont(true);
    }
    const handllDueDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.id === "choose") {
            setAcceptDuedate(true);
            setRejectDuedate(false);
        }
        else {
            setRejectDuedate(true);
            setAcceptDuedate(false);
        }
    }
    return (
        <>

            <Modal id="task-modal" size="lg" show={props.showTask} onHide={props.hideTask} backdrop="static" keyboard={false} aria-labelledby="example-modal-sizes-title-lg" >
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Aufgabe erstellen
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body className='pb-0'>
                    <Row>
                        <Col md={9}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Titel" />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Button className='default-btn f-12px w-100' onClick={() => setSelectTemplateModalShow(true)}>Vorlage auswählen</Button>
                        </Col>
                    </Row>
                    <div className='border-top border-bottom py-2'>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="">
                                    <Form.Check
                                        inline
                                        label="Öffentlich"
                                        name="group1"
                                        type="radio"
                                        id="public"
                                        onChange={() => { setAddUser(true); }}
                                    />
                                    <Form.Check
                                        inline
                                        label="Nicht öffentlich"
                                        name="group1"
                                        type="radio"
                                        id="notpublic"
                                        onChange={() => { setAddUser(true); }}
                                    />
                                    <Form.Check
                                        inline
                                        label=" An alle Nutzer"
                                        name="group1"
                                        type="radio"
                                        id="toalluser"
                                        onChange={() => { setAddUser(false); }}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                    </div>
                    {AddUser ?
                        <div className='mt-3'>
                            <Row>
                                <Col md={ShowSubscriber === false ? 7 : 9}>
                                    <Form.Control size="sm" type="text" placeholder="Empfänger" />
                                </Col>
                                <Col md={ShowSubscriber === false ? 5 : 3} className='ps-0'>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {ShowSubscriber === false ? <Button className='default-btn f-12px me-1 w-lg-150px' onClick={() => setShowSubscriber(true)}>Abonnenten wählen</Button> : null}
                                        <Button className='default-btn f-12px w-lg-180px' onClick={() => setRecipientModal(true)}><FaPlusCircle className='default-btn-icon' /> Alle Empfänger anzeigen</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        : null
                    }
                    {ShowSubscriber ?
                        <Row className='mt-3'>
                            <Col md={9}>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Control size="sm" type="text" placeholder="Abonnenten" />
                                </Form.Group>
                            </Col>
                            <Col md={3} className='text-end ps-0'>
                                <Button className='default-btn f-12px' onClick={() => setRecipientModal(true)}><FaPlusCircle className='default-btn-icon' /> Alle Abonnenten anzeigen</Button>
                            </Col>
                        </Row>
                        : null
                    }

                    <Row className='mt-4'>
                        <Col md={6}>
                            <Form.Label>Fälligkeit</Form.Label>
                            <Form.Group controlId="" className='mb-2'>
                                <Form.Check
                                    inline
                                    label="Auswählen ..."
                                    name="group2"
                                    type="radio"
                                    id="choose"
                                    checked={AcceptDuedate}
                                    onChange={(e) => handllDueDate(e)}
                                />
                                <Form.Check
                                    inline
                                    label="Keine"
                                    name="group2"
                                    type="radio"
                                    id="not-choose"
                                    onChange={(e) => handllDueDate(e)}
                                />
                            </Form.Group>
                        </Col>
                        {RejectDuedate ?
                            <Col md={6}>
                                <Form.Label>Priorität</Form.Label>
                                <Form.Group controlId="" className='mb-2'>
                                    <Form.Check
                                        inline
                                        label="Maximal"
                                        name="group1"
                                        type="radio"
                                        id="Maximal"
                                    />
                                    <Form.Check
                                        inline
                                        label="Hoch"
                                        name="group1"
                                        type="radio"
                                        id="Hoch"
                                    />
                                    <Form.Check
                                        inline
                                        label="Normal"
                                        name="group1"
                                        type="radio"
                                        id="Normal"
                                    />
                                    <Form.Check
                                        inline
                                        label="Niedrig"
                                        name="group1"
                                        type="radio"
                                        id="Low"
                                    />
                                </Form.Group>
                            </Col>
                            : null
                        }
                        {AcceptDuedate ?
                            <Col md={12}>
                                <div className='mb-3'>
                                    <div className='d-flex align-items-start'>
                                        <div className='me-1' style={{ width: '120px' }}>
                                            <TimePicker />
                                        </div>
                                        <div style={{ width: '120px' }}>
                                            <DatePicker />
                                        </div>
                                        <div className='ms-2'>
                                            <Form.Check
                                                label="Wiederhole diese Aufgabe"
                                                name="group1"
                                                type="checkbox"
                                                id="repeat"
                                                checked={IsRepeat}
                                                onChange={() => setIsRepeat(!IsRepeat)}
                                            />
                                        </div>
                                    </div>
                                    <p className='m-0'>Pakistan Standard Time</p>
                                </div>
                            </Col>
                            : null
                        }
                    </Row>
                    {IsRepeat && AcceptDuedate ?
                        <Row>
                            <Col md={4}>
                                <div className='mb-3'>
                                    <Form.Label>Intervall wiederholen</Form.Label>
                                    <Form.Select size="sm">
                                        <option>Täglich</option>
                                        <option>Wöchentlich</option>
                                        <option>Monatlich</option>
                                        <option>Quartalsweise</option>
                                        <option>Halbjährlich</option>
                                        <option>Jährlich</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={4} className='align-self-end'>
                                <div className='mb-3'>
                                    <Form.Label>Wiederhole bis</Form.Label>
                                    <DatePicker />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-3'>
                                    <Form.Label>Wiederhole alle</Form.Label>
                                    <div className='d-flex align-items-center'>
                                        <Form.Select size="sm" className='me-1'>
                                            {(() => {
                                                let options = [];
                                                for (let i = 0; i <= 30; i++) {
                                                    options.push(<option value={i}>{i}</option>);
                                                }
                                                return options;
                                            })()}
                                        </Form.Select>
                                        <span>Wochen</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <Form.Label>Wiederholen am</Form.Label>
                                <Form.Group controlId="" className='mb-3'>
                                    <Form.Check
                                        inline
                                        label="Sonntag"
                                        name="Sunday"
                                        type="checkbox"
                                        id="Sunday"
                                    />
                                    <Form.Check
                                        inline
                                        label="Montag"
                                        name="Monday"
                                        type="checkbox"
                                        id="Monday"
                                    />
                                    <Form.Check
                                        inline
                                        label="Dienstag"
                                        name="Tuesday"
                                        type="checkbox"
                                        id="Tuesday"
                                    />
                                    <Form.Check
                                        inline
                                        label="Mittwoch"
                                        name="Wednesday"
                                        type="checkbox"
                                        id="Wednesday"
                                    />
                                    <Form.Check
                                        inline
                                        label="Donnerstag"
                                        name="Thursday"
                                        type="checkbox"
                                        id="Thursday"
                                    />
                                    <Form.Check
                                        inline
                                        label="Freitag"
                                        name="Friday"
                                        type="checkbox"
                                        id="Friday"
                                    />
                                    <Form.Check
                                        inline
                                        label="Samstag"
                                        name="Saturday"
                                        type="checkbox"
                                        id="Saturday"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        : null
                    }
                    <Col md={12}>
                        {TextEditorShow ?
                            <div className="mb-3">
                                <EditorConvertToMarkdown />
                            </div>
                            :
                            <div className="mb-3">
                                <Form.Control as="textarea" placeholder="Text" rows={5} />
                            </div>
                        }
                    </Col>
                    <Col md={12}>
                        <ModelActions
                            ImgClick={() => setAddMediaModalShow(true)}
                            FontClick={() => HandleFontClick()}
                            isHideFont={HideFont}
                            TagifyClick={() => setTagifyShow(!TagifyShow)}
                        />
                        {TagifyShow ?
                            <div className='my-3 tagify'>
                                <Tagify />
                            </div>
                            : null
                        }
                    </Col>
                    {/* //Check List Begin */}
                    <div className='text-end mb-3'>
                        {isAddAllChecklist ?
                            <Button className='default-btn f-12px me-1' onClick={() => { addAllChecklist(); setIsAddAllChecklist(false) }}>
                                <span className='d-flex align-items-center'><FaTasks className='me-1' /> Checkliste hinzufügen</span>
                            </Button>
                            :
                            null
                        }
                    </div>
                    <Row className='border-top pt-3'>
                        <Col md={12}>
                            <div className='mb-3'>
                                <Form.Check
                                    inline
                                    label="Muss in fortlaufender Reihenfolge abgearbeitet werden"
                                    type="checkbox"
                                    name=""
                                    id=""

                                />
                                <Form.Check
                                    inline
                                    label="Antworten können nach 5 Minuten nicht mehr geändert werden"
                                    type="checkbox"
                                    name=""
                                    id=""

                                />
                            </div>
                        </Col>
                        <CheckListWidget
                            Data={ChecklistQuestions}
                            setData={setChecklistQuestions}
                            handleSubtask={handleAnswerChange}
                            RemoveOne={removeOneQuestion}
                            addOptions={addOptions}
                            removeOption={removeOption}
                            removeSubheading={removeSubheading}
                            handleWaypointTagChange={handleWaypointTagChange}
                            removeWaypoint={removeWaypoint}
                            removeInspection={removeInspection}
                        />
                    </Row>
                    <Row>
                        {!isAddAllChecklist ?
                            <Col md={12}>
                                <Row className='my-3'>
                                    <Col md={6}>
                                        <Button className='default-btn f-12px' onClick={() => { removeAllQuestion(); setIsAddAllChecklist(true); }}>Checkliste entfernen</Button>
                                    </Col>
                                    <Col md={6}>
                                        <DisplayEnd>
                                            <Box>
                                                <DropdownButton size="sm" className="template-sort checklist f-12px me-1" id="new-item" title="Hinzufügen...">
                                                    <Dropdown.Item href="javascript:;" onClick={addSubheading}>
                                                        <span className='f-14px'>Zwischenüberschrift</span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="javascript:;" onClick={addWaypoint}>
                                                        <span className='f-14px'>Wegpunkt</span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="javascript:;" onClick={addInspection}>
                                                        <span className='f-14px'>Inspektion</span>
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </Box>
                                            <Box ml="10px">
                                                <Button className='default-btn f-12px' onClick={() => addOneQuestion()}>Teilaufgabe hinzufügen</Button>
                                            </Box>
                                        </DisplayEnd>
                                    </Col>

                                </Row>
                            </Col>
                            :
                            null
                        }
                    </Row>
                    {/* //Check List End  */}
                    {AllowReading ?
                        <div>
                            <Row className='border-bottom border-top pt-3'>
                                <Col md={9}>
                                    <Form.Control size="sm" type="text" placeholder="Liste" />
                                </Col>
                                <Col md={3}>
                                    <div className='mb-3'>
                                        <Button onClick={() => setShowAllListModal(true)} className='default-btn f-12px w-lg-100'><FaPlusCircle className='default-btn-icon' /> Alle Listen anzeigen</Button>
                                    </div>
                                </Col>
                                <Col md={9}>
                                    <Form.Control size="sm" type="text" placeholder="Ort" />
                                </Col>
                                <Col md={3}>
                                    <div className='mb-3'>
                                        <Button onClick={() => setShowAllPlacesModal(true)} className='default-btn f-12px w-lg-100'><FaPlusCircle className='default-btn-icon' /> Alle Orte anzeigen</Button>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                {CustomizeReminder ? null : <p className='m-0 py-1'><a className='more-task-link' onClick={() => setCustomizeReminder(true)} href='javascript:;'>Erinnerungen anpassen</a></p>}
                                {CustomizeReminder ?
                                    <div className='mt-2'>
                                        <p className='m-0'><b>Erinnerungen</b></p>
                                        <Row className='border-bottom mt-2'>
                                            <Col md={6}>
                                                <div className='mb-3'>
                                                    <Form.Label>Empfänger</Form.Label>
                                                    <Form.Select size='sm'>
                                                        <option value="no">Nie</option>
                                                        <option value="0" selected>zur Fälligkeit</option>
                                                        <option value="5">5 Minuten vor Fälligkeit</option>
                                                        <option value="15">15 Minuten vor Fälligkeit</option>
                                                        <option value="30">30 Minuten vor Fälligkeit</option>
                                                        <option value="60">1 Stunde vor Fälligkeit</option>
                                                        <option value="120">2 Stunden vor Fälligkeit</option>
                                                        <option value="180">3 Stunden vor Fälligkeit</option>
                                                        <option value="360">6 Stunden vor Fälligkeit</option>
                                                        <option value="480">8 Stunden vor Fälligkeit</option>
                                                        <option value="600">10 Stunden vor Fälligkeit</option>
                                                        <option value="1440">1 Tag vor Fälligkeit</option>
                                                        <option value="2880">2 Tage vor Fälligkeit</option>
                                                        <option value="4320">3 Tage vor Fälligkeit</option>
                                                        <option value="7200">5 Tage vor Fälligkeit</option>
                                                        <option value="10080">7 Tage vor Fälligkeit</option>
                                                        <option value="14400">10 Tage vor Fälligkeit</option>
                                                        <option value="20160">14 Tage vor Fälligkeit</option>
                                                        <option value="43200">30 Tage vor Fälligkeit</option>
                                                        <option value="64800">45 Tage vor Fälligkeit</option>
                                                        <option value="86400">60 Tage vor Fälligkeit</option>
                                                        <option value="129600">90 Tage vor Fälligkeit</option>
                                                        <option value="172800">120 Tage vor Fälligkeit</option>
                                                    </Form.Select>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className='mb-3'>
                                                    <Form.Label>Ersteller</Form.Label>
                                                    <Form.Select size="sm">
                                                        <option value="no">Nie</option>
                                                        <option value="0" selected>zur Fälligkeit</option>
                                                        <option value="5">5 Minuten vor Fälligkeit</option>
                                                        <option value="15">15 Minuten vor Fälligkeit</option>
                                                        <option value="30">30 Minuten vor Fälligkeit</option>
                                                        <option value="60">1 Stunde vor Fälligkeit</option>
                                                        <option value="120">2 Stunden vor Fälligkeit</option>
                                                        <option value="180">3 Stunden vor Fälligkeit</option>
                                                        <option value="360">6 Stunden vor Fälligkeit</option>
                                                        <option value="480">8 Stunden vor Fälligkeit</option>
                                                        <option value="600">10 Stunden vor Fälligkeit</option>
                                                        <option value="1440">1 Tag vor Fälligkeit</option>
                                                        <option value="2880">2 Tage vor Fälligkeit</option>
                                                        <option value="4320">3 Tage vor Fälligkeit</option>
                                                        <option value="7200">5 Tage vor Fälligkeit</option>
                                                        <option value="10080">7 Tage vor Fälligkeit</option>
                                                        <option value="14400">10 Tage vor Fälligkeit</option>
                                                        <option value="20160">14 Tage vor Fälligkeit</option>
                                                        <option value="43200">30 Tage vor Fälligkeit</option>
                                                        <option value="64800">45 Tage vor Fälligkeit</option>
                                                        <option value="86400">60 Tage vor Fälligkeit</option>
                                                        <option value="129600">90 Tage vor Fälligkeit</option>
                                                        <option value="172800">120 Tage vor Fälligkeit</option>
                                                    </Form.Select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    : null
                                }

                                {AddEscalation ? null : <p className='m-0 py-1'><a className='more-task-link' onClick={() => setAddEscalation(true)} href='javascript:;'>Eskalation hinzufügen</a></p>}
                                {AddEscalation ?
                                    <div className='mt-2'>
                                        <p className='m-0'><b>Eskalation</b></p>
                                        <Row className="border-bottom mt-2">
                                            <Col md={6}>
                                                <Form.Check
                                                    label="Benachrichtige, wenn Aufgabe nicht erledigt ist"
                                                    name=""
                                                    type="checkbox"
                                                    id=""
                                                />
                                                <div className='mb-3'>
                                                    <div className='d-flex align-items-center'>
                                                        <Form.Select size="sm" className='me-1'>
                                                            <option value="number:5">5 Minuten</option>
                                                            <option value="number:15">15 Minuten</option>
                                                            <option value="number:30">30 Minuten</option>
                                                            <option value="number:60" selected>1 Stunde</option>
                                                            <option value="number:120">2 Stunden</option>
                                                            <option value="number:180">3 Stunden</option>
                                                            <option value="number:360">6 Stunden</option>
                                                            <option value="number:480">8 Stunden</option>
                                                            <option value="number:600">10 Stunden</option>
                                                            <option value="number:1440">1 Tag</option>
                                                            <option value="number:2880">2 Tage</option>
                                                            <option value="number:4320">3 Tage</option>
                                                            <option value="number:7200">5 Tage</option>
                                                            <option value="number:10080">7 Tage</option>
                                                            <option value="number:14400">10 Tage</option>
                                                            <option value="number:20160">14 Tage</option>
                                                            <option value="number:43200">30 Tage</option>
                                                            <option value="number:64800">45 Tage</option>
                                                            <option value="number:86400">60 Tage</option>
                                                            <option value="number:129600">90 Tage</option>
                                                            <option value="number:172800">120 Tage</option>
                                                        </Form.Select>
                                                        <Form.Select size="sm">
                                                            <option value="string:createTime">nach Erstellung</option>
                                                            <option value="string:deadline" selected>nach Fälligkeit</option>
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6} className='align-self-end'>
                                                <div className='mb-3'>
                                                    <Form.Check
                                                        inline
                                                        label="An Ersteller"
                                                        name=""
                                                        type="checkbox"
                                                        id=""
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="An Empfänger"
                                                        name=""
                                                        type="checkbox"
                                                        id=""
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    : null
                                }

                                {CustomizeNotification ? null : <p className='m-0 py-1'><a className='more-task-link' onClick={() => setCustomizeNotification(true)} href='javascript:;'>Benachrichtigungen anpassen</a></p>}
                                {CustomizeNotification ?
                                    <div className='mt-2'>
                                        <p className='m-0'><b>Benachrichtigungen</b></p>
                                        <Row className='border-bottom mt-2'>
                                            <Col md={4}>
                                                <div className='mb-3'>
                                                    <Form.Label>Über Erstellung</Form.Label>
                                                    <Form.Group>
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Ersteller"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Empfänger"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className='mb-3'>
                                                    <Form.Label>Über Kommentare</Form.Label>
                                                    <Form.Group>
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Ersteller"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Empfänger"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className='mb-3'>
                                                    <Form.Label>Über Statusveränderungen</Form.Label>
                                                    <Form.Group>
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Ersteller"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Empfänger"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    : null
                                }

                                {MoreOption ? null : <p className='m-0 py-1'><a className='more-task-link' onClick={() => setMoreOption(true)} href='javascript:;'>Weitere Optionen</a></p>}
                                {MoreOption ?
                                    <div className='mt-2'>
                                        <p className='m-0'><b>Weitere Optionen</b></p>
                                        <Row className='mt-2'>
                                            <Col md={6}>
                                                <div>
                                                    <Form.Check
                                                        label=" Die Aufgabe muss von mir kontrolliert werden."
                                                        name=""
                                                        type="checkbox"
                                                        id=""
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div>
                                                    <Form.Check
                                                        label=" Ein Ergebnis muss übermittelt werden."
                                                        name=""
                                                        type="checkbox"
                                                        id=""
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div>
                                                    <Form.Check
                                                        label="Empfänger müssen die Aufgabe aktiv annehmen."
                                                        name=""
                                                        type="checkbox"
                                                        id=""
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div>
                                                    <Form.Check
                                                        label="Nur der Ersteller darf fremde Ergebnisse sehen."
                                                        name=""
                                                        type="checkbox"
                                                        id=""
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div>
                                                    <Form.Check
                                                        label="Erlaube Empfängern den Eintrag zu bearbeiten."
                                                        name=""
                                                        type="checkbox"
                                                        id=""
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                        : null
                    }
                </Modal.Body>
                <Modal.Footer className='justify-content-between footer-sticky'>
                    <div>
                        <Button className='default-btn f-14px' onClick={HandleAllowReding}>
                            {AllowReading ? <FaMinusCircle className='default-btn-icon' /> : <FaPlusCircle className='default-btn-icon' />}
                            <span>{AllowReading ? "Optionen ausblenden" : "Zeige weitere Optionen"}</span>
                        </Button>
                    </div>
                    <div>
                        <Button className='default-btn f-14px me-1'>
                            Entwurf speichern
                        </Button>
                        <Button className='default-btn f-14px me-1' onClick={() => setAddTemplateModalShow(true)}>
                            Als Vorlage hinzufügen
                        </Button>
                        <Button className='default-btn f-14px me-1' onClick={() => setAlertModal(true)}>
                            Abbrechen
                        </Button>
                        <Button onClick={props.hideTask} className='primary-bg f-14px'>
                            Erstellen
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <SelectTemplateModal
                displayTemplateModal={selectTemplateModalShow}
                hideTemplateModal={() => setSelectTemplateModalShow(false)}
            />
            <AddTemplateModal
                displayAddTemplateModal={AddTemplateModalShow}
                hideAddTemplateModal={() => setAddTemplateModalShow(false)}
                chooseCategoryClickHandler={() => setChooseTempCategoryModal(true)}
                recipientClickHandler={() => setRecipientModal(true)}
                isTask={true}
            />

            {/* ====== Inner Modal of Add Template modal Begin ====== */}
            <ChooseTemplateCategoryModal
                displayCategoryModal={chooseTempCategoryModal}
                hideCategoryModal={() => setChooseTempCategoryModal(false)}
                manageCategoriesClickHandler={() => setManageTempCategoryModal(true)}
            />
            <ManageTemplateCategoryModal
                displayCategoriesTemplateModal={manageTempCategoryModal}
                modalTitle="Vorlagenkategorien verwalten"
                hideCategoriesTemplateModal={() => setManageTempCategoryModal(false)}
            />
            {/* ====== Inner Modal of Add Template modal Begin ====== */}

            <CommonModal
                showModal={showAllListModal}
                hideModal={() => setShowAllListModal(false)}
                modalTitle='Wähle eine Liste'
                modalContent={<ShowAllListModalContent />}
                modalSize='lg'
                modalFooterContent={<ShowAllListFooterContent showModal={() => setManageListModal(true)} hideModal={() => setShowAllListModal(false)} />}
            />
            <CommonModal
                showModal={manageListModal}
                hideModal={() => setManageListModal(false)}
                modalTitle='Listen verwalten'
                modalContent={<ManageListModalContent />}
                modalSize='lg'
                modalFooterContent={<ManageListFooterContent hideModal={() => setManageListModal(false)} />}
                bodyStyleClass='pt-0 pb-0'
                modelId='manageList'
            />
            <CommonModal
                showModal={showAllPlacesModal}
                hideModal={() => setShowAllPlacesModal(false)}
                modalTitle='Wähle einen Ort'
                modalContent={<ShowAllPlacesModalContent />}
                modalSize='lg'
                modalFooterContent={<ShowAllPlacesFooterContent showModal={() => setManagePlaceModal(true)} hideModal={() => setShowAllPlacesModal(false)} />}
            />
            <CommonModal
                showModal={managePlaceModal}
                hideModal={() => setManagePlaceModal(false)}
                modalTitle='Orte verwalten'
                modalContent={<ManagePlacesModalContent />}
                modalSize='lg'
                modalFooterContent={<ManagePlaceFooterContent hideModal={() => setManagePlaceModal(false)} />}
                bodyStyleClass='pt-0 pb-0'
                modelId='managePlace'
            />

            <Recipient displayRecipientModal={RecipientModal} hideRecipientModal={() => setRecipientModal(false)} />
            <AbortAlrt displayAlertModal={AlertModal} hideAlertModal={() => setAlertModal(false)} />
        </>
    );
}




export default AddEditTask;



type listModalType = {
    showModal: () => void,
    hideModal: () => void,
}
type manageModelType = {
    hideModal: () => void,
}
// *********** Show All List Modal Footer Content Begins

export const ShowAllListFooterContent = (props: listModalType) => {
    return (
        <>
            <DisplayBetween className='w-100'>
                <Box>
                    <Button className='default-btn f-14px me-1' onClick={props.showModal}>
                        Listen verwalten
                    </Button>
                </Box>
                <Box>
                    <Button className='default-btn f-14px me-1' onClick={props.hideModal}>
                        Abbrechen
                    </Button>
                    <Button className='primary-bg f-14px'>
                        Erstellen
                    </Button>
                </Box>
            </DisplayBetween>
        </>
    )
}
// *********** Show All List Modal Footer Content End


// *********** Manage List Modal Footer Content Begins

export const ManageListFooterContent = (props: manageModelType) => {
    return (
        <>

            <Button className='default-btn f-14px me-1' onClick={props.hideModal}>
                Abbrecnen
            </Button>

        </>
    )
}
// *********** Manage List Modal Footer Content End


// *********** Show All Places Modal Footer Content Begins

export const ShowAllPlacesFooterContent = (props: listModalType) => {
    return (
        <>
            <DisplayBetween className='w-100'>
                <Box>
                    <Button className='default-btn f-14px me-1' onClick={props.showModal}>
                        Orte vemalten
                    </Button>
                </Box>
                <Box>
                    <Button className='default-btn f-14px me-1' onClick={props.hideModal}>
                        Abbrechen
                    </Button>
                    <Button className='primary-bg f-14px'>
                        Erstellen
                    </Button>
                </Box>
            </DisplayBetween>
        </>
    )
}
// *********** Show All Places Modal Footer Content End

// *********** Manage Places Modal Footer Content Begins

export const ManagePlaceFooterContent = (props: manageModelType) => {
    return (
        <>

            <Button className='default-btn f-14px me-1' onClick={props.hideModal}>
                Abbrecnen
            </Button>

        </>
    )
}
// *********** Manage Places Modal Footer Content End