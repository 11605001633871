import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BodyText, Box, DisplayEnd, DisplayStart, Divider } from '../../Components/StyledComponents';
import { FaSearch } from 'react-icons/fa';
import Form from "react-bootstrap/Form";
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CommonModal from '../../Components/Modals/CommonModal';
import BadgeWidget from '../../Components/BadgeWidget';
import { MomentModalContent } from '../../Components/Modals/ModelContents/MarketPlaceModalContent/MomentModalContent';
import { MessangerModalContent } from '../../Components/Modals/ModelContents/MarketPlaceModalContent/MessangerModalContent';


const MarketData = [
    {
        image: "/images/assets/moment-icon.png",
        status: true,
        statusText: "Active",
        title: "Moments",
        roleBadge: "medikit Tool",
        description: "Share special moments amongst your team."
    },
    {
        image: "/images/assets/messanger-icon.png",
        status: false,
        statusText: "Active",
        title: "Facebook Messenger Integration",
        roleBadge: "Guest Engagement",
        description: "This interface enables direct communication with the patient via Facebook Messenger."
    },
]

type ModalState = {
    index: number | undefined;
    isOpen: boolean;
    title: any;
    content: any;
}

export const MainContent = () => {

    const [messangerModal, setMessangerModal] = useState<ModalState>({
        index: undefined,
        isOpen: false,
        title: undefined,
        content: undefined,
    });

    const handleCardClick = (index?: number, title?: string) => {
        switch (title) {
            case 'Moments':
                setMessangerModal({ index: index, isOpen: true, title: title, content: <MomentModalContent /> });
                break;
            case 'Facebook Messenger Integration':
                setMessangerModal({ index: index, isOpen: true, title: title, content: <MessangerModalContent /> });
                break;
        }

    }


    return (
        <>
            <div className='topbar-video-tutorial mb-3'>
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <BodyText fontSize='14px' fontWeight='bold' textColor='#666' className='text-uppercase'> All </BodyText>
                    </div>
                    <div>
                        <DisplayStart>

                            <Form className="d-flex">
                                <Form.Control
                                    type="search"
                                    placeholder="Search by name"
                                    className="me-0 rounded-end-0 py-0"
                                    aria-label="Search"
                                />
                                <Button className='default-btn f-14px fw-500 rounded-end rounded-start-0' >Search</Button>
                            </Form>
                            <DropdownButton size="sm" className="template-sort f-12px ms-3" id="new-item" title="Order: Random">
                                <Dropdown.Item>
                                    <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Alphabetical </BodyText>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Date </BodyText>
                                </Dropdown.Item>
                            </DropdownButton>
                        </DisplayStart>

                    </div>
                </div>
                <Box mt="12px" mb="12px">
                    <Divider mb="3px" />
                    <Divider />
                </Box>
            </div>
            <div className='market-card-sec'>
                <Container>
                    <Row>
                        {MarketData.map((item, index) => {
                            return (
                                <>
                                    <Col lg={4} md={4} sm={12} className='cursor-pointer card-tutorial-video mb-2' key={index} onClick={() => handleCardClick(index, item.title)}>
                                        <Card className='h-320px'>
                                            <Card.Body className='p-3'>
                                                {item.status ?
                                                    <>
                                                        <DisplayEnd>
                                                            <Box>
                                                                <BadgeWidget solid={true} title={item.statusText} color="#fff" bgColor="#5cb85c" />
                                                            </Box>
                                                        </DisplayEnd>
                                                    </>
                                                    :
                                                    <Box mt="30px"></Box>
                                                }
                                                <Box className='text-center'>
                                                    <Box mt="10px" mb="15px">
                                                        <img src={item.image} className='integration-preview-img' />
                                                    </Box>
                                                    <Box>
                                                        <BodyText fontSize='18px' fontWeight='600' textColor='#666' mb="12px"> {item.title} </BodyText>
                                                    </Box>
                                                    <Card.Text>
                                                        <Box mb="15px">
                                                            <BadgeWidget solid={true} title={item.roleBadge} color="#fff" bgColor="#aaa" />
                                                        </Box>
                                                        <BodyText fontSize='13px' fontWeight='400' textColor='#666' mb="10px"> {item.description} </BodyText>
                                                    </Card.Text>
                                                </Box>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </>
                            )
                        })}

                    </Row>
                </Container>
                <CommonModal
                    showModal={messangerModal.isOpen}
                    hideModal={() => setMessangerModal({ index: undefined, isOpen: false, content: undefined, title: undefined })}
                    modalTitle={messangerModal.title}
                    modalContent={messangerModal.content}
                    modalSize='lg'
                    modalFooterContent={<>
                        <DisplayEnd>
                            <Button className="default-btn f-14px me-1" onClick={() => setMessangerModal({ index: undefined, isOpen: false, content: undefined, title: undefined })}>
                                Close
                            </Button>
                        </DisplayEnd>
                    </>}
                    footerClass={"footer-sticky"}
                />
            </div>
        </>
    )
}
