import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Sidebar from '../Message/SideBar';
import { HandbookDetail } from './details/HandbookDetail';
import { Outlet, useLocation } from "react-router-dom";
import { TopbarSelectFilterDropdown } from '../../Components/TopbarSelectFilterDropdown';
import { TdisplayMode } from '../Message/Index';

//******** handbooks List Data Begins ********//
export interface IHandbookProps {
    serialNumber?: number,
    title: string,
    iconMail: boolean,
    isChecked: boolean,
}

const HandbookData = [
    {
        serialNumber: 1,
        title: "Unser Leitbild",
        iconMail: true,
        isChecked: false,
    },
    {
        serialNumber: 2,
        title: "Qualitätsmanagement",
        iconMail: true,
        isChecked: false,
    },
    {
        serialNumber: 3,
        title: "Verwaltung",
        iconMail: true,
        isChecked: false,
    },
    {
        serialNumber: 4,
        title: "Behandlung",
        iconMail: true,
        isChecked: false,
    },
    {
        serialNumber: 5,
        title: "Labor",
        iconMail: true,
        isChecked: false,
    },
    {
        serialNumber: 6,
        title: "Lager & Material",
        iconMail: true,
        isChecked: false,
    },
    {
        serialNumber: 7,
        title: "Besprechungsprotokolle",
        iconMail: true,
        isChecked: false,
    },
    {
        serialNumber: undefined,
        title: "OPTI QMH",
        iconMail: true,
        isChecked: false,
    },
]
//******** handbooks List Data End ********//

//******** handbooksDetail List Data Start ********//
export interface HandbookDetailType {
    folderIcon: boolean,
    title: string,
    userName: string,
    userNameTooltip: string,
    updateIcon: boolean,
    updateIconTooltip: string,
    dateTime: string,
    noticeIcon: boolean
    noticeIconTooltip: string,
    versionText: string,
    isChecked: boolean,
}
const HandbookDetailData: HandbookDetailType[] = [
    {
        folderIcon: true,
        title: "Die 10 Gebote unserer Serviceorientierung",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        updateIcon: true,
        updateIconTooltip: "Letzte Änderung",
        dateTime: "08.12.2022 11:15",
        noticeIcon: true,
        noticeIconTooltip: "gemerkt",
        versionText: "Version 3",
        isChecked: false,
    },
    {
        folderIcon: true,
        title: "OPTI-QM-Flat: Neue Dokumente",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        updateIcon: true,
        updateIconTooltip: "Letzte Änderung",
        dateTime: "",
        noticeIcon: true,
        noticeIconTooltip: "gemerkt",
        versionText: "Version 8",
        isChecked: false,
    },
    {
        folderIcon: true,
        title: "Die 10 Gebote unserer Serviceorientierung",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        updateIcon: false,
        updateIconTooltip: "",
        dateTime: "08.12.2022 11:15",
        noticeIcon: true,
        noticeIconTooltip: "gemerkt",
        versionText: "Version 9",
        isChecked: false,
    },
    {
        folderIcon: true,
        title: "OPTI-QM-Flat: Neue Dokumente",
        userName: "Dr. Sven Markus Gallenbach",
        userNameTooltip: "Zahnarzt - Praxis Gallenbach & Zähne",
        updateIcon: true,
        updateIconTooltip: "Letzte Änderung",
        dateTime: "08.12.2022 11:15",
        noticeIcon: true,
        noticeIconTooltip: "gemerkt",
        versionText: "Version 12",
        isChecked: false,
    },
]
//******** handbooksDetail List Data End ********//


export const HandBookIndex = () => {
    const location = useLocation();
    //******** handbooks States Begins ********//
    const [handBookData, setHandBookData] = useState<IHandbookProps[]>([]);
    const [isShowChecksHandBook, setIsShowChecksHandBook] = useState<boolean>(false);
    const [checkAllEntriesHandBook, setCheckAllEntriesHandBook] = useState<boolean>(false);
    const [totalAccountHandBook, setTotalAccountHandBook] = useState<number>(0);
    //******** handbooks States End ********//

    //******** handbooksDetail States Begins ********//
    const [handbookDetailData, setHandbookDetailData] = useState<HandbookDetailType[]>([]);
    const [isShowChecksHandBookDetail, setIsShowChecksHandBookDetail] = useState<boolean>(false);
    const [checkAllEntriesHandBookDetail, setCheckAllEntriesHandBookDetail] = useState<boolean>(false);
    const [totalAccountHandBookDetail, setTotalAccountHandBookDetail] = useState<number>(0);
    //******** handbooksDetail States End ********//

    const [displayMode, setDisplayMode] = useState<TdisplayMode>({ displayNormal: true, displayCompact: false, displaySplit: false });
    const [sendReminder, setSendReminder] = useState<boolean>(false);

    useEffect(() => {
        setHandBookData(HandbookData);
        setHandbookDetailData(HandbookDetailData);
    }, []);


    //******** handbooks Actions Begins ********//
    const HandleCheckboxHandBook = (index: number) => {
        const temp = [...handBookData];
        var count: number = 0;
        temp[index].isChecked = !temp[index].isChecked;
        setHandBookData(temp);
        for (var i = 0; i < handBookData.length; i++) {
            if (handBookData[i].isChecked === true) {
                count++;
            }
        }
        setTotalAccountHandBook(count);
    }
    const HandleSelectAllHandBook = () => {
        const tempArray = [...handBookData];
        for (var i = 0; i < tempArray.length; i++) {
            tempArray[i].isChecked = true;
        }
        setHandBookData(tempArray);
        setTotalAccountHandBook(tempArray.length);
    }
    const handleToggleEntryCheckHandBook = () => {
        setIsShowChecksHandBook(!isShowChecksHandBook);
        const tempArray = [...handBookData];
        for (var i = 0; i < tempArray.length; i++) {
            tempArray[i].isChecked = false;
        }
        setHandBookData(tempArray);
        setTotalAccountHandBook(0);
    }

    //******** handbooks Actions End ********//




    //******** handbooksDetail  Actions Begins ********//
    const HandleCheckboxHandBookDetail = (index: number) => {
        const temp = [...handBookData];
        var count: number = 0;
        temp[index].isChecked = !temp[index].isChecked;
        setHandBookData(temp);
        for (var i = 0; i < handBookData.length; i++) {
            if (handBookData[i].isChecked === true) {
                count++;
            }
        }
        setTotalAccountHandBookDetail(count);
    }
    const HandleSelectAllHandBookDetail = () => {
        let tempArray = [...handbookDetailData];
        for (var i = 0; i < tempArray.length; i++) {
            tempArray[i].isChecked = true;
        }
        setHandbookDetailData(tempArray);
        setTotalAccountHandBookDetail(tempArray.length);
    }
    const handleToggleEntryCheckHandBookDetail = () => {
        setIsShowChecksHandBookDetail(!isShowChecksHandBookDetail);
        const tempArray = [...handbookDetailData];
        for (var i = 0; i < tempArray.length; i++) {
            tempArray[i].isChecked = false;
        }
        setHandbookDetailData(tempArray);
        setTotalAccountHandBookDetail(0);
    }

    //******** handbooksDetail  Actions Begins ********//

    return (
        <>
            <Row>
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <div className="news-topbar mb-2">
                        {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "handbooks" ?
                            <TopbarSelectFilterDropdown
                                showHideEntryCheck={handleToggleEntryCheckHandBook}
                                showChecks={isShowChecksHandBook}
                                checkAllEntries={checkAllEntriesHandBook}
                                handleSelectAll={HandleSelectAllHandBook}
                                totalAccount={totalAccountHandBook}
                            />
                            :
                            <TopbarSelectFilterDropdown
                                showHideEntryCheck={handleToggleEntryCheckHandBookDetail}
                                showChecks={isShowChecksHandBookDetail}
                                checkAllEntries={checkAllEntriesHandBookDetail}
                                handleSelectAll={HandleSelectAllHandBookDetail}
                                totalAccount={totalAccountHandBookDetail}
                            />
                        }
                    </div>
                    {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "handbooks" ?
                        <Outlet context={[handBookData, setHandBookData, displayMode, isShowChecksHandBook, setTotalAccountHandBook]} />
                        :
                        <Outlet context={[handbookDetailData, setHandbookDetailData, displayMode, isShowChecksHandBookDetail, setTotalAccountHandBookDetail]} />
                    }

                </Col>
            </Row>
        </>
    );
}
