import React from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { HiOutlineChatAlt } from "react-icons/hi";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { HiOutlineInformationCircle } from 'react-icons/hi';
import CustomTooltip from '../../Components/Tooltip';
import { Link } from 'react-router-dom';

const ActivitiesData = [
    {
        image1: "/images/shopping-bag.png",
        image2: "/images/account-b.png",
        titleBorder: "Praxis Gallenbach & Zähne",
        link1: "APZ APZ",
        roleThe: "at die Aufgabe",
        link2: "541",
        timeTooltip: "vor 26 Stunden",
        timeTooltipContent: "15.11.2022 14:52",
        created: " erstellt HKP´s sind erstellt und freigegeben"
    },
    {
        image1: "/images/shopping-bag.png",
        image2: "/images/account-b.png",
        titleBorder: "Praxis Gallenbach & Zähne",
        link1: "APZ APZ",
        roleThe: "at die Aufgabe",
        link2: "7078",
        timeTooltip: "vor 27 Stunden",
        timeTooltipContent: "15.11.2022 14:52",
        created: " erstellt HKP´s sind erstellt und freigegeben"
    },
    {
        image1: "/images/shopping-bag.png",
        image2: "/images/account-b.png",
        titleBorder: "Praxis Gallenbach & Zähne",
        link1: "APZ APZ",
        roleThe: "at die Aufgabe",
        link2: "5996",
        timeTooltip: "vor 27 Stunden",
        timeTooltipContent: "15.11.2022 14:52",
        created: " erstellt HKP´s sind erstellt und freigegeben"
    },
    {
        image1: "/images/shopping-bag.png",
        image2: "/images/working.png",
        titleBorder: "Praxis Gallenbach & Zähne",
        link1: "APZ APZ",
        roleThe: "at die Aufgabe",
        link2: "2741",
        timeTooltip: "vor 56 Stunden",
        timeTooltipContent: "15.11.2022 14:52",
        created: " erstellt HKP´s sind erstellt und freigegeben"
    },
    {
        image1: "/images/shopping-bag.png",
        image2: "/images/working.png",
        titleBorder: "Praxis Gallenbach & Zähne",
        link1: "APZ APZ",
        roleThe: "at die Aufgabe",
        link2: "439",
        timeTooltip: "vor 29 Stunden",
        timeTooltipContent: "15.11.2022 14:52",
        created: " erstellt HKP´s sind erstellt und freigegeben"
    },
    {
        image1: "/images/shopping-bag.png",
        image2: "/images/account-b.png",
        titleBorder: "Praxis Gallenbach & Zähne",
        link1: "APZ APZ",
        roleThe: "at die Aufgabe",
        link2: "7078",
        timeTooltip: "vor 27 Stunden",
        timeTooltipContent: "15.11.2022 14:52",
        created: " erstellt HKP´s sind erstellt und freigegeben"
    },
    {
        image1: "/images/shopping-bag.png",
        image2: "/images/account-b.png",
        titleBorder: "Praxis Gallenbach & Zähne",
        link1: "APZ APZ",
        roleThe: "at die Aufgabe",
        link2: "7078",
        timeTooltip: "vor 27 Stunden",
        timeTooltipContent: "15.11.2022 14:52",
        created: " erstellt HKP´s sind erstellt und freigegeben"
    },
]

function Activities() {

    function truncate(source: string, size: number) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }

    return (
        <>
            <Card className='border-0'>
                <Card.Body className='border-top-none activity-accordion'>
                    <Accordion defaultActiveKey={['0', '1']} >
                        <Accordion.Item eventKey="0">
                            <div className='d-flex justify-content-between align-items-center px-2 py-3'>
                                <div>
                                    <h6 className='m-0'>HEUTE</h6>
                                </div>
                                <div>
                                    <Accordion.Header>Heute ausblenden</Accordion.Header>
                                </div>
                            </div>
                            <Accordion.Body>
                                {ActivitiesData.map((item, index) => {
                                    return (
                                        <>
                                            <div className='accordion-content' key={index}>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3'>
                                                        <img width='25' src={item.image1} />
                                                    </div>
                                                    <div className='me-3'>
                                                        <img width='25' src={item.image2} />
                                                    </div>
                                                    <div className='me-2'>
                                                        <span className='practice-label text-nowrap'> {item.titleBorder} </span>
                                                    </div>
                                                    <div className='me-3'>
                                                        <p className='m-0 d-flex'>
                                                            <Link to="" className='text-decoration-none text-uppercase'>
                                                                <span className='fw-400 text-truncate text-link-color cursor-pointer'> {item.link1} </span>
                                                            </Link>
                                                            <span className='mx-1 text-nowrap'> {item.roleThe} </span>
                                                            <Link to="" className='text-decoration-none ms-1'>
                                                                <span className='fw-400 text-truncate text-link-color cursor-pointer'> {item.link2} </span>
                                                            </Link>
                                                            <span className='text-nowrap ms-1'> {truncate(item.created, 20)} </span>
                                                        </p>
                                                    </div>
                                                    <div className='me-2 text-nowrap'>
                                                        <CustomTooltip
                                                            ToolTipTrigger={<><span className='text-secondary'>{item.timeTooltip}</span></>}
                                                            toolTipContent={item.timeTooltipContent}
                                                            toolTipPlacement='top'
                                                            contentAlign='center'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <div className='d-flex justify-content-between align-items-center px-2 py-3'>
                                <div>
                                    <h6 className='m-0'>GESTERN</h6>
                                </div>
                                <div>
                                    <Accordion.Header>Heute ausblenden</Accordion.Header>
                                </div>
                            </div>
                            <Accordion.Body>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <HiOutlineLightBulb size='25px' className='gray-color' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <HiOutlineInformationCircle size="25px" className='gray-color' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <div className='d-flex justify-content-between align-items-center px-2 py-3'>
                                <div>
                                    <h6 className='m-0'>13.11.2022</h6>
                                </div>
                                <div>
                                    <Accordion.Header>Heute ausblenden</Accordion.Header>
                                </div>
                            </div>
                            <Accordion.Body>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <FaRegCalendarAlt size="25px" className='gray-color' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <img width='25' src='/images/shopping-bag.png' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <div className='d-flex justify-content-between align-items-center px-2 py-3'>
                                <div>
                                    <h6 className='m-0'>14.11.2022</h6>
                                </div>
                                <div>
                                    <Accordion.Header>Heute ausblenden</Accordion.Header>
                                </div>
                            </div>
                            <Accordion.Body>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <img width='25' src='/images/working.png' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <FaRegClock size="25px" className='gray-color' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Card.Body>
            </Card>
        </>
    );
}

export default Activities;