import React, { FC, Dispatch, SetStateAction, MouseEvent, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { FaLongArrowAltRight } from "react-icons/fa";
import { HiOutlineLockClosed, HiOutlinePaperClip } from "react-icons/hi2";
import CustomTooltip from '../../Components/Tooltip';
import BadgeWidget from '../../Components/BadgeWidget';
import FormCheck from '../../Components/FormCheck';
import { BodyText } from '../../Components/StyledComponents';
import { useNavigate } from "react-router-dom";
import { IMessageData, TdisplayMode } from './Index';


interface IProps {
    listData: IMessageData[],
    setListData: Dispatch<SetStateAction<IMessageData[]>>,
    displayMode: TdisplayMode,
    showChecks: boolean,
    setCheckCount: Dispatch<SetStateAction<number>>
}
export const MessageList: FC<IProps> = ({ listData, setListData, displayMode, showChecks, setCheckCount }) => {
    const navigate = useNavigate();
    const [sendReminder, setSendReminder] = useState<boolean>(false);


    const HandleCheckbox = (index: number, event: MouseEvent<HTMLInputElement>) => {
        debugger
        let temp = [...listData];
        var count: number = 0;
        temp[index].isChecked = !temp[index].isChecked;

        for (var i = 0; i < listData.length; i++) {
            if (listData[i].isChecked === true) {
                count++;
            }
        }
        setCheckCount(count);
        setListData(temp);
    }
    const handleRowClick = (event: MouseEvent<HTMLElement>) => {
        navigate('/messages/messages-detial');
    }
    const handleClickGraph = (event: React.MouseEvent<HTMLTableCellElement>) => {
        event.stopPropagation();
        setSendReminder(true);
    }
    return (
        <>
            <Card className='boxShadow'>
                <Card.Body>
                    <Table responsive hover className='mb-0'>
                        <tbody>
                            {listData.map((item, index) => {
                                return (
                                    <>
                                        <tr key={index} onClick={(event: MouseEvent<HTMLElement>) => handleRowClick(event)} className='cursor-pointer'>
                                            {showChecks ?
                                                <td width='30'>
                                                    <FormCheck
                                                        type='checkbox'
                                                        name='phone'
                                                        Id='phone'
                                                        value={item.title}
                                                        label=''
                                                        isFormGroup={false}
                                                        isInline={true}
                                                        checked={item.isChecked}
                                                        handleClick={(event: MouseEvent<HTMLInputElement>) => {
                                                            HandleCheckbox(index, event);
                                                            event.stopPropagation();
                                                        }}
                                                    />
                                                </td>
                                                : null}
                                            <td>
                                                <div className='msg-title'>
                                                    <BodyText mb='0px' fontSize='1.1em' fontWeight='700' textColor='#000'> {item.title} </BodyText>
                                                </div>
                                                {displayMode.displayNormal ?
                                                    <>
                                                        <div className='d-flex align-items-center'>
                                                            <BadgeWidget outiline={true} title={item.badgeOutline} color='#0d3f5f' mr='8px' />
                                                            <CustomTooltip
                                                                ToolTipTrigger={<BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'> {item.userName1} </BodyText>}
                                                                toolTipContent={item.userNameTooltipContent1}
                                                                toolTipPlacement='top'
                                                            />
                                                            <FaLongArrowAltRight className='mx-1' />
                                                            <div className='ms-1'>
                                                                <CustomTooltip
                                                                    ToolTipTrigger={<BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'> {item.userName2} </BodyText>}
                                                                    toolTipContent={item.userNameTooltipContent2}
                                                                    toolTipPlacement='top'
                                                                />
                                                                {!!item.badgeSolidTitle ?
                                                                    <>
                                                                        <CustomTooltip
                                                                            ToolTipTrigger={<BadgeWidget solid={true} title={item.badgeSolidTitle} color='#fff' bgColor={item.badgeSolidColor} />}
                                                                            toolTipContent={item.badgeSolidTooltipContent}
                                                                            toolTipPlacement='top'
                                                                            contentAlign='center'
                                                                        />
                                                                    </>
                                                                    : null}
                                                            </div>
                                                            <div>
                                                                <CustomTooltip
                                                                    ToolTipTrigger={<BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'> {item.userName3} </BodyText>}
                                                                    toolTipContent={item.userNameTooltipContent3}
                                                                    toolTipPlacement='top'
                                                                />
                                                            </div>
                                                            <div>
                                                                <CustomTooltip
                                                                    ToolTipTrigger={<BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'> {item.userName4} </BodyText>}
                                                                    toolTipContent={item.userNameTooltipContent4}
                                                                    toolTipPlacement='top'
                                                                />
                                                            </div>
                                                            <div>
                                                                <CustomTooltip
                                                                    ToolTipTrigger={<BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'> {item.userName5} </BodyText>}
                                                                    toolTipContent={item.userNameTooltipContent5}
                                                                    toolTipPlacement='top'
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    : null}
                                            </td>
                                            <td width='110'>
                                                <div className='d-flex align-items-center justify-content-center mt-1'>
                                                    <div className='ms-1'>
                                                        <CustomTooltip
                                                            ToolTipTrigger={<><span className='gray-label'>{item.comment}</span></>}
                                                            toolTipContent={item.commentTooltip}
                                                            toolTipPlacement='top'
                                                        />
                                                    </div>
                                                    <div className='mx-2'>
                                                        {item.limitedVisibilty ?
                                                            <HiOutlineLockClosed />
                                                            : null}
                                                    </div>
                                                    <div className=''>
                                                        {item.attachment ?
                                                            <HiOutlinePaperClip />
                                                            : null}
                                                    </div>
                                                </div>
                                            </td>
                                            <td width='150'>
                                                <div className='d-flex align-items-center justify-content-center mt-1'>
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BodyText mb='0px' fontSize='12px' fontWeight='400' textColor='#666' pl='5px'> {item.messageTime} </BodyText>}
                                                        toolTipContent={item.messageTimeTooltip}
                                                        toolTipPlacement='top'
                                                    />
                                                </div>
                                            </td>
                                            <td width='50' onClick={(event: React.MouseEvent<HTMLTableCellElement>) => handleClickGraph(event)}>
                                                <div>
                                                    <CustomTooltip
                                                        ToolTipTrigger={<img src={item.image} className='mt-1' alt='graph-icons' />}
                                                        toolTipContent={item.imageToolTip}
                                                        toolTipPlacement='top'
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
}

