import { FC } from "react"
import { OverdueTasksList } from "../ListOfEntries/OverdueTasksList"
import { Card, Dropdown, DropdownButton } from "react-bootstrap"
import { BodyText, DisplayBetween, DisplayStart } from "../StyledComponents"
import { RiDragMove2Line } from "react-icons/ri";


type OverdueTasksProps = {
    title: string,
    badge1: string,
    badgeTooltip: string,
    noBadge: string,
    color: string,
    labelText: string,
    labelColor: string,
}

const OverdueTasks: OverdueTasksProps[] = [
    {
        title: 'Rückinfo 03.06.',
        badge1: 'Zahnärzte',
        badgeTooltip: 'Dr. Sven Markus Gallenbach, ZA Robert Stühn',
        noBadge: '',
        color: 'dark',
        labelText: 'Nicht angenommen',
        labelColor: 'dark',
    },
    {
        title: 'Rückinfo 15.06',
        badge1: 'Zahnärzte',
        badgeTooltip: 'Dr. Sven Markus Gallenbach, ZA Robert Stühn',
        noBadge: '',
        color: 'dark',
        labelText: 'Nicht angenommen',
        labelColor: 'dark',
    },
    {
        title: 'Rückinfo 15.06',
        badge1: 'Zahnärzte',
        badgeTooltip: 'Dr. Sven Markus Gallenbach, ZA Robert Stühn',
        noBadge: '',
        color: 'dark',
        labelText: 'Nicht angenommen',
        labelColor: 'dark',
    },
    {
        title: 'Termin ausmache :-)',
        badge1: '',
        badgeTooltip: 'Zahnarzt - Praxis Gallenbach & Zähne',
        noBadge: 'Dr. Sven Markus Gallenbach',
        color: 'orange',
        labelText: 'Offen',
        labelColor: 'orange',
    },
    {
        title: 'Rückinfo 21.06',
        badge1: 'Zahnärzte',
        badgeTooltip: 'Dr. Sven Markus Gallenbach, ZA Robert Stühn',
        noBadge: '',
        color: 'dark',
        labelText: 'Nicht angenommen',
        labelColor: 'dark',
    },
    {
        title: 'Rückinfo 22.06',
        badge1: 'Zahnärzte',
        badgeTooltip: 'Dr. Sven Markus Gallenbach, ZA Robert Stühn',
        noBadge: '',
        color: 'dark',
        labelText: 'Nicht angenommen',
        labelColor: 'dark',
    },
    {
        title: 'Rückinfo 29.06',
        badge1: 'Zahnärzte',
        badgeTooltip: 'Dr. Sven Markus Gallenbach, ZA Robert Stühn',
        noBadge: '',
        color: 'dark',
        labelText: 'Nicht angenommen',
        labelColor: 'dark',
    },
    {
        title: 'Rückinfo 30.06',
        badge1: 'Zahnärzte',
        badgeTooltip: 'Dr. Sven Markus Gallenbach, ZA Robert Stühn',
        noBadge: '',
        color: 'dark',
        labelText: 'Nicht angenommen',
        labelColor: 'dark',
    },
]




interface IProps {
    isDragMode?: boolean,
    dragHandleProps?: any
}

const OverdueTasksModule: FC<IProps> = ({ dragHandleProps, isDragMode }) => {
    return (
        <>
            <Card className='border-0 rounded-0 b-shadow'>
                <Card.Header className='bg-white'>
                    <DisplayBetween>
                        <BodyText fontSize='18px' textColor='#666' fontWeight='600'>Überfällige Aufgaben</BodyText>
                        <DisplayStart>
                            {isDragMode ?
                                <div {...dragHandleProps}>
                                    <RiDragMove2Line size="18px" color="#666" />
                                </div>
                                :
                                null
                            }
                            <DropdownButton size="sm" className="template-withouticon f-12px ms-2" id="new-item" title={<></>}>
                                <Dropdown.Item>
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Bearbeiten </BodyText>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Löschen </BodyText>
                                </Dropdown.Item>
                            </DropdownButton>
                        </DisplayStart>
                    </DisplayBetween>
                </Card.Header>
                <Card.Body >
                    <OverdueTasksList Data={OverdueTasks} isSeletable={false} />
                </Card.Body>
            </Card>

        </>
    )
}

export default OverdueTasksModule;