import React, { useState, useEffect } from 'react';
import CalendarList from './calendarlist';
import CollapseContainer from './left/collapsecontainer';
import ToolBar from './toolbar/toolbar';
import Klemmbrett from './right/klemmbrett';
import Calendar from './calendar';
import AddEditForm from "./popups/addEditForm";
import { useCookies, withCookies, cookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
// import { dynamicPostApi, dynamicGetApi } from './../Common'
import { useSelector, useDispatch } from 'react-redux'
import { setRerenderCalendar, setRerenderAppointmentData, setAppointmenFormData } from '../../Redux/Slice/calendarSlice';
import moment from 'moment';
import { Helmet } from 'react-helmet';

export class FullCalendarPage extends React.Component {
  constructor(props) {
    super(props);

    var now = new Date();
    this.state = {
      calendar_hidden: 0,
      calendar_collapsed: 0,
      klemmbrett: 0,
      zoomValue: 1,
      show: false,
      isEdit: false,
      callApis: this.calendarApi.bind(this)
    };
    this.setHidden = this.setHidden.bind(this);
    this.setCollapsed = this.setCollapsed.bind(this);
    this.setKlemmbrett = this.setKlemmbrett.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setView = this.setView.bind(this);
    this.setZoomValue = this.setZoomValue.bind(this);
    this.setOpenForm = this.setOpenForm.bind(this);
    this.calendarApi = this.calendarApi.bind(this);
    this.onCalendarEvent = this.onCalendarEvent.bind(this);
    this.moveAppointmentToClipboard = this.moveAppointmentToClipboard.bind(this);
    this.deleteAppFromCalendar = this.deleteAppFromCalendar.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.applySort = this.applySort.bind(this);

    this.state.methods = {
      setHidden: this.setHidden,
      setCollapsed: this.setCollapsed,
      setKlemmbrett: this.setKlemmbrett,
      setDate: this.setDate,
      setView: this.setView,
      setZoom: this.setZoomValue,
      setOpenForm: this.setOpenForm,
      // calendarApi: this.calendarApi
    }
    this.state.calendar = {
      highlights: [
        { "backgroundColor": "#7fff7f", "date": "2021-04-01", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-02", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-03", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-04", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-05", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-06", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-07", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-08", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-09", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-10", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-11", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-12", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-13", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-14", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-15", "description": "Osterferien 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-04-16", "description": "Osterferien 2021" },

        { "backgroundColor": "#7fff7f", "date": "2021-06-03", "description": "Test 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-06-04", "description": "Test 2021" },
        { "backgroundColor": "#7fff7f", "date": "2021-06-05", "description": "Test 2021" },
      ],
      holidays: [
        { "date": "2021-04-02", "description": "Karfreitag 2021", "type": "holiday" },
        { "date": "2021-04-04", "description": "Ostersonntag 2021", "type": "holiday" },
        { "date": "2021-04-05", "description": "Ostermontag 2021", "type": "holiday" },
        { "date": "2021-05-01", "description": "Maifeiertag", "type": "holiday" },
      ],
      _highlights: {
        "2022-08-24": { "backgroundColor": "#7fff7f", "date": "2021-04-01", "description": "Osterferien 2021" },
        "2021-04-02": { "backgroundColor": "#7fff7f", "date": "2021-04-02", "description": "Osterferien 2021" },
        "2021-04-03": { "backgroundColor": "#7fff7f", "date": "2021-04-03", "description": "Osterferien 2021" },
        "2021-04-04": { "backgroundColor": "#7fff7f", "date": "2021-04-04", "description": "Osterferien 2021" },
        "2021-04-05": { "backgroundColor": "#7fff7f", "date": "2021-04-05", "description": "Osterferien 2021" },
        "2021-04-06": { "backgroundColor": "#7fff7f", "date": "2021-04-06", "description": "Osterferien 2021" },
        "2021-04-07": { "backgroundColor": "#7fff7f", "date": "2021-04-07", "description": "Osterferien 2021" },
        "2021-04-08": { "backgroundColor": "#7fff7f", "date": "2021-04-08", "description": "Osterferien 2021" },
        "2021-04-09": { "backgroundColor": "#7fff7f", "date": "2021-04-09", "description": "Osterferien 2021" },
        "2021-04-10": { "backgroundColor": "#7fff7f", "date": "2021-04-10", "description": "Osterferien 2021" },
        "2021-04-11": { "backgroundColor": "#7fff7f", "date": "2021-04-11", "description": "Osterferien 2021" },
        "2021-04-12": { "backgroundColor": "#7fff7f", "date": "2021-04-12", "description": "Osterferien 2021" },
        "2021-04-13": { "backgroundColor": "#7fff7f", "date": "2021-04-13", "description": "Osterferien 2021" },
        "2021-04-14": { "backgroundColor": "#7fff7f", "date": "2021-04-14", "description": "Osterferien 2021" },
        "2021-04-15": { "backgroundColor": "#7fff7f", "date": "2021-04-15", "description": "Osterferien 2021" },
        "2021-04-16": { "backgroundColor": "#7fff7f", "date": "2021-04-16", "description": "Osterferien 2021" },

        "2021-05-02": { "backgroundColor": "#7fff7f", "date": "2021-04-16", "description": "Osterferien 2021" },
        "2021-06-02": { "backgroundColor": "#7fff7f", "date": "2021-04-16", "description": "Osterferien 2021" },
      },
      _holidays: {
        "2022-08-24": { "description": "Karfreitag 2021", "type": "holiday" },
        "2021-04-04": { "description": "Ostersonntag 2021", "type": "holiday" },
        "2021-04-05": { "description": "Ostermontag 2021", "type": "holiday" },
        "2021-05-01": { "description": "Maifeiertag", "type": "holiday" },
      },
      _fulldays: {
        "2022-08-24": [{ "id": 1, "description": "Kiel holen", "date": "2021-04-02", "backgroundColor": "#2d08db", "sections": [1, 2] }, { "id": 2, "description": "Kiel holen2", "date": "2021-04-02", "backgroundColor": "#08dbd9", "sections": [2, 4] }],
        "2021-05-02": [{ "id": 3, "description": "Kiel holen", "date": "2021-05-02", "backgroundColor": "#db0808", "sections": [1, 4] }],
        "2021-06-02": [{ "id": 4, "description": "Kiel holen", "date": "2021-06-02", "border": "#000000", "backgroundColor": "#37add2", "color": "#ffffff", "sections": [1, 2] }],

        "2021-06-03": [
          { "id": 5, "description": "descr1", "date": "2021-06-03", "border": "000000", "backgroundColor": "#1e05a7", "color": "#ffffff", "sections": [1, 2] },
          { "id": 6, "description": "descr2", "date": "2021-06-03", "border": "000000", "backgroundColor": "#17a705", "color": "#ffffff", "sections": [2] },
          { "id": 7, "description": "descr3", "date": "2021-06-03", "border": "000000", "backgroundColor": "#ed6f19", "color": "#ffffff", "sections": [2] },
          { "id": 8, "description": "descr4", "date": "2021-06-03", "border": "#000000", "backgroundColor": "#ffffff", "color": "#000000", "sections": [1, 2] },
        ],
      },
      fulldays: [
        { "id": 1, "description": "Kiel holen", "date": "2021-04-02", "backgroundColor": "#2d08db", "sections": [1, 2] }, { "id": 2, "description": "Kiel holen2", "date": "2021-04-02", "backgroundColor": "#08dbd9", "sections": [2, 4] },

        { "id": 1, "description": "Kiel holen", "date": "2021-04-02", "backgroundColor": "#2d08db", "sections": [1, 2] }, { "id": 2, "description": "Kiel holen2", "date": "2021-04-02", "backgroundColor": "#08dbd9", "sections": [2, 4] },
        { "id": 3, "description": "Kiel holen", "date": "2021-05-02", "backgroundColor": "#db0808", "sections": [1, 4] },
        { "id": 4, "description": "Kiel holen", "date": "2021-06-02", "border": "#000000", "backgroundColor": "#37add2", "color": "#ffffff", "sections": [1, 2] },

        { "id": 5, "description": "descr1", "date": "2021-06-03", "border": "000000", "backgroundColor": "#1e05a7", "color": "#ffffff", "sections": [1, 2] },
        { "id": 6, "description": "descr2", "date": "2021-06-03", "border": "000000", "backgroundColor": "#17a705", "color": "#ffffff", "sections": [2] },
        { "id": 7, "description": "descr3", "date": "2021-06-03", "border": "000000", "backgroundColor": "#ed6f19", "color": "#ffffff", "sections": [2] },
        { "id": 8, "description": "descr4", "date": "2021-06-03", "border": "#000000", "backgroundColor": "#ffffff", "color": "#000000", "sections": [1, 2] },

        { "id": 9, "description": "descr5", "date": "2021-06-04", "border": "#000000", "backgroundColor": "#ffffff", "color": "#000000", "sections": [] },
      ],
      _behandlungszeit: [],
      behandlungszeit: [],
      _appointments: [],
      appointments: [],
      _reserviert: [],
      reserviert: [],
      date: this.props.date,
      view: "day",
      sections: [],
      _sections: [],
      daystart: 6,
      dayend: 20

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.reCallCalendarApis === true) {
      nextProps.setCookie('reCallCalendarApis', false);
      prevState.callApis();
      return false;
    }
    if (nextProps.reRenderCalendar === true) {
      nextProps.dispatch(setRerenderCalendar(false));
      prevState.callApis();
      return null;
    }
    if (!!nextProps.selectedPractitioner && nextProps.selectedPractitioner !== "all") {
      let selSec = nextProps.selectedPractitioner;
      let selSecArr = selSec.split(',');
      let preSection = [...prevState.calendar._sections];
      let newSecArr = [];
      selSecArr.map((x) => preSection.find(y => y.id === parseInt(x) ? newSecArr.push(y) : null));
      console.log(newSecArr);
      nextProps.setCookie('mitarbeiterFilter', '');
      return {
        calendar: {
          ...prevState.calendar,
          sections: newSecArr
        }
      };
    }
    if (nextProps.selectedPractitioner === "all") {
      let preSection = [...prevState.calendar._sections];
      return {
        calendar: {
          ...prevState.calendar,
          sections: preSection,
          date: nextProps.date
        }
      };
    }
    return {
      calendar: {
        ...prevState.calendar,
        date: nextProps.date
      }
    };
  }

  componentDidMount() {
    var self = this;
    this.calendarApi();
  }

  componentWillUnmount() {
    var self = this;

  }
  setOpenForm(val) {
    this.setState({
      show: val
    })
  }
  setZoomValue(val) {
    this.setState({
      zoomValue: val
    })
  }

  onCalendarEvent = async(name, params) => {
    switch (name) {

      case 'onHeaderClick': {
        console.log("params: " + JSON.stringify(params));

        let date = new Date(params.year, params.month - 1, params.date);

        this.state.calendar.date = date
        this.state.calendar.view = "day";

        this.setState(this.state.calendar);
        break;
      }
      case 'onSectionHeaderClick': {
        console.log("params: " + JSON.stringify(params));

        let date_string = params.year + "-" + ("0" + params.month).slice(-2) + "-" + ("0" + params.date).slice(-2);
        let fullday = {
          "id": 10,
          "description": "crazy day",
          "date": date_string,
          "border": "#000000",
          "backgroundColor": "#ffffff",
          "color": "#000000",
          "sections": [parseInt(params.section)]
        };
        this.state.calendar.fulldays.push(fullday);
        
        this.setState(this.state.calendar.fulldays);
        break;
      }
      case 'onFulldayClick': {
        console.log("params: " + JSON.stringify(params));

        if (params.type == "fullday") {
          this.state.calendar.fulldays.splice(params.index, 1);

          this.setState(this.state.calendar.fulldays);
        }
        if (params.type == "highlight") {
          this.state.calendar.highlights.splice(params.index, 1);

          this.setState(this.state.calendar.highlights);
        }
        break;
      }
      case 'onSpalteClick': {
        let date_string = params.year + "-" + ("0" + params.month).slice(-2) + "-" + ("0" + params.date).slice(-2);
        let startTime = moment().startOf('day').add(params.time, 'minutes');
        let endTime = moment().startOf('day').add(params.time + 60, 'minutes');
        let data = { date: new Date(date_string), practitionerId: params.section, startTime: startTime._d, endTime: endTime._d };
        this.props.dispatch(setAppointmenFormData(data));
        // let event = {
        //   date: date_string,

        //   id: 1,
        //   app_type: 0,
        //   section: params.section,

        //   title: "PZR 60",
        //   patient: "Anonym",
        //   behandler: "Greifenberg, Wolfgang",
        //   zimmer: "Prophylaxe",
        //   kategorie: "Bestell-Patient",

        //   von_min: params.time,
        //   bis_min: params.time + 60,

        //   color: "#FFF2CC",
        //   border_color: "#006666",
        //   vorbereitung: 0,
        //   nachbereitung: 0,
        // };
        // this.state.calendar.appointments.push(event);

        // this.setState(this.state.calendar.appointments);
        break;
      }

      case 'onEventClick': {
        console.log("params: " + JSON.stringify(params));

        this.state.calendar.appointments.splice(params.index, 1);

        this.setState(this.state.calendar.appointments);
        break;
      }
      case 'onEventMove': {
        debugger
        let item = this.state.calendar.appointments[params.index]
        let length = item.bis_min - item.von_min;
        let date_string = params.year + "-" + ("0" + params.month).slice(-2) + "-" + ("0" + params.date).slice(-2);
        // section autoreszing fixing on event move
        item.section = params.section;
        item.von_min = params.newFrom;
        item.bis_min = item.von_min + length;
        item.date = date_string;

        this.setState(prevState => ({
          calendar: {                   // object that we want to update
          ...prevState.calendar, 
          appointments:{
            section: params.section,
            von_min :  params.newFrom,
            bis_min : item.von_min + length,
            date : date_string
          },   // keep all other key-value pairs
            // update the value of specific key
          }
        }))

        let data = { appId: item.id, start: params.newFrom, end: item.von_min + length, date: date_string, practitionerId: params.section }
        let url = 'online-terminbuch/clinicCalendar/dragdrop';
        // let res = await dynamicPostApi(data, url, this.props.navigate, this.props.setCookie);

        // if (res.data) {
        //   this.calendarApi();
        // }

        // this.setState(prevState => ({
        //   calendar: {
        //     ...prevState.calendar,
        //     appointments: prevState.calendar.appointments.map((x, index) => {
        //       if (index === parseInt(params.index)) {
        //         return {
        //           ...x,
        //           section: params.section,
        //           von_min: params.newFrom,
        //           bis_min: item.von_min + length,
        //           date: date_string
        //         }
        //       }
        //       return x
        //     }),
        //   },
        // }));
        break;
      }
      case 'onEventResize': {
        console.log("check for event resize ")
        let item = this.state.calendar.appointments[params.index]
        //item.section = params.section;
        //item.von_min = params.newFrom;
        let bis_min = item.von_min + params.newLength;
        if (item.bis_min == bis_min) {
          item.bis_min = bis_min + 1;
        } else {
          item.bis_min = bis_min;
        }
        // let url = 'online-terminbuch/clinicCalendar/appointmentBlockResize?appId=' + item.id + '&endTime=' + item.bis_min;
        // let res = dynamicGetApi(url, this.props.navigate, this.props.setCookie)
        this.setState(this.state.calendar.appointments);
        this.props.dispatch(setRerenderAppointmentData(true));
        break;
      }
    }

  }

  setHidden(val) {
    console.log("setHidden: ");
    this.setState(state => ({
      calendar_hidden: val,
    }));
  }
  setCollapsed(val) {
    console.log("setCollapsed: ");
    this.setState(state => ({
      calendar_collapsed: val,
    }));
  }
  setKlemmbrett(val) {
    console.log("setKlemmbrett: ");
    this.setState(state => ({
      klemmbrett: val,
    }));
  }

  setDate(val) {
    var date;
    if (val == "today") {
      date = new Date();
    }
    else if (val == "prev") {
      if (this.state.calendar.view == "day") {
        date = this.state.calendar.date.addDays(-1);
      }
      else if (this.state.calendar.view == "week") {
        date = this.state.calendar.date.addDays(-7);
      }
    }
    else if (val == "next") {
      if (this.state.calendar.view == "day") {
        date = this.state.calendar.date.addDays(+1);
      }
      if (this.state.calendar.view == "week") {
        date = this.state.calendar.date.addDays(+7);
      }
    }
    else {
      date = val;
    }
    // let newState = Object.assign({}, this.state);
    // newState.calendar.date = date;
    // this.setState(newState);
    // this.setState({
    //   calendar: {
    //     ...this.state.calendar,
    //     date: date }
    // });
    this.props.setCookie('calendarSelectDate', date);
  }

  setView(val) {
    console.log("setView: ");
    let newState = Object.assign({}, this.state);
    newState.calendar.view = val;
    console.table(newState)
    this.setState(newState);
  }

  tooltip(visible, mouseover) {
    return <div id="ds-qtip-body" className={visible ? "ds-qtip-body ds-mobile-modal" : "ds-qtip-body"}>
      <div id="ds-qtip-container" className="ds-qtip-container">
        {visible &&
          <div id="qtip-1" className={mouseover ? "qtip qtip-default qtip-bootstrap qtip-pos-bc qtip-focus qtip-hover" : "qtip qtip-default qtip-bootstrap qtip-pos-bc qtip-focus"} tracking="false" role="alert" aria-live="polite" aria-atomic="false" aria-describedby="qtip-1-content" aria-hidden="false" data-qtip-id="1"
            //style="z-index: 15001; top: 57px; left: 289.351px; opacity: 1; display: block;"
            style={{
              zIndex: 15001,
              top: 57 + "px",
              left: 289.351 + "px",
              opacity: 1,
              display: "block",
            }}
          >
            <div className="qtip-tip"
              //style="background-color: transparent !important; border: 0px !important; width: 8px; height: 8px; line-height: 8px; left: 50%; margin-left: -4px; bottom: -8px;"
              style={{
                backgroundColor: "transparent !important",
                border: 0 + "px !important",
                width: 8 + "px",
                height: 8 + "px",
                lineHeight: 8 + "px",
                left: "50%",
                marginLeft: -4 + "px",
                bottom: -8 + "px"
              }}
            >
              <canvas width="8" height="8"
                //style="background-color: transparent !important; border: 0px !important;"
                style={{
                  backgroundColor: "transparent !important",
                  border: 0 + "px !important",
                }}
              ></canvas>
            </div>
            <div className="qtip-titlebar">
              <div id="qtip-1-title" className="qtip-title" aria-atomic="true">Ferien</div>
              <a className="qtip-close qtip-icon" title="Schließen" aria-label="Schließen" role="button">
                <span className="ui-icon ui-icon-close">×</span>
              </a>
            </div>
            <div className="qtip-content" id="qtip-1-content" aria-atomic="true">
              <div data-view-id="wWyLalDTRqekUicnJxb-aA" data-ds-rt="tooltip" data-ds-rid="1000004691_tooltip" id="wMpYlZYHSl-c6swcwYaz0Q">
                <div lang="de" data-ds-rt="view" data-ds-rid="allgemeinTooltipView" id="wzcw4BNTQ_60fuUyJpd6lw">
                  <div data-ds-rt="verticalPanel" data-ds-rid="resourceToolTip" id="whF5VTKtTd6P9aAzrTNwHg">
                    <div><span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="1000004691_beschreibungLabel" id="wobYnMbAQoieOrCR7l9EZA">Osterferien 2021</span></div>
                    <div><span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="1000004691_datumsLabel" id="wlO7x6AeTk2-cTter4jyhQ">01.04.2021 - 16.04.2021</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>;
  }

  calendarApi = async () => {
    // let url = "online-terminbuch/getAllAppointment";
    // let res = await dynamicGetApi(url, this.props.navigate, this.props.setCookie);
    // let url1 = "online-terminbuch/getAllReservation";
    // let res1 = await dynamicGetApi(url1, this.props.navigate, this.props.setCookie);
    // var practitionerListUrl = "online-terminbuch/role/getAllUserByRoleName?roleName=Practitioner";
    // var res2 = await dynamicGetApi(practitionerListUrl, this.props.navigate, this.props.setCookie);
    // var practitionerAvailabilityUrl = "online-terminbuch/clinicCalendar/practitionerAvailability";
    // var res3 = await dynamicGetApi(practitionerAvailabilityUrl, this.props.navigate, this.props.setCookie);
    // let sections = [];
    // res2.data.map((x, index) => sections.push({ section: index, id: x.id, label: x.firstName }));
    // this.setState({
    //   calendar: {
    //     ...this.state.calendar,
    //     appointments: res.data,
    //     _appointments: res.data,
    //     reserviert: res1.data,
    //     _reserviert: res1.data,
    //     sections: sections,
    //     _sections: sections,
    //     behandlungszeit: res3.data,
    //     _behandlungszeit: res3.data,
    //   }
    // })
  }

  moveAppointmentToClipboard = async (e) => {
    let url = "online-terminbuch/caldendarToClipboard?appId=" + e;
    // let res = await dynamicGetApi(url, this.props.navigate, this.props.setCookie)
    this.props.setCookie('reCallApis', true);
    this.props.setCookie('reCallCalendarApis', true);
  }
  deleteAppFromCalendar = async (e) => {
    let url = "online-terminbuch/deleteAppointmentFromCalendar?appId=" + e;
    // let res = await dynamicGetApi(url, this.props.navigate, this.props.setCookie);
    this.props.setCookie("reCallApis", true);
    this.props.setCookie("reCallCalendarApis", true);
  }

  applyFilter = (val) => {
    let filterData;
    if (val === 0) {
      filterData = this.state.calendar.appointments;
    }
    else if (val === 1) {
      filterData = this.state.calendar.appointments.sort((a, b) => b.behandler - a.behandler);
    }
    else if (val === 2) {
      filterData = this.state.calendar.appointments.sort((a, b) => b.zimmer - a.zimmer);
    }
    else if (val === 3) {
      filterData = this.state.calendar.appointments.sort((a, b) => b.kategorie - a.kategorie);
    }
    else {
      filterData = this.state.calendar.appointments.sort((a, b) => b.date - a.date);
    }
    this.setState({
      calendar: {
        ...this.state.calendar,
        appointments: filterData
      }
    });
  }

  applySort = (val) => {
    let filterData;
    if (val === 0) {
      filterData = this.state.calendar.appointments.sort((a, b) => b.date - a.date);
    }
    else if (val === 1) {
      filterData = this.state.calendar.appointments.sort();
    }
    else {
      filterData = this.state.calendar.appointments.sort((a, b) => b - a);
    }
    this.setState({
      calendar: {
        ...this.state.calendar,
        appointments: filterData
      }
    });
  }

  render() {
    return <>
      <Helmet>
        <link rel="stylesheet" href="/css/colors.css" />
        <link rel="stylesheet" href="/css/main.css" />
        <link rel="stylesheet" href="/css/calendar.css" />
        <link rel="stylesheet" href="/css/datepicker.css" />
        <link rel="stylesheet" href="/css/media.css" />
      </Helmet>
      <div>
        <div id="main-content" data-ajax-url="/online-terminbuch/startmenu_ajax" className="ds-is-loading">
          <div data-hascontrolparamschangehandler="true" lang="de" data-ds-rt="view" data-ds-rid="Main" id="wDeAsdG8S6a3HKE2WB9ZzQ">
            <div className="ds-grid ds-grid-spacing-sm" data-ds-rt="grid" data-ds-rid="grid1" id="wGbw73WbSk6YlTzIwIAlTA">

              <div className="row controls controls-row"></div>

              <div className="row controls controls-row tollbar ds-grid-spacing-none">
                <div className="ds-cell col-xs-12 col-sm-12 col-md-12 col-lg-12">

                  <ToolBar methods={this.state.methods} klemmbrett={this.state.klemmbrett} setShow={this.setOpenForm} filter={this.applyFilter} sort={this.applySort}></ToolBar>


                </div>
              </div>

              <div className="row controls controls-row ds-grid-spacing-none">
                <div className="ds-cell col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="ds-messagebar-fixed" data-ds-rt="messageBar" data-ds-rid="callBackMB" id="wNcoolPjTZ2jVaeUFXP2xA"
                    //style="display: none;"
                    style={{
                      display: "none",
                    }}
                  >
                    <div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row controls controls-row">
                <div className="ds-cell col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div
                    //style="align-items:stretch;" 
                    style={{
                      alignItems: "stretch",
                    }}
                    className="ds-fb ds-fd-r ds-fb-nw ds-fb-si ds-fb-ex ds-collapseable-parent" data-ds-rt="flexPanel" data-ds-rid="horizontalFlexPanel" id="w5MIbREtQCihb9DjJataow">

                    <CollapseContainer collapse_hidden={false} collapsed={false} view={this.state.calendar.view} ></CollapseContainer>



                    {true && <Calendar
                      fog={false}
                      zoomValue={this.state.zoomValue}
                      highlights={this.state.calendar.highlights}
                      holidays={this.state.calendar.holidays}
                      fulldays={this.state.calendar.fulldays}
                      date={this.state.calendar.date}
                      view={this.state.calendar.view}
                      sections={this.state.calendar.sections}
                      behandlungszeit={this.state.calendar.behandlungszeit}
                      reserviert={this.state.calendar.reserviert}
                      appointments={this.state.calendar.appointments}
                      daystart={this.state.calendar.daystart}
                      dayend={this.state.calendar.dayend}
                      onCalendarEvent={this.onCalendarEvent}
                      setShow={this.setOpenForm}
                      moveAppToClipBorad={this.moveAppointmentToClipboard}
                      deleteAppFromCalendar={this.deleteAppFromCalendar}
                      dispatch={this.props.dispatch}
                    />}

                    {false && <CalendarList></CalendarList>}

                    <Klemmbrett visible={this.state.klemmbrett}></Klemmbrett>


                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="ds-loading-bar">
            <div className="progress active ds-progress-fadein">
              <div className="progress-bar"
                style={{
                  width: "100%",
                }}
              ></div>
            </div>
          </div>
        </div>

        {this.tooltip(false, false)}

      </div>;
      {/* <AddEditForm open={this.state.show} setOpen={this.setOpenForm} /> */}
    </>


  }
}

export default function CalendarPageFunComp() {
  let dispatch = useDispatch();
  let checking = useSelector(state => state.calendar.checkrerenderCalendar);
  const [cookies, setCookie, removeCookie] = useCookies(['appToken', 'calendarSelectDate', 'reCallApis', 'reCallCalendarApis', 'mitarbeiterFilter', 'reRenderCalendar']);
  let navigate = useNavigate();
  const [dateSel, setDateSel] = useState(new Date());
  const [recallApis, setRecallApis] = useState(false);
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  const [reRenderCalendar, setReRenderCalendar] = useState(false);

  const setcookiesss = (name, value) => {
    //debugger
    setCookie(name, value, { path: '/' })
  }

  useEffect(() => {
    //debugger
    if (!!cookies.calendarSelectDate) {
      setDateSel(new Date(cookies.calendarSelectDate));
    }
  }, [cookies.calendarSelectDate]);

  useEffect(() => {
    if (cookies.reCallCalendarApis === 'true') {
      setRecallApis(true);
    }
    else {
      setRecallApis(false);
    }
  }, [cookies.reCallCalendarApis]);

  useEffect(() => {
    if (!!cookies.mitarbeiterFilter) {
      setSelectedPractitioner(cookies.mitarbeiterFilter);
    }
    else {
      setSelectedPractitioner(null);
    }
  }, [cookies.mitarbeiterFilter]);
  return (
    <FullCalendarPage dispatch={dispatch} reRenderCalendar={checking} navigate={navigate} setCookie={setCookie} date={dateSel} reCallCalendarApis={recallApis} removeCookie={removeCookie} selectedPractitioner={selectedPractitioner} />
  )
}
