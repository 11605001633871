import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, } from 'react-bootstrap';
import SelectTemplateModal from '../../Components/Modals/SelectTemplateModal';
import AddTemplateModal from '../../Components/Modals/AddTemplateModal';
import Recipient from '../../Components/Modals/Recipient';
import AbortAlrt from '../../Components/Modals/AbortAlrt';
import EditorConvertToMarkdown from '../../Components/TextEditor';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import Badge from 'react-bootstrap/Badge';
import ViewRecipient from '../../Components/ViewRecipient';
import AddMediaModal from '../../Components/Modals/AddMediaModal';
import ModelActions from '../../Components/ModelActions';
import Tagify from '../../Components/Tagify';
import { ModalHeader } from '../../Components/StyledComponents';
import ChooseTemplateCategoryModal from '../../Components/Modals/ChooseTemplateCategoryModal';
import ManageTemplateCategoryModal from '../../Components/Modals/ManageTemplateCategoryModal';



interface ideaTypes {
    showIdea: boolean,
    hideIdea: () => void,
}

function AddEditIdea(props: ideaTypes) {
    // State for modals Begin
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    const [AlertModal, setAlertModal] = useState<boolean>(false);
    const [selectTemplateModal, setSelectTemplateModal] = useState<boolean>(false);
    const [AddTemplateModalShow, setAddTemplateModalShow] = useState<boolean>(false);
    const [AddMediaModalShow, setAddMediaModalShow] = useState<boolean>(false);
    const [chooseTempCategoryModal, setChooseTempCategoryModal] = useState<boolean>(false);
    const [manageTempCategoryModal, setManageTempCategoryModal] = useState(false);
    // State for modals End

    // State for Hide Show Begin
    const [AddUser, setAddUser] = useState<boolean>(true);
    const [NonPublic, setNonPublic] = useState<boolean>(false);
    const [TextEditorShow, setTextEditorShow] = useState<boolean>(false);
    const [HideFont, setHideFont] = useState<boolean>(false);
    const [TagifyShow, setTagifyShow] = useState<boolean>(false);
    // State for Hide Show Begin


    const HandleFontClick = () => {
        setTextEditorShow(true);
        setHideFont(true);
    }

    return (
        <>

            <Modal size="lg" show={props.showIdea} onHide={props.hideIdea} backdrop="static" keyboard={false} aria-labelledby="example-modal-sizes-title-lg" >
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Idee erstellen
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body className='pb-0'>
                    <Row>
                        <Col md={9}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Titel" />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Button className='default-btn f-12px w-100' onClick={() => setSelectTemplateModal(true)}>Vorlage auswählen</Button>
                        </Col>
                    </Row>
                    <div className='border-top border-bottom py-2'>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="">
                                    <Form.Check
                                        inline
                                        label="Öffentlich"
                                        name="group1"
                                        type="radio"
                                        id="public"
                                        onChange={() => { setAddUser(true); setNonPublic(false) }}
                                    />
                                    <Form.Check
                                        inline
                                        label="Nicht öffentlich"
                                        name="group1"
                                        type="radio"
                                        id="notpublic"
                                        onChange={() => { setAddUser(true); setNonPublic(true) }}
                                    />
                                    <Form.Check
                                        inline
                                        label=" An alle Nutzer"
                                        name="group1"
                                        type="radio"
                                        id="toalluser"
                                        onChange={() => { setAddUser(false); setNonPublic(false) }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                {NonPublic ?
                                    <div>
                                        <NonPublicNews />
                                    </div>
                                    : null
                                }
                            </Col>
                        </Row>
                    </div>
                    {AddUser ?
                        <div className='mt-3'>
                            <ViewRecipient showModel={() => setRecipientModal(true)} Palaceholder="Empfänger" BtnText="Alle Empfänger anzeigen" />
                        </div>
                        : null
                    }
                    <Row className='mt-3'>
                        <Col md={12}>
                            <div className='w-50 d-flex align-items-baseline'>
                                <Form.Label>Kategorie</Form.Label>
                                <Form.Select size="sm" className="mb-3 ms-3">
                                    <option value="" selected={true}></option>
                                    <option value="Behandlung">Behandlung</option>
                                    <option value="Hygiene">Hygiene</option>
                                    <option value="Marketing">Marketing</option>
                                    <option value="Patienten">Patienten</option>
                                    <option value="Qualitätsmanagement">Qualitätsmanagement</option>
                                    <option value="Sonstiges">Sonstiges</option>
                                    <option value="Team, Aus- und Fortbildung">Team, Aus- und Fortbildung</option>
                                    <option value="Verwaltung Organisation">Verwaltung Organisation</option>
                                </Form.Select>
                            </div>
                            <div>
                                {TextEditorShow ?
                                    <div className="mb-3">
                                        <EditorConvertToMarkdown />
                                    </div>
                                    :
                                    <div className="mb-3">
                                        <Form.Control as="textarea" placeholder="Text" rows={5} />
                                    </div>
                                }
                            </div>
                            <div>
                                <ModelActions
                                    ImgClick={() => setAddMediaModalShow(true)}
                                    FontClick={() => HandleFontClick()}
                                    isHideFont={HideFont}
                                    TagifyClick={() => setTagifyShow(!TagifyShow)}
                                />
                                {TagifyShow ?
                                    <div className='my-3 tagify'>
                                        <Tagify />
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="mb-3">
                                <Form.Check
                                    label="Erlaube Empfängern den Eintrag zu bearbeiten."
                                    name="group1"
                                    type="checkbox"
                                    id="public"
                                />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='justify-content-end footer-sticky'>
                    <div>
                        <Button className='default-btn f-14px me-1'>
                            Entwurf speichern
                        </Button>
                        <Button className='default-btn f-14px me-1' onClick={() => setAddTemplateModalShow(true)}>
                            Als Vorlage hinzufügen
                        </Button>
                        <Button className='default-btn f-14px me-1' onClick={() => setAlertModal(true)}>
                            Abbrechen
                        </Button>
                        <Button className='primary-bg f-14px'>
                            Erstellen
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <SelectTemplateModal
                displayTemplateModal={selectTemplateModal}
                hideTemplateModal={() => setSelectTemplateModal(false)}
            />
            <AddTemplateModal
                displayAddTemplateModal={AddTemplateModalShow}
                hideAddTemplateModal={() => setAddTemplateModalShow(false)}
                chooseCategoryClickHandler={() => setChooseTempCategoryModal(true)}
                recipientClickHandler={() => setRecipientModal(true)}
            />

            {/* ====== Inner Modal of Add Template modal Begin ====== */}
            <ChooseTemplateCategoryModal
                displayCategoryModal={chooseTempCategoryModal}
                hideCategoryModal={() => setChooseTempCategoryModal(false)}
                manageCategoriesClickHandler={() => setManageTempCategoryModal(true)}
            />
            <ManageTemplateCategoryModal
                displayCategoriesTemplateModal={manageTempCategoryModal}
                modalTitle="Vorlagenkategorien verwalten"
                hideCategoriesTemplateModal={() => setManageTempCategoryModal(false)}
            />
            {/* ====== Inner Modal of Add Template modal Begin ====== */}

            <Recipient
                displayRecipientModal={RecipientModal}
                hideRecipientModal={() => setRecipientModal(false)}
            />
            <AbortAlrt
                displayAlertModal={AlertModal}
                hideAlertModal={() => setAlertModal(false)}
            />
            <AddMediaModal
                displayAddMediaModal={AddMediaModalShow}
                hideAddMediaModal={() => setAddMediaModalShow(false)}
            />
        </>
    );
}

const NonPublicNews = () => {
    return (
        <>
            <div className='d-flex flex-wrap align-items-center'>
                <FaInfoCircle className='me-1' />
                <span className='me-1'>Nicht öffentliche News können immer gelesen werden von </span>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Alicia Gojani, Diana Lauser, Dr. Sven Markus Gallenbach, Nadine Häring, Thomas Planitzer & Team</Tooltip>}>
                    <Badge className='bg-dark f-12px me-1'>Admin</Badge>
                </OverlayTrigger>
                <Badge className='badge-light-blue f-12px me-1'>QM</Badge>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Alicia Gojani, Diana Lauser, Dr. Sven Markus Gallenbach, Nadine Häring</Tooltip>}>
                    <Badge className='badge-dark-blue f-12px me-1'>medikit Champions</Badge>
                </OverlayTrigger>
            </div>
        </>
    )
}



export default AddEditIdea;