import { FC } from "react";
import { BodyText, Box, DisplayEnd, DisplayStart } from "../../../Components/StyledComponents";
import ButtonWidget from "../../../Components/ButtonWidget";
import { Col, Row } from "react-bootstrap";
import { CheckControl } from "../../../Components/Widgets/InputControls/CheckControl";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaGalacticSenate, FaInfoCircle } from "react-icons/fa";
import CustomTooltip from "../../../Components/Tooltip";
import { InputTextControl } from "../../../Components/Widgets/InputControls/InputTextControl";
import FormSelect from "../../../Components/FormSelect";
import { footerLinkOptions, headerLinkOptions } from "../../../CommonJSON";


interface IProps { }
export const PDFSetting: FC<IProps> = ({ }) => {
    const { control, register, handleSubmit, formState: { errors, defaultValues }, reset, formState, watch, } = useForm();
    return (
        <>
            <h2>PDF-EinsteIIungen</h2>
            <p>Standardeinstellungen und Design aller Ausdrucke als PDF Version ändern</p>
            <div className="mt-2">
                <h4>Inhalte festlegen</h4>
            </div>
            <Row>
                <Col md={4} className="mt-2">
                    <h6 className="mb-3">NACHRICHTEN</h6>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"titel"}
                            value="titel"
                            label="Titel"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"creator"}
                            value="creator, recipient and details"
                            label="Ersteller, Empfänger und Details"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"readUnread"}
                            value="Read and unread"
                            label="Gelesen und ungelesen"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"opinionPoll"}
                            value="Opinion poll"
                            label="Umfrage"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"comments"}
                            value="Comments"
                            label="Kommentare"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>

                </Col>
                <Col md={4} className="mt-2">
                    <h6 className="mb-3">NEWS</h6>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"titel"}
                            value="titel"
                            label="Titel"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"creator"}
                            value="creator, recipient and details"
                            label="Ersteller, Empfänger und Details"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"readUnread"}
                            value="Read and unread"
                            label="Gelesen und ungelesen"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"opinionPoll"}
                            value="Opinion poll"
                            label="Umfrage"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"comments"}
                            value="Comments"
                            label="Kommentare"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                </Col>
                <Col md={4} className="mt-2">
                    <h6 className="mb-3">IDEEN</h6>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"titel"}
                            value="titel"
                            label="Titel"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"creator"}
                            value="creator, recipient and details"
                            label="Ersteller, Empfänger und Details"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"readUnread"}
                            value="Read and unread"
                            label="Gelesen und ungelesen"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"opinionPoll"}
                            value="Opinion poll"
                            label="Umfrage"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"comments"}
                            value="Comments"
                            label="Kommentare"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={4} className="mt-2">
                    <h6 className="mb-3">HANDBUCH</h6>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"titel"}
                            value="titel"
                            label="Titel"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"category"}
                            value="category"
                            label="Kategorie"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"text"}
                            value="text"
                            label="Text"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"attachments"}
                            value="attachments"
                            label="Liste der Anhänge"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"readUnread"}
                            value="Read and unread"
                            label="Gelesen und ungelesen"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>

                </Col>
                <Col md={4} className="mt-2">
                    <h6 className="mb-3">AUFGABEN</h6>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"creator"}
                            value="creator, recipient and details"
                            label="Ersteller, Empfänger und Details"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"recipient"}
                            value="Status for each recipient"
                            label="Status für jeden Empfänger"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"text"}
                            value="Text"
                            label="Text"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"titel"}
                            value="Comments"
                            label="Kommentare"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                </Col>
                <Col md={4} className="mt-2">
                    <h6 className="mb-3">KALENDER</h6>
                    <Box className='mb-3'>
                        <DisplayStart className="calendar-pdf-checks remove-mr-formcheck">
                            <CheckControl
                                fieldType={"checkbox"}
                                control={control}
                                fieldName={"time"}
                                value="Time window"
                                label="Zeitfenster"
                                isInline={true}
                                disabled={false}
                                defaultChecked={false}
                            />
                            <InputTextControl
                                control={control}
                                fieldName={"startTime"}
                                fieldType="text"
                                size={"sm"}
                            />
                            <span>:00 -</span>
                            <InputTextControl
                                control={control}
                                fieldName={"endTime"}
                                fieldType="text"
                                size={"sm"}
                            />
                            <span className="me-2">:00</span>
                            <CustomTooltip
                                ToolTipTrigger={<FaInfoCircle />}
                                toolTipContent="Nur bei Wochenansicht verfügbar"
                                toolTipPlacement="top"
                            />
                        </DisplayStart>
                    </Box>
                    <Box className='mb-3'>
                        <DisplayStart>
                            <CheckControl
                                fieldType={"checkbox"}
                                control={control}
                                fieldName={"daysSeparately"}
                                value="Show all days separately"
                                label="Ganztägige separat zeigen"
                                isInline={true}
                                disabled={false}
                                defaultChecked={false}
                            />
                            <CustomTooltip
                                ToolTipTrigger={<FaInfoCircle />}
                                toolTipContent="Nur bei Wochenansicht verfügbar"
                                toolTipPlacement="top"
                            />
                        </DisplayStart>

                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"legend"}
                            value="Legende"
                            label="Legende"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box className='mb-3'>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"term"}
                            value="Term list"
                            label="Terminliste"
                            isInline={true}
                            disabled={false}
                            defaultChecked={false}
                        />
                    </Box>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={12} className="mt-2">
                    <div>
                        <h4>Kopf- und Fußzeile einstellen</h4>
                    </div>
                </Col>
                <Col md={1} className="mt-3 align-self-end">
                    <BodyText mb="10px">Kopfzeile</BodyText>
                </Col>
                <Col md={3} className="mt-3">
                    <BodyText textAlign="center" mb='5px'>Links</BodyText>
                    <Box>
                        <FormSelect
                            isFormGroup={false}
                            selectOptions={headerLinkOptions}
                            Size='sm'
                            Name='headerLinks'
                            Id='salute'
                        />
                    </Box>
                </Col>
                <Col md={3} className="mt-3">
                    <BodyText textAlign="center" mb='5px'>Mitte</BodyText>
                    <Box>
                        <FormSelect
                            isFormGroup={false}
                            selectOptions={headerLinkOptions}
                            Size='sm'
                            Name='headerNot'
                            Id='salute'
                        />
                    </Box>
                </Col>
                <Col md={3} className="mt-3">
                    <BodyText textAlign="center" mb='5px'>Rechts</BodyText>
                    <Box>
                        <FormSelect
                            isFormGroup={false}
                            selectOptions={headerLinkOptions}
                            Size='sm'
                            Name='headerRight'
                            Id='salute'
                        />
                    </Box>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={1}>
                    <BodyText>Fußzeile</BodyText>
                </Col>
                <Col md={3}>
                    <Box className='mb-3'>
                        <FormSelect
                            isFormGroup={false}
                            selectOptions={footerLinkOptions}
                            Size='sm'
                            Name='footerLinks'
                            Id='salute'
                        />
                    </Box>
                </Col>
                <Col md={3}>
                    <Box className='mb-3'>
                        <FormSelect
                            isFormGroup={false}
                            selectOptions={footerLinkOptions}
                            Size='sm'
                            Name='footerNot'
                            Id='salute'
                        />
                    </Box>
                </Col>
                <Col md={3}>
                    <Box className='mb-3'>
                        <FormSelect
                            isFormGroup={false}
                            selectOptions={footerLinkOptions}
                            Size='sm'
                            Name='footerRight'
                            Id='salute'
                        />
                    </Box>
                </Col>
            </Row>
            <div className="mt-2 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                <DisplayEnd className="px-3">
                    <ButtonWidget
                        text="Speichern"
                        varient="primary"
                        class="py-2 px-3"
                    />
                </DisplayEnd>
            </div>


        </>
    )
}