import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { FaGlobeEurope, FaHome } from 'react-icons/fa';
import { NavLink } from "react-router-dom";

export const Sidebar = () => {
    return (
        <>
            <div className='market-place-sidebar'>
                <Card className='boxShadow border-0 rounded-0'>
                    <Card.Header className='bg-white d-flex justify-content-center align-items-center'>
                        <Card.Title className='mb-0'>
                            <FaGlobeEurope className='me-2 gray-icon-color' size="25px" />
                        </Card.Title>
                        <Card.Title className='text-uppercase mb-0 gray-color'>Market</Card.Title>
                    </Card.Header>
                    <Card.Body className='p-0'>
                        <div className='pt-2'>
                            <ul className='list-unstyled ps-4 info-list'>
                                <li className='pt-1 pb-1 border-left-item'>
                                    <NavLink to="" className='text-uppercase'> All </NavLink>
                                </li>
                                <li className='pt-1 pb-1 border-left-item'>
                                    <NavLink to="" className='text-uppercase'> Tools </NavLink>
                                </li>
                                <li className='pt-1 pb-1 border-left-item'>
                                    <NavLink to="" className='text-uppercase'> Integrations </NavLink>
                                </li>
                                <li className='pt-1 pb-1 border-left-item'>
                                    <NavLink to="" className='text-uppercase'> Mine </NavLink>
                                </li>
                            </ul>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}