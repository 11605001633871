import { FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CheckControl } from "../../../Components/Widgets/InputControls/CheckControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { BodyText, Box, DisplayEnd, DisplayStart, Divider } from "../../../Components/StyledComponents";
import { InputTextControl } from "../../../Components/Widgets/InputControls/InputTextControl";
import ButtonWidget from "../../../Components/ButtonWidget";
import AddEditMessage from "../../Message/AddEditMessage";


interface IProps { }


const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
        lastName: yup
            .string()
            .typeError("Last name is required")
            .required("Last name is required"),
        position: yup
            .string()
            .typeError("Position is required")
            .required("Position is required"),
    })
    .required();


const MomentsConfig: FC<IProps> = ({ }) => {


    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });
    const fn = watch("firstName");
    const { gender } = watch();
    console.log(fn);

    return (
        <>
            <Row>
                <Col md={12}>
                    <h2>Moments</h2>
                    <BodyText fontSize='15px' fontWeight='400' textColor='#666' mt="12px" mb="15px"> Wortwahl für das Teilen der neuesten Moments anpassen. </BodyText>
                    <Box>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"gender"}
                            error={errors.gender}
                            value={"Teile deinen neuesten Moment..."}
                            label={"Teile deinen neuesten Moment..."}
                            isInline={false}
                            disabled={false}
                            checked={undefined}
                            defaultChecked={false}
                        />
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"gender"}
                            error={errors.gender}
                            value={"Benutzerdefiniert"}
                            label={"Benutzerdefiniert"}
                            isInline={false}
                            disabled={false}
                            checked={undefined}
                            defaultChecked={false}
                        />
                        <DisplayStart ml="15px">
                            <BodyText className='text-nowrap' fontSize='15px' fontWeight='400' textColor='#333'> Teile deinen neusten </BodyText>
                            <Box ml="6px" mr="6px">
                                <InputTextControl
                                    control={control}
                                    fieldName={"firstName"}
                                    error={errors.firstName}
                                    placeholder=""
                                    fieldType="text"
                                    size={"sm"}
                                />
                            </Box>
                            <BodyText className='text-nowrap' fontSize='15px' fontWeight='400' textColor='#333'> Moment... </BodyText>
                        </DisplayStart>
                    </Box>
                </Col>
                <Col md={12}>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
                <Col md={12}>
                    <Box mt="15px" mb="10px">
                        <Divider mt="" mb="3px" />
                        <Divider mt="" mb="" />
                    </Box>
                    <h2>Reaktionen</h2>
                    <Box>
                        <BodyText fontSize='15px' fontWeight='400' textColor='#333' mt="12px" mb="15px"> Reaktionen für Tools zulassen: </BodyText>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"gender"}
                            error={errors.gender}
                            value={"Moments"}
                            label={"Moments"}
                            isInline={false}
                            disabled={false}
                            checked={undefined}
                            defaultChecked={false}
                        />
                    </Box>
                    <Box>
                        <BodyText fontSize='15px' fontWeight='400' textColor='#333' mt="12px" mb="15px"> Reaktionen für Kommentare zulassen: </BodyText>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"gender"}
                            error={errors.gender}
                            value={"Moments"}
                            label={"Moments"}
                            isInline={false}
                            disabled={false}
                            checked={undefined}
                            defaultChecked={false}
                        />
                    </Box>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
            </Row>

        </>
    );
}

export default MomentsConfig;