import React, {
  useState,
  useEffect,
  FC,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import { Row, Col } from "react-bootstrap";
import {
  AccountSettingImg,
  BodyText,
  BorderBox,
  Box,
  CustomButton,
  DisplayCenter,
  DisplayStart,
  Heading,
  HeadingWithDivider,
  InlineBox,
  ProfileImg,
  UploadImgLabel,
} from "../../StyledComponents";
import Form from "react-bootstrap/Form";
import FormControl from "../../FormControl";
import FormCheck from "../../FormCheck";
import DatePicker from "../../DatePicker";
import { FaHtml5, FaInfoCircle, FaPlusCircle } from "react-icons/fa";
import CustomTooltip from "../../Tooltip";
import { BsQuestionCircleFill } from "react-icons/bs";
import { GrUploadOption } from "react-icons/gr";
import ButtonWidget from "../../ButtonWidget";
// import { RxCrossCircled } from "react-icons/rx";
import { FaRegTimesCircle } from "react-icons/fa";
import BadgeWidget from "../../BadgeWidget";
import Recipient from "../Recipient";
import FormSelect from "../../FormSelect";
import CommonModal from "../CommonModal";
import ChangePasswordModalContent from "./ChangePasswordModalContent";
import ChangePINModalContent from "./ChangePINModalContent";
import ResetPasswordModalContent from "./ResetPasswordModalContent";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserService from "../../../APIService/userService";
import { toast } from "react-hot-toast";
import { FaCrop } from "react-icons/fa";
import CropImgModalContent from "./CropImgModalContent";
import Cropper, { ReactCropperElement } from "react-cropper";
import { TextAreaControl } from "../../Widgets/InputControls/TextareaControl";
import { InputTextControl } from "../../Widgets/InputControls/InputTextControl";
import { CheckControl } from "../../Widgets/InputControls/CheckControl";
import { SelectControl } from "../../Widgets/InputControls/SelectControl";
import { DateTimeControl } from "../../Widgets/InputControls/DateTimeControl";
import { homePageOptions, languageOptions, saluteOptions, signOutTimeOptions } from "../../../CommonJSON";




const formSchema = yup
  .object({
    firstName: yup
      .string()
      .typeError("First name is required")
      .required("First name is required"),
    lastName: yup
      .string()
      .typeError("Last name is required")
      .required("Last name is required"),
    position: yup
      .string()
      .typeError("Position is required")
      .required("Position is required"),
  })
  .required();

function AccountSettingsModalContent(props: any) {
  const [cookies, setCookie, removeCookie] = useCookies([
    "authToken",
    "userImage",
  ]);
  const userService = new UserService();
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [diversToogle, setDiversToogle] = useState<boolean>(false);
  const [salutation, setSalutation] = useState<string>("");
  const [salutationtest, setSalutationtest] = useState<string>("");
  const [isFormCheck, setIsFormCheck] = useState<string>("1");
  const [ImgSource, setImgSource] = useState<string>("");
  const [RecipientModal, setRecipientModal] = useState<boolean>(false);
  const [badgeList, setBadgeList] = useState<string[]>([]);
  const [dateOpinion, setdateOpinion] = useState<boolean>(false);
  const [changePasswordModal, setChangePasswordModal] =
    useState<boolean>(false);
  const [changePINModal, setChangePINModal] = useState<boolean>(false);
  const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false);
  const [cropImgModal, setCropImgModal] = useState<boolean>(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
    formState,
    watch,
  } = useForm<UserProfileDto>({
    defaultValues: new UserProfileDto(),
    resolver: yupResolver(formSchema) as any,
    // mode: "onChange",
    //resolver: yupResolver(formSchema),
  });
  const fn = watch("firstName");
  const { gender } = watch();
  console.log(fn);

  useEffect(() => {
    setBadgeList(["Dentists", "medikit champions"]);
  }, []);

  const handleFormCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    debugger;
    setSalutation(event.currentTarget.value);
    console.log(event.currentTarget.value);
    if (event.currentTarget.value === "Divers") {
      setDiversToogle(true);
    } else {
      setDiversToogle(false);
    }
  };

  const handleImgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      var src = URL.createObjectURL(selectedFile);
      setImgSource(src);
    }
  };

  const handleCropChange = () => { };

  const handleBadgeClick = (event: React.MouseEvent | React.TouchEvent) => { };

  const cropperRef = useRef<ReactCropperElement>();
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setImgSource(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setCropImgModal(false);
    }
  };




  const onSubmit: SubmitHandler<UserProfileDto> = async (
    data: UserProfileDto,
    event: any
  ) => {
    if (data) {
      data.userImage = "None";
      userService
        .saveProfile(data)
        .then((result) => {
          toast.success("Save Successful");
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.message);
          }
        });
    }
  };

  return (
    <>
      <Form ref={props.formRef} onSubmit={handleSubmit(onSubmit)}>
        <HeadingWithDivider mb="20px" ms="10px">
          Profile
        </HeadingWithDivider>
        <Row>
          <Col md={6}>
            <Box className="mb-3">

              <Form.Label>Vorname</Form.Label>
              <InputTextControl
                control={control}
                fieldName={"firstName"}
                error={errors.firstName}
                placeholder="Vorname"
                fieldType="text"
                size={"sm"}
              />
            </Box>
          </Col>
          <Col md={6}>
            <Box className="mb-3">

              <Form.Label>Nachname Titel</Form.Label>
              <InputTextControl
                control={control}
                fieldName={"lastName"}
                error={errors.lastName}
                placeholder="Nachname Titel"
                fieldType="text"
                size={"sm"}
              />
            </Box>
          </Col>
          <Col md={6}>
            <Box className="mb-3">

              <Form.Label>Position</Form.Label>
              <InputTextControl
                control={control}
                fieldName={"position"}
                error={errors.position}
                placeholder="Position"
                fieldType="text"
                size={"sm"}
              />
            </Box>
          </Col>
          <Col md={6}>
            <Box className="mb-3">

              <Form.Label>Abteilung</Form.Label>
              <InputTextControl
                control={control}
                fieldName={"department"}
                error={errors.department}
                placeholder="Abteilung"
                fieldType="text"
                size={"sm"}
              />
            </Box>
          </Col>
          <Col md={6}>
            <Box className="mb-3">
              <Form.Label>Telefonnummer</Form.Label>
              <InputTextControl
                control={control}
                fieldName={"phoneNumber"}
                error={errors.phoneNumber}
                placeholder="Telefonnummer"
                fieldType="text"
              />
            </Box>
          </Col>
          <Col md={6}>
            <Box className="mb-3">
              <Form.Label>Salutation</Form.Label>
              <Form.Group
                controlId="Anrede"
                style={{ height: "31px" }}
              >
                <CheckControl
                  fieldType={"radio"}
                  control={control}
                  fieldName={"gender"}
                  error={errors.gender}
                  value="Weiblich"
                  label="Weiblich"
                  isInline={true}
                  disabled={false}
                  checked={gender == "Weiblich" ? true : false}
                  defaultChecked={false}
                />
                <CheckControl
                  fieldType={"radio"}
                  control={control}
                  fieldName={"gender"}
                  error={errors.gender}
                  value="Männlich"
                  label="Männlich"
                  isInline={true}
                  disabled={false}
                  checked={gender == "Männlich" ? true : false}
                  defaultChecked={false}
                />
                <CheckControl
                  fieldType={"radio"}
                  control={control}
                  fieldName={"gender"}
                  error={errors.gender}
                  value="Divers"
                  label="Divers"
                  isInline={true}
                  disabled={false}
                  checked={gender == "Divers" ? true : false}
                  defaultChecked={false}
                />
              </Form.Group>
              {diversToogle ? (
                <SelectControl
                  fieldId={"salutationId"}
                  fieldName={"salutationId"}
                  control={control}
                  error={errors.salutationId}
                  selectOptions={saluteOptions}
                />
              ) : // <FormSelect
                //   isFormGroup={true}
                //   selectOptions={saluteOptions}
                //   Size="sm"
                //   Name="salute"
                //   Id="salute"
                // />
                null}
            </Box>
          </Col>
          <Col md={6}>
            <Box className="mb-3">

              <DisplayStart className="mb-2">
                <Form.Label className="me-2 mb-0">DOB</Form.Label>
                <CustomTooltip
                  ToolTipTrigger={<FaInfoCircle />}
                  toolTipContent="Hello World"
                  toolTipPlacement="top"
                />
              </DisplayStart>
              <Form.Group>
                <DateTimeControl
                  name="dob"
                  control={control}
                  error={errors.dob}
                />
                {/* <DatePicker /> */}
              </Form.Group>
            </Box>
          </Col>
          <Col md={6}>
            <Form.Label>Eintrittsdatum</Form.Label>
            <Form.Group className="mb-3">
              <DateTimeControl
                name="entryDate"
                control={control}
                error={errors.entryDate}
              />
              {/* <DatePicker /> */}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Box className="mb-3">

              <DisplayStart className="mb-2">
                <Form.Label className="me-2 mb-0">Email</Form.Label>
                <CustomTooltip
                  ToolTipTrigger={<BsQuestionCircleFill />}
                  toolTipContent="Hello World"
                  toolTipPlacement="top"
                  toolTipPadding={5}
                />
              </DisplayStart>
              <Form.Group>
                <InputTextControl
                  control={control}
                  fieldName={"email"}
                  error={errors.email}
                  placeholder="E-Mail-Adresse"
                  fieldType="email"
                  size={"sm"}
                />
              </Form.Group>
            </Box>
          </Col>
          <Col md={6}>
            <Box className="mb-3">

              <Form.Label>E-Mail-Adresse bestätigen</Form.Label>
              <InputTextControl
                control={control}
                fieldName={"confirmEmail"}
                error={errors.email}
                placeholder="E-Mail-Adresse bestätigen"
                fieldType="email"
                size={"sm"}
              />
            </Box>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" style={{ height: "31px" }}>
              <CheckControl
                fieldType={"checkbox"}
                control={control}
                fieldName={"showEmail"}
                error={errors.showEmail}
                value="showEmail"
                label="E-Mail-Adresse nicht anzeigen (außer für Admins)"
                isInline={true}
                disabled={false}
                checked={defaultValues?.showEmail}
              />
              {/* <FormCheck
                type="checkbox"
                name="phone"
                value="phone"
                Id="phone"
                label="E-Mail-Adresse nicht anzeigen (außer für Admins)"
                isFormGroup={false}
                isInline={true}
              /> */}
            </Form.Group>
          </Col>
        </Row>
        <BorderBox borderBottom="none">
          <HeadingWithDivider mb="20px" ms="10px">
            <DisplayStart>
              Foto
              <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                <CustomTooltip
                  ToolTipTrigger={<BsQuestionCircleFill />}
                  toolTipContent="Max 10 Mb."
                  toolTipPlacement="top"
                />
              </span>
            </DisplayStart>
          </HeadingWithDivider>
          <Row className="mb-1">
            <Col md={6}>
              <Row>
                <Col md={6} className="pe-0">
                  <Box mb="10px">
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="upload-img"
                      type="file"
                      onChange={handleImgChange}
                    />
                    <UploadImgLabel htmlFor="upload-img" className="w-100">
                      <DisplayCenter>
                        <GrUploadOption />
                        <span className="ms-1">Bild hochladen</span>
                      </DisplayCenter>
                    </UploadImgLabel>
                  </Box>
                  <Box mb="10px">
                    {!!ImgSource ? (
                      <ButtonWidget
                        varient="light"
                        class="w-100"
                        text={
                          <>
                            <DisplayCenter>
                              <FaRegTimesCircle />
                              <span className="ms-1">Bild löschen</span>
                            </DisplayCenter>
                          </>
                        }
                        handleClick={() => setImgSource("")}
                      />
                    ) : null}
                  </Box>
                  <Box mb="10px">
                    {!!ImgSource ? (
                      <ButtonWidget
                        varient="light"
                        class="w-100"
                        text={
                          <>
                            <DisplayCenter>
                              <FaCrop />
                              <span className="ms-1">Bild zuschneiden</span>
                            </DisplayCenter>
                          </>
                        }
                        handleClick={() => setCropImgModal(true)}
                      />
                    ) : null}
                  </Box>
                </Col>
                <Col md={6}>
                  <DisplayCenter>
                    <AccountSettingImg
                      src={`${!!ImgSource ? ImgSource : "/images/account-b.png"
                        }`}
                    />
                  </DisplayCenter>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <h6>Zeige Profilbild in diesen Netzwerken</h6>
              <Box mb="10px">
                <Form.Check
                  label={
                    <BadgeWidget
                      outiline={true}
                      title="Praxis Gallenbach & Zähne"
                      color="#0d3f5f"
                    />
                  }
                  name="group1"
                  type="checkbox"
                />
              </Box>
              <Box mb="10px">
                <Form.Check
                  label={
                    <BadgeWidget
                      outiline={true}
                      title="OPTI QMH Updates"
                      color="#e4005f"
                    />
                  }
                  name="group1"
                  type="checkbox"
                />
              </Box>
            </Col>
          </Row>
        </BorderBox>

        <BorderBox className="mb-3 pb-3">
          <Row>
            <Col md={12}>
              {/* <Heading>
                                    Gruppen
                                </Heading> */}
              <HeadingWithDivider mb="20px" ms="10px">
                Gruppen
              </HeadingWithDivider>
            </Col>
            <Col md={8}>
              <DisplayStart>
                {badgeList.map((item, index) => {
                  return (
                    <>
                      <CustomTooltip
                        ToolTipTrigger={
                          <BadgeWidget
                            solid={true}
                            title="Assistenz"
                            color="#fff"
                            bgColor="#678ea4"
                            mr="5px"
                            closeIcon={true}
                            handleBadgeClick={handleBadgeClick}
                          />
                        }
                        toolTipContent="Alicia Gojani, Anna Behm, Cara Bianco, Hoa Trinh, Irene Gantert"
                        toolTipPlacement="top"
                      />
                    </>
                  );
                })}
              </DisplayStart>
            </Col>
            <Col md={4}>
              <ButtonWidget
                varient="light"
                class="w-100"
                text={
                  <>
                    <DisplayCenter>
                      <FaPlusCircle className="default-btn-icon" />
                      <span className="ms-1">Alle Kategorien anzeigen</span>
                    </DisplayCenter>
                  </>
                }
                handleClick={(event) => setRecipientModal(true)}
              />
            </Col>
          </Row>
        </BorderBox>

        <BorderBox borderTop="none" className="pb-2">
          <Row>
            <Col md={6}>
              <Form.Label>Sprache</Form.Label>
              <FormSelect
                isFormGroup={true}
                selectOptions={languageOptions}
                Size="sm"
                Name="salute"
                Id="salute"
              />
            </Col>
            <Col md={6}>
              <Form.Label>Startseite</Form.Label>
              <FormSelect
                isFormGroup={true}
                selectOptions={homePageOptions}
                Size="sm"
                Name="salute"
                Id="salute"
              />
            </Col>
          </Row>
        </BorderBox>

        <BorderBox borderTop="none">
          <Row>
            <Col md={12}>
              <HeadingWithDivider mb="20px" ms="10px">
                Ansicht
              </HeadingWithDivider>
            </Col>
            <Col md={6}>
              <FormCheck
                type="checkbox"
                name="dateOpinion"
                Id="dateOpinion"
                value=""
                label="Datum immer ausschreiben"
                isFormGroup={true}
                isInline={true}
                disabled={false}
                handleChange={() => setdateOpinion(!dateOpinion)}
              />
            </Col>
            <Col md={6}>
              <DisplayCenter>
                {!!dateOpinion ? (
                  <BodyText textColor="#bbb" fontSize="14">
                    Z.B.: 17.05.2023 08:49
                  </BodyText>
                ) : (
                  <CustomTooltip
                    ToolTipTrigger={
                      <BodyText textColor="#bbb" fontSize="14">
                        Z.B.: vor 2 Tagen
                      </BodyText>
                    }
                    toolTipContent="1705.2023 08:49"
                    toolTipPlacement="top"
                  />
                )}
              </DisplayCenter>
            </Col>
          </Row>
        </BorderBox>
        <BorderBox borderTop="none" className="pb-3">
          <Row>
            <Col md={12}>
              <HeadingWithDivider mb="20px" ms="10px">
                Sicherheit
              </HeadingWithDivider>
            </Col>
            <Col md={5}>
              <Form.Label>Melde mich ab, wenn ich inaktiv bin</Form.Label>
              <FormSelect
                isFormGroup={false}
                selectOptions={signOutTimeOptions}
                Size="sm"
                Name="salute"
                Id="salute"
              />
            </Col>
            <Col md={7} className="h-100 align-self-end">
              <DisplayStart>
                <ButtonWidget
                  text="Passwort ändern ..."
                  varient="custom"
                  bgColor="#46b8da"
                  textColor="#fff"
                  bgHoverColor="#31b0d5"
                  handleClick={(event) => setChangePasswordModal(true)}
                />
                <ButtonWidget
                  text="PIN ändern..."
                  varient="custom"
                  bgColor="#46b8da"
                  textColor="#fff"
                  bgHoverColor="#31b0d5"
                  class="mx-1"
                  handleClick={(event) => setChangePINModal(true)}
                />
                <ButtonWidget
                  text="Passwort zurücksetzen..."
                  varient="custom"
                  bgColor="#46b8da"
                  textColor="#fff"
                  bgHoverColor="#31b0d5"
                  handleClick={(event) => setResetPasswordModal(true)}
                />
              </DisplayStart>
            </Col>
          </Row>
        </BorderBox>
        <Row className="pt-2">
          <Col md={12}>
            <HeadingWithDivider mb="20px" ms="10px">
              <DisplayStart>
                Abwesenheit
                <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                  <CustomTooltip
                    ToolTipTrigger={<BsQuestionCircleFill />}
                    toolTipContent="Andere Nutzer sehen, dass du abwesend bist, wenn sie versuchen KontaKt mit dir aufzunehmen."
                    toolTipPlacement="top"
                  />
                </span>
              </DisplayStart>
            </HeadingWithDivider>
          </Col>
          <Col md={6}>
            <Form.Label>Erster Tag der Abwesenheit</Form.Label>
            <Form.Group className="mb-3">
              <DatePicker />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Label>Letzter Tag der Abwesenheit</Form.Label>
            <Form.Group className="mb-3">
              <DatePicker />
            </Form.Group>
          </Col>
        </Row>

        <Recipient
          displayRecipientModal={RecipientModal}
          hideRecipientModal={() => setRecipientModal(false)}
        />
        <CommonModal
          showModal={changePasswordModal}
          hideModal={() => setChangePasswordModal(false)}
          modalTitle="Passwort ändern"
          modalContent={<ChangePasswordModalContent />}
          modalSize="md"
        />
        <CommonModal
          showModal={changePINModal}
          hideModal={() => setChangePINModal(false)}
          modalTitle="PIN ändern"
          modalContent={<ChangePINModalContent />}
          modalSize="md"
        />
        <CommonModal
          showModal={resetPasswordModal}
          hideModal={() => setResetPasswordModal(false)}
          modalTitle="Passwort zurücksetzen"
          modalContent={<ResetPasswordModalContent />}
          modalSize="md"
        />
        <CommonModal
          showModal={cropImgModal}
          hideModal={() => setCropImgModal(false)}
          modalTitle="Bild zuschneiden"
          modalContent={
            <CropImgModalContent ImgSrc={ImgSource} CropRef={cropperRef} />
          }
          modalSize="lg"
          modalFooterContent={
            <CropImgModalFooterContent
              hideModel={() => setCropImgModal(false)}
              saveCrop={getCropData}
            />
          }
        />
      </Form>
    </>
  );
}

interface ICropImgFooterProps {
  hideModel: () => void;
  saveCrop: () => void;
}

export const CropImgModalFooterContent: FC<ICropImgFooterProps> = ({
  hideModel,
  saveCrop,
}) => {
  return (
    <>
      <ButtonWidget varient="light" text="Abbrechen" handleClick={hideModel} />
      <ButtonWidget varient="primary" text="Erstellen" handleClick={saveCrop} />
    </>
  );
};

export default AccountSettingsModalContent;
