import { FC } from "react";
import { Box, DisplayEnd } from "../../../Components/StyledComponents";
import ButtonWidget from "../../../Components/ButtonWidget";
import { Col, Form, Row } from "react-bootstrap";
import FormSelect from "../../../Components/FormSelect";
import { standardStreamtyp } from "../../../CommonJSON";
import { CheckControl } from "../../../Components/Widgets/InputControls/CheckControl";
import { SubmitHandler, useForm } from "react-hook-form";

interface IProps { }

export const StandardStreamtyp: FC<IProps> = ({ }) => {
    const { control, register, handleSubmit, formState: { errors, defaultValues }, reset, formState, watch, } = useForm();
    return (
        <>
            <Row>
                <Col md={12}>
                    <h2>Standard Streamtyp</h2>
                    <p>Ändere die auf deinem Dashboard voreingestellte Registerkarte. Diese Einstellung kann in den Dashboard-Einstellungen der einzelnen Nutzern überschrieben werden.</p>
                </Col>
                <Col md={5}>
                    <div className="mt-2">
                        <Box className='mb-4'>
                            <Form.Label>Sprache</Form.Label>
                            <FormSelect
                                selectOptions={standardStreamtyp}
                                Size="md"
                                Name="salute"
                                Id="salute"
                            />
                        </Box>
                        <Box className='mb-1'>
                            <CheckControl
                                fieldType={"radio"}
                                control={control}
                                fieldName={"streamType"}
                                value="newUsers"
                                label="Standard-Streamtyp für alle neuen Nutzer einstellen."
                                isInline={true}
                                disabled={false}
                                defaultChecked={false}
                            />
                        </Box>
                        <Box>
                            <CheckControl
                                fieldType={"radio"}
                                control={control}
                                fieldName={"streamType"}
                                value="allUsers"
                                label="Standard-Streamtyp für alle Nutzer einstellen."
                                isInline={true}
                                disabled={false}
                                defaultChecked={false}
                            />
                        </Box>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
            </Row>
        </>
    )
}