import { FC } from "react";
import { DisplayEnd } from "../../../Components/StyledComponents";
import ButtonWidget from "../../../Components/ButtonWidget";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import FormSelect from "../../../Components/FormSelect";
import { languageOptions } from "../../../CommonJSON";


interface IProps { }

export const LanguageSetting: FC<IProps> = ({ }) => {
    return (
        <>
            <Row>
                <Col md={12}>
                    <h2>Spracheinstellungen</h2>
                    <p>Sprache dieses Kits ändern</p>
                </Col>
                <Col md={6} className="mt-2">
                    <Form.Label>Sprache</Form.Label>
                    <FormSelect
                        selectOptions={languageOptions}
                        Size="md"
                        Name="salute"
                        Id="salute"
                    />
                </Col>
                <Col md={12}>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
            </Row>



        </>
    )
}