import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Row, Col, InputGroup, } from 'react-bootstrap';
import Recipient from '../../../Components/Modals/Recipient';
import DateTimePicker from '../../../Components/DateTimePicker';
import ViewRecipient from '../../../Components/ViewRecipient';
import { Box, DisplayBetween, DisplayEnd, DisplayStart, Divider } from '../../../Components/StyledComponents';
import FormSelect from '../../FormSelect';
import { HiOutlineSwitchVertical, HiX } from "react-icons/hi";
import ButtonWidget from '../../ButtonWidget';
import { FaPlusCircle } from 'react-icons/fa';
import { voiceOptions } from '../../../CommonJSON';


interface questionType {
    answer: string,
}

export const AddPollModalContent = (props: any) => {

    // State for modals Begin
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    // State for modals End

    // State for Hide Show Begin
    const [AddUser, setAddUser] = useState<boolean>(false);
    const [selection, setSelection] = useState<boolean>(false);

    //State for Checklist Question Begin
    const [ChecklistQuestions, setChecklistQuestions] = useState<questionType[]>([]);
    //State for Checklist Question End

    useEffect(() => {
        var temp = [];
        for (let x = 0; x < 3; x++) {
            let newQuestion = {
                answer: '',
            }
            temp.push(newQuestion)
        }
        setChecklistQuestions(temp);
    }, []);

    const addOneQuestion = () => {
        let newQuestion = {
            answer: '',
        }
        setChecklistQuestions([...ChecklistQuestions, newQuestion]);
    }

    const removeOneQuestion = (index: number) => {
        if (index > -1 && index > 2) {
            ChecklistQuestions.splice(index, 1);
            setChecklistQuestions([...ChecklistQuestions]);
        }
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Control size="sm" type="text" placeholder="Frage Oder Titel" />
                    </Form.Group>
                </Col>
                <div className='border-top border-bottom py-2'>
                    <Col md={12}>
                        <Form.Group controlId="">
                            <Form.Check
                                inline
                                label="Ersteller und Empfänger dieses Eintrags"
                                name="group1"
                                type="radio"
                                id="public"
                                onChange={() => { setAddUser(false) }}
                            />
                            <Form.Check
                                inline
                                label="Alle Empfänger dieses Eintrags"
                                name="group1"
                                type="radio"
                                id="notpublic"
                                onChange={() => { setAddUser(false) }}
                            />
                            <Form.Check
                                inline
                                label="An alle Nutzer"
                                name="group1"
                                type="radio"
                                id="toalluser"
                                onChange={() => { setAddUser(false) }}
                            />
                            <Form.Check
                                inline
                                label="Individuelle Auswahl"
                                name="group1"
                                type="radio"
                                id="toalluser"
                                onChange={() => { setAddUser(true) }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        {AddUser ?
                            <div className='mt-3'>
                                <ViewRecipient showModel={() => setRecipientModal(true)} Palaceholder="Empfänger" BtnText="Alle Empfänger anzeigen" />
                            </div>
                            : null
                        }
                    </Col>
                </div>
                <Col md={12} className='mt-3'>
                    {ChecklistQuestions.map((element, index) => (
                        <>
                            <Box id={`${"button-sort-" + index}`}>
                                <InputGroup size="sm" className="mb-1">
                                    <Form.Control
                                        aria-label={`${"Check list Queastion no" + index}`}
                                        aria-describedby="basic-addon1"
                                        placeholder='Antwort...'
                                    />
                                    <Button variant="secondary" id={`${"button-close-" + index}`} onClick={() => removeOneQuestion(index)}>
                                        <HiX size="20px" />
                                    </Button>
                                </InputGroup>
                            </Box>
                        </>
                    )
                    )}
                </Col>
                <Col md={12}>
                    <DisplayEnd mt="10px" mb="10px">
                        <Box className='mt-1'>
                            <ButtonWidget
                                varient='light'
                                text={
                                    <span><FaPlusCircle className='default-btn-icon' /> Antwort hinzufügen </span>
                                }
                                handleClick={() => addOneQuestion()}
                            />
                        </Box>
                    </DisplayEnd>
                </Col>
                <Divider mt="10px" mb="10px" />
                <Col md={12} className='mt-3'>
                    <div className="mb-3">
                        <Form.Control as="textarea" placeholder="Text" rows={5} />
                    </div>
                </Col>
                <Divider mt="8px" mb="8px" />
                <Row>
                    <Col md={3}>
                        <Form.Check
                            inline
                            label="Einfachauswahl"
                            name="group1"
                            type="radio"
                            id="public"
                            onChange={() => { setSelection(false) }}
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Check
                            inline
                            label="Mehrfachauswahl"
                            name="group1"
                            type="radio"
                            id="notpublic"
                            onChange={() => { setSelection(true) }}
                        />
                        {selection ?
                            <>
                                <div className="ms-3 w-50 mt-2">
                                    <FormSelect
                                        isFormGroup={true}
                                        selectOptions={voiceOptions}
                                        Size='sm'
                                        Name='voices'
                                        Id='voices'
                                    />
                                </div>
                            </>
                            : null}
                    </Col>
                </Row>
                {props.AllowReading ?
                    <>
                        <Box>
                            <Divider mt="10px" mb="10px" />
                            <Form.Check
                                inline
                                label="Anonyme Umfrage"
                                name="Anonyme Umfrage"
                                type="checkbox"
                                id="notpublic"
                                disabled={false}
                            />
                            <Divider mt="10px" mb="10px" />
                            <Box>
                                <DisplayBetween width='400px'>
                                    <Form.Label className='mb-0 me-1'> Ende der Umfrage: </Form.Label>
                                    <DateTimePicker />
                                </DisplayBetween>
                            </Box>
                            <Divider mt="10px" mb="10px" />
                            <Box>
                                <DisplayBetween width='400px'>
                                    <Form.Label className='mb-0 me-1'> Erinnerung: </Form.Label>
                                    <DateTimePicker />
                                </DisplayBetween>
                            </Box>
                            <Divider mt="10px" mb="10px" />
                        </Box>
                    </>
                    : null}
            </Row>
            <Recipient
                displayRecipientModal={RecipientModal}
                hideRecipientModal={() => setRecipientModal(false)}
            />
        </>
    )
}