import { Table } from "react-bootstrap";
import { ImArrowRight } from "react-icons/im";
import CustomTooltip from "../Tooltip";
import { DisplayStart } from "../StyledComponents";
import { FC } from "react";

type OverdueTasksPropsType = {
    Name?: string,
    heading?: string,
    Data: any,
    isSeletable?: boolean,
}

export const OverdueTasksList: FC<OverdueTasksPropsType> = (props) => {
    return (
        <>
            <Table className={`${props.Name?.replace(/\s/g, '') + "table"}`}>
                <tbody>
                    {props.Data.map((item: any, index: number) => {
                        return (
                            <>
                                <tr className="" key={index}>
                                    <td className="p-1">
                                        <span className="fw-bold">{item.title}</span>
                                    </td>
                                    <td className="p-1">
                                        <DisplayStart>
                                            <span className="me-2">
                                                <ImArrowRight />
                                            </span>
                                            {item.badgeTooltip ?
                                                <CustomTooltip
                                                    ToolTipTrigger={
                                                        <>
                                                            {item.badge1 ?
                                                                <span className="ng-badge">{item.badge1}</span>
                                                                : null}
                                                        </>
                                                    }
                                                    toolTipContent={item.badgeTooltip}
                                                    toolTipPlacement='top'
                                                />

                                                :
                                                <>
                                                    {item.badge1 ?
                                                        <span className="ng-badge">{item.badge1}</span>
                                                        : null}
                                                </>
                                            }

                                            {item.badgeTooltip ?
                                                <CustomTooltip
                                                    ToolTipTrigger={
                                                        <>
                                                            {item.noBadge ?
                                                                <span className="">{item.noBadge}</span>
                                                                : null}
                                                        </>
                                                    }
                                                    toolTipContent={item.badgeTooltip}
                                                    toolTipPlacement='top'
                                                />
                                                :
                                                <>
                                                    {item.noBadge ?
                                                        <span className="">{item.noBadge}</span>
                                                        : null}
                                                </>
                                            }
                                        </DisplayStart>
                                    </td>
                                    <td className="p-1 text-end w-23">
                                        {item.labelColor === "dark" ? (
                                            <div className="d-flex border rounded">
                                                <span className="border-left-1 mx-2"></span>
                                                <span className="text-nowrap me-2 fw-400">
                                                    {item.labelText}
                                                </span>
                                            </div>
                                        ) : null}
                                        {item.labelColor === "orange" ? (
                                            <div className="d-flex border rounded">
                                                <span className="border-1 mx-2"></span>
                                                <span className="text-orange me-2 fw-400">
                                                    {item.labelText}
                                                </span>
                                            </div>
                                        ) : null}
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </Table>

        </>
    )
}