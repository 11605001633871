import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { SidebarDetail } from './SidebarDetail';
import { MainContentDetail } from './MainContentDetail';

export const UserDetailIndex = () => {
    return (
        <>
            <Row>
                <Col md={3}>
                    <SidebarDetail />
                </Col>
                <Col md={9}>
                    <MainContentDetail />
                </Col>
            </Row>
        </>
    )
}