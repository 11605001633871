import React, { useState, useEffect } from 'react';
import { Row, Col, } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FormCheck from '../../FormCheck';
import FormSelect from '../../FormSelect';
import { homePageOptions2 } from '../../../CommonJSON';


function EditStaticModuleModalContent() {
    const [hideStatic, setHideStatic] = useState<string>('');

    const handleFormCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHideStatic(event.currentTarget.value);
        console.log(event.currentTarget.value);
    };

    useEffect(() => {
        console.log(hideStatic);
    }, [hideStatic]);



    return (
        <>
            <Row className='pb-2'>
                <Col md={12}>
                    <Form.Label>Zeitraum</Form.Label>
                    <FormSelect
                        isFormGroup={true}
                        selectOptions={homePageOptions2}
                        Size='sm'
                        Name='salute'
                        Id='salute'
                    />
                </Col>
                <Col md={12}>
                    <Form.Group controlId='Anrede' className="mb-3" style={{ height: '31px' }}>
                        <FormCheck
                            handleChange={handleFormCheck}
                            type='checkbox'
                            name='phone'
                            Id='phone'
                            value='Legende ausblenden'
                            label='Legende ausblenden'
                            isFormGroup={false}
                            isInline={false}
                            disabled={false}
                            checked={hideStatic === 'Legende ausblenden' ? true : false}
                        />
                        <FormCheck
                            handleChange={handleFormCheck}
                            type='checkbox'
                            name='phone'
                            Id='phone2'
                            value='Verstecke zu Verteilende'
                            label='Verstecke zu Verteilende'
                            isFormGroup={false}
                            isInline={false}
                            disabled={false}
                            checked={hideStatic === 'Verstecke zu Verteilende' ? true : false}
                        />
                        <FormCheck
                            handleChange={handleFormCheck}
                            type='checkbox'
                            name='phone'
                            Id='phone3'
                            value='Verstecke erledigte'
                            label='Verstecke erledigte'
                            isFormGroup={false}
                            isInline={false}
                            disabled={false}
                            checked={hideStatic === 'Verstecke erledigte' ? true : false}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
}

export default EditStaticModuleModalContent;
