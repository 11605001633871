import { FC } from "react"
import { CirclesWithBar } from "react-loader-spinner"

export const Loader: FC = () => {
    return (
        <>
            <div className="LoaderWrapper">
                <CirclesWithBar color="white" />
            </div>
        </>
    )
}