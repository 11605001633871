import React, { useState, useEffect } from 'react';
import { Row, Col, } from 'react-bootstrap';
import { BodyText, BorderBox, Box, CustomButton, DisplayBetween, DisplayCenter, DisplayStart, Divider } from '../../StyledComponents';
import Form from 'react-bootstrap/Form';
import FormControl from '../../FormControl';
import FormCheck from '../../FormCheck';
import CustomTooltip from '../../Tooltip';
import { Link } from 'react-router-dom';

export const VotesModalContent = () => {

    const [checkMaximum, setCheckMaximum] = useState<boolean>(false);
    const [checkMinimum, setCheckMinimum] = useState<boolean>(false);
    const [checkMore, setCheckMore] = useState<boolean>(false);
    const [checkMuch, setCheckMuch] = useState<boolean>(false);
    const [checkAnswer, setCheckAnswer] = useState<boolean>(false);

    const [showbyRead, setShowByRead] = useState<string>('');

    const handleAllAnsers = (event: React.ChangeEvent<HTMLInputElement>) => {
        debugger;
        setShowByRead(event.currentTarget.value);
        if (event.currentTarget.value === 'maximum') {
            setCheckMaximum(true);
            setCheckMinimum(false);
            setCheckMore(false);
            setCheckMuch(false);
            setCheckAnswer(false);
        }
        if (event.currentTarget.value === 'minimum') {
            setCheckMaximum(false);
            setCheckMinimum(true);
            setCheckMore(false);
            setCheckMuch(false);
            setCheckAnswer(false);
        }
        if (event.currentTarget.value === 'more') {
            setCheckMaximum(false);
            setCheckMinimum(false);
            setCheckMore(true);
            setCheckMuch(false);
            setCheckAnswer(false);
        }
        if (event.currentTarget.value === 'much') {
            setCheckMaximum(false);
            setCheckMinimum(false);
            setCheckMore(false);
            setCheckMuch(true);
            setCheckAnswer(false);
        }
        if (event.currentTarget.value === 'allAnswers') {
            setCheckMaximum(true);
            setCheckMinimum(true);
            setCheckMore(true);
            setCheckMuch(true);
            setCheckAnswer(true);
        }
    };

    return (
        <>
            <Row>
                <Col md={12}>
                    <FormControl isFormGroup={true} Type='text' Size='sm' Placeholder='Search....' Name='searchFor' Id='searchFor' />
                </Col>
                <Divider mt='10px' mb='8px' />
                <Col md={12}>
                    <Form.Group controlId='Anrede' className="mb-1">
                        <FormCheck
                            handleChange={handleAllAnsers}
                            type='radio'
                            name='byRead'
                            Id='byRead1'
                            value='maximum'
                            label='maximum (1)'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showbyRead === 'maximum' ? true : false}
                        />
                        <FormCheck
                            handleChange={handleAllAnsers}
                            type='radio'
                            name='byRead'
                            Id='byRead2'
                            value='minimum'
                            label='minimum (0)'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showbyRead === 'minimum' ? true : false}
                        />
                        <FormCheck
                            handleChange={handleAllAnsers}
                            type='radio'
                            name='byRead'
                            Id='byRead3'
                            value='more'
                            label='more (0)'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showbyRead === 'more' ? true : false}
                        />
                        <FormCheck
                            handleChange={handleAllAnsers}
                            type='radio'
                            name='byRead'
                            Id='byRead4'
                            value='much'
                            label='much (0)'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showbyRead === 'much' ? true : false}
                        />
                        <FormCheck
                            handleChange={handleAllAnsers}
                            type='radio'
                            name='byRead'
                            Id='byRead5'
                            value='allAnswers'
                            label='All answers'
                            isFormGroup={false}
                            isInline={true}
                            disabled={false}
                            checked={showbyRead === 'allAnswers' ? true : false}
                        />
                    </Form.Group>
                </Col>
                <Divider mt='10px' mb='8px' />
            </Row>

            <Row>
                <Col md={12}>

                    {checkMaximum ?
                        <>
                            <Box>
                                <CustomTooltip
                                    ToolTipTrigger={<span className='fs-17 fw-600'> Maximum </span>}
                                    toolTipContent='Users who have read this item'
                                    toolTipPlacement='top'
                                />
                                <DisplayBetween>
                                    <DisplayStart className='p-2'>
                                        <Box>
                                            <img width='50' src='/images/account.png' />
                                        </Box>
                                        <Box ml="8px">
                                            <CustomTooltip
                                                ToolTipTrigger={<BodyText textColor='#bbb' fontSize='16' fontWeight='500'> Dr. Sven Markus Gallenbach </BodyText>}
                                                toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                                toolTipPlacement='top'
                                            />
                                            <BodyText textColor='#bbb' fontSize='14'> Gelesen 09.06.2023 11:51 </BodyText>
                                        </Box>
                                    </DisplayStart>
                                </DisplayBetween>
                            </Box>
                        </>
                        : null}

                    {checkMinimum ?
                        <>
                            <Box>
                                <span>
                                    <CustomTooltip
                                        ToolTipTrigger={<BodyText fontSize='17px' fontWeight='600' textColor='#666'> Minimum </BodyText>}
                                        toolTipContent='Users who have read this item'
                                        toolTipPlacement='top'
                                    />
                                </span>
                                <BodyText fontSize='14px' fontWeight='400'> No one has voted for this answer yet </BodyText>
                            </Box>
                        </>
                        : null}

                    {checkMore ?
                        <>
                            <Box>
                                <CustomTooltip
                                    ToolTipTrigger={<BodyText fontSize='17px' fontWeight='600' textColor='#666'> More </BodyText>}
                                    toolTipContent='Users who have read this item'
                                    toolTipPlacement='top'
                                />
                                <BodyText fontSize='14px' fontWeight='400'> No one has voted for this answer yet </BodyText>
                            </Box>
                        </>
                        : null}

                    {checkMuch ?
                        <>
                            <Box>
                                <CustomTooltip
                                    ToolTipTrigger={<BodyText fontSize='17px' fontWeight='600' textColor='#666'> Much </BodyText>}
                                    toolTipContent='Users who have read this item'
                                    toolTipPlacement='top'
                                />
                                <BodyText fontSize='14px' fontWeight='400'> No one has voted for this answer yet </BodyText>
                            </Box>
                        </>
                        : null}

                </Col>
            </Row>
        </>
    );
}

