import { useEffect, useState } from "react";
import { Row, Col } from 'react-bootstrap';
import Sidebar from '../Message/SideBar';
import { Outlet } from "react-router-dom";
import { TopbarSelectFilterDropdown } from '../../Components/TopbarSelectFilterDropdown';
import { TdisplayMode } from "../Message/Index";

export interface INewsData {
    title: string,
    badgeOutlineText: string,
    badgeOutlineColor: string,
    badgeOutlineBg: string,
    userName1: string,
    userNameTooltipContent1: string,
    userName2: string,
    badgeSolidColor1: string,
    badgeSolidBg1: string,
    badgeSolidTitle1: string,
    badgeSolidTooltipContent1: string,
    badgeSolidColor2: string,
    badgeSolidTitle2: string,
    badgeSolidTooltipContent2: string,
    badgeSolidBg2: string,
    limitedVisibilty: boolean,
    limitedVisibiltyTooltipContent: string,
    noticed: boolean,
    noticedTooltipContent: string,
    dateTime: string,
    image: string,
    imageTooltipContent: string,
    isChecked: boolean,
    recordBg: string,
}

const NewsData = [
    {
        title: "OPTI-QM-Flat: Neue Dokumente",
        badgeOutlineText: "OPTI QMH Updates",
        badgeOutlineColor: "#e4005f",
        badgeOutlineBg: "#fff",
        userName1: "OPTI Updates",
        userNameTooltipContent1: "OPTI QMH Updates",
        userName2: "",
        badgeSolidColor1: "#990040",
        badgeSolidBg1: "#ffcce1",
        badgeSolidTitle1: "OPTI Concept & OPTI Concept XL",
        badgeSolidTooltipContent1: "",
        badgeSolidColor2: "#990040",
        badgeSolidTitle2: "Empfänger Updates",
        badgeSolidTooltipContent2: "",
        badgeSolidBg2: "#ffcce1",
        limitedVisibilty: true,
        limitedVisibiltyTooltipContent: "Sichtbarkeit eingeschränkt",
        noticed: false,
        noticedTooltipContent: "",
        dateTime: "21.02.2023 14:52",
        image: "/images/graph-img.png",
        imageTooltipContent: "Nicht gelesen: 11",
        isChecked: false,
        recordBg: '#fff',
    },
    {
        title: "OPTI-QM-Flat: Neue Dokumente",
        badgeOutlineText: "Praxis Gallenbach & Zähne ",
        badgeOutlineColor: "#0d3f5f",
        badgeOutlineBg: "#ffffff",
        userName1: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent1: "Zahnarzt - Praxis Gallenbach & Zähne",
        userName2: "Alle",
        badgeSolidColor1: "",
        badgeSolidBg1: "",
        badgeSolidTitle1: "",
        badgeSolidTooltipContent1: "",
        badgeSolidColor2: "",
        badgeSolidTitle2: "",
        badgeSolidTooltipContent2: "",
        badgeSolidBg2: "",
        limitedVisibilty: false,
        limitedVisibiltyTooltipContent: "",
        noticed: false,
        noticedTooltipContent: "",
        dateTime: "21.02.2023 14:52",
        image: "/images/graph-img.png",
        imageTooltipContent: "Nicht gelesen: 8",
        isChecked: false,
        recordBg: '#fff',
    },
    {
        title: "ASCJsacd",
        badgeOutlineText: "Praxis Gallenbach & Zähne ",
        badgeOutlineColor: "#0d3f5f",
        badgeOutlineBg: "#ffffff",
        userName1: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent1: "Zahnarzt - Praxis Gallenbach & Zähne",
        userName2: "",
        badgeSolidColor1: "#395260",
        badgeSolidBg1: "#d4eaf7",
        badgeSolidTitle1: "Assistenz",
        badgeSolidTooltipContent1: "Alicia Gojani, Anna Behm, Cara Bianco, Hoa Trinh, Irene Ganten",
        badgeSolidColor2: "",
        badgeSolidTitle2: "",
        badgeSolidTooltipContent2: "",
        badgeSolidBg2: "",
        limitedVisibilty: false,
        limitedVisibiltyTooltipContent: "",
        noticed: true,
        noticedTooltipContent: "gemerkt",
        dateTime: "21.11.2022 18:13",
        image: "/images/graph-img.png",
        imageTooltipContent: "Nicht gelesen: 4",
        isChecked: false,
        recordBg: '#FFFFCC',
    },
    {
        title: "etzt alle medikit Tools entdecken!",
        badgeOutlineText: "Praxis Gallenbach & Zähne ",
        badgeOutlineColor: "#0d3f5f",
        badgeOutlineBg: "#ffffff",
        userName1: "Entfernter Nutzer",
        userNameTooltipContent1: "Zahnarzt - Praxis Gallenbach & Zähne",
        userName2: "Alle",
        badgeSolidColor1: "",
        badgeSolidBg1: "",
        badgeSolidTitle1: "",
        badgeSolidTooltipContent1: "",
        badgeSolidColor2: "",
        badgeSolidTitle2: "",
        badgeSolidTooltipContent2: "",
        badgeSolidBg2: "",
        limitedVisibilty: true,
        limitedVisibiltyTooltipContent: "Sichtbarkeit eingeschränkt",
        noticed: false,
        noticedTooltipContent: "",
        dateTime: "13.04.2021 14:52",
        image: "/images/graph-img.png",
        imageTooltipContent: "Nicht gelesen: 7",
        isChecked: false,
        recordBg: '#FFFFCC',
    },
    {
        title: "Tite13",
        badgeOutlineText: "Praxis Gallenbach & Zähne ",
        badgeOutlineColor: "#0d3f5f",
        badgeOutlineBg: "#ffffff",
        userName1: "Dr. Sven Markus Gallenbach",
        userNameTooltipContent1: "Zahnarzt - Praxis Gallenbach & Zähne",
        userName2: "Alle",
        badgeSolidColor1: "",
        badgeSolidBg1: "",
        badgeSolidTitle1: "",
        badgeSolidTooltipContent1: "",
        badgeSolidColor2: "",
        badgeSolidTitle2: "",
        badgeSolidTooltipContent2: "",
        badgeSolidBg2: "",
        limitedVisibilty: false,
        limitedVisibiltyTooltipContent: "",
        noticed: true,
        noticedTooltipContent: "gemerkt",
        dateTime: "12.07.2022 12:40",
        image: "/images/graph-img.png",
        imageTooltipContent: "Nicht gelesen: 7",
        isChecked: false,
        recordBg: '#FFFFCC',
    },
]

export const NewsIndex = () => {

    const [showNewsData, setShowNewsData] = useState<INewsData[]>([]);
    const [isChecktEntry, setIsChecktEntry] = useState<boolean>(false);
    const [checkAllEntries, setCheckAllEntries] = useState<boolean>(false);
    const [totalAccount, setTotalAccount] = useState<number>(0);
    const [displayMode, setDisplayMode] = useState<TdisplayMode>({ displayNormal: true, displayCompact: false, displaySplit: false });

    const [sendReminder, setSendReminder] = useState<boolean>(false);

    const [displayNormal, setDisplayNormal] = useState<boolean>(true);
    const [displayCompact, setDisplayCompact] = useState<boolean>(false);




    const handleDisplayNormal = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayNormal(event.target.checked);
        setDisplayCompact(false);
    }

    const handleDisplayCompact = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayNormal(false);
        setDisplayCompact(event.target.checked);
    }


    useEffect(() => {
        setShowNewsData(NewsData);
    }, []);

    const HandleCheckbox = (index: number) => {
        const temp = [...showNewsData];
        var count: number = 0;
        temp[index].isChecked = !temp[index].isChecked;
        setShowNewsData(temp);
        for (var i = 0; i < showNewsData.length; i++) {
            if (showNewsData[i].isChecked === true) {
                count++;
            }
        }
        setTotalAccount(count);
    }

    const HandleSelectAll = () => {
        const tempArray = [...showNewsData];
        for (var i = 0; i < tempArray.length; i++) {
            tempArray[i].isChecked = true;
        }
        setShowNewsData(tempArray);
        setTotalAccount(tempArray.length);
    }

    const handleToggleEntryCheck = () => {
        setIsChecktEntry(!isChecktEntry);
        const tempArray = [...showNewsData];
        for (var i = 0; i < tempArray.length; i++) {
            tempArray[i].isChecked = false;
        }
        setShowNewsData(tempArray);
        setTotalAccount(0);
    }
    const handleDisplayMode = (event: React.MouseEvent) => {
        var valueAttr = event.currentTarget.attributes.getNamedItem('value');
        var value = valueAttr ? valueAttr.value : null;
        if (value === 'normal') {
            var temp: TdisplayMode = { ...displayMode };
            temp.displayNormal = true;
            temp.displayCompact = false;
            temp.displaySplit = false;

            setDisplayMode(temp);

        } else {
            var temp: TdisplayMode = { ...displayMode };
            temp.displayCompact = true;
            temp.displayNormal = false;
            temp.displaySplit = false;

            setDisplayMode(temp);
        }
    }


    return (
        <>
            <Row>
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <div className="news-topbar mb-2">
                        <TopbarSelectFilterDropdown
                            showHideEntryCheck={handleToggleEntryCheck}
                            showChecks={isChecktEntry}
                            checkAllEntries={checkAllEntries}
                            handleSelectAll={HandleSelectAll}
                            displayMode={displayMode}
                            handleDisplayMode={handleDisplayMode}
                            totalAccount={totalAccount}
                        />
                    </div>
                    <Outlet context={[showNewsData, setShowNewsData, displayMode, isChecktEntry, setTotalAccount]} />
                </Col>
            </Row>
        </>
    )
}