import React, { useState } from 'react';
import TreeView from './TreeView';


type dataType = {
    id: number,
    label: string,
    isActive: boolean,
    parentId?: number,
    children?: dataType[],
}
//   const jsonData: dataType[] = [
//     {
//       id: 1,
//       label: 'Node 1',
//       isActive: false,
//       children: [
//         {
//           id: 2,
//           parentId: 1,
//           label: 'Node 1.1',
//           isActive: false,
//           children: [
//             {
//               id: 3,
//               parentId: 2,
//               label: 'Node 1.1.1',
//               isActive: false,
//             },
//             {
//               id: 4,
//               parentId: 2,
//               label: 'Node 1.1.2',
//               isActive: false,
//             }
//           ]
//         },
//         {
//           id: 5,
//           parentId: 1,
//           label: 'Node 1.2',
//           isActive: false,
//           children: [
//             {
//               id: 6,
//               parentId: 5,
//               label: 'Node 1.2.1',
//               isActive: false,
//               children: [
//                 {
//                   id: 7,
//                   parentId: 6,
//                   label: 'Node 1.2.1.1',
//                   isActive: false,
//                 },
//                 {
//                   id: 8,
//                   parentId: 6,
//                   label: 'Node 1.2.2.2',
//                   isActive: false,
//                 }
//               ]
//             },
//             {
//               id: 9,
//               parentId: 5,
//               label: 'Node 1.2.2',
//               isActive: false,
//             }
//           ]
//         }
//       ]
//     },
//     {
//       id: 10,
//       label: 'Node 2',
//       isActive: false,
//       children: [
//         {
//           id: 11,
//           parentId: 10,
//           label: 'Node 2.1',
//           isActive: false,
//         },
//         {
//           id: 12,
//           parentId: 10,
//           label: 'Node 2.2',
//           isActive: false,
//         }
//       ]
//     }
//   ];


interface ITreeWidgetProps {
    treeData: dataType[];
    getActiveLabel?: (label: string) => void;
}

function WidgetTreeView(props: ITreeWidgetProps) {
    const [treeData, setTreeData] = useState<dataType[]>(props.treeData);

    // &&&&&&&&&---- Function to find Node By ID  Begins ----&&&&&&&&&
    function findNodeById(nodes: dataType[], id: number) {
        for (const node of nodes) {
            if (node.id === id) {
                return node; // Found the node with the desired ID
            }
            if (node.children) {
                const result: any = findNodeById(node.children, id); // Recursively search in children
                if (result) {
                    return result; // Found the node with the desired ID in the children
                }
            }
        }
        return null; // ID not found in the tree
    }
    // &&&&&&&&&---- Function to find Node By ID  End ----&&&&&&&&&


    // &&&&&&&&&---- Function for handling nodes Begins ----&&&&&&&&&
    function triggerNode(data: any, id: any, updatedObject: any) {
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            if (data[i].id === id) {
                if (data[i].isActive === true) {
                    data[i].isActive = false;
                } else {
                    data[i].isActive = true;
                }

                return true;
            }

            if (data[i].children) {
                const childResult = triggerNode(data[i].children, id, updatedObject);
                if (childResult) {
                    return true;
                }
            }
        }

        return false;
    }
    // &&&&&&&&&---- Function for handling nodes End ----&&&&&&&&&


    // &&&&&&&&&---- Function for handle Node Click  Begins ----&&&&&&&&&
    function handleNodeClick(data: any) {
        debugger
        let innerTemp = { ...data };
        let temp = [...treeData];
        const desiredId = innerTemp.id;

        if (props.getActiveLabel) {
            props.getActiveLabel(innerTemp.label);
        }

        if (innerTemp.isActive === false) {

            if (innerTemp.hasOwnProperty('parentId')) {

                const parentNode = findNodeById(temp, innerTemp.parentId);
                for (let i = 0; i < parentNode?.children.length; i++) {
                    parentNode.children[i].isActive = false;
                }
                // if (innerTemp.hasOwnProperty('children')) {
                //     for (let i = 0; i < parentNode?.children.length; i++) {
                //         parentNode.children[i].isActive = false;
                //     }
                // } else {
                //     for (let i = 0; i < parentNode?.children.length; i++) {
                //         if (!parentNode.children[i].hasOwnProperty('children')) {
                //             parentNode.children[i].isActive = false;
                //         }
                //     }
                // }

            } else {
                for (var i = 0; i < temp.length; i++) {
                    temp[i].isActive = false;
                }
            }
        } else {
            const currentNode = findNodeById(temp, innerTemp.id);
            if (currentNode.hasOwnProperty('children')) {
                for (let i = 0; i < currentNode?.children.length; i++) {
                    currentNode.children[i].isActive = false;
                }
            }

        }
        // console.log(temp);

        triggerNode(temp, desiredId, innerTemp);
        // console.log(temp);

        setTreeData(temp);
    }
    // &&&&&&&&&---- Function for handle Node Click  End ----&&&&&&&&&
    return (
        <>
            <TreeView treeData={treeData} getNode={handleNodeClick} />
        </>
    );
}

export default WidgetTreeView;