import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { HiCog } from 'react-icons/hi';
import { HiHome } from 'react-icons/hi2';
import { FaClock } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import AddEditMessage from './AddEditMessage';
import Recipient from '../../Components/Modals/Recipient';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom';
import AccordionMenu from '../../Components/AccordionMenu';
import DatePicker from '../../Components/DatePicker';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { HiOutlinePlusCircle } from "react-icons/hi";



function Sidebar() {
    const [MessageModal, setMessageModal] = useState(false);
    const [RecipientModal, setRecipientModal] = useState(false);

    return (
        <>
            <Card className='h-100'>
                <Card.Header className='bg-white d-flex justify-content-center align-items-center'>
                    <Card.Title>
                        <FaClock className='me-2' size="20px" />
                    </Card.Title>
                    <Card.Title>NACHRICHTEN</Card.Title>
                </Card.Header>
                <Card.Body className='px-0'>
                    <div className='modal-button-box d-flex align-items-center justify-content-center'>
                        <Button onClick={() => setMessageModal(true)} className='primary-bg f-14px d-flex align-items-center justify-content-center'><FaClock className='me-2' size="14px" /> Nachricht erstellen</Button>
                    </div>
                    <div className='mt-3 px-3 border-top'>
                        <div className='side-nav'>
                            <ul>
                                <li className="sub">
                                    <input type="checkbox" />
                                    <a className="" href="javascript:;" title="Unser Produktsortiment">Meine</a>
                                    <ul className="submenu">
                                        <li><a href="javascript:;" title="Von mir">Von mir</a></li>
                                        <li><a href="javascript:;" title="An mich">An mich</a></li>
                                        <li><a href="javascript:;" title="Mit mir">Mit mir</a></li>
                                        <li><a href="javascript:;" title="Kommentiert">Kommentiert</a></li>
                                        <li><a href="javascript:;" title="Unveröffentlich">Unveröffentlicht</a></li>
                                    </ul>
                                </li>
                                <li className="sub">
                                    <input type="checkbox" />
                                    <a href="#" title="Mein Archiv">Mein Archiv</a>
                                    <ul className="submenu">
                                        <li><a href="javascript:;" title="Von mir">Von mir</a></li>
                                        <li><a href="javascript:;" title="An mich">An mich</a></li>
                                        <li><a href="javascript:;" title="Mit mir">Mit mir</a></li>
                                        <li><a href="javascript:;" title="Kommentiert">Kommentiert</a></li>
                                        <li><a href="javascript:;" title="Unveröffentlich">Unveröffentlicht</a></li>
                                    </ul>
                                </li>
                                <li><a href="#" title="So erreichen Sie uns">Alle</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='p-3 border-top'>
                        <div className='mb-3'>
                            <DatePicker />
                        </div>
                        <div>
                            <DatePicker />
                        </div>
                    </div>
                    <div className='p-3 border-top'>
                        <InputGroup size="sm">
                            <Form.Control
                                aria-label="ad"
                                aria-describedby="basic-addon1"
                                placeholder='Nutzer/Gruppe filtern'
                            />
                            <Button variant="outline-secondary" id="ad" onClick={() => setRecipientModal(true)}>
                                <HiOutlinePlusCircle size="20px" />
                            </Button>
                        </InputGroup>
                    </div>
                    <div className='p-3 border-top'>
                        <AccordionMenu />
                    </div>
                </Card.Body>
            </Card>
            <AddEditMessage showMessange={MessageModal} hideMessange={() => setMessageModal(false)} />
            <Recipient displayRecipientModal={RecipientModal} hideRecipientModal={() => setRecipientModal(false)} />
        </>
    );
}

export default Sidebar;