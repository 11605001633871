import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IinitialState {
    showLoader: boolean,
    theme: {
        colors: {
            primaryColor: string
        }
    }
}

const initialState: IinitialState = {
    showLoader: false,
    theme: {
        colors: {
            primaryColor: '#0D3F5F'
        }
    }

}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setShowLoader: (state, action: PayloadAction<boolean>) => {
            state.showLoader = action.payload
        },
        setThemeColor: (state, action: PayloadAction<string>) => {
            state.theme.colors.primaryColor = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setShowLoader, setThemeColor } = appSlice.actions

export default appSlice.reducer