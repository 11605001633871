import React, { useState, useEffect, FC, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { AccountSettingImg, BodyText, BorderBox, Box, DisplayBetween, DisplayCenter, DisplayStart, Divider, HeadingWithDivider, HighlightBox, UploadImgLabel } from '../../../Components/StyledComponents';
import FormControl from '../../../Components/FormControl';
import DatePicker from '../../../Components/DatePicker';
import FormCheck from '../../../Components/FormCheck';
import FormSelect from '../../../Components/FormSelect';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { GrUploadOption } from 'react-icons/gr';
import { FaCrop, FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import ButtonWidget from '../../../Components/ButtonWidget';
import CustomTooltip from '../../../Components/Tooltip';
import Recipient from '../../../Components/Modals/Recipient';
import CommonModal from '../../../Components/Modals/CommonModal';
import CropImgModalContent from '../../../Components/Modals/ModelContents/CropImgModalContent';
import Cropper, { ReactCropperElement } from "react-cropper";
import { Link } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UserProfileDto } from '../../../Model/ViewModels/User/UserProfileDto';
import { SubmitHandler, useForm } from "react-hook-form";
import { InputTextControl } from '../../../Components/Widgets/InputControls/InputTextControl';
import { CheckControl } from '../../../Components/Widgets/InputControls/CheckControl';
import { SelectControl } from '../../../Components/Widgets/InputControls/SelectControl';
import { DateTimeControl } from '../../../Components/Widgets/InputControls/DateTimeControl';
import UserService from '../../../APIService/userService';
import { toast } from 'react-hot-toast';
import { BlockTools, homePageOptions3, languageOptions, reportTypeOptions2 } from '../../../CommonJSON';

const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
        lastName: yup
            .string()
            .typeError("Last name is required")
            .required("Last name is required"),
        position: yup
            .string()
            .typeError("Position is required")
            .required("Position is required"),
        department: yup
            .string()
            .typeError("Department is required")
            .required("Department is required"),
        phoneNumber: yup
            .string()
            .typeError("Phone is required")
            .required("Phone is required"),
        gender: yup
            .string()
            .typeError("Gender is required")
            .required("Gender is required"),
        dob: yup
            .date()
            .typeError("DOB is required")
            .required("DOB is required"),
        entryDate: yup
            .date()
            .typeError("Entry date is required")
            .required("Entry date is required"),
        email: yup
            .string()
            .typeError("Email is required")
            .required("Email is required"),
    })
    .required();


export const UserAdminModal = (props: any) => {
    const userService = new UserService();
    const [ImgSource, setImgSource] = useState<string>("");
    const [cropImgModal, setCropImgModal] = useState<boolean>(false);
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    const [dateOpinion, setdateOpinion] = useState<boolean>(false);
    const [addTitle, setAddTitle] = useState<boolean>(false);
    const [externalUser, setExternalUser] = useState<boolean>(false);
    const [showFemale, setShowFemale] = useState<boolean>(false);
    const [showMale, setShowMale] = useState<boolean>(false);
    const [showDriver, setShowDriver] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<typeof BlockTools>([]);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
    });

    const { gender } = watch();

    interface optionType {
        label: string,
        value: string
    }

    const handleTitleChange = () => {
        setAddTitle(!addTitle);
    }

    useEffect(() => {
        const abortController = new AbortController();
        setSelectAll(BlockTools);
        return () => {
            // Component unmount code.
            abortController.abort();
        };
    }, []);

    const handleSelectAll = () => {
        let temp = [...selectAll];
        for (var i = 0; i < temp.length; i++) {
            temp[i].isCheck = true;
        }
        setSelectAll(temp);
    }

    const handleChangeOne = (index: number) => {
        let temp = [...selectAll];
        setSelectAll(temp);
    }


    const handleImgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            var src = URL.createObjectURL(selectedFile);
            setImgSource(src);
        }
    };

    const cropperRef = useRef<ReactCropperElement>();
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setImgSource(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setCropImgModal(false);
        }
    };

    const onSubmit: SubmitHandler<UserProfileDto> = async (
        data: UserProfileDto,
        event: any
    ) => {
        debugger;
        if (data) {
            data.userImage = "None";
            userService
                .saveProfile(data)
                .then((result) => {
                    toast.success("Save Successful");
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error(error.message);
                    }
                });
        }
    };

    return (
        <>
            <Form ref={props.formRef} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={2} xs={12}>
                        <BodyText fontSize='14px' fontWeight='500' textColor='#666' mb="10px">
                            Profil
                        </BodyText>
                    </Col>
                    <Col md={10} xs={12}>
                        {addTitle ?
                            <>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <Box mb="10px">
                                            <DisplayStart mb="8px">
                                                <BodyText fontSize='14px' fontWeight='400' textColor='#666' className='text-nowrap'>
                                                    Titel (Präfix)
                                                </BodyText>
                                                <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                                                    <CustomTooltip
                                                        ToolTipTrigger={<BsQuestionCircleFill />}
                                                        toolTipContent="Der Titel ist nicht Teil Ihres
                                    Benutzernamens_ Ihr
                                    Benutzername besteht
                                    ausschließlich aus Ihrem Vor- und
                                    Nachnamen."
                                                        toolTipPlacement="top"
                                                    />
                                                </span>
                                            </DisplayStart>
                                            <FormControl Type='text' Size='sm' Placeholder='' Name='emailAddress' Id='emailAddress' />
                                        </Box>
                                        <Box mb="10px">
                                            <Form.Group controlId='Anrede'>

                                                <DisplayStart>
                                                    <FormCheck
                                                        type='checkbox'
                                                        name='phone'
                                                        Id='phone'
                                                        value='Titel im Anzeigenamen ausblenden'
                                                        label='Titel im Anzeigenamen ausblenden'
                                                        isFormGroup={false}
                                                        isInline={true}
                                                        disabled={false}
                                                    />
                                                    <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                                                        <CustomTooltip
                                                            ToolTipTrigger={<BsQuestionCircleFill />}
                                                            toolTipContent="1st dieses Kästchen angekreuzt,
                                                            werden nur Vor- und Nachname
                                                            im System angezeigt."
                                                            toolTipPlacement="top"
                                                        />
                                                    </span>
                                                </DisplayStart>
                                            </Form.Group>
                                        </Box>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <Box mb="10px">
                                            <DisplayBetween>
                                                <DisplayStart mb="8px">
                                                    <BodyText fontSize='14px' fontWeight='400' textColor='#666' className='text-nowrap'>
                                                        Titel (Suffix)
                                                    </BodyText>
                                                    <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                                                        <CustomTooltip
                                                            ToolTipTrigger={<BsQuestionCircleFill />}
                                                            toolTipContent="Der Titel ist nicht Teil Ihres
                                                        Benutzernemens. Ihr
                                                        Benutzerneme besteht
                                                        ausschließlich aus Ihrem Vor- und
                                                        Nachnamen."
                                                            toolTipPlacement="top"
                                                        />
                                                    </span>
                                                </DisplayStart>
                                                <Link to="" className='text-decoration-none mb-2' onClick={handleTitleChange}>
                                                    <span className='fw-400 w-176px text-truncate text-link-color f-14px'> Titel entfernen </span>
                                                </Link>
                                            </DisplayBetween>

                                            <FormControl Type='text' Size='sm' Placeholder='' Name='emailAddress' Id='emailAddress' />
                                        </Box>
                                    </Col>
                                </Row>
                            </>
                            : null}

                        <Row>
                            <Col md={6} xs={12}>
                                <Box mb="10px">
                                    <Form.Label>Vorname</Form.Label>
                                    <InputTextControl
                                        control={control}
                                        fieldName={"firstName"}
                                        error={errors.firstName}
                                        placeholder="Vorname"
                                        fieldType="text"
                                        size={"sm"}
                                    />
                                </Box>
                                <Box mb="10px">
                                    <Form.Label> Position </Form.Label>
                                    <InputTextControl
                                        control={control}
                                        fieldName={"position"}
                                        error={errors.position}
                                        placeholder="Position"
                                        fieldType="text"
                                        size={"sm"}
                                    />
                                </Box>
                                <Box mb="10px">
                                    <Form.Label> Telefonnummer </Form.Label>
                                    <InputTextControl
                                        control={control}
                                        fieldName={"phoneNumber"}
                                        error={errors.phoneNumber}
                                        placeholder="phoneNumber"
                                        fieldType="text"
                                        size={"sm"}
                                    />
                                </Box>
                                <Box mb="10px">
                                    <DisplayStart mb="8px">
                                        <BodyText fontSize='14px' fontWeight='400' textColor='#666' className='text-nowrap'>
                                            Geburtsdatum
                                        </BodyText>
                                        <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                                            <CustomTooltip
                                                ToolTipTrigger={<BsQuestionCircleFill />}
                                                toolTipContent="Dies Wird für alle in deinemNetzwerk sichtbar sein"
                                                toolTipPlacement="top"
                                            />
                                        </span>
                                    </DisplayStart>
                                    <DateTimeControl
                                        name="dob"
                                        control={control}
                                        error={errors.dob}
                                    />
                                </Box>
                                <Box mb="10px">
                                    <DisplayStart mb="8px">
                                        <BodyText fontSize='14px' fontWeight='400' textColor='#666' className='text-nowrap'>
                                            E-Mail-Adresse
                                        </BodyText>
                                        <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                                            <CustomTooltip
                                                ToolTipTrigger={<BsQuestionCircleFill />}
                                                toolTipContent="Deine personlicne Adresse. Du kannst Benachrichtigungen via E-Mail erhalten."
                                                toolTipPlacement="top"
                                            />
                                        </span>
                                    </DisplayStart>
                                    <InputTextControl
                                        control={control}
                                        fieldName={"email"}
                                        error={errors.email}
                                        placeholder="Email"
                                        fieldType="email"
                                        size={"sm"}
                                    />
                                </Box>
                                <Box mb="10px">
                                    <Form.Group controlId='Anrede'>
                                        <CheckControl
                                            fieldType={"checkbox"}
                                            control={control}
                                            fieldName={"showEmail"}
                                            error={errors.showEmail}
                                            value="showEmail"
                                            label="E-Mail-Adresse nicht anzeigen (außer für Admins)"
                                            isInline={true}
                                            disabled={false}
                                            checked={defaultValues?.showEmail}
                                            defaultChecked={false}
                                        />
                                    </Form.Group>
                                </Box>
                            </Col>
                            <Col md={6} xs={12}>
                                <Box mb="10px">
                                    <DisplayBetween>
                                        <Form.Label>Nachname</Form.Label>
                                        {addTitle ?
                                            null
                                            :
                                            <>
                                                <Link to="" className='text-decoration-none mb-2' onClick={handleTitleChange}>
                                                    <span className='fw-400 w-176px text-truncate text-link-color f-14px'> Titel entternen </span>
                                                </Link>
                                            </>
                                        }

                                    </DisplayBetween>

                                    <InputTextControl
                                        control={control}
                                        fieldName={"lastName"}
                                        error={errors.lastName}
                                        placeholder="Last Name"
                                        fieldType="text"
                                        size={"sm"}
                                    />
                                </Box>
                                <Box mb="10px">
                                    <Form.Label> Abteilung </Form.Label>
                                    <InputTextControl
                                        control={control}
                                        fieldName={"department"}
                                        error={errors.department}
                                        placeholder="Department"
                                        fieldType="text"
                                        size={"sm"}
                                    />
                                </Box>
                                <Box mb="10px">
                                    <Form.Label> Anrede </Form.Label>
                                    <Form.Group controlId='Anrede' className="mb-3">
                                        <CheckControl
                                            fieldType={"radio"}
                                            control={control}
                                            fieldName={"gender"}
                                            error={errors.gender}
                                            value="Weiblich"
                                            label="Weiblich"
                                            isInline={true}
                                            disabled={false}
                                            checked={gender == "Weiblich" ? true : false}
                                            defaultChecked={false}
                                        />
                                        <CheckControl
                                            fieldType={"radio"}
                                            control={control}
                                            fieldName={"gender"}
                                            error={errors.gender}
                                            value="Männlich"
                                            label="Männlich"
                                            isInline={true}
                                            disabled={false}
                                            checked={gender == "Männlich" ? true : false}
                                            defaultChecked={false}
                                        />
                                        <CheckControl
                                            fieldType={"radio"}
                                            control={control}
                                            fieldName={"gender"}
                                            error={errors.gender}
                                            value="Divers"
                                            label="Divers"
                                            isInline={true}
                                            disabled={false}
                                            checked={gender == "Divers" ? true : false}
                                            defaultChecked={false}
                                        />
                                    </Form.Group>
                                    {showDriver ?
                                        <>
                                            <SelectControl
                                                fieldId={"salutationId"}
                                                fieldName={"salutationId"}
                                                control={control}
                                                error={errors.salutationId}
                                                selectOptions={reportTypeOptions2}
                                            />
                                        </>
                                        : null}
                                </Box>
                                <Box mb="10px">
                                    <Form.Label> Eintrittsdatum </Form.Label>
                                    <DateTimeControl
                                        name="entryDate"
                                        control={control}
                                        error={errors.entryDate}
                                    />
                                </Box>
                                <Box mb="10px">
                                    <Form.Label> E-Mail-Adresse bestätigen </Form.Label>
                                    <InputTextControl
                                        control={control}
                                        fieldName={"confirmEmail"}
                                        error={errors.email}
                                        placeholder="E-Mail-Adresse bestätigen"
                                        fieldType="email"
                                        size={"sm"}
                                    />
                                </Box>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider mt="6px" mb="14px" />
                <Row>
                    <Col md={2} xs={12}>
                        <DisplayStart>
                            <BodyText fontSize='14px' fontWeight='500' textColor='#666'>
                                Foto
                            </BodyText>
                            <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                                <CustomTooltip
                                    ToolTipTrigger={<BsQuestionCircleFill />}
                                    toolTipContent="Max 10 Mb."
                                    toolTipPlacement="top"
                                />
                            </span>
                        </DisplayStart>
                    </Col>
                    <Col md={10} xs={12}>
                        <Row className="mb-1">
                            <Col md={6} xs={12}>
                                <Row>
                                    <Col md={6} className="pe-0" xs={12}>
                                        <Box mb="10px">
                                            <input
                                                accept="image/*"
                                                style={{ display: "none" }}
                                                id="upload-img"
                                                type="file"
                                                onChange={handleImgChange}
                                            />
                                            <UploadImgLabel htmlFor="upload-img" className="w-100">
                                                <DisplayCenter>
                                                    <GrUploadOption />
                                                    <span className="ms-1">Bild hochladen</span>
                                                </DisplayCenter>
                                            </UploadImgLabel>
                                        </Box>
                                        <Box mb="10px">
                                            {!!ImgSource ? (
                                                <ButtonWidget
                                                    varient="light"
                                                    class="w-100"
                                                    text={
                                                        <>
                                                            <DisplayCenter>
                                                                <FaRegTimesCircle />
                                                                <span className="ms-1">Bild löschen</span>
                                                            </DisplayCenter>
                                                        </>
                                                    }
                                                    handleClick={() => setImgSource("")}
                                                />
                                            ) : null}
                                        </Box>
                                        <Box mb="10px">
                                            {!!ImgSource ? (
                                                <ButtonWidget
                                                    varient="light"
                                                    class="w-100"
                                                    text={
                                                        <>
                                                            <DisplayCenter>
                                                                <FaCrop />
                                                                <span className="ms-1">Bild zuschneiden</span>
                                                            </DisplayCenter>
                                                        </>
                                                    }
                                                    handleClick={() => setCropImgModal(true)}
                                                />
                                            ) : null}
                                        </Box>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <DisplayCenter>
                                            {showFemale ?
                                                <>
                                                    <AccountSettingImg
                                                        src={`${!!ImgSource ? ImgSource : "/images/assets/female.png"
                                                            }`}
                                                    />
                                                </>
                                                : null}
                                            {showMale ?
                                                <>
                                                    <AccountSettingImg
                                                        src={`${!!ImgSource ? ImgSource : "/images/assets/male.png"
                                                            }`}
                                                    />
                                                </>
                                                : null}
                                            {showDriver ?
                                                <>
                                                    <AccountSettingImg
                                                        src={`${!!ImgSource ? ImgSource : "/images/assets/driver.png"
                                                            }`}
                                                    />
                                                </>
                                                : null}

                                        </DisplayCenter>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} xs={12}>
                                <Box mb="10px">
                                    <Form.Check
                                        label={'Zeige mein Nutzerbild'}
                                        name="group1"
                                        type="checkbox"
                                    />
                                </Box>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider mt="6px" mb="14px" />
                <Row>
                    <Col md={2} xs={12}>
                        <DisplayStart>
                            <BodyText fontSize='14px' fontWeight='500' textColor='#666'>
                                Gruppen
                            </BodyText>
                        </DisplayStart>
                    </Col>
                    <Col md={10} xs={12}>
                        <Row className="mb-1">
                            <Col md={8}>
                                <Box mb="10px">
                                    <FormControl Type='text' Size='sm' Placeholder='' Name='emailAddress' Id='emailAddress' />
                                </Box>
                            </Col>
                            <Col md={4}>
                                <Box mb="10px">
                                    <ButtonWidget
                                        varient="light"
                                        class="w-100"
                                        text={
                                            <>
                                                <DisplayCenter>
                                                    <FaPlusCircle className="default-btn-icon" />
                                                    <span className="ms-1 text-nowrap">Alle Kategorien anzeigen</span>
                                                </DisplayCenter>
                                            </>
                                        }
                                        handleClick={(event) => setRecipientModal(true)}
                                    />
                                </Box>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider mt="6px" mb="14px" />
                <Row>
                    <Col md={2} xs={12}>
                        <DisplayStart>
                            <BodyText fontSize='14px' fontWeight='500' textColor='#666' className='text-nowrap'>
                                Externer Nutzer
                            </BodyText>
                            <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                                <CustomTooltip
                                    ToolTipTrigger={<BsQuestionCircleFill />}
                                    toolTipContent="Der Zugriff zu verschiedenen Sektionen des Systems kann für externe Nutzer beschränkt werden"
                                    toolTipPlacement="top"
                                />
                            </span>
                        </DisplayStart>
                    </Col>
                    <Col md={10} xs={12}>
                        <Box mb="10px">
                            <Form.Check
                                label={'Zeige mein Nutzerbild'}
                                name="group1"
                                type="checkbox"
                                onChange={() => setExternalUser(!externalUser)}
                            />
                        </Box>
                    </Col>
                </Row>
                {externalUser ?
                    <>
                        <Row>
                            <Col md={6} xs={12}>
                                <Box>
                                    <BodyText fontSize='15px' fontWeight='700' textColor='#666' className='text-nowrap'>
                                        Sperre folgende Tools/Bereiche:
                                    </BodyText>
                                </Box>
                            </Col>
                            <Col md={6} xs={12}>
                                <Box>
                                    <ButtonWidget varient="light" text="Sperre alle" class='' handleClick={(index: any) => handleSelectAll()} />
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            {selectAll.map((item, index) => {
                                return (
                                    <>
                                        <Col md={6} xs={12}>
                                            <Box mt="5px" mb="5px" key={index}>
                                                <Form.Check
                                                    label={item.title}
                                                    name="group1"
                                                    type="checkbox"
                                                    checked={item.isCheck}
                                                    onChange={() => handleChangeOne(index)}
                                                />
                                            </Box>
                                        </Col>
                                    </>
                                )
                            })}
                        </Row>
                    </>
                    : null}
                <Divider mt="6px" mb="14px" />
                <Row>
                    <Col md={2} xs={12}>
                        <DisplayStart>
                            <BodyText fontSize='14px' fontWeight='500' textColor='#666'>
                                Sprache
                            </BodyText>
                        </DisplayStart>
                    </Col>
                    <Col md={10} xs={12}>
                        <Box mb="10px">
                            <div className='w-50'>
                                <FormSelect
                                    isFormGroup={true}
                                    selectOptions={languageOptions}
                                    Size="sm"
                                    Name="salute"
                                    Id="salute"
                                />
                            </div>
                        </Box>
                    </Col>
                </Row>
                <Divider mt="6px" mb="14px" />
                <Row>
                    <Col md={2} xs={12}>
                        <DisplayStart>
                            <BodyText fontSize='14px' fontWeight='500' textColor='#666'>
                                Startseite
                            </BodyText>
                        </DisplayStart>
                    </Col>
                    <Col md={10} xs={12}>
                        <Box mb="10px">
                            <div className='w-50'>
                                <FormSelect
                                    isFormGroup={true}
                                    selectOptions={homePageOptions3}
                                    Size="sm"
                                    Name="salute"
                                    Id="salute"
                                />
                            </div>
                        </Box>
                    </Col>
                </Row>
                <Divider mt="6px" mb="14px" />
                <Row>
                    <Col md={2} xs={12}>
                        <DisplayStart>
                            <BodyText fontSize='14px' fontWeight='500' textColor='#666'>
                                Ansicht
                            </BodyText>
                        </DisplayStart>
                    </Col>
                    <Col md={10} xs={12}>
                        <Row>
                            <Col md={7} xs={12}>
                                <Box>
                                    <FormCheck
                                        type="checkbox"
                                        name="dateOpinion"
                                        Id="dateOpinion"
                                        value=""
                                        label="Datum immer ausschreiben"
                                        isFormGroup={true}
                                        isInline={true}
                                        disabled={false}
                                        handleChange={() => setdateOpinion(!dateOpinion)}
                                    />
                                </Box>
                            </Col>
                            <Col md={5} xs={12}>
                                <Box>
                                    {!!dateOpinion ? (
                                        <BodyText textColor="#bbb" fontSize="14">
                                            Z.B.: 17.05.2023 08:49
                                        </BodyText>
                                    ) : (
                                        <CustomTooltip
                                            ToolTipTrigger={
                                                <BodyText textColor="#bbb" fontSize="14">
                                                    Z.B.: vor 2 Tagen
                                                </BodyText>
                                            }
                                            toolTipContent="1705.2023 08:49"
                                            toolTipPlacement="top"
                                        />
                                    )}
                                </Box>
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Divider mt="6px" mb="14px" />
                <Row>
                    <Col md={2} xs={12}>
                        <DisplayStart>
                            <BodyText fontSize='14px' fontWeight='500' textColor='#666'>
                                Sicherheit
                            </BodyText>
                        </DisplayStart>
                    </Col>
                    <Col md={10} xs={12}>
                        <Row>
                            <Col md={6} xs={12}>
                                <Box mb="10px">
                                    <Form.Label>Passwort</Form.Label>
                                    <FormControl Type='password' Size='sm' Placeholder='' Name='emailAddress' Id='emailAddress' />
                                </Box>
                                <Box mb="10px">
                                    <Form.Label>Passwort bestätigen</Form.Label>
                                    <FormControl Type='password' Size='sm' Placeholder='' Name='emailAddress' Id='emailAddress' />
                                </Box>
                            </Col>
                            <Col md={6} xs={12}>
                                <Box>
                                    <ButtonWidget varient="light" text="Passwort generjeren" class='w-100' />
                                </Box>
                                <Box mt="10px">
                                    <HighlightBox
                                        className="mb-2 text-center"
                                        bgColor="#d9edf7"
                                        borderColor="#bce8f1"
                                        textColor="#31708f"
                                    >
                                        Das Passwort fÜr diesen Nutzer lautet:
                                        <br />
                                        xQ29fM98
                                        <br />
                                        Bitte schreibe es aut.
                                        <br />
                                        <ButtonWidget varient="light" text="Passwort in Zwischenablage kopjeren" class='' />
                                    </HighlightBox>
                                </Box>
                            </Col>

                        </Row>
                    </Col>
                </Row>

            </Form>

            <Recipient
                displayRecipientModal={RecipientModal}
                hideRecipientModal={() => setRecipientModal(false)}
            />
            <CommonModal
                showModal={cropImgModal}
                hideModal={() => setCropImgModal(false)}
                modalTitle="Bild zuschneiden"
                modalContent={
                    <CropImgModalContent ImgSrc={ImgSource} CropRef={cropperRef} />
                }
                modalSize="lg"
                modalFooterContent={
                    <CropImgModalFooterContent
                        hideModel={() => setCropImgModal(false)}
                        saveCrop={getCropData}
                    />
                }
            />

        </>
    )
}

interface ICropImgFooterProps {
    hideModel: () => void;
    saveCrop: () => void;
}


const CropImgModalFooterContent: FC<ICropImgFooterProps> = ({
    hideModel,
    saveCrop,
}) => {
    return (
        <>
            <ButtonWidget varient="light" text="Abbrechen" handleClick={hideModel} />
            <ButtonWidget varient="primary" text="Erstellen" handleClick={saveCrop} />
        </>
    );
};
