
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaBookmark, FaRegBookmark, FaRegStar, FaStar } from 'react-icons/fa';
import { BodyText, DisplayStart } from '../StyledComponents';
import { JsxElement } from 'typescript';
import ButtonWidget from '../ButtonWidget';

type ActionButtonProps = {
    bookMarkBtn?: boolean,
    noticeBtn?: boolean,
    isDropdownIcon?: boolean,
    hideHomeScreen?: () => void,
    hideStarModal?: () => void,
    hideNoticeModal?: () => void,
    hidePrintPdf?: () => void,
    hideDeleteOrder?: () => void,
    hideDeleteMessage?: () => void,
    hideReportContent?: () => void,
    hideUnsavedContent?: () => void,
    hidepermission?: () => void,
    hideAddPoll?: () => void,
    hideCompareVersion?: () => void,
    hideArticleTranslation?: () => void,
    hideSendEmail?: () => void,
    hideEditHandbook?: () => void,
    pageName: string,
    handleDisableNotification?: () => void,
    notificationTextToggle?: boolean,
    commentTextBoxToggle?: boolean,
    handleCommentTextBoxToggle?: () => void,
    addEditRepairModal?: () => void,
    addEditTaskModal?: () => void,
    addEditIdeaModal?: () => void,
    addEditNewsModal?: () => void,
    addEditMessageModal?: () => void,
}

export const GroupActionButtonWidget = (props: ActionButtonProps) => {

    const {
        bookMarkBtn,
        noticeBtn,
        isDropdownIcon,
        hideHomeScreen,
        hideStarModal,
        hideNoticeModal,
        hidePrintPdf,
        hideDeleteOrder,
        pageName,
        hideDeleteMessage,
        hideReportContent,
        hideUnsavedContent,
        hidepermission,
        hideAddPoll,
        hideCompareVersion,
        hideSendEmail,
        hideArticleTranslation,
        hideEditHandbook,
        notificationTextToggle,
        commentTextBoxToggle,
        addEditRepairModal,
        addEditTaskModal,
        addEditIdeaModal,
        addEditNewsModal,
        addEditMessageModal,
    } = props;

    const [isBookMark, setIsBookMark] = useState<boolean>(false);
    const [isNotice, setIsNotice] = useState<boolean>(false);

    const handleBookMark = () => {
        setIsBookMark(true);
    }

    const handleNoticed = () => {
        setIsNotice(true);
    }


    return (
        <>
            {pageName === "repairPage" ?
                <ButtonGroup className='custom-btn-group'>
                    {bookMarkBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isBookMark ?
                                            <DisplayStart>
                                                <FaStar size={12} />
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegStar size={12} />
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleBookMark(); hideStarModal?.(); }}
                            />
                        </>
                        :
                        null
                    }

                    {noticeBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isNotice ?
                                            <DisplayStart>
                                                <FaBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Gemerkt </BodyText>
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Merken </BodyText>
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleNoticed(); hideNoticeModal?.(); }}
                            />
                        </>
                        :
                        null
                    }
                    {isDropdownIcon ?
                        <DropdownButton as={ButtonGroup} title={false} id="custom-bg-dropdown" varient='light'>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hidePrintPdf?.()}> PDF erstellen / Drucken </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { props.handleDisableNotification?.(); }}>
                                {notificationTextToggle ?
                                    <BodyText fontSize='12px' fontWeight='400'> Benachrichtigungen deaktivieren </BodyText>
                                    :
                                    <BodyText fontSize='12px' fontWeight='400'> Beobachten </BodyText>
                                }
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => addEditRepairModal?.()}> Bearbeiten </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideDeleteOrder?.()}> Löschen</BodyText>
                            </Dropdown.Item>
                        </DropdownButton>
                        :
                        null
                    }
                </ButtonGroup>
                : null}
            {pageName === "messageDetailPage" ?
                <ButtonGroup className='custom-btn-group'>
                    {bookMarkBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isBookMark ?
                                            <DisplayStart>
                                                <FaStar size={12} />
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegStar size={12} />
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleBookMark(); hideStarModal?.(); }}
                            />
                        </>
                        :
                        null
                    }

                    {noticeBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isNotice ?
                                            <DisplayStart>
                                                <FaBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Gemerkt </BodyText>
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Merken </BodyText>
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleNoticed(); hideNoticeModal?.(); }}
                            />
                        </>
                        :
                        null
                    }
                    {isDropdownIcon ?
                        <DropdownButton as={ButtonGroup} title={false} id="custom-bg-dropdown" className="btn-group-blue-01">
                            <Dropdown.Item onClick={() => addEditMessageModal?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Bearbeiten </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideUnsavedContent?.()}> Ungelesen markieren </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hidePrintPdf?.()}> PDF erstellen / Drucken</BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { props.handleDisableNotification?.(); }}>
                                {notificationTextToggle ?
                                    <BodyText fontSize='12px' fontWeight='400'> Benachrichtigungen deaktivieren </BodyText>
                                    :
                                    <BodyText fontSize='12px' fontWeight='400'> Beobachten </BodyText>
                                }
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400'> Dearchivieren </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideAddPoll?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Umfrage hinzufügen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { props.handleCommentTextBoxToggle?.(); }}>
                                {commentTextBoxToggle ?
                                    <BodyText fontSize='12px' fontWeight='400'> Kommentare schließen </BodyText>
                                    :
                                    <BodyText fontSize='12px' fontWeight='400'> Kommentare öffnen </BodyText>
                                }
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hidepermission?.()}> Berechtigungen anzeigen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideHomeScreen?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Auf Startseite anzeigen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideDeleteMessage?.()}> Löschen</BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideReportContent?.()}> Inhalt melden </BodyText>
                            </Dropdown.Item>
                        </DropdownButton>
                        :
                        null
                    }
                </ButtonGroup>
                : null}

            {pageName === "handbookDetailPage" ?
                <ButtonGroup className='custom-btn-group'>
                    {bookMarkBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isBookMark ?
                                            <DisplayStart>
                                                <FaStar size={12} />
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegStar size={12} />
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleBookMark(); hideStarModal?.(); }}
                            />
                        </>
                        :
                        null
                    }

                    {noticeBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isNotice ?
                                            <DisplayStart>
                                                <FaBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Gemerkt </BodyText>
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Merken </BodyText>
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleNoticed(); hideNoticeModal?.(); }}
                            />
                        </>
                        :
                        null
                    }
                    {isDropdownIcon ?
                        <DropdownButton as={ButtonGroup} title={false} id="custom-bg-dropdown" className="btn-group-blue-01">
                            <Dropdown.Item onClick={() => hideEditHandbook?.()}>
                                <BodyText fontSize='12px' fontWeight='400' className='text-nowrap'> Bearbeiten </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hidePrintPdf?.()} className='text-nowrap'> PDF erstellen / Drucken</BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { props.handleDisableNotification?.(); }}>
                                {notificationTextToggle ?
                                    <BodyText fontSize='12px' fontWeight='400' className='text-nowrap'> Benachrichtigungen deaktivieren </BodyText>
                                    :
                                    <BodyText fontSize='12px' fontWeight='400' className='text-nowrap'> Beobachten </BodyText>
                                }
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideCompareVersion?.()}>
                                <BodyText fontSize='12px' fontWeight='400' className='text-nowrap'> Versionen anzeigen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideSendEmail?.()}>
                                <BodyText fontSize='12px' fontWeight='400' className='text-nowrap'> Als E-Mail senden </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hidepermission?.()} className='text-nowrap'> Berechtigungen anzeigen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideHomeScreen?.()}>
                                <BodyText fontSize='12px' fontWeight='400' className='text-nowrap'> Auf Startseite anzeigen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideArticleTranslation?.()}>
                                <BodyText fontSize='12px' fontWeight='400' className='text-nowrap'> Übersetzungen verwalten </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideDeleteMessage?.()} className='text-nowrap'> Löschen</BodyText>
                            </Dropdown.Item>
                        </DropdownButton>
                        :
                        null
                    }
                </ButtonGroup>
                : null}

            {pageName === "taskDetailPage" ?
                <ButtonGroup className='custom-btn-group'>
                    {bookMarkBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isBookMark ?
                                            <DisplayStart>
                                                <FaStar size={12} />
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegStar size={12} />
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleBookMark(); hideStarModal?.(); }}
                            />
                        </>
                        :
                        null
                    }

                    {noticeBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isNotice ?
                                            <DisplayStart>
                                                <FaBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Gemerkt </BodyText>
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Merken </BodyText>
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleNoticed(); hideNoticeModal?.(); }}
                            />
                        </>
                        :
                        null
                    }
                    {isDropdownIcon ?
                        <DropdownButton as={ButtonGroup} title={false} id="custom-bg-dropdown" className="btn-group-blue-01">
                            <Dropdown.Item onClick={() => addEditTaskModal?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Bearbeiten </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideUnsavedContent?.()}> Ungelesen markieren </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hidePrintPdf?.()}> PDF erstellen / Drucken</BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { props.handleDisableNotification?.(); }}>
                                {notificationTextToggle ?
                                    <BodyText fontSize='12px' fontWeight='400'> Benachrichtigungen deaktivieren </BodyText>
                                    :
                                    <BodyText fontSize='12px' fontWeight='400'> Beobachten </BodyText>
                                }
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400'> Dearchivieren </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideAddPoll?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Umfrage hinzufügen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { props.handleCommentTextBoxToggle?.(); }}>
                                {commentTextBoxToggle ?
                                    <BodyText fontSize='12px' fontWeight='400'> Kommentare schließen </BodyText>
                                    :
                                    <BodyText fontSize='12px' fontWeight='400'> Kommentare öffnen </BodyText>
                                }
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hidepermission?.()}> Berechtigungen anzeigen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideHomeScreen?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Auf Startseite anzeigen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideDeleteMessage?.()}> Löschen</BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideReportContent?.()}> Inhalt melden </BodyText>
                            </Dropdown.Item>
                        </DropdownButton>
                        :
                        null
                    }
                </ButtonGroup>
                : null}

            {pageName === "ideasDetailPage" ?
                <ButtonGroup className='custom-btn-group'>
                    {bookMarkBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isBookMark ?
                                            <DisplayStart>
                                                <FaStar size={12} />
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegStar size={12} />
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleBookMark(); hideStarModal?.(); }}
                            />
                        </>
                        :
                        null
                    }

                    {noticeBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isNotice ?
                                            <DisplayStart>
                                                <FaBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Gemerkt </BodyText>
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Merken </BodyText>
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleNoticed(); hideNoticeModal?.(); }}
                            />
                        </>
                        :
                        null
                    }
                    {isDropdownIcon ?
                        <DropdownButton as={ButtonGroup} title={false} id="custom-bg-dropdown" className="btn-group-blue-01">
                            <Dropdown.Item onClick={() => addEditIdeaModal?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Bearbeiten </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideUnsavedContent?.()}> Ungelesen markieren </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hidePrintPdf?.()}> PDF erstellen / Drucken</BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { props.handleDisableNotification?.(); }}>
                                {notificationTextToggle ?
                                    <BodyText fontSize='12px' fontWeight='400'> Benachrichtigungen deaktivieren </BodyText>
                                    :
                                    <BodyText fontSize='12px' fontWeight='400'> Beobachten </BodyText>
                                }
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400'> Dearchivieren </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideAddPoll?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Umfrage hinzufügen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { props.handleCommentTextBoxToggle?.(); }}>
                                {commentTextBoxToggle ?
                                    <BodyText fontSize='12px' fontWeight='400'> Kommentare schließen </BodyText>
                                    :
                                    <BodyText fontSize='12px' fontWeight='400'> Kommentare öffnen </BodyText>
                                }
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hidepermission?.()}> Berechtigungen anzeigen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideHomeScreen?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Auf Startseite anzeigen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideDeleteMessage?.()}> Löschen</BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideReportContent?.()}> Inhalt melden </BodyText>
                            </Dropdown.Item>
                        </DropdownButton>
                        :
                        null
                    }
                </ButtonGroup>
                : null}
            {pageName === "newsDetailPage" ?
                <ButtonGroup className='custom-btn-group'>
                    {bookMarkBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isBookMark ?
                                            <DisplayStart>
                                                <FaStar size={12} />
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegStar size={12} />
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleBookMark(); hideStarModal?.(); }}
                            />
                        </>
                        :
                        null
                    }

                    {noticeBtn ?
                        <>
                            <ButtonWidget
                                varient='light'
                                class='w-100'
                                text={
                                    <>
                                        {isNotice ?
                                            <DisplayStart>
                                                <FaBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Gemerkt </BodyText>
                                            </DisplayStart>
                                            :
                                            <DisplayStart>
                                                <FaRegBookmark size={12} />
                                                <BodyText fontSize='12px' pl='5px' fontWeight='500' className='text-nowrap'> Merken </BodyText>
                                            </DisplayStart>
                                        }
                                    </>
                                }
                                handleClick={() => { handleNoticed(); hideNoticeModal?.(); }}
                            />
                        </>
                        :
                        null
                    }
                    {isDropdownIcon ?
                        <DropdownButton as={ButtonGroup} title={false} id="custom-bg-dropdown" className="btn-group-blue-01">
                            <Dropdown.Item onClick={() => addEditNewsModal?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Bearbeiten </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideUnsavedContent?.()}> Ungelesen markieren </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hidePrintPdf?.()}> PDF erstellen / Drucken</BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { props.handleDisableNotification?.(); }}>
                                {notificationTextToggle ?
                                    <BodyText fontSize='12px' fontWeight='400'> Benachrichtigungen deaktivieren </BodyText>
                                    :
                                    <BodyText fontSize='12px' fontWeight='400'> Beobachten </BodyText>
                                }
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400'> Dearchivieren </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideAddPoll?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Umfrage hinzufügen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { props.handleCommentTextBoxToggle?.(); }}>
                                {commentTextBoxToggle ?
                                    <BodyText fontSize='12px' fontWeight='400'> Kommentare schließen </BodyText>
                                    :
                                    <BodyText fontSize='12px' fontWeight='400'> Kommentare öffnen </BodyText>
                                }
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hidepermission?.()}> Berechtigungen anzeigen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => hideHomeScreen?.()}>
                                <BodyText fontSize='12px' fontWeight='400'> Auf Startseite anzeigen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideDeleteMessage?.()}> Löschen</BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='12px' fontWeight='400' onClick={() => hideReportContent?.()}> Inhalt melden </BodyText>
                            </Dropdown.Item>
                        </DropdownButton>
                        :
                        null
                    }
                </ButtonGroup>
                : null}

        </>
    )
}