import React, { FC, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { FaClock, FaHome } from 'react-icons/fa';
import { NavLink } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { MdAdminPanelSettings } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
type adminDdMenuType = {
    user: boolean,
    setting: boolean,
    security: boolean,
    toolCofiguration: boolean
}
const adminDdMenu: adminDdMenuType = {
    user: false,
    setting: false,
    security: false,
    toolCofiguration: false
}


interface IProps {
}

export const SidebarAdmin: FC<IProps> = ({ }) => {
    const [dropDownMenu, setdropDownMenu] = useState<adminDdMenuType>(adminDdMenu);
    const handleOpenDD = (menuItem: string) => {
        switch (menuItem) {
            case "user":
                setdropDownMenu({ user: !dropDownMenu.user, setting: false, security: false, toolCofiguration: false })
                break;
            case "setting":
                setdropDownMenu({ user: false, setting: !dropDownMenu.setting, security: false, toolCofiguration: false })
                break;
            case "security":
                setdropDownMenu({ user: false, setting: false, security: !dropDownMenu.security, toolCofiguration: false })
                break;
            case "toolCofiguration":
                setdropDownMenu({ user: false, setting: false, security: false, toolCofiguration: !dropDownMenu.toolCofiguration })
                break;
            default:
                setdropDownMenu({ user: false, setting: false, security: false, toolCofiguration: false })
        }
    }
    return (
        <>
            <Card>
                <Card.Header className='primary-bg d-flex justify-content-center align-items-center'>
                    <Card.Title>
                        <RiAdminFill className='me-2 admin-icon' size="25px" />
                    </Card.Title>
                    <Card.Title className='m-0 admin-title'>ADMIN</Card.Title>
                </Card.Header>
                <Card.Body className='px-0'>
                    <div className='mt-2 px-3'>
                        <div className='side-nav'>
                            <ul>
                                <li className="sub">
                                    <input type="checkbox" checked={dropDownMenu.user} />
                                    <NavLink onClick={() => handleOpenDD("user")} to="user" className='text-uppercase' title="NUTZER"> NUTZER </NavLink>
                                    <ul className="submenu">
                                        <li>
                                            <NavLink to="moderation-reports" className='text-uppercase' title="Gemeldeter Inhalt"> Gemeldeter Inhalt </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink onClick={() => handleOpenDD("")} to="groups" className='text-uppercase' title="GRUPPEN"> GRUPPEN </NavLink>
                                </li>
                                <li className="sub">
                                    <input type="checkbox" checked={dropDownMenu.setting} />
                                    <NavLink onClick={() => handleOpenDD("setting")} to="settings/option" className='text-uppercase' title="Einstellungen"> Einstellungen </NavLink>
                                    <ul className="submenu">
                                        <li>
                                            <NavLink to="settings/option" className='text-uppercase' title="Ansicht">Ansicht </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="settings/language" className='text-uppercase' title="Spracheinstellungen">Spracheinstellungen </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="settings/welcome-meessage" className='text-uppercase' title="Willkommensnachricht">Willkommensnachricht </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="settings/pdf-setting" className='text-uppercase' title="PDF-EinsteIIungen">PDF-EinsteIIungen </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="settings/standard-streamtyp" className='text-uppercase' title="Standard-Streamtyp">Standard-Streamtyp </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="settings/embedded-videos" className='text-uppercase' title="Eingebettete Videos">Eingebettete Videos </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink onClick={() => handleOpenDD("")} to="dashboards" className='text-uppercase' title="DASHBOARDS">DASHBOARDS</NavLink>
                                </li>
                                <li className="sub">
                                    <input type="checkbox" checked={dropDownMenu.security} />
                                    <NavLink onClick={() => handleOpenDD("security")} to="security/mobilepin" className='text-uppercase' title="SICHERHEIT">SICHERHEIT</NavLink>
                                    <ul className="submenu">
                                        <li>
                                            <NavLink to="security/mobilepin" className='text-uppercase' title="Mobile PIN">Mobile PIN </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="security/passwords" className='text-uppercase' title="Passworter">Passworter </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink onClick={() => handleOpenDD("")} to="device-manager" className='text-uppercase' title="GERÄTE-MANAGER">GERÄTE-MANAGER</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => handleOpenDD("")} to="ip-manager" className='text-uppercase' title="IP-MANAGER">IP-MANAGER</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => handleOpenDD("")} to="article" className='text-uppercase' title="ART IKEL">ART IKEL</NavLink>
                                </li>
                                <li className="sub">
                                    <input type="checkbox" checked={dropDownMenu.toolCofiguration} />
                                    <NavLink to="tool-configuration/messages" onClick={() => handleOpenDD("toolCofiguration")} className='text-uppercase' title='TOOL-KONFIGIJRATION'>TOOL-KONFIGIJRATIONL</NavLink>
                                    <ul className="submenu">
                                        <li>
                                            <NavLink to="tool-configuration/messages" className='text-uppercase' title="Nachrichten">Nachrichten </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="tool-configuration/news" className='text-uppercase' title="News">News </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="tool-configuration/ideas" className='text-uppercase' title="Ideen">Ideen </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="tool-configuration/manual" className='text-uppercase' title="Handbuch">Handbuch </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="tool-configuration/tasks" className='text-uppercase' title="Aufgaben">Aufgaben </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="tool-configuration/term" className='text-uppercase' title="Termine">Termine </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="tool-configuration/repairs" className='text-uppercase' title="Reparaturen">Reparaturen </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="tool-configuration/moments" className='text-uppercase' title="Moments">Moments </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink onClick={() => handleOpenDD("")} to="backup" className='text-uppercase' title="BACKUP">BACKUP</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => handleOpenDD("")} to="data-protection" className='text-uppercase' title="DATENSCHUTZ / DSGVO">DATENSCHUTZ / DSGVO</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}