import React, { FC, useState } from 'react';
import Card from 'react-bootstrap/Card';
import UpcomingEvents from '../UpcomingEvents';
import { BodyText, DisplayBetween, DisplayEnd, DisplayStart } from '../StyledComponents';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import CommonModal from '../Modals/CommonModal';
import { DeleteModuleModalContent } from '../Modals/ModelContents/DeleteModuleModalContent';
import FormCheck from '../FormCheck';
import { EventModuleOptionModalContent } from '../Modals/ModelContents/EventModuleOptionModalContent';
import { RiDragMove2Line } from "react-icons/ri";

interface IProps {
    isDragMode?: boolean,
    dragHandleProps?: any
}

const EventsModule: FC<IProps> = ({ dragHandleProps, isDragMode }) => {

    const [deleteModule, setDeleteModule] = useState<boolean>(false);
    const [eventModule, setEventModule] = useState<boolean>(false);

    return (
        <>
            <Card className='border-0 rounded-0 b-shadow'>
                <Card.Header className='bg-white'>
                    <DisplayBetween>
                        <BodyText fontSize='18px' textColor='#666' fontWeight='600'> Aktuelle Termine </BodyText>
                        <DisplayStart>
                            {isDragMode ?
                                <div {...dragHandleProps}>
                                    <RiDragMove2Line size="18px" color="#666" />
                                </div>
                                :
                                null
                            }                            <DropdownButton size="sm" className="template-withouticon f-12px ms-2" id="new-item" title={<></>}>
                                <Dropdown.Item onClick={(event) => setEventModule(true)}>
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Bearbeiten </BodyText>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <FormCheck
                                        type='checkbox'
                                        name='phone'
                                        Id='phone'
                                        value='Automatische Höhe'
                                        label='Automatische Höhe'
                                        isFormGroup={false}
                                        isInline={false}
                                        disabled={false}
                                    />
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(event) => setDeleteModule(true)}>
                                    <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Löschen </BodyText>
                                </Dropdown.Item>
                            </DropdownButton>
                        </DisplayStart>
                    </DisplayBetween>
                </Card.Header>
                <Card.Body className='h-190px'>
                    <Card.Text className='text-center mt-2'>
                        <UpcomingEvents />
                        <span>Aktuell keine Einträge</span>
                    </Card.Text>
                </Card.Body>
            </Card>

            <CommonModal
                showModal={deleteModule}
                hideModal={() => setDeleteModule(false)}
                modalTitle='Dieses Modul löschen'
                modalContent={<DeleteModuleModalContent deleteModal="upcomingEvents" />}
                modalSize='md'
                centered={true}
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <Button className='default-btn f-14px me-1' onClick={() => setDeleteModule(false)}> Cancel </Button>
                            <Button variant="danger" className='f-14px'> Delete</Button>
                        </DisplayEnd>
                    </>
                }
            />

            <CommonModal
                showModal={eventModule}
                hideModal={() => setEventModule(false)}
                modalTitle='Moduloptionen'
                modalContent={<EventModuleOptionModalContent />}
                modalSize='sm'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <Button className='default-btn f-14px me-1' onClick={() => setEventModule(false)}> Cancel </Button>
                            <Button variant="danger" className='f-14px'> Delete</Button>
                        </DisplayEnd>
                    </>
                }
            />

        </>
    );
}

export default EventsModule;