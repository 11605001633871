import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';


export const AccountHeader = () => {
    return (
        <>
            <div className='header-main w-100 shadow'>
                <Navbar collapseOnSelect expand="xl">
                    <Container fluid>
                        <Navbar.Brand href="#home">
                            <img src='/images/logo.png' />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    </Container>
                </Navbar>
            </div>
        </>
    );
}

