import React, { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css"
import enGb from 'date-fns/locale/en-GB';
import DatePicker, { registerLocale } from 'react-datepicker';
import _DatePicker from './datepicker';
import DateComponent from './datepicker';
import Select from 'react-select'
import styled from 'styled-components';
// import { dynamicGetApi, dynamicPostApi } from './../../Common'
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import SaveCurrentFilter from './SaveCurrentFilter/SaveFilter';
import PraxisCheckboxList from './SaveCurrentFilter/PraxisCheckboxList';

registerLocale('en-gb', enGb);
const DatePickerStyled = styled.div`
  /* STYLE FOR WITH */
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%  !important;
  }
`;


const options_mit = [
  { value: '1', label: '1 Greifenberg, Wolfram' },
  { value: '5', label: '5 Meyer, Peter' },
  { value: '4', label: '4 Sommer, Sandra' }
]
const options_tb = [
  { value: 'TB1', label: 'Greifenberg (TB 1)' },
  { value: 'TB2', label: 'Meyer, Peter (TB 2)' },
  { value: 'TB3', label: 'Prophylaxe (TB 3)' }
]

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}
const highlightWithRanges = [
  {
    "react-datepicker__day--highlighted-custom-1": [
      (new Date()).addDays(4),
    ]
  },
  {
    "react-datepicker__day--highlighted-custom-2": [
      (new Date()).addDays(-4),
      (new Date()).addDays(7),
      (new Date()).addDays(14),
    ]
  },
  (new Date()).addDays(-7), (new Date()).addDays(7), (new Date()).addDays(5),
];

export class Controls extends React.Component {
  constructor(props) {
    super(props);
    var now = new Date(),
    today = new Date(now);
    //now.setMonth(now.getMonth() - 1);
    //today.setMonth(today.getMonth() - 1);

    today.setHours(0, 0, 0, 0);

    this.state = {
      highlights: {
        "2021-04-01": { "backgroundColor": "7fff7f", "date":"2021-04-01", "description":"Osterferien 2021" },
        "2021-04-02": { "backgroundColor": "7fff7f", "date":"2021-04-02", "description":"Osterferien 2021" },
        "2021-04-03": { "backgroundColor": "7fff7f", "date":"2021-04-03", "description":"Osterferien 2021" },
        "2021-04-04": { "backgroundColor": "7fff7f", "date":"2021-04-04", "description":"Osterferien 2021" },
        "2021-04-05": { "backgroundColor": "7fff7f", "date":"2021-04-05", "description":"Osterferien 2021" },
        "2021-04-06": { "backgroundColor": "7fff7f", "date":"2021-04-06", "description":"Osterferien 2021" },
        "2021-04-07": { "backgroundColor": "7fff7f", "date":"2021-04-07", "description":"Osterferien 2021" },
        "2021-04-08": { "backgroundColor": "7fff7f", "date":"2021-04-08", "description":"Osterferien 2021" },
        "2021-04-09": { "backgroundColor": "7fff7f", "date":"2021-04-09", "description":"Osterferien 2021" },
        "2021-04-10": { "backgroundColor": "7fff7f", "date":"2021-04-10", "description":"Osterferien 2021" },
        "2021-04-11": { "backgroundColor": "7fff7f", "date":"2021-04-11", "description":"Osterferien 2021" },
        "2021-04-12": { "backgroundColor": "7fff7f", "date":"2021-04-12", "description":"Osterferien 2021" },
        "2021-04-13": { "backgroundColor": "7fff7f", "date":"2021-04-13", "description":"Osterferien 2021" },
        "2021-04-14": { "backgroundColor": "7fff7f", "date":"2021-04-14", "description":"Osterferien 2021" },
        "2021-04-15": { "backgroundColor": "7fff7f", "date":"2021-04-15", "description":"Osterferien 2021" },
        "2021-04-16": { "backgroundColor": "7fff7f", "date":"2021-04-16", "description":"Osterferien 2021" }
      },
      holidays: {
          "2021-04-02": { "description":"Karfreitag 2021", "type":"holiday" },
          "2021-04-04" : { "description":"Ostersonntag 2021", "type":"holiday" },
          "2021-04-05" : { "description":"Ostermontag 2021", "type":"holiday" },
          "2021-05-01" : { "description":"Maifeiertag", "type":"holiday" },
      },
      practitionerDDL: [],
      methods: {
        setView: this.setView,
        setSelectedDate: this.setSelectedDate,
        setCurrentDate: this.setCurrentDate,
      },
      selectedDate: today.addDays(0),
      currentDate: today.addDays(0),
    };
    this.setStartDate = this.setStartDate.bind(this);

    this.setView = this.setView.bind(this);
    this.setSelectedDate = this.setSelectedDate.bind(this);
  }
  componentDidMount() {
    var self = this;
    this.callApis();
	  }

	componentWillUnmount() {
    var self = this;
	}

callApis = async () =>{
  // var practitionerListUrl = "online-terminbuch/role/getAllUserByRoleName?roleName=Practitioner";
  // var res = await dynamicGetApi(practitionerListUrl,this.props.navigate,this.props.setCookie);
  // var resArr = [];
  // res.data.map((v)=> resArr.push({value:v.id,label:v.firstName +' '+v.lastName}));
  // this.setState({practitionerDDL: resArr});
}

mitarbeiterChange = (e) => {
  if(e.length > 0){
    let selectedPractitioner = '';
    e.map(x=> {selectedPractitioner === '' ? selectedPractitioner = x.value : selectedPractitioner = selectedPractitioner +','+x.value});
    this.props.setCookie('mitarbeiterFilter',selectedPractitioner);
  }
  else{
    this.props.setCookie('mitarbeiterFilter','all');
  }
}
  //-----datepicker start
  setView(view) {
    this.setState(state => ({
      view: view
    }));
  }

  setSelectedDate(date) {
    this.setState(state => ({
      selectedDate: date
    }));
  }

  setCurrentDate(date) {
    this.setState(state => ({
      currentDate: date
    }));
  }
  //-----datepicker end

  getMitarbeiterSelect() {
    return <div className="ds-selectbox-vertical" data-ds-rt="selectbox" data-ds-rid="mitarbeiterFilterSb" id="wlbQLNYAQkaTbKlrWq5xsA">
      <div className="select2-container select2-container-multi" id="s2id_autogen1">
        <ul className="select2-choices">
          <li className="select2-search-field"> 
            <label htmlFor="s2id_autogen2" className="select2-offscreen"></label> 
            <input type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" className="select2-input select2-default" id="s2id_autogen2" placeholder="" 
              style={{width: 179.75 + "px"}}>
            </input>
          </li>
        </ul>
        <div className="select2-drop select2-drop-multi select2-display-none">
          <ul className="select2-results">
            <li className="select2-no-results">Keine Übereinstimmungen gefunden</li>
            <li className="select2-no-results">Keine Übereinstimmungen gefunden</li>
          </ul>
        </div>
      </div>
      <input type="hidden" tabIndex="-1" className="select2-offscreen" value=""></input>
    </div>;
  }

  getTerminbuchSelect() {
    return <div className="ds-selectbox-vertical" data-ds-rt="selectbox" data-ds-rid="terminbuchSb" id="wqTM8FlFSomcNVCrFg1fiw">
      <div className="select2-container select2-container-multi" id="s2id_autogen3">

        <ul className="select2-choices">
          <li className="select2-search-choice">
            <div>
              <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="1dispLb" id="wcB9f0uLTmSwQAIhGHeF5g">Greifenberg, Praxis Dr. Wolfram Greifenberg (TB 1)</span>
            </div> 
            <a href="https://test.termin.dampsoft.net/online-terminbuch/startmenu#" className="select2-search-choice-close" tabIndex="-1"></a>
          </li>
          <li className="select2-search-choice">
            <div>
              <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="2dispLb" id="wskHD3sDT3Sc04I0qb4Sog">Meyer, Peter, Praxis Dr. Wolfram Greifenberg (TB 2)</span>
            </div> 
            <a href="https://test.termin.dampsoft.net/online-terminbuch/startmenu#" className="select2-search-choice-close" tabIndex="-1"></a>
          </li>
          <li className="select2-search-choice">
            <div>
              <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="3dispLb" id="wsD8FdnbR2WJab__k3gqGA">Prophylaxe, Praxis Dr. Wolfram Greifenberg (TB 3)</span>
            </div> 
            <a href="https://test.termin.dampsoft.net/online-terminbuch/startmenu#" className="select2-search-choice-close" tabIndex="-1"></a>
          </li>
          <li className="select2-search-field"> 
            <label htmlFor="s2id_autogen4" className="select2-offscreen"></label> 
            <input 
              type="text" 
              autoComplete="off" 
              autoCorrect="off" 
              autoCapitalize="off" 
              spellCheck="false" 
              className="select2-input select2-default" 
              id="s2id_autogen4" 
              placeholder="" 
              style={{width: 20 + "px"}}
              ></input>
          </li>
        </ul>

        <div className="select2-drop select2-drop-multi select2-display-none">
          <ul className="select2-results">
            <li className="select2-no-results">Keine Übereinstimmungen gefunden</li>
            <li className="select2-no-results">Keine Übereinstimmungen gefunden</li>
          </ul>
        </div>
      </div>
      <input type="hidden" tabIndex="-1" className="select2-offscreen" value="1,2,3"></input>
    </div>;
  }

  setStartDate(date) {
    this.setState(state => ({
      selectedDate: date
    }));

  }

  render() {

    return <div className="row controls controls-row">
      <div className="ds-cell col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div style={{alignItems: "stretch"}} className="ds-s-lw ds-s-rd ds-fb ds-fd-c ds-fb-nw ds-fb-si" data-ds-rt="flexPanel" data-ds-rid="flexPanel1" id="wWFlGpSmR2-B2MrvJYuuJw">
          <div className="ds-fi" data-ds-rt="flexItem" data-ds-rid="minCalItem" id="wuHLg47aSTqja9K4yeh7fw">
              {false && <DatePicker 
                style={{width:100+"px"}}
                selected={this.state.selectedDate} 
                onChange={this.setSelectedDate}
                locale="en-gb"
                highlightDates={highlightWithRanges}
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={3}
                scrollableYearDropdown
                //showWeekNumbers
                inline
              />}
              <DateComponent
                view={this.props.view}
                selected={this.state.selectedDate}
                onChange={this.setSelectedDate}
                highlights={this.state.highlights}
                holidays={this.state.holidays}
              ></DateComponent>
          </div>
          <hr />
          {/* <div className="ds-fi" data-ds-rt="flexItem" data-ds-rid="filterDetailLbItem" id="w85uLH2jRa-k55_932zyyw">
            <p data-ds-rid="filterDetailLb" id="w-DZaWlOQwywIUTjw-UYag" data-ds-rt="label" className="text-left ds-t-nw ds-t-oe">
              <strong>Mitarbeiter</strong>
            </p>
          </div>
          <div className="ds-fi" data-ds-rt="flexItem" data-ds-rid="mitarbeiterFilterSbItem" id="wBUC8xVvQWWWJA4ai7WsEw">
            {false && this.getMitarbeiterSelect()}
            <Select 
              options={this.state.practitionerDDL} 
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e)=>this.mitarbeiterChange(e)}
            />
          </div>
          <div className="ds-fi" data-ds-rt="flexItem" data-ds-rid="terminbuchfilterLbItem" id="w2uaR14nTuiQFGC3bPxYeQ">
            <p data-ds-rid="terminbuchfilterLb" id="wRnNMx8aRTaHI3u9lqOt0w" data-ds-rt="label" className="text-left  ds-t-nw ds-t-oe">
              <strong>Terminbuch</strong>
            </p>
          </div>
          <div className="ds-fi" data-ds-rt="flexItem" data-ds-rid="terminbuchSbItem" id="wophAyfjRQeQyHLF5YMSiA">
            {false && this.getTerminbuchSelect()}
            <Select 
              options={this.state.practitionerDDL} 
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e)=>this.mitarbeiterChange(e)}
            />
          </div> */}
          <SaveCurrentFilter />
          <PraxisCheckboxList />
        </div>
      </div>
    </div>;
  }
}

export default function(props) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  return <Controls {...props} navigate={navigate} setCookie={setCookie}/>;
}


