import { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { JsxElement, isJsxElement } from 'typescript';
import { DisplayCenter } from './StyledComponents';

type toolTipType = {
  ToolTipTrigger: any,
  toolTipContent: string | JSX.Element,
  toolTipPlacement?: any,
  toolTipPadding?: number,
  contentAlign?: string,
  maxWidth?: number | string,

}

function CustomTooltip(props: toolTipType) {
  const { ToolTipTrigger, toolTipContent, toolTipPlacement, toolTipPadding, contentAlign, maxWidth } = props;
  const [show, setShow] = useState<any>(false);
  const [target, setTarget] = useState<any>(null);
  const ref = useRef(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setShow(true);
    setTarget(event.target);
  }
  const handleMouseLeave = () => {
    setShow(false);
  }

  return (
    <div ref={ref} >
      <div onMouseEnter={(event) => handleMouseEnter(event)} onMouseLeave={() => handleMouseLeave()}>
        {ToolTipTrigger}
      </div>

      <Overlay
        show={show}
        target={target}
        placement={toolTipPlacement ? toolTipPlacement : 'bottom'}
        container={ref}
        containerPadding={toolTipPadding ? toolTipPadding : 20}

      >
        <Popover id="popover-contained" style={{ position: "fixed", maxWidth }}>
          {/* <Popover.Header as="h3">Popover bottom</Popover.Header> */}
          <Popover.Body>
            {!!contentAlign ?
              <DisplayCenter className='text-center'>
                {toolTipContent}
              </DisplayCenter>
              :
              <>
                {toolTipContent}
              </>
            }
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

export default CustomTooltip;