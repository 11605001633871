import * as React from 'react';
import { BodyText, Box } from '../../StyledComponents';


export const UnsavedCommentModalContent = () => {
    return (
        <>
            <Box className='p-1'>
                <BodyText fontSize='13px' fontWeight='400' textColor='#666'>
                    Du hast einen ungespeicherten Kommentar. Möchtest du wirklich diese Seite verlassen und den Kommentar verwerfen? Du kannst auch einen Entwurf speichern und später fortsetzen. (Der Entwurf wird vorübergehend auf diesem Gerät gespeichert und kann nicht auf einem anderen Gerät fortgesetzt werden.)
                </BodyText>
            </Box>
        </>
    )
}