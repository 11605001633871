import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, } from 'react-bootstrap';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import SelectTemplateModal from '../../Components/Modals/SelectTemplateModal';
import AddTemplateModal from '../../Components/Modals/AddTemplateModal';
import Recipient from '../../Components/Modals/Recipient';
import AbortAlrt from '../../Components/Modals/AbortAlrt';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import EditorConvertToMarkdown from '../../Components/TextEditor';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import DateTimePicker from '../../Components/DateTimePicker';
import Badge from 'react-bootstrap/Badge';
import AddMediaModal from '../../Components/Modals/AddMediaModal';
import ModelActions from '../../Components/ModelActions';
import Tagify from '../../Components/Tagify';
import { BorderBox, DisplayStart, ModalHeader } from '../../Components/StyledComponents';
import CustomTooltip from '../../Components/Tooltip';
import BadgeWidget from '../../Components/BadgeWidget';
import styled from 'styled-components';
import ChooseTemplateCategoryModal from '../../Components/Modals/ChooseTemplateCategoryModal';
import ManageTemplateCategoryModal from '../../Components/Modals/ManageTemplateCategoryModal';
import { Link } from 'react-router-dom';


interface PropsType {
    showMessange: boolean,
    hideMessange: () => void,
}





function AddEditMessage(props: PropsType) {
    // State for modals Begin
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    const [AlertModal, setAlertModal] = useState<boolean>(false);
    const [selectTemplateModalShow, setSelectTemplateModalShow] = useState<boolean>(false);
    const [AddTemplateModalShow, setAddTemplateModalShow] = useState<boolean>(false);
    const [AddMediaModalShow, setAddMediaModalShow] = useState<boolean>(false);
    const [chooseTempCategoryModal, setChooseTempCategoryModal] = useState<boolean>(false);
    const [manageTempCategoryModal, setManageTempCategoryModal] = useState(false);

    // State for modals End

    // State for Hide Show Begin
    const [AllowReading, setAllowReading] = useState<boolean>(false);
    const [TextEditorShow, setTextEditorShow] = useState<boolean>(false);
    const [HideFont, setHideFont] = useState<boolean>(false);
    const [TagifyShow, setTagifyShow] = useState<boolean>(false);
    const [PublishNow, setPublishNow] = useState<boolean>(true);
    const [additionallyReadable, setAdditionallyReadable] = useState<boolean>(false);
    // State for Hide Show End

    const [badgeList, setBadgeList] = useState<string[]>([]);

    useEffect(() => {
        setBadgeList(['Dentists', 'medikit champions']);
    }, []);

    const HandleAllowReding = () => {
        setAllowReading(!AllowReading);
    }
    const HandleFontClick = () => {
        setTextEditorShow(true);
        setHideFont(true);
    }
    return (
        <>

            <Modal size="lg" show={props.showMessange} onHide={props.hideMessange} backdrop="static" keyboard={false} aria-labelledby="example-modal-sizes-title-lg" >
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Nachricht erstellen
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body className='pb-0'>
                    <Row>
                        <Col md={7}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Title" />
                            </Form.Group>
                        </Col>
                        <Col md={5} className='ps-0'>
                            <div className='d-flex align-items-center'>
                                <DropdownButton size="sm" className="template-sort f-12px me-1 w-50" id="new-item" title="Zuletzt verwendet">
                                    <Dropdown.Item href="javascript:;">
                                        <span className='f-14px'>Message Template</span>
                                    </Dropdown.Item>
                                </DropdownButton>
                                <Button className='default-btn f-12px w-50' onClick={() => setSelectTemplateModalShow(true)}>Vorlage auswählen</Button>
                            </div>
                        </Col>
                    </Row>
                    <div className='border-top border-bottom py-2'>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="">
                                    <div className='d-flex justify-content-between w-lg-85'>
                                        <div>
                                            <Form.Check
                                                inline
                                                label="Öffentlich"
                                                name="group1"
                                                type="radio"
                                                id="public"
                                                onChange={() => setAdditionallyReadable(false)}
                                            />
                                            <Form.Check
                                                inline
                                                label="Nicht öffentlich"
                                                name="group1"
                                                type="radio"
                                                id="notpublic"
                                                onChange={() => setAdditionallyReadable(true)}
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <Row className='mt-3'>
                        <Col md={7}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Empfänger" />
                            </Form.Group>
                        </Col>
                        <Col md={5} className='text-end ps-0'>
                            <div className='d-flex align-items-center'>
                                <DropdownButton size="sm" className="template-sort f-12px me-1 w-45" id="new-item" title="Kürzlich kontaktiert">
                                    <Dropdown.Item>
                                        <Link to="">
                                            <CustomTooltip
                                                ToolTipTrigger={<Badge className='primary-bg f-12px me-2'>Abrechnung</Badge>}
                                                toolTipContent={"APZ APZ"}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <CustomTooltip
                                            ToolTipTrigger={<span className='f-14px'>Dr. Sven Markus Gallenbach</span>}
                                            toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                            toolTipPlacement='top'
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <CustomTooltip
                                            ToolTipTrigger={<span className='f-14px'>Anna Behm</span>}
                                            toolTipContent='ZFA - Praxis Gallenbach & Zähne'
                                            toolTipPlacement='top'
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <CustomTooltip
                                            ToolTipTrigger={<span className='f-14px'>Alicia Gojani</span>}
                                            toolTipContent='ZFA - Praxis Gallenbach & Zähne'
                                            toolTipPlacement='top'
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item >
                                        <CustomTooltip
                                            ToolTipTrigger={<span className='f-14px'>Thomas Planitzer & Team</span>}
                                            toolTipContent='Customer Success Manager -'
                                            toolTipPlacement='top'
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <CustomTooltip
                                            ToolTipTrigger={<span className='f-14px'>Emine Mollaoglu</span>}
                                            toolTipContent='Consultant - OPTI Health Consulting'
                                            toolTipPlacement='top'
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <CustomTooltip
                                            ToolTipTrigger={<span className='f-14px'>APZ APZ</span>}
                                            toolTipContent='Praxis Gallenbach & Zähne'
                                            toolTipPlacement='top'
                                        />
                                    </Dropdown.Item>
                                </DropdownButton>
                                <Button className='default-btn f-12px' onClick={() => setRecipientModal(true)}><FaPlusCircle className='default-btn-icon' />Alle Kategorien anzeigen</Button>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className='no-template-alert mb-3'>
                                <div className='text-center'>
                                    <span>Acntung! Unter den Emptängem befinden Sich Gruppen ohne Mitglieder</span>
                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            {TextEditorShow ?
                                <div className="mb-3">
                                    <EditorConvertToMarkdown />
                                </div>
                                :
                                <div className="mb-3">
                                    <Form.Control as="textarea" placeholder="Text" rows={5} />
                                </div>
                            }
                        </Col>
                        <Col md={12}>
                            <ModelActions
                                ImgClick={() => setAddMediaModalShow(true)}
                                FontClick={() => HandleFontClick()}
                                isHideFont={HideFont}
                                TagifyClick={() => setTagifyShow(!TagifyShow)}
                            />
                            {TagifyShow ?
                                <div className='my-3 tagify'>
                                    <Tagify />
                                </div>
                                : null
                            }
                        </Col>
                    </Row>
                    {AllowReading ?
                        <>
                            <>
                                {additionallyReadable ?
                                    <BorderBox borderBottom='none'>
                                        <Row>
                                            <Col md={7}>
                                                <DisplayStart>
                                                    {badgeList.map((item, index) => {
                                                        return <>
                                                            <CustomTooltip
                                                                ToolTipTrigger={<BadgeWidget
                                                                    solid={true}
                                                                    title='Assistenz'
                                                                    color='#fff'
                                                                    bgColor='#678ea4'
                                                                    mr="5px"
                                                                    closeIcon={true}
                                                                />
                                                                }
                                                                toolTipContent='Alicia Gojani, Anna Behm, Cara Bianco, Hoa Trinh, Irene Gantert'
                                                                toolTipPlacement='top'
                                                            />
                                                        </>
                                                    })}
                                                </DisplayStart>
                                            </Col>
                                            <Col md={5} className='text-end'>
                                                <Button className='default-btn f-12px' onClick={() => setRecipientModal(true)}><FaPlusCircle className='default-btn-icon' />Alle anzeigen</Button>
                                            </Col>
                                        </Row>
                                        <div className='no-template-alert mt-2'>
                                            <div className='text-center'>
                                                <span>Acntung! Unter den Emptängem befinden Sich Gruppen ohne Mitglieder</span>
                                            </div>
                                        </div>
                                    </BorderBox>
                                    :
                                    null
                                }
                            </>
                            <>
                                <div className='border-top border-bottom py-2'>
                                    <Row>
                                        <Col md={3}>
                                            <Form.Check
                                                label="Jetzt veröffentlichen"
                                                name="publish"
                                                type="radio"
                                                id="publish-now"
                                                onChange={() => setPublishNow(true)}
                                                checked={PublishNow === true}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Check
                                                label="Später veröffentlichen"
                                                name="publish"
                                                type="radio"
                                                id="publish-later"
                                                onChange={() => setPublishNow(false)}
                                                checked={PublishNow === false}
                                            />
                                            {PublishNow ?
                                                null :
                                                <div className='my-2'>
                                                    <DateTimePicker />
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                <div className='mt-3'>
                                    <DisplayStart>
                                        <span className='me-1'> Die Gültigkeit dieser Nachricht endet... </span>
                                        <CustomTooltip
                                            ToolTipTrigger={<FaInfoCircle />}
                                            toolTipContent='Nach dieser Zeit wird die Nachricht archiviert und bestehende Benachrichtigungen werden deaktiviert.'
                                            toolTipPlacement='top'
                                        />
                                    </DisplayStart>
                                </div>
                                <Row className='mt-3'>
                                    <Col md={1}>
                                        <Form.Check
                                            className='me-1'
                                            label="nie"
                                            name="validity"
                                            type="radio"
                                            id="no"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <div className='d-flex align-items-center'>
                                            <Form.Check
                                                className='me-1'
                                                label="in"
                                                name="validity"
                                                type="radio"
                                                id="in"
                                                style={{ width: '50px' }}
                                            />
                                            <Form.Select size="sm" style={{ width: '60px' }}>
                                                {(() => {
                                                    let options = [];
                                                    for (let i = 0; i <= 31; i++) {
                                                        options.push(<option value={i}>{i}</option>);
                                                    }
                                                    return options;
                                                })()}
                                            </Form.Select>
                                            <span className='ms-1'>Tagen</span>
                                        </div>
                                        <div className='d-flex align-items-center mt-2'>
                                            <Form.Label className='mb-0 me-1' style={{ width: '50px' }}>und</Form.Label>
                                            <Form.Select size="sm" style={{ width: '60px' }}>
                                                {(() => {
                                                    let options = [];
                                                    for (let i = 0; i <= 23; i++) {
                                                        options.push(<option value={i}>{i}</option>);
                                                    }
                                                    return options;
                                                })()}
                                            </Form.Select>
                                            <span className='ms-1'>Tagen</span>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className='d-flex align-items-center'>
                                            <Form.Check
                                                className='me-2'
                                                label="in"
                                                name="validity"
                                                type="radio"
                                                id="validityActivity-in"
                                            />
                                            <Form.Select size="sm" style={{ width: '60px' }}>
                                                {(() => {
                                                    let options = [];
                                                    for (let i = 0; i <= 31; i++) {
                                                        options.push(<option value={i}>{i}</option>);
                                                    }
                                                    return options;
                                                })()}
                                            </Form.Select>
                                            <span className='ms-1'> Tage ohne Aktivität</span>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className='d-flex align-items-center'>
                                            <Form.Check
                                                className='me-1'
                                                label="am"
                                                name="validity"
                                                type="radio"
                                                id="am"
                                            />
                                            <DateTimePicker />
                                        </div>
                                    </Col>
                                </Row>
                                <div className='border-top py-2 mt-3'>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group controlId="">
                                                <div className='d-flex justify-content-between w-lg-85'>
                                                    <div>
                                                        <Form.Check
                                                            inline
                                                            label="Erlaube Empfängern den Eintrag zu bearbeiten."
                                                            name="allow-recipients"
                                                            type="checkbox"
                                                            id="allow-recipients"
                                                        />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        </>
                        :
                        null
                    }
                </Modal.Body>
                <Modal.Footer className='justify-content-between footer-sticky'>
                    <div>
                        <Button className='default-btn f-14px' onClick={HandleAllowReding}>
                            {AllowReading ? <FaMinusCircle className='default-btn-icon' /> : <FaPlusCircle className='default-btn-icon' />}
                            <span>{AllowReading ? "Optionen ausblenden" : "Zeige weitere Optionen"}</span>
                        </Button>
                    </div>
                    <div>
                        <Button className='default-btn f-14px me-1'>
                            Entwurf speichern
                        </Button>
                        <Button className='default-btn f-14px me-1' onClick={() => setAddTemplateModalShow(true)}>
                            Als Vorlage hinzufügen
                        </Button>
                        <Button className='default-btn f-14px me-1' onClick={() => setAlertModal(true)}>
                            Abbrechen
                        </Button>
                        <Button className='primary-bg f-14px'>
                            Erstellen
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <SelectTemplateModal displayTemplateModal={selectTemplateModalShow} hideTemplateModal={() => setSelectTemplateModalShow(false)} />

            <AddTemplateModal
                displayAddTemplateModal={AddTemplateModalShow}
                hideAddTemplateModal={() => setAddTemplateModalShow(false)}
                chooseCategoryClickHandler={() => setChooseTempCategoryModal(true)}
                recipientClickHandler={() => setRecipientModal(true)}
            />
            {/* ====== Inner Modal of Add Template modal Begin ====== */}
            <ChooseTemplateCategoryModal
                displayCategoryModal={chooseTempCategoryModal}
                hideCategoryModal={() => setChooseTempCategoryModal(false)}
                manageCategoriesClickHandler={() => setManageTempCategoryModal(true)}
            />
            <ManageTemplateCategoryModal
                displayCategoriesTemplateModal={manageTempCategoryModal}
                modalTitle="Vorlagenkategorien verwalten"
                hideCategoriesTemplateModal={() => setManageTempCategoryModal(false)}
            />
            {/* ====== Inner Modal of Add Template modal End ====== */}

            <Recipient displayRecipientModal={RecipientModal} hideRecipientModal={() => setRecipientModal(false)} />
            <AbortAlrt displayAlertModal={AlertModal} hideAlertModal={() => setAlertModal(false)} />
            <AddMediaModal displayAddMediaModal={AddMediaModalShow} hideAddMediaModal={() => setAddMediaModalShow(false)} />
        </>
    );
}

export default AddEditMessage;

