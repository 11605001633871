import React, { useEffect, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';

export const Footer = () => {

    const [showButton, setShowButton] = useState(false);


    useEffect(() => {

        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };


    return (
        <>
            <div>
                {showButton && (
                    <button onClick={scrollToTop} className="back-to-top">
                        <FaArrowUp size={20} />
                    </button>
                )}
            </div>
            <div className='footer-main shadow py-3'>
                <div className='text-center'>
                    <p className='m-0'>
                        <b>Medikit Copyright© 2023</b>
                    </p>
                </div>
            </div>
        </>
    )
}