import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Table from 'react-bootstrap/Table';
import { FaCaretDown } from 'react-icons/fa';
import { ModalHeader } from '../StyledComponents';

interface PropsType {
    displayTemplateModal: boolean,
    hideTemplateModal: () => void,
}

const SelectTemplateModal = (props: PropsType) => {
    return (
        <>
            <Modal size="lg" className='shadow-modal' show={props.displayTemplateModal} onHide={props.hideTemplateModal} backdrop="static" keyboard={false} aria-labelledby="template-modal" >
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="template-modal">
                        Vorlage hinzufügen
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <Row>
                        <Col md={7}>
                            <Form.Control size="sm" type="Text" />
                        </Col>
                        <Col md={5} className='ps-0'>
                            <div className='d-flex align-items-center'>
                            <Button className='default-btn f-12px w-50' >Suchen</Button>
                            <DropdownButton size="sm" className="template-sort f-12px ms-1 w-50" id="new-item" title="Sortieren nach">
                                        <Dropdown.Item href="javascript:;">
                                            <span>Titel</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="javascript:;">
                                            <span>Titel</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="javascript:;">
                                            <span>Erstellung</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="javascript:;">
                                            <span>Erstellung</span>
                                        </Dropdown.Item>
                                    </DropdownButton>
                            </div>
                        </Col>
                        <Col md={2} className='mt-4'>
                            <div>
                                <span><FaCaretDown /></span>
                                <span> Alle</span>
                            </div>
                        </Col>
                        <Col md={10} className="mt-4">
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        <th>Titel</th>
                                        <th>Kategorie</th>
                                        <th>Erstellung</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Protokoll Besprechung</td>
                                        <td>Praxis Gallenbach & Zähne </td>
                                        <td>
                                            <div><em>Entfernter Nutzer</em></div>
                                            <div><span>16.06.2021 14:37</span></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Standard Arbeitsanweisung</td>
                                        <td>Praxis Gallenbach & Zähne </td>
                                        <td>
                                            <div><em>Entfernter Nutzer</em></div>
                                            <div><span>16.06.2021 14:40</span></div>
                                        </td>
                                    </tr>

                                </tbody>
                            </Table>
                            <div className='no-template-alert'>
                                <div className='text-center'>
                                    <span>Es wurden keine Vorlagen mit diesen Kriterien gefunden</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.hideTemplateModal} className='default-btn f-14px me-2' >Abbrechen</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default SelectTemplateModal;