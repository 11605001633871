import React, { useState } from 'react';
import { DisplayEnd } from '../../Components/StyledComponents';
import { useOutletContext } from "react-router-dom";
import CommonModal from '../../Components/Modals/CommonModal';
import { SendReminderModalContent } from '../../Components/Modals/ModelContents/SendReminderModalContent';
import ButtonWidget from '../../Components/ButtonWidget';
import { MessageList } from './MessageList';


function MessageListWrapper() {
    const [showMessageData, setShowMessageData, displayMode, isShowChecks, setTotalAccount]: any = useOutletContext();

    const [sendReminder, setSendReminder] = useState<boolean>(false);


    return (
        <>
            <MessageList
                listData={showMessageData}
                setListData={setShowMessageData}
                displayMode={displayMode}
                showChecks={isShowChecks}
                setCheckCount={setTotalAccount}
            />

            <CommonModal
                showModal={sendReminder}
                hideModal={() => setSendReminder(false)}
                modalTitle='Erinnerung senden'
                modalContent={<SendReminderModalContent />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Schließen'}
                                class='me-2 ms-3'
                                handleClick={() => setSendReminder(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Ausgewählte erinnem'}
                                disabled={true}
                            />
                        </DisplayEnd>
                    </>
                }
                footerClass={"footer-sticky"}
            />
        </>
    );
}

export default MessageListWrapper;


