import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Form, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { BodyText, Box, Comment, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider, InnerReplyItem, ReplyBox, ReplyItem, StatusBadge, StatusBadgeTextColor, CommentBox, CommentItem, CommentWrapper, TaskDetails, TaskInfo, TaskRecipientInfo, TaskWrapper } from './StyledComponents';
import { GroupActionButtonWidget } from './Widgets/GroupActionButtonWidget';
import CustomTooltip from './Tooltip';
import { FaAngleDown, FaAngleUp, FaArrowLeft, FaArrowRight, FaLink, FaLongArrowAltRight, FaMinusCircle, FaPlusCircle, FaQuestionCircle, FaRegHandPointRight } from 'react-icons/fa';
import BadgeWidget from './BadgeWidget';
import EditorConvertToMarkdown from './TextEditor';
import ModelActions from './ModelActions';
import Tagify from './Tagify';
import AddMediaModal from './Modals/AddMediaModal';
import ButtonWidget from './ButtonWidget';
import { SendReminderModalContent } from './Modals/ModelContents/SendReminderModalContent';
import CommonModal from './Modals/CommonModal';
import { PdfSettingsModalContent } from './Modals/ModelContents/PdfSettingsModalContent';
import { DeleteMessageDetailModalContent } from './Modals/ModelContents/DeleteMessageDetailModalContent';
import { ReportContentModalContent } from './Modals/ModelContents/ReportContentModalContent';
import { UnsavedCommentModalContent } from './Modals/ModelContents/UnsavedCommentModalContent';
import { PermissionModalContent } from './Modals/ModelContents/PermissionModalContent';
import { AddPollModalContent } from './Modals/ModelContents/AddPollModalContent';
import { BookMarkOptionModalContent } from './Modals/GroupActionButtonModalContent.tsx/BookMarkOptionModalContent';
import { BookMarkFooterContent } from '../Pages/Repair/MainContent';
import { RememberOptionModalContent } from './Modals/GroupActionButtonModalContent.tsx/RememberOptionModalContent';
import { ShowHomeScreenModalContent } from './Modals/ModelContents/ShowHomeScreenModalContent';
import { Link } from 'react-router-dom';
import { ConfirmDeleteCommentModalContent } from './Modals/ModelContents/ConfirmDeleteCommentModalContent';
import { AttachmentOptionModalContent } from './Modals/ModelContents/AttachmentOptionModalContent';
import { PleasesModalContent } from './Modals/ModelContents/PleasesModalContent';
import { TiArrowBack } from "react-icons/ti";
import FormControl from './FormControl';
import Index from '../Pages/Message/Index';
import moment, { Moment } from 'moment';
import { useCookies } from 'react-cookie';

type replyType = {
    userName: string,
    profileImg?: string,
    userTooltipText: string,
    commentTime: any,
    commentTimeTooltipText: any,
    commentText: string,
    innerReplyText?: string,
    isDeleted: boolean
}
type commentType = {
    userName: string,
    profileImg?: string,
    userTooltipText: string,
    commentTime: any,
    commentTimeTooltipText: any,
    commentText: string,
    isDeleted: boolean,
    commentReply?: replyType[],
}
const sampleComment: commentType[] = [
    {
        userName: 'Dr. Sven Markus Gallenbach ',
        userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
        commentTime: 'vor 14 Minuten',
        commentTimeTooltipText: '21.06.2023 09:27',
        commentText: 'Testing Comment',
        isDeleted: false,
        commentReply: [],
    },
    {
        userName: 'Dr. Sven Markus Gallenbach ',
        userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
        commentTime: 'vor 22 Minuten',
        commentTimeTooltipText: '21.06.2023 09:27',
        commentText: 'Testing2 Comment',
        isDeleted: false,
        commentReply: [
            {
                userName: 'Dr. Sven Markus Gallenbach ',
                userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
                commentTime: 'vor 14 Minuten',
                commentTimeTooltipText: '21.06.2023 09:27',
                commentText: 'Testing Reply',
                innerReplyText: 'Testing InnerReply',
                isDeleted: false
            },
            {
                userName: 'Dr. Markus ',
                userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
                commentTime: 'vor 13 Minuten',
                commentTimeTooltipText: '21.06.2023 09:27',
                commentText: 'Testing Reply',
                innerReplyText: 'Testing InnerReply',
                isDeleted: false
            }
        ]
    },
    {
        userName: 'Dr. Sven Markus Gallenbach',
        userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
        commentTime: 'vor 22 Minuten',
        commentTimeTooltipText: '21.06.2023 09:27',
        commentText: 'Testing Comment',
        isDeleted: false,
        commentReply: [],
    },

];

type commentDDType = {
    index?: number,
    innerIndex?: number
}

type showReplyType = {
    index: number | undefined,
    innerIndex: number | undefined,
    isShow: boolean
}




interface IProp {
}
export const CommentWidget: FC<IProp> = ({ }) => {
    const [commentData, setCommentData] = useState<commentType[]>(sampleComment);
    const [commentIndexShow, setCommentIndexShow] = useState<commentDDType>({});
    const [commentValue, setCommentValue] = useState<string>('');
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [showReply, setShowReply] = useState<showReplyType>({ index: undefined, innerIndex: undefined, isShow: false });
    const [AddMediaModalShow, setAddMediaModalShow] = useState<boolean>(false);
    const [TextEditorShow, setTextEditorShow] = useState<boolean>(false);
    const [HideFont, setHideFont] = useState<boolean>(false);

    const [cookies, setCookie, removeCookie] = useCookies([
        "authToken",
        "userName",
    ]);


    const handleMouseEnter = (index: number, innerIndex?: number) => {
        var temp = { ...commentIndexShow };
        if (index !== undefined) {
            temp.index = index;
            temp.innerIndex = undefined;
        }
        if (innerIndex !== undefined) {
            temp.index = undefined;
            temp.innerIndex = innerIndex;
        }
        setCommentIndexShow(temp);
    };

    const handleMouseLeave = () => {
        var temp = { ...commentIndexShow };
        temp.index = undefined;
        temp.innerIndex = undefined;
        setCommentIndexShow(temp);
    };
    const HandleFontClick = () => {
        setTextEditorShow(true);
        setHideFont(true);
    }


    // ******** Add Comment Begins ******** //
    const handleCommentSubmit = () => {
        if (!!commentValue) {
            var temp = [...commentData];
            const currentDate = new Date();

            var newObj = {
                userName: cookies.userName,
                userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
                commentTime: currentDate.toISOString(),
                commentTimeTooltipText: currentDate.toDateString(),
                commentText: commentValue,
                isDeleted: false,
                commentReply: [],
            }
            temp.push(newObj);
            setCommentValue('');
            setCommentData(temp);
        }

    }
    const handleReplySubmit = (index: number) => {
        debugger
        if (!!commentValue && isEdited === false) {
            var temp = [...commentData];
            const currentDate = new Date();
            var newObj: replyType = {
                userName: 'Dr. Sven Markus Gallenbach',
                userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
                commentTime: currentDate.toISOString(),
                commentTimeTooltipText: currentDate.toDateString(),
                commentText: commentValue,
                isDeleted: false
            }
            temp[index].commentReply?.push(newObj);
            setCommentData(temp);
            setCommentValue('');
            setShowReply({ index: undefined, innerIndex: undefined, isShow: false });
        }
        if (!!commentValue && isEdited === true) {
            var temp = [...commentData];
            temp[index].commentText = commentValue;
            setCommentData(temp);
            setCommentValue('');
            setShowReply({ index: undefined, innerIndex: undefined, isShow: false });
            setIsEdited(false);
        }
    }
    const handleInnerReplySubmit = (index: number, innerIndex: number) => {
        debugger
        if (!!commentValue && isEdited === false) {
            var temp = [...commentData];
            const currentDate = new Date();
            var tagUser = "@" + temp[index].commentReply?.[innerIndex].userName;

            var newObj = {
                userName: temp[index].userName,
                userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
                commentTime: currentDate.toISOString(),
                commentTimeTooltipText: currentDate.toDateString(),
                commentText: '',
                innerReplyText: tagUser + ' ' + commentValue,
                isDeleted: false
            }
            temp[index].commentReply?.push(newObj);
            setCommentData(temp);
            setCommentValue('');
            setShowReply({ index: undefined, innerIndex: undefined, isShow: false });
        }
        if (!!commentValue && isEdited === true) {
            var temp = [...commentData];
            // @ts-expect-error
            temp[index].commentReply[innerIndex].innerReplyText = commentValue;
            setCommentData(temp);
            setCommentValue('');
            setShowReply({ index: undefined, innerIndex: undefined, isShow: false });
            setIsEdited(false);
        }
    }
    // ******** Add Comment Ends ******** //

    // ******** Edit Comments Begin ******** //
    const editMainComment = (index: number) => {
        debugger
        setIsEdited(true);
        handleReply(index);
        var temp = [...commentData];
        setCommentValue(temp[index].commentText);
    }
    const editInnerComment = (index: number, innerIndex: number) => {
        setIsEdited(true);
        handleReply(index, innerIndex);
        var temp = [...commentData];
        const innerReplyText = temp[index].commentReply?.[innerIndex].innerReplyText;
        if (innerReplyText !== undefined) {
            setCommentValue(innerReplyText);
        }
    }
    // ******** Edit Comments Ends ******** //

    // ******** Delete Comments Begin ******** //
    const deleteMainComment = (index: number) => {
        var temp = [...commentData];
        temp.splice(index, 1);
        setCommentData(temp);

    }
    const deleteInnerComment = (index: number, innerIndex: number) => {
        var temp = [...commentData];
        temp[index].commentReply?.splice(innerIndex, 1);
        setCommentData(temp);
    }
    // ******** Delete Comments Ends ******** //

    const handleReply = (index: number, innerIndex?: number) => {
        debugger
        var temp = { ...showReply };
        if (index !== undefined && innerIndex === undefined) {
            temp.isShow = true;
            temp.index = index;
            temp.innerIndex = undefined;
        }
        if (innerIndex !== undefined && index !== undefined) {
            temp.isShow = true;
            temp.index = index;
            temp.innerIndex = innerIndex;
        }
        setShowReply(temp);
    }
    const handleCancelReply = (index: number) => {
        setShowReply({ index: undefined, innerIndex: undefined, isShow: false });
        setCommentValue('');
        setIsEdited(false);
    }




    return (
        <>
            <CommentWrapper className='mb-3'>
                <Box className='bg-light p-2 my-3'>
                    <BodyText fontWeight='700' textColor='#666' fontSize='15px'>Kommentar</BodyText>
                    {commentData.map((item, index) => {
                        return <>
                            <CommentItem key={index}>
                                <Divider mt='10px' mb='10px' />

                                <Box>
                                    <Comment>
                                        <DisplayStart verticalAlign='start'
                                            width='100%'
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={handleMouseLeave}>
                                            <Box className='me-2'>
                                                <img width='30' height='30' src={!!item.profileImg ? item.profileImg : '/images/account.png'} alt="userName-img" />
                                            </Box>
                                            <Box width='100%'>
                                                <DisplayBetween width='100%'>
                                                    <Box>
                                                        <DisplayStart>
                                                            <CustomTooltip
                                                                ToolTipTrigger={<BodyText fontWeight='700' textColor='#666' fontSize='14px'>{item.userName}</BodyText>}
                                                                toolTipContent={item.userTooltipText}
                                                                toolTipPlacement='top'
                                                                contentAlign='center'
                                                            />
                                                            <span className='mx-1'> - </span>
                                                            <CustomTooltip
                                                                ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px'> {moment(new Date(item.commentTime)).startOf('hour').fromNow()} </BodyText>}
                                                                toolTipContent={item.commentTimeTooltipText}
                                                                toolTipPlacement='top'
                                                                contentAlign='center'
                                                            />
                                                            <div className='d-flex align-items-center justify-content-start'>
                                                                <Link to="" className='text-decoration-none' onClick={() => handleReply(index)}>
                                                                    <TiArrowBack className='ms-2' color='#666' />
                                                                    <span className='ms-1 fw-400 w-176px text-truncate text-link-color'> Antwort </span>
                                                                </Link>
                                                            </div>
                                                        </DisplayStart>
                                                    </Box>
                                                    <Box>
                                                        <DisplayEnd>
                                                            {index === commentIndexShow.index ?
                                                                <>
                                                                    <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="">
                                                                        <Dropdown.Item onClick={() => editMainComment(index)}>
                                                                            <span className='f-14px'> Bearbeiten </span>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => deleteMainComment(index)}>
                                                                            <span className='f-14px'> Löschen </span>
                                                                        </Dropdown.Item>
                                                                    </DropdownButton>
                                                                </>
                                                                : null}
                                                        </DisplayEnd>
                                                    </Box>
                                                </DisplayBetween>
                                                <BodyText fontWeight='400' textColor='#333' fontSize='13px' mt='5px'>{item.commentText}</BodyText>
                                            </Box>
                                        </DisplayStart>
                                    </Comment>
                                    {showReply.isShow === true && index === showReply.index && showReply.innerIndex === undefined ?
                                        <ReplyBox className={isEdited ? 'w-100' : ''}>
                                            <DisplayStart verticalAlign='start' className='mt-4 mb-2'>
                                                <Box className='me-2'>
                                                    <img width='30' height='30' src='/images/account.png' alt="userName-img" />
                                                </Box>
                                                <Box width='100%'>
                                                    <Box className="mb-3">
                                                        <FormControl Name='comment' Type="textarea" Value={commentValue} Placeholder="Text" rows={4} handleChange={(event) => setCommentValue(event.target.value)} />
                                                    </Box>
                                                    <ModelActions
                                                        ImgClick={() => setAddMediaModalShow(true)}
                                                        FontClick={() => HandleFontClick()}
                                                        isHideFont={HideFont}
                                                    />
                                                    <DisplayEnd>
                                                        <ButtonWidget class="me-1" text={"Abbrechen"} varient='light' handleClick={() => handleCancelReply(index)} />
                                                        <ButtonWidget text={"Antwort"} varient='primary' handleClick={() => handleReplySubmit(index)} />
                                                    </DisplayEnd>
                                                </Box>
                                            </DisplayStart>
                                        </ReplyBox>
                                        :
                                        null
                                    }
                                </Box>
                                {!!item.commentReply ?
                                    item.commentReply.map((innerItem, innerIndex) => {
                                        return <>
                                            <ReplyItem>
                                                <Divider mt='10px' mb='10px' />
                                                <Box>
                                                    <Comment>
                                                        <DisplayStart verticalAlign='start'
                                                            width='100%'
                                                            onMouseEnter={() => handleMouseEnter(index, innerIndex)}
                                                            onMouseLeave={handleMouseLeave}>
                                                            <Box className='me-2'>
                                                                <img width='30' height='30' src='/images/account.png' alt="userName-img" />
                                                            </Box>
                                                            <Box width='100%'>
                                                                <DisplayBetween width='100%'>
                                                                    <Box>
                                                                        <DisplayStart>
                                                                            <CustomTooltip
                                                                                ToolTipTrigger={<BodyText fontWeight='700' textColor='#666' fontSize='14px'>{innerItem.userName}</BodyText>}
                                                                                toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                                                                toolTipPlacement='top'
                                                                                contentAlign='center'
                                                                            />
                                                                            <span className='mx-1'> - </span>
                                                                            <CustomTooltip
                                                                                ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px'> {innerItem.commentTime} </BodyText>}
                                                                                toolTipContent={innerItem.commentTime}
                                                                                toolTipPlacement='top'
                                                                                contentAlign='center'
                                                                            />
                                                                            <div className='d-flex align-items-center justify-content-start'>
                                                                                <Link to="" className='text-decoration-none' onClick={() => handleReply(index, innerIndex)}>
                                                                                    <TiArrowBack className='ms-2' color='#666' />
                                                                                    <span className='ms-1 fw-400 w-176px text-truncate text-link-color'> Antworten </span>
                                                                                </Link>
                                                                            </div>
                                                                        </DisplayStart>
                                                                    </Box>
                                                                    <Box>
                                                                        <DisplayEnd>
                                                                            {innerIndex === commentIndexShow.innerIndex ?
                                                                                <>
                                                                                    <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="">
                                                                                        <Dropdown.Item onClick={() => editInnerComment(index, innerIndex)}>
                                                                                            <span className='f-14px'> Bearbeiten </span>
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item onClick={() => deleteInnerComment(index, innerIndex)}>
                                                                                            <span className='f-14px'> Löschen </span>
                                                                                        </Dropdown.Item>
                                                                                    </DropdownButton>
                                                                                </>
                                                                                : null}
                                                                        </DisplayEnd>
                                                                    </Box>
                                                                </DisplayBetween>
                                                                {!!innerItem.innerReplyText ?
                                                                    <BodyText fontWeight='400' textColor='#333' fontSize='13px' mt='5px'>{innerItem.innerReplyText}</BodyText>
                                                                    :
                                                                    <BodyText fontWeight='400' textColor='#333' fontSize='13px' mt='5px'>{innerItem.commentText}</BodyText>
                                                                }
                                                            </Box>
                                                        </DisplayStart>
                                                    </Comment>
                                                    {showReply.isShow === true && index === showReply.index && innerIndex === showReply.innerIndex ?
                                                        <InnerReplyItem>
                                                            <DisplayStart verticalAlign='start' className='mt-4 mb-2'>
                                                                <Box className='me-2'>
                                                                    <img width='30' height='30' src='/images/account.png' alt="userName-img" />
                                                                </Box>
                                                                <Box width='100%'>
                                                                    <Box className="mb-3">
                                                                        <FormControl Name='comment' Type="textarea" Value={commentValue} Placeholder="Text" rows={4} handleChange={(event) => setCommentValue(event.target.value)} />
                                                                    </Box>
                                                                    <ModelActions
                                                                        ImgClick={() => setAddMediaModalShow(true)}
                                                                        FontClick={() => HandleFontClick()}
                                                                        isHideFont={HideFont}
                                                                    />
                                                                    <DisplayEnd>
                                                                        <ButtonWidget class="me-1" text={"Abbrechen"} varient='light' handleClick={() => handleCancelReply(index)} />
                                                                        <ButtonWidget text={"Antwort"} varient='primary' handleClick={() => handleInnerReplySubmit(index, innerIndex)} />
                                                                    </DisplayEnd>
                                                                </Box>
                                                            </DisplayStart>
                                                        </InnerReplyItem>
                                                        :
                                                        null
                                                    }
                                                </Box>
                                            </ReplyItem>
                                        </>
                                    })
                                    :
                                    null
                                }

                            </CommentItem>
                        </>
                    })}
                    {!showReply.isShow && !showReply.index ?
                        <CommentBox>
                            <DisplayStart verticalAlign='start' className='mt-4 mb-2'>
                                <Box className='me-2'>
                                    <img width='30' height='30' src='/images/account.png' alt="userName-img" />
                                </Box>
                                <Box width='100%'>
                                    <Box className="mb-3">
                                        <FormControl Name='comment' Type="textarea" Value={commentValue} Placeholder="Text" rows={5} handleChange={(event) => setCommentValue(event.target.value)} />
                                    </Box>
                                    <ModelActions
                                        ImgClick={() => setAddMediaModalShow(true)}
                                        FontClick={() => HandleFontClick()}
                                        isHideFont={HideFont}
                                    />
                                    <DisplayEnd>
                                        <ButtonWidget text={"Kommentar"} varient='primary' handleClick={() => handleCommentSubmit()} />
                                    </DisplayEnd>
                                </Box>
                            </DisplayStart>
                        </CommentBox>
                        :
                        null
                    }
                </Box>
            </CommentWrapper>

            <AddMediaModal displayAddMediaModal={AddMediaModalShow} hideAddMediaModal={() => setAddMediaModalShow(false)} />
        </>
    )

}