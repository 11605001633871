import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { FaCog } from "react-icons/fa";


const Update = () => {

    const Updates = [
        {
            title: 'Moments - the social stream for the whole team',
            description: 'Medikit Moments',
            date: '05.10.2022',
        },
        {
            title: 'medikit Insights | Vol. 08',
            description: 'medikit Insights',
            date: '22.09.2022',
        },
        {
            title: 'Product Update - Measures to improve performance',
            description: 'medikit tool time',
            date: '08.07.2022',
        },
        {
            title: 'Product Update - Measures to improve performance',
            description: 'medikit tool time',
            date: '08.07.2022',
        },
        {
            title: 'medikit Insights | Vol. 07',
            description: '',
            date: '22.06.2022',
        },
        {
            title: 'New Feature – Limited visibility of task results',
            description: 'medikit tool time',
            date: '19.05.2022',
        },
        {
            title: 'New feature - add absence cover in medikit',
            description: 'medikit tool time',
            date: '16.05.2022',
        },
        {
            title: 'New feature – Academic title in medikit profile',
            description: 'medikit tool time',
            date: '05.05.2022',
        },
        {
            title: 'New Feature - More details on repairs',
            description: 'medikit tool time',
            date: '20.04.2022',
        },
        {
            title: 'medikit Insights | Vol. 06',
            description: '',
            date: '24.03.2022',
        },
        {
            title: 'New Feature: Housekeeping Statistics',
            description: 'medikit tool time',
            date: '16.03.2022',
        },
        {
            title: 'New Feature - Plan ahead with the Housekeeping Tool',
            description: 'medikit tool time',
            date: '24.02.2022',
        },
        {
            title: 'New design of the group labels',
            description: 'medikit Tool time',
            date: '27.01.2022',
        },
        {
            title: 'medikit Insights | Vol. 05',
            description: '',
            date: '15.12.2021',
        },
        {
            title: 'medikit Insights | Vol. 04',
            description: '',
            date: '16.09.2021',
        },
        {
            title: 'New Feature - Checklists with files and photos',
            description: 'medikit tool time',
            date: '25.08.2021',
        },
        {
            title: 'medikit Insights | Vol. 03',
            description: '',
            date: '21.06.2021',
        },
        {
            title: 'medikit Insights | Vol. 02',
            description: '',
            date: '29.03.2021',
        },
        {
            title: 'medikit Insights | Vol. 01',
            description: 'The digital medikit newsletter, only for insiders.',
            date: '20.12.2020',
        },
        {
            title: 'Update 2019 / II',
            description: 'Create external users',
            date: '28.08.2019',
        },
        {
            title: 'Update 2019 / I',
            description: 'Start page adjustable after log-in',
            date: '31.01.2019',
        },
        {
            title: 'Admin Update 2018 / I',
            description: 'New security features in medikit',
            date: '22.10.2018',
        },
        {
            title: 'Update 2018 / IV',
            description: '2 new functions for the manual',
            date: '20.09.2018',
        },
        {
            title: 'Update 2018 / III',
            description: '2 new functions for the homepage',
            date: '13.08.2018',
        },
        {
            title: 'GDPR & data protection',
            description: 'Information on GDPR-compliant use',
            date: '25.05.2018',
        },
        {
            title: 'Update 2018 / I',
            description: 'medikit shines in new splendor',
            date: '14.03.2018',
        },
        {
            title: 'Update 2017 / V',
            description: 'Advanced watch list & bookmark options',
            date: '21.09.2017',
        },
        {
            title: 'Update 2017 / IV',
            description: 'Templates in the news and manual',
            date: '21.09.2017',
        },
        {
            title: 'Update 2017 / II',
            description: 'Templates, welcome message & statistics',
            date: '27.04.2017',
        },
        {
            title: 'Update 2017 / I',
            description: 'Absence can be set in the profile',
            date: '03.03.2017',
        },
        {
            title: 'Update 2016 / IV',
            description: 'More status checklists and escalation levels',
            date: '14.09.2016',
        },
        {
            title: 'Update 2016 / III',
            description: 'Improvements compact view as well as individual filters as well as colored notifications',
            date: '07.09.2016',
        },
        {
            title: 'Update 2016 / II',
            description: '3 new manual functions',
            date: '28.04.2016',
        },
        {
            title: 'Update 2016 / I',
            description: 'New Design - New Features',
            date: '11.04.2016',
        },
        {
            title: 'Update 2015 / III',
            description: 'After the new calendar system has been online for 4 months, we have now added a function extension for you.',
            date: '14.10.2015',
        },
        {
            title: 'Update 2015 / II',
            description: 'The new calendar has arrived',
            date: '03.06.2015',
        },
        {
            title: 'Update 2015 / I',
            description: 'With the last major update, there are now some improvements.',
            date: '01.04.2015',
        },
        {
            title: 'Admin Update 2015 / I',
            description: 'The new repair tool is here',
            date: '24.03.2015',
        },
        {
            title: 'Update 2014 / V',
            description: 'Versioning of articles, new display and download options for attachments and PDF are now also available in the calendar week view',
            date: '02.12.2014',
        },
        {
            title: 'Update 2014 / IV',
            description: 'Tasks without a due date & user filter for tasks',
            date: '27.11.2014',
        },
        {
            title: 'Update 2014 / III',
            description: 'The new task tool is here!',
            date: '18.09.2014',
        },
        {
            title: 'Admin Update 2014 / I',
            description: 'Conversation leave function',
            date: '12.03.2014',
        },
    ]

    return (
        <>
            <Card className='border-0 rounded-0'>
                <Card.Header className='ps-0 border-0'>
                    <Card.Title className='text-uppercase f-14px'>Updates</Card.Title>
                </Card.Header>
                <Card.Body className='p-0 boxShadow cursor-pointer'>
                    <Table responsive hover className='mb-0'>
                        <tbody>
                            {Updates.map((element, index) => {
                                return (
                                    <>
                                        <tr className='' key={index}>
                                            <td className='p-td'>
                                                <span className='ms-2'><FaCog size={18} /></span>
                                            </td>
                                            <td className='p-td'>
                                                <div className='msg-title'>
                                                    <p className='m-0 fw-600'>{element.title}</p>
                                                </div>
                                            </td>
                                            <td className='p-td'>
                                                <span className='f-13px'>{element.description}</span>
                                            </td>
                                            <td>
                                                <span className='f-13px'>{element.date}</span>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}
export default Update;