import * as React from "react";
import "./App.css";
import "./mainstyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Layout/Header";
import { Footer } from "./Layout/Footer/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SidebarRight from "./Layout/Sidebar/SidebarRight";
import Container from "react-bootstrap/Container";
import Home from "./Pages/Home/Index";
import Login from "./Pages/Account/Login/Index";
import ForgetPassword from "./Pages/Account/ForgetPassword/Index";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { AccountHeader } from "./Layout/AccountLayout/AccountHeader";
import { AccountFooter } from "./Layout/AccountLayout/AccountFooter";
import Messages from "./Pages/Message/Index";
import { IdeaIndex } from "./Pages/Idea/IdeaIndex";
import DashboardActions from "./Components/DashboardActions";
import { useState } from "react";
import ResetModal from "./Components/Modals/ResetModal";
import AddModuleModal from "./Components/Modals/AddModuleModal";
import ResetPassword from "./Pages/Account/ResetPassword/Index";
import EmailConfirmation from "./Pages/Account/EmailConfirmation/Index";
import InfosPage from "./Pages/Infos/Index";
import Tips from "./Pages/Infos/Tips/Tips";
import Update from "./Pages/Infos/Updates/Update";
import toast, { Toaster } from "react-hot-toast";
import { useCookies } from "react-cookie";
import { JsxChild, JsxElement, JsxFragment } from "typescript";
import { HandBookIndex } from "./Pages/HandBooks/HandBookIndex";
import { TaskIndex } from "./Pages/Task/TaskIndex";
import { RepairIndex } from "./Pages/Repair/RepairIndex";
import { NewsIndex } from "./Pages/News/NewsIndex";
import { MessageDetailIndex } from "./Pages/Message/details/MessageDetailIndex";
import { HandbookDetailIndex } from "./Pages/HandBooks/details/HandbookDetailIndex";
import FullCalendarPage from "./Pages/mycalendar";
import MessageListWrapper from "./Pages/Message/MessageListWrapper";
import { NewsListWrapper } from "./Pages/News/NewsListWrapper";
import { NewsDetailIndex } from "./Pages/News/details/NewsDetailIndex";
import { IdeaListWrapper } from "./Pages/Idea/IdeaListWrapper";
import { IdeaDetailIndex } from "./Pages/Idea/details/IdeaDetailIndex";
import { HandBookList } from "./Pages/HandBooks/HandBookList";
import { HandbookDetail } from "./Pages/HandBooks/details/HandbookDetail";
import { TaskListWrapper } from "./Pages/Task/TaskListWrapper";
import { TaskDetailIndex } from "./Pages/Task/details/TaskDetailIndex";
import { Loader } from "./Components/Loader";
import { useSelector } from "react-redux";
import { stat } from "fs";
import { store } from './Redux/store';
import { TutorialVideo } from "./Pages/Infos/Tutorial-Videos/TutorialVideo";
import { IndexUser } from "./Pages/Users/IndexUser";
import { UserDetailIndex } from "./Pages/Users/detail/UserDetailIndex";
import { MarketIndex } from "./Pages/Market-Place/MarketIndex";
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { SidebarLeft } from "./Layout/Sidebar/SidebarLeft";
import { DashboardModule } from "./Components/DashboardModules/DashboardModule";
import { NotedModule } from "./Components/DashboardModules/NotedModule";
import StatusRepairsModule from "./Components/DashboardModules/StatusRepairsModule";
import EventsModule from "./Components/DashboardModules/EventsModule";
import { CustomCard } from "./Components/CustomCard";
import OverdueTasksModule from "./Components/DashboardModules/OverdueTasksModule";
import InformationTabs from "./Pages/Home/InformationTabs";
import { LatestNewsModule } from "./Components/DashboardModules/LatestNewsModule";
import { UserList } from "./Pages/Admin/User/UserList";
import { AdminIndex } from "./Pages/Admin/AdminIndex";
import { GroupList } from "./Pages/Admin/TheGroup/GroupList";
import { SettingsIndex } from "./Pages/Admin/Setting/SettingsIndex";
import { LatestIdeasModule } from "./Components/DashboardModules/LatestIdeasModule";
import { TodayTasksModule } from "./Components/DashboardModules/TodayTasksModule";
import { LatestRepairOrderModule } from "./Components/DashboardModules/LatestRepairOrderModule";
import { AdminDashboard } from "./Pages/Admin/AdminDashboard/AdminDashboard";
import { ModerationReportsIndex } from "./Pages/Admin/User/ModerationReportsIndex";
import { GlobalStyles } from "./Components/StyledComponents";
import { ThemeProvider } from "styled-components";
import { DashboardList } from "./Pages/Admin/Dashboard/DashboardList";
import { Option } from "./Pages/Admin/Setting/Option";
import { LanguageSetting } from "./Pages/Admin/Setting/LanguageSetting";
import { WelcomeMessage } from "./Pages/Admin/Setting/WelcomeMessage";
import { PDFSetting } from "./Pages/Admin/Setting/PDFSetting";
import { StandardStreamtyp } from "./Pages/Admin/Setting/StandardStreamtyp";
import { EmbeddedVideos } from "./Pages/Admin/Setting/EmbeddedVideos";
import { DeviceManagerList } from "./Pages/Admin/DeviceManager/DeviceManagerList";
import IPManager from "./Pages/Admin/IPManager/IPManager";
import SecurityIndex from "./Pages/Admin/Security/SecurityIndex";
import Article from "./Pages/Admin/Article/Article";
import ToolConfigurationIndex from "./Pages/Admin/ToolConfiguration/ToolConfigurationIndex";
import BackUp from "./Pages/Admin/BackUp/BackUp";
import DataProtection from "./Pages/Admin/DataProtection/DataProtection";
import MobilePIN from "./Pages/Admin/Security/MobilePIN";
import Passwords from "./Pages/Admin/Security/Passwords";
import MessagesConfig from "./Pages/Admin/ToolConfiguration/MessagesConfig";
import NewsConfig from "./Pages/Admin/ToolConfiguration/NewsConfig";
import IdeasConfig from "./Pages/Admin/ToolConfiguration/IdeasConfig";
import MomentsConfig from "./Pages/Admin/ToolConfiguration/MomentsConfig";
import TasksConfig from "./Pages/Admin/ToolConfiguration/TasksConfig";
import TermConfig from "./Pages/Admin/ToolConfiguration/TermConfig";
import RepairsConfig from "./Pages/Admin/ToolConfiguration/RepairsConfig";
import ManualConfig from "./Pages/Admin/ToolConfiguration/ManualConfig";

interface AppState {
  app: {
    showLoader: boolean,
    theme: {
      colors: {
        primaryColor: string
      }
    }
  };
}

interface IProps {
}

const App: React.FC<IProps> = ({ }) => {
  let appFormData = useSelector((state: AppState) => state.app.showLoader);
  const primaryColor = useSelector((state: AppState) => state.app.theme.colors.primaryColor);
  const theme = {
    colors: {
      primaryColor: primaryColor,
    },
  };


  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <div className="App">
          <div className="d-flex flex-column justify-content-between min-vh-100">
            {appFormData ?
              <Loader />
              :
              null
            }
            <Routes>
              <Route
                path="/"
                element={
                  <RestrictLogin>
                    <AccountLayout />
                  </RestrictLogin>
                }
              >
                <Route index element={<Login />} />
                <Route path="forgetpassword" element={<ForgetPassword />} />
                <Route path="resetpassword" element={<ResetPassword />} />
                <Route path="emailconfirmation" element={<EmailConfirmation />} />
              </Route>
              <Route path="/" element={
                <PrivateRoute>
                  <DashboardContent />
                </PrivateRoute>
              }
              >
                <Route path="home" element={<Home />} />
              </Route>

              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <MainLayout />
                  </PrivateRoute>
                }
              >
                <Route path="messages" element={<Messages />}>
                  <Route index element={<MessageListWrapper />} />
                  <Route path="messages-detial" element={<MessageDetailIndex />} />
                </Route>

                <Route path="news" element={<NewsIndex />}>
                  <Route index element={<NewsListWrapper />} />
                  <Route path="news-detial" element={<NewsDetailIndex />} />
                </Route>

                <Route path="ideas" element={<IdeaIndex />}>
                  <Route index element={<IdeaListWrapper />} />
                  <Route path="ideas-detial" element={<IdeaDetailIndex />} />
                </Route>

                <Route path="handbooks" element={<HandBookIndex />}>
                  <Route index element={<HandBookList />} />
                  <Route path="handbooks-detial" element={<HandbookDetailIndex />} />
                  <Route path="handbooks-detial-id" element={<HandbookDetail />} />
                </Route>

                <Route path="tasks" element={<TaskIndex />}>
                  <Route index element={<TaskListWrapper />} />
                  <Route path="tasks-detial/:id" element={<TaskDetailIndex />} />
                </Route>

                <Route path="calendar" element={<FullCalendarPage />} />
                <Route path="repair" element={<RepairIndex />} />
                <Route path="news" element={<NewsIndex />} />

                <Route path="user" element={<IndexUser />} />
                <Route path="user/user-detail" element={<UserDetailIndex />} />

                <Route path="market" element={<MarketIndex />} />

                {/* <Route path="/admin/user" element={<UserList />} /> */}


                <Route path="infos" element={<InfosPage />}>
                  <Route path="tips" element={<Tips />} />
                  <Route path="updates" element={<Update />} />
                  <Route path="tutorialVideos" element={<TutorialVideo />} />
                </Route>
              </Route>

              <Route path="/"
                element={
                  <PrivateRoute>
                    <AdminLayout />
                  </PrivateRoute>
                }>
                <Route path="admin" element={<AdminIndex />}>
                  <Route index element={<AdminDashboard />} />
                  <Route path="user" element={<UserList />} />
                  <Route path="moderation-reports" element={<ModerationReportsIndex />} />
                  <Route path="groups" element={<GroupList />} />
                  <Route path="settings" element={<SettingsIndex />}>
                    <Route path='option' element={<Option />} />
                    <Route path="language" element={<LanguageSetting />} />
                    <Route path="welcome-meessage" element={<WelcomeMessage />} />
                    <Route path="pdf-setting" element={<PDFSetting />} />
                    <Route path="standard-streamtyp" element={<StandardStreamtyp />} />
                    <Route path="embedded-videos" element={<EmbeddedVideos />} />
                  </Route>
                  <Route path="security" element={<SecurityIndex />}>
                    <Route path='mobilepin' element={<MobilePIN />} />
                    <Route path="passwords" element={<Passwords />} />
                  </Route>
                  <Route path="dashboards" element={<DashboardList />} />
                  <Route path="device-manager" element={<DeviceManagerList />} />
                  <Route path="ip-manager" element={<IPManager />} />
                  <Route path="article" element={<Article />} />
                  <Route path="tool-configuration" element={<ToolConfigurationIndex />}>
                    <Route path='messages' element={<MessagesConfig />} />
                    <Route path='news' element={<NewsConfig />} />
                    <Route path='ideas' element={<IdeasConfig />} />
                    <Route path='moments' element={<MomentsConfig />} />
                    <Route path='tasks' element={<TasksConfig />} />
                    <Route path='term' element={<TermConfig />} />
                    <Route path='repairs' element={<RepairsConfig />} />
                    <Route path='manual' element={<ManualConfig />} />
                  </Route>
                  <Route path="backup" element={<BackUp />} />
                  <Route path="data-protection" element={<DataProtection />} />
                </Route>
              </Route>

            </Routes>
          </div>
        </div >
      </ThemeProvider>

    </>
  );
}
export default App;




export const hexToRgba = (hex: any, alpha: any) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}




type BoardState = {
  tasks: { [taskId: string]: { id: string; content: JSX.Element | JsxElement | string; isDragDisabled?: boolean } };
  columns: {
    [columnId: string]: { id: string; dragableItems: string[] };
  };
};


export type selectedModuleType = {
  key: string,
  moduleName: string
}

const DashboardContent = () => {
  const [DashboardActionsShow, setDashboardActionsShow] = useState(false);
  const [ResetModalShow, setResetModalShow] = useState(false);
  const [AddModuleShow, setAddModuleShow] = useState(false);

  const [selectedModule, setSelectedModule] = useState<selectedModuleType>({ key: '', moduleName: '' });

  const compIds = {
    "DashboardModule": <DashboardModule showActions={() => setDashboardActionsShow(!DashboardActionsShow)} isRed={DashboardActionsShow} />,
    "NotedModule": (providedDragHandleProps: any) => (
      <NotedModule dragHandleProps={providedDragHandleProps} isDragMode={DashboardActionsShow} />
    ),
    "InformationTabs": <InformationTabs />,
    "OverdueTasksModule": (providedDragHandleProps: any) => (
      <OverdueTasksModule dragHandleProps={providedDragHandleProps} isDragMode={DashboardActionsShow} />
    ),
    "StatusRepairsModule": (providedDragHandleProps: any) => (
      <StatusRepairsModule dragHandleProps={providedDragHandleProps} isDragMode={DashboardActionsShow} />
    ),
    "EventsModule": (providedDragHandleProps: any) => (
      <EventsModule dragHandleProps={providedDragHandleProps} isDragMode={DashboardActionsShow} />
    ),
    "LatestNewsModule": (providedDragHandleProps: any) => (
      <LatestNewsModule dragHandleProps={providedDragHandleProps} isDragMode={DashboardActionsShow} />
    ),
    "LatestIdeasModule": (providedDragHandleProps: any) => (
      <LatestIdeasModule dragHandleProps={providedDragHandleProps} isDragMode={DashboardActionsShow} />
    ),
    "TodayTasksModule": (providedDragHandleProps: any) => (
      <TodayTasksModule dragHandleProps={providedDragHandleProps} isDragMode={DashboardActionsShow} />
    ),
    "LatestRepairOrderModule": (providedDragHandleProps: any) => (
      <LatestRepairOrderModule dragHandleProps={providedDragHandleProps} isDragMode={DashboardActionsShow} />
    ),
  }

  const initialBoard: BoardState = {
    tasks: {
      "item-1": { id: "item-1", content: "DashboardModule", isDragDisabled: true },
      "item-2": { id: "item-2", content: "NotedModule" },
      "item-3": { id: "item-3", content: "InformationTabs", isDragDisabled: true },
      "item-4": { id: "item-4", content: "OverdueTasksModule" },
      "item-5": { id: "item-5", content: "StatusRepairsModule" },
      "item-6": { id: "item-6", content: "EventsModule" },
      "item-7": { id: "item-7", content: "LatestNewsModule" },
      "item-8": { id: "item-8", content: "LatestIdeasModule" },
      "item-9": { id: "item-9", content: "TodayTasksModule" },
      "item-10": { id: "item-10", content: "LatestRepairOrderModule" },
    },
    columns: {
      "column-1": {
        id: "column-1",
        dragableItems: ["item-1", "item-2"],
      },
      "column-2": {
        id: "column-2",
        dragableItems: ["item-3", "item-4"],
      },
      "column-3": {
        id: "column-3",
        dragableItems: ["item-5", "item-6"],
      },
    },
  };

  const [board, setBoard] = useState(initialBoard);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    console.log(board);
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const startColumn = board.columns[source.droppableId];
    const endColumn = board.columns[destination.droppableId];

    if (startColumn === endColumn) {
      const newTaskIds = Array.from(startColumn.dragableItems);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...startColumn,
        dragableItems: newTaskIds,
      };

      setBoard({
        ...board,
        columns: {
          ...board.columns,
          [newColumn.id]: newColumn,
        },
      });
    } else {
      const startTaskIds = Array.from(startColumn.dragableItems);
      startTaskIds.splice(source.index, 1);
      const newStartColumn = {
        ...startColumn,
        dragableItems: startTaskIds,
      };

      const endTaskIds = Array.from(endColumn.dragableItems);
      endTaskIds.splice(destination.index, 0, draggableId);
      const newEndColumn = {
        ...endColumn,
        dragableItems: endTaskIds,
      };

      setBoard({
        ...board,
        columns: {
          ...board.columns,
          [newStartColumn.id]: newStartColumn,
          [newEndColumn.id]: newEndColumn,
        },
      });
    }
  };

  React.useEffect(() => {
    console.log(board);
  }, [board]);

  const SelectedModule = (key: string, moduleName: string) => {
    setSelectedModule({ key: key, moduleName: moduleName });
    setBoard({ ...board, columns: { ...board.columns, "column-2": { ...board.columns["column-2"], dragableItems: [...board.columns["column-2"].dragableItems, key] } } });
  }



  return (
    <>
      <Header />
      <div className="MainContent">
        <Container fluid>
          <Row className={`${DashboardActionsShow ? "mt-4" : "mt-5"}`}>
            <Col md={12}>
              {DashboardActionsShow ? (
                <DashboardActions
                  resetAction={() => setResetModalShow(true)}
                  exitEdit={() => setDashboardActionsShow(false)}
                  addModule={() => setAddModuleShow(true)}
                />
              ) : null}
            </Col>
            <Col md={12}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Row>
                  <Col className="w-col">
                    <Droppable key="column-1" droppableId="column-1">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="column">

                          <SidebarLeft compIds={compIds} showActions={() => setDashboardActionsShow(!DashboardActionsShow)}
                            isRed={DashboardActionsShow}
                            dragData={board}
                          />

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Col>
                  <Col lg={7}>
                    <Droppable key="column-2" droppableId="column-2">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="column">

                          <Outlet context={[board, compIds]} />

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Col>
                  <Col className="w-col">
                    <Droppable key="column-3" droppableId="column-3">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="column">
                          <SidebarRight dragData={board} compIds={compIds} />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Col>
                </Row>
              </DragDropContext>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
      <ResetModal
        showReset={ResetModalShow}
        hideReset={() => setResetModalShow(false)}
      />
      <AddModuleModal
        handleSelectModule={SelectedModule}
        showAddModule={AddModuleShow}
        hideAddModule={() => setAddModuleShow(false)}
      />
    </>
  );
}
const MainLayout = () => {

  return (
    <>
      <Header />
      <div className="MainContent mb-5">
        <Container fluid>
          <Row className="mt-5">
            <Col md={12}>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
const AdminLayout = () => {

  return (
    <>
      <Header />
      <div className="MainContent mb-5">
        <Container fluid>
          <Row className="mt-5">
            <Col md={12}>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
const AccountLayout = () => {
  return (
    <>
      <AccountHeader />
      <div className="MainContent-account">
        <Container fluid>
          <Row>
            <Col md={4} className="mx-auto">
              {<Outlet />}
            </Col>
          </Row>
        </Container>
      </div>
      <AccountFooter />
    </>
  );
};


type Props = {
  children: string | JSX.Element | JSX.Element[];
};
function PrivateRoute({ children }: Props) {
  const [cookies, setCookie, removeCookie] = useCookies(["authToken"]);

  return (
    <>{isAuthenticated(cookies) ? children : <Navigate to="/" replace />}</>
  );
}

function RestrictLogin({ children }: Props) {
  const [cookies, setCookie, removeCookie] = useCookies(["authToken"]);

  return (
    <>
      {!isAuthenticated(cookies) ? children : <Navigate to="/home" replace />}
    </>
  );
}

const isAuthenticated = (cookies: any) => {
  let token = cookies.authToken;
  if (!!token) {
    return true;
  } else {
    return false;
  }
};
