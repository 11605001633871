import { PagingDto } from "../CommonModel/pagingDto";
import { ResponseDto } from "../CommonModel/responseDto";
import { UserProfileDto } from "../Model/ViewModels/User/UserProfileDto";
import BaseService from "./baseService";

export default class UserService extends BaseService {
  url: string = "api/account/";

  public getPaginationResult = (data: PagingDto): Promise<ResponseDto> => {
    return this.GET<ResponseDto>(this.url + "updateUserProfile", data);
  };
  public async saveProfile(data: UserProfileDto): Promise<boolean> {
    return this.POSTWithFile<boolean>(this.url + "updateUserProfile", data);
  }

  // public getList = async (): Promise<BoardDto[]> => {
  //   return this.GET<BoardDto[]>(this.url + "GetList", {});
  // };

  // public getById = async (id: string): Promise<BoardDto> => {
  //   return this.GET<BoardDto>(this.url + "GetById", { id: id });
  // };

  // public deleteRecord = async (id: string): Promise<boolean> => {
  //   return this.DELETE<boolean>(this.url + "DeleteRecord", { id: id });
  // };

}
