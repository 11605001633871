import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, } from 'react-bootstrap';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import TemplateModal from '../../Components/Modals/SelectTemplateModal';
import AddTemplateModal from '../../Components/Modals/AddTemplateModal';
import Recipient from '../../Components/Modals/Recipient';
import AbortAlrt from '../../Components/Modals/AbortAlrt';
import EditorConvertToMarkdown from '../../Components/TextEditor';
import NonPublicNews from '../../Components/NonPublicNews';
import AddMediaModal from '../../Components/Modals/AddMediaModal';
import ModelActions from '../../Components/ModelActions';
import Tagify from '../../Components/Tagify';
import { ModalHeader } from '../../Components/StyledComponents';

interface propType {
    showRepair: boolean,
    hideRepair: () => void,
}

function AddEditRepair(props: propType) {
    // State for modals Begin
    const [RecipientModal, setRecipientModal] = useState<boolean>(false);
    const [AlertModal, setAlertModal] = useState<boolean>(false);
    const [TemplateModalShow, setTemplateModalShow] = useState<boolean>(false);
    const [AddTemplateModalShow, setAddTemplateModalShow] = useState<boolean>(false);
    const [AddMediaModalShow, setAddMediaModalShow] = useState<boolean>(false);
    // State for modals End

    // State for Hide Show Begin
    const [AddUser, setAddUser] = useState<boolean>(true);
    const [AllowReading, setAllowReading] = useState<boolean>(false);
    const [ShowSubscriber, setShowSubscriber] = useState<boolean>(false);
    const [NonPublic, setNonPublic] = useState<boolean>(false);
    const [AddEscalation, setAddEscalation] = useState<boolean>(false);
    const [CustomizeNotification, setCustomizeNotification] = useState<boolean>(false);
    const [MoreOption, setMoreOption] = useState<boolean>(false);
    const [TextEditorShow, setTextEditorShow] = useState<boolean>(false);
    const [HideFont, setHideFont] = useState<boolean>(false);
    const [TagifyShow, setTagifyShow] = useState<boolean>(false);
    const [ResultShow, setResultShow] = useState<boolean>(false);
    // State for Hide Show Begin


    const HandleAllowReding = () => {
        setAllowReading(!AllowReading);
    }
    const HandleFontClick = () => {
        setTextEditorShow(true);
        setHideFont(true);
    }
    return (
        <>

            <Modal id="task-modal" size="lg" show={props.showRepair} onHide={props.hideRepair} backdrop="static" keyboard={false} aria-labelledby="example-modal-sizes-title-lg" >
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Reparatur erstellen
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body className='pb-0'>
                    <Row>
                        <Col md={9}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Reparaturtyp" />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Button className='default-btn f-12px w-100' onClick={() => setTemplateModalShow(true)}><FaPlusCircle className='default-btn-icon' />Alle Typen anzeigen</Button>
                        </Col>
                        <Col md={9}>
                            <Form.Group className="mb-3" controlId="">
                                <Form.Control size="sm" type="text" placeholder="Ort" />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Button className='default-btn f-12px w-100' onClick={() => setTemplateModalShow(true)}><FaPlusCircle className='default-btn-icon' />Alle Orte anzeigen</Button>
                        </Col>
                    </Row>
                    {AddUser ?
                        <Row>
                            <Col md={ShowSubscriber === false ? 7 : 9}>
                                <Form.Control size="sm" type="text" placeholder="Empfänger" />
                            </Col>
                            <Col md={ShowSubscriber === false ? 5 : 3} className='ps-0'>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {ShowSubscriber === false ? <Button className='default-btn f-12px me-1 w-lg-150px' onClick={() => setShowSubscriber(true)}>Abonnenten wählen</Button> : null}
                                    <Button className='default-btn f-12px w-lg-180px' onClick={() => setRecipientModal(true)}><FaPlusCircle className='default-btn-icon' /> Alle Empfänger anzeigen</Button>
                                </div>
                            </Col>
                        </Row>
                        : null
                    }
                    {ShowSubscriber ?
                        <Row className='mt-3'>
                            <Col md={9}>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Control size="sm" type="text" placeholder="Abonnenten" />
                                </Form.Group>
                            </Col>
                            <Col md={3} className='text-end ps-0'>
                                <Button className='default-btn f-12px' onClick={() => setRecipientModal(true)}><FaPlusCircle className='default-btn-icon' /> Alle Abonnenten anzeigen</Button>
                            </Col>
                        </Row>
                        : null
                    }
                    <Row className='my-3'>
                        <Col md={6}>
                            <Form.Label>Priorität</Form.Label>
                            <Form.Group controlId="" className='mb-2'>
                                <Form.Check
                                    inline
                                    label="Maximal"
                                    name="priority"
                                    type="radio"
                                    id=""
                                />
                                <Form.Check
                                    inline
                                    label="Hoch"
                                    name="priority"
                                    type="radio"
                                    id=""
                                />
                                <Form.Check
                                    inline
                                    label="Normal"
                                    name="priority"
                                    type="radio"
                                    id=""
                                />
                                <Form.Check
                                    inline
                                    label="Niedrig"
                                    name="priority"
                                    type="radio"
                                    id=""
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {AllowReading ?
                        <div>
                            <div className='border-top border-bottom py-2'>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group controlId="">
                                            <Form.Check
                                                inline
                                                label="Öffentlich"
                                                name="group1"
                                                type="radio"
                                                id="public"
                                                onChange={() => { setNonPublic(false) }}
                                            />
                                            <Form.Check
                                                inline
                                                label="Nicht öffentlich"
                                                name="group1"
                                                type="radio"
                                                id="notpublic"
                                                onChange={() => { setNonPublic(true) }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={8} className="px-0">
                                        {NonPublic ?
                                            <div >
                                                <NonPublicNews />
                                            </div>
                                            : null
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                {TextEditorShow ?
                                    <div className="mb-3">
                                        <EditorConvertToMarkdown />
                                    </div>
                                    :
                                    <div className="mb-3">
                                        <Form.Control as="textarea" placeholder="Text" rows={5} />
                                    </div>
                                }
                            </div>
                            <div className='border-bottom'>
                                <ModelActions
                                    ImgClick={() => setAddMediaModalShow(true)}
                                    FontClick={() => HandleFontClick()}
                                    isHideFont={HideFont}
                                    TagifyClick={() => setTagifyShow(!TagifyShow)}
                                />
                                {TagifyShow ?
                                    <div className='my-3 tagify'>
                                        <Tagify />
                                    </div>
                                    : null
                                }
                            </div>
                            <div className='my-2'>

                                {AddEscalation ? null : <p className='m-0 py-1'><a className='more-task-link' onClick={() => setAddEscalation(true)} href='javascript:;'>Eskalation hinzufügen</a></p>}
                                {AddEscalation ?
                                    <div className='mt-2'>
                                        <p className='m-0'><b>Eskalation</b></p>
                                        <Row className="border-bottom mt-2">
                                            <Col md={6}>
                                                <Form.Check
                                                    label="Benachrichtige, wenn Reparaturauftrag nicht erledigt ist"
                                                    name=""
                                                    type="checkbox"
                                                    id=""
                                                    checked
                                                    onChange={() => setAddEscalation(!AddEscalation)}
                                                />
                                                <div className='mb-3'>
                                                    <div className='d-flex align-items-center'>
                                                        <Form.Select size="sm" className='me-1 w-50'>
                                                            <option value="number:5">5 Minuten</option>
                                                            <option value="number:15">15 Minuten</option>
                                                            <option value="number:30">30 Minuten</option>
                                                            <option value="number:60" selected>1 Stunde</option>
                                                            <option value="number:120">2 Stunden</option>
                                                            <option value="number:180">3 Stunden</option>
                                                            <option value="number:360">6 Stunden</option>
                                                            <option value="number:480">8 Stunden</option>
                                                            <option value="number:600">10 Stunden</option>
                                                            <option value="number:1440">1 Tag</option>
                                                            <option value="number:2880">2 Tage</option>
                                                            <option value="number:4320">3 Tage</option>
                                                            <option value="number:7200">5 Tage</option>
                                                            <option value="number:10080">7 Tage</option>
                                                            <option value="number:14400">10 Tage</option>
                                                            <option value="number:20160">14 Tage</option>
                                                            <option value="number:43200">30 Tage</option>
                                                            <option value="number:64800">45 Tage</option>
                                                            <option value="number:86400">60 Tage</option>
                                                            <option value="number:129600">90 Tage</option>
                                                            <option value="number:172800">120 Tage</option>
                                                        </Form.Select>
                                                        <span>nach Erstellung.</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6} className='align-self-end'>
                                                <div className='mb-3'>
                                                    <Form.Check
                                                        inline
                                                        label="An Ersteller"
                                                        name=""
                                                        type="checkbox"
                                                        id=""

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="An Empfänger"
                                                        name=""
                                                        type="checkbox"
                                                        id=""

                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    : null
                                }

                                {CustomizeNotification ? null : <p className='m-0 py-1'><a className='more-task-link' onClick={() => setCustomizeNotification(true)} href='javascript:;'>Benachrichtigungen anpassen</a></p>}
                                {CustomizeNotification ?
                                    <div className='mt-2'>
                                        <p className='m-0'><b>Benachrichtigungen</b></p>
                                        <Row className='border-bottom mt-2'>
                                            <Col md={4}>
                                                <div className='mb-3'>
                                                    <Form.Label>Über Erstellung</Form.Label>
                                                    <Form.Group>
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Ersteller"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Empfänger"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className='mb-3'>
                                                    <Form.Label>Über Kommentare</Form.Label>
                                                    <Form.Group>
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Ersteller"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Empfänger"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className='mb-3'>
                                                    <Form.Label>Über Statusveränderungen</Form.Label>
                                                    <Form.Group>
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Ersteller"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                        <Form.Check
                                                            inline
                                                            className='mb-0'
                                                            label="Empfänger"
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    : null}


                                {MoreOption ? null : <p className='m-0 py-1'><a className='more-task-link' onClick={() => setMoreOption(true)} href='javascript:;'>Weitere Optionen</a></p>}
                                {MoreOption ?
                                    <div className='mt-2'>
                                        <p className='m-0'><b>Weitere Optionen</b></p>
                                        <Row className='mt-2'>
                                            <Col md={6}>
                                                <div>
                                                    <Form.Check
                                                        label="Der Reparaturauftrag muss von mir bestätigt werden."
                                                        name=""
                                                        type="checkbox"
                                                        id=""
                                                    />
                                                </div>
                                                <div>
                                                    <Form.Check
                                                        label="Empfänger müssen den Reparaturauftrag aktiv annehmen."
                                                        name=""
                                                        type="checkbox"
                                                        id=""
                                                    />
                                                </div>
                                                <div>
                                                    <Form.Check
                                                        label="Erlaube Empfängern den Eintrag zu bearbeiten."
                                                        name=""
                                                        type="checkbox"
                                                        id=""
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div>
                                                    <Form.Check
                                                        label="Ein Ergebnis muss übermittelt werden."
                                                        name=""
                                                        type="checkbox"
                                                        id=""
                                                        onChange={() => setResultShow(!ResultShow)}
                                                    />
                                                </div>
                                                {ResultShow ?
                                                    <div>
                                                        <Form.Check
                                                            label="Nur der Ersteller darf fremde Ergebnisse sehen."
                                                            name=""
                                                            type="checkbox"
                                                            id=""
                                                        />
                                                    </div>
                                                    : null
                                                }

                                            </Col>
                                        </Row>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                        : null
                    }
                </Modal.Body>
                <Modal.Footer className='justify-content-between footer-sticky'>
                    <div>
                        <Button className='default-btn f-14px' onClick={HandleAllowReding}>
                            {AllowReading ? <FaMinusCircle className='default-btn-icon' /> : <FaPlusCircle className='default-btn-icon' />}
                            <span>{AllowReading ? "Optionen ausblenden" : "Zeige weitere Optionen"}</span>
                        </Button>
                    </div>
                    <div>
                        <Button className='default-btn f-14px me-1' onClick={() => setAlertModal(true)}>
                            Abbrechen
                        </Button>
                        <Button className='primary-bg f-14px'>
                            Erstellen
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <TemplateModal displayTemplateModal={TemplateModalShow} hideTemplateModal={() => setTemplateModalShow(false)} />
            <Recipient displayRecipientModal={RecipientModal} hideRecipientModal={() => setRecipientModal(false)} />
            <AbortAlrt displayAlertModal={AlertModal} hideAlertModal={() => setAlertModal(false)} />
            <AddMediaModal displayAddMediaModal={AddMediaModalShow} hideAddMediaModal={() => setAddMediaModalShow(false)} />
        </>
    );
}




export default AddEditRepair;