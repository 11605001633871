import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { FaInfoCircle } from "react-icons/fa";


const Tips = () => {

    const Tips = [
        {
            title: 'Schnell zurück zur Startseite',
            description: 'Es gibt zwei Möglichkeiten wie man schnell von jeder beliebigen Seite wieder auf die Startseite gelangt....',
        },
        {
            title: 'Benachrichtigungen einsehen',
            description: 'Oft passiert es, dass man Benachrichtigungen zu schnell wegklickt und im Nachhinein Infos nochmal ansehen möchte...',
        },
        {
            title: 'Einträge nach Gruppen filtern',
            description: 'Ihr könnt jegliche Einträge auch gruppen-spezifisch anzeigen lassen...',
        },
        {
            title: 'Nachricht-Gültigkeit begrenzen',
            description: 'Um eine effiziente Kommunikation zu gewährleisten, könnt ihr die Gültigkeit von Nachrichten begrenzen.',
        },
        {
            title: 'E-Mail Benachrichtigung',
            description: 'Man kann sich E-Mail-Sofortbenachrichtigungen für medikit Aktivitäten einstellen',
        },
        {
            title: 'Passwort anfordern',
            description: 'Neues Passwort anforden wird durch Hinterlegen der E-Mail Adresse vereinfacht.',
        },
        {
            title: 'Bilder direkt im Eintrag einfügen',
            description: 'Bilder können auch direkt in einem Eintrag eingefügt werden',
        },
        {
            title: 'Tabellen sortieren',
            description: 'Tabellen können nach allen Spalten  sortiert werden',
        },
        {
            title: 'Push-Benachrichtigungen',
            description: 'Push-Benachrichtigungen für Smartphones',
        },
        {
            title: 'Aufgaben ohne Fälligkeit',
            description: 'Aufgaben können auch ohne Fälligkeit eingegeben werden',
        },
        {
            title: 'Einträge merken',
            description: 'Eine eigene Merkliste aufbauen',
        },
        {
            title: 'Teilnehmer bei Terminen',
            description: 'Teilnehmer hinzufügen',
        },
        {
            title: 'Abonnent vs. Teilnehmer',
            description: 'Einstellungen im Kalender',
        },
        {
            title: 'Abonnent bei Aufgaben und Reparaturen',
            description: 'Jemanden als Abonnent in Einträge aufnehmen',
        },
        {
            title: 'Umfragen ergänzen',
            description: 'Schnell und einfach Meinungen einholen',
        },
        {
            title: 'Filter nach zuletzt erledigten Reparaturen / Gästewünsche',
            description: 'Zuletzt erledigte Reparaturen und Gästewünsche schnell anzeigen lassen',
        },
        {
            title: 'Persönliche Filter setzen',
            description: 'Die persönlichen Ansichten individuell abspeichern',
        },
        {
            title: 'Einträge nach Gruppen filtern ',
            description: 'Relevante Informationen schneller finden...',
        },
        {
            title: 'Nachrichten, News & Ideen als ungelesen markieren',
            description: 'Für eine bessere Übersicht Einträge als ungelesen markieren...',
        },
        {
            title: 'Inhaltsverzeichnis bei Artikel einfügen',
            description: 'Um ein besser strukturiertes Handbuch zu gewährleisten könnt ihr Überschriften auch als solche formatieren...',
        },
        {
            title: 'Nachricht-Gültigkeit begrenzen',
            description: 'Um eine effiziente Kommunikation zu gewährleisten, könnt ihr die Gültigkeit von Nachrichten begrenzen...',
        },
        {
            title: 'Weiterleiten von Einträgen',
            description: 'Empfänger zu einem Eintrag im Nachhinein hinzufügen...',
        },
        {
            title: 'Verlinkungen setzen',
            description: 'Intern als auch extern auf andere Einträge bzw. Internetseiten oder E-Mail Adressen verlinken...',
        },
        {
            title: 'Versionen vergleichen',
            description: 'Im Handbuch kann man verschiedene Versionen vergleichen...',
        },
        {
            title: 'Checklisten',
            description: 'Einzelne Checkpoints in den Aufgaben ergänzen...',
        },
        {
            title: 'Einträge archivieren',
            description: 'Einträge in das eigene Archiv verschieben...',
        },
        {
            title: 'Kalender ein- und ausblenden',
            description: 'Die Terminansicht individualisieren...',
        },
    ]

    return (
        <>
            <Card className='border-0 rounded-0'>
                <Card.Header className='ps-0 border-0'>
                    <Card.Title className='text-uppercase f-14px fw-700'>Tips</Card.Title>
                </Card.Header>
                <Card.Body className='p-0 boxShadow cursor-pointer'>
                    <Table responsive hover className='mb-0'>
                        <tbody>
                            {Tips.map((element, index) => {
                                return (
                                    <>
                                        <tr className='' key={index}>
                                            <td className='p-td'>
                                                <span className='ms-2'><FaInfoCircle size={18} /></span>
                                            </td>
                                            <td className='p-td'>
                                                <div className='msg-title'>
                                                    <p className='m-0 fw-600'>{element.title}</p>
                                                </div>
                                            </td>
                                            <td className='p-td'>
                                                <span className='f-13px'>{element.description}</span>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}
export default Tips;