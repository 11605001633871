import React, { useState, useEffect, useRef } from "react";
import {useNavigate} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { styled } from '@mui/material/styles';
import { FaTrashAlt } from "react-icons/fa";
import { BsRepeat } from "react-icons/bs";
// import { dynamicPostApi, dynamicGetApi } from './../../Common'



function Klemmbrett(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['appToken','reCallApis','reCallCalendarApis']);
  let navigate = useNavigate();

  const [clipBoardItems, setClipBoardItems] = useState([]);
  const [von_text, setVon_text] = useState("");
  const [bis_text, setBis_text] = useState("");

useEffect(() => {
  getAllClipBoardItems();
}, []);
useEffect(() => {
  if(cookies.reCallApis === 'true'){
    getAllClipBoardItems();
    setCookie('reCallApis',false);
  }
}, [cookies.reCallApis]);

const getAllClipBoardItems = async () => {
  // let url = "online-terminbuch/calendarClipboardItems";
  // let res = await dynamicGetApi(url,navigate,setCookie);
  // setClipBoardItems(res.data);
}

const deleteFromClipboard = async (e) =>{
//  let url = "online-terminbuch/deleteClipboardItems?appId="+e;
//  let res = await dynamicGetApi(url,navigate,setCookie);
//  setCookie('reCallApis',true);
}

const moveFromClipboard = async (e) =>{
//  let url = "online-terminbuch/moveClipboardItems?appId="+e;
//  let res = await dynamicGetApi(url,navigate,setCookie);
//  setCookie('reCallApis',true);
//  setCookie('reCallCalendarApis',true);
}

const deleteAllClipboardItems = async () =>{
 let clipboardItemsIds = '';
 clipBoardItems.map(x=> {clipboardItemsIds === '' ? clipboardItemsIds = x.id.toString():clipboardItemsIds = clipboardItemsIds+','+x.id.toString()});
 let url = "online-terminbuch/clearClipboardItems?appIds="+clipboardItemsIds;
//  let res = await dynamicGetApi(url,navigate,setCookie);
//  setClipBoardItems([]);
//  setCookie('reCallApis',true);
}

const getNumber = (number) => {
  if (number >= 10)
    return "" + number;
  else {
    return "0" + number;
  }
}

 const  klemmbrettAppointment = () => {
    return <div style={{fontSize:"smaller"}} className="ds-list-item" data-ds-rt="ListItem" data-ds-rid="1000281LI" id="wXlg3cbwRRCKfETsLGvIVg">
      {!!clipBoardItems && clipBoardItems.length > 0 ? 
      clipBoardItems.map(x =>
        <div data-ds-rt="verticalPanel" data-ds-rid="1000281" id="wDX-2O8FSBu9DpxAsXDG3A">

        <div><span style={{color: "hsl(0, 0%, var(--hsL80))"}} className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="1000281_patLab" id="wbDu-vlkScmV2v1T7L2QEg">{x.patientName}</span></div>

        <div><p style={{color: "hsl(0, 0%, var(--hsL80))"}} data-ds-rid="1000281_beschreib" id="wJDWMkjdQEKdYcV4trvFvQ" data-ds-rt="label" className="text-left ds-t-nw ds-t-oe">{x.description}</p></div>

        <div><span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="1000281_terminLab" id="wCfsJAfYQsKT41h_WcsO-Q">{x.date} {getNumber(parseInt(x.start / 60)) + ":" + getNumber(x.start % 60)} - {getNumber(parseInt(x.end / 60)) + ":" + getNumber(x.end % 60)}, {x.end-x.start} Minuten</span></div>

        <div><span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="1000281_orga" id="wpVn10JURpKYOv5AnqMuTw">{x.practitioner}</span></div>
        
        <div>
          <div className="ds-grid ds-grid-spacing-default" data-ds-rt="grid" data-ds-rid="1000281buttonGrid" id="wT6TCY23SS-ALevF3FUzcQ">

            <div className="row controls controls-row">

              <div className="ds-cell col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <button className="btn-default btn" data-ds-rt="button" data-ds-rid="1000281_DdUndo" id="wBB5wwnsTS-nTWMlfX4gDw" onClick={()=>moveFromClipboard(x.id)}>
                  <span className="ds-b-c">
                    <span data-ds-rid="1000281_UndoIcon" id="wSoXzcqLSkyDA7Uu-BWGNg" data-ds-rt="icon" className="ds-i"><BsRepeat className="fa-fw  fal fa-repeat" /></span>
                    <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wwfDaLuaS0eAagR8k20x-w"></span>
                  </span>
                </button>
              </div>

              <div className="ds-cell col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <button className="btn-default btn" data-ds-rt="button" data-ds-rid="1000281_DdDelete" id="wIeOZY5sQVGQl9tmxLLjXg" onClick={()=>deleteFromClipboard(x.id)}>
                  <span className="ds-b-c">
                    <span data-ds-rid="1000281_DeleteIcon" id="wxGjUtKxTIGonMC6ULALqA" data-ds-rt="icon" className="ds-i"><FaTrashAlt className="fa-fw  fal fa-trash-alt" /></span>
                    <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="w4Ht_FNLRWyCgYxNPYf9nw"></span>
                  </span>
                </button>
              </div>

            </div>

          </div>

        </div>

      </div>
        )
      : null}
     
    </div>;
  }


  return ( 
    <div className="ds-fi" data-ds-rt="flexItem" data-ds-rid="klemmbrettItem" id="weIkR3-GShuzakUlNqiw3Q">
      <div className={props.visible ? "ds-sizepanel-container" :  "ds-not-visible ds-sizepanel-container"} data-ds-rt="sizePanel" data-ds-rid="klemmbrettPanel" id="wjbY8kr7SaSO2aLDZc8X2Q" 
        //style=""
        style={{ }}
        >
        <div className="ds-sizepanel-wrapper ds-collapse-undefined" 
          //style="min-height: 0px; min-width: 0px; height: 910px;"
          style={{ 
            width: props.visible ? "250px" : "0px",
            minHeight: 0 + "px",
            minWidth: 0 + "px",
            height: 910 + "px",
          }}
          >
          <div data-ds-rt="verticalPanel" data-ds-rid="w" id="wEilTXbYTEWA6vMeDES2vw">
            <div 
              //style="position: relative; height: 100%;"
              style={{position: "relative", height: "100%",}}
              >
              <div className="ds-scrollpanel" data-ds-rt="scrollPanel" data-ds-rid="klemmbrettScrollPanel" id="wBBndN4SRgablXy0Eecanw">
                <div className="ds-shadow-right ds-fade ds-animation"></div>
                <div className="ds-shadow-left ds-fade ds-animation"></div>
                <div className="ds-shadow-top ds-fade ds-animation"></div>

                <div className="ds-scroll-area ds-scroll-y">

                  <div className="ds-scroll-content">

                    <div className="ds-s-ld" data-ds-rt="verticalPanel" data-ds-rid="klemmbrettVP" id="waFVMkkdSgy-ijydFX1bxw">

                      <div>
                        <div className="ds-list" data-ds-rt="List" data-ds-rid="klemmbrettList" id="wJYP1MzeQLGuqa5smHInQQ">

                        {klemmbrettAppointment()}
                        

                        </div>
                      </div>

                      <div>
                        <div className="ds-grid ds-grid-spacing-default" data-ds-rt="grid" data-ds-rid="klemmbrettLeerenGrid" id="w5cw0W4-RLuv3YSRTFs_hQ">
                          <div className="row controls controls-row">
                            <div className="ds-cell col-xs-12 col-sm-12 col-md-12 col-lg-12">

                              {clipBoardItems.length <= 0 && <p style={{color: "hsl(0, 0%, var(--hsL80))"}} data-ds-rid="leeresKlemmbrettLb" id="wUDzeX0AT4uDyPirxV_Mkw" data-ds-rt="label" className="text-center ds-t-nw ds-t-oe">Keine Einträge vorhanden</p>}

                              {!!clipBoardItems && clipBoardItems.length > 0 && <button className="btn-default btn" data-ds-rt="button" data-ds-rid="klemmbrettLeerenBt" id="wZA24TTfSLaEiafm4WBCMw" onClick={()=>deleteAllClipboardItems()}>
                                <span className="ds-b-cm ds-b-c">
                                  <span data-ds-rid="klemmbrettLeerenIcon" id="w9gcISpXRFuV1IGex5mpUg" data-ds-rt="icon" className="ds-i"><FaTrashAlt className="fa-fw  fal fa-trash-alt" /></span>
                                  <span className="ds-t-nw ds-t-oe" data-ds-rt="label" data-ds-rid="btnLabel" id="wHIfUVE6TZOuFX20kdXeVQ">Klemmbrett Leeren</span>
                                </span>
                              </button>}

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>

                <div className="ds-shadow-bottom ds-fade ds-animation"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   );
}

export default Klemmbrett;

// export default class Klemmbrett extends React.Component {
//   constructor(props) {
//     super(props);

 
//   }

//   componentDidMount() {
//     var self = this;


// 	  }

// 	componentWillUnmount() {
//     var self = this;

// 	}



//   render() {
//     return ;
//   }
// }

