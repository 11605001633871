import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Badge from 'react-bootstrap/Badge';
import { FaInfoCircle, FaTasks, FaTimes } from 'react-icons/fa';
const NonPublicNews = () => {
    return (
        <>
            <div className='d-flex flex-wrap align-items-center'>
                <FaInfoCircle className='me-1' />
                <span className='me-1'>Nicht öffentliche News können immer gelesen werden von </span>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Alicia Gojani, Diana Lauser, Dr. Sven Markus Gallenbach, Nadine Häring, Thomas Planitzer & Team</Tooltip>}>
                    <Badge className='bg-dark f-12px me-1'>Admin</Badge>
                </OverlayTrigger>
                <Badge className='badge-light-blue f-12px me-1'>QM</Badge>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Alicia Gojani, Diana Lauser, Dr. Sven Markus Gallenbach, Nadine Häring</Tooltip>}>
                    <Badge className='badge-dark-blue f-12px me-1'>medikit Champions</Badge>
                </OverlayTrigger>
            </div>
        </>
    )
}
export default NonPublicNews;