import * as React from 'react';
import { BodyText, Box, DisplayStart } from '../../StyledComponents';
import { Col, Row } from 'react-bootstrap';
import CustomTooltip from '../../Tooltip';
import BadgeWidget from '../../BadgeWidget';


export const PermissionModalContent = () => {
    return (
        <>
            <Row>
                <Col md={2} sm={12}>
                    <Box>
                        <BodyText fontSize='16px' fontWeight='600' textColor='#666'>
                            Lesen:
                        </BodyText>
                        <BodyText fontSize='13px' fontWeight='400' textColor='#666'>
                            Alle
                        </BodyText>
                    </Box>
                </Col>
                <Col md={5} sm={12}>
                    <Box>
                        <BodyText fontSize='16px' fontWeight='600' textColor='#666'>
                            Bearbeiten:
                        </BodyText>
                        <CustomTooltip
                            ToolTipTrigger={<BodyText fontSize='13px' fontWeight='400' textColor='#666'> Dr. Sven Markus Gallenbach</BodyText>}
                            toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                            toolTipPlacement='top'
                            contentAlign='center'
                        />
                    </Box>
                    <Box mt='20px'>
                        <BodyText fontSize='15px' fontWeight='400' textColor='#666'> Globale Berechtigungen: </BodyText>
                        <ul className='list-unstyled'>
                            <li className='my-1'>
                                <DisplayStart>
                                    <Box>
                                        <CustomTooltip
                                            ToolTipTrigger={<BadgeWidget solid={true} title="Admin" color='#000000' bgColor="#e6e6e6" />}
                                            toolTipContent={'Alicia Gojani, Diana Lauser, Dr.Sven Markus Gallenbach, Nadine Häring, Thomas Planitzer & Team'}
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </Box>
                                    <span> {"<br>"} </span>
                                    <Box>
                                        <CustomTooltip
                                            ToolTipTrigger={<BadgeWidget solid={true} title="Verwaltung" color='#1e6c7b' bgColor="#d4f2f7" />}
                                            toolTipContent={'Alicia Gojani, Nadine Häring'}
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </Box>
                                </DisplayStart>
                            </li>
                            <li className='my-1'>
                                <DisplayStart>
                                    <span> {"<br>"} </span>
                                    <Box>
                                        <CustomTooltip
                                            ToolTipTrigger={<BadgeWidget solid={true} title="medikit Champions" color='#1e6c7b' bgColor="#d4f2f7" />}
                                            toolTipContent={'Alicia Gojani, Diana Lauser, Dr.Sven Markus Gallenbach, Nadine Häring'}
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </Box>
                                </DisplayStart>
                            </li>
                            <li className='my-1'>
                                <DisplayStart>
                                    <span> {"<br>"} </span>
                                    <Box>
                                        <CustomTooltip
                                            ToolTipTrigger={<BadgeWidget solid={true} title="OPTI" color='#1e6c7b' bgColor="#d4f2f7" />}
                                            toolTipContent={'Emine Mollaoglu'}
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </Box>
                                </DisplayStart>
                            </li>
                        </ul>
                    </Box>
                </Col>
                <Col md={5} sm={12}>
                    <Box>
                        <BodyText fontSize='15px' fontWeight='600' textColor='#666'>
                            Löschen:
                        </BodyText>
                        <CustomTooltip
                            ToolTipTrigger={<BodyText fontSize='13px' fontWeight='400' textColor='#666'> Dr. Sven Markus Gallenbach</BodyText>}
                            toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                            toolTipPlacement='top'
                            contentAlign='center'
                        />
                    </Box>
                    <Box mt='20px'>
                        <BodyText fontSize='15px' fontWeight='400' textColor='#666'> Globale Berechtigungen: </BodyText>
                        <ul className='list-unstyled'>
                            <li className='my-1'>
                                <DisplayStart>
                                    <Box>
                                        <CustomTooltip
                                            ToolTipTrigger={<BadgeWidget solid={true} title="Admin" color='#000000' bgColor="#e6e6e6" />}
                                            toolTipContent={'Alicia Gojani, Diana Lauser, Dr.Sven Markus Gallenbach, Nadine Häring, Thomas Planitzer & Team'}
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </Box>
                                    <span> {"<br>"} </span>
                                    <Box>
                                        <CustomTooltip
                                            ToolTipTrigger={<BadgeWidget solid={true} title="Verwaltung" color='#1e6c7b' bgColor="#d4f2f7" />}
                                            toolTipContent={'Alicia Gojani, Nadine Häring'}
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </Box>
                                </DisplayStart>
                            </li>
                            <li className='my-1'>
                                <DisplayStart>
                                    <span> {"<br>"} </span>
                                    <Box>
                                        <CustomTooltip
                                            ToolTipTrigger={<BadgeWidget solid={true} title="medikit Champions" color='#1e6c7b' bgColor="#d4f2f7" />}
                                            toolTipContent={'Alicia Gojani, Diana Lauser, Dr.Sven Markus Gallenbach, Nadine Häring'}
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </Box>
                                </DisplayStart>
                            </li>
                            <li className='my-1'>
                                <DisplayStart>
                                    <span> {"<br>"} </span>
                                    <Box>
                                        <CustomTooltip
                                            ToolTipTrigger={<BadgeWidget solid={true} title="OPTI" color='#1e6c7b' bgColor="#d4f2f7" />}
                                            toolTipContent={'Emine Mollaoglu'}
                                            toolTipPlacement='top'
                                            contentAlign='center'
                                        />
                                    </Box>
                                </DisplayStart>
                            </li>
                        </ul>
                    </Box>
                </Col>
            </Row>
        </>
    )
}