import React from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { HiOutlineChatAlt } from "react-icons/hi";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { HiOutlineInformationCircle } from 'react-icons/hi';
import CustomTooltip from '../../Components/Tooltip';

function Notification() {
    return (
        <>
            <Card className='border-top-none'>
                <Card.Body className='border-top-none activity-accordion'>
                    <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <div className='d-flex justify-content-between align-items-center px-2 py-3'>
                                <div>
                                    <h6 className='m-0'>HEUTE</h6>
                                </div>
                                <div>
                                    <Accordion.Header>Heute ausblenden</Accordion.Header>
                                </div>
                            </div>
                            <Accordion.Body>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <img width='25' src='/images/shopping-bag.png' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <HiOutlineChatAlt size="25px" className='gray-color' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <div className='d-flex justify-content-between align-items-center px-2 py-3'>
                                <div>
                                    <h6 className='m-0'>GESTERN</h6>
                                </div>
                                <div>
                                    <Accordion.Header>Heute ausblenden</Accordion.Header>
                                </div>
                            </div>
                            <Accordion.Body>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <HiOutlineLightBulb size='25px' className='gray-color' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <HiOutlineInformationCircle size="25px" className='gray-color' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <div className='d-flex justify-content-between align-items-center px-2 py-3'>
                                <div>
                                    <h6 className='m-0'>13.11.2022</h6>
                                </div>
                                <div>
                                    <Accordion.Header>Heute ausblenden</Accordion.Header>
                                </div>
                            </div>
                            <Accordion.Body>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <FaRegCalendarAlt size="25px" className='gray-color' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <img width='25' src='/images/shopping-bag.png' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <div className='d-flex justify-content-between align-items-center px-2 py-3'>
                                <div>
                                    <h6 className='m-0'>14.11.2022</h6>
                                </div>
                                <div>
                                    <Accordion.Header>Heute ausblenden</Accordion.Header>
                                </div>
                            </div>
                            <Accordion.Body>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <img width='25' src='/images/working.png' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-content'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <FaRegClock size="25px" className='gray-color' />
                                        </div>
                                        <div className='me-3'>
                                            <img width='25' src='/images/account-b.png' />
                                        </div>
                                        <div className='me-2'>
                                            <span className='practice-label'>Praxis Gallenbach & Zähne </span>
                                        </div>
                                        <div className='me-3'>
                                            <p className='m-0'>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>ZA Robert Stühn</a>
                                                <span> has set the task </span>
                                                <a className='text-decoration-none text-dark' href='javascript:;'>feedback 04.11.22</a>
                                            </p>
                                        </div>
                                        <div className='me-3'>
                                            <CustomTooltip
                                                ToolTipTrigger={<><span className='text-secondary' id="tooltip-disabled">5 hours ago</span></>}
                                                toolTipContent={<span>15.11.2022 14:52</span>}
                                                toolTipPlacement='top'
                                                contentAlign='center'
                                            />

                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Card.Body>
            </Card>
        </>
    );
}

export default Notification;