import { LoginDto } from "../Model/ViewModels/Login/loginDto";
import { LoginResponseDto } from "../Model/ViewModels/Login/loginResponseDto";
import ResetPasswordDto from "../Model/ViewModels/ResetPassword/resetPasswordDto";
import BaseService from "./baseService";

export default class AuthService extends BaseService {
  url: string = "api/account/";

  public async login(data: LoginDto): Promise<LoginResponseDto> {
    return this.POST<LoginResponseDto>(this.url + "Login", data);
  }

  public logout(
    removeCookie: (token: "authToken" | "userImage") => void,
    navigate: (path: string) => any
  ) {
    removeCookie("authToken");
    removeCookie("userImage");
    navigate("/");
  }

  public async forgetPassword(email: string) {
    return this.GET<Boolean>(this.url + "ForgetPassword?email=" + email);
  }

  public async resetPassword(data: ResetPasswordDto): Promise<Boolean> {
    return this.POST<Boolean>(this.url + "ResetPassword", data);
  }

  public async getUser() {
    return this.GET<LoginResponseDto>(this.url + "GetUser");
  }
}
