import React, { useState, useEffect } from 'react';
import NewTreeView from '../../../TreeView';
import WidgetTreeView from '../../../WidgetTreeView';


const jsonData = [
  {
    id: 1,
    label: 'UG',
    isActive: false,
    children: [
      {
        id: 2,
        parentId: 1,
        label: 'Heizungsraum',
        isActive: false,
      }
    ]
  },
  {
    id: 3,
    label: 'EG',
    isActive: false,
    children: [
      {
        id: 4,
        parentId: 3,
        label: 'Toilette',
        isActive: false,
      },
      {
        id: 5,
        parentId: 3,
        label: 'Windfang',
        isActive: false,
      },
      {
        id: 6,
        parentId: 3,
        label: 'Rezeption',
        isActive: false,
      },
      {
        id: 7,
        parentId: 3,
        label: 'Wartezimmer',
        isActive: false,
      },
      {
        id: 8,
        parentId: 3,
        label: 'Büros',
        isActive: false,
        children: [
          {
            id: 9,
            parentId: 8,
            label: 'Büro 1',
            isActive: false,
          },
          {
            id: 10,
            parentId: 8,
            label: 'Back Office',
            isActive: false,
          },
        ]
      },
      {
        id: 11,
        parentId: 3,
        label: 'Behandlungszimmer',
        isActive: false,
        children: [
          {
            id: 12,
            parentId: 11,
            label: 'Zimmer 1',
            isActive: false,
          },
          {
            id: 13,
            parentId: 11,
            label: 'Zimmer 2',
            isActive: false,
          },
          {
            id: 14,
            parentId: 11,
            label: 'Zimmer 3',
            isActive: false,
          },
          {
            id: 15,
            parentId: 11,
            label: 'Prophylaxe',
            isActive: false,
          },
        ]
      },
      {
        id: 16,
        parentId: 3,
        label: 'Gang',
        isActive: false,
        children: [
          {
            id: 17,
            parentId: 16,
            label: 'Steriraum',
            isActive: false,
          },
          {
            id: 18,
            parentId: 16,
            label: 'Röntgenraum',
            isActive: false,
          },
        ]
      },
      {
        id: 19,
        parentId: 3,
        label: 'Personalraum mit Küche',
        isActive: false,
        children: [
          {
            id: 20,
            parentId: 19,
            label: 'Labor',
            isActive: false,
          },
        ]
      },
    ]
  },
  {
    id: 21,
    label: 'Außenbereich',
    isActive: false,
    children: [
      {
        id: 22,
        parentId: 21,
        label: 'Müllplatz',
        isActive: false,
      },
      {
        id: 23,
        parentId: 21,
        label: 'Parkplatz',
        isActive: false,
      }
    ]
  }
];

function ShowAllPlaces() {
  const [activeLabel, setActiveLabel] = useState<string>('');
  return (
    <>
      <WidgetTreeView treeData={jsonData} getActiveLabel={setActiveLabel} />
    </>
  );
}

export default ShowAllPlaces;