
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { BodyText, Box, Comment, DisplayBetween, DisplayCenter, DisplayEnd, DisplayStart, Divider, InnerReplyItem, ReplyBox, ReplyItem, StatusBadge, StatusBadgeTextColor, CommentBox, CommentItem, CommentWrapper, TaskDetails, TaskInfo, TaskRecipientInfo, TaskWrapper } from '../../../Components/StyledComponents';
import { GroupActionButtonWidget } from '../../../Components/Widgets/GroupActionButtonWidget';
import CustomTooltip from '../../../Components/Tooltip';
import { FaAngleDown, FaAngleUp, FaArrowLeft, FaArrowRight, FaLink, FaLongArrowAltRight, FaMinusCircle, FaPlusCircle, FaQuestionCircle, FaRegHandPointRight } from 'react-icons/fa';
import BadgeWidget from '../../../Components/BadgeWidget';
import EditorConvertToMarkdown from '../../../Components/TextEditor';
import ModelActions from '../../../Components/ModelActions';
import Tagify from '../../../Components/Tagify';
import AddMediaModal from '../../../Components/Modals/AddMediaModal';
import ButtonWidget from '../../../Components/ButtonWidget';
import { SendReminderModalContent } from '../../../Components/Modals/ModelContents/SendReminderModalContent';
import CommonModal from '../../../Components/Modals/CommonModal';
import { PdfSettingsModalContent } from '../../../Components/Modals/ModelContents/PdfSettingsModalContent';
import { DeleteMessageDetailModalContent } from '../../../Components/Modals/ModelContents/DeleteMessageDetailModalContent';
import { ReportContentModalContent } from '../../../Components/Modals/ModelContents/ReportContentModalContent';
import { UnsavedCommentModalContent } from '../../../Components/Modals/ModelContents/UnsavedCommentModalContent';
import { PermissionModalContent } from '../../../Components/Modals/ModelContents/PermissionModalContent';
import { AddPollModalContent } from '../../../Components/Modals/ModelContents/AddPollModalContent';
import { BookMarkOptionModalContent } from '../../../Components/Modals/GroupActionButtonModalContent.tsx/BookMarkOptionModalContent';
import { BookMarkFooterContent } from '../../Repair/MainContent';
import { RememberOptionModalContent } from '../../../Components/Modals/GroupActionButtonModalContent.tsx/RememberOptionModalContent';
import { ShowHomeScreenModalContent } from '../../../Components/Modals/ModelContents/ShowHomeScreenModalContent';
import { Link, useOutletContext } from 'react-router-dom';
import { ConfirmDeleteCommentModalContent } from '../../../Components/Modals/ModelContents/ConfirmDeleteCommentModalContent';
import { AttachmentOptionModalContent } from '../../../Components/Modals/ModelContents/AttachmentOptionModalContent';
import { PleasesModalContent } from '../../../Components/Modals/ModelContents/PleasesModalContent';
import { TiArrowBack } from "react-icons/ti";
import FormControl from '../../../Components/FormControl';
import Index from '../../Message/Index';
import { useCookies } from 'react-cookie';
import moment, { Moment } from 'moment';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import AddEditTask from '../AddEditTask';

type replyType = {
    userName: string,
    profileImg?: string,
    userTooltipText: string,
    commentTime: any,
    commentTimeTooltipText: any,
    commentText: string,
    innerReplyText?: string,
    isDeleted: boolean
}
type commentType = {
    userName: string,
    profileImg?: string,
    userTooltipText: string,
    commentTime: any,
    commentTimeTooltipText: any,
    commentText: string,
    isDeleted: boolean,
    commentReply?: replyType[],
}

const sampleComment: commentType[] = [
    {
        userName: 'Dr. Sven Markus Gallenbach ',
        userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
        commentTime: 'vor 14 Minuten',
        commentTimeTooltipText: '21.06.2023 09:27',
        commentText: 'Testing Comment',
        isDeleted: false,
        commentReply: [],
    },
    {
        userName: 'Dr. Sven Markus Gallenbach ',
        userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
        commentTime: 'vor 22 Minuten',
        commentTimeTooltipText: '21.06.2023 09:27',
        commentText: 'Testing2 Comment',
        isDeleted: false,
        commentReply: [
            {
                userName: 'Dr. Sven Markus Gallenbach ',
                userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
                commentTime: 'vor 14 Minuten',
                commentTimeTooltipText: '21.06.2023 09:27',
                commentText: 'Testing Reply',
                innerReplyText: 'Testing InnerReply',
                isDeleted: false
            },
            {
                userName: 'Dr. Markus ',
                userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
                commentTime: 'vor 13 Minuten',
                commentTimeTooltipText: '21.06.2023 09:27',
                commentText: 'Testing Reply',
                innerReplyText: 'Testing InnerReply',
                isDeleted: false
            }
        ]
    },
    {
        userName: 'Dr. Sven Markus Gallenbach',
        userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
        commentTime: 'vor 22 Minuten',
        commentTimeTooltipText: '21.06.2023 09:27',
        commentText: 'Testing Comment',
        isDeleted: false,
        commentReply: [],
    },

];


type assignTasksType = {
    userName: string,
    profileImg?: string,
    userTooltipText: string,
    taskAssignTime: any,
    taskStatus: {
        open?: boolean,
        done?: boolean,
        inProgress?: boolean,
        discard?: boolean,
        remind?: boolean,
        reOpen?: boolean,
    },
}

const assignTasksListData: assignTasksType[] = [
    {
        userName: 'Dr. Sven Markus Gallenbach',
        userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
        taskAssignTime: '18.07.2023 10:20 Pakistan Standard Time',
        taskStatus: {
            open: true,
        }
    },
    {
        userName: 'Nadine Häring',
        userTooltipText: 'ZFA - Praxis Gallenbach & Zähne',
        taskAssignTime: '18.07.2023 10:18 Pakistan Standard Time',
        taskStatus: {
            open: true,
        }
    },
    {
        userName: '	Alicia Gojani',
        userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
        taskAssignTime: ' 18.07.2023 10:18 Pakistan Standard Time',
        taskStatus: {
            open: true,
        }
    },
    {
        userName: '	ZA Robert Stühn',
        userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne ',
        taskAssignTime: '18.07.2023 10:20 Pakistan Standard Time',
        taskStatus: {
            open: true,
        }
    }
]


type commentDDType = {
    index?: number,
    innerIndex?: number
}

type showReplyType = {
    index: number | undefined,
    innerIndex: number | undefined,
    isShow: boolean
}

type taskStatusForAllType = {
    doneForAll: boolean,
    discardForAll: boolean,
    remindAll: boolean,
}

type taskStatusSingleType = {
    done: boolean,
    inProgress: boolean,
    discard: boolean,
    remind: boolean,
    reOpen: boolean,
}





interface IProps {
    handleDisableNotification?: () => void,
    handleCommentTextBoxToggle?: () => void,
}

export const TaskDetailIndex = (props: IProps) => {

    const { handleDisableNotification, handleCommentTextBoxToggle } = props;

    const [taskData, setTaskData]: any = useOutletContext();
    const { id } = useParams();

    const [taskIndex, setTaskIndex] = useState<number>(0);




    const [TextEditorShow, setTextEditorShow] = useState<boolean>(false);
    const [HideFont, setHideFont] = useState<boolean>(false);
    const [TagifyShow, setTagifyShow] = useState<boolean>(false);
    const [AddMediaModalShow, setAddMediaModalShow] = useState<boolean>(false);
    const [AllowReading, setAllowReading] = useState<boolean>(false);
    const [showBookMark, setShowBookMark] = useState<boolean>(false);
    const [showRememberOption, setShowRememberOption] = useState<boolean>(false);
    const [showHomeScreen, setShowHomeScreen] = useState<boolean>(false);
    const [deleteComment, setDeleteComment] = useState<boolean>(false);
    const [attachOption, setAttachOption] = useState<boolean>(false);

    const [sendReminder, setSendReminder] = useState<boolean>(false);
    const [printPdfSetting, setPrintPdfSetting] = useState<boolean>(false);
    const [deleteMessage, setDeleteMessage] = useState<boolean>(false);
    const [reportContent, setReportContent] = useState<boolean>(false);
    const [unsavedComment, setUnsavedComment] = useState<boolean>(false);
    const [permission, setPermission] = useState<boolean>(false);
    const [notificationTextToggle, setNotificationTextToggle] = useState<boolean>(false);
    const [commentTextBoxToggle, setCommentTextBoxToggle] = useState<boolean>(false);
    const [addPoll, setAddPoll] = useState<boolean>(false);
    const [TaskModal, setTaskModal] = useState<boolean>(false);

    const [commentDropdown, setCommentDropdown] = useState<boolean>(false);
    const [showLike, setShowLike] = useState<boolean>(false);
    const [attachLink, setAttachLink] = useState<boolean>(false);
    const [pleases, setPleases] = useState<boolean>(false);

    const [commentData, setCommentData] = useState<commentType[]>(sampleComment);
    const [commentValue, setCommentValue] = useState<string>('');
    const [commentIndexShow, setCommentIndexShow] = useState<commentDDType>({});
    const [showReply, setShowReply] = useState<showReplyType>({ index: undefined, innerIndex: undefined, isShow: false });
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [showMoreRecipient, setShowMoreRecipient] = useState<boolean>(false);
    const [doneForAllTask, setDoneForAllTask] = useState<boolean>(false);
    const [taskStatusForAll, setTaskStatusForAll] = useState<taskStatusForAllType>({ doneForAll: false, discardForAll: false, remindAll: false });
    const [taskStatusSingle, setTaskStatusSingle] = useState<taskStatusSingleType>({ done: false, inProgress: false, discard: false, remind: false, reOpen: false });
    const [assignTasksList, setAssignTasksList] = useState<assignTasksType[]>(assignTasksListData);
    const [randomNum, setRandomNum] = useState<number | undefined>(undefined);
    const [taskStatusDropdown, setTaskStatusDropdown] = useState<number | null>(null);

    const handleAttachLink = () => {
        setAttachLink(!attachLink);
    }

    const handleMouseEnter = (index: number, innerIndex?: number) => {
        var temp = { ...commentIndexShow };
        if (index !== undefined) {
            temp.index = index;
            temp.innerIndex = undefined;
        }
        if (innerIndex !== undefined) {
            temp.index = undefined;
            temp.innerIndex = innerIndex;
        }
        setCommentIndexShow(temp);
    };

    const handleMouseLeave = () => {
        var temp = { ...commentIndexShow };
        temp.index = undefined;
        temp.innerIndex = undefined;
        setCommentIndexShow(temp);
    };

    const HandleFontClick = () => {
        setTextEditorShow(true);
        setHideFont(true);
    }

    const HandleAllowReding = () => {
        setAllowReading(!AllowReading);
    }
    const [cookies, setCookie, removeCookie] = useCookies([
        "authToken",
        "userName",
    ]);



    // ******** Add Comment Begins ******** //
    const handleCommentSubmit = () => {
        if (!!commentValue) {
            var temp = [...commentData];
            const currentDate = new Date();

            var newObj = {
                userName: cookies.userName,
                userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
                commentTime: currentDate.toISOString(),
                commentTimeTooltipText: currentDate.toDateString(),
                commentText: commentValue,
                isDeleted: false,
                commentReply: [],
            }
            temp.push(newObj);
            setCommentValue('');
            setCommentData(temp);
        }

    }
    const handleReplySubmit = (index: number) => {
        debugger
        if (!!commentValue && isEdited === false) {
            var temp = [...commentData];
            const currentDate = new Date();
            var newObj: replyType = {
                userName: 'Dr. Sven Markus Gallenbach',
                userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
                commentTime: currentDate.toISOString(),
                commentTimeTooltipText: currentDate.toDateString(),
                commentText: commentValue,
                isDeleted: false
            }
            temp[index].commentReply?.push(newObj);
            setCommentData(temp);
            setCommentValue('');
            setShowReply({ index: undefined, innerIndex: undefined, isShow: false });
        }
        if (!!commentValue && isEdited === true) {
            var temp = [...commentData];
            temp[index].commentText = commentValue;
            setCommentData(temp);
            setCommentValue('');
            setShowReply({ index: undefined, innerIndex: undefined, isShow: false });
            setIsEdited(false);
        }
    }
    const handleInnerReplySubmit = (index: number, innerIndex: number) => {
        debugger
        if (!!commentValue && isEdited === false) {
            var temp = [...commentData];
            const currentDate = new Date();
            var tagUser = "@" + temp[index].commentReply?.[innerIndex].userName;

            var newObj = {
                userName: temp[index].userName,
                userTooltipText: 'Zahnarzt - Praxis Gallenbach & Zähne',
                commentTime: currentDate.toISOString(),
                commentTimeTooltipText: currentDate.toDateString(),
                commentText: '',
                innerReplyText: tagUser + ' ' + commentValue,
                isDeleted: false
            }
            temp[index].commentReply?.push(newObj);
            setCommentData(temp);
            setCommentValue('');
            setShowReply({ index: undefined, innerIndex: undefined, isShow: false });
        }
        if (!!commentValue && isEdited === true) {
            var temp = [...commentData];
            // @ts-expect-error
            temp[index].commentReply[innerIndex].innerReplyText = commentValue;
            setCommentData(temp);
            setCommentValue('');
            setShowReply({ index: undefined, innerIndex: undefined, isShow: false });
            setIsEdited(false);
        }
    }
    // ******** Add Comment Ends ******** //

    // ******** Edit Comments Begin ******** //
    const editMainComment = (index: number) => {
        debugger
        setIsEdited(true);
        handleReply(index);
        var temp = [...commentData];
        setCommentValue(temp[index].commentText);
    }
    const editInnerComment = (index: number, innerIndex: number) => {
        setIsEdited(true);
        handleReply(index, innerIndex);
        var temp = [...commentData];
        const innerReplyText = temp[index].commentReply?.[innerIndex].innerReplyText;
        if (innerReplyText !== undefined) {
            setCommentValue(innerReplyText);
        }
    }
    // ******** Edit Comments Ends ******** //

    // ******** Delete Comments Begin ******** //
    const deleteMainComment = (index: number) => {
        var temp = [...commentData];
        temp.splice(index, 1);
        setCommentData(temp);

    }
    const deleteInnerComment = (index: number, innerIndex: number) => {
        var temp = [...commentData];
        temp[index].commentReply?.splice(innerIndex, 1);
        setCommentData(temp);
    }
    // ******** Delete Comments Ends ******** //

    const handleReply = (index: number, innerIndex?: number) => {
        debugger
        var temp = { ...showReply };
        if (index !== undefined && innerIndex === undefined) {
            temp.isShow = true;
            temp.index = index;
            temp.innerIndex = undefined;
        }
        if (innerIndex !== undefined && index !== undefined) {
            temp.isShow = true;
            temp.index = index;
            temp.innerIndex = innerIndex;
        }
        setShowReply(temp);
    }
    const handleCancelReply = (index: number) => {
        setShowReply({ index: undefined, innerIndex: undefined, isShow: false });
        setCommentValue('');
        setIsEdited(false);
    }

    const calculatePostTime = (postDate: Date) => {
        // var currentDate = new Date(); // Current date and time

        // Convert the postDate string to a Date object
        // var postDateTime = new Date(postDate);

        // Calculate the time difference in milliseconds

        console.log(moment(postDate).startOf('hour').fromNow())

        // Calculate the time difference in seconds, minutes, hours, and days
        // var seconds = Math.floor(timeDiff / 1000);
        // var minutes = Math.floor(seconds / 60);
        // var hours = Math.floor(minutes / 60);
        // var days = Math.floor(hours / 24);

        // if (days > 0) {
        //     return days + " day(s) ago";
        // } else if (hours > 0) {
        //     return hours + " hour(s) ago";
        // } else if (minutes > 0) {
        //     return minutes + " minute(s) ago";
        // } else {
        //     return seconds + " second(s) ago";
        // }
    }
    useEffect(() => {

        if (id !== undefined) {
            setTaskIndex(parseInt(id));
            console.log(taskIndex);
        }


        const interval = setInterval(() => {

            // Update the state
            const currentDate = new Date();
            // const formattedDate = currentDate.toISOString();
            calculatePostTime(currentDate);
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, []);




    // ******** Assign Task Actions Begins ******** //
    const handleDoneAllTaske = () => {
        debugger
        setTaskStatusForAll({ doneForAll: true, discardForAll: false, remindAll: false });
        setTaskStatusSingle({ done: true, inProgress: false, discard: false, remind: false, reOpen: false });

        var tempData = [...taskData];
        var newtemp = tempData[taskIndex];
        for (var i = 0; i < newtemp.recipientList.length; i++) {
            newtemp.recipientList[i].taskStatus.open = false;
            newtemp.recipientList[i].taskStatus.done = true;
            newtemp.recipientList[i].taskStatus.inProgress = false;
            newtemp.recipientList[i].taskStatus.discard = false;
            newtemp.recipientList[i].taskStatus.remind = false;
        }
        setTaskData(tempData);

        var temp = [...assignTasksList];
        for (var i = 0; i < temp.length; i++) {
            temp[i].taskStatus.done = true;
        }
        setAssignTasksList(temp);

    }
    const handleReOpenForAll = () => {
        setTaskStatusForAll({ doneForAll: false, discardForAll: false, remindAll: false });
        setTaskStatusSingle({ done: false, inProgress: false, discard: false, remind: false, reOpen: false });

        var tempData = [...taskData];
        var newtemp = tempData[taskIndex];
        for (var i = 0; i < newtemp.recipientList.length; i++) {
            newtemp.recipientList[i].taskStatus.open = true;
            newtemp.recipientList[i].taskStatus.done = false;
            newtemp.recipientList[i].taskStatus.inProgress = false;
            newtemp.recipientList[i].taskStatus.discard = false;
            newtemp.recipientList[i].taskStatus.remind = false;
        }
        setTaskData(tempData);
    }

    const handleDiscardAllTasks = () => {
        Swal.fire({
            title: 'Sie sind dabei, die Aufgabe für alle Empfänger zu verwerfen. Möchten Sie fortfahren?',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            confirmButtonText: 'Für alle verwerfen',
            cancelButtonText: `Abbrechen`,
            customClass: {
                container: 'discardTask-alert',
                confirmButton: 'f-14px btn-danger fw-400',
                cancelButton: 'f-14px default-btn fw-400',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Verworfen!', '', 'success');
                setTaskStatusForAll({ doneForAll: false, discardForAll: true, remindAll: false });
                setTaskStatusSingle({ done: false, inProgress: false, discard: false, remind: false, reOpen: false });

                var tempData = [...taskData];
                var newtemp = tempData[taskIndex];
                for (var i = 0; i < newtemp.recipientList.length; i++) {
                    newtemp.recipientList[i].taskStatus.open = false;
                    newtemp.recipientList[i].taskStatus.done = false;
                    newtemp.recipientList[i].taskStatus.inProgress = false;
                    newtemp.recipientList[i].taskStatus.discard = true;
                    newtemp.recipientList[i].taskStatus.remind = false;
                }
                setTaskData(tempData);
            }
        })

    }

    const handleDoneSingleTask = (index: number) => {
        setTaskStatusSingle({ done: true, inProgress: false, discard: false, remind: false, reOpen: true });
        setTaskStatusForAll({ doneForAll: false, discardForAll: false, remindAll: false });

        var tempData = [...taskData];
        var newtemp = tempData[taskIndex];
        for (var i = 0; i < newtemp.recipientList.length; i++) {
            newtemp.recipientList[i].taskStatus.open = false;
            newtemp.recipientList[i].taskStatus.done = false;
            newtemp.recipientList[i].taskStatus.inProgress = false;
            newtemp.recipientList[i].taskStatus.discard = false;
            newtemp.recipientList[i].taskStatus.remind = false;
        }
        tempData[taskIndex].recipientList[index].taskStatus.done = true;
        setTaskData(tempData);


        var temp = [...assignTasksList];
        for (var i = 0; i < temp.length; i++) {
            temp[i].taskStatus.done = false;
        }
        temp[0].taskStatus.done = true;
        setAssignTasksList(temp);
    }

    const handleReOpenForSingle = (index: number) => {
        setTaskStatusForAll({ doneForAll: false, discardForAll: false, remindAll: false });
        setTaskStatusSingle({ done: false, inProgress: false, discard: false, remind: false, reOpen: true });


        var tempData = [...taskData];
        var newtemp = tempData[taskIndex];
        for (var i = 0; i < newtemp.recipientList.length; i++) {
            newtemp.recipientList[i].taskStatus.open = false;
            newtemp.recipientList[i].taskStatus.done = false;
            newtemp.recipientList[i].taskStatus.inProgress = false;
            newtemp.recipientList[i].taskStatus.discard = false;
            newtemp.recipientList[i].taskStatus.remind = false;
        }
        tempData[taskIndex].recipientList[index].taskStatus.open = true;
        setTaskData(tempData);



        var temp = [...assignTasksList];
        for (var i = 0; i < temp.length; i++) {
            temp[i].taskStatus.done = false;
            temp[i].taskStatus.inProgress = false;
            temp[i].taskStatus.discard = false;
            temp[i].taskStatus.remind = false;
            temp[i].taskStatus.reOpen = false;
        }
        temp[index].taskStatus.reOpen = true;
        setAssignTasksList(temp);
    }

    const handleInProgress = (index: number) => {
        setTaskStatusForAll({ doneForAll: false, discardForAll: false, remindAll: false });
        setTaskStatusSingle({ done: false, inProgress: true, discard: false, remind: false, reOpen: false });

        var tempData = [...taskData];
        var newtemp = tempData[taskIndex];
        for (var i = 0; i < newtemp.recipientList.length; i++) {
            newtemp.recipientList[i].taskStatus.open = false;
            newtemp.recipientList[i].taskStatus.done = false;
            newtemp.recipientList[i].taskStatus.inProgress = false;
            newtemp.recipientList[i].taskStatus.discard = false;
            newtemp.recipientList[i].taskStatus.remind = false;
        }
        tempData[taskIndex].recipientList[index].taskStatus.inProgress = true;
        setTaskData(tempData);


        var temp = [...assignTasksList];
        for (var i = 0; i < temp.length; i++) {
            temp[i].taskStatus.done = false;
            temp[i].taskStatus.inProgress = false;
            temp[i].taskStatus.discard = false;
            temp[i].taskStatus.remind = false;
            temp[i].taskStatus.reOpen = false;
        }
        temp[index].taskStatus.inProgress = true;
        setAssignTasksList(temp);
    }

    const handleDiscardSingle = (index: number) => {
        Swal.fire({
            title: 'Sie sind dabei, die Aufgabe für alle Empfänger zu verwerfen. Möchten Sie fortfahren?',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            confirmButtonText: 'Für alle verwerfen',
            cancelButtonText: `Abbrechen`,
            customClass: {
                container: 'discardTask-alert',
                confirmButton: 'f-14px btn-danger fw-400',
                cancelButton: 'f-14px default-btn fw-400',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Verworfen!', '', 'success');
                setTaskStatusForAll({ doneForAll: false, discardForAll: false, remindAll: false });
                setTaskStatusSingle({ done: false, inProgress: false, discard: true, remind: false, reOpen: false });

                var tempData = [...taskData];
                var newtemp = tempData[taskIndex];
                for (var i = 0; i < newtemp.recipientList.length; i++) {
                    newtemp.recipientList[i].taskStatus.open = false;
                    newtemp.recipientList[i].taskStatus.done = false;
                    newtemp.recipientList[i].taskStatus.inProgress = false;
                    newtemp.recipientList[i].taskStatus.discard = false;
                    newtemp.recipientList[i].taskStatus.remind = false;
                }
                tempData[taskIndex].recipientList[index].taskStatus.discard = true;
                setTaskData(tempData);


                var temp = [...assignTasksList];
                for (var i = 0; i < temp.length; i++) {
                    temp[i].taskStatus.done = false;
                    temp[i].taskStatus.inProgress = false;
                    temp[i].taskStatus.discard = false;
                    temp[i].taskStatus.remind = false;
                    temp[i].taskStatus.reOpen = false;
                }
                temp[index].taskStatus.discard = true;
                setAssignTasksList(temp);
            }
        })
    }

    const handleDoneSingleTaskDD = (index: number) => {
        debugger
        var tempData = [...taskData];
        var newtemp = tempData[taskIndex];
        var newItemToMove = newtemp.recipientList[index];
        newtemp.recipientList.splice(index, 1);
        newtemp.recipientList.unshift(newItemToMove);

        var temp = [...assignTasksList];
        var itemToMove = temp[index];

        temp.splice(index, 1);
        temp.unshift(itemToMove);

        setAssignTasksList(temp);
        setRandomNum(Math.random() * 10);

    }
    useEffect(() => {
        if (randomNum !== undefined) {
            handleDoneSingleTask(0);
        }
    }, [randomNum]);

    const onMouseEnterTask = (index: number) => {
        setTaskStatusDropdown(index)
    }
    const onMouseLeaveTask = () => {
        setTaskStatusDropdown(null)
    }

    // ******** Assign Task Actions End ******** //


    return (
        <>
            <Card className='boxShadow overflow-hidden'>
                <Card.Header>
                    <DisplayBetween>
                        <Box>
                            <BodyText fontSize='20px' fontWeight='700' textColor='#212b36'>{taskData[taskIndex].title}</BodyText>
                        </Box>
                        <Box>
                            <GroupActionButtonWidget
                                bookMarkBtn={true}
                                noticeBtn={true}
                                isDropdownIcon={true}
                                hideHomeScreen={() => setShowHomeScreen(true)}
                                hidePrintPdf={() => setPrintPdfSetting(true)}
                                hideStarModal={() => setShowBookMark(true)}
                                hideNoticeModal={() => setShowRememberOption(true)}
                                hideUnsavedContent={() => setUnsavedComment(true)}
                                hideDeleteMessage={() => setDeleteMessage(true)}
                                hideReportContent={() => setReportContent(true)}
                                hidepermission={() => setPermission(true)}
                                hideAddPoll={() => setAddPoll(true)}
                                notificationTextToggle={notificationTextToggle}
                                handleDisableNotification={() => setNotificationTextToggle(!notificationTextToggle)}
                                commentTextBoxToggle={commentTextBoxToggle}
                                handleCommentTextBoxToggle={() => setCommentTextBoxToggle(!commentTextBoxToggle)}
                                addEditTaskModal={() => setTaskModal(true)}
                                pageName="taskDetailPage" />
                        </Box>
                    </DisplayBetween>
                </Card.Header>
                <Card.Body>
                    <TaskWrapper>
                        <TaskInfo>
                            <DisplayBetween>
                                <Box width='95%'>
                                    <DisplayStart width='100%'>
                                        <Box mr='5px'>
                                            {!!taskData[taskIndex].profileImg ?
                                                ''
                                                :
                                                <img width='50' src='../../../images/account.png' alt="userName-img" />
                                            }

                                        </Box>
                                        <Box width='90%'>
                                            <DisplayStart mb='5px'>
                                                <CustomTooltip
                                                    ToolTipTrigger={<BodyText mb='0px' fontSize='14px' fontWeight='700' textColor='#666' pl='5px'> {taskData[taskIndex].userName1} </BodyText>}
                                                    toolTipContent={taskData[taskIndex].userNameTooltipContent1}
                                                    toolTipPlacement='top'
                                                />
                                                <FaArrowRight className='mx-1' />
                                                <CustomTooltip
                                                    ToolTipTrigger={<BadgeWidget solid={true} title={taskData[taskIndex].badgeRoleTitle1} color='#1e6c7b' bgColor={taskData[taskIndex].badgeRoleColor1} />}
                                                    toolTipContent={taskData[taskIndex].badgeRoleTooltipContent1}
                                                    toolTipPlacement='top'
                                                    contentAlign='center'
                                                />
                                                <span className='mx-1'> , </span>
                                                <CustomTooltip
                                                    ToolTipTrigger={<BadgeWidget solid={true} title={taskData[taskIndex].badgeRoleTitle2} color='#1e6c7b' bgColor={taskData[taskIndex].badgeRoleColor2} />}
                                                    toolTipContent={taskData[taskIndex].badgeRoleTooltipContent2}
                                                    toolTipPlacement='top'
                                                    contentAlign='center'
                                                />
                                            </DisplayStart>
                                            <DisplayStart>
                                                <Box width='33.33%'>
                                                    <DisplayStart>
                                                        <BodyText pl='5px' pr='6px'> Netzwerk: </BodyText>
                                                        <BadgeWidget outiline={true} title={taskData[taskIndex].badgeOutline} color='#0d3f5f' bgColor='#ffffff' />
                                                    </DisplayStart>
                                                </Box>
                                                <Box width='20.77%'>
                                                    <DisplayStart>
                                                        <BodyText fontSize='12px' fontWeight='500'> List:</BodyText>
                                                        <BodyText pl='5px' fontSize='12px' fontWeight='500'>{taskData[taskIndex].list}</BodyText>
                                                    </DisplayStart>
                                                </Box>
                                                <Box width='46%'>
                                                    <DisplayStart>
                                                        <BodyText fontSize='12px' fontWeight='500'> Created:</BodyText>
                                                        <BodyText pl='5px' fontSize='12px' fontWeight='500'>{taskData[taskIndex].taskCreatedTime} </BodyText>
                                                    </DisplayStart>
                                                </Box>
                                            </DisplayStart>
                                        </Box>
                                    </DisplayStart>
                                </Box>
                                <Box>
                                    <div onClick={() => setSendReminder(true)}>
                                        <CustomTooltip
                                            ToolTipTrigger={<img src='/images/graph-img.png' alt='graph-icons' />}
                                            toolTipContent='Gelesen: 2'
                                            toolTipPlacement='top'
                                        />
                                    </div>
                                </Box>
                            </DisplayBetween>
                        </TaskInfo>
                        <TaskDetails className='my-3'>
                            <Box className='p-2 bg-light'>
                                <Box>
                                    <DisplayBetween>
                                        <Box>
                                            <BodyText fontWeight='700' fontSize='15px' display='inline'>Priority: {taskData[taskIndex].prioritySubtitle}</BodyText>
                                            <BodyText fontSize='13px' pl='5px' display='inline'>- One of {taskData[taskIndex].recipientList.length} recipients has to fulfil the task</BodyText>
                                        </Box>
                                        <Box>
                                            <DisplayStart>
                                                <CustomTooltip
                                                    ToolTipTrigger={<BodyText fontSize='13px' display='inline'>Dr. Sven Markus Gallenbach</BodyText>}
                                                    toolTipContent="Zannarzt - Praxis Gallenbach & Zåhne"
                                                    toolTipPlacement='top'
                                                />
                                                <BodyText fontSize='13px' mr='7px' pl='5px' display='inline'>- One of {taskData[taskIndex].recipientList.length} recipients has to fulfil the task</BodyText>
                                                <BadgeWidget bordered={true}
                                                    title={taskStatusForAll.doneForAll ? 'Erledigt' : taskStatusForAll.discardForAll ? 'Weggeworfen' : taskStatusSingle.discard ? 'Weggeworfen' : taskStatusSingle.done ? 'Erledigt' : taskStatusSingle.inProgress ? 'Im Gange' : 'offen'}
                                                    color={taskStatusForAll.doneForAll ? '#5cb85b' : taskStatusForAll.discardForAll ? '#f66' : taskStatusSingle.discard ? '#f66' : taskStatusSingle.done ? '#5cb85b' : taskStatusSingle.inProgress ? '#04a0a0' : '#fc9136'}
                                                />
                                            </DisplayStart>
                                        </Box>
                                    </DisplayBetween>
                                </Box>

                                <Divider mt='6px' mb='6px' />
                                <Box>
                                    <Row className='m-0'>
                                        <Col lg={12} className='bg-task-recipient'>
                                            {taskStatusForAll.doneForAll === true || taskStatusForAll.discardForAll === true || taskStatusSingle.done === true || taskStatusSingle.discard === true ?
                                                <DisplayEnd>
                                                    <ButtonWidget handleClick={() => handleReOpenForAll()} text="wieder für alle geöffnet" varient='custom' textColor='#aaa' bgColor='#fff' textColorHover='#aaa' bgHoverColor='#fff' borderColor='#aaa' />
                                                </DisplayEnd>
                                                :
                                                <DisplayEnd>
                                                    <ButtonWidget handleClick={() => handleDoneAllTaske()} text="Alle als erledigt markieren" varient='custom' textColor='#fff' bgColor='#5cb85c' bgHoverColor='#449d44' borderColor='#4cae4c' />
                                                    <ButtonWidget handleClick={() => handleDiscardAllTasks()} text="Für alle verwerfen" varient='custom' textColor='#d9534f' bgColor='#fff' textColorHover='#d9534f' bgHoverColor='#fff' borderColor='#d43f3a' class='ms-2' />
                                                    {!taskStatusSingle.reOpen ?
                                                        <ButtonWidget handleClick={() => setTaskStatusForAll({ doneForAll: false, discardForAll: false, remindAll: true })} disabled={taskStatusForAll.remindAll} text="Erinnere alle daran" varient='custom' textColor='#43b0b0' bgColor='#fff' textColorHover='#43b0b0' bgHoverColor='#fff' borderColor='#3c9e9e' class='ms-2' />
                                                        :
                                                        null
                                                    }
                                                </DisplayEnd>
                                            }

                                        </Col>
                                        {taskData[taskIndex].recipientList.map((item: any, index: number) => {
                                            return <>
                                                {index === 0 ?
                                                    <Col lg={12} className='bg-task-recipient'>
                                                        <Row>
                                                            <Col lg={7}>
                                                                <TaskRecipientInfo>
                                                                    <DisplayStart>
                                                                        <Box className='me-2'>
                                                                            {!!item.profileImg ?
                                                                                <img width='30' height='30' src={item.profileImg} alt="userName-img" />
                                                                                :
                                                                                <img width='30' height='30' src='/images/account.png' alt="userName-img" />
                                                                            }
                                                                        </Box>
                                                                        <Box>
                                                                            <CustomTooltip
                                                                                ToolTipTrigger={<BodyText>{item.userName}</BodyText>}
                                                                                toolTipContent={item.userTooltipText}
                                                                                toolTipPlacement='top'
                                                                                contentAlign='center'
                                                                            />
                                                                            <DisplayStart>
                                                                                <BadgeWidget bordered={true}
                                                                                    title={taskStatusForAll.doneForAll ? 'Erledigt' : taskStatusForAll.discardForAll ? 'Weggeworfen' : taskStatusSingle.discard ? 'Weggeworfen' : taskStatusSingle.done ? 'Erledigt' : taskStatusSingle.inProgress ? 'Im Gange' : 'offen'}
                                                                                    color={taskStatusForAll.doneForAll ? '#5cb85b' : taskStatusForAll.discardForAll ? '#f66' : taskStatusSingle.discard ? '#f66' : taskStatusSingle.done ? '#5cb85b' : taskStatusSingle.inProgress ? '#04a0a0' : '#fc9136'}
                                                                                />

                                                                                <BodyText fontSize='13px' mt='5px' ml="5px">- {item.taskAssignTime}</BodyText>
                                                                            </DisplayStart>
                                                                        </Box>
                                                                    </DisplayStart>
                                                                </TaskRecipientInfo>
                                                            </Col>
                                                            <Col lg={5}>
                                                                {taskStatusForAll.doneForAll === true || taskStatusForAll.discardForAll === true || taskStatusSingle.done === true || taskStatusSingle.discard === true ?
                                                                    <DisplayEnd mt='8px'>
                                                                        <ButtonWidget handleClick={() => handleReOpenForSingle(index)} text="wieder öffnen" varient='custom' textColor='#aaa' bgColor='#fff' textColorHover='#aaa' bgHoverColor='#fff' borderColor='#aaa' />
                                                                    </DisplayEnd>
                                                                    :
                                                                    <DisplayEnd mt='8px'>
                                                                        <ButtonWidget handleClick={() => handleDoneSingleTask(index)} text="Erledigt" varient='custom' textColor='#fff' bgColor='#5cb85c' bgHoverColor='#449d44' borderColor='#4cae4c' />
                                                                        <ButtonWidget handleClick={() => handleInProgress(index)} text="Im Gange" varient='custom' textColor='#43b0b0' bgColor='#fff' textColorHover='#43b0b0' bgHoverColor='#fff' borderColor='#3c9e9e' class='ms-2' />
                                                                        <ButtonWidget handleClick={() => handleDiscardSingle(index)} text="Verwerfen" varient='custom' textColor='#d9534f' bgColor='#fff' textColorHover='#d9534f' bgHoverColor='#fff' borderColor='#d43f3a' class='ms-2' />
                                                                    </DisplayEnd>
                                                                }

                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    :
                                                    <>
                                                        {showMoreRecipient ?
                                                            <Col lg={12} onMouseEnter={() => onMouseEnterTask(index)} onMouseLeave={() => onMouseLeaveTask()} className={taskStatusSingle.done === true && taskStatusForAll.doneForAll === false || taskStatusSingle.inProgress === true || taskStatusSingle.reOpen === true || taskStatusSingle.discard === true ? 'bg-task-recipient light-opacity' : 'bg-task-recipient'}>
                                                                <Row>
                                                                    <Col lg={7}>
                                                                        <TaskRecipientInfo mt='5px'>
                                                                            <DisplayStart>
                                                                                <Box className='me-2'>
                                                                                    {!!item.profileImg ?
                                                                                        <img width='30' height='30' src={item.profileImg} alt="userName-img" />
                                                                                        :
                                                                                        <img width='30' height='30' src='/images/account.png' alt="userName-img" />
                                                                                    }
                                                                                </Box>
                                                                                <Box>
                                                                                    <CustomTooltip
                                                                                        ToolTipTrigger={<BodyText>{item.userName}</BodyText>}
                                                                                        toolTipContent={item.userTooltipText}
                                                                                        toolTipPlacement='top'
                                                                                        contentAlign='center'
                                                                                    />
                                                                                    <DisplayStart>
                                                                                        {taskStatusSingle.done === true && taskStatusForAll.doneForAll === false || taskStatusSingle.inProgress === true || taskStatusSingle.reOpen === true || taskStatusSingle.discard === true ?
                                                                                            null
                                                                                            :
                                                                                            <BadgeWidget bordered={true}
                                                                                                title={taskStatusForAll.doneForAll ? 'Erledigt' : taskStatusForAll.discardForAll ? 'Weggeworfen' : 'offen'}
                                                                                                color={taskStatusForAll.doneForAll ? '#5cb85b' : taskStatusForAll.discardForAll ? '#f66' : taskStatusSingle.done ? '#5cb85b' : '#fc9136'}
                                                                                            />
                                                                                        }

                                                                                        <BodyText fontSize='13px' mt='5px' ml="5px">- {item.taskAssignTime}</BodyText>
                                                                                    </DisplayStart>
                                                                                </Box>
                                                                            </DisplayStart>
                                                                        </TaskRecipientInfo>
                                                                    </Col>
                                                                    <Col lg={5}>

                                                                        {taskStatusForAll.doneForAll === true || taskStatusForAll.discardForAll === true || taskStatusSingle.done === true || taskStatusSingle.reOpen === true || taskStatusSingle.inProgress === true || taskStatusSingle.discard === true ?
                                                                            null
                                                                            :
                                                                            <DisplayEnd verticalAlign='center' className='h-100'>
                                                                                {taskStatusDropdown === index ?
                                                                                    <Box>
                                                                                        <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="Status ändern">
                                                                                            <Dropdown.Item onClick={() => handleDoneSingleTaskDD(index)}>
                                                                                                <span className='f-14px'>Erledigt</span>
                                                                                            </Dropdown.Item>
                                                                                        </DropdownButton>
                                                                                    </Box>
                                                                                    :
                                                                                    null
                                                                                }

                                                                                <Box>
                                                                                    <ButtonWidget text="Erinnern" handleClick={() => setTaskStatusForAll({ doneForAll: false, discardForAll: false, remindAll: true })} disabled={taskStatusForAll.remindAll} varient='custom' textColor='#43b0b0' bgColor='#fff' bgHoverColor='#fff' textColorHover='#43b0b0' borderColor='#3c9e9e' class='ms-2' />
                                                                                </Box>
                                                                            </DisplayEnd>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            :
                                                            null
                                                        }
                                                    </>
                                                }
                                            </>
                                        })}
                                    </Row>
                                </Box>
                                {taskStatusForAll.doneForAll === true || taskStatusSingle.done === true ?
                                    null
                                    :
                                    <Box>
                                        <Divider mt='10px' mb='6px' />
                                        <DisplayBetween>
                                            <Box>
                                                {!showMoreRecipient ?
                                                    <BodyText fontSize='13px'>........ 3 weitere Empfänger</BodyText>
                                                    :
                                                    null
                                                }
                                            </Box>
                                            <Box>
                                                <ButtonWidget text={showMoreRecipient ? 'Weniger Empfänger anzeigen' : 'Alle Empfänger anzeigen'} varient='light' handleClick={() => setShowMoreRecipient(!showMoreRecipient)} />
                                            </Box>
                                        </DisplayBetween>
                                    </Box>
                                }

                            </Box>
                        </TaskDetails>
                        <Box>
                            <BodyText fontSize='13px'>Bitte Doku vervollständigen lassen. Danke Lg JS</BodyText>
                        </Box>
                        <CommentWrapper className='mb-3'>
                            <Box className='bg-light p-2 my-3'>
                                <BodyText fontWeight='700' textColor='#666' fontSize='15px'>Kommentar</BodyText>
                                {commentData.map((item, index) => {
                                    return <>
                                        <CommentItem>
                                            <Divider mt='10px' mb='10px' />

                                            <Box>
                                                <Comment>
                                                    <DisplayStart verticalAlign='start'
                                                        width='100%'
                                                        onMouseEnter={() => handleMouseEnter(index)}
                                                        onMouseLeave={handleMouseLeave}>
                                                        <Box className='me-2'>
                                                            <img width='30' height='30' src={!!item.profileImg ? item.profileImg : '/images/account.png'} alt="userName-img" />
                                                        </Box>
                                                        <Box width='100%'>
                                                            <DisplayBetween width='100%'>
                                                                <Box>
                                                                    <DisplayStart>
                                                                        <CustomTooltip
                                                                            ToolTipTrigger={<BodyText fontWeight='700' textColor='#666' fontSize='14px'>{item.userName}</BodyText>}
                                                                            toolTipContent={item.userTooltipText}
                                                                            toolTipPlacement='top'
                                                                            contentAlign='center'
                                                                        />
                                                                        <span className='mx-1'> - </span>
                                                                        <CustomTooltip
                                                                            ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px'> {moment(new Date(item.commentTime)).startOf('hour').fromNow()} </BodyText>}
                                                                            toolTipContent={item.commentTimeTooltipText}
                                                                            toolTipPlacement='top'
                                                                            contentAlign='center'
                                                                        />
                                                                        <div className='d-flex align-items-center justify-content-start'>
                                                                            <Link to="" className='text-decoration-none' onClick={() => handleReply(index)}>
                                                                                <TiArrowBack className='ms-2' color='#666' />
                                                                                <span className='ms-1 fw-400 w-176px text-truncate text-link-color'> Antwort </span>
                                                                            </Link>
                                                                        </div>
                                                                    </DisplayStart>
                                                                </Box>
                                                                <Box>
                                                                    <DisplayEnd>
                                                                        {index === commentIndexShow.index ?
                                                                            <>
                                                                                <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="">
                                                                                    <Dropdown.Item onClick={() => editMainComment(index)}>
                                                                                        <span className='f-14px'> Bearbeiten </span>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item onClick={() => deleteMainComment(index)}>
                                                                                        <span className='f-14px'> Löschen </span>
                                                                                    </Dropdown.Item>
                                                                                </DropdownButton>
                                                                            </>
                                                                            : null}
                                                                    </DisplayEnd>
                                                                </Box>
                                                            </DisplayBetween>
                                                            <BodyText fontWeight='400' textColor='#333' fontSize='13px' mt='5px'>{item.commentText}</BodyText>
                                                        </Box>
                                                    </DisplayStart>
                                                </Comment>
                                                {showReply.isShow === true && index === showReply.index && showReply.innerIndex === undefined ?
                                                    <ReplyBox className={isEdited ? 'w-100' : ''}>
                                                        <DisplayStart verticalAlign='start' className='mt-4 mb-2'>
                                                            <Box className='me-2'>
                                                                <img width='30' height='30' src='/images/account.png' alt="userName-img" />
                                                            </Box>
                                                            <Box width='100%'>
                                                                <Box className="mb-3">
                                                                    <FormControl Name='comment' Type="textarea" Value={commentValue} Placeholder="Text" rows={4} handleChange={(event) => setCommentValue(event.target.value)} />
                                                                </Box>
                                                                <ModelActions
                                                                    ImgClick={() => setAddMediaModalShow(true)}
                                                                    FontClick={() => HandleFontClick()}
                                                                    isHideFont={HideFont}
                                                                />
                                                                <DisplayEnd>
                                                                    <ButtonWidget class="me-1" text={"Abbrechen"} varient='light' handleClick={() => handleCancelReply(index)} />
                                                                    <ButtonWidget text={"Antwort"} varient='primary' handleClick={() => handleReplySubmit(index)} />
                                                                </DisplayEnd>
                                                            </Box>
                                                        </DisplayStart>
                                                    </ReplyBox>
                                                    :
                                                    null
                                                }
                                            </Box>
                                            {!!item.commentReply ?
                                                item.commentReply.map((innerItem, innerIndex) => {
                                                    return <>
                                                        <ReplyItem>
                                                            <Divider mt='10px' mb='10px' />
                                                            <Box>
                                                                <Comment>
                                                                    <DisplayStart verticalAlign='start'
                                                                        width='100%'
                                                                        onMouseEnter={() => handleMouseEnter(index, innerIndex)}
                                                                        onMouseLeave={handleMouseLeave}>
                                                                        <Box className='me-2'>
                                                                            <img width='30' height='30' src='/images/account.png' alt="userName-img" />
                                                                        </Box>
                                                                        <Box width='100%'>
                                                                            <DisplayBetween width='100%'>
                                                                                <Box>
                                                                                    <DisplayStart>
                                                                                        <CustomTooltip
                                                                                            ToolTipTrigger={<BodyText fontWeight='700' textColor='#666' fontSize='14px'>{innerItem.userName}</BodyText>}
                                                                                            toolTipContent='Zahnarzt - Praxis Gallenbach & Zähne'
                                                                                            toolTipPlacement='top'
                                                                                            contentAlign='center'
                                                                                        />
                                                                                        <span className='mx-1'> - </span>
                                                                                        <CustomTooltip
                                                                                            ToolTipTrigger={<BodyText fontWeight='400' textColor='#666' fontSize='14px'> {innerItem.commentTime} </BodyText>}
                                                                                            toolTipContent={innerItem.commentTime}
                                                                                            toolTipPlacement='top'
                                                                                            contentAlign='center'
                                                                                        />
                                                                                        <div className='d-flex align-items-center justify-content-start'>
                                                                                            <Link to="" className='text-decoration-none' onClick={() => handleReply(index, innerIndex)}>
                                                                                                <TiArrowBack className='ms-2' color='#666' />
                                                                                                <span className='ms-1 fw-400 w-176px text-truncate text-link-color'> Antworten </span>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </DisplayStart>
                                                                                </Box>
                                                                                <Box>
                                                                                    <DisplayEnd>
                                                                                        {innerIndex === commentIndexShow.innerIndex ?
                                                                                            <>
                                                                                                <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="">
                                                                                                    <Dropdown.Item onClick={() => editInnerComment(index, innerIndex)}>
                                                                                                        <span className='f-14px'> Bearbeiten </span>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item onClick={() => deleteInnerComment(index, innerIndex)}>
                                                                                                        <span className='f-14px'> Löschen </span>
                                                                                                    </Dropdown.Item>
                                                                                                </DropdownButton>
                                                                                            </>
                                                                                            : null}
                                                                                    </DisplayEnd>
                                                                                </Box>
                                                                            </DisplayBetween>
                                                                            {!!innerItem.innerReplyText ?
                                                                                <BodyText fontWeight='400' textColor='#333' fontSize='13px' mt='5px'>{innerItem.innerReplyText}</BodyText>
                                                                                :
                                                                                <BodyText fontWeight='400' textColor='#333' fontSize='13px' mt='5px'>{innerItem.commentText}</BodyText>
                                                                            }
                                                                        </Box>
                                                                    </DisplayStart>
                                                                </Comment>
                                                                {showReply.isShow === true && index === showReply.index && innerIndex === showReply.innerIndex ?
                                                                    <InnerReplyItem>
                                                                        <DisplayStart verticalAlign='start' className='mt-4 mb-2'>
                                                                            <Box className='me-2'>
                                                                                <img width='30' height='30' src='/images/account.png' alt="userName-img" />
                                                                            </Box>
                                                                            <Box width='100%'>
                                                                                <Box className="mb-3">
                                                                                    <FormControl Name='comment' Type="textarea" Value={commentValue} Placeholder="Text" rows={4} handleChange={(event) => setCommentValue(event.target.value)} />
                                                                                </Box>
                                                                                <ModelActions
                                                                                    ImgClick={() => setAddMediaModalShow(true)}
                                                                                    FontClick={() => HandleFontClick()}
                                                                                    isHideFont={HideFont}
                                                                                />
                                                                                <DisplayEnd>
                                                                                    <ButtonWidget class="me-1" text={"Abbrechen"} varient='light' handleClick={() => handleCancelReply(index)} />
                                                                                    <ButtonWidget text={"Antwort"} varient='primary' handleClick={() => handleInnerReplySubmit(index, innerIndex)} />
                                                                                </DisplayEnd>
                                                                            </Box>
                                                                        </DisplayStart>
                                                                    </InnerReplyItem>
                                                                    :
                                                                    null
                                                                }
                                                            </Box>
                                                        </ReplyItem>
                                                    </>
                                                })
                                                :
                                                null
                                            }

                                        </CommentItem>
                                    </>
                                })}
                                {!showReply.isShow && !showReply.index ?
                                    <CommentBox>
                                        <DisplayStart verticalAlign='start' className='mt-4 mb-2'>
                                            <Box className='me-2'>
                                                <img width='30' height='30' src='/images/account.png' alt="userName-img" />
                                            </Box>
                                            <Box width='100%'>
                                                <Box className="mb-3">
                                                    <FormControl Name='comment' Type="textarea" Value={commentValue} Placeholder="Text" rows={5} handleChange={(event) => setCommentValue(event.target.value)} />
                                                </Box>
                                                <ModelActions
                                                    ImgClick={() => setAddMediaModalShow(true)}
                                                    FontClick={() => HandleFontClick()}
                                                    isHideFont={HideFont}
                                                />
                                                <DisplayEnd>
                                                    <ButtonWidget text={"Kommentar"} varient='primary' handleClick={() => handleCommentSubmit()} />
                                                </DisplayEnd>
                                            </Box>
                                        </DisplayStart>
                                    </CommentBox>
                                    :
                                    null
                                }
                            </Box>
                        </CommentWrapper>
                    </TaskWrapper>
                </Card.Body>
            </Card>

            <AddMediaModal displayAddMediaModal={AddMediaModalShow} hideAddMediaModal={() => setAddMediaModalShow(false)} />
            <AddEditTask showTask={TaskModal} hideTask={() => setTaskModal(false)} />

            <CommonModal
                showModal={attachOption}
                hideModal={() => setAttachOption(false)}
                modalTitle='Anhangoptionen'
                modalContent={<AttachmentOptionModalContent />}
                modalSize='md'
                modalFooterContent={
                    <>
                        <ButtonWidget
                            varient='light'
                            text={'Schließen'}
                            handleClick={() => setAttachOption(false)}
                        />
                    </>
                }
            />

            <CommonModal
                showModal={pleases}
                hideModal={() => setPleases(false)}
                modalTitle='Anhangoptionen'
                modalContent={<PleasesModalContent />}
                modalSize='md'
                modalFooterContent={
                    <>
                        <ButtonWidget
                            varient='light'
                            text={'Schließen'}
                            handleClick={() => setPleases(false)}
                        />
                    </>
                }
            />

            <CommonModal
                showModal={deleteComment}
                hideModal={() => setDeleteComment(false)}
                modalTitle='Bestätigung'
                modalContent={<ConfirmDeleteCommentModalContent />}
                modalSize='md'
            />
            <CommonModal
                showModal={showHomeScreen}
                hideModal={() => setShowHomeScreen(false)}
                modalTitle='Auf Startseite anzeigen'
                modalContent={<ShowHomeScreenModalContent />}
                modalSize='lg'
                footerClass={"footer-sticky"}
            />
            <CommonModal
                showModal={showBookMark}
                hideModal={() => setShowBookMark(false)}
                modalTitle='Lesezeichen-Optionen'
                modalContent={<BookMarkOptionModalContent />}
                modalSize='lg'
                modalFooterContent={<BookMarkFooterContent footerModalArea='bookMarkOption' hideModal={() => setShowBookMark(false)} />}
            />

            <CommonModal
                showModal={showRememberOption}
                hideModal={() => setShowRememberOption(false)}
                modalTitle='Merk-Optionen'
                modalContent={<RememberOptionModalContent />}
                modalSize='lg'
                modalFooterContent={<BookMarkFooterContent footerModalArea='rememberOption' hideModal={() => setShowRememberOption(false)} />}
            />

            <CommonModal
                showModal={printPdfSetting}
                hideModal={() => setPrintPdfSetting(false)}
                modalTitle='PDF-Einstellungen'
                modalContent={<PdfSettingsModalContent />}
                modalSize='md'
            />

            <CommonModal
                showModal={deleteMessage}
                hideModal={() => setDeleteMessage(false)}
                modalTitle='Lösche "OPTI CONCEPT Coaching"?'
                modalContent={<DeleteMessageDetailModalContent />}
                modalSize='md'
                centered={true}
            />
            <CommonModal
                showModal={reportContent}
                hideModal={() => setReportContent(false)}
                modalTitle='Inhalt melden'
                modalContent={<ReportContentModalContent />}
                modalSize='md'
            />
            <CommonModal
                showModal={permission}
                hideModal={() => setPermission(false)}
                modalTitle='Berechtigungen:'
                modalContent={<PermissionModalContent />}
                modalSize='md'
            />
            <CommonModal
                showModal={addPoll}
                hideModal={() => setAddPoll(false)}
                modalTitle='Umfrage hinzufügen'
                modalContent={<AddPollModalContent AllowReading={AllowReading} />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayBetween className='w-100'>
                            <Box>
                                <ButtonWidget
                                    varient='light'
                                    text={
                                        <>
                                            {AllowReading ?
                                                <span><FaMinusCircle className='default-btn-icon' /> Optionen ausblenden</span>
                                                :
                                                <span><FaPlusCircle className='default-btn-icon' /> Zeige weitere Optionen</span>
                                            }
                                        </>
                                    }
                                    handleClick={HandleAllowReding}
                                />
                            </Box>
                            <Box>
                                <ButtonWidget
                                    varient='light'
                                    text={'Abbrechen'}
                                    class='me-2'
                                />
                                <ButtonWidget
                                    varient='primary'
                                    text={'Erstellen'}
                                />
                            </Box>
                        </DisplayBetween>
                    </>
                }
                footerClass={"footer-sticky"}
            />
            <CommonModal
                showModal={unsavedComment}
                hideModal={() => setUnsavedComment(false)}
                modalTitle='Ungespeicherter Kommentar'
                modalContent={<UnsavedCommentModalContent />}
                modalSize='md'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Bleiben'}
                                handleClick={() => setUnsavedComment(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Entwurf speichem'}
                                class='mx-1'
                            />
                            <ButtonWidget
                                varient='danger'
                                text={'Verlassen'}
                            />
                        </DisplayEnd>
                    </>
                }
            />

            <CommonModal
                showModal={sendReminder}
                hideModal={() => setSendReminder(false)}
                modalTitle='Erinnerung senden'
                modalContent={<SendReminderModalContent />}
                modalSize='lg'
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <ButtonWidget
                                varient='light'
                                text={'Schließen'}
                                class='me-2 ms-3'
                                handleClick={() => setSendReminder(false)}
                            />
                            <ButtonWidget
                                varient='primary'
                                text={'Ausgewählte erinnem'}
                                disabled={true}
                            />
                        </DisplayEnd>
                    </>
                }
                footerClass={"footer-sticky"}
            />
        </>
    )
}