import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
function BarChart(props) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );
    const labels = props.labels;
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
    };
    const data = {
        labels,
        datasets: [{
            label: props.title,
            data: props.data,
            backgroundColor: [
                'rgb(66,133,255)',
                'rgb(220,57,18)',
                'rgb(255,153,0)',
                'rgb(16,150,24)',
                'rgb(204,204,204)'
            ],
            borderColor: [
                'rgb(66,133,255)',
                'rgb(220,57,18)',
                'rgb(255,153,0)',
                'rgb(16,150,24)',
                'rgb(204,204,204)'
            ],
            borderWidth: 1
        }],
    };
    return (
        <>
            <div>
                <Bar data={data} options={options} />
            </div>
        </>
    );
};

export default BarChart;