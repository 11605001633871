import { FC } from "react";
import Card from 'react-bootstrap/Card';
import { HiCog } from 'react-icons/hi';
import { HiHome } from 'react-icons/hi2';
import { BodyText } from "../StyledComponents";

interface IProps {
    showActions: () => void;
    isRed: boolean,
}
export const DashboardModule: FC<IProps> = ({ showActions, isRed }) => {
    return (
        <>
            <Card className='border-0 rounded-0 b-shadow'>
                <Card.Header className='bg-white d-flex justify-content-between align-items-center'>
                    <Card.Title>
                        <HiHome size="20px" color="#666" />
                    </Card.Title>
                    <BodyText fontSize='24px' textColor='#666' fontWeight='600'>Dashboard</BodyText>
                    <Card.Title>
                        <HiCog size="20px" color={`${isRed ? "red" : "#666"}`} onClick={showActions} />
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className='text-center'>
                        <img className='w-50' src='/images/account-b.png' />
                    </div>
                    <Card.Text className='text-center mt-2'>
                        <b>Dr. Sven Markus Gallenbach</b>
                        <br />
                        <span>Profil anzeigen | Profil bearbeiten</span>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

