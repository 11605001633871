import React, { useState, MouseEvent } from 'react';
import { Card, Table } from "react-bootstrap";
import { BodyText, Box, DisplayStart } from '../../../Components/StyledComponents';
import ButtonWidget from '../../../Components/ButtonWidget';


const ReportContentData = [
    {
        creatorName: "Thomas Planitzer & Team",
        reportType: "comment/message",
        reportedBy: "Dr. Sven Markus Gallenbach",
        reason: "bullying and harassment",
        dateTime: "19.06.2023 09:41",
    },
    {
        creatorName: "Barbara Wasmer & Team",
        reportType: "comment/message",
        reportedBy: "Dr. Sven Markus Gallenbach",
        reason: "bullying and harassment",
        dateTime: "20.07.2023 09:41",
    },
    {
        creatorName: "Emine Mollaoglu",
        reportType: "comment",
        reportedBy: "Dr. Sven Markus Gallenbach",
        reason: "bullying and harassment",
        dateTime: "19.06.2023 09:41",
    },
    {
        creatorName: "Thomas Planitzer & Team",
        reportType: "comment/message",
        reportedBy: "Dr. Sven Markus Gallenbach",
        reason: "bullying and harassment",
        dateTime: "19.06.2023 09:41",
    },
]

export const ModerationReportsIndex = () => {

    return (
        <>
            <div className='users-list-sec'>
                <div className='user-detail mb-2'>
                    <BodyText fontSize="1.6em" fontWeight="700" textColor="#666"> Gemeldeter Inhalt </BodyText>
                </div>
                <Card className='boxShadow'>
                    <Card.Body>
                        <Table className='mb-0' responsive>
                            <thead>
                                <tr className='table-secondary'>
                                    <th>
                                        <div>Ersteller</div>
                                    </th>
                                    <th>
                                        <div>Typ</div>
                                    </th>
                                    <th>
                                        <div>Gemeldet von</div>
                                    </th>
                                    <th>
                                        <div>Grund</div>
                                    </th>
                                    <th>
                                        <div>Zeit</div>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {ReportContentData.map((item, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td className='align-middle'>
                                                    <Box>
                                                        <BodyText fontSize="12px" fontWeight="400" textColor="#666"> {item.creatorName} </BodyText>
                                                    </Box>
                                                </td>
                                                <td className='align-middle'>
                                                    <Box>
                                                        <BodyText fontSize="12px" fontWeight="400" textColor="#666"> {item.reportType} </BodyText>
                                                    </Box>
                                                </td>
                                                <td className='align-middle'>
                                                    <Box>
                                                        <BodyText fontSize="12px" fontWeight="400" textColor="#666"> {item.reportedBy} </BodyText>
                                                    </Box>
                                                </td>
                                                <td className='align-middle'>
                                                    <Box>
                                                        <BodyText fontSize="12px" fontWeight="400" textColor="#666"> {item.reason} </BodyText>
                                                    </Box>
                                                </td>
                                                <td className='align-middle'>
                                                    <Box>
                                                        <BodyText fontSize="12px" fontWeight="400" textColor="#666"> {item.dateTime} </BodyText>
                                                    </Box>
                                                </td>
                                                <td className='align-middle'>
                                                    <DisplayStart>
                                                        <Box className='cursor-pointer'>
                                                            <ButtonWidget class="me-2 fs-12" text={"Überprüfen"} varient='light' />
                                                        </Box>
                                                        <Box className='cursor-pointer'>
                                                            <ButtonWidget text={"als gelöst markieren"} varient='danger' class='fs-12' />
                                                        </Box>
                                                    </DisplayStart>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>

                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}