import * as React from "react";
import Form from "react-bootstrap/Form";
import FormControl from "../../FormControl";
import { Box } from "../../StyledComponents";
import { InputTextControl } from "../../Widgets/InputControls/InputTextControl";
import { useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const formSchema = yup
  .object({
    firstName: yup
      .string()
      .typeError("First name is required")
      .required("First name is required"),
  })
  .required();

function ChangePINModalContent() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
    formState,
    watch,
  } = useForm<UserProfileDto>({
    defaultValues: new UserProfileDto(),
    resolver: yupResolver(formSchema) as any,
    // mode: "onChange",
    //resolver: yupResolver(formSchema),
  });
  return (
    <>
      <Box className="mb-2">
        <Form.Label>Passwort</Form.Label>
        {/* <FormControl isFormGroup={true} Type='password' Size='sm' Placeholder='' Name='password' Id='password' /> */}
        <InputTextControl
          control={control}
          fieldName={"firstName"}
          error={errors.firstName}
          placeholder="Vorname"
          fieldType="password"
          size={"sm"}
        />
      </Box>
      <Box className="mb-2">
        <Form.Label>Neues PIN</Form.Label>
        <FormControl
          isFormGroup={true}
          Type="password"
          Size="sm"
          Placeholder=""
          Name="newPIN"
          Id="newPIN"
        />
      </Box>
      <Box className="mb-2">
        <Form.Label>Neue PIN bestätigen</Form.Label>
        <FormControl
          isFormGroup={true}
          Type="password"
          Size="sm"
          Placeholder=""
          Name="confirmNewPIN"
          Id="confirmNewPIN"
        />
      </Box>
    </>
  );
}

export default ChangePINModalContent;
