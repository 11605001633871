import React, { FC, Dispatch, SetStateAction, MouseEvent, useState } from 'react';
import { IMessageData, TdisplayMode } from '../Message/Index';
import { Card, Dropdown, DropdownButton, Table } from "react-bootstrap"
import { TopbarSelectFilterDropdown } from "../../Components/TopbarSelectFilterDropdown";
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaLock, FaLongArrowAltRight, FaListOl } from "react-icons/fa";
import { HiOutlineInformationCircle } from 'react-icons/hi';
import CustomTooltip from "../../Components/Tooltip";
import { CgAttachment } from "react-icons/cg";
import { BodyText, Box, DisplayBetween, DisplayCenter, DisplayEnd, MultiColorBox, StatusBadge, StatusBadgeTextColor } from "../../Components/StyledComponents";
import BadgeWidget from "../../Components/BadgeWidget";
import { OpenBadgeCardTooltip } from "./OpenBadgeCardTooltip";
import ButtonWidget from '../../Components/ButtonWidget';
import { ITaskData } from './TaskIndex';
import { useNavigate } from 'react-router-dom';

interface ITaskList {
    listData: ITaskData[],
    setListData: Dispatch<SetStateAction<IMessageData[]>>,
    displayMode: TdisplayMode,
}

export const TaskList: FC<ITaskList> = ({ listData, setListData, displayMode }) => {
    const navigate = useNavigate();


    const [statusDropdown, setStatusDropdown] = useState<number | null>(null);

    const handleMouseEnter = (index: number) => {
        setStatusDropdown(index);
    };

    const handleMouseLeave = () => {
        setStatusDropdown(null);
    };

    const handleRowClick = (event: MouseEvent<HTMLElement>, index: number) => {
        navigate(`/tasks/tasks-detial/${index}`);
    }

    return (
        <>
            <div className="card-section">
                <Card className='border-top-none boxShadow'>
                    <Card.Body className='border-top-none activity-accordion'>
                        <Accordion defaultActiveKey="1">
                            <Accordion.Item eventKey="1">
                                <div className='d-flex justify-content-between align-items-center px-2 py-2'>
                                    <div>
                                        <h6 className='m-0'>TASKS WITHOUT DEADLINE</h6>
                                    </div>
                                    <div>
                                        <Accordion.Header>Aufgabe ohne Fälligkeit ausblenden</Accordion.Header>
                                    </div>
                                </div>
                                <Accordion.Body>
                                    <div className='accordion-content p-0'>
                                        <Table responsive hover>
                                            <tbody>
                                                {listData.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index}
                                                                onClick={(event: MouseEvent<HTMLElement>) => handleRowClick(event, index)}
                                                                onMouseEnter={() => handleMouseEnter(index)}
                                                                onMouseLeave={handleMouseLeave}
                                                                className='cursor-pointer'
                                                            >
                                                                <td>
                                                                    <div className='msg-title'>
                                                                        <BodyText mb='0px' fontSize='1.1em' fontWeight='700' textColor='#000'>{item.title}</BodyText>
                                                                    </div>
                                                                    {displayMode.displayNormal ?
                                                                        <>
                                                                            <div className='d-flex align-items-center mt-1'>
                                                                                <BadgeWidget outiline={true} title={item.badgeOutline} color='#0d3f5f' bgColor='#fff' />
                                                                                <CustomTooltip
                                                                                    ToolTipTrigger={
                                                                                        <>
                                                                                            <BodyText mb='0px' fontSize='12px' fontWeight='500' pl='5px'>{item.userName1}</BodyText>
                                                                                        </>
                                                                                    }
                                                                                    toolTipContent={item.userNameTooltipContent1}
                                                                                    toolTipPlacement='top'
                                                                                />
                                                                                <FaLongArrowAltRight className='mx-2' />
                                                                                {item.userName2 ?
                                                                                    <CustomTooltip
                                                                                        ToolTipTrigger={
                                                                                            <>
                                                                                                <BodyText mb='0px' fontSize='12px' fontWeight='500'>{item.userName2}</BodyText>
                                                                                            </>
                                                                                        }
                                                                                        toolTipContent={item.userNameTooltipContent2}
                                                                                        toolTipPlacement='top'
                                                                                    />
                                                                                    : null}
                                                                                <div className='ms-1'>
                                                                                    <CustomTooltip
                                                                                        ToolTipTrigger={<BadgeWidget solid={true} title={item.badgeRoleTitle1} color='#1e6c7b' bgColor={item.badgeRoleColor1} />}
                                                                                        toolTipContent={item.badgeRoleTooltipContent1}
                                                                                        toolTipPlacement='top'
                                                                                        contentAlign='center'
                                                                                    />
                                                                                </div>
                                                                                <div className='d-flex'>
                                                                                    {item.badgeRoleTitle2 ?
                                                                                        <>
                                                                                            <div><span className='mx-1'>,</span></div>
                                                                                            <CustomTooltip
                                                                                                ToolTipTrigger={<BadgeWidget solid={true} title={item.badgeRoleTitle2} color='#1e6c7b' bgColor={item.badgeRoleColor2} />}
                                                                                                toolTipContent={item.badgeRoleTooltipContent2}
                                                                                                toolTipPlacement='top'
                                                                                                contentAlign='center'
                                                                                            />
                                                                                        </>
                                                                                        : null}
                                                                                </div>
                                                                                <div>
                                                                                    {statusDropdown === (index) ?
                                                                                        <ChangeStatusDropdown />
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : null}
                                                                </td>
                                                                <td className="w-25">
                                                                    <div className='d-flex align-items-center justify-content-center mt-1'>
                                                                        <CustomTooltip
                                                                            ToolTipTrigger={
                                                                                <>
                                                                                    <div className='me-2'>
                                                                                        <BadgeWidget bordered={true} title={item.labelText} color={item.labelBorderColor} />
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            toolTipContent={
                                                                                <>
                                                                                    <ul className="list-unstyled">
                                                                                        {item.recipientList.map((x, i) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <li className="my-2">
                                                                                                        <DisplayBetween key={i}>
                                                                                                            <div className="d-flex me-2 align-items-center">
                                                                                                                <div className='me-2'>
                                                                                                                    <img width='20' src={x.profileImg} />
                                                                                                                </div>
                                                                                                                <div className='text-nowrap'>
                                                                                                                    <BodyText mb='0px' fontSize='13px' fontWeight='500'>{x.userName}</BodyText>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='me-2'>
                                                                                                                <BadgeWidget bordered={true} title={item.labelText} color={item.labelBorderColor} />
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <BodyText mb='0px' fontSize='12px' fontWeight='500'>{x.taskAssignTime}</BodyText>
                                                                                                            </div>
                                                                                                        </DisplayBetween>
                                                                                                    </li>
                                                                                                </>
                                                                                            )
                                                                                        })}
                                                                                    </ul>
                                                                                </>
                                                                            }
                                                                            toolTipPlacement='top'
                                                                            contentAlign='center'
                                                                            maxWidth={'450px'}
                                                                        />
                                                                        <div className='ms-1'>
                                                                            {item.comment ?
                                                                                <CustomTooltip
                                                                                    ToolTipTrigger={<><span className='gray-label'>{item.comment}</span></>}
                                                                                    toolTipContent={item.commentTooltip}
                                                                                    toolTipPlacement='top'
                                                                                />
                                                                                : null}
                                                                        </div>
                                                                        {item.limitedVisibilty ?
                                                                            <CustomTooltip
                                                                                ToolTipTrigger={
                                                                                    <>
                                                                                        <FaLock className='mx-1' size="14px" />
                                                                                    </>
                                                                                }
                                                                                toolTipContent={item.limitedVisibiltyTooltip}
                                                                                toolTipPlacement='top'
                                                                            />
                                                                            : null}
                                                                        {item.attachment ?
                                                                            <CustomTooltip
                                                                                ToolTipTrigger={
                                                                                    <>
                                                                                        <CgAttachment className='mx-1' size="14px" />
                                                                                    </>
                                                                                }
                                                                                toolTipContent={item.attachmentTooltip}
                                                                                toolTipPlacement='top'
                                                                            />
                                                                            : null}
                                                                        {item.doneIcon ?
                                                                            <CustomTooltip
                                                                                ToolTipTrigger={
                                                                                    <>
                                                                                        <FaListOl className='mx-1' size="14px" />
                                                                                    </>
                                                                                }
                                                                                toolTipContent={item.doneIconTooltip}
                                                                                toolTipPlacement='top'
                                                                            />
                                                                            : null}
                                                                    </div>
                                                                </td>
                                                                <td width='170' className="text-end">
                                                                    <DisplayEnd>
                                                                        <BodyText mb='0px' fontSize='13px' fontWeight='700'>Priorität:</BodyText>
                                                                        <BodyText mb='0px' fontSize='13px' fontWeight='700'>{item.prioritySubtitle}</BodyText>
                                                                    </DisplayEnd>
                                                                    <BodyText mb='0px' fontSize='12px' fontWeight='500'>
                                                                        <CustomTooltip
                                                                            ToolTipTrigger={<><span className='f-12px'>{item.taskCreatedTime}</span></>}
                                                                            toolTipContent={item.taskCreatedTimeTooltip}
                                                                            toolTipPlacement='top'
                                                                        />
                                                                    </BodyText>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* <Accordion.Item eventKey="2">
                                <div className='d-flex justify-content-between align-items-center px-2 py-3'>
                                    <div>
                                        <h6 className='m-0'>GESTERN</h6>
                                    </div>
                                    <div>
                                        <Accordion.Header>Aufgabe ohne Fälligkeit ausblenden</Accordion.Header>
                                    </div>
                                </div>
                                <Accordion.Body>
                                    <div className='accordion-content'>
                                        <div className='accordion-content p-0'>
                                            <Table responsive hover>
                                                <tbody>
                                                    {listData.map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                                                                    <td>
                                                                        <div className='msg-title'>
                                                                            <BodyText mb='0px' fontSize='13px' fontWeight='700'>{item.title}</BodyText>
                                                                        </div>
                                                                        {displayMode.displayNormal ?
                                                                            <>
                                                                                <div className='d-flex align-items-center mt-1'>
                                                                                    <BadgeWidget outiline={true} title={item.badgeOutline} color='#0d3f5f' bgColor='#fff' />
                                                                                    <CustomTooltip
                                                                                        ToolTipTrigger={
                                                                                            <>
                                                                                                <BodyText mb='0px' fontSize='12px' fontWeight='500' pl='5px'>{item.userName1}</BodyText>
                                                                                            </>
                                                                                        }
                                                                                        toolTipContent={item.userNameTooltipContent1}
                                                                                        toolTipPlacement='top'
                                                                                    />
                                                                                    <FaLongArrowAltRight className='mx-2' />
                                                                                    {item.userName2 ?
                                                                                        <CustomTooltip
                                                                                            ToolTipTrigger={
                                                                                                <>
                                                                                                    <BodyText mb='0px' fontSize='12px' fontWeight='500'>{item.userName2}</BodyText>
                                                                                                </>
                                                                                            }
                                                                                            toolTipContent={item.userNameTooltipContent2}
                                                                                            toolTipPlacement='top'
                                                                                        />
                                                                                        : null}
                                                                                    <div className='ms-1'>
                                                                                        <CustomTooltip
                                                                                            ToolTipTrigger={<BadgeWidget solid={true} title={item.badgeRoleTitle1} color='#1e6c7b' bgColor={item.badgeRoleColor1} />}
                                                                                            toolTipContent={item.badgeRoleTooltipContent1}
                                                                                            toolTipPlacement='top'
                                                                                            contentAlign='center'
                                                                                        />
                                                                                    </div>
                                                                                    <div className='mx-1'>
                                                                                        <span>,</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <CustomTooltip
                                                                                            ToolTipTrigger={<BadgeWidget solid={true} title={item.badgeRoleTitle2} color='#1e6c7b' bgColor={item.badgeRoleColor2} />}
                                                                                            toolTipContent={item.badgeRoleTooltipContent2}
                                                                                            toolTipPlacement='top'
                                                                                            contentAlign='center'
                                                                                        />
                                                                                    </div>
                                                                                    <div>
                                                                                        {statusDropdown === (index) ?
                                                                                            <ChangeStatusDropdown />
                                                                                            : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : null}
                                                                    </td>
                                                                    <td className="w-25">
                                                                        <div className='d-flex align-items-center justify-content-center mt-1'>
                                                                            <CustomTooltip
                                                                                ToolTipTrigger={
                                                                                    <>
                                                                                        <div className="d-flex border rounded width-fit-content me-2">
                                                                                            <StatusBadge borderColor={item.labelBorderColor} mr='6px' ml='8px' mt='3px' mb='3px'></StatusBadge>
                                                                                            <StatusBadgeTextColor textColor={item.color} mr='15px'>
                                                                                                {item.labelText}
                                                                                            </StatusBadgeTextColor>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                                toolTipContent={
                                                                                    <>
                                                                                        <ul className="list-unstyled">
                                                                                            {item.openCardText.map((x, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <li className="my-2">
                                                                                                            <DisplayBetween key={i}>
                                                                                                                <div className="d-flex me-2 align-items-center">
                                                                                                                    <div className='me-2'>
                                                                                                                        <img width='20' src={x.image} />
                                                                                                                    </div>
                                                                                                                    <div className='text-nowrap'>
                                                                                                                        <BodyText mb='0px' fontSize='13px' fontWeight='500'>{x.title}</BodyText>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="d-flex border rounded width-fit-content me-2">
                                                                                                                    <StatusBadge borderColor={item.labelBorderColor} mr='6px' ml='8px' mt='3px' mb='3px'></StatusBadge>
                                                                                                                    <StatusBadgeTextColor textColor={item.color} mr='15px'>
                                                                                                                        {item.labelText}
                                                                                                                    </StatusBadgeTextColor>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <BodyText mb='0px' fontSize='12px' fontWeight='500'>{x.dateTime}</BodyText>
                                                                                                                </div>
                                                                                                            </DisplayBetween>
                                                                                                        </li>
                                                                                                    </>
                                                                                                )
                                                                                            })}
                                                                                        </ul>
                                                                                    </>
                                                                                }
                                                                                toolTipPlacement='top'
                                                                                contentAlign='center'
                                                                                maxWidth={'450px'}
                                                                            />
                                                                            <div className='ms-1'>
                                                                                {item.comment ?
                                                                                    <CustomTooltip
                                                                                        ToolTipTrigger={<><span className='gray-label'>{item.comment}</span></>}
                                                                                        toolTipContent={item.commentTooltip}
                                                                                        toolTipPlacement='top'
                                                                                    />
                                                                                    : null}
                                                                            </div>
                                                                            {item.limitedVisibilty ?
                                                                                <CustomTooltip
                                                                                    ToolTipTrigger={
                                                                                        <>
                                                                                            <FaLock className='mx-1' size="14px" />
                                                                                        </>
                                                                                    }
                                                                                    toolTipContent={item.limitedVisibiltyTooltip}
                                                                                    toolTipPlacement='top'
                                                                                />
                                                                                : null}
                                                                            {item.attachment ?
                                                                                <CustomTooltip
                                                                                    ToolTipTrigger={
                                                                                        <>
                                                                                            <CgAttachment className='mx-1' size="14px" />
                                                                                        </>
                                                                                    }
                                                                                    toolTipContent={item.attachmentTooltip}
                                                                                    toolTipPlacement='top'
                                                                                />
                                                                                : null}
                                                                            {item.doneIcon ?
                                                                                <CustomTooltip
                                                                                    ToolTipTrigger={
                                                                                        <>
                                                                                            <FaListOl className='mx-1' size="14px" />
                                                                                        </>
                                                                                    }
                                                                                    toolTipContent={item.doneIconTooltip}
                                                                                    toolTipPlacement='top'
                                                                                />
                                                                                : null}
                                                                        </div>
                                                                    </td>
                                                                    <td width='170' className="text-end">
                                                                        <DisplayEnd>
                                                                            <BodyText mb='0px' fontSize='13px' fontWeight='700'>Priorität:</BodyText>
                                                                            <BodyText mb='0px' fontSize='13px' fontWeight='700'>{item.prioritySubtitle}</BodyText>
                                                                        </DisplayEnd>
                                                                        <BodyText mb='0px' fontSize='12px' fontWeight='500'>
                                                                            <CustomTooltip
                                                                                ToolTipTrigger={<><span className='f-12px'>{item.taskCreatedTime}</span></>}
                                                                                toolTipContent={item.taskCreatedTimeTooltip}
                                                                                toolTipPlacement='top'
                                                                            />
                                                                        </BodyText>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item> */}
                        </Accordion>
                    </Card.Body>
                </Card>
                <Box mt="15px">
                    <DisplayCenter>
                        <ButtonWidget
                            varient='light'
                            text={'Zeige spätere'}
                        />
                    </DisplayCenter>
                </Box>
            </div>
        </>
    );
}
const ChangeStatusJson = [
    {
        title: "Für alle erledigen",
    },
    {
        title: "Für alle verwerfen",
    },
    {
        title: "Erledigt markieren",
    },
    {
        title: "In Arbeit",
    },
    {
        title: "Verwerfen",
    },
]
export const ChangeStatusDropdown = () => {

    return (
        <>
            <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="Status ändern">
                {ChangeStatusJson.map((item, index) => {
                    return (
                        <>
                            <Dropdown.Item key={index}>
                                <span className='f-14px'>{item.title}</span>
                            </Dropdown.Item>
                        </>
                    )
                })}
            </DropdownButton>
        </>
    )
}