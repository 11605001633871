import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { DisplayStart, InlineBox } from "./StyledComponents";

interface formChecktype {
  type: any;
  label?: any;
  name?: any;
  Id?: any;
  value?: any;
  isInline?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  isFormGroup?: boolean;
  disabled?: any;
  checked?: boolean;
  defaultChecked?: boolean;
}

function FormCheck(props: formChecktype) {
  const {
    type,
    label,
    name,
    Id,
    value,
    isInline,
    handleChange,
    handleClick,
    isFormGroup,
    disabled,
    checked,
    defaultChecked,
  } = props;
  // const [innerValue, setInnerValue] = useState<string>('');
  // useEffect(() => {
  //     debugger
  //     setInnerValue(value);
  //     console.log(innerValue);
  //     console.log(value);
  // }, [innerValue]);
  return (
    <>
      {isFormGroup ? (
        <>
          {isInline ? (
            <Form.Group controlId={`${label}-${type}`} className="mb-3">
              <Form.Check
                inline
                label={label}
                name={name}
                id={Id}
                type={type}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                onClick={handleClick}
                checked={checked}
                defaultChecked={defaultChecked}
              />
            </Form.Group>
          ) : (
            <Form.Group controlId={`${label}-${type}`} className="mb-3">
              <Form.Check
                label={label}
                name={name}
                id={Id}
                type={type}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                onClick={handleClick}
                checked={checked}
                defaultChecked={defaultChecked}
              />
            </Form.Group>
          )}
        </>
      ) : (
        <>
          {isInline ? (
            <InlineBox key={`reverse-${type}`}>
              <Form.Check
                inline
                label={label}
                name={name}
                id={Id}
                type={type}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                onClick={handleClick}
                checked={checked}
                defaultChecked={defaultChecked}
              />
            </InlineBox>
          ) : (
            <div key={`reverse-${type}`}>
              <Form.Check
                label={label}
                name={name}
                id={Id}
                type={type}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                onClick={handleClick}
                checked={checked}
                defaultChecked={defaultChecked}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FormCheck;
