import React, { useState, Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TreeView from '../../Pages/Article/TreeView';
import { ModalHeader } from '../StyledComponents';
import ChooseCategoryModal from './ChooseTemplateCategoryModal';


interface TreeViewType{
    displayTreeModal:boolean,
    hideTreeModal:()=>void,
    manageCategoriesClick?:()=>void
}


const treeData = [
    {
        key: "0",
        label: "Documents",
        isLast: false,
        children: [
            {
                key: "0-0",
                label: "Document 1-1",
                isLast: false,
                children: [
                    {
                        key: "0-1-1",
                        label: "Document-0-1.doc",
                        isLast: false,
                        children: [
                            {
                                key: "0-0",
                                label: "Document 1-1",
                                isLast: false,
                                children: [
                                    {
                                        key: "0-1-1",
                                        label: "Document-0-1.doc",
                                        isLast: true,
                                    },
                                    {
                                        key: "0-1-2",
                                        label: "Document-0-2.doc",
                                        isLast: true,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        key: "0-1-2",
                        label: "Document-0-2.doc",
                        isLast: true,
                    },
                ],
            },
        ],
    },
    {
        key: "1",
        label: "Desktop",
        isLast: false,
        children: [
            {
                key: "1-0",
                label: "document1.doc",
                isLast: false,
                children: [
                    {
                        key: "0-0",
                        label: "Document 1-1",
                        isLast: false,
                        children: [
                            {
                                key: "0-1-1",
                                label: "Document-0-1.doc",
                                isLast: true,
                            },
                            {
                                key: "0-1-2",
                                label: "Document-0-2.doc",
                                isLast: true,
                            },
                        ],
                    },
                ],
            },
            {
                key: "0-0",
                label: "documennt-2.doc",
                isLast: true,
            },
        ],
    },
    {
        key: "2",
        label: "Downloads",
        isLast: true,
        children: [],
    },
];


const TreeViewModal = (props:TreeViewType) => {
    return (
        <>
            <Modal size="lg" show={props.displayTreeModal} onHide={props.hideTreeModal} backdrop="static" keyboard={false} className='shadow-modal' aria-labelledby="TreeModal">
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="TreeModal">
                        Kategorie auswählen
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <TreeView treeData={treeData} />
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <div>
                        <Button className='default-btn f-14px' onClick={props.manageCategoriesClick}>Kategorien verwalten</Button>
                    </div>
                    <div>
                        <Button className='default-btn f-14px me-1' onClick={props.hideTreeModal} >Abbrechen</Button>
                        <Button className='primary-bg f-14px'>Auswählen</Button>
                    </div>
                </Modal.Footer>
            </Modal>


        </>
    )
}
export default TreeViewModal;
