import * as React from 'react';
import { useState, useEffect } from 'react';
import { filtesListType } from '../../../../Pages/mycalendar/left/SaveCurrentFilter/SaveFilter';
import { BoxTitle, ColorCheckboxTemplate } from '../../../../Pages/mycalendar/left/SaveCurrentFilter/PraxisCheckboxList';



interface Iprops {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}


const SubscribedCalendarContent: React.FC<Iprops> = ({ closeModal }) => {

    return (
        <div>
            <div className="form-group form-inline">
                <div className="col-sm-2">
                </div>
                <div className="col-sm-5">
                    <span className="ng-binding">Already subscribed calendars</span>
                </div>
                <div className="col-sm-5">
                    <span className="ng-binding">Not subscribed calendars</span>
                </div>
            </div>
            <div className="form-group form-inline">
                <div className="col-sm-2">
                    <BoxTitle>Praxis Gallenbach & Zähne </BoxTitle>
                </div>
                <div className="col-sm-5">
                    <ColorCheckboxTemplate />
                </div>
                <div className="col-sm-5">
                </div>
            </div>
        </div>
    );
}

export default SubscribedCalendarContent;