import React, { FC } from "react";
import Form from "react-bootstrap/Form";
import { Control, FieldError, Controller } from "react-hook-form";
import { IInputProps } from "../Interface/IInputProps";

type objType = {
  label: string;
  value: string;
};

interface IProps extends IInputProps {
  placeholder?: string;
  fieldName: string;
  fieldId?: string;
  size?: any;
  control: Control<any, any>;
  error?: FieldError;
  cssClass?: any;
  selectOptions: objType[];
}

export const SelectControl: FC<IProps> = ({
  required,
  min,
  minLength,
  max,
  maxLength,
  placeholder,
  fieldName,
  fieldId,
  size,
  control,
  error,
  cssClass,
  selectOptions,
}) => {
  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        rules={{ required, min, minLength, max, maxLength }}
        render={({ field: { name, onBlur, onChange, ref, value } }) => (
          <Form.Select
            name={name}
            id={fieldId}
            ref={ref}
            onChange={onChange}
            isInvalid={!!error}
            onBlur={onBlur}
            value={value}
            className={cssClass}
            size={size}
            placeholder={placeholder}
          >
            {selectOptions.map((item: any, index: number) => {
              return (
                <>
                  <option key={`${item.label}-${index}`} value={item.value}>
                    {item.label}
                  </option>
                </>
              );
            })}
          </Form.Select>
        )}
      />
      {error && (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  );
};
