import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HiOutlineClock } from "react-icons/hi";
import { DateBox, DateIcon } from "./StyledComponents";

interface type {
  disabled?: boolean;
}

function TimePicker(props: type) {
  const [startDate, setStartDate] = useState<any>(new Date());
  return (
    <>
      {/* <div className='position-relative'> */}
      <DateBox>
        <DatePicker
          className="form-control form-control-sm"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={5}
          timeCaption="Time"
          dateFormat="h:mm aa"
          disabled={props.disabled}
        />
        <DateIcon>
          <HiOutlineClock size={15} />
        </DateIcon>
      </DateBox>

      {/* <div className='position-absolute icon-time-picker text-center' onClick={() => TimerIconClick()}>
                    <HiOutlineClock size="20px" />
                </div>
            </div> */}
    </>
  );
}

export default TimePicker;
