import * as React from 'react';
import { BodyText, Box } from '../../StyledComponents';
import FormSelect from '../../FormSelect';
import { reportTypeOptions } from '../../../CommonJSON';


export const ReportUserModalContent = () => {
    return (
        <>
            <Box className='p-1'>
                <BodyText fontSize='15px' fontWeight='700' textColor='#666' mb="10px">
                    Report type
                </BodyText>
                <div>
                    <FormSelect
                        isFormGroup={true}
                        selectOptions={reportTypeOptions}
                        Size='sm'
                        Name='salute'
                        Id='salute'
                    />
                </div>
            </Box>
        </>
    )
}