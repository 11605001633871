import React, { useState, useEffect } from 'react';
import { BsCaretRight, BsCaretDown } from "react-icons/bs";
import { TbPlaystationCircle } from "react-icons/tb";
import { VscCircleLargeFilled, VscCircleFilled } from "react-icons/vsc";

type dataType = {
    id: number,
    label: string,
    isActive: boolean,
    parentId?: number,
    children?: dataType[],
}

interface propsTree {
    treeData: dataType[],
    getNode?: (item: dataType) => void
}

const TreeNode = (props: { id: number, parentId?: number, label: string, isOpen: boolean, children: any, onClick: () => void }) => {
    const { onClick } = props;
    // const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggle = () => {
        // setIsOpen(!isOpen);
        onClick();
    };

    return (
        <li>
            <span style={{ cursor: 'pointer' }} className={props.isOpen && !props.children ? 'd-flex align-items-center active-tree-node' : 'd-flex align-items-center'} onClick={() => { handleToggle(); }}>
                {props.isOpen ?
                    <>
                        {!!props.children ?
                            <span className='me-1'>
                                <BsCaretDown />
                            </span>
                            :
                            null
                        }
                    </>
                    :
                    <>
                        {!!props.children ?
                            <span className='me-1'>
                                <BsCaretRight />
                            </span>
                            :
                            null
                        }
                    </>

                }

                <span className='d-flex align-items-center'>
                    {!props.children && !props.parentId ? <span className='d-inline-flex ms-2 me-1'><VscCircleFilled size={12} /></span> : null}
                    <span>{props.label}</span>
                </span>
            </span>
            {props.isOpen && !!props.children ? <ul>{props.children}</ul> : null}
        </li>
    );
};
/*{ data: dataType[], getNode?: (item: dataType) => void } */
const RenderTreeNodes: React.FC<{ data: dataType[], getNode?: (item: dataType) => void }> = (props) => {
    return (
        <>
            {
                props.data.map((node) => (
                    <TreeNode id={node.id} parentId={node.parentId} label={node.label} isOpen={node.isActive} onClick={() => props.getNode?.(node)}>
                        {node.children && <RenderTreeNodes data={node.children} getNode={props.getNode} />}
                        {/* {node.children && renderTreeNodes(node.children,props.getNode?.(item))} */}
                        {/* {node.children && renderTreeNodes(node.children,props.getNode?.(item))} */}
                    </TreeNode>
                ))
            }
        </>
    )
};

const TreeView = (props: { jsonData: dataType[], getNode?: (item: dataType) => void }) => {
    // const treeNodes = RenderTreeNodes(props.jsonData, props.getNode?.(item));
    const { jsonData, getNode } = props;
    return (
        <ul className='tree'>
            {/* {treeNodes} */}
            <RenderTreeNodes data={jsonData} getNode={getNode} />
        </ul>
    );
};


function NewTreeView(props: propsTree) {

    return (
        <TreeView jsonData={props.treeData} getNode={props.getNode} />
    );
}

export default NewTreeView;



