import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, } from 'react-bootstrap';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import { ModalHeader } from '../StyledComponents';


interface categoryTemplateType{
    displayCategoriesTemplateModal:boolean,
    hideCategoriesTemplateModal:()=>void,
    modalTitle:string
}



function ManageTemplateCategoryModal(props:categoryTemplateType) {
    const [EnableInput, setEnableInput] = useState<boolean>(false);
    const [ShowTemplate, setShowTemplate] = useState<boolean>(false);
    return (
        <>
            <Modal size="lg" className='shadow-modal' show={props.displayCategoriesTemplateModal} onHide={props.hideCategoriesTemplateModal} backdrop="static" keyboard={false} aria-labelledby="template-modal" >
                <ModalHeader closeButton bgColor='#d8d8d8'>
                    <Modal.Title id="template-modal">
                        {props.modalTitle}
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body className='pt-0'>
                    <Row>
                        <Col md={3} className='pt-4 border-end border-bottom'>
                            <div onClick={() => setShowTemplate(!ShowTemplate)}>
                                {ShowTemplate === true ? <span><FaCaretDown /></span> : <span><FaCaretRight /></span>}
                                <span className='chain'> Praxis Gallenbach & Zähne </span>
                            </div>
                        </Col>
                        <Col md={9} className="pt-4 border-bottom px-0">
                            <div className={`text-center pb-4 ${ShowTemplate === false ? "d-block" : "d-none"}`}>
                                <span>Bitte Kategorie auswählen...</span>
                            </div>
                            <div className={`${ShowTemplate === true ? "d-block" : "d-none"}`}>
                                <div className='type-edit-practice'>
                                    <div><h6 className='ps-3'>Typ Praxis Gallenbach & Zähne bearbeiten</h6></div>
                                    <div className='mb-3'>
                                        <Button onClick={() => setEnableInput(!EnableInput)} className='default-btn f-14px me-2' >Untertyp hinzufügen</Button>
                                    </div>
                                </div>
                                <div className='border-top'>
                                    <div className='row py-3 px-3'>
                                        <div className='col-md-4'>
                                            <span>Name:</span>
                                        </div>
                                        <div className='col-md-8'>
                                            {EnableInput === false ? <span>Praxis Gallenbach & Zähne</span> : <input type="text" className='form-control' />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.hideCategoriesTemplateModal} className='default-btn f-14px me-2' >Schließen</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ManageTemplateCategoryModal;