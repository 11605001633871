import React, { useState, useEffect, FC } from 'react';
import { Button, DropdownButton } from "react-bootstrap"
import CustomDropdown from "../Components/Dropdown"
import { BodyText, CustomDropDownItem, DisplayBetween, DisplayStart, MultiColorBox, StatusBadge, StatusBadgeTextColor } from "../Components/StyledComponents";
import { HiCog } from 'react-icons/hi';
import Dropdown from 'react-bootstrap/Dropdown';
import FormCheck from "../Components/FormCheck";
import CommonModal from '../Components/Modals/CommonModal';
import { CurrentFiltersModalContent } from './Modals/ModelContents/CurrentFiltersModalContent';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaBars, FaChartBar, FaList, FaPrint, FaRegChartBar, FaThList } from 'react-icons/fa';
import { BiChevronLeftCircle } from "react-icons/bi";
import { PdfSettingsModalContent } from './Modals/ModelContents/PdfSettingsModalContent';
import CustomTooltip from './Tooltip';
import { ViewReportsModalContent } from './Modals/ModelContents/ViewReportsModalContent';
import { TdisplayMode } from '../Pages/Message/Index';
import BadgeWidget from './BadgeWidget';


const AllIdeaDropdown = [
    {
        title: "Ideen in Diskussion",
        color: "#fbd300",
    },
    {
        title: "Ideen in Umsetzung",
        color: "#077ce0",
    },
    {
        title: "Ideen für später",
        color: "#fc9136",
    },
    {
        title: "Verworfene Ideen",
        color: "#ed6162",
    },
    {
        title: "Umgesetzte Ideen",
        color: "#80c35c",
    },
]

const SortingDropdown = [
    {
        title: "Status",
    },
    {
        title: "Älteste zuerst",
    },
    {
        title: "Neueste zuerst",
    },
    {
        title: "Letzte Aktion",
    },
    {
        title: "Meiste Kommentare",
    },
]
const SortingDropdownHandbook = [
    {
        title: "Älteste zuerst",
    },
    {
        title: "Neueste zuerst",
    },
    {
        title: "Alphabetisch A-Z",
    },
    {
        title: "Alphabetisch Z-A",
    },
]

const AllTypeDropdownTask = [
    {
        title: "Alle Typen",
    },
    {
        title: "Aufgaben ohne Fälligkeit",
    },
    {
        title: "Aufgaben mit Fälligkeit",
    },
    {
        title: "Aufgaben mit Checkliste",
    },
    {
        title: "Aufgaben",
    },
]

const sortingPriorityDropdown = [
    {
        title: "Älteste zuerst",
    },
    {
        title: "Neueste zuerst",
    },
    {
        title: "Nach Priorität",
    },
    {
        title: "Nach Priorität, ältere zuerst",
    },
    {
        title: "Nach Ort",
    },
    {
        title: "Neueste Statusänderung",
    },
]

const StatusDropdownTask = [
    {
        color: "#5cb85b",
        labelText: 'Erledigt',
        labelBorderColor: '#5cb85b',
    },
    {
        color: "#888",
        labelText: 'Nicht angenommen',
        labelBorderColor: '#888',
    },
    {
        color: "#fc9136",
        labelText: 'Offen',
        labelBorderColor: '#fc9136',
    },
    {
        color: "#04a0a0",
        labelText: 'In Arbeit',
        labelBorderColor: '#04a0a0',
    },
    {
        color: "#077ce0",
        labelText: 'In Kontrolle',
        labelBorderColor: '#077ce0',
    },
    {
        color: "#f66",
        labelText: 'Verworfen',
        labelBorderColor: '#f66',
    },
    {
        color: "#e84dbc",
        labelText: 'Abgelaufen',
        labelBorderColor: '#e84dbc',
    },
]


interface Iprops {
    showHideEntryCheck?: () => void,
    showChecks?: boolean,
    handleSelectAll?: () => void,
    checkAllEntries?: boolean,
    displaySplit?: boolean,

    displayMode?: TdisplayMode,
    handleDisplayMode?: (event: React.MouseEvent) => void,


    displayNormal?: boolean,
    displayCompact?: boolean,
    handleDisplayNormal?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleDisplayCompact?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleDisplayNormalRepair?: () => void,
    handleDisplayCompactRepair?: () => void,
    handleDisplaySplitRepair?: () => void,
    totalAccount?: number,
    pageName?: string,
}

export const TopbarSelectFilterDropdown: FC<Iprops> = (props) => {

    const location = useLocation();
    // const history = useHistory();

    const [currentFilter, setCurrentFilter] = useState<boolean>(false);
    const [printPdfSetting, setPrintPdfSetting] = useState<boolean>(false);
    const [viewReportModal, setViewReportModal] = useState<boolean>(false);


    const { showHideEntryCheck, handleSelectAll, checkAllEntries, showChecks, totalAccount, pageName, displayMode, handleDisplayMode, displaySplit, displayNormal, displayCompact, handleDisplayNormal, handleDisplayCompact, handleDisplayCompactRepair, handleDisplayNormalRepair, handleDisplaySplitRepair } = props;

    const navigate = useNavigate();



    return (
        <>

            {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "messages-detial" || location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "messages" ?
                <div className="d-flex align-items-center justify-content-between test">
                    <div className="d-flex">
                        {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) !== "messages" ?
                            <Button className='btn-primary btn-sm primary-bg f-12px lh-12px me-2' onClick={() => { navigate(-1) }}> <BiChevronLeftCircle size="20px" /> </Button>
                            :
                            null
                        }
                        <DropdownButton size="sm" className="template-sort f-12px me-1" id="new-item" title="Alle Nachrichten">
                            <Dropdown.Item>
                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Alle Nachrichten </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Ungelesene </BodyText>
                            </Dropdown.Item>
                        </DropdownButton>
                        <div className="ps-3">
                            <Button className='default-btn f-12px' onClick={showHideEntryCheck}>
                                {totalAccount ?
                                    <>
                                        <span className='me-1'>{totalAccount > 0 ? totalAccount : null}</span>
                                        <span>Einträge auswählen</span>
                                    </>
                                    :
                                    <>
                                        <span>Einträge auswählen</span>
                                    </>
                                }

                            </Button>
                            {showChecks ?
                                <>
                                    <Button className='default-btn f-12px mx-1' onClick={handleSelectAll}>Alle auswählen</Button>
                                    <Button className='default-btn f-12px'>Ungelesen markieren</Button>
                                </>
                                : null
                            }
                        </div>
                    </div>
                    <div className="d-flex">
                        <DropdownButton size="sm" className="template-sort f-12px" id="new-item" title="Sortierung: letzte Aktion">
                            {SortingDropdown.map((item, index) => {
                                return (
                                    <>
                                        <Dropdown.Item key={index}>
                                            <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                        </Dropdown.Item>
                                    </>
                                )
                            })}
                        </DropdownButton>
                        {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) !== "messages-detial" ?
                            <>
                                <DropdownButton size="sm" className="template-sort display-mode f-12px ms-3" id="new-item" title={<><HiCog size="18px" /></>}>
                                    <Dropdown.Item>
                                        <span className='f-14px pe-none'> Anzeigemodus: </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item value='normal'
                                        onClick={(event: React.MouseEvent) => { handleDisplayMode?.(event) }}
                                        className={displayMode?.displayNormal ? 'dropdown-item-active' : ''}>
                                        <FormCheck
                                            type='checkbox'
                                            name='displayMode'
                                            Id='normal'
                                            value='Normal'
                                            label='Normal'
                                            isFormGroup={false}
                                            isInline={true}
                                            disabled={false}
                                            checked={displayMode?.displayNormal}
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item value='compact'
                                        onClick={(event: React.MouseEvent) => { handleDisplayMode?.(event) }}
                                        className={displayMode?.displayCompact ? 'dropdown-item-active' : ''}>
                                        <FormCheck
                                            type='checkbox'
                                            name='displayMode'
                                            Id='compact'
                                            value='compact'
                                            label='Kompakt'
                                            isFormGroup={false}
                                            isInline={true}
                                            disabled={false}
                                            checked={displayMode?.displayCompact}
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => setCurrentFilter(true)}>
                                        <BodyText fontSize='14px' fontWeight='600'> Aktuelle Filter speichern </BodyText>
                                    </Dropdown.Item>
                                </DropdownButton>

                            </>
                            :
                            null
                        }
                    </div>

                </div>
                :
                null
            }
            {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "news-detial" || location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "news" ?
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                        {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) !== "news" ?
                            <Button className='btn-primary btn-sm primary-bg f-12px lh-12px me-2' onClick={() => { navigate(-1) }}> <BiChevronLeftCircle size="20px" /> </Button>
                            :
                            null
                        }
                        <DropdownButton size="sm" className="template-sort f-12px me-1" id="new-item" title="Alle News">
                            <Dropdown.Item>
                                <span className='f-14px'> Alle News </span>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <span className='f-14px'> Ungelesene </span>
                            </Dropdown.Item>
                        </DropdownButton>
                        <div className="ps-3">
                            <Button className='default-btn f-12px' onClick={showHideEntryCheck}>
                                {totalAccount ?
                                    <>
                                        <span className='me-1'>{totalAccount > 0 ? totalAccount : null}</span>
                                        <span>Einträge auswählen</span>
                                    </>
                                    :
                                    <span>Einträge auswählen</span>
                                }

                            </Button>
                            {showChecks ?
                                <>
                                    <Button className='default-btn f-12px mx-1' onClick={handleSelectAll}>Alle auswählen</Button>
                                    <Button className='default-btn f-12px'>Ungelesen markieren</Button>
                                </>
                                : null
                            }
                        </div>
                    </div>
                    <div className="d-flex">
                        <DropdownButton size="sm" className="template-sort f-12px" id="new-item" title="Sortierung: letzte Aktion">
                            {SortingDropdown.map((item, index) => {
                                return (
                                    <>
                                        <Dropdown.Item key={index}>
                                            <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                        </Dropdown.Item>
                                    </>
                                )
                            })}
                        </DropdownButton>
                        {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) !== "news-detial" ?
                            <DropdownButton size="sm" className="template-sort display-mode f-12px ms-3" id="new-item" title={<><HiCog size="18px" /></>}>
                                <Dropdown.ItemText>
                                    <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Anzeigemodus: </BodyText>
                                </Dropdown.ItemText>
                                <Dropdown.Item value='normal'
                                    onClick={(event: React.MouseEvent) => { handleDisplayMode?.(event) }}
                                    className={displayMode?.displayNormal ? 'dropdown-item-active' : ''}>
                                    <FormCheck
                                        type='checkbox'
                                        name='displayMode'
                                        Id='normal'
                                        value='Normal'
                                        label='Normal'
                                        isFormGroup={false}
                                        isInline={true}
                                        disabled={false}
                                        checked={displayMode?.displayNormal}
                                    />
                                </Dropdown.Item>
                                <Dropdown.Item value='compact'
                                    onClick={(event: React.MouseEvent) => { handleDisplayMode?.(event) }}
                                    className={displayMode?.displayCompact ? 'dropdown-item-active' : ''}>
                                    <FormCheck
                                        type='checkbox'
                                        name='displayMode'
                                        Id='compact'
                                        value='compact'
                                        label='Kompakt'
                                        isFormGroup={false}
                                        isInline={true}
                                        disabled={false}
                                        checked={displayMode?.displayCompact}
                                    />
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={() => setCurrentFilter(true)}>
                                    <BodyText fontSize='14px' fontWeight='600'> Aktuelle Filter speichern </BodyText>
                                </Dropdown.Item>
                            </DropdownButton>
                            : null}
                    </div>
                </div>
                :
                null
            }
            {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "ideas-detial" || location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "ideas" ?
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                        {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) !== "ideas" ?
                            <Button className='btn-primary btn-sm primary-bg f-12px lh-12px me-2' onClick={() => { navigate(-1) }}> <BiChevronLeftCircle size="20px" /> </Button>
                            :
                            null
                        }
                        <DropdownButton size="sm" className="template-sort f-12px me-1" id="new-item" title="Alle Ideen">
                            <Dropdown.Item >
                                <span className='f-14px'>Alle Ideen</span>
                            </Dropdown.Item>
                            {AllIdeaDropdown.map((item, index) => {
                                return (
                                    <>
                                        <Dropdown.Item key={index}>
                                            <div className="d-flex align-items-center">
                                                <MultiColorBox bgcolor={item.color} mr='10px'></MultiColorBox>
                                                <span className='f-14px'>{item.title}</span>
                                            </div>
                                        </Dropdown.Item>
                                    </>
                                )
                            })}
                        </DropdownButton>
                        <div className="ps-3">
                            <Button className='default-btn f-12px' onClick={showHideEntryCheck}>
                                {totalAccount ?
                                    <>
                                        <span className='me-1'>{totalAccount > 0 ? totalAccount : null}</span>
                                        <span>Einträge auswählen</span>
                                    </>
                                    :
                                    <span>Einträge auswählen</span>
                                }
                            </Button>
                            {showChecks ?
                                <>
                                    <Button className='default-btn f-12px mx-1' onClick={handleSelectAll}>Alle auswählen</Button>
                                    <Button className='default-btn f-12px'>Ungelesen markieren</Button>
                                </>
                                : null
                            }
                        </div>
                    </div>
                    <div className="d-flex">
                        <DropdownButton size="sm" className="template-sort f-12px" id="new-item" title="Sortierung: letzte Aktion">
                            {SortingDropdown.map((item, index) => {
                                return (
                                    <>
                                        <Dropdown.Item key={index}>
                                            <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                        </Dropdown.Item>
                                    </>
                                )
                            })}
                        </DropdownButton>
                        {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) !== "ideas-detial" ?
                            <>
                                <DropdownButton size="sm" className="template-sort display-mode f-12px ms-3" id="new-item" title={<><HiCog size="18px" /></>}>
                                    <Dropdown.Item>
                                        <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Anzeigemodus: </BodyText>
                                    </Dropdown.Item>
                                    <Dropdown.Item value='normal'
                                        onClick={(event: React.MouseEvent) => { handleDisplayMode?.(event) }}
                                        className={displayMode?.displayNormal ? 'dropdown-item-active' : ''}>
                                        <FormCheck
                                            type='checkbox'
                                            name='displayMode'
                                            Id='normal'
                                            value='Normal'
                                            label='Normal'
                                            isFormGroup={false}
                                            isInline={true}
                                            disabled={false}
                                            checked={displayMode?.displayNormal}
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item value='compact'
                                        onClick={(event: React.MouseEvent) => { handleDisplayMode?.(event) }}
                                        className={displayMode?.displayCompact ? 'dropdown-item-active' : ''}>
                                        <FormCheck
                                            type='checkbox'
                                            name='displayMode'
                                            Id='compact'
                                            value='compact'
                                            label='Kompakt'
                                            isFormGroup={false}
                                            isInline={true}
                                            disabled={false}
                                            checked={displayMode?.displayCompact}
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => setCurrentFilter(true)}>
                                        <BodyText fontSize='14px' fontWeight='600'> Aktuelle Filter speichern </BodyText>
                                    </Dropdown.Item>
                                </DropdownButton>
                            </>

                            : null}
                    </div>
                </div>
                :
                null
            }

            {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "handbooks-detial"
                || location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "handbooks"
                || location.pathname.substring(location.pathname.lastIndexOf('/') + 1) == "handbooks-detial-id" ?
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) !== "handbooks" ?
                            <Button className='btn-primary btn-sm primary-bg f-12px lh-12px me-2' onClick={() => { navigate(-1) }}> <BiChevronLeftCircle size="20px" /> </Button>
                            :
                            null
                        }
                        <Link to="" className='text-decoration-none'>
                            <span className='fw-700 w-176px text-truncate text-link-color f-14px'> Praxis Gallenbach & Zähne </span>
                        </Link>
                    </div>
                    <div className="d-flex">
                        <Button className='default-btn f-12px' onClick={showHideEntryCheck}>
                            {totalAccount ?
                                <>
                                    <span className='me-1 text-nowrap'>{totalAccount > 0 ? totalAccount : null}</span>
                                    <span>Einträge auswählen</span>
                                </>
                                :
                                <span>Einträge auswählen</span>
                            }
                        </Button>
                        {showChecks ?
                            <>
                                <Button className='default-btn f-12px mx-1' onClick={handleSelectAll}>Alle auswählen</Button>
                                <Button className='default-btn f-12px'>Ungelesen markieren</Button>
                            </>
                            : null
                        }
                        <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="Artikel zuerst">
                            <Dropdown.Item>
                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Artikel zuerst </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Kategorien zuerst </BodyText>
                            </Dropdown.Item>
                        </DropdownButton>
                        <DropdownButton size="sm" className="template-sort f-12px mx-1" id="new-item" title="Sortierung: alphabetisch A-Z">
                            {SortingDropdownHandbook.map((item, index) => {
                                return (
                                    <>
                                        <Dropdown.Item key={index}>
                                            <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                        </Dropdown.Item>
                                    </>
                                )
                            })}
                        </DropdownButton>
                        <DropdownButton size="sm" className="template-sort f-12px" id="new-item" title={<><HiCog size="18px" /></>}>
                            <Dropdown.Item onClick={() => setCurrentFilter(true)}>
                                <BodyText fontSize='14px' fontWeight='600' > Aktuelle Filter speichern </BodyText>
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
                :
                null
            }
            {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "tasks" || location.pathname.includes('tasks-detial') === true ?
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                        {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) !== "tasks" ?
                            <Button className='btn-primary btn-sm primary-bg f-12px lh-12px me-2' onClick={() => { navigate(-1) }}> <BiChevronLeftCircle size="20px" /> </Button>
                            :
                            null
                        }
                        <DropdownButton size="sm" className="template-sort f-12px me-1" id="new-item" title="Status: Nicht abgeschlossen">
                            <Dropdown.Item>
                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Nicht abgeschlossen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Jeder Status </BodyText>
                            </Dropdown.Item>
                            {StatusDropdownTask.map((item, index) => {
                                return (
                                    <>
                                        <Dropdown.Item key={item.labelText + "-" + index}>
                                            <BadgeWidget bordered={true} title={item.labelText} color={item.labelBorderColor} />
                                        </Dropdown.Item>
                                    </>
                                )
                            })}
                        </DropdownButton>
                        <div className="ps-1">
                            <DropdownButton size="sm" className="template-sort f-12px me-3" id="new-item" title="Type: All types">
                                {AllTypeDropdownTask.map((item, index) => {
                                    return (
                                        <>
                                            <Dropdown.Item key={index}>
                                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title}</BodyText>
                                            </Dropdown.Item>
                                        </>
                                    )
                                })}
                            </DropdownButton>
                        </div>
                    </div>
                    <div className="d-flex">
                        <span className='icon-bg-rounded notification me-2 border rounded-1 border-dark bg-white' onClick={() => setPrintPdfSetting(true)}>
                            <FaPrint size="15px" />
                        </span>
                        <DropdownButton size="sm" className="template-sort f-12px" id="new-item" title={<><HiCog size="18px" /></>}>
                            <Dropdown.ItemText>
                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Anzeigemodus: </BodyText>
                            </Dropdown.ItemText>
                            <Dropdown.Item value='normal'
                                onClick={(event: React.MouseEvent) => { handleDisplayMode?.(event) }}
                                className={displayMode?.displayNormal ? 'dropdown-item-active' : ''}>
                                <FormCheck
                                    type='checkbox'
                                    name='displayMode'
                                    Id='normal'
                                    value='Normal'
                                    label='Normal'
                                    isFormGroup={false}
                                    isInline={true}
                                    disabled={false}
                                    checked={displayMode?.displayNormal}
                                />
                            </Dropdown.Item>
                            <Dropdown.Item value='compact'
                                onClick={(event: React.MouseEvent) => { handleDisplayMode?.(event) }}
                                className={displayMode?.displayCompact ? 'dropdown-item-active' : ''}>
                                <FormCheck
                                    type='checkbox'
                                    name='displayMode'
                                    Id='compact'
                                    value='compact'
                                    label='Kompakt'
                                    isFormGroup={false}
                                    isInline={true}
                                    disabled={false}
                                    checked={displayMode?.displayCompact}
                                />
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => setCurrentFilter(true)}>
                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Aktuelle Filter speichern </BodyText>
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
                :
                null
            }

            {location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === "repair" ?
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                        <DropdownButton size="sm" className="template-sort f-12px me-1" id="new-item"
                            title={<BodyText fontSize='14px' fontWeight='400' textColor='#333'> Status: Nicht abgeschlossen</BodyText>}>
                            <Dropdown.Item>
                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Nicht abgeschlossen </BodyText>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> Jeder Status </BodyText>
                            </Dropdown.Item>
                            {StatusDropdownTask.map((item, index) => {
                                return (
                                    <>
                                        <Dropdown.Item key={index}>
                                            <div className="d-flex border rounded width-fit-content">
                                                <StatusBadge borderColor={item.labelBorderColor} mr='6px' ml='8px' mt='3px' mb='3px'></StatusBadge>
                                                <StatusBadgeTextColor textColor={item.color} mr='15px'>
                                                    {item.labelText}
                                                </StatusBadgeTextColor>
                                            </div>
                                        </Dropdown.Item>
                                    </>
                                )
                            })}
                        </DropdownButton>
                    </div>
                    <div className="d-flex">
                        <CustomTooltip
                            ToolTipTrigger={
                                <>
                                    <span className='icon-bg-rounded notification me-2 border rounded-1 border-dark bg-white' onClick={() => setViewReportModal(true)}>
                                        <FaChartBar size="15px" />
                                    </span>
                                </>
                            }
                            toolTipContent='Berichte anzeigen'
                            toolTipPlacement='top'
                        />
                        <span className='icon-bg-rounded notification me-2 border rounded-1 border-dark bg-white' onClick={() => setPrintPdfSetting(true)}>
                            <FaPrint size="15px" />
                        </span>
                        <div className="ps-1">
                            <DropdownButton size="sm" className="template-sort f-12px me-3" id="new-item"
                                title={<BodyText fontSize='14px' fontWeight='400' textColor='#333'> Sortierung: Nach Priorität </BodyText>}>
                                {sortingPriorityDropdown.map((item, index) => {
                                    return (
                                        <>
                                            <Dropdown.Item key={index}>
                                                <BodyText fontSize='14px' fontWeight='400' textColor='#333'> {item.title} </BodyText>
                                            </Dropdown.Item>
                                        </>
                                    )
                                })}
                            </DropdownButton>
                        </div>
                        <DropdownButton size="sm" className="template-sort" id="new-item" title={<><HiCog size="18px" /></>}>
                            <Dropdown.ItemText>
                                <BodyText fontSize='15px' fontWeight='600'> Display options </BodyText>
                            </Dropdown.ItemText>
                            <Dropdown.Divider />
                            <Dropdown.Item value='split'
                                onClick={(event: React.MouseEvent) => { handleDisplayMode?.(event) }}
                                className={displayMode?.displaySplit ? 'dropdown-item-active' : ''}>
                                <DisplayStart>
                                    <div className='me-3'>
                                        <FaThList size={20} />
                                    </div>
                                    <div> {displaySplit}
                                        <BodyText fontSize='15px' fontWeight='600' textColor='#000'> Geteiltes Display </BodyText>
                                        <BodyText fontSize='13px' fontWeight='400'> Standard  </BodyText>
                                    </div>
                                </DisplayStart>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item value='normal'
                                onClick={(event: React.MouseEvent) => { handleDisplayMode?.(event) }}
                                className={displayMode?.displayNormal ? 'dropdown-item-active' : ''}>
                                <DisplayStart>
                                    <div className='me-3'>
                                        <FaBars size={20} />
                                    </div>
                                    <div> {displayNormal}
                                        <BodyText fontSize='15px' fontWeight='600' textColor='#000'> Normales Display </BodyText>
                                        <BodyText fontSize='13px' fontWeight='400'> Mehr Details </BodyText>
                                    </div>
                                </DisplayStart>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item value='compact'
                                onClick={(event: React.MouseEvent) => { handleDisplayMode?.(event) }}
                                className={displayMode?.displayCompact ? 'dropdown-item-active' : ''}>
                                <DisplayStart>
                                    <div className='me-3'>
                                        <FaList size={20} />
                                    </div>
                                    <div> {displayCompact}
                                        <BodyText fontSize='15px' fontWeight='600' textColor='#000'> Kompaktes Display</BodyText>
                                        <BodyText fontSize='13px' fontWeight='400'>Weniger Details</BodyText>
                                    </div>
                                </DisplayStart>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => setCurrentFilter(true)}>
                                <BodyText fontSize='14px' fontWeight='600'> Aktuelle Filter speichern </BodyText>
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
                :
                null
            }




            <CommonModal
                showModal={currentFilter}
                hideModal={() => setCurrentFilter(false)}
                modalTitle='Aktuelle Filter speichern'
                modalContent={<CurrentFiltersModalContent />}
                modalSize='md'
            />

            <CommonModal
                showModal={printPdfSetting}
                hideModal={() => setPrintPdfSetting(false)}
                modalTitle='PDF-Einstellungen'
                modalContent={<PdfSettingsModalContent />}
                modalSize='md'
            />

            <CommonModal
                showModal={viewReportModal}
                hideModal={() => setViewReportModal(false)}
                modalTitle='Berichte'
                modalContent={<ViewReportsModalContent />}
                modalSize='xl'
            />

        </>
    )
}