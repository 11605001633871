import React from "react";
import Form from "react-bootstrap/Form";
import {
  Control,
  FieldError,
  UseFormRegister,
  Controller,
} from "react-hook-form";
import { IInputProps } from "./Widgets/Interface/IInputProps";
interface IProps extends IInputProps {
  Type?: string;
  Placeholder?: string;
  Name: string;
  Id?: string;
  rows?: number;
  Size?: any;
  isFormGroup?: boolean;
  Value?: string;
  register?: UseFormRegister<any>;
  control?: Control<any, any>;
  error?: FieldError;
  handleChange?: (event: any, value?: any) => void;
  Class?: any;
}
function FormControl({
  required,
  min,
  minLength,
  max,
  maxLength,
  Class,
  Type,
  Placeholder,
  Name,
  Id,
  rows,
  Size,
  isFormGroup,
  handleChange,
  Value,
  register,
  control,
  error,
}: IProps) {
  //   const { ref, ...registerField } = register
  //     ? register(Name, {
  //         required,
  //         min,
  //         minLength,
  //         max,
  //         maxLength,
  //       })
  //     : ({} as any);

  return (
    // <>
    //   <Controller
    //     name={Name}
    //     control={control}
    //     rules={{ required, min, minLength, max, maxLength }}
    //     render={({ field: { name, onBlur, onChange, ref, value } }) => (
    //       <Form.Control
    //         name={name}
    //         onBlur={onBlur}
    //         value={value}
    //         className={Class}
    //         size={Size}
    //         type={Type}
    //         placeholder={Placeholder}
    //         ref={ref}
    //         onChange={onChange}
    //         isInvalid={!!error}
    //       />
    //     )}
    //   />

    //   {error && (
    //     <Form.Control.Feedback type="invalid">
    //       {error.message}
    //     </Form.Control.Feedback>
    //   )}
    // </>

    <>
      {Type === "textarea" ? (
        <>
          {isFormGroup ? (
            <Form.Group className="mb-3" controlId={Id}>
              <Form.Control name={Name} value={Value} as={Type} className={Class} placeholder={Placeholder} rows={rows} onChange={handleChange} />
            </Form.Group>
          ) : (
            <>
              <Form.Control name={Name} value={Value} as={Type} className={Class} placeholder={Placeholder} rows={rows} onChange={handleChange} />
            </>
          )}
        </>
      ) : (
        <>
          {isFormGroup ? (
            <Form.Group className="mb-3" controlId={Id}>
              <Form.Control name={Name} value={Value} className={Class} size={Size} type={Type} placeholder={Placeholder} onChange={handleChange} />
            </Form.Group>
          ) : (
            <>
              <Form.Control name={Name} value={Value} className={Class} size={Size} type={Type} placeholder={Placeholder} onChange={handleChange} />
            </>
          )}
        </>
      )}
    </>
  );
}

export default FormControl;
