import * as React from 'react';
import { BodyText, Box } from '../../StyledComponents';

export const DeleteRepairOrderModalContent = () => {
    return (
        <>
            <Box>
                <BodyText fontSize='13px' fontWeight='400' textColor='#666'>
                    Diesen Reparaturauftrag löschen?
                </BodyText>
            </Box>
        </>
    )
}