import { Button, Col, Form, Row } from 'react-bootstrap';
import FormCheck from '../../FormCheck';
import FormControl from '../../FormControl';
import { DisplayEnd } from '../../StyledComponents';
import { SubmitHandler, useForm, useWatch } from "react-hook-form";





export const CurrentFiltersModalContent = () => {
    return (
        <>
            <Row>
                <Col md='12' className='p-2'>
                    <FormControl Type='text' Size='sm' Placeholder='Titel...' Name='emailAddress' Id='emailAddress' />
                    <div className='my-2'>
                        <FormCheck
                            type='checkbox'
                            name='phone'
                            Id='phone'
                            value='Als Standard-Filter speichern'
                            label='Als Standard-Filter speichern'
                            isFormGroup={false}
                            isInline={true}
                        />
                    </div>
                    <DisplayEnd>
                        <Button className='primary-bg f-14px'>
                            Speichem
                        </Button>
                    </DisplayEnd>
                </Col>
            </Row>
        </>
    )
}