import React, { useState } from 'react';
import { Button, Card, Dropdown, DropdownButton } from 'react-bootstrap';
import { BodyText, CustomCardBody, DisplayBetween, DisplayEnd } from '../Components/StyledComponents';
import FormCheck from '../Components/FormCheck';
import { JsxElement } from 'typescript';
import CommonModal from './Modals/CommonModal';
import { DeleteModuleModalContent } from './Modals/ModelContents/DeleteModuleModalContent';

type CardProp = {
    title?: any,
    titleFontSize?: string,
    isCardHeader?: boolean,
    cardBorder?: string,
    cardHeaderBg?: string,
    cardBodyContent?: any,
    cardBodyHeight?: string,
    cardBodyPadding?: string,
    isHeaderDropdown?: boolean,
    isCardFooter?: boolean,
    cardFooterContent?: JsxElement | string,
    handleClick?: () => void,
}

export const CustomCard = (props: CardProp) => {

    const [deleteOverdue, setDeleteOverdue] = useState<boolean>(false);

    return (
        <>
            <Card className={`${props.cardBorder ? null : "border-0 rounded-0 b-shadow mt-2"}`}>
                {!!props.isCardHeader ?
                    <Card.Header className={`${props.cardHeaderBg ? null : "bg-white"}`} onClick={props.handleClick}>
                        <DisplayBetween>
                            <BodyText fontSize={!!props.titleFontSize ? props.titleFontSize : '15px'} textColor='#666' fontWeight='700'>
                                <>
                                    {props.title}
                                </>
                            </BodyText>
                            {props.isHeaderDropdown ?
                                <>
                                    <DropdownButton size="sm" className="template-withouticon f-12px" id="new-item" title={<></>}>
                                        <Dropdown.Item>
                                            <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Bearbeiten </BodyText>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <FormCheck
                                                type='checkbox'
                                                name='phone'
                                                Id='phone'
                                                value='Automatische Höhe'
                                                label='Automatische Höhe'
                                                isFormGroup={false}
                                                isInline={false}
                                                disabled={false}
                                            />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => setDeleteOverdue(true)}>
                                            <BodyText fontSize='14px' textColor='#333' fontWeight='400'> Löschen </BodyText>
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </>
                                : null}

                        </DisplayBetween>
                    </Card.Header>
                    : null}

                <CustomCardBody height={props.cardBodyHeight} padding={props.cardBodyPadding}>
                    <> {props.cardBodyContent}</>
                </CustomCardBody>
                {props.isCardFooter ?
                    <Card.Footer>
                        <> {props.cardFooterContent}</>
                    </Card.Footer>
                    : null}

            </Card>

            <CommonModal
                showModal={deleteOverdue}
                hideModal={() => setDeleteOverdue(false)}
                modalTitle='Dieses Modul löschen'
                modalContent={<DeleteModuleModalContent deleteModal="OverdueTasksList" />}
                modalSize='md'
                centered={true}
                modalFooterContent={
                    <>
                        <DisplayEnd>
                            <Button className='default-btn f-14px me-1' onClick={() => setDeleteOverdue(false)}> Cancel </Button>
                            <Button variant="danger" className='f-14px'> Delete</Button>
                        </DisplayEnd>
                    </>
                }
            />
        </>
    )
}