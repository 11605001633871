import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { SidebarAdmin } from './SidebarAdmin';

export const AdminIndex = () => {
    return (
        <>
            <Row>
                <Col md={3} xs={12}>
                    <SidebarAdmin />
                </Col>
                <Col md={9} xs={12} className='overflow-hidden'>
                    <Outlet />
                </Col>
            </Row>
        </>
    )
}