import { FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CheckControl } from "../../../Components/Widgets/InputControls/CheckControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserProfileDto } from "../../../Model/ViewModels/User/UserProfileDto";
import { BodyText, Box, DisplayEnd, DisplayStart } from "../../../Components/StyledComponents";
import { InputTextControl } from "../../../Components/Widgets/InputControls/InputTextControl";
import ButtonWidget from "../../../Components/ButtonWidget";
import AddEditArticle from "../../Article/AddEditArticle";


interface IProps { }


const formSchema = yup
    .object({
        firstName: yup
            .string()
            .typeError("First name is required")
            .required("First name is required"),
        lastName: yup
            .string()
            .typeError("Last name is required")
            .required("Last name is required"),
        position: yup
            .string()
            .typeError("Position is required")
            .required("Position is required"),
    })
    .required();


const ManualConfig: FC<IProps> = ({ }) => {

    const [ArtikelModal, setArtikelModal] = useState<boolean>(false);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, defaultValues },
        reset,
        formState,
        watch,
    } = useForm<UserProfileDto>({
        defaultValues: new UserProfileDto(),
        resolver: yupResolver(formSchema) as any,
        // mode: "onChange",
        //resolver: yupResolver(formSchema),
    });
    const fn = watch("firstName");
    const { gender } = watch();
    console.log(fn);

    return (
        <>
            <Row>
                <Col md={12}>
                    <h2>Handbuch</h2>
                    <Box>
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"gender"}
                            error={errors.gender}
                            value={"Zulassen, dass Leserechte nur auf MainAdmin beschränkt werden (in Artikelformular)"}
                            label={"Zulassen, dass Leserechte nur auf MainAdmin beschränkt werden (in Artikelformular)"}
                            isInline={false}
                            disabled={false}
                            checked={undefined}
                            defaultChecked={false}
                        />
                        <CheckControl
                            fieldType={"checkbox"}
                            control={control}
                            fieldName={"gender"}
                            error={errors.gender}
                            value={"Zulassen, dass Bearbeitungsrechte nur auf MainAdmin beschränkt werden (in Artikelformular)"}
                            label={"Zulassen, dass Bearbeitungsrechte nur auf MainAdmin beschränkt werden (in Artikelformular)"}
                            isInline={false}
                            disabled={false}
                            checked={undefined}
                            defaultChecked={false}
                        />
                    </Box>
                    <BodyText fontSize='15px' fontWeight='400' textColor='#666' mt="12px" mb="15px"> Vorlage ändern, die für alle neu erstellten Artikel verwendet wird, für die keine Vorlage eingestellt wurden und keine Standardvorlagen in ihren Kategorien vorhanden sind. </BodyText>
                    <ButtonWidget varient="primary" text="Standard Artikel bearbeiten" class='text-nowrap' handleClick={() => setArtikelModal(true)} />
                </Col>
                <Col md={12}>
                    <div className="mt-4 pt-3 border-top" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                        <DisplayEnd className="px-3">
                            <ButtonWidget
                                text="Speichern"
                                varient="primary"
                                class="py-2 px-3"
                            />
                        </DisplayEnd>
                    </div>
                </Col>
            </Row>

            <AddEditArticle
                showArticle={ArtikelModal}
                hideArticle={() => setArtikelModal(false)}
            />
        </>
    );
}

export default ManualConfig;